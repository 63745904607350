import * as ServiceHelper from "../service-helper";
import { Button, Input, InputNumber, message, Modal, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import SimpleMdeReact from "react-simplemde-editor";
import {
  SaveOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
  EditFilled,
  FileOutlined,
} from "@ant-design/icons";
import Countries from "../../../countryList.json";
import AppContext from "../../../store";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";

export default function DataTable(props: {
  tableData?: any;
  initialTableData?: any;
  saveCounter?: any;
  setSaveCounter?: any;
}) {
  //@ts-ignore
  let { tab, subtab, cardId, key } = useParams();
  const context = React.useContext(AppContext);
  const [inEditMode, setInEditMode] = useState<any>(false);
  const [tableData, setTableData] = useState<any>([...props.tableData]);
  const [editColumnName, setEditColumnName] = useState<string>("");
  const [editCellKey, setEditCellKey] = useState<any>();
  const [editCellDataIndex, setEditCellDataIndex] = useState<string>("");
  const [editCellValue, setEditCellValue] = useState<any>("");
  const [isModalVisibleCell, setIsModalVisibleCell] = useState(false);
  const [maxKey, setMaxKey] = useState(0);

  const columns = [
    {
      title: "Publication",
      dataIndex: "publication",
    },
    {
      title: "Countries",
      dataIndex: "countries",
    },
    {
      title: "Prevalence",
      dataIndex: "prevalence",
    },
    {
      title: "Incidence",
      dataIndex: "incidence",
    },
    {
      title: "Mortality",
      dataIndex: "mortality",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Other Notes",
      dataIndex: "other_notes",
    },
  ];

  useEffect(() => {
    let temp = 0;
    props.initialTableData.map((item: any) => {
      if (item.key > temp) temp = item.key;
    });
    setMaxKey(temp + 1);
  }, []);

  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "700px",
    };
  }, []);

  const handleOkCell = () => {
    let temp = [...tableData];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["key"] === editCellKey) {
        if (editCellDataIndex === "countries") {
          temp[i][editCellDataIndex] = editCellValue.join(", ");
        } else temp[i][editCellDataIndex] = editCellValue;
      }
    }
    console.log("after ok", temp);
    setIsModalVisibleCell(false);
    setEditCellValue("");
  };

  const handleCancelCell = () => {
    setIsModalVisibleCell(false);
    setEditCellValue("");
  };

  const handleCellValueChange = (value: string) => {
    setEditCellValue(value);
  };

  const showModalCell = (key: any, dataIndex: any, value: any) => {
    console.log(key, dataIndex, value);
    setEditCellKey(key);
    setEditCellDataIndex(dataIndex);
    for (let i = 0; i < columns.length; i++) {
      if (columns[i]["dataIndex"] === dataIndex) {
        setEditColumnName(columns[i]["title"]);
        break;
      }
    }
    if (dataIndex === "countries") {
      if (value === "" || value === undefined || value === null)
        setEditCellValue(undefined);
      else setEditCellValue(value.replaceAll(", ", "***").split("***"));
    } else {
      if (value === "" || value === undefined || value === null)
        setEditCellValue(undefined);
      else setEditCellValue(value);
    }
    setIsModalVisibleCell(true);
  };

  async function saveHandler() {
    let tempColumns = [...columns];
    let tempData = JSON.parse(JSON.stringify(tableData));
    let tempColumnKeys: any = [];

    tempData.map((item: any) => {
      let tempKeys = Object.keys(item);
      columns.map((col: any) => {
        if (!tempKeys.includes(col.dataIndex)) {
          item[col.dataIndex] = "";
        }
      });
    });

    console.log("saving data for study", tempColumns, tempData);
    console.log("urls", tab, subtab, cardId, key);
    message.loading("Saving Changes!", 2);
    const d: any = await ServiceHelper.editSupportingMessageEvidenceAtlas(
      tableData,
      key
    );
    console.log(d);
    if (d) {
      message.success("Saved!");
      props.setSaveCounter(props.saveCounter + 1);
    } else {
      message.error("Save failed!");
    }
    setInEditMode(false);
  }

  function addRowHandler() {
    let temp = [...tableData];
    // let key = temp[temp.length - 1]?.key + 1 || 0;
    temp.push({
      key: maxKey,
      //   publication: "*Click to Edit!*",
      //   countries: "",
    });
    setMaxKey(maxKey + 1);
    setTableData([...temp]);
    console.log(temp);
  }

  function deleteRowHandler(key: any, publication: any) {
    let temp = [...tableData];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].key === key && temp[i].publication === publication) {
        temp.splice(i, 1);
        break;
      }
    }
    setTableData([...temp]);
  }

  return (
    <div style={{ height: "525px" }} className="bg-white shadow-md p-4 mx-4">
      {context.isAdmin && (
        <div className="flex items-center">
          <div className="w-1/2 flex items-center">
            {inEditMode && <p className="italic text-red-500">*</p>}
            {inEditMode && (
              <p className="text-xs italic ml-1">
                Click on a cell to enter/update data!
              </p>
            )}
          </div>
          <div className="w-1/2 flex justify-end items-center">
            {inEditMode ? (
              <div>
                <Button className="px-2" type="default" onClick={addRowHandler}>
                  <PlusCircleOutlined />
                  Add Row
                </Button>
                <Button className="ml-2" type="default" onClick={saveHandler}>
                  <SaveOutlined />
                  Save
                </Button>
                <Button
                  className="ml-2"
                  type="default"
                  onClick={() => {
                    setTableData(props.initialTableData);
                    setInEditMode(false);
                  }}
                >
                  <CloseOutlined />
                  Cancel
                </Button>
              </div>
            ) : (
              <Button type="default" onClick={() => setInEditMode(true)}>
                <EditFilled />
                Edit
              </Button>
            )}
          </div>
        </div>
      )}
      {tableData.length > 0 || inEditMode ? (
        <div
          className="fixedHeader mt-2"
          style={
            inEditMode
              ? {
                  overflowY: "auto",
                  height: 450,
                }
              : {
                  overflowY: "auto",
                  height: 450,
                }
          }
        >
          <table>
            <thead>
              <tr className="roundedRows ">
                {inEditMode && (
                  <th
                    style={{
                      backgroundColor: "#27a6a4",
                      color: "whitesmoke",
                      width: "2rem",
                    }}
                    className="font-bold text-center px-2 "
                  ></th>
                )}
                {columns.map((col: any) => (
                  <th
                    key={col.dataIndex}
                    style={{
                      backgroundColor: "#27a6a4",
                      color: "whitesmoke",
                    }}
                    className="font-bold text-center px-2 "
                  >
                    {col.title}
                  </th>
                ))}
              </tr>
            </thead>
            <div className="h-1"></div>
            <tbody>
              {tableData.map((row: any, index: any) => {
                const color = index % 2 === 0 ? "#eaf8f8" : "white";
                const keys: any = [];
                columns.map((c: any) => keys.push(c["dataIndex"]));
                const temp = keys.indexOf("key");
                if (temp > -1) {
                  keys.splice(temp, 1);
                }
                return (
                  <tr
                    key={row.key}
                    className="hover:bg-transparent border-b-8 roundedRows"
                    style={{ backgroundColor: color }}
                  >
                    {inEditMode && (
                      <td
                        style={{ width: "2rem" }}
                        className="align-top p-2 text-center"
                      >
                        <DeleteOutlined
                          onClick={() =>
                            deleteRowHandler(row.key, row.publication)
                          }
                        />
                      </td>
                    )}
                    {
                      //@ts-ignore
                      keys.map((key) => {
                        return (
                          <td
                            key={key}
                            onClick={() => {
                              if (inEditMode)
                                showModalCell(row.key, key, row[key]);
                            }}
                            style={{ width: "30rem" }}
                            className={`align-top p-2 text-center ${
                              inEditMode ? "cursor-pointer" : ""
                            }`}
                          >
                            <ReactMarkdown children={row[key]} />
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          style={{ height: "93%" }}
          className="flex items-center justify-center border-2 rounded-md mt-2"
        >
          <div className="text-center">
            <EditFilled style={{ color: "#27a6a4", fontSize: "3rem" }} />
            <p className="text-xl mt-2 italic">Click on Edit to Add Details!</p>
          </div>
        </div>
      )}
      <Modal
        className="editTableModal"
        title={<p className="text-white">Edit Data</p>}
        destroyOnClose
        visible={isModalVisibleCell}
        onOk={handleOkCell}
        onCancel={handleCancelCell}
        closable={false}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
      >
        {editCellDataIndex === "countries" ? (
          <div className="flex justify-center items-center">
            <p className="mr-2 italic">Select Countries</p>
            <Select
              style={{ width: 300 }}
              defaultValue={editCellValue}
              placeholder="Select Option"
              onChange={handleCellValueChange}
              mode="multiple"
              allowClear
            >
              {Countries.countryList.map((item) => (
                <Select.Option value={item.country}>
                  {item.country}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : editCellDataIndex === "gender" ? (
          <div className="flex justify-center items-center">
            <p className="mr-2 italic">Select Gender</p>
            <Select
              style={{ width: 300 }}
              defaultValue={editCellValue}
              placeholder="Select Option"
              onChange={handleCellValueChange}
            >
              <Select.Option value={"Male"}>{"Male"}</Select.Option>
              <Select.Option value={"Female"}>{"Female"}</Select.Option>
            </Select>
          </div>
        ) : (
          <div id="SLRMDEditor" className="flex justify-center items-center">
            <p className="mr-2 italic">Enter {editColumnName}:</p>
            <SimpleMdeReact
              options={options}
              value={editCellValue || ""}
              onChange={handleCellValueChange}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}
