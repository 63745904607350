import React from "react";
import BodyCardLayout from "../../../layout/components/body-layout";
import { ArrowThickDown } from "@styled-icons/zondicons/ArrowThickDown";
import { Handshake } from "@styled-icons/fa-solid/Handshake";
import { ManageSearch } from "@styled-icons/material-outlined/ManageSearch";
import { PersonChat } from "@styled-icons/fluentui-system-regular/PersonChat";
import { MessageSquareCheck } from "@styled-icons/boxicons-regular/MessageSquareCheck";
import { MakeGroup } from "@styled-icons/icomoon/MakeGroup";
import { BrainCircuit } from "@styled-icons/fluentui-system-filled/BrainCircuit";
import { ThumbsUp } from "@styled-icons/fa-solid/ThumbsUp";
import { CrossReference } from "@styled-icons/octicons/CrossReference";
import AppContext from "../../../store";
import {
	ObjectionsContainer,
	DivTopCard,
	DivBottomCard,
	ObjecionCard,
	ObjecionCard2,
	ObjectionCardDetail1,
	ObjectionCardDetail2,
	ObjectionCard2Header,
	ObjectionCardDetail3,
} from "./style";
import { Slide } from "react-slideshow-image";
import { Row, Col } from "antd";
export default function PreparingForObjections(props: {
	showReturn: React.Dispatch<React.SetStateAction<string>>;
}) {
	props.showReturn("");
	const context = React.useContext(AppContext);

	// const subSectionId: number =
	// 	context.subSectionMapping
	// 		.find((section) => section.name === "Objection Handler")
	// 		?.subSection.find(
	// 			(subSection) => subSection.name === "Preparing for Objections"
	// 		)?.id || 0;
	// const sectionId: number =
	// 	context.subSectionMapping
	// 		.find((section) => section.name === "Objection Handler")
	// 		?.subSection.find(
	// 			(subSection) => subSection.name === "Preparing for Objections"
	// 		)?.sectionId || 0;
	const data1 = [
		{
			icon: <Handshake size={100} />,
			header: "Acknowledge",
			detail1:
				"Listen & Acknowledge the challenge by confirming your understanding of the objection",
			detail2:
				"The first step when responding to an objection is to carefully take an interest in the payer’s concern and try to understand their perspective.",
		},
		{
			icon: <ManageSearch size={100} />,
			header: "Probe",
			detail1: "Explore the real issues / payer perspectives at play.",
			detail2:
				"Understanding the real issues / payer perspectives behind an objection is essential if we are to put across the right point of view or the best proposal.",
		},
		{
			icon: <PersonChat size={100} />,
			header: "Answer",
			detail1:
				"Answer appropriately to different types of objections and be bold in defending your point of view.",
			detail2:
				"Understanding the type of objection at play allows you to answer effectively and tailor your approach. It can provide you with the opportunity to clarify any misunderstanding and emphasise that the benefits match payer’s needs and outweigh the drawback.",
		},
		{
			icon: <MessageSquareCheck size={100} />,
			header: "Confirm & Transition​",
			detail1:
				"Propose and confirm that you have handled the Payer’s objection and provide a pathway to move forward​",
			detail2:
				"Confirming with the payers that the objection is no longer an issue is essential.",
		},
	];
	const data2 = [
		{
			color: "#ed7d31",
			icon: <MakeGroup size={40} />,
			header: "Categorise the Objection",
			detail: "Understanding the type and class of objection allows affiliates to easily, effectively and clearly address the objection",
		},
		{
			color: "#a5a5a5",
			icon: <BrainCircuit size={40} />,
			header: "Think about the why",
			detail: "Considering the rationale for why a payer is asking a question and how it is impacting their behavior provides insight into what an answer needs to contain to meet and address payer needs.",
		},
		{
			color: "#e1bf00",
			icon: <ThumbsUp size={40} />,
			header: "Acknowledge and Respond",
			detail: "Determining the key topics/strategy to address the question will ensure answers are specific, complete and address the actual question",
		},
		{
			color: "#5b9bd5",
			icon: <CrossReference size={40} />,
			header: "Back it up (with data)​",
			detail: "Supplementing the answer with factual, validated data is crucial",
		},
	];
	const zoomOutProperties = {
		scale: 0.4,
	};
	function Content() {
		return (
			<>
				<Slide
					// infinite={false}
					autoplay={false}
					className="slidediv"
					easing="ease"
					{...zoomOutProperties}
				>
					<DivTopCard>
						{data1.map((row) => {
							return (
								<>
									<ObjecionCard>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												color: "orange",
												fontSize: "1.2rem",
												fontWeight: 600,
											}}
										>
											{row.icon}
											{row.header}
										</div>
										<ObjectionCardDetail1>
											{row.detail1}
										</ObjectionCardDetail1>
										<ObjectionCardDetail2>
											{row.detail2}
										</ObjectionCardDetail2>
									</ObjecionCard>
								</>
							);
						})}
					</DivTopCard>
					<DivBottomCard>
						{data2.map((row, index) => {
							return (
								<Row align="middle">
									<Col
										// offset={2 * index}
										span={24}
										style={{ color: row.color }}
									>
										<ObjecionCard2>
											<ObjectionCard2Header
												color={row.color}
											>
												<div>{row.icon}</div>
												<p>{row.header}</p>
											</ObjectionCard2Header>

											<ObjectionCardDetail3>
												<p>{row.detail}</p>
											</ObjectionCardDetail3>
										</ObjecionCard2>
									</Col>
								</Row>
							);
						})}
					</DivBottomCard>
				</Slide>
			</>
		);
	}
	return (
		<>
			{/* <BodyCardLayout
				grid={3}
				type="overview"
				subSectionId={subSectionId}
				sectionId={sectionId}
			/> */}
			<ObjectionsContainer>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Content />
				</div>
			</ObjectionsContainer>
		</>
	);
}
