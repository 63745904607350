import styled from "styled-components";
import { colors } from "./../../../../globalTheme";
import { Tooltip } from "antd";
export const SupportingMessageContainer = styled.div`
	border-radius: 0px;
	width: 100%;
	/* display: flex;
	flex-direction: column;
	justify-content: center; */
	box-sizing: border-box;

	padding: ${(props: {
		height?: string;
		padding?: string | null;
		bg?: string;
		layout?: string;
	}) => props.padding || "1.5em"};
	background-color: ${(props: {
		height?: string;
		padding?: string;
		bg?: string;
		layout?: string;
	}) =>
		props.bg ||
		"transparent"}; // Have option for transparent or other colors
	/* background: red; */
	height: ${(props: {
		height?: string;
		padding?: string | null;
		bg?: string;
		layout?: string;
	}) =>
		props.layout
			? "calc(100vh - 32vh)"
			: props.height || "calc(100vh - 24%)"};

	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
export const StyledMessageContainer = styled.div`
	position: relative;
	width: 100%;
	padding: 1.8em 4em;
	background-color: ${colors.zsTeal};
	border-radius: 10px;
	p {
		margin: 0;
		padding: 0;
		font-weight: 700;
		font-size: 1.2rem;
		color: ${colors.zsWhite};
	}
`;

export const StyledSupportingMessage = styled.div`
	margin: 1em 0 2em 0;
	padding: 0.5em 1em;
	width: 100%;
	/* box-shadow: 4px 4px 10px ${colors.zsShadow}; */
	border-left: 6px solid ${colors.zsTeal};
	border-radius: 8px;
	background: ${colors.zsWhite};
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const StyledSupportingMessageEdit = styled.div`
	margin: 1em 0;
	padding: 0.5em 1em;
	width: 100%;
	box-shadow: 4px 4px 10px ${colors.zsShadow};
	background: ${colors.zsWhite};

	.content {
		max-height: ${(props: { edit?: boolean }) =>
			props.edit ? "100px" : "auto"};
		overflow: hidden;
		display: flex;
		align-items: ${(props: { edit?: boolean }) =>
			props.edit ? "top" : "center"};
		justify-content: space-between;
	}
`;
export const SupportingMessageTextPlaceholder = styled.div`
	min-width: 100px;
	padding: 1em 2em;
	/* flex: 0 0 60%; */
	flex: 1;
	/* background: red; */
`;
export const SupportingMessageImagePlaceholder = styled.div`
	/* flex: 0 0 50%; */
	flex: 1;
	max-width: 30%;
	/* min-width: 700px; */
	min-height: 90px;
	max-height: 30vh;
	/* background: #dfdfdf; */
`;
export const SupportingMessageHeaderText = styled.h4`
	font-size: 1.2rem;
	font-weight: 700;
	color: ${colors.zsGray80};
`;
export const SupportingMessageBodyTexts = styled.ul`
	padding: 0 4em;
`;
export const SupportingMessageBodyText = styled.li`
	/* font-size: 0.8rem; */
	font-weight: 400;
	color: ${colors.zsGray80};
	margin: 0.8em 0;
`;
export const Actions = styled.div`
	margin: 0;
	padding: 0.8em 0 0 0;
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: center;
	color: ${colors.zsTeal};
	font-weight: 500;
	button {
		border: none;
		background: transparent;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.messageIcons{
		&:hover{
			color:${colors.zsOrange}
				transform: scale(110%);

		}
	}
`;
export const StyledTooltip = styled(Tooltip)`
	vertical-align: super;
	font-size: 0.6rem;
	padding: 5px;
`;
export const SupportingMessageIconPlaceholder = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0.5em;
	color: ${colors.zsTeal};
	.messageIcons {
		&:hover {
			color: ${colors.zsOrange};
			transform: scale(110%);
		}
	}
`;

export const SupportingMessageFlexContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	@media (max-width: 1000px) {
		flex-direction: column;
	}
`;

export const StyledTableChartPlaceholder = styled.div`
	width: 100%;
	flex: 0 0 100%;
	/* background: red; */
`;
