import {
  TileContainer,
  TileHeader,
  TileBody,
  AddBrandContainer,
  TileFooter,
} from "./style";
import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline";
import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import { Edit2Outline } from "@styled-icons/evaicons-outline/Edit2Outline";
import React from "react";
import { Link } from "react-router-dom";
// import AppContext from "../../../store";
import { Popconfirm, Tooltip } from "antd";
import { Create } from "@styled-icons/ionicons-sharp/Create";
import { Update } from "@styled-icons/material-sharp/Update";

/**
 * Renders the Landing Page and sets up the major context and session variables.
 *
 * @param id - Product id of the tile
 * @param header - Product Name of the Tile
 * @param description - Product Description of the Tile
 * @param createdBy - Created By username
 * @param lastUpdatedBy - Last Updated By username
 * @param add - Whether the Card is an "Add Card" Button or not
 * @param link - Header Link
 * @param handleDelete - Callback function for handling delete product function.
 * @param showModalEdit - Callback function for
 *
 * @returns Landing Page of the App
 */
export default function LandingTile(props: {
  id: number;
  header: string;
  description: string;
  createdBy: string;
  lastUpdatedBy: string;
  add?: boolean;
  link?: string;
  role?: number;
  split?: boolean;
  handleDelete?: (id: number) => void;
  showModalEdit?: (id: number) => void;
  onClick?: () => void;
}) {
  // const context = React.useContext(AppContext);
  function confirm(e: any) {
    //@ts-ignore
    if (props.handleDelete) props.handleDelete(props.id);
  }

  return (
    <div>
      {!props.add && (
        <TileContainer>
          <TileHeader>
            <Link
              to={props.link || "/"}
              onClick={() => {
                localStorage.setItem("productId", props.id.toString());
                localStorage.setItem(
                  "productName",
                  props.header.split("_").join(" ")
                );
                // context.setProduct(props.id, props.header);
                // context.setFirstLoading(true);
              }}
            >
              {props.split && props.header.length > 14 ? (
                <Tooltip title={props.header}>
                  {props.header.toLowerCase().slice(0, 10) + "..."}
                </Tooltip>
              ) : props.header.length > 24 ? (
                <Tooltip title={props.header}>
                  {props.header.toLowerCase().slice(0, 21) + "..."}
                </Tooltip>
              ) : (
                props.header.toLowerCase()
              )}
            </Link>
            {String(props.role) === "2" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Popconfirm
                  title="Are you sure to delete this disaese area?"
                  onConfirm={confirm}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Delete
                    title="Delete Disease Area"
                    className="icon"
                    size={30}
                    // onClick={() => {
                    //   if (props.handleDelete) props.handleDelete(props.id);
                    // }}
                  />
                </Popconfirm>

                <Edit2Outline
                  title="Edit Disease Area"
                  className="icon"
                  size={26}
                  style={{ marginTop: "4px" }}
                  onClick={() => {
                    if (props.showModalEdit) props.showModalEdit(props.id);
                  }}
                />
              </div>
            )}
          </TileHeader>
          <TileBody>{props.description}</TileBody>
          <TileFooter>
            <span>
              <Create size={15} style={{ marginBottom: "2px" }} />
              <h3>Created By </h3>
              <p>{props.createdBy}</p>
            </span>
            <span>
              <Update size={15} style={{ marginBottom: "2px" }} />
              <h3>Last Updated By </h3>
              <p>{props.lastUpdatedBy}</p>
            </span>
          </TileFooter>
        </TileContainer>
      )}
      {props.add && (
        <div
          onClick={() => {
            if (props.onClick) props.onClick();
          }}
        >
          <TileContainer>
            <AddBrandContainer>
              <p>
                <AddCircleOutline size={30} />
                <br />
                Add Disease Area
              </p>
            </AddBrandContainer>
          </TileContainer>
        </div>
      )}
    </div>
  );
}
