import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";

export function GlobalFilter({ filter, setFilter }) {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 400);
  return (
    <span className="mb-4">
      Search:{" "}
      <input
        className="border rounded-md h-7 w-72 ml-2"
        placeholder="Enter a search term"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </span>
  );
}
