import { request } from "./../layout";
export const getExecutiveSummaryData = async (
	productId: number,
	subSectionId: number,
	tabId?: number
) => {
	let fetchUrl =
		"SubSection/GetAllSubSection?BrandId=" +
		productId +
		"&SubSectionId=" +
		subSectionId;
	if (tabId) {
		fetchUrl = fetchUrl + "&tabId=" + tabId;
	}

	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			return null;
		});

	return data;
};

export const addNewTileData = async (
	tileData: any,
	brandId: number,
	subSectionId: number
) => {
	let fetchUrl =
		"SubSection/AddCardComponent?BrandId=" +
		brandId +
		"&SubSectionId=" +
		subSectionId;
	console.log("Adding : " + JSON.stringify(tileData));
	const data = await request
		.post(fetchUrl, tileData, { withCredentials: true })
		.then((response) => {
			console.log(response);
			return response.status;
		})
		.catch((err) => {
			console.log(err.message, "error here");
			return null;
		});
	return data;
};

export const deleteTileData = async (id: number, productId: number) => {
	let fetchUrl =
		"SubSection/DeleteCardComponent/" + id + "?BrandId=" + productId;

	const data = await request
		.delete(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response);
			return response.status;
		})
		.catch((err) => {
			console.log(err.message, "error here");
			return null;
		});
	return data;
};
export const editTileData = async (
	tileData: any,
	tileId: number,
	productId: number
) => {
	let fetchUrl =
		"SubSection/EditCardComponent/?id=" + tileId + "&BrandId=" + productId;
	console.log(tileData);
	const data = await request
		.patch(fetchUrl, tileData, {
			headers: { contenType: "application/json" },
			withCredentials: true,
		})
		.then((response) => {
			console.log(response);
			return response.status;
		})
		.catch((err) => {
			console.log(err.message, "error here");
			return null;
		});
	return data;
};

export const editBannerData = async (bannerData: any) => {
	let fetchUrl = "SubSection/EditSubSectionComponent";
	console.log(bannerData);
	const data = await request
		.post(fetchUrl, bannerData, {
			headers: { contenType: "application/json" },
			withCredentials: true,
		})
		.then((response) => {
			console.log(response);

			return response.status;
		})
		.catch((err) => {
			console.log(err.message, "error here");
			return null;
		});
	return data;
};
export const editOrderData = async (orderData: any, productId: number) => {
	let fetchUrl = "SubSection/EditCardComponentOrder?BrandId=" + productId;
	// console.log("Save Order API :", orderData);
	const data = await request
		.patch(fetchUrl, orderData, {
			headers: { contenType: "application/json" },
			withCredentials: true,
		})
		.then((response) => {
			console.log(response);

			return response.status;
		})
		.catch((err) => {
			console.log(err.message, "error here");
			return null;
		});
	return data;
};

export const getSubSection = async (productId: number) => {
	let fetchUrl = "brand/GetBrandSubsections?BrandId=" + productId;

	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			return null;
		});

	return data;
};
