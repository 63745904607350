export enum projectionType {
	azimuthalEqualArea = "azimuthalEqualArea",
	azimuthalEquidistant = "azimuthalEquidistant",
	gnomonic = "gnomonic",
	orthographic = "orthographic",
	stereographic = "stereographic",
	equalEarth = "equalEarth",
	equirectangular = "equirectangular",
	mercator = "mercator",
	transverseMercator = "transverseMercator",
	naturalEarth1 = "naturalEarth1",
}
/**
 * Main Interface for Chart Config of GeoMap Component
 */
export interface Interface {
	domain?: [number, number];
	projection?: {
		type?: projectionType;
		scale?: number;
		translation?: [number, number];
		rotation?: [number, number, number];
	};
	colorArray?: string[];
	unknownColor?: string;
	graticule?: {
		lineWidth?: number;
		lineColor?: string;
	};
}
