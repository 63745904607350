import React from "react";
import AppContext from "./../../store/index";
import "../../layout/components/body-layout/Subtabs/Subtabs.css";
import BodyCardLayout from "../../layout/components/body-layout";
import { Route, Switch, useParams } from "react-router-dom";
import * as ServiceHelper from "./service-helper";
/**
 * Renders the Executive Summary Page.
 *
 * @returns Executive Summary Page
 */
export default function ExecutiveSummary() {
	const context = React.useContext(AppContext);
	//@ts-ignore
	const { productId, productName } = useParams();

	// async function getSubSection(productId: number) {
	// 	const d: any = await ServiceHelper.getSubSection(productId);
	// 	console.log("Fetched....", d);
	// 	if (d && d.brandSubsectionDetails.length > 0) {
	// 		context.setSubSection(
	// 			d.brandSubsectionDetails[0].subSectionDetails || []
	// 		);
	// 	}
	// }

	React.useEffect(() => {
		// if (context.subSectionMapping.length <= 1) getSubSection(productId);
		context.setView(1);
		// localStorage.setItem("productId", productId);
		// localStorage.setItem("productName", productName.split("_").join(" "));
	}, []);

	const access =
		context.userRole.find(
			(product) => product.productId === Number(productId)
		)?.roleId || 0;
	if (access === 2) {
		localStorage.setItem("admin", "true");
		context.setAdmin(true);
	} else if (access === 1) {
		localStorage.setItem("admin", "false");
		context.setAdmin(false);
	}
	// console.log("Access", access);
	const subSections = context.subSectionMapping.find(
		(section) => section.name === "Executive Summary"
	);
	const subTabs: {
		name: string;
		link: string;
		path: string;
		component: (subSectionId: number) => JSX.Element;
		isDefault: boolean;
	}[] = [];
	const subTabViews = (
		subSectionName: string,
		subSectionId: number,
		sectionId: number
	) => (
		<BodyCardLayout
			// data={dummyData}
			grid={subSectionName === "Overview" ? 3 : 2}
			type={
				subSectionName === "Overview"
					? "overview"
					: subSectionName === "default"
					? "overview"
					: "keyMessage"
			}
			subSectionId={subSectionId}
			sectionId={sectionId}
			productId={productId}
			productName={productName}
		/>
	);
	subSections?.subSection.map((tab, index) =>
		subTabs.push({
			name: tab.name,
			link: tab.name.split(" ").join("_").toLowerCase(),
			path:
				tab.name === "default"
					? "/summary/:productId/:productName"
					: "/" + tab.name.split(" ").join("_").toLowerCase(),
			component: () => subTabViews(tab.name, tab.id, tab.sectionId),
			isDefault: tab.isDefault,
		})
	);

	return (
		<>
			{console.log("Exec Summ", subTabs)}
			<Switch>
				{subTabs.map((subTab) => {
					return (
						<Route
							exact
							path={subTab.path}
							component={subTab.component}
						/>
					);
				})}
			</Switch>
		</>
	);
}
