import * as ExecutiveSummaryService from "../../services/executiveSummary";

export const getAllData: (
	brandId: number,
	subSectionId: number,
	tabId?: number
) => Promise<unknown> = async (brandId, subSectionId, tabId) => {
	const data = await ExecutiveSummaryService.getExecutiveSummaryData(
		brandId,
		subSectionId,
		tabId
	);
	if (data === null || data === undefined) return null;
	return data;
};

export const addNewTile: (
	tileData: any,
	brandId: number,
	subSectionId: number,
	tabId?: number
) => Promise<unknown> = async (tileData, brandId, subSectionId, tabId) => {
	const data = await ExecutiveSummaryService.addNewTileData(
		tileData,
		brandId,
		subSectionId
	);
	const data2 = await ExecutiveSummaryService.getExecutiveSummaryData(
		brandId,
		subSectionId,
		tabId
	);
	if (data === null || data === undefined) return null;
	return data2;
};

export const deleteTile: (
	id: number,
	brandId: number,
	subSectionId: number,
	productId: number,
	tabId?: number
) => Promise<unknown> = async (id, brandId, subSectionId, productId, tabId) => {
	// console.log("Inside Add Brand");
	const data = await ExecutiveSummaryService.deleteTileData(id, productId);
	const data2 = await ExecutiveSummaryService.getExecutiveSummaryData(
		brandId,
		subSectionId,
		tabId
	);
	if (data === null || data === undefined) return null;
	return data2;
};
export const editTile: (
	tileData: any,
	tileId: number,
	brandId: number,
	subSectionId: number,
	tabId?: number
) => Promise<unknown> = async (
	tileData,
	tileId,
	brandId,
	subSectionId,
	tabId
) => {
	const data = await ExecutiveSummaryService.editTileData(
		tileData,
		tileId,
		brandId
	);
	const data2 = await ExecutiveSummaryService.getExecutiveSummaryData(
		brandId,
		subSectionId,
		tabId
	);
	if (data === null || data === undefined) return null;
	return data2;
};

export const editBanner: (
	bannerData: any,
	brandId: number,
	subSectionId: number
) => Promise<unknown> = async (bannerData, brandId, subSectionId) => {
	const data = await ExecutiveSummaryService.editBannerData(bannerData);
	const data2 = await ExecutiveSummaryService.getExecutiveSummaryData(
		brandId,
		subSectionId
	);
	if (data === null || data === undefined) return null;
	return data2;
};

export const editOrder: (
	orderData: any,
	brandId: number,
	subSectionId: number,
	productId: number,
	tabId?: number
) => Promise<unknown> = async (
	orderData,
	brandId,
	subSectionId,
	productId,
	tabId
) => {
	const data = await ExecutiveSummaryService.editOrderData(
		orderData,
		productId
	);
	const data2 = await ExecutiveSummaryService.getExecutiveSummaryData(
		brandId,
		subSectionId,
		tabId
	);
	if (data === null || data === undefined) return null;
	return data2;
};

export const getSubSection: (brandId: number) => Promise<unknown> = async (
	brandId
) => {
	const data = await ExecutiveSummaryService.getSubSection(brandId);
	if (data === null || data === undefined) return null;
	return data;
};
