import styled from "styled-components";
import { colors } from "../../globalTheme";
export const AbbreviationsContainer = styled.div`
	width: 100%;
	background: ${colors.zsWhite};

	padding-bottom: 8vh;
	/* height: calc(100vh - 22%); */
	height: ${(props: { noSubtab?: boolean }) =>
		props?.noSubtab ? "calc(100vh - 16%)" : "calc(100vh - 22%)"};
	overflow-y: scroll;
	padding: 1em;
	::-webkit-scrollbar {
		display: none;
	}
`;
