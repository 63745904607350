import { User } from "@styled-icons/remix-fill/User";
import { Briefcase } from "@styled-icons/boxicons-solid/Briefcase";
import { Home7 } from "@styled-icons/remix-fill/Home7";
import { Link } from "react-router-dom";
import {
  AppHeaderText,
  HeaderIcon,
  HeaderIcons,
  HeaderIconUser,
  StyledTopHeader,
} from "./style";
import { Breadcrumb } from "antd";

/**
 * Renders the Top Header showing the Application Name, UserName, About Us page link and Homepage link.
 *
 * @returns Top Header of the App along with About Us page link and Homepage link.
 */
export default function TopHeader(props: { isLogo: boolean }) {
  return (
    <StyledTopHeader>
      <div style={{ height: "100%" }} className="flex items-center">
        {props.isLogo && (
          <img
            className="zsLogo"
            src="/logo/ZSLogoSmall.png"
            alt="ZS Logo"
            style={{
              // marginLeft: ",
              height: "2.3rem",
              marginRight: "0.5rem",
              objectFit: "cover",
            }}
          />
        )}
        <div className="">
          <AppHeaderText>Systematic Literature Review</AppHeaderText>
          <div className="mt-0.5">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/SLR">SLR</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a
                  href={
                    "/SLR/overview/" +
                    window.sessionStorage.getItem("selectedProduct")
                  }
                >
                  {window.sessionStorage.getItem("selectedProduct")}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="/SLR/ExecutiveSummary">
                  {window.sessionStorage.getItem("selectedBackgroundName")}
                </a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <HeaderIcons>
        <HeaderIcon title="About">
          <Link to="/SLR/about">
            <Briefcase size={28} />
          </Link>
        </HeaderIcon>
        <HeaderIcon
          onClick={() => window.sessionStorage.setItem("appInfo", "")}
          title="Home"
        >
          <a href="/">
            <Home7 size={28} />
          </a>
        </HeaderIcon>
        <HeaderIconUser>
          <User size={28} />{" "}
          <div>
            <p>{window.localStorage.getItem("userName") || "USERNAME"}</p>
            <a href="https://idm.dev.zsservices.com/ZSHeor/IdentityManager/app/Web/logout.aspx">
              Sign out
            </a>
          </div>
        </HeaderIconUser>
      </HeaderIcons>
    </StyledTopHeader>
  );
}
