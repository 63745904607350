import styled from "styled-components";
import { colors } from "./../../../../globalTheme";
import { Tooltip } from "antd";
export const SupportingMessageContainer = styled.div`
	border-radius: 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	background: white;
	margin-top: 1.5em;
	background-color: ${(props: { bg?: string }) =>
		props.bg ||
		"transparent"}; // Have option for transparent or other colors
`;
export const StyledMessageContainer = styled.div`
	position: relative;
	width: 102%;
	padding: 1.8em 4em;
	left: -21px;

	background-color: #27a6a4;
	border-radius: 10px;
	p {
		margin: 0;
		padding: 0;
		margin-left: 30px;
		font-weight: 700;
		font-size: 1.2rem;
		color: white;
	}
`;

export const StyledSupportingMessage = styled.div`
	/* margin: 1em 0; */
	padding: 0.5em 1em;
	width: 100%;
	/* box-shadow: 4px 4px 10px #bebebe; */
	background: white;
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
`;

export const SupportingMessageBodyText = styled.li`
	font-size: 0.8rem;
	font-weight: 400;
	color: ${colors.zsGray80};
	margin: 0.8em 0;
	padding-top: 50px;
`;
export const Actions = styled.div`
	margin: 0;
	padding: 0.8em 0 0 0;
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: center;
	color: ${colors.zsTeal};
	font-weight: 500;
	button {
		border: none;
		background: transparent;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
`;
export const StyledTooltip = styled(Tooltip)`
	vertical-align: super;
	font-size: 0.6rem;
	padding: 5px;
`;
