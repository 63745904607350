import React from "react";
import { DocumentBulletList } from "@styled-icons/fluentui-system-filled/DocumentBulletList";
import { Summarize } from "@styled-icons/material/Summarize";
import { HospitalUser } from "@styled-icons/fa-solid/HospitalUser";
import { HandHoldingMedical } from "@styled-icons/fa-solid/HandHoldingMedical";
import { InfoWithCircle } from "@styled-icons/entypo/InfoWithCircle";
import { BookLetter } from "@styled-icons/fluentui-system-filled/BookLetter";
import { FlowChart } from "@styled-icons/remix-editor/FlowChart"; //methodolgy
import { SearchAlt } from "@styled-icons/boxicons-regular/SearchAlt"; //searchdb
import { Pipette } from "@styled-icons/typicons/Pipette"; //picos
import { Prismic } from "@styled-icons/simple-icons/Prismic"; //prima
import { Assessment } from "@styled-icons/material/Assessment"; //QA
import { DataTreemap } from "@styled-icons/fluentui-system-filled/DataTreemap"; //heatmap
import { Openstreetmap } from "@styled-icons/simple-icons/Openstreetmap"; //evidence
import { TextDescription } from "@styled-icons/fluentui-system-filled/TextDescription"; //desc
import { ProtocolHandler } from "@styled-icons/fluentui-system-regular/ProtocolHandler";
import { Collection } from "@styled-icons/bootstrap/Collection";
import { NetworkChart } from "@styled-icons/boxicons-regular/NetworkChart";
import AboutUs from "../pages/AboutUs";
import MethodologyReview from "../pages/MethodologyReview";
import { Patient } from "@styled-icons/fluentui-system-filled/Patient";
import Introduction from "../pages/Introduction";
import Results from "../pages/Results";
import Conclusion from "../pages/Conclusion";
import DataCollection from "../pages/MethodologyReview/DataCollection";
import PICOS from "../pages/MethodologyReview/PICOS";
import SearchDB from "../pages/MethodologyReview/SearchDB";
import FeasibilityAsessment from "../pages/MethodologyReview/FeasibilityAsessment";
import Prisma from "../pages/Results/Prisma";
import Outcomes from "../pages/Results/Outcomes";
import StudyCharacteristics from "../pages/Results/StudyCharacteristics";
import PatientCharacteristics from "../pages/Results/PatientCharacteristics";
import QualityAssessment from "../pages/Results/QualityAssessment";
import ExecutiveSummary from "../pages/ExecutiveSummary";
import Characteristics from "../pages/Results/Characteristcs";
import Approach from "../pages/MethodologyReview/Approach";

/**
 * Defines the different routes to the pages
 *
 * @returns Routes
 */
export function Routes() {
  const routePaths = [
    // {
    //   path: "/SLR/overview/:productName",
    //   component: () => <ExecutiveSummary />,
    // },
    {
      path: "/SLR/about",
      component: () => <AboutUs />,
    },

    {
      path: "/SLR/:tab/Introduction",
      component: () => <Introduction />,
    },
    {
      path: "/SLR/:tab/Methodology",
      component: () => <MethodologyReview />,
    },
    {
      path: "/SLR/:tab/Results",
      component: () => <Results />,
    },
    {
      path: "/SLR/:tab/Conclusion",
      component: () => <Conclusion />,
    },
    {
      path: "/SLR/:tab/Methodology/Approach",
      component: () => <Approach />,
    },
    {
      path: "/SLR/:tab/Methodology/Data",
      component: () => <DataCollection />,
    },
    {
      path: "/SLR/:tab/Methodology/Eligibility",
      component: () => <PICOS />,
    },
    {
      path: "/SLR/:tab/Methodology/Search",
      component: () => <SearchDB />,
    },
    {
      path: "/SLR/:tab/Feasibility",
      component: () => <FeasibilityAsessment />,
    },
    {
      path: "/SLR/:tab/Results/Prisma",
      component: () => <Prisma />,
    },
    {
      path: "/SLR/:tab/Results/Outcomes",
      component: () => <Outcomes />,
    },
    {
      path: "/SLR/:tab/Results/Characteristics",
      component: () => <Characteristics />,
    },
    {
      path: "/SLR/:tab/Results/Quality",
      component: () => <QualityAssessment />,
    },
  ];
  return routePaths;
}

/**
 * Configures the SideBar Menu Items
 *
 * @returns SideBar Menu Items
 */
export function TabNames() {
  const tabNames: {
    name: string;
    path: string;
    subPath: string;
    icon: JSX.Element;
    subTabs?: { name: string; icon: JSX.Element; path: string }[];
  }[] = [
    {
      name: "Executive Summary",
      path: "ExecutiveSummary",
      subPath: "",
      icon: <Summarize size={"1.45rem"} />,
    },
    {
      name: "Introduction",
      path: "Introduction",
      subPath: "",
      icon: <TextDescription size={"1.45rem"} />,
    },
    {
      name: "Methodology",
      path: "Methodology",
      subPath: "Approach",
      icon: <InfoWithCircle size={"1.45rem"} />,
      subTabs: [
        {
          name: "Approach",
          path: "Approach",
          icon: <ProtocolHandler size={"1rem"} />,
        },
        {
          name: "Eligibility Criteria",
          path: "Eligibility",
          icon: <Pipette size={"1rem"} />,
        },
        {
          name: "Database Search",
          path: "Search",
          icon: <SearchAlt size={"1rem"} />,
        },
        {
          name: "Data Collection & Extraction Process",
          path: "Data",
          icon: <Collection size={"1rem"} />,
        },
        // {
        //   name: "Feasibility Assessment",
        //   path: "Feasibility",
        //   icon: <NetworkChart size={"1rem"} />,
        // },
      ],
    },
    {
      name: "Feasibility Assessment",
      path: "Feasibility",
      icon: <NetworkChart size={"1.45rem"} />,
      subPath: "",
    },
    {
      name: "Results",
      path: "Results",
      subPath: "Prisma",
      icon: <HandHoldingMedical size={"1.45rem"} />,
      subTabs: [
        { name: "PRISMA", path: "Prisma", icon: <Prismic size={"1rem"} /> },
        {
          name: "Quality Assessment Results",
          path: "Quality",
          icon: <DataTreemap size={"1rem"} />,
        },
        {
          name: "Study/Patient Characteristics",
          path: "Characteristics",
          icon: <Assessment size={"1rem"} />,
        },
        {
          name: "Outcomes",
          path: "Outcomes",
          icon: <Openstreetmap size={"1rem"} />,
        },
      ],
    },
    {
      name: "Conclusion",
      path: "Conclusion",
      subPath: "",
      icon: <HospitalUser size={"1.45rem"} />,
    },
    // { name: "Appendix", icon: <BookLetter size={30} /> },
  ];

  return tabNames;
}
