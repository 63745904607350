import * as ReportTypesComponentProps from "../../interfaces/main";
/**
 * Transform the Data from the Default Type to the type accepted by Bar Chart of Nivo.
 *
 * @param data - Raw Data
 * @param series - Series details
 * @param labelDim - Array containing the Label names
 * @returns Transformed data
 */
export default function transformData(
	data: ReportTypesComponentProps.Interface["data"],
	series: ReportTypesComponentProps.Interface["series"],
	labelDim: number
) {
	let res: any = []; //Check this type

	series.map((s, idx) => {
		return data[s.dim].map((value: number, index: number) => {
			if (idx === 0) {
				return res.push({
					id: data[labelDim][index],
					[s.name]: value,
					[s.name + "Color"]: s.color,
				});
			} else {
				res[index][s.name] = value;
				res[index][s.name + "Color"] = s.color;
				return res;
			}
		});
	});

	return res;
}
