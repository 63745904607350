import { Menu } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
// import DataCollection from "../components/MethodologyReview/DataCollection";
// import FeasibilityAsessment from "../components/MethodologyReview/FeasibilityAsessment";
// import PICOS from "../components/MethodologyReview/Picos/PICOS";
// import SearchDB from "../components/MethodologyReview/SearchDB";

export default function MethodologyReview() {
  const [keySelected, setKeySelected] = useState("DataCollection");
  const history = useHistory();

  function handleMenuClick(e: any) {
    // let background = window.sessionStorage.getItem("selectedBackground");
    // let url = background + "/" + e.key;
    history.push(
      "/" +
        window.sessionStorage.getItem("selectedBackground") +
        "/MethodologyReview/" +
        e.key
    );
    setKeySelected(e.key);
  }

  return (
    <div>
      {/* <div>
        <Menu
          onClick={handleMenuClick}
          mode="horizontal"
          defaultSelectedKeys={["DataCollection"]}
        >
          <Menu.Item key="DataCollection">
            Data Collection/Extraction Process
          </Menu.Item>
          <Menu.Item key="Eligibility">Eligibility Criteria</Menu.Item>
          <Menu.Item key="Search">Database Search</Menu.Item>
          <Menu.Item key="Feasibility">Feasibility Assessment</Menu.Item>
        </Menu>
      </div> */}
      {/* {keySelected === "data" ? (
        <DataCollection />
      ) : keySelected === "eligibility" ? (
        <PICOS />
      ) : keySelected === "search" ? (
        <SearchDB />
      ) : keySelected === "feasibility" ? (
        <FeasibilityAsessment />
      ) : (
        ""
      )} */}
    </div>
  );
}
