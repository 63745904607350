import { User } from "@styled-icons/remix-fill/User";
import { Briefcase } from "@styled-icons/boxicons-solid/Briefcase";
import { Home7 } from "@styled-icons/remix-fill/Home7";
import { Grid } from "@styled-icons/remix-fill/Grid";

import { Link } from "react-router-dom";
import AppContext from "../../store";
import React from "react";
import {
  AppHeaderText,
  HeaderIcon,
  HeaderIcons,
  HeaderIconUser,
  StyledTopHeader,
} from "./style";

/**
 * Renders the Top Header showing the Application Name, UserName, About Us page link and Homepage link.
 *
 * @returns Top Header of the App along with About Us page link and Homepage link.
 */
export default function TopHeader() {
  const context = React.useContext(AppContext);
  return (
    <StyledTopHeader>
      <AppHeaderText>Global Value Dossiers</AppHeaderText>
      <HeaderIcons>
        <HeaderIconUser>
          <User size={32} />{" "}
          <div>
            <p>{context.userId}</p>
            <a href="https://idm.dev.zsservices.com/ZSHeor/IdentityManager/app/Web/logout.aspx">
              Sign out
            </a>
          </div>
        </HeaderIconUser>
        <HeaderIcon title="About">
          <Link to="/about">
            <Briefcase size={28} />
          </Link>
        </HeaderIcon>
        {/* <HeaderIcon title="Home">
					<Link to="/">
						<Home2 size={28} />
					</Link>
				</HeaderIcon> */}
        <HeaderIcon title="Product Selection">
          <Link to="/GVD">
            <Grid size={28} />
          </Link>
        </HeaderIcon>
        <HeaderIcon
          onClick={() => window.sessionStorage.setItem("appInfo", "")}
          title="Home"
        >
          <a href="/">
            <Home7 size={28} />
          </a>
        </HeaderIcon>
      </HeaderIcons>
    </StyledTopHeader>
  );
}
