import { Carousel, Drawer, Popover, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import ReactMarkdown from "react-markdown";
import { Button, message, Modal } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  EditFilled,
  InfoCircleOutlined,
  SaveOutlined,
  CloseOutlined,
  FileOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import SimpleMdeReact from "react-simplemde-editor";
import "./style.css";
import { request } from "../layout/SLR_Layout";
import { TextBulletListSquare } from "@styled-icons/fluentui-system-regular/TextBulletListSquare";
import BeatLoader from "react-spinners/BeatLoader";
import introductionPicture from "./../pictures/introductionPicture.png";
import introductionPicture2 from "./../pictures/introductionPicture2.png";
import introductionPicture3 from "./../pictures/introductionPicture3.png";
import introductionPicture4 from "./../pictures/introductionPicture4.png";
import { useHistory } from "react-router-dom";

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: "80%",
  width: "100%",
  color: "black",
  lineHeight: "65vh",
  textAlign: "center",
  background: "#a3bbe8",
  border: "4px solid black",
};

export default function Introduction() {
  const [inEditMode, setInEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialData, setInitialData] = useState("");
  const [data, setData] = useState("");
  const [imageList, setImageList] = useState<any>([]);
  const [screenInfo, setScreenInfo] = useState<any>(
    "This section provides introduction and context to this systematic literature review"
  );
  const [open, setOpen] = useState(false);
  const [footnote, setFootnote] = useState<any>("");
  const [footnoteInitial, setFootnoteInitial] = useState<any>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inEditModeFootnote, setInEditModeFootnote] = useState(false);
  const [isLoadingFootnote, setIsLoadingFootnote] = useState(true);

  const props: UploadProps = {
    // action:"",
    beforeUpload: (file) => {
      const isPNG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      if (!isPNG) {
        message.error(`${file.name} is not a valid image file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      console.log(info.fileList);
      setImageList([...info.fileList]);
    },
  };

  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "100%",
      maxHeight: "100%",
    };
  }, []);
  const navigate = useHistory();

  useEffect(() => {
    //check for redirection
    if (
      window.sessionStorage.getItem("selectedBackgroundName") === undefined ||
      window.sessionStorage.getItem("selectedBackgroundName") === null ||
      window.sessionStorage.getItem("selectedBackgroundName") === "" ||
      window.sessionStorage.getItem("productReviewId") === undefined ||
      window.sessionStorage.getItem("productReviewId") === null ||
      window.sessionStorage.getItem("productReviewId") === "" ||
      window.localStorage.getItem("productId") === undefined ||
      window.localStorage.getItem("productId") === null ||
      window.localStorage.getItem("productId") === ""
    ) {
      navigate.push("/SLR");
      return;
    }
    setIsLoading(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    request
      .get("/GetIntroduction?productReviewId=" + productReviewId)
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (!res.data.description) setData("");
          else setData(res.data.description);
          setInitialData(res.data.description);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };

  function saveHandler() {
    console.log("data after save", data);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    message.loading("Saving Changes!", 0.8).then(() => {
      request
        .post("/EditIntroduction?productReviewId=" + productReviewId, {
          description: data,
        })
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            message.success("Saved!");
            setInitialData(data);
          } else {
            message.error("Error!");
            setData(initialData);
          }
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });

    setInEditMode(false);
  }

  function saveHandlerFootnote() {
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Introduction"];
    message.loading("Saving Changes!", 0.8).then(() => {
      request
        .post(
          "/EditFootNote?productReviewId=" +
            productReviewId +
            "&id=" +
            sectionId,
          {
            footNote: footnote,
          }
        )
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            message.success("Saved!");
            setFootnoteInitial(footnote);
          } else {
            message.error("Error!");
            setFootnote(footnoteInitial);
          }
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
    setInEditModeFootnote(false);
  }

  function showDrawerFootnote() {
    setIsLoadingFootnote(true);

    setDrawerOpen(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Introduction"];
    request
      .get(
        "/GetFootNote?productReviewId=" + productReviewId + "&id=" + sectionId
      )
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (!res.data.footNote) setFootnote("");
          else setFootnote(res.data.footNote);
          setFootnoteInitial(res.data.footNote);
          setIsLoadingFootnote(false);
        } else {
          setIsLoadingFootnote(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoadingFootnote(false);
        console.log(err);
      });
  }

  function onSaveHoverData() {
    setOpen(false);
  }

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <div style={{ overflow: "hidden", position: "relative", height: "100%" }}>
      <div className="flex px-4 py-2 bg-white border-b-2">
        <div className="flex w-1/2 justify-start items-center text-xl font-semibold">
          {/* <Popover
            placement="bottomLeft"
            // visible={open}
            // onVisibleChange={handleOpenChange}
            style={{ top: "4rem" }}
            overlayStyle={{
              minHeight: "2rem",
              maxWidth: "40rem",
            }}
            content={
              <div className="text-xs">
                <p>{screenInfo}</p>
              </div>
            }
            trigger={"hover"}
          >
            <InfoCircleOutlined
              style={{
                marginRight: "0.6rem",
                fontSize: "1.1rem",
                marginTop: "0.2rem",
              }}
            />
          </Popover> */}
          Introduction
        </div>

        <div className="flex w-1/2 justify-end items-center">
          {window.sessionStorage.getItem("isAdmin") === "2" && (
            <div className="flex justify-end items-center">
              {inEditMode ? (
                <div>
                  <Button type="default" onClick={saveHandler}>
                    <SaveOutlined />
                    Save
                  </Button>
                  <Button
                    className="ml-2"
                    type="default"
                    onClick={() => {
                      setData(initialData);
                      setInEditMode(false);
                    }}
                  >
                    <CloseOutlined />
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button type="default" onClick={() => setInEditMode(true)}>
                  <EditFilled />
                  Edit
                </Button>
              )}
            </div>
          )}
        </div>
        <TextBulletListSquare
          size={30}
          style={{
            marginLeft: "0.6rem",
            color: "#27a6a4",
            cursor: "pointer",
          }}
          onClick={showDrawerFootnote}
          title="Open Footnotes"
        />
      </div>

      <div>
        {isLoading ? (
          <div
            style={{ minHeight: "80vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : (data === "" || data === undefined || data === null) &&
          !inEditMode ? (
          <div
            className="mt-4 mx-4 bg-white rounded-sm shadow-md flex items-center justify-center"
            style={{ minHeight: "80vh", overflowY: "auto" }}
          >
            <div
              className="m-4 bg-white border-2 rounded-sm flex items-center justify-center w-full"
              style={{ minHeight: "75vh", overflowY: "auto", width: "60%" }}
            >
              {window.sessionStorage.getItem("isAdmin") === "2" ? (
                <div className="text-center">
                  <EditFilled style={{ color: "#27a6a4", fontSize: "3rem" }} />
                  <p className="text-xl mt-2 italic">
                    Click on Edit to add details!
                  </p>
                </div>
              ) : (
                <div className="text-center">
                  <FileOutlined
                    style={{ color: "#27a6a4", fontSize: "3rem" }}
                  />
                  <p className="text-xl mt-2 italic">No Details Added!</p>
                </div>
              )}
            </div>
            <div
              className="ml-2 py-8 px-2 introCarousel"
              style={{ width: "40%", height: "74vh" }}
            >
              <Carousel
                style={{ height: "60vh" }}
                autoplay
                afterChange={onChange}
              >
                {/* <div className="flex items-center justify-center w-full border-2">
                  <img src={introductionPicture}></img>
                </div> */}
                <div className="flex items-center justify-center w-full border-2">
                  <img src={introductionPicture2}></img>
                </div>
                <div className="flex items-center justify-center w-full border-2">
                  <img src={introductionPicture3}></img>
                </div>
                <div className="flex items-center justify-center w-full border-2">
                  <img src={introductionPicture4}></img>
                </div>
              </Carousel>
            </div>
          </div>
        ) : (
          <div className="flex items-center bg-white rounded-sm shadow-md m-4 p-4">
            {inEditMode ? (
              <div
                className="border-r"
                style={{ minHeight: "77vh", overflowY: "auto", width: "60%" }}
              >
                <div id="introEditor" className="">
                  <SimpleMdeReact
                    options={options}
                    value={data}
                    onChange={(e) => {
                      let temp = data;
                      temp = String(e);
                      setData(temp);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                className="border-r"
                style={{
                  minHeight: "77vh",
                  maxHeight: "77vh",
                  overflowY: "auto",
                  width: "60%",
                }}
              >
                <div id="introEditor" className="py-4 px-4">
                  <ReactMarkdown children={data}></ReactMarkdown>
                </div>
              </div>
            )}
            <div
              className="ml-2 py-8 px-2 introCarousel"
              style={{ width: "40%", height: "74vh" }}
            >
              <Carousel
                style={{ height: "60vh" }}
                autoplay
                afterChange={onChange}
              >
                {/* <div className="flex items-center justify-center w-full border-2">
                  <img src={introductionPicture}></img>
                </div> */}
                <div className="flex items-center justify-center w-full border-2">
                  <img src={introductionPicture2}></img>
                </div>
                <div className="flex items-center justify-center w-full border-2">
                  <img src={introductionPicture3}></img>
                </div>
                <div className="flex items-center justify-center w-full border-2">
                  <img src={introductionPicture4}></img>
                </div>
              </Carousel>
            </div>
          </div>
        )}
      </div>
      <Drawer
        title={
          <div className="flex">
            <p className="text-black mb-0 w-1/2">Footnotes</p>
            <div className="flex w-1/2 justify-end items-center">
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <div className="flex w-full justify-end items-center">
                  {inEditModeFootnote ? (
                    <div className="flex">
                      <Tooltip title="Save Changes">
                        <SaveOutlined
                          onClick={saveHandlerFootnote}
                          style={{ color: "black", fontSize: "1.2rem" }}
                        />
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <UndoOutlined
                          onClick={() => {
                            setInEditModeFootnote(false);
                            setFootnote(footnoteInitial);
                          }}
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            marginLeft: "0.6rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Edit Footnotes">
                      <EditFilled
                        onClick={() => setInEditModeFootnote(true)}
                        style={{ color: "black", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Close">
                    <CloseOutlined
                      onClick={() => {
                        setDrawerOpen(false);
                        setInEditModeFootnote(false);
                      }}
                      style={{
                        color: "black",
                        fontSize: "1.2rem",
                        marginLeft: "0.6rem",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        }
        closable={false}
        placement="bottom"
        onClose={() => {
          setDrawerOpen(false);
          setInEditModeFootnote(false);
        }}
        getContainer={false}
        visible={drawerOpen}
        style={{ position: "absolute" }}
        zIndex={20}
        bodyStyle={{ padding: "0.5rem" }}
        headerStyle={{
          paddingTop: "0.6rem",
          paddingBottom: "0.6rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "whitesmoke",
          color: "black",
        }}
      >
        {isLoadingFootnote ? (
          <div
            style={{ minHeight: "23vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : footnote === "" && !inEditModeFootnote ? (
          <div
            className="border flex items-center justify-center"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Edit icon to add footnotes!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">No Footnotes Added!</p>
              </div>
            )}
          </div>
        ) : inEditModeFootnote ? (
          <div
            className="border-r"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            <div id="introEditor" className="">
              <SimpleMdeReact
                options={options}
                value={footnote}
                onChange={(e) => {
                  let temp = footnote;
                  temp = String(e);
                  setFootnote(temp);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="border-r"
            style={{
              minHeight: "23vh",
              maxHeight: "23vh",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div id="SLRMDEditor" className="py-4 px-4">
              <ReactMarkdown children={footnote}></ReactMarkdown>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
