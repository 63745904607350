import styled from "styled-components";
import { colors } from "./../../../../globalTheme";
export const SubtabContainer = styled.div`
	position: relative;
	/* display: grid;
	grid-template-columns: 70% 30%; */
	width: 100%;
	background-color: ${colors.zsWhite};
	padding: 0.5em 0;
	margin: auto;
	border-bottom: 2px solid #b8b8b8;
	display: flex;
`;
export const SubtabLeft = styled.div`
	/* flex: 0 0 80%; */
	width: ${(props: { showRight: any }) =>
		props.showRight === "" ? "100%" : "70%"};
	display: flex;
	flex-wrap: wrap;
	gap: 8px 0;

	justify-content: left;
	white-space: nowrap;
	.default {
		font-size: 0.9rem;
		font-weight: 600;
		color: ${colors.zsGray80};
		&:hover {
			color: ${colors.zsTeal};
		}
	}
	.non-default {
		font-size: 0.9rem;
		font-weight: 600;
		color: ${colors.zsDataPurple};
		&:hover {
			color: ${colors.zsTeal};
		}
	}
	.active {
		color: ${colors.zsTeal};
		padding-bottom: 0.7em;
		border-bottom: 4px solid rgb(39, 166, 164) !important;
	}
`;
export const SubtabElement = styled.div`
	position: relative;
	padding: 0.5em 1em;
`;
export const StyledBorder = styled.div`
	position: absolute;
	background: #b8b8b8aa;
	height: 1px;
	width: 100%;
	top: 44px;
`;
export const SubtabRight = styled.div`
	flex: 1;
	background-color: ${colors.zsWhite};
	display: flex;
	justify-content: right;
	a {
		font-size: 0.9rem;
		font-weight: 600;
		color: ${colors.zsTeal};
		text-transform: capitalize;
	}
`;
