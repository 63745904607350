import { Menu } from "antd";
import { useState } from "react";
// import Heatmap from "../components/Results/Heatmap";
// import PatientCharacteristics from "../components/Results/PatientCharacteristics";
// import Prisma from "../components/Results/Prisma";
// import QualityAssessment from "../components/Results/QualityAssessment";
// import StudyCharacteristics from "../components/Results/StudyCharacteristics";

export default function Results() {
  const [keySelected, setKeySelected] = useState("data");

  function handleMenuClick(e: any) {
    setKeySelected(e.key);
  }

  return (
    <div>
      {/* <div>
        <Menu
          onClick={handleMenuClick}
          mode="horizontal"
          defaultSelectedKeys={["prisma"]}
        >
          <Menu.Item key="prisma">PRISMA</Menu.Item>
          <Menu.Item key="quality">Quality Assessment Results </Menu.Item>
          <Menu.Item key="study">Study Characteristics</Menu.Item>
          <Menu.Item key="patient">Patient Characteristics</Menu.Item>
          <Menu.Item key="outcomes">Outcomes</Menu.Item>
        </Menu>
      </div> */}
      {/* {keySelected === "prisma" ? (
        <Prisma />
      ) : keySelected === "quality" ? (
        <QualityAssessment />
      ) : keySelected === "study" ? (
        <StudyCharacteristics />
      ) : keySelected === "patient" ? (
        <PatientCharacteristics />
      ) : keySelected === "outcomes" ? (
        <Heatmap />
      ) : (
        ""
      )} */}
    </div>
  );
}
