export enum hoverTarget {
	cell = "cell",
	row = "row",
	column = "column",
	rowColumn = "rowColumn",
}

/**
 * Main Interface for Chart Config of Table Component
 */
export interface Interface {
	forceSquare?: boolean;
	intraction?: {
		cellSizeVariation?: number;
		hoverHighlight?: hoverTarget;
		cellHoverOpacity?: number;
		cellHoverOtherOpacity?: number;
	};

	cellPadding?: number;
	cellShape?: "rect" | "circle" | any; //check here
	minHeatValue?: number;
	maxHeatValue?: number;
	grid?: {
		enableGridX?: boolean;
		enableGridY?: boolean;
	};
	heatColorArray?: string[];
	tableData?: any;
	rows?: number;
}
