import BeatLoader from "react-spinners/BeatLoader";
import { Link, useHistory } from "react-router-dom";
import TopHeader from "../layout/components/topHeader";
import {
  PlusCircleOutlined,
  RightCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, message, Modal, Row } from "antd";
import {
  StyledHeaderContainer,
  StyledHeader,
  LandingContainerBackground,
  StyledHeaderContainerBackground,
  BackgroundHeaderLink,
} from "./style";
import { UserAdd } from "@styled-icons/typicons/UserAdd";
import { LogOut } from "@styled-icons/boxicons-regular/LogOut";
import { Home7 } from "@styled-icons/remix-fill/Home7";
import { useEffect, useState } from "react";
import { Edit2Outline } from "@styled-icons/evaicons-outline/Edit2Outline";
import FormField1 from "../components/Review/formField1";
import { request } from "../layout/SLR_Layout";

export default function Background(props: {
  setSelectedBackground: any;
  tabNames: {
    name: string;
    icon: JSX.Element;
    subTabs?: { name: string; icon: JSX.Element }[];
  }[];
}) {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [modalText, setModalText] = useState(<></>);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cards, setCards] = useState<any>([]);
  const [addReviewModalVisible, setAddReviewModalVisible] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const productId = window.sessionStorage.getItem("selectedProductId");
    request
      .get("/GetProductReview?productId=" + String(productId))
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          res.data.map((item: any) => {
            if (item.reviewName === null) {
              item.reviewName = "";
            }
            item["navName"] = item.reviewName.split(" ").join("");
          });
          console.log(res.data);
          res.data.sort((a: any, b: any) =>
            String(a.id).localeCompare(String(b.id))
          );
          setCards([...res.data]);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOk = () => {
    setModalText(<p>Creating...</p>);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1500);
  };

  const handleCancel = () => {
    setVisible(false);
    setAddReviewModalVisible(false);
  };

  const showModalEdit = (id: number) => {
    const editData = cards.find((card: any) => card.id === id);
    setModalText(
      <FormField1
        handleEdit={handleEdit}
        editData={editData}
        closeModal={handleCancel}
      />
    );
    setVisible(true);
  };

  async function handleEdit(value: any, id: number) {
    console.log("value", value, id);
    value.id = id;
    const productId = window.sessionStorage.getItem("selectedProductId");
    message.loading("Saving changes!", 2);
    request
      .post("/EditProductReview?productId=" + String(productId), value)
      .then((res: any) => {
        if (res.status === 200) {
          message.success("Saved!");
          let temp = [...cards];
          temp.find((card: any) => {
            if (card.id === id) {
              card.reviewName = value.reviewName;
              card.description = value.description;
              card.navName = value.reviewName.split(" ").join("");
            }
          });
          setCards([...temp]);
          console.log("cards", temp);
        } else message.error("Error!");
      })
      .catch((err) => {
        message.error("Error!");
        console.log(err);
      });
  }

  return (
    <div>
      <LandingContainerBackground>
        <div
          style={{
            padding: "1.35rem 1.2rem",
          }}
          className="flex items-center backDiv"
          onClick={() => history.goBack()}
        >
          <ArrowLeftOutlined
            style={{
              color: "whitesmoke",
              fontSize: "1.2rem",
              cursor: "pointer",
            }}
          />
          <span
            className="ml-2 cursor-pointer"
            style={{ fontSize: "16px", color: "whitesmoke" }}
          >
            Back
          </span>
        </div>
        <div
          style={{
            marginTop: "0.6rem",
            position: "absolute",
            right: "20px",
          }}
          className="flex justify-end items-end"
        >
          <a
            style={{
              // background: "rgb(25,28,39)",
              padding: "0.8rem 0.6rem",
            }}
            href={process.env.REACT_APP_ADD_USER_URL}
            target="_blank"
          >
            <UserAdd
              style={{
                marginBottom: "0.3em",
                marginRight: "0.3em",
              }}
              size={26}
            />
            <span style={{ fontSize: "16px" }}>Create User</span>
          </a>
          <a
            style={{
              // background: "rgb(25,28,39)",
              padding: "0.8rem 0.6rem",
            }}
            onClick={() => {
              window.sessionStorage.setItem("appInfo", "");
            }}
            href={"/"}
            // target="_blank"
          >
            <Home7
              style={{
                marginBottom: "0.3em",
                marginRight: "0.3em",
              }}
              size={26}
            />
            <span style={{ fontSize: "16px" }}>Home</span>
          </a>
          <a
            style={{
              // background: "rgb(25,28,39)",
              padding: "0.8rem 0.6rem",
            }}
            href={process.env.REACT_APP_SIGNOUT_URL}
          >
            <LogOut
              style={{
                marginBottom: "0.3em",
                marginRight: "0.3em",
              }}
              size={26}
            />
            <span className="text-white" style={{ fontSize: "16px" }}>
              {window.localStorage.getItem("userName")}
            </span>
          </a>
        </div>
      </LandingContainerBackground>
      <StyledHeaderContainerBackground>
        <StyledHeader>Systematic Literature Review</StyledHeader>
      </StyledHeaderContainerBackground>
      <div className="flex items-center justify-center">
        <p
          className={`text-white text-center text-4xl mb-0 uppercase tracking-widest ${
            window.innerWidth < 1250 ? "mt-8" : ""
          }`}
        >
          Review Type
        </p>
      </div>
      <div>
        {isLoading ? (
          <div
            style={{ minHeight: "60vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : (
          <div
            className="mt-12 px-12"
            style={{
              overflowY: "auto",
              height: "59vh",
              paddingBottom: "2rem",
            }}
          >
            <Row>
              {cards &&
                cards.map((card: any) => {
                  return (
                    <Col span={8}>
                      <div
                        style={{
                          minHeight: "11rem",
                          minWidth: "18rem",
                          maxWidth: "24rem",
                        }}
                        className="bg-white  mx-4 rounded-md shadow-md"
                      >
                        <div
                          style={
                            card.disabled
                              ? { backgroundColor: "slategrey" }
                              : { backgroundColor: "teal" }
                          }
                          className={`px-2 py-2 flex justify-start items-center border-b-2 rounded-md cursor-pointer1 `}
                        >
                          <div
                            onClick={() => {
                              props.setSelectedBackground(card.navName);
                              window.sessionStorage.setItem(
                                "selectedBackground",
                                card.navName
                              );
                              window.sessionStorage.setItem(
                                "selectedBackgroundName",
                                card.reviewName
                              );
                              window.sessionStorage.setItem(
                                "productReviewId",
                                card.id
                              );
                            }}
                            className={`text-xl ml-2 w-4/5 font-bold ${
                              card.disabled ? "text-white" : "text-white"
                            }`}
                          >
                            <BackgroundHeaderLink>
                              <Link
                                to="/SLR/ExecutiveSummary"
                                style={
                                  card.disabled ? { pointerEvents: "none" } : {}
                                }
                              >
                                {card.reviewName}
                              </Link>
                            </BackgroundHeaderLink>
                          </div>

                          <div className="flex items-center justify-end w-1/5 pr-4">
                            <Link
                              to="/SLR/ExecutiveSummary"
                              style={
                                card.disabled ? { pointerEvents: "none" } : {}
                              }
                            >
                              <RightCircleOutlined
                                onClick={() => {
                                  props.setSelectedBackground(card.navName);
                                  window.sessionStorage.setItem(
                                    "selectedBackground",
                                    card.navName
                                  );
                                  window.sessionStorage.setItem(
                                    "selectedBackgroundName",
                                    card.reviewName
                                  );
                                  window.sessionStorage.setItem(
                                    "productReviewId",
                                    card.id
                                  );
                                }}
                                style={
                                  card.disabled
                                    ? {
                                        fontSize: "1.3rem",
                                        color: "whitesmoke",
                                      }
                                    : {
                                        fontSize: "1.3rem",
                                        color: "whitesmoke",
                                      }
                                }
                              />
                            </Link>
                            {window.sessionStorage.getItem("isAdmin") === "2" &&
                              !card.disabled && (
                                <Edit2Outline
                                  title="Edit Product"
                                  className="icon"
                                  size={26}
                                  style={
                                    card.disabled
                                      ? {
                                          marginLeft: "0.2rem",
                                          color: "whitesmoke",
                                        }
                                      : {
                                          marginLeft: "0.2rem",
                                          color: "whitesmoke",
                                          cursor: "pointer",
                                        }
                                  }
                                  onClick={() => {
                                    card.disabled
                                      ? console.log()
                                      : showModalEdit(card.id);
                                  }}
                                />
                              )}
                          </div>
                        </div>

                        <div className="flex py-2 justify-left px-4 mt-2 text-left">
                          {card.description}
                        </div>
                        {card.disabled && (
                          <div className="mt-2 flex justify-end px-4 pb-2 text-gray-400 text-sm italic text-right w-full">
                            Coming Soon
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
        )}
      </div>
      <Modal
        destroyOnClose={true}
        title={"Edit Review Type"}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <p>{modalText}</p>
      </Modal>
      <Modal
        destroyOnClose={true}
        title={"Edit Review Type"}
        visible={addReviewModalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <p>{modalText}</p>
      </Modal>
    </div>
  );
}
