import { Col, Row } from "antd";
import { colors } from "../../../globalTheme";
import {
  HeaderRibbon,
  TileBody,
  TileContainer,
  TileHeader,
  TileHeaderText,
} from "../../../../libraries/tile/style";
import pic1 from "../Pictures/executive.png";
import pic2 from "../Pictures/executive2.png";
import pic3 from "../Pictures/introduction.png";
import pic4 from "../Pictures/approach.png";
import pic5 from "../Pictures/eligibility.png";
import pic6 from "../Pictures/searchDB.png";
import pic7 from "../Pictures/dataCollection.png";
import pic8 from "../Pictures/prisma.png";
import pic9 from "../Pictures/quality.png";
import pic10 from "../Pictures/evidenceAtlas.png";
import pic11 from "../Pictures/study.png";
import pic12 from "../Pictures/patient.png";
import pic13 from "../Pictures/outcomes.png";
import pic14 from "../Pictures/dashboard.png";
import pic15 from "../Pictures/studiesIncluded.png";
import pic16 from "../Pictures/conclusion.png";

const data = [
  {
    header: "Executive Summary (homepage)",
    details: [
      {
        text: "The executive summary page includes key take-home messages pulled from each subsequent section of the dSLR. Click on the green arrows inside the tiles to proceed to corresponding sections for more details.",
        imageIndex: 0,
      },
      {
        text: "The navigation bar on the left side of the screen contains the key sections comprising the dSLR. Click on the gray arrow near the logo on the top left side of the window to expand or close the navigation bar. ",
        imageIndex: 1,
      },
    ],
  },
  // {
  //   header:
  //     "Disease Overview, Current Treatment Landscape, Burden of Illness & Unmet Need, Product Information, Economic Value, and Market Access",
  //   details: [
  //     {
  //       text: "Each section includes comprehensively referenced key value messages with layered supporting information. Further subsections can be navigated to using the tabs across the top of the window, or by clicking the green arrows inside the tiles. ",
  //       imageIndex: 2,
  //     },
  //     {
  //       text: "Within each subsection, each key message is highlighted in green within its own tile. Click on the box with the arrow to navigate to further supporting information associated with the message. ",
  //       imageIndex: 3,
  //     },
  //     {
  //       text: "On any page, hover over the superscript enclosed in brackets to reveal the reference. If available, you will also see a hyperlink that takes you directly to the URL. ",
  //       imageIndex: 4,
  //     },
  //   ],
  // },
];
const data2 = [
  {
    header: "Introduction",
    details: [
      {
        header: "",
        text: "This section provides brief introduction to the study ",
        imageIndex: 2,
      },
    ],
  },
  {
    header: "Methodology",
    details: [
      {
        header: "Approach",
        text: "Provides workflow of the approach followed for conducting the SLR.",
        imageIndex: 3,
      },
      {
        header: "Eligibility Criterion",
        text: "This includes section for inclusion and exclusion criteria. Sections can be navigated by clicking on the tabs. Within each subsection, criteria are represented based on PICOS (Population, Intervention, Comparator, Outcome, Study) and other eligibility criteria.",
        imageIndex: 4,
      },
      {
        header: "Database Search",
        text: "This section includes tabs for viewing database search conducted for All databases, PubMed, Embase.com, Cochrane and any other databases that were searched. Click on a particular pie-chart section to view records from that database.",
        imageIndex: 5,
      },
      {
        header: "Data Collection & Extraction Process",
        text: "Provides workflow of data collection and extraction process.",
        imageIndex: 6,
      },
    ],
  },
  {
    header: "Results",
    details: [
      {
        header: "PRISMA",
        text: "Click on the tabs to navigate through PRISMA flow diagram and individual included studies.",
        imageIndex: 7,
      },
      {
        header: "Studies Included",
        text: "CLick on Studies Included tab to add studies.",
        imageIndex: 8,
      },
      {
        header: "Quality Assessment",
        text: "This section shows tools that were utilized for assessing risk of bias. Hover on overall risk of bias graph to view low risk, some concerns, and high-risk percentages for each domain.",
        imageIndex: 9,
      },
      {
        header: "Evidence Atlas",
        text: "Click on the tabs to navigate through the subsections. In Evidence Atlas, hover over the map to view the number of studies included from a geography. ",
        imageIndex: 10,
      },
      {
        header: "Study/Patient Characteristics",
        text: "Click on select intervention dropdown menu to view intervention specific results. Click on plot by or select country drop down menu to change the view of bar graph and select dropdown menu above pie chart to change the view based on different patient characteristics.",
        imageIndex: 11,
      },
      {
        header: "Outcomes",
        text: "This section provides overall view of outcomes. Hover over boxes for efficacy outcomes, safety outcomes, tolerability outcomes to view the significance of the outcomes reported for each outcome. Click on any outcome to view a forest plot for that particular outcome.",
        imageIndex: 12,
      },
    ],
  },
  {
    header: "Conclusion",
    details: [
      {
        header: "",
        text: "Provides conclusion of the results of SLR",
        imageIndex: 13,
      },
    ],
  },
];
const imgArray = [
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic15,
  pic9,
  pic10,
  pic14,
  pic13,
  pic16,
];
export default function PicTile() {
  return (
    <>
      <TileContainer>
        <TileHeader>
          <TileHeaderText>
            Navigation and functionality of the dSLR
          </TileHeaderText>
          <HeaderRibbon />
        </TileHeader>
        <TileBody>
          {data.map((group) => {
            return (
              <div style={{ width: "100%", margin: "2em 1em" }}>
                <div
                  style={{
                    borderBottom: `1px solid ${colors.zsGray20}`,
                    padding: "1em 0",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: 500,
                      color: colors.zsTeal,
                    }}
                  >
                    {group.header}
                  </h3>
                </div>

                <div style={{ padding: "2em 1em" }}>
                  <Row gutter={[10, 40]} align="top">
                    {group.details.map((points) => {
                      return (
                        <>
                          <Col
                            span={7}
                            style={{
                              fontSize: "1rem",
                            }}
                          >
                            {points.text}
                          </Col>
                          <Col offset={1} span={16}>
                            <div
                              style={{
                                // width: "60%",
                                // margin: "1em auto",
                                // marginBottom:
                                // 	"2em",
                                border: "2px solid black",
                              }}
                            >
                              <img src={imgArray[points.imageIndex]} />
                            </div>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                </div>
              </div>
            );
          })}
          {data2.map((group) => {
            return (
              <div style={{ width: "100%", margin: "2em 1em" }}>
                <div
                  style={{
                    borderBottom: `1px solid ${colors.zsGray20}`,
                    padding: "1em 0",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: 500,
                      color: colors.zsTeal,
                    }}
                  >
                    {group.header}
                  </h3>
                </div>

                <div style={{ padding: "2em 1em" }}>
                  <Row gutter={[10, 40]} align="top">
                    {group.details.map((points) => {
                      return (
                        <>
                          <Col
                            span={points.imageIndex === -1 ? 24 : 7}
                            style={{
                              fontSize: "1rem",
                            }}
                          >
                            <p className="font-bold mb-0">{points.header}</p>
                            {points.text}
                          </Col>
                          <Col
                            offset={1}
                            span={points.imageIndex === -1 ? 0 : 16}
                          >
                            <div
                              style={{
                                // width: "60%",
                                // margin: "1em auto",
                                // marginBottom:
                                // 	"2em",
                                border: "2px solid black",
                              }}
                            >
                              <img src={imgArray[points.imageIndex]} />
                            </div>
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                </div>
              </div>
            );
          })}
        </TileBody>
      </TileContainer>
    </>
  );
}
