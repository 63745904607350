import { Upcoming } from "@styled-icons/material-outlined/Upcoming";

export default function FeasibilityAsessment() {
  return (
    <div className="">
      <div className="flex items-center px-4 py-2 bg-white">
        <p className="w-1/2 text-lg font-semibold pl-1">
          Feasibility Asessment
        </p>
      </div>
      <div className="m-4 bg-white shadow-md rounded-sm">
        <div
          className="flex items-center justify-center"
          style={{ minHeight: "78vh" }}
        >
          <div className="">
            <Upcoming size={56} color="lightgray" className="mx-8" />
            <p className="text-xl text-gray-500">Coming Soon</p>
          </div>
        </div>
      </div>
    </div>
  );
}
