import React from "react";
import AppContext from "../../store/index";
import "../../layout/components//body-layout/Subtabs/Subtabs.css";
import PreparingForObjections from "./PreparingForObjections";
import ObjectionHandlerOverview from "./ObjectionHandlerOverview";
import Subtabs from "../../layout/components/body-layout/Subtabs/Subtabs";
import { Tabs } from "antd";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  Redirect,
} from "react-router-dom";
import BodyCardLayout from "../../layout/components/body-layout";
import SupportingMessage1 from "../SupportingMessages/SupportingMessage1";
export default function ObjectionHandler() {
  const context = React.useContext(AppContext);
  const [showReturn, setShowReturn] = React.useState("");
  React.useEffect(() => {
    context.setView(8);
  });
  // let matchLink = useRouteMatch();
  const subtabOptions = [
    {
      name: "Preparing For Objections",
      link: "preparing-for-objections",
      isDefault: true,
    },

    {
      name: "Objection Handler",
      link: "objection-handler/tabs/1",
      isDefault: true,
    },
  ];
  const TabPane = Tabs.TabPane;
  const subSectionId: number =
    context.subSectionMapping
      .find((section) => section.name === "Objection Handler")
      ?.subSection.find((subSection) => subSection.name === "Objection Handler")
      ?.id || 0;
  const sectionId: number =
    context.subSectionMapping
      .find((section) => section.name === "Objection Handler")
      ?.subSection.find((subSection) => subSection.name === "Objection Handler")
      ?.sectionId || 0;
  return (
    <>
      <Subtabs subtabOptions={subtabOptions} showRight={showReturn} />

      <Switch>
        <Redirect
          exact
          from="/objection"
          to="/objection/preparing-for-objections"
        />

        <Route path="/objection/preparing-for-objections">
          <PreparingForObjections showReturn={setShowReturn} />
        </Route>

        <Route path="/objection/objection-handler/tabs/:tabID">
          <ObjectionHandlerOverview showReturn={setShowReturn} />
        </Route>

        <Route path="/objection/objection-handler-overview/:tabID/supporting/:compID/:compBodyID">
          <ObjectionHandlerOverview showReturn={setShowReturn} />
        </Route>
      </Switch>
    </>
  );
}
