export function transformChartRawData(data: (string | number)[][]) {
	const processedChartData: { [id: string | number]: string | number }[] = [];
	data.map((row, index) => {
		if (index !== 0) {
			const temp: { [id: string | number]: string | number } = {};
			row.map((element, index) => (temp[data[0][index]] = element));
			processedChartData.push(temp);
		}
	});

	return processedChartData;
}
export function reverseTransformChartProcessedData(
	data: {
		[id: string]: string | number;
		[id: number]: string | number;
	}[]
) {
	const rawChartData: (string | number)[][] = [];

	rawChartData.push([]);
	for (let key in data[0]) {
		rawChartData[0].push(key);
	}
	data.map((row, index) => {
		rawChartData.push([]);
		for (let key in row) {
			rawChartData[index + 1].push(row[key]);
		}
	});

	return rawChartData;
}
