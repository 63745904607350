import React from "react";
import {
  TileContainer,
  TileHeader,
  TitleIcon,
  TileHeaderText,
  TileHeaderAction,
  TileHeaderActions,
  HeaderRibbon,
  TileBody,
  StyledTooltip,
} from "./style";
import AppContext from "../../store/index";
import { Link, useRouteMatch } from "react-router-dom";
import { EyeFill } from "@styled-icons/bootstrap/EyeFill";
import { CircleRight } from "@styled-icons/icomoon/CircleRight";
import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import { Edit2Outline } from "@styled-icons/evaicons-outline/Edit2Outline";
import { ThreeDots } from "@styled-icons/bootstrap/ThreeDots";
import ReactMarkdown from "react-markdown";
import { headerIcons } from "../../globalTheme";
import { Popconfirm, message } from "antd";
import { Attachment } from "@styled-icons/typicons/Attachment";
import { request } from "../../layout";
import Highlight from "react-highlighter";

export interface Interface {
  data: {
    header: string;
    id: number;
    filePath: string;
    text: {
      id: number;
      text: string;
      link: string;
      bullets?: { title: string; text: string }[];
      header?: string;
      // referenceHeader?: string;
      // referenceLink?: string;
    }[];
    references?: {
      id?: number;
      referenceHeader?: string;
      referenceLink?: string;
    }[];
    link?: string | undefined;
  };
  bg?: string;
  minHeight?: string;
  height?: string;
  hideHeaderIcon?: boolean;
  hideActions?: boolean;
  hideRibbon?: boolean;
  bodyPadding?: string;
  link?: string;
  bodyText?: { text: string; link: string }[];
  keyNumber?: number;
  noMatch?: boolean;
  children?: React.ReactNode;
  handleDelete?: (key?: number) => void;
  layout?: string;
  edit: boolean;
  ref_start: number;
  refArray: any;
  showModal?: (editData: any) => void;
}
/**
 * Renders the Key Message Tile.
 *
 * @param data - Data for the Tile
 * @param bg - For setting Background color of the Tile
 * @param minHeight - For setting min height of the Body Container
 * @param height - For setting height of the Body Container
 * @param bodyPadding - For adding padding to Body Container
 * @param children - Children Elements
 * @param edit - Edit Handler function for editing form
 * @param ref_start - Reference Start Number
 * @param handleDelete - Function for handling delete
 * @param showModal - Function for opening the Modal with the Add / Edit Form
 * @param hideHeaderIcon - For hiding the Header Icons
 * @param link - Header Link Text
 * @param bodyText - Body Text data
 *
 * @returns Key Message Tile
 */
export default function Tile(props: Interface) {
  let match = useRouteMatch();
  const [highlight, setHighlight] = React.useState(false);
  const context = React.useContext(AppContext);

  function confirm(e: any) {
    //@ts-ignore
    props.handleDelete(props.data.id);
  }
  let icon: any = <></>;
  if (headerIcons.find((t) => t.id === props.data.header))
    icon = headerIcons.find((t) => t.id === props.data.header);

  let n = props.ref_start;
  let FileName = "";
  if (
    props.data.filePath !== null &&
    props.data.filePath !== "" &&
    props.data.filePath !== undefined
  ) {
    FileName = props.data.filePath;
    FileName = FileName.split("/")[5];
  }
  // const section = context.subSectionMapping.find(
  // 	(section) => section.id === props.sectionId
  // );
  // (section?.subSection || []).map((subSection) => {
  // 	if (subSection.name !== "Overview") {
  // 		options.push({
  // 			label: subSection.name,
  // 			// value:
  // 			// 	section?.name.split(" ")[0] +
  // 			// 	"/" +
  // 			// 	subSection.name.split(" ").join("_"),
  // 			value: subSection.id,
  // 		});
  // 	}
  // });
  function DownloadDocument(filePath: string) {
    //specify path for file to download
    message.loading("Downloading");
    //API to download a document, given a proper filepath
    //returns an object, which needs to downloaded in desired format
    request
      .get(
        process.env.REACT_APP_API_URL +
          "subSection/DownloadFromS3?filePath=" +
          filePath,
        {
          method: "GET",
          responseType: "blob",
          withCredentials: true,
        }
      )
      .then((res) => {
        // console.log("From s3", res);
        //open the document
        const fileName = filePath.split("/")[5];
        const url = window.URL.createObjectURL(
          new Blob([res.data as BlobPart])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        message.error("Error!");
        // console.log({ err });
      });
  }
  function findSubsection(subsectionId: any) {
    let link = "";
    let subsectionName = "";
    const t = context.subSectionMapping.findIndex((section) => {
      subsectionName = section.subSection.filter(
        (subsection) => subsection.id == subsectionId
      )[0]?.name;
      if (subsectionName) return true;
      else return false;
    });
    const tt = context.subSectionMapping.filter(
      (section) =>
        section.name.split(" ")[0].toLocaleLowerCase() == subsectionId
    )[0]?.name;
    // link =
    // 	sectionName.split(" ")[0] +
    // 	"/" +
    // 	subsectionName.split(" ").join("_");
    if (subsectionName && subsectionName !== "")
      link = subsectionName?.split(" ").join("_");
    else if (tt) link = "/" + tt?.split(" ")[0];
    console.log("SSN", subsectionId, subsectionName, tt);
    return link;
  }
  const HighlightSearchWord = {
    color: "red",
    backgroundColor: "yellow",
    padding: 0,
  };

  React.useEffect(() => {
    // if (window.getSelection && context.searchWord) {
    //   document.designMode = "on";
    //   var sel = window.getSelection();
    //   sel?.collapse(document.body, 0);
    //   while (window.find(context.searchWord)) {
    //     document.execCommand("HiliteColor", false, "#27a6a4");
    //     sel?.collapseToEnd();
    //   }
    //   document.designMode = "off";
    let searched = context.searchWord;
    console.log(searched);
    // if (searched !== "") {
    //   //@ts-ignore
    //   props.data.text.map((item: any) => {
    //     if (
    //       (item.header && item.header.toLowerCase().includes(searched)) ||
    //       (item.text && item.text.toLowerCase().includes(searched))
    //     )
    //       setHighlight(true);
    //   });
    // } else {
    //   setHighlight(false);
    // }
  }, []);

  return (
    <>
      <TileContainer
        className="tile"
        height={props.height}
        bg={props.bg}
        highlight={highlight}
      >
        <TileHeader>
          {/* {!props.hideHeaderIcon && (
						<TitleIcon>
							{icon.icon || <EyeFill size={24} />}
						</TitleIcon>
					)} */}
          <TileHeaderText>
            {console.log("searchword", context.searchWord)}
            <Highlight
              search={context.searchWord}
              matchStyle={HighlightSearchWord}
            >
              {props.data.header}
            </Highlight>
            {props.data.references &&
              props.data.references.length > 0 &&
              props.data.references.map((refs) => {
                return (
                  <StyledTooltip
                    title={
                      <>
                        <span
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {refs.referenceHeader}
                          <br />
                          {refs.referenceLink && (
                            <a href={refs.referenceLink} target="_blank">
                              Link
                            </a>
                          )}
                        </span>
                      </>
                    }
                    // title={"Refs"}
                    // className="reference-tooltip"
                  >
                    <span>
                      [
                      {props.refArray.findIndex(
                        (r: any) => r.referenceHeader === refs.referenceHeader
                      ) + 1}
                      ]
                    </span>
                    {/* <span>[{n++}]</span> */}
                  </StyledTooltip>
                );
              })}
          </TileHeaderText>

          {props.data.filePath && (
            <TileHeaderAction>
              <Attachment
                title={FileName}
                className="headerIcon"
                size={22}
                style={{ marginTop: "1px" }}
                onClick={() => {
                  console.log(props);
                  console.log(props.data);
                  DownloadDocument(props.data.filePath);
                  //@ts-ignore
                  // props.showModal(props.data);
                }}
              />
            </TileHeaderAction>
          )}

          {!props.hideActions && (
            <TileHeaderActions>
              <TileHeaderAction>
                <Link
                  // to={
                  // 	props.noMatch
                  // 		? props.link || ""
                  // 		: `${match.url}/${props.data.link}` ||
                  // 		  `${match.url}`
                  // }
                  // to={
                  // 	props.layout === "ObjectionHandler"
                  // 		? props.data.link +
                  // 		  "/" +
                  // 		  props.data.id +
                  // 		  "/" +
                  // 		  props.data.text[0].id
                  // 		: props.data.link || ""
                  // }
                  to={
                    props.layout === "ObjectionHandler"
                      ? props.data.link +
                        "/" +
                        props.data.id +
                        "/" +
                        props.data.text[0].id
                      : findSubsection(props.data.link) || ""
                  }
                >
                  <CircleRight className="headerIcon" size={18} />
                </Link>
              </TileHeaderAction>
              {context.isAdmin && (
                <>
                  <TileHeaderAction>
                    <Popconfirm
                      title="Are you sure to delete this message?"
                      onConfirm={confirm}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Delete
                        title="Delete"
                        className="headerIcon"
                        size={24}
                        // onClick={() => {
                        // 	//@ts-ignore
                        // 	props.handleDelete(props.data.id);
                        // }}
                      />
                    </Popconfirm>
                  </TileHeaderAction>
                  <TileHeaderAction>
                    <Edit2Outline
                      title="Edit"
                      className="headerIcon"
                      size={22}
                      style={{ marginTop: "4px" }}
                      onClick={() => {
                        //@ts-ignore
                        props.showModal(props.data);
                      }}
                    />
                  </TileHeaderAction>
                </>
              )}
            </TileHeaderActions>
          )}
          <HeaderRibbon />
        </TileHeader>
        <TileBody edit={props.edit}>
          <ul>
            {props.data.text?.map((bullet, index) => {
              if (!(props.edit && index > 1)) {
                return (
                  <li key={index}>
                    {bullet.text.toLowerCase().includes(context.searchWord) &&
                      context.searchWord !== "" && (
                        <Highlight
                          search={context.searchWord}
                          matchStyle={HighlightSearchWord}
                        >
                          {bullet.text}
                        </Highlight>
                      )}
                    {(!bullet.text.toLowerCase().includes(context.searchWord) ||
                      context.searchWord == "") && (
                      <ReactMarkdown source={bullet.text} />
                    )}
                    {bullet.bullets && (
                      <ul>
                        {bullet.bullets?.map((b) => {
                          return (
                            <li>
                              <p
                                style={{
                                  fontWeight: 500,
                                }}
                              >
                                <Highlight
                                  search={"chm"}
                                  matchStyle={HighlightSearchWord}
                                >
                                  {b.title}
                                </Highlight>
                              </p>
                              <ul>
                                <li>
                                  <Highlight
                                    search={"chm"}
                                    matchStyle={HighlightSearchWord}
                                  >
                                    {b.text}
                                  </Highlight>
                                </li>
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              } else return <></>;
            })}
          </ul>
        </TileBody>
        <div>
          {props.edit && (
            <>
              <ThreeDots
                size={24}
                style={{ float: "right", marginRight: "20px" }}
              />
            </>
          )}
        </div>
      </TileContainer>
    </>
  );
}
