import { Form, Input, Button, Space, Select, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "./styled.css";

const FormField1 = (props: {
  handleEdit?: (value: any, key: number) => void;
  handleSave?: (value: any) => void;
  editData?: any;
  closeModal: () => void;
}) => {
  const roles = [
    { label: "Read only", value: 1 },
    { label: "Read and Edit", value: 2 },
  ];
  // const dataCopy = props.editData;
  // if (props.editData) {
  // 	for (let i = 0; i < dataCopy.userRole.length; i++) {
  // 		if (dataCopy.userRole[i].rolesId == 2)
  // 			dataCopy.userRole[i].rolesId = "Read and Edit";
  // 		else if (dataCopy.userRole[i].rolesId == 1)
  // 			dataCopy.userRole[i].rolesId = "Read only";
  // 	}
  // }

  // console.log(props.editData);
  return (
    <div>
      <Form
        name="dynamic_form_nest_item"
        autoComplete="off"
        onFinish={(value) => {
          if (props.closeModal) props.closeModal();
          if (props.handleSave) props.handleSave(value);
          else if (props.handleEdit) props.handleEdit(value, props.editData.id);
        }}
        initialValues={props.editData}
      >
        <div>
          <Row>
            <Col span={5}>Review Name</Col>
            <Col span={19}>
              <Form.Item
                name="reviewName"
                // label="Product Name"
                rules={[
                  {
                    required: true,
                    message: "Please input Review Name",
                  },
                ]}
              >
                <Input placeholder="Enter review name" maxLength={48} />
              </Form.Item>
            </Col>
            <Col span={5}>Description</Col>
            <Col span={19}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input Review Description",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter review description"
                  showCount
                  rows={4}
                  //   maxLength={100}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormField1;
