export function filterRefs(
	dataS: {
		section: string;
		subSection: string;
		references: {
			referenceHeader: string;
			referenceLink: string;
			id: number;
		}[];
	}[]
) {
	const data = dataS.slice();
	const finalFilteredRefs: {
		section: string;
		subSection: {
			subSectionName: string;
			references: {
				referenceHeader: string;
				referenceLink: string;
				id: number;
			}[];
		}[];
	}[] = [];
	const filteredRefs: {
		section: string;
		subSection: string;
		references: {
			referenceHeader: string;
			referenceLink: string;
			id: number;
		}[];
	}[] = [];

	data.map((subSection) => {
		let flag = false;
		subSection.references.map((ref) => {
			if (ref.referenceHeader !== null && ref.referenceHeader !== "") {
				if (
					filteredRefs
						.find(
							(filteredRef) =>
								filteredRef.subSection === subSection.subSection
						)
						?.references.find(
							(r) => r.referenceHeader === ref.referenceHeader
						)
				) {
					flag = false;
				} else {
					flag = true;
				}
				// console.log()
			}
			if (flag) {
				const find = filteredRefs.find(
					(filteredRef) =>
						filteredRef.subSection === subSection.subSection
				);
				if (find) {
					find.references.push({
						referenceHeader: ref.referenceHeader,
						referenceLink: ref.referenceLink,
						id: ref.id,
					});
				} else {
					filteredRefs.push({
						section: subSection.section,
						subSection: subSection.subSection,
						references: [
							{
								referenceHeader: ref.referenceHeader,
								referenceLink: ref.referenceLink,
								id: ref.id,
							},
						],
					});
				}
			}
		});
		// if (flag) filteredRefs.push(subSection);
	});

	filteredRefs.map((ref) => {
		const findSection = finalFilteredRefs.find(
			(r) => r.section === ref.section
		);
		if (findSection) {
			finalFilteredRefs
				.find((r) => r.section === ref.section)
				?.subSection.push({
					subSectionName: ref.subSection,
					references: ref.references,
				});
		} else {
			finalFilteredRefs.push({
				section: ref.section,
				subSection: [
					{
						subSectionName: ref.subSection,
						references: ref.references,
					},
				],
			});
		}
	});

	filteredRefs.map((sections) => {
		sections.references.sort((a, b) => (a.id < b.id ? -1 : 1));
	});

	return finalFilteredRefs;
}
