import countries from "../../../countries.json";
import { ResponsiveChoropleth } from "@nivo/geo";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Drawer, Slider, Tooltip } from "antd";
import { ExternalLinkOutline } from "@styled-icons/evaicons-outline/ExternalLinkOutline";
import Geos from "../../../features.json";

export default function EvidenceAtlas(props: {
  mapData: any;
  tableData?: any;
  maxDomainLength?: any;
}) {
  const [selectedCountry, setSelectedCountry] = useState<any>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [renderTableData, setRenderTableData] = useState([...props.tableData]);
  const [hoverCountry, setHoverCountry] = useState("");
  const [hoverCountryCount, setHoverCountryCount] = useState(0);

  useEffect(() => {
    let tempData: any = [];
    props.tableData?.map((data: any) => {
      if (data.countries?.includes(selectedCountry)) {
        tempData.push(data);
      }
    });
    setRenderTableData([...tempData]);
    let temp: any = {};
    props.mapData.map((item: any) => {
      temp[item.id] = item.value;
    });
    Geos.objects.world.geometries.map((item, i) => {
      item.properties["color"] = "#D6D6DA";
      let val = temp[item.id] ? temp[item.id] : 0;
      item.properties["value"] = val;
      if (item.id === "GBR" || item.id === "FRA") {
      }
    });
  }, [selectedCountry]);

  const onClose = () => {
    setOpenDrawer(false);
  };

  const columns = [
    {
      title: "Publication",
      dataIndex: "publication",
    },
    {
      title: "Countries",
      dataIndex: "countries",
    },
    {
      title: "Prevalence",
      dataIndex: "prevalence",
    },
    {
      title: "Incidence",
      dataIndex: "incidence",
    },
    {
      title: "Mortality",
      dataIndex: "mortality",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Other Notes",
      dataIndex: "othernotes",
    },
  ];

  function showVal(a: any) {
    var zoomScale = Number(a) / 10;
    setZoom(zoomScale, document.getElementsByClassName("zoomableMap")[0]);
  }

  function setZoom(zoom: any, el: any) {
    let transformOrigin = [0, 0];
    var p = ["webkit", "moz", "ms", "o"],
      s = "scale(" + zoom + ")",
      oString = "top left";
    for (var i = 0; i < p.length; i++) {
      el.style[p[i] + "Transform"] = s;
      el.style[p[i] + "TransformOrigin"] = oString;
    }
    el.style["transform"] = s;
    el.style["transformOrigin"] = oString;
  }

  function myFunction(e: any) {
    var x = e.clientX - 1000;
    var y = e.clientY - 350;
    if (
      document.getElementById("tooltip") !== null &&
      document.getElementById("tooltip") !== undefined
    ) {
      //@ts-ignore
      document.getElementById("tooltip").style.left = x + "px";
      //@ts-ignore
      document.getElementById("tooltip").style.top = y + "px";
    }
  }

  return (
    <div className="bg-white shadow-md px-4 pt-2 mx-4 overflow-auto">
      <div className="flex items-center ">
        <p className="text-xs italic w-1/2">
          Countries for which epidemiology data are currently available are
          shaded in green. Hover over a country to see the number of studies
          identified, and click to see detailed data.
        </p>
        <div className="flex w-1/2 justify-end items-center pr-8">
          Slide to Zoom: &nbsp;&nbsp;&nbsp;
          <Slider
            min={10}
            max={20}
            step={0.5}
            style={{ width: "15rem", margin: 0 }}
            defaultValue={10}
            onChange={(value) => showVal(value)}
            tooltipVisible={false}
          />
        </div>
      </div>

      <div className="flex items-center mt-1">
        {/* <div
          style={{ height: "485px", width: "10%" }}
          className="flex items-end"
        >
          <div className="ml-12 mt-8 pb-32">
            <div className="flex items-center justify-start">
              <div
                style={{ backgroundColor: "#a5a5a5" }}
                className="h-4 w-4"
              ></div>
              <p className="ml-2">No Data</p>
            </div>
            <div className="flex items-center justify-start mt-1">
              <div
                style={{ backgroundColor: "#289b9d" }}
                className="h-4 w-4"
              ></div>
              <p className="ml-2">Data</p>
            </div>
          </div>
        </div> */}

        <div
          style={{
            height: "485px",
            width: "100%",
            position: "relative",
            overflow: "auto",
          }}
          // id="mapBox"
          // onMouseMove={myFunction}
        >
          {/* <div
            id="tooltip"
            className="bg-white flex items-center justify-center p-1 z-50"
          >
            <div
              style={{ backgroundColor: "#289b9d" }}
              className="h-3 w-3"
            ></div>
            {hoverCountry} : {hoverCountryCount}
          </div> */}
          <div
            // style={{ height: "460px", width: "100%", overflow: "auto" }}
            className="zoomableMap"
            id="zoomableMap"
          >
            <ResponsiveChoropleth
              data={props.mapData}
              features={countries.features}
              margin={
                window.innerWidth < 1200
                  ? { top: 0, right: 0, bottom: 0, left: 20 }
                  : { top: 0, right: 0, bottom: 0, left: 20 }
              }
              colors={[
                "#289b9d",
                "#289b9d",
                "#289b9d",
                "#289b9d",
                "#289b9d",
                "#289b9d",
              ]}
              domain={[0, props.maxDomainLength]}
              unknownColor={"#a5a5a5"}
              label="properties.name"
              valueFormat=".2"
              projectionType="equirectangular"
              projectionScale={window.innerWidth < 1200 ? 150 : 165}
              projectionTranslation={[0.5, 0.5]}
              projectionRotation={[0, 0, 0]}
              graticuleLineWidth={2}
              graticuleLineColor="#dddddd"
              borderColor="#152538"
              borderWidth={0.4}
              // tooltip={({ children, feature }) => {
              //   if (feature.data) {
              //     // setHoverCountry(feature.data.country);
              //     // setHoverCountryCount(feature.data.value);
              //     return (
              //       <div
              //         // id="tooltip"
              //         style={{ top: 0, left: 0, position:"absolute" }}
              //         className="bg-white flex items-center justify-center p-2 z-50"
              //       >
              //         <div
              //           style={{ backgroundColor: "#289b9d" }}
              //           className="h-3 w-3"
              //         ></div>
              //         {feature.data.country} : {feature.data.value}
              //       </div>
              //     );
              //   } else {
              //     setHoverCountry("");
              //     setHoverCountryCount(0);
              //     return <></>;
              //   }
              // }}
              legends={undefined}
              onClick={({ color, data, formattedValue, label, value }) => {
                if (data) {
                  setSelectedCountry(data.country);
                  setOpenDrawer(true);
                  // props.setSelectedCountry(data.country);
                }
              }}
            />
          </div>
        </div>
      </div>

      {openDrawer && (
        <Drawer
          title={selectedCountry}
          placement={"bottom"}
          onClose={onClose}
          visible={openDrawer}
          key={"bottom"}
          height="18rem"
          getContainer={false}
          style={{ position: "absolute" }}
          zIndex={10}
          bodyStyle={{ padding: "0.5rem" }}
          headerStyle={{ paddingTop: "0.8rem", paddingBottom: "0.5rem" }}
        >
          <div
            style={{
              height: "220px",
              overflowY: "auto",
              width: "100%",
              overflowX: "auto",
            }}
            className="fixedHeader dashboardCheckboxTable bg-white"
          >
            {renderTableData && (
              <table>
                <thead>
                  <tr className="roundedRows">
                    {columns.map((col: any) => (
                      <th
                        key={col.title}
                        style={{
                          backgroundColor: "#27a6a4",
                          color: "whitesmoke",
                          minWidth: "10rem",
                        }}
                        className="font-bold text-center "
                      >
                        {col.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {renderTableData.map((row: any, index: any) => {
                    const color = index % 2 === 0 ? "#eaf8f8" : "white";
                    const keys: any = [];
                    columns.map((c: any) => keys.push(c["dataIndex"]));
                    const temp = keys.indexOf("key");
                    if (temp > -1) {
                      keys.splice(temp, 1);
                    }
                    return (
                      <tr
                        key={row.index}
                        id={row.studyname}
                        style={{ backgroundColor: color }}
                        className="hover:bg-transparent border-b-8 roundedRows"
                      >
                        {keys.map((key: any) => {
                          if (key === "studyname") {
                            return (
                              <td
                                key={key}
                                className="align-top p-1 text-center"
                              >
                                <div className="flex justify-end items-center">
                                  <Tooltip title={props.tableData[row["key"]]}>
                                    <a
                                      href={props.tableData[row["key"]]}
                                      target="_blank"
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        height: "0.7rem",
                                      }}
                                    >
                                      <ExternalLinkOutline
                                        size={12}
                                        style={{ right: 0 }}
                                      />
                                    </a>
                                  </Tooltip>
                                </div>
                                <ReactMarkdown
                                  children={row[key]}
                                ></ReactMarkdown>
                              </td>
                            );
                          } else
                            return (
                              <td className="align-top p-2 text-center">
                                <ReactMarkdown
                                  children={row[key]}
                                ></ReactMarkdown>
                              </td>
                            );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </Drawer>
      )}
    </div>
  );
}
