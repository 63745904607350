import React from "react";
import { Form, Input, Row, Col, Button, Tabs, Select } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "./styled.css";
import { colors } from "../../globalTheme";
import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline";
import { Cross } from "@styled-icons/entypo/Cross";
const FormField6 = (props: {
	data: any;
	addLabel: (uniqueLabel: string) => void;
	deleteLabel: (id: number) => void;
	// handleSaveSeries: (value: any) => void;
	handleSaveData: (value: any) => void;
}) => {
	const { TabPane } = Tabs;
	const charts = [
		{ label: "Bar Chart", value: 1 },
		{ label: "Line Chart", value: 2 },
		{ label: "Pie Chart", value: 3 },
	];
	function makeid(length: number) {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	}
	const [error, setError] = React.useState<null | string>(null);
	// let error: string | null = null;
	return (
		<>
			<Form
				onFinish={(value) => {
					console.log("DATA VALUE ", value);
					// value.chartData.map((row: any) => {
					// 	for (let key in row) {
					// 		row[key] = Number(row[key]);
					// 	}
					// });
					let flag = false;
					value.series.map((s: any) => {
						console.log("DIM...", Number(s.dim));
						if (Number(s.dim) > value.chartData.length) {
							setError(
								"Row Number of Series doesn't exist on Data."
							);
							flag = true;
						}
					});
					if (!flag) {
						value.series.map((s: any) => {
							if (s.dim === undefined || s.dim === "") {
								setError(
									"Incomplete Form : Row Number is missing"
								);
								flag = true;
								// console.log(flag);
							} else if (s.name === undefined || s.name === "") {
								setError(
									"Incomplete Form : Series Name is missing"
								);
								flag = true;
								// console.log(flag);
							}
						});
						value.labelNames.map((l: any) => {
							if (
								l.headerName === undefined ||
								l.headerName === ""
							) {
								setError("Incomplete Form : Label is missing");
								flag = true;
								// console.log(flag);
							}
						});
					}
					// console.log("VAL", value);
					if (value.series.length <= value.chartData.length && !flag)
						props.handleSaveData(value);
				}}
				name="dynamic_form_nest_item"
				initialValues={props.data}
				// preserve={true}
			>
				{error && (
					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
						}}
					>
						<p style={{ color: "red", margin: 0 }}>
							<i>{error}</i>
						</p>
					</div>
				)}
				<Tabs defaultActiveKey="1">
					<TabPane tab="Chart Properties" key="1">
						<>
							<Row align="middle" gutter={[8, 16]}>
								<Col offset={5} span={4}>
									Chart Name
								</Col>
								<Col span={10}>
									<Form.Item
										className="tableFormField"
										name="chartName"
										style={{
											marginBottom: "0px !important",
										}}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={5}></Col>
								<Col offset={5} span={4}>
									Chart Type
								</Col>
								<Col span={10}>
									<Form.Item
										className="tableFormField"
										name="chartType"
										rules={[
											{
												required: true,
												message: "Missing Chart Type",
											},
										]}
									>
										<Select
											placeholder="Chart Type"
											style={{ width: 130 }}
											options={charts}
										></Select>
									</Form.Item>
								</Col>
								<Col span={5}></Col>
								<Col offset={5} span={4}>
									Data Label
								</Col>
								<Col span={10}>
									<Form.Item
										className="tableFormField"
										name="xAxisLabel"
										style={{
											marginBottom: "0px !important",
										}}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={5}></Col>
								<Col offset={5} span={4}>
									Value Label
								</Col>
								<Col span={10}>
									<Form.Item
										className="tableFormField"
										name="yAxisLabel"
										style={{
											marginBottom: "0px !important",
										}}
									>
										<Input />
									</Form.Item>
								</Col>
								<Col span={5}></Col>
							</Row>
						</>
					</TabPane>
					<TabPane tab="Series" key="2" forceRender>
						<Row align="top" gutter={[8, 0]}>
							<Col span={7}>
								<h4>Row Number</h4>
							</Col>
							<Col span={12}>
								<h4>Series Name</h4>
							</Col>
							<Col span={4}>
								<h4>Color</h4>
							</Col>
						</Row>
						<Form.List name="series">
							{(fields, { add, remove }) => (
								<>
									{fields.map(
										({
											key,
											name,
											fieldKey,
											...restField
										}) => (
											<>
												<Row
													align="top"
													gutter={[8, 0]}
												>
													{/* <Col span={2}>Row {index + 1}</Col> */}

													<Col span={7}>
														<Form.Item
															className="tableFormField"
															{...restField}
															name={[name, "dim"]}
															fieldKey={[
																fieldKey,
																"dim",
															]}
															style={{
																marginBottom:
																	"0px !important",
															}}
															// rules={[
															// 	{
															// 		required:
															// 			true,
															// 		message:
															// 			"Enter the Row Number",
															// 	},
															// ]}
														>
															<Input type="number" />
														</Form.Item>
													</Col>
													<Col span={12}>
														<Form.Item
															className="tableFormField"
															{...restField}
															name={[
																name,
																"name",
															]}
															fieldKey={[
																fieldKey,
																"name",
															]}
															style={{
																marginBottom:
																	"0px !important",
															}}
														>
															<Input />
														</Form.Item>
													</Col>
													<Col span={4}>
														<Form.Item
															className="tableFormField"
															{...restField}
															name={[
																name,
																"color",
															]}
															fieldKey={[
																fieldKey,
																"color",
															]}
															style={{
																marginBottom:
																	"0px !important",
															}}
															initialValue="#3287C4"
														>
															<Input type="color" />
														</Form.Item>
													</Col>
													<Col span={1}>
														<MinusCircleOutlined
															title="Delete Series"
															onClick={() =>
																remove(name)
															}
														/>
													</Col>
												</Row>
											</>
										)
									)}
									<Form.Item>
										<button
											type="button"
											className="addBtn"
											style={{
												// width: "5rem",
												border: "none",
												background:
													"transparent !important",
												display: "flex",
												alignItems: "center",
												cursor: "pointer",
											}}
											onClick={() => add()}
											// block
										>
											<PlusOutlined />
											Add Series
										</button>
									</Form.Item>
									{/* <Form.Item>
										<Button
											type="primary"
											htmlType="submit"
										>
											Update Chart
										</Button>
									</Form.Item> */}
								</>
							)}
						</Form.List>
					</TabPane>
					<TabPane tab="Data" key="3" forceRender>
						{/* <Form
						onFinish={(value) => {
							props.handleSaveData(value);
							// if (props.closeModal) props.closeModal();
							// if (props.handleSave) props.handleSave(value);
							// else if (props.handleEdit) {
							// 	props.handleEdit(value, props.messageId || 0);
							// }
						}}
						name="dynamic_form_nest_item"
						initialValues={props.data}
					> */}
						{/* {console.log(props.data.chartData)} */}

						<Form.List
							name="labelNames"
							// initialValue={props.data.labelNames}
						>
							{(labelNames, { add, remove }) => (
								<Row>
									<Col span={23}>
										<div
											style={{
												// width: "100%",
												display: "flex",
												alignContent: "center",
												justifyContent: "space-evenly",
											}}
										>
											{labelNames.map(({ name }) => (
												<div
													style={{
														border: "1px solid black",
														background:
															colors.zsGray10,
														display: "flex",
														justifyContent:
															"center",
													}}
												>
													<Form.Item
														className="tableFormField"
														name={[
															name,
															"headerName",
														]}
														// rules={[
														// 	{
														// 		required: true,
														// 		message:
														// 			"Missing",
														// 	},
														// ]}
													>
														<Input
															placeholder="Label Name"
															style={{
																border: "none",
																background:
																	colors.zsGray10,
																fontWeight: 700,
															}}
														/>
													</Form.Item>

													<Cross
														title="Delete Label"
														size={20}
														cursor="pointer"
														onClick={() => {
															remove(name);
															props.deleteLabel(
																name
															);
														}}
													/>
												</div>
											))}
										</div>
									</Col>
									<Col span={1}>
										<Form.Item>
											<AddCircleOutline
												size={18}
												style={{ marginLeft: "4px" }}
												onClick={() => {
													const uniqueId =
														"Label " + makeid(2);
													const temp = {
														id: 0,
														headerName: "",
														identifier: uniqueId,
														chartComponentId: 0,
													};
													add(temp);
													props.addLabel(uniqueId);
												}}
											/>
											<p style={{ fontSize: "0.6rem" }}>
												Labels
											</p>
										</Form.Item>
									</Col>
								</Row>
							)}
						</Form.List>

						{/* <Form.List name="labelNames">
							{(fields) => (
								<Row>
									<Col span={23}>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												height: "auto",
											}}
										>
											{props.data.labelNames.map(
												(label: any, index: number) => {
													return (
														<Form.Item
															className="tableFormField"
															name={[
																fields[index]
																	.name,
																"headerName",
															]}
															style={{
																marginBottom:
																	"0px !important",
															}}
														>
															{console.log(
																"FIELDSSSS",
																fields
															)}
															<Input />
														</Form.Item>
													);
												}
											)}
										</div>
									</Col>
									<Col span={1}>
										<Form.Item>
											<AddCircleOutline
												size={18}
												style={{
													marginLeft: "4px",
												}}
												onClick={() => {
													props.addLabel();
												}}
											/>
										</Form.Item>
									</Col>
								</Row>
							)}
						</Form.List> */}
						<Form.List name="chartData">
							{(fields, { add, remove }) => (
								<>
									{fields.map(
										({
											key,
											name,
											fieldKey,
											...restField
										}) => (
											<>
												<Row
													align="top"
													gutter={[8, 0]}
												>
													{/* <Col span={2}>Row {index + 1}</Col> */}

													<Col span={23}>
														<div
															style={{
																display: "flex",
																height: "auto",
																justifyContent:
																	"space-evenly",
															}}
														>
															{props.data.labelNames.map(
																(col: any) => {
																	return (
																		<Form.Item
																			className="tableFormField"
																			{...restField}
																			name={[
																				name,
																				col.identifier,
																			]}
																			fieldKey={[
																				fieldKey,
																				col,
																			]}
																			style={{
																				marginBottom:
																					"0px !important",
																			}}
																		>
																			<Input type="number" />
																		</Form.Item>
																	);
																}
															)}
														</div>
													</Col>

													<Col span={1}>
														{" "}
														<MinusCircleOutlined
															title="Delete Row"
															onClick={() =>
																remove(name)
															}
														/>
													</Col>
												</Row>
											</>
										)
									)}
									<Form.Item>
										<button
											type="button"
											className="addBtn"
											style={{
												border: "none",
												background:
													"transparent !important",
												display: "flex",
												alignItems: "center",
												cursor: "pointer",
											}}
											onClick={() => add()}
										>
											<PlusOutlined /> Add Data Row
										</button>
									</Form.Item>
								</>
							)}
						</Form.List>
						{/* </Form> */}
					</TabPane>
				</Tabs>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Update Chart
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default FormField6;
