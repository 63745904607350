import React from "react";
import LandingTile from "./Component/tile";
import {
  LandingContainer,
  StyledHeaderContainer,
  StyledHeader,
  StyledWelcomeContainer,
  StyledBrandsContainer,
  StyledBrands,
} from "./style";
import { Button, message, Modal, Skeleton } from "antd";
import * as LandingServiceHelper from "./service-helper";
import FormField3 from "../../libraries/form-fields/formField3";
import AppContext from "../../store";
import { UserAdd } from "@styled-icons/typicons/UserAdd";
import { SignOut } from "@styled-icons/fluentui-system-filled/SignOut";
import { Home7 } from "@styled-icons/remix-fill/Home7";

function setRole(userId: number, productsData: any) {
  const userRole: { productId: number; roleId: number }[] = [];
  productsData.map((product: any) => {
    const search = product.userRole.find((user: any) => user.userId === userId);
    if (search)
      userRole.push({ productId: product.id, roleId: search.rolesId });
  });
  console.log("Role", userRole);
  // context.setRole(userRole)
  localStorage.setItem("userRole", JSON.stringify(userRole));
  return userRole;
}

/**
 * Renders the Landing Page and sets up the major context and session variables.
 *
 * @returns Landing Page of the App
 */
export default function Landing() {
  const [fetchedData, setFetchedData] = React.useState<any>(null);
  const context = React.useContext(AppContext);
  const [fetchCounter, setFetchCounter] = React.useState(0);
  async function fetchdata() {
    setFetchCounter(fetchCounter + 1);
    const data: any = await LandingServiceHelper.getAllProducts();

    // console.log(data);

    if (data) {
      context.setUser(data.username);
      localStorage.setItem("userName", data.username);
      context.setRole(setRole(data.username, data.brandList));
    }

    setFetchedData(data);
    console.log("data", data);
  }

  React.useEffect(() => {
    // fetchdata();
    if (
      (fetchedData === null ||
        fetchedData === undefined ||
        fetchedData.brandList === null ||
        fetchedData.brandList === undefined ||
        fetchedData.brandList.length === 0) &&
      fetchCounter < 2
    ) {
      fetchdata();
    }
  });

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("Modal Header");
  const [modalText, setModalText] = React.useState(<></>);
  const showModal = () => {
    setModalHeader("Add Product");
    setModalText(
      <FormField3 handleSave={handleSave} closeModal={handleCancel} />
    );
    setVisible(true);
  };
  const showModalEdit = (id: number) => {
    const editData = fetchedData.brandList.find(
      (product: any) => product.id === id
    );
    setModalHeader("Edit Product");
    setModalText(
      <FormField3
        handleEdit={handleEdit}
        editData={editData}
        closeModal={handleCancel}
      />
    );
    setVisible(true);
  };
  const handleOk = () => {
    setModalText(<p>Creating...</p>);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1500);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  async function handleSave(value: any) {
    value.createdBy = "ms30001";
    const key = "updatable";
    if (!value.userRole) value.userRole = [];
    message.loading({ content: "Saving changes...", key });
    const data: any = await LandingServiceHelper.addNewBrand(value);

    if (data) message.success({ content: "Saved successfully", key });
    else message.error({ content: "Save failed", key });
    if (data) {
      setFetchedData(data);
      context.setUser(data.username);
      localStorage.setItem("userName", data.username);
      context.setSubSection(data.brandSubsectionDetails);
      context.setRole(setRole(data.username, data.brandList));
    }
    // console.log("Data : " + data);
  }
  async function handleDelete(id: number) {
    const key = "updatable";
    message.loading({ content: "Saving changes...", key });
    const data: any = await LandingServiceHelper.deleteBrand(
      id,
      context.productId
    );
    // setFetchedData(data);
    if (data) message.success({ content: "Saved successfully", key });
    else message.error({ content: "Save failed", key });
    if (data) {
      setFetchedData(data);
      context.setUser(data.username);
      localStorage.setItem("userName", data.username);
      context.setSubSection(data.brandSubsectionDetails);
      context.setRole(setRole(data.username, data.brandList));
    }
  }
  async function handleEdit(value: any, id: number) {
    value.id = id;
    value.lastUpdatedBy = "ms30001";
    const key = "updatable";
    message.loading({ content: "Saving changes...", key });
    const data: any = await LandingServiceHelper.updateBrand(value);
    if (data) message.success({ content: "Saved successfully", key });
    else message.error({ content: "Save failed", key });
    if (data) {
      setFetchedData(data);
      context.setUser(data.username);
      localStorage.setItem("userName", data.username);
      context.setSubSection(data.brandSubsectionDetails);
      context.setRole(setRole(data.username, data.brandList));
    }
  }
  return (
    <>
      {fetchedData ? (
        <>
          <Modal
            destroyOnClose={true}
            title={modalHeader}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            // cancelButtonProps={{ style: { display: "none" } }}
            footer={null}
          >
            <p>{modalText}</p>
          </Modal>
          <LandingContainer>
            <div
              style={{
                marginTop: "1.5rem",
                position: "absolute",
                right: "20px",
              }}
            >
              <a
                style={{
                  background: "rgb(25,28,39)",
                  padding: "0.8rem 0.6rem",
                }}
                href={process.env.REACT_APP_ADD_USER_URL}
                target="_blank"
              >
                <UserAdd
                  style={{
                    marginBottom: "0.3em",
                    marginRight: "0.3em",
                  }}
                  size={26}
                />
                <span style={{ fontSize: "14px" }}>Create User</span>
              </a>
              <a
                style={{
                  // background: "rgb(25,28,39)",
                  padding: "0.8rem 0.6rem",
                }}
                onClick={() => {
                  window.sessionStorage.setItem("appInfo", "");
                }}
                href={"/"}
                // target="_blank"
              >
                <Home7
                  style={{
                    marginBottom: "0.3em",
                    marginRight: "0.3em",
                  }}
                  size={26}
                />
                <span style={{ fontSize: "14px" }}>Home</span>
              </a>
              <a
                style={{
                  background: "rgb(25,28,39)",
                  padding: "0.8rem 0.6rem",
                }}
                href={process.env.REACT_APP_SIGNOUT_URL}
              >
                <SignOut
                  style={{
                    marginBottom: "0.3em",
                    marginRight: "0.3em",
                  }}
                  size={26}
                />
                <span style={{ fontSize: "14px" }}>Sign out</span>
              </a>
            </div>

            <StyledHeaderContainer>
              <StyledHeader>Global Value Dossiers</StyledHeader>
            </StyledHeaderContainer>
            <StyledWelcomeContainer>
              <p>Welcome {context.userId}</p>
              {/* <h2>Ayushi Agarwal</h2> */}
              {/* <h3>{context.userId}</h3> */}
            </StyledWelcomeContainer>
            <StyledBrandsContainer>
              {fetchedData.brandList.map((brand: any) => {
                return (
                  <StyledBrands>
                    <LandingTile
                      id={brand.id}
                      header={brand.name}
                      description={brand.description}
                      createdBy={brand.createdBy}
                      lastUpdatedBy={brand.lastUpdatedBy}
                      handleDelete={handleDelete}
                      link={
                        "summary/" +
                        brand.id +
                        "/" +
                        brand.name.split(" ").join("_") +
                        "/"
                      }
                      showModalEdit={showModalEdit}
                    />
                  </StyledBrands>
                );
              })}
              <StyledBrands>
                <LandingTile
                  id={0}
                  header={""}
                  description={""}
                  createdBy={""}
                  lastUpdatedBy={""}
                  add
                  onClick={showModal}
                />
              </StyledBrands>
            </StyledBrandsContainer>
          </LandingContainer>
        </>
      ) : (
        <>
          <LandingContainer>
            <StyledHeaderContainer>
              <StyledHeader>Global Value Dossiers</StyledHeader>
            </StyledHeaderContainer>
            <StyledWelcomeContainer>
              <p>
                <Skeleton.Input style={{ width: 300 }} active size="large" />
              </p>
              {/* <h2>Ayushi Agarwal</h2> */}
              <h3>
                <Skeleton.Input style={{ width: 500 }} active size="large" />
              </h3>
            </StyledWelcomeContainer>
            <StyledBrandsContainer>
              <StyledBrands>
                <Skeleton.Avatar active size={200} shape="square" />
              </StyledBrands>
              <StyledBrands>
                <Skeleton.Avatar active size={200} shape="square" />
              </StyledBrands>
              <StyledBrands>
                <Skeleton.Avatar active size={200} shape="square" />
              </StyledBrands>
            </StyledBrandsContainer>
          </LandingContainer>
        </>
      )}
    </>
  );
}
