import BeatLoader from "react-spinners/BeatLoader";
import { Button, Drawer, message, Popover, Tooltip } from "antd";
import {
  EditFilled,
  SaveOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  UndoOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useEffect, useMemo, useState } from "react";
import Inclusion from "../../components/MethodologyReview/Picos/Inclusion";
import Exclusion from "../../components/MethodologyReview/Picos/Exclusion";
import { request } from "../../layout/SLR_Layout";
import { TextBulletListSquare } from "@styled-icons/fluentui-system-regular/TextBulletListSquare";
import ReactMarkdown from "react-markdown";
import SimpleMdeReact from "react-simplemde-editor";
import { useHistory } from "react-router-dom";

export default function PICOS() {
  const [keySelected, setKeySelected] = useState("inclusion");
  const [inEditMode, setInEditMode] = useState(false);
  const [dataInclusion, setDataInclusion] = useState<any>();
  const [dataExclusion, setDataExclusion] = useState<any>();
  const [initialdataInclusion, setInitialDataInclusion] = useState<any>();
  const [initialdataExclusion, setInitialDataExclusion] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [screenInfo, setScreenInfo] = useState<any>(
    "This section provides information on inclusion and exclusion criterions based on PICOTS (Population, intervention, Comparator, Outcomes, Timeframe, Study design) format"
  );
  const [footnote, setFootnote] = useState<any>("");
  const [footnoteInitial, setFootnoteInitial] = useState<any>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inEditModeFootnote, setInEditModeFootnote] = useState(false);
  const [isLoadingFootnote, setIsLoadingFootnote] = useState(true);

  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "100%",
      maxHeight: "100%",
    };
  }, []);
  const navigate = useHistory();

  useEffect(() => {
    if (
      window.sessionStorage.getItem("selectedBackgroundName") === undefined ||
      window.sessionStorage.getItem("selectedBackgroundName") === null ||
      window.sessionStorage.getItem("selectedBackgroundName") === "" ||
      window.sessionStorage.getItem("productReviewId") === undefined ||
      window.sessionStorage.getItem("productReviewId") === null ||
      window.sessionStorage.getItem("productReviewId") === "" ||
      window.localStorage.getItem("productId") === undefined ||
      window.localStorage.getItem("productId") === null ||
      window.localStorage.getItem("productId") === ""
    ) {
      navigate.push("/SLR");
      return;
    }
    fetchData();
  }, []);

  function fetchData() {
    setIsLoading(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    request
      .get("/GetAllCriteria?productReviewId=" + productReviewId)
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          setDataInclusion(res.data.inclusion);
          setDataExclusion(res.data.exclusion);
          setInitialDataInclusion(res.data.inclusion);
          setInitialDataExclusion(res.data.exclusion);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  function saveHandler() {
    console.log("data", dataInclusion, dataExclusion);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    if (keySelected === "inclusion") {
      message.loading("Saving Changes!", 1.8);

      request
        .post(
          "/EditInclusionCriteria?productReviewId=" + String(productReviewId),
          { inclusion: dataInclusion }
        )
        .then((res) => {
          console.log("Response from API", res);
          if (res.status === 200) {
            setInitialDataInclusion(dataInclusion);
            message.success("Saved!");
            setInEditMode(false);
          } else {
            setDataInclusion(initialdataInclusion);
            message.error("Error!");
          }
        })
        .catch((err) => {
          message.error("Error!");
          setDataInclusion(initialdataInclusion);
          console.log(err);
        });
    } else {
      message.loading("Saving Changes!", 1.8);
      request
        .post(
          "/EditExclusionCriteria?productReviewId=" + String(productReviewId),
          { exclusion: dataExclusion }
        )
        .then((res) => {
          console.log("Response from API", res);
          if (res.status === 200) {
            setInitialDataExclusion(dataExclusion);
            message.success("Saved!");
            setInEditMode(false);
          } else {
            setDataExclusion(initialdataExclusion);
            message.error("Error!");
          }
        })
        .catch((err) => {
          message.error("Error!");
          setDataExclusion(initialdataExclusion);
          console.log(err);
        });
    }
  }

  function handleMenuChange(value: any) {
    if (inEditMode) {
      message.warning("Please save your data!");
      return;
    }
    setKeySelected(value.key);
  }

  const handlePopOverOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  function saveHandlerFootnote() {
    console.log("data after save", footnote);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Eligibility Criteria"];
    message.loading("Saving Changes!", 0.8).then(() => {
      request
        .post(
          "/EditFootNote?productReviewId=" +
            productReviewId +
            "&id=" +
            sectionId,
          {
            footNote: footnote,
          }
        )
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            message.success("Saved!");
            setFootnoteInitial(footnote);
          } else {
            message.error("Error!");
            setFootnote(footnoteInitial);
          }
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
    setInEditModeFootnote(false);
  }

  function showDrawerFootnote() {
    setIsLoadingFootnote(true);

    setDrawerOpen(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Eligibility Criteria"];
    request
      .get(
        "/GetFootNote?productReviewId=" + productReviewId + "&id=" + sectionId
      )
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (!res.data.footNote) setFootnote("");
          else setFootnote(res.data.footNote);
          setFootnoteInitial(res.data.footNote);
          setIsLoadingFootnote(false);
        } else {
          setIsLoadingFootnote(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoadingFootnote(false);
        console.log(err);
      });
    // setIsLoadingFootnote(false);
  }

  return (
    <div style={{ overflow: "hidden", position: "relative" }}>
      <div className="flex pr-4 items-center w-full bg-white">
        <div className="w-1/2 flex items-center">
          <Popover
            placement="bottomLeft"
            visible={popoverOpen}
            onVisibleChange={handlePopOverOpenChange}
            style={{ top: "4rem" }}
            overlayStyle={{
              minHeight: "2rem",
              maxWidth: "40rem",
            }}
            content={
              <div className="text-xs" style={{ maxWidth: "30rem" }}>
                <p>{screenInfo}</p>
              </div>
            }
            trigger={"hover"}
          >
            <InfoCircleOutlined
              style={{
                marginLeft: "1.2rem",
                fontSize: "1.1rem",
                // marginTop: "0.2rem",
              }}
            />
          </Popover>
          <Menu
            onClick={handleMenuChange}
            mode="horizontal"
            defaultSelectedKeys={["prisma"]}
            // activeKey={keySelected}
            selectedKeys={[keySelected]}
            className="w-full"
          >
            <Menu.Item key="inclusion">Inclusion Criteria</Menu.Item>
            <Menu.Item key="exclusion">Exclusion Criteria</Menu.Item>
          </Menu>
        </div>
        {window.sessionStorage.getItem("isAdmin") === "2" ? (
          <div className="flex w-1/2 justify-end items-center">
            {inEditMode ? (
              <div>
                <Button type="default" onClick={saveHandler}>
                  <SaveOutlined />
                  Save
                </Button>
                <Button
                  className="ml-2"
                  type="default"
                  onClick={() => {
                    fetchData();
                    setInEditMode(false);
                  }}
                >
                  <CloseOutlined />
                  Cancel
                </Button>
              </div>
            ) : (
              <Button type="default" onClick={() => setInEditMode(true)}>
                <EditFilled />
                Edit
              </Button>
            )}
            <TextBulletListSquare
              size={30}
              style={{
                marginLeft: "0.6rem",
                color: "#27a6a4",
                cursor: "pointer",
              }}
              onClick={showDrawerFootnote}
              title="Open Footnotes"
            />
          </div>
        ) : (
          ""
        )}
      </div>

      <div style={{ maxHeight: "86vh", overflowY: "auto" }}>
        {isLoading ? (
          <div
            style={{ minHeight: "80vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : keySelected === "inclusion" ? (
          dataInclusion && (
            <Inclusion
              data={dataInclusion}
              setData={setDataInclusion}
              inEditMode={inEditMode}
            />
          )
        ) : (
          dataExclusion && (
            <Exclusion
              data={dataExclusion}
              setData={setDataExclusion}
              inEditMode={inEditMode}
            />
          )
        )}
      </div>
      <Drawer
        title={
          <div className="flex">
            <p className="text-black mb-0 w-1/2">Footnotes</p>
            <div className="flex w-1/2 justify-end items-center">
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <div className="flex w-full justify-end items-center">
                  {inEditModeFootnote ? (
                    <div className="flex">
                      <Tooltip title="Save Changes">
                        <SaveOutlined
                          onClick={saveHandlerFootnote}
                          style={{ color: "black", fontSize: "1.2rem" }}
                        />
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <UndoOutlined
                          onClick={() => {
                            setInEditModeFootnote(false);
                            setFootnote(footnoteInitial);
                          }}
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            marginLeft: "0.6rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Edit Footnotes">
                      <EditFilled
                        onClick={() => setInEditModeFootnote(true)}
                        style={{ color: "black", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Close">
                    <CloseOutlined
                      onClick={() => {
                        setDrawerOpen(false);
                        setInEditModeFootnote(false);
                      }}
                      style={{
                        color: "black",
                        fontSize: "1.2rem",
                        marginLeft: "0.6rem",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        }
        closable={false}
        placement="bottom"
        onClose={() => {
          setDrawerOpen(false);
          setInEditModeFootnote(false);
        }}
        getContainer={false}
        visible={drawerOpen}
        style={{ position: "absolute" }}
        zIndex={20}
        bodyStyle={{ padding: "0.5rem" }}
        headerStyle={{
          paddingTop: "0.6rem",
          paddingBottom: "0.6rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "whitesmoke",
          color: "black",
        }}
      >
        {isLoadingFootnote ? (
          <div
            style={{ minHeight: "23vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : footnote === "" && !inEditModeFootnote ? (
          <div
            className="border flex items-center justify-center"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Edit icon to add footnotes!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">No Footnotes Added!</p>
              </div>
            )}
          </div>
        ) : inEditModeFootnote ? (
          <div
            className="border-r"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            <div id="introEditor" className="">
              <SimpleMdeReact
                options={options}
                value={footnote}
                onChange={(e) => {
                  let temp = footnote;
                  temp = String(e);
                  setFootnote(temp);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="border-r"
            style={{
              minHeight: "23vh",
              maxHeight: "23vh",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div id="SLRMDEditor" className="py-4 px-4">
              <ReactMarkdown children={footnote}></ReactMarkdown>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
