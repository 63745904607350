import {
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Select,
  Tabs,
  Upload,
  message,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "easymde/dist/easymde.min.css";
import React, { useState, useEffect, useRef } from "react";
import { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import "./styled.css";
import AppContext from "../../store";
import { request } from "../../layout";

const FormFieldEvidence = (props: {
  handleEdit?: (value: any, key: number, deletedId: number[]) => void;
  handleSave?: (value: any) => void;
  editData?: any;
  closeModal?: () => void;
  subSectionId?: number;
  sectionId: number;
  viewType: string;
}) => {
  const context = React.useContext(AppContext);
  const [changedRef, setChangedRef] = React.useState(false);
  const [fileRemoved, setFileRemoved] = React.useState(false);
  const value = React.useRef("");
  const onChange = (values: string) => {
    // setValue(value);
    value.current = values;
  };

  //   const [form] = Form.useForm();
  useEffect(() => {
    if (props.editData) {
      if (
        props.editData.filePath !== null &&
        props.editData.filePath !== "" &&
        props.editData.filePath !== undefined
      ) {
        defaultFileList.push({
          uid: "1",
          name: props.editData.filePath.split("/")[5],

          // response: "Server Error 500"; // custom error message to show
          url: props.editData.filePath,
        });
      }
    }
  }, []);

  const section = context.subSectionMapping.find(
    (section) => section.id === props.sectionId
  );
  const options: {
    label: string;
    value: string;
  }[] = [];
  (section?.subSection || []).map((subSection) => {
    if (subSection.name !== "Overview") {
      options.push({
        label: subSection.name,
        // value:
        // 	section?.name.split(" ")[0] +
        // 	"/" +
        // 	subSection.name.split(" ").join("_"),
        value: subSection.id + "",
      });
    }
  });

  const dataCopy = props.editData;
  const { TabPane } = Tabs;
  const [error, setError] = React.useState<null | string>(null);
  let fileName: string = "";
  let fileLoc: string = "";
  if (props.editData) {
    fileLoc =
      process.env.REACT_APP_API_URL +
      "subSection/UploadtoS3?filePath=files/" +
      context.productId +
      "/" +
      props.sectionId +
      "/" +
      props.subSectionId +
      "/" +
      props.editData.id +
      "/";
  }
  //subsectioncomponentid + cardcomponentid + cardbodycomponentid
  //   if (dataCopy.Text)
  const defaultFileList: any = [];
  //   const formRef = useRef(null);
  //   if (props.editData.overviewBody[0].Text) {
  //     const tempName = props.editData.imageUrl.split("/");
  //     fileName = tempName[tempName.length - 1];
  //     defaultFileList.push({
  //       uid: "1",
  //       name: tempName[tempName.length - 1],

  //       // response: "Server Error 500"; // custom error message to show
  //       url: filePath,
  //     });
  //   }

  return (
    <Form
      //   form={form}
      //   ref={formRef}
      name="dynamic_form_nest_item"
      onFinish={(value) => {
        value.changedRef = changedRef;

        value.text.map(
          (item: any) => (item.header = "Epidemiology data by country")
        );
        value.header = "Epidemiology data by country";
        console.log("DATA VALUE...", value);

        value.filePath = fileLoc.split("=")[1] + fileName;
        if (value.fileName !== null && value.fileName !== "") {
          value.filePath = fileLoc.split("=")[1] + fileName;
        }
        if (
          value.fileName === null ||
          value.fileName === "" ||
          value.fileName === undefined
        ) {
          value.filePath = "";
        }
        if (fileRemoved) {
          value.filePath = "";
        }
        let flag = false;
        if (
          props.viewType === "overview" &&
          (value.header === undefined || value.header === "")
        ) {
          setError("Incomplete Form : Header is missing");
          flag = true;
          // console.log(flag);
        } else if (props.viewType === "overview" && value.link === undefined) {
          // console.log("INside Link!");
          setError("Incomplete Form : Link is missing");
          flag = true;
        }
        {
          !flag &&
            value.text.map((val: any) => {
              console.log("Inside Map...", val);

              if (
                props.viewType !== "overview" &&
                (val.header === "" || val.header === undefined)
              ) {
                setError("Incomplete Form : Header is missing");
                flag = true;
                // console.log(flag);
              }
              if (
                props.viewType === "overview" &&
                (val.text === "" || val.text === undefined)
              ) {
                setError("Incomplete Form : Message is missing");
                flag = true;
              }
            });
        }

        const refDeleted: number[] = [];
        if (props.editData)
          dataCopy.references.map((refs: any) => {
            if (!value.references.find((r: any) => r.id === refs.id))
              refDeleted.push(refs.id);
          });
        value.refDeleted = refDeleted;

        if (!value.references) value.references = [];
        if (props.subSectionId === 29) {
          console.log(value.text.length);
          if (!value.text.length) {
            value.text.push({
              header: "",
              link: "",
              referenceHeader: "",
              refereneLink: "",
            });
          }
        }

        if (!value.text) value.text = [];
        if (props.handleSave && !flag) {
          props.handleSave(value);
          if (props.closeModal) props.closeModal();
        } else if (props.handleEdit && !flag) {
          if (props.closeModal) props.closeModal();

          const deletedId: number[] = [];
          let message: any;
          for (let textData in value.text) {
            if (!value.text[textData].id) value.text[textData].id = 0;
          }
          for (message in dataCopy.text) {
            // console.log(dataCopy.text[message].id);
            const present = value.text.some(
              (el: any) => el.id === dataCopy.text[message].id
            );
            if (!present) deletedId.push(dataCopy.text[message].id);
          }

          props.handleEdit(value, props.editData.id, deletedId);
        }
      }}
      autoComplete="off"
      // layout="inline"
      initialValues={
        props.editData || {
          text: [{ header: "", link: "supporting", text: "" }],
        }
      }
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Message Properties" key="1">
          <div className="formItemOverview">
            <Form.List name="text">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <>
                      <Row>
                        <Col offset={1} span={22}>
                          <p>Body</p>
                          <Form.Item
                            {...restField}
                            name={[name, "text"]}
                            fieldKey={[fieldKey, "text"]}
                            // rules={[
                            //   {
                            //     required:
                            //       props.viewType === "overview" ? true : false,
                            //     message: "Missing Body",
                            //   },
                            // ]}
                          >
                            <SimpleMdeReact
                              options={{
                                hideIcons: [
                                  "fullscreen",
                                  "side-by-side",
                                  "image",
                                  "guide",
                                ],
                              }}
                              value={value.current}
                              onChange={onChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ))}
                </>
              )}
            </Form.List>
          </div>
        </TabPane>
        <TabPane tab="References" key="2" forceRender>
          <Form.List name="references">
            {(fields, { add, remove }) => (
              <>
                <Row gutter={[8, 0]}>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <>
                      <Form.Item
                        {...restField}
                        name={[name, "id"]}
                        fieldKey={[fieldKey, "id"]}
                        initialValue={0}
                      />
                      <Col span={13}>
                        <Form.Item
                          {...restField}
                          name={[name, "referenceHeader"]}
                          fieldKey={[fieldKey, "referenceHeader"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Reference Header",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Reference Header"
                            onChange={() => setChangedRef(true)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, "referenceLink"]}
                          fieldKey={[fieldKey, "referenceLink"]}
                          rules={
                            [
                              // {
                              // 	required: true,
                              // 	message: "Missing role",
                              // },
                            ]
                          }
                        >
                          <Input
                            placeholder="Reference Link"
                            onChange={() => setChangedRef(true)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(name);
                            // setChangedRef(true);
                          }}
                        />
                      </Col>
                    </>
                  ))}
                </Row>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add References
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </TabPane>
      </Tabs>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormFieldEvidence;
