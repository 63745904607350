import styled from "styled-components";
import { colors } from "../../globalTheme";

export const SectionContainer = styled.div`
	width: 100%;
	background: transparent;
	display: grid;
	grid-template-columns: 50% 50%;
	margin-bottom: 1.2em;
`;
export const SectionHeader = styled.div`
	width: 100%;
	padding: 0.5em 0;
	/* background-color: green; */
	h3 {
		font-size: 1rem;
		font-weight: 600;
		color: ${colors.zsGray90};
	}
`;
export const SectionReferences = styled.div`
	width: 100%;
	padding: 0.4em 0.6em;
	/* background-color: red; */
`;

export const FormContainer = styled.div`
	margin: 0% 20%;
	margin-bottom: 10%;
	padding: 1em 1.5em;
	background: white;
`;

export const ContainerHeading = styled.h2`
	padding: 1em 0;
	margin-top: 0;
	margin-bottom: 2em;
	font: 12px;
`;
