import React from "react";
import Tile from "../../libraries/tile";
import AppContext from "../../store/index";
import ContactCard from "./Component/ContactCard";
import PicTile from "./Component/PicTile";
import { AboutUsContainer, CardContainer, CardSlot } from "./style";

/**
 * Renders the About Us Page.
 *
 * @returns About Us Page of the App
 */
export default function AboutUs() {
  const context = React.useContext(AppContext);

  React.useEffect(() => {
    context.setView(11);
  });
  const data1 = {
    header: "Introduction to digital global value dossiers (dGVDs)",
    id: 0,
    filePath: "",
    text: [
      {
        id: 0,
        text: "GVDs (global value dossiers) are industry standard tools used throughout product lifecycles that act as a foundation of current and future market initiatives through development, launch, and marketing. To aid in communicating specific key value messages and stories tailored for payers in each global region, the GVD acts as a comprehensive guide and outline of the therapy area background, clinical evidence, and global payer strategies associated with each asset.",
        bullets: [],
        link: "some-link",
      },
      {
        id: 1,
        text: "Traditionally, GVDs have been long and dense documents that are often difficult to digest, challenging to navigate, and a hassle to update. As a solution, this dGVD (digital GVD) has been developed to deliver information in a modular format, with tiered information organized into clear sections to streamline your experience in locating specific information and resources when needed.",
        bullets: [],
        link: "some-link",
      },
    ],
    link: "",
  };
  const data2 = {
    header: "Disclaimers",
    id: 0,
    filePath: "",
    text: [
      {
        id: 0,
        text: "The dGVD is an internal resource including company-confidential content and should not be directly shared with external parties, payers, providers, or vendors. It is intended to be used as a basic reference for building multidisciplinary resources.",
        bullets: [],
        link: "some-link",
      },
    ],
    link: "",
  };
  const data3 = {
    header: "How to use",
    id: 0,
    filePath: "",
    text: [
      {
        id: 0,
        text: "Consistent with a traditional GVD, the dGVD presents evidence-based messaging that demonstrate the value individual assets bring to the market, as well as how they are positioned relative to other relevant products on the market or in the pipeline. Specifically customized to optimize usability, information in the dGVD is concisely presented to provide an informed payer value proposition which can be used to demonstrate comparative benefit with payers and HTA (health technology assessment) organizations.",
        bullets: [],
        link: "some-link",
      },
      {
        id: 0,
        text: "In the dGVD, a full spectrum of information from high-level value messages to granular supporting details associated with the following topics can be found. The content is fully referenced and is updated on a scheduled basis to ensure alignment with the rapidly changing market landscape:",
        bullets: [
          {
            title: "Disease Overview",
            text: "Includes epidemiology, pathophysiology, clinical presentation, diagnosis, natural history",
          },
          {
            title: "Current Treatment Landscape",
            text: "Includes standards and guidelines, treatment options, approaches to management, emerging therapies",
          },
          {
            title: "Burden of Illness & Unmet Need ",
            text: "Includes clinical burden, humanistic burden, economic burden, unmet need",
          },
          {
            title: "Product Information",
            text: "Includes pharmacological description, pre-clinical data, clinical development program",
          },
          {
            title: "Economic Value",
            text: "Includes cost-effectiveness model, budget impact model",
          },
          {
            title: "Market Access",
            text: "Includes information to support country- or region-specific market access",
          },
        ],
        link: "some-link",
      },
    ],
    link: "",
  };

  return (
    <>
      <AboutUsContainer>
        <CardContainer grid={1}>
          <CardSlot>
            <Tile
              data={data1}
              hideHeaderIcon
              hideActions={true}
              edit={false}
              ref_start={0}
              refArray={[]}
            />
          </CardSlot>
          <CardSlot>
            <Tile
              data={data2}
              hideHeaderIcon
              hideActions={true}
              edit={false}
              ref_start={0}
              refArray={[]}
            />
          </CardSlot>
          <CardSlot>
            <Tile
              data={data3}
              hideHeaderIcon
              hideActions={true}
              edit={false}
              ref_start={0}
              refArray={[]}
            />
          </CardSlot>
          <CardSlot>
            <PicTile />
          </CardSlot>
          <CardSlot>
            <ContactCard />
          </CardSlot>
        </CardContainer>
      </AboutUsContainer>
    </>
  );
}
