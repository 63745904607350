import styled from "styled-components";
import { colors } from "../../globalTheme";

export const SubsectionBody = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 1em;
`;
export const ReferenceContainer = styled.div`
  width: 100%;
  background: transparent;
  padding-bottom: 8vh;
  /* height: calc(100vh - 22%); */

  height: ${(props: { noSubtab?: boolean }) =>
    props?.noSubtab ? "calc(100vh - 16%)" : "calc(100vh - 22%)"};
  overflow-y: scroll;
  padding: 1em;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SectionHeader = styled.div`
  width: 100%;
  padding: 0.5em 0;
  /* background-color: green; */
  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    color: ${colors.zsTeal};
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  background: transparent;
  display: grid;
`;

export const SectionContainer = styled.div`
  width: 100%;
  background: transparent;
  margin-bottom: 1.2em;
`;

export const SectionReferences = styled.div`
  width: 100%;
  padding: 0.4em 0.6em;
  /* background-color: red; */
`;

export const FormContainer = styled.div`
  margin: 0% 20%;
  margin-bottom: 10%;
  padding: 1em 1.5em;
  background: white;
`;

export const ContainerHeading = styled.h2`
  padding: 1em 0;
  margin-top: 0;
  margin-bottom: 2em;
  font: 12px;
`;
