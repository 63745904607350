import { TextBulletListSquare } from "@styled-icons/fluentui-system-regular/TextBulletListSquare";
import BeatLoader from "react-spinners/BeatLoader";
import { useEffect, useMemo, useRef, useState } from "react";
import { Download } from "@styled-icons/boxicons-regular/Download";
import { ExternalLinkOutline } from "@styled-icons/evaicons-outline/ExternalLinkOutline";
import {
  EditFilled,
  SaveOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  UndoOutlined,
  FileOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Tooltip,
} from "antd";
import ReactMarkdown from "react-markdown";
import { ResponsiveBar } from "@nivo/bar";
import html2canvas from "html2canvas";
import { request } from "../../layout/SLR_Layout";
import { Link, useHistory } from "react-router-dom";
import SimpleMdeReact from "react-simplemde-editor";

export default function QualityAssessment() {
  const [inEditMode, setInEditMode] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [concernValues, setConcernValues] = useState<any>([]);
  const [barData, setBarData] = useState<any>([]);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [check, setCheck] = useState(true);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [screenInfo, setScreenInfo] = useState<any>(
    "This section shows the results of the critical appraisal tools that were utilized for quality assessment for each study included in the scope of this SLR"
  );
  const [footnote, setFootnote] = useState<any>("");
  const [footnoteInitial, setFootnoteInitial] = useState<any>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inEditModeFootnote, setInEditModeFootnote] = useState(false);
  const [isLoadingFootnote, setIsLoadingFootnote] = useState(true);
  const [studyDetails, setStudyDetails] = useState<any>({});

  const printRef: React.MutableRefObject<null> = useRef(null);
  const [form] = Form.useForm();
  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "100%",
      maxHeight: "100%",
    };
  }, []);
  const navigate = useHistory();

  useEffect(() => {
    if (
      window.sessionStorage.getItem("selectedBackgroundName") === undefined ||
      window.sessionStorage.getItem("selectedBackgroundName") === null ||
      window.sessionStorage.getItem("selectedBackgroundName") === "" ||
      window.sessionStorage.getItem("productReviewId") === undefined ||
      window.sessionStorage.getItem("productReviewId") === null ||
      window.sessionStorage.getItem("productReviewId") === "" ||
      window.localStorage.getItem("productId") === undefined ||
      window.localStorage.getItem("productId") === null ||
      window.localStorage.getItem("productId") === ""
    ) {
      navigate.push("/SLR");
      return;
    }
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    request
      .get("/GetQuality?productReviewId=" + productReviewId)
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          let tempData = res.data.data;
          let tempColumns = res.data.columns;
          setConcernValues([...res.data.columns]);
          setTableData([...res.data.data]);

          // convert data to string format
          // 1 for less concerns
          // 2 for some concerns
          // 3 for high concerns
          if (tempData.length >= 0) {
            let tempFormValues: any = {};
            tempData.map((data: any) => {
              tempColumns.map((key: any) => {
                if (key.dataIndex !== "studyname") {
                  let tempKey = data.studyname + "_" + key.dataIndex;
                  if (data[key.dataIndex] === "1") {
                    tempFormValues[tempKey] = "less";
                  } else if (data[key.dataIndex] === "2") {
                    tempFormValues[tempKey] = "some";
                  } else if (data[key.dataIndex] === "3") {
                    tempFormValues[tempKey] = "high";
                  }
                }
              });
            });
            console.log("formValues", tempFormValues);
            form.setFieldsValue(tempFormValues);

            // create data for bar graph
            let tempBarData: any = [];
            let tempBarKeys: any = [];
            tempColumns.map((col: any) => {
              if (col.dataIndex !== "studyname") {
                tempBarKeys.push(col.dataIndex);
                let temp = {
                  domain: "D" + String(col.dataIndex),
                  "High Risk": 0,
                  "Some Concerns": 0,
                  "Low Risk": 0,
                };
                tempData.map((row: any) => {
                  if (row[col.dataIndex] === "3") {
                    temp["High Risk"] += 1;
                    setCheck(false);
                  } else if (row[col.dataIndex] === "2") {
                    temp["Some Concerns"] += 1;
                    setCheck(false);
                  } else if (row[col.dataIndex] === "1") {
                    temp["Low Risk"] += 1;
                    setCheck(false);
                  }
                });
                tempBarData.push(temp);
              }
            });

            // bar data to 2 decimal places
            tempBarData.map((data: any) => {
              data["High Risk"] =
                Math.round((data["High Risk"] / tempData.length) * 100 * 100) /
                100;
              data["Low Risk"] =
                Math.round((data["Low Risk"] / tempData.length) * 100 * 100) /
                100;
              data["Some Concerns"] =
                Math.round(
                  (data["Some Concerns"] / tempData.length) * 100 * 100
                ) / 100;
            });
            setBarData([...tempBarData]);

            let tempStudyDetails: any = {};
            res.data.studyDetails.map((item: any) => {
              tempStudyDetails[item["key"]] = item["reference"];
            });
            setStudyDetails(tempStudyDetails);
          }
        } else {
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function saveHandler() {
    console.log(tableData);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    message.loading("Saving Changes!", 1.8);

    request
      .post("/EditQuality?productReviewId=" + String(productReviewId), {
        data: tableData,
      })
      .then((res) => {
        if (res.status === 200) {
          setInEditMode(false);
          setIsModalVisibleEdit(false);
          message.success("Saved!");
        } else {
          setIsModalVisibleEdit(false);
          message.error("Error!");
        }
        console.log("res", res);
      })
      .catch((err) => {
        message.error("Error!");
        console.log(err);
        setIsModalVisibleEdit(false);
      });
  }

  const handleCancel = () => {
    setIsModalVisibleEdit(false);
  };

  function handleOkModalEdit() {
    let values = form.getFieldsValue();
    console.log("values", values);

    let tempData = [...tableData];

    let formKeys = Object.keys(values);

    // convert data to int format
    // 1 for less concerns
    // 2 for some concerns
    // 3 for high concerns
    formKeys.map((key: any) => {
      let studyname = key.split("_")[0];
      let domain = key.split("_")[1];

      let index = tempData.findIndex(
        (data: any) => data.studyname === studyname
      );
      if (index > -1 && domain !== undefined) {
        if (values[key] === "less") {
          tempData[index][domain] = "1";
        } else if (values[key] === "some") {
          tempData[index][domain] = "2";
        } else if (values[key] === "high") {
          tempData[index][domain] = "3";
        }
      }
    });

    let tempBarData: any = [];
    let tempBarKeys: any = [];
    concernValues.map((col: any) => {
      if (col.dataIndex !== "studyname") {
        tempBarKeys.push(col.dataIndex);
        let temp = {
          domain: "D" + col.dataIndex,
          "High Risk": 0,
          "Some Concerns": 0,
          "Low Risk": 0,
        };
        tempData.map((row: any) => {
          // console.log("checking for ", row, col.dataIndex)
          if (row[col.dataIndex] === "3") {
            temp["High Risk"] += 1;
            setCheck(false);
          } else if (row[col.dataIndex] === "2") {
            temp["Some Concerns"] += 1;
            setCheck(false);
          } else if (row[col.dataIndex] === "1") {
            temp["Low Risk"] += 1;
            setCheck(false);
          }
        });
        tempBarData.push(temp);
      }
    });
    setBarData([...tempBarData]);
    setTableData([...tempData]);
    console.log("values after update", values, tempBarData);
    form.setFieldsValue(values);
    setIsModalVisibleEdit(false);
    // setInEditMode(false);
  }

  const handleDownloadImage = async (ref: React.MutableRefObject<null>) => {
    message.loading("Downloading!");
    const element = ref.current;
    if (element) {
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = data;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }
  };

  const handlePopOverOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  function saveHandlerFootnote() {
    console.log("data after save", footnote);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Quality Assessment"];
    message.loading("Saving Changes!", 0.8).then(() => {
      request
        .post(
          "/EditFootNote?productReviewId=" +
            productReviewId +
            "&id=" +
            sectionId,
          {
            footNote: footnote,
          }
        )
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            message.success("Saved!");
            setFootnoteInitial(footnote);
          } else {
            message.error("Error!");
            setFootnote(footnoteInitial);
          }
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
    setInEditModeFootnote(false);
  }

  function showDrawerFootnote() {
    setIsLoadingFootnote(true);

    setDrawerOpen(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Quality Assessment"];
    request
      .get(
        "/GetFootNote?productReviewId=" + productReviewId + "&id=" + sectionId
      )
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (!res.data.footNote) setFootnote("");
          else setFootnote(res.data.footNote);
          setFootnoteInitial(res.data.footNote);
          setIsLoadingFootnote(false);
        } else {
          setIsLoadingFootnote(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoadingFootnote(false);
        console.log(err);
      });
    // setIsLoadingFootnote(false);
  }

  return (
    <div style={{ overflow: "hidden", position: "relative", height: "100%" }}>
      <div className="flex px-4 py-2 items-center bg-white">
        <p className="w-1/2 text-lg font-semibold pl-1">
          <Popover
            placement="bottomLeft"
            visible={popoverOpen}
            onVisibleChange={handlePopOverOpenChange}
            style={{ top: "4rem" }}
            overlayStyle={{
              minHeight: "2rem",
              maxWidth: "40rem",
            }}
            content={
              <div className="text-xs" style={{ maxWidth: "30rem" }}>
                <p>{screenInfo}</p>
              </div>
            }
            trigger={"hover"}
          >
            <InfoCircleOutlined
              style={{
                marginRight: "0.6rem",
                fontSize: "1.1rem",
                // marginTop: "0.2rem",
              }}
            />
          </Popover>
          Quality Assessment
        </p>

        {window.sessionStorage.getItem("isAdmin") === "2" && (
          <div className="flex w-1/2 justify-end items-center">
            <Button
              type="default"
              onClick={() => {
                setInEditMode(true);
                setIsModalVisibleEdit(true);
              }}
            >
              <EditFilled />
              Edit
            </Button>
            {inEditMode ? (
              <div>
                <Button className="ml-2" type="default" onClick={saveHandler}>
                  <SaveOutlined />
                  Save
                </Button>
                <Button
                  className="ml-2"
                  type="default"
                  onClick={() => {
                    setInEditMode(false);
                  }}
                >
                  <CloseOutlined />
                  Cancel
                </Button>
              </div>
            ) : (
              ""
            )}
            <TextBulletListSquare
              size={30}
              style={{
                marginLeft: "0.6rem",
                color: "#27a6a4",
                cursor: "pointer",
              }}
              onClick={showDrawerFootnote}
              title="Open Footnotes"
            />
          </div>
        )}
      </div>
      <div className="mx-4 mt-4 bg-white px-2 py-2 ">
        {isLoading ? (
          <div
            style={{ minHeight: "79vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : tableData.length === 0 ? (
          <div
            className="bg-white m-2 border-2 rounded-sm flex items-center justify-center"
            style={{ minHeight: "79vh", overflowY: "auto" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center ">
                <p className="text-lg text-gray-500 mt-2 mr-4 italic font-semibold mb-1 flex items-center pl-12 ">
                  Navigate to PRISMA to add Study Details
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <Link
                    to={
                      "/SLR/" +
                      window.sessionStorage.getItem("selectedBackground") +
                      "/Results/Prisma"
                    }
                  >
                    <Button
                      type="default"
                      onClick={() =>
                        window.sessionStorage.setItem("redirectToStudy", "true")
                      }
                      className="p-1"
                    >
                      <ExternalLinkOutline
                        size={24}
                        style={{ right: 0, paddingBottom: "0.2rem" }}
                      />
                    </Button>
                  </Link>
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "#27a6a4", fontSize: "3rem" }} />
                <p className="text-xl mt-2 italic">No Details Added!</p>
              </div>
            )}
          </div>
        ) : check ? (
          <div
            className="bg-white m-2 border-2 rounded-sm flex items-center justify-center"
            style={{ minHeight: "79vh", overflowY: "auto" }}
          >
            <div className="text-center">
              <EditFilled style={{ color: "#27a6a4", fontSize: "3rem" }} />
              <p className="text-xl mt-2 italic">
                Click on Edit to Add Details!
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-start">
            <div className="fixedHeader qualityTable w-1/2 pl-2 pt-4">
              <div
                style={{ backgroundColor: "#d1e8e8" }}
                className="w-full py-2 px-4 rounded-md"
              >
                <p className="text-black font-semibold text-xl text-center">
                  Cochrane Risk of Bias tool
                </p>
              </div>
              <div
                style={{
                  maxHeight: "57vh",
                  minHeight: "57vh",
                  overflowY: "auto",
                }}
                className="px"
              >
                {tableData && (
                  <table style={{ marginTop: "1rem" }}>
                    <thead>
                      <tr className="roundedRows">
                        <th
                          style={{
                            backgroundColor: "#27a6a4",
                            color: "whitesmoke",
                          }}
                          className="font-bold text-center"
                        >
                          Study Name
                        </th>
                        {concernValues.map((col: any, index: any) => {
                          if (
                            col.dataIndex === "studyname" ||
                            col.dataIndex === "6"
                          )
                            return "";
                          return (
                            <th
                              style={{
                                backgroundColor: "#27a6a4",
                                color: "whitesmoke",
                              }}
                              className="font-semibold text-center"
                            >
                              D{col.dataIndex}
                            </th>
                          );
                        })}
                        <th
                          style={{
                            backgroundColor: "#27a6a4",
                            color: "whitesmoke",
                          }}
                          className="font-bold text-center"
                        >
                          Overall
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((row: any, index: any) => {
                        const color = index % 2 === 0 ? "#eaf8f8" : "white";
                        const keys: any = [];
                        concernValues.map((c: any) =>
                          keys.push(c["dataIndex"])
                        );
                        const temp = keys.indexOf("key");
                        if (temp > -1) {
                          keys.splice(temp, 1);
                        }

                        return (
                          <tr
                            key={index}
                            id={row.studyname}
                            className="border-b-2 roundedRows"
                            style={{ backgroundColor: color }}
                          >
                            <td
                              key={"studyname"}
                              // style={{ backgroundColor: "#eaf8f8" }}
                              className="align-top p-1 text-center italic"
                            >
                              <div className="flex justify-end items-center">
                                <Tooltip title={studyDetails[row["key"]]}>
                                  <a
                                    href={studyDetails[row["key"]]}
                                    target="_blank"
                                  >
                                    <ExternalLinkOutline
                                      size={12}
                                      style={{ right: 0 }}
                                    />
                                  </a>
                                </Tooltip>
                              </div>
                              {row["studyname"]}
                            </td>
                            {keys.map((key: any, i: any) => {
                              if (key === "studyname") return "";
                              else
                                return (
                                  <td className="align-top px-2 pt-3 text-center">
                                    <div className="flex justify-center items-center">
                                      {row[key] === "3" ? (
                                        <div
                                          style={{ backgroundColor: "#FD595F" }}
                                          className="h-5 w-5 rounded-2xl"
                                        ></div>
                                      ) : row[key] === "1" ? (
                                        <div
                                          style={{ backgroundColor: "#54D7AF" }}
                                          className="h-5 w-5 rounded-2xl"
                                        ></div>
                                      ) : row[key] === "2" ? (
                                        <div
                                          style={{ backgroundColor: "#FFB234" }}
                                          className="h-5 w-5 rounded-2xl"
                                        ></div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <div className="flex items-center justify-center mt-4 text-xs">
                <div className="w-1/2 justify-center pl-12">
                  {concernValues.map((concern: any, index: any) => {
                    if (concern.dataIndex !== "studyname")
                      return (
                        <p>
                          D{concern.dataIndex}: {concern.title}
                        </p>
                      );
                  })}
                  {/* <p>D1: Bias due to randomisation</p>
                  <p>D2: Bias due to deviations from intended intervention</p>
                  <p>D3: Bias due to missing data</p>
                  <p>D4: Bias due to outcome measurement</p>
                  <p>D5: Bias due to selection of reported result</p> */}
                </div>
                <div className="w-1/2 pl-24 text-xs">
                  <div className="flex justify-start">
                    <div
                      style={{ backgroundColor: "#FD595F" }}
                      className="h-4 w-4 rounded-2xl"
                    />
                    <p className="ml-2">High Risk</p>
                  </div>
                  <div className="flex mt-2 justify-start">
                    <div
                      style={{ backgroundColor: "#54D7AF" }}
                      className="h-4 w-4 rounded-2xl"
                    />
                    <p className="ml-2">Low Risk</p>
                  </div>
                  <div className="flex mt-2 justify-start">
                    <div
                      style={{ backgroundColor: "#FFB234" }}
                      className="h-4 w-4 rounded-2xl"
                    />
                    <p className="ml-2">Some Concerns</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/2 pt-4 pl-4">
              <div className="border-l">
                <div
                  style={{ backgroundColor: "#d1e8e8" }}
                  className="py-2 mx-4 px-4 rounded-md flex"
                >
                  <p
                    style={{ width: "95%" }}
                    className="text-black font-semibold text-xl text-center "
                  >
                    Overview of risk of bias
                  </p>
                  <div style={{ width: "5%" }} className="flex justify-end">
                    <Download
                      title="Download Chart"
                      size={22}
                      style={{
                        color: "#32A29B",
                        cursor: "pointer",
                        marginTop: "0.25rem",
                      }}
                      onClick={() => handleDownloadImage(printRef)}
                    />
                  </div>
                </div>
              </div>
              <div
                ref={printRef}
                style={{ height: "510px", width: "100%" }}
                className="w-full flex items-center justify-center border-l"
              >
                {barData && (
                  <ResponsiveBar
                    data={barData}
                    keys={["High Risk", "Some Concerns", "Low Risk"]}
                    indexBy="domain"
                    margin={
                      window.innerWidth < 1250
                        ? { top: 50, right: 130, bottom: 70, left: 80 }
                        : { top: 50, right: 170, bottom: 50, left: 140 }
                    }
                    padding={0.3}
                    valueScale={{ type: "linear" }}
                    indexScale={{ type: "band", round: true }}
                    colors={["#FD595F", "#FFB234", "#54D7AF"]}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    axisTop={null}
                    axisRight={null}
                    enableLabel={false}
                    enableGridY={false}
                    theme={{
                      axis: {
                        domain: {
                          line: {
                            stroke: "gray",
                            strokeWidth: 2,
                          },
                        },
                      },
                    }}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Domains",
                      legendPosition: "middle",
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "%age Studies",
                      legendPosition: "middle",
                      legendOffset: -40,
                      tickValues: 5,
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 1.6]],
                    }}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 4,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 14,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                    role="application"
                    ariaLabel="Quality Assessment Chart"
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        title={<p className="text-white">Edit</p>}
        className="editTableModal"
        visible={isModalVisibleEdit}
        footer={null}
        onCancel={handleCancel}
        width={"70rem"}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
      >
        <Form form={form} name="userResponses">
          <Row>
            <Col span={4}>
              <table className="qualityTable mt-2">
                <thead>
                  <tr className="hover:bg-transparent">
                    <th
                      style={{
                        backgroundColor: "#27a6a4",
                        color: "whitesmoke",
                      }}
                      className="align-top p-2 text-center border-b"
                    >
                      Study Name
                    </th>
                  </tr>
                </thead>
                <div className="h-0.5 bg-gray-300" />
                <tbody>
                  {tableData.map((row: any) => {
                    return (
                      <tr className="hover:bg-transparent">
                        <td
                          style={{
                            backgroundColor: "#eaf8f8",
                          }}
                          className="align-middle p-2 text-center italic"
                        >
                          <div className="flex justify-end items-center">
                            <Tooltip title={studyDetails[row["key"]]}>
                              <a
                                href={studyDetails[row["key"]]}
                                target="_blank"
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  height: "0.7rem",
                                }}
                              >
                                <ExternalLinkOutline
                                  size={12}
                                  style={{ right: 0 }}
                                />
                              </a>
                            </Tooltip>
                          </div>
                          {row.studyname}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
            <Col span={20} style={{ overflowX: "auto" }}>
              <div className="outcomesForm">
                <div className="flex items-center pt-2">
                  {concernValues.map((key: any, index: any) => {
                    if (key.dataIndex === "studyname") return "";
                    return (
                      <div
                        id={index}
                        style={{
                          minWidth: "140px",
                          maxWidth: "140px",
                          backgroundColor: "#eaf8f8",
                        }}
                        className="px-2 py-1 flex justify-start mx-1 items-center border"
                      >
                        {/* <Form.Item name={key}> */}
                        <Tooltip
                          title={key.title}
                          color="#FFFFFF"
                          overlayStyle={{
                            fontSize: "0.75rem",
                          }}
                          overlayInnerStyle={{
                            color: "black",
                          }}
                        >
                          <input
                            style={{
                              width: "95%",
                              backgroundColor: "transparent",
                            }}
                            value={key.title}
                            className="px-2 py-1"
                          />
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>

                {tableData.map((t: any, i: any) => {
                  return (
                    <div
                      className="flex items-center"
                      style={{ height: "50.3px" }}
                    >
                      {concernValues.map((key: any, j: any) => {
                        if (key.dataIndex === "studyname") return "";
                        return (
                          <div
                            style={{ minWidth: "140px" }}
                            className="flex justify-center items-center mt-1 mx-1"
                          >
                            <Form.Item name={t.studyname + "_" + key.dataIndex}>
                              <Select
                                placeholder="Select Value"
                                style={{ minWidth: "140px" }}
                                id={t.studyname + "_" + key.dataIndex}
                              >
                                <Select.Option value="less">
                                  Low Risk
                                </Select.Option>
                                <Select.Option value="some">
                                  Some Concerns
                                </Select.Option>
                                <Select.Option value="high">
                                  High Risk
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <div className="flex mt-2 w-full justify-end outcomesForm">
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={handleOkModalEdit}
                type="default"
              >
                Ok
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Drawer
        title={
          <div className="flex">
            <p className="text-black mb-0 w-1/2">Footnotes</p>
            <div className="flex w-1/2 justify-end items-center">
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <div className="flex w-full justify-end items-center">
                  {inEditModeFootnote ? (
                    <div className="flex">
                      <Tooltip title="Save Changes">
                        <SaveOutlined
                          onClick={saveHandlerFootnote}
                          style={{ color: "black", fontSize: "1.2rem" }}
                        />
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <UndoOutlined
                          onClick={() => {
                            setInEditModeFootnote(false);
                            setFootnote(footnoteInitial);
                          }}
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            marginLeft: "0.6rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Edit Footnotes">
                      <EditFilled
                        onClick={() => setInEditModeFootnote(true)}
                        style={{ color: "black", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Close">
                    <CloseOutlined
                      onClick={() => {
                        setDrawerOpen(false);
                        setInEditModeFootnote(false);
                      }}
                      style={{
                        color: "black",
                        fontSize: "1.2rem",
                        marginLeft: "0.6rem",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        }
        closable={false}
        placement="bottom"
        onClose={() => {
          setDrawerOpen(false);
          setInEditModeFootnote(false);
        }}
        getContainer={false}
        visible={drawerOpen}
        style={{ position: "absolute" }}
        zIndex={20}
        bodyStyle={{ padding: "0.5rem" }}
        headerStyle={{
          paddingTop: "0.6rem",
          paddingBottom: "0.6rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "whitesmoke",
          color: "black",
        }}
      >
        {isLoadingFootnote ? (
          <div
            style={{ minHeight: "23vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : footnote === "" && !inEditModeFootnote ? (
          <div
            className="border flex items-center justify-center"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Edit icon to add footnotes!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">No Footnotes Added!</p>
              </div>
            )}
          </div>
        ) : inEditModeFootnote ? (
          <div
            className="border-r"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            <div id="introEditor" className="">
              <SimpleMdeReact
                options={options}
                value={footnote}
                onChange={(e) => {
                  let temp = footnote;
                  temp = String(e);
                  setFootnote(temp);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="border-r"
            style={{
              minHeight: "23vh",
              maxHeight: "23vh",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div id="SLRMDEditor" className="py-4 px-4">
              <ReactMarkdown children={footnote}></ReactMarkdown>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
