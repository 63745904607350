import React from "react";
import LandingTile from "./Component/tile";
import {
  LandingContainer,
  StyledHeaderContainer,
  StyledHeader,
  StyledWelcomeContainer,
  StyledBrandsContainer,
  StyledBrands,
} from "./style";
import { Button, message, Modal, Skeleton } from "antd";
import * as LandingServiceHelper from "./service-helper";
import FormField3 from "../../SLR/libraries/form-fields/formField3";
import { UserAdd } from "@styled-icons/typicons/UserAdd";
import { LogOut } from "@styled-icons/boxicons-regular/LogOut";
import { Home7 } from "@styled-icons/remix-fill/Home7";
import { Link } from "react-router-dom";
import { request } from "../layout/SLR_Layout";

/**
 * Renders the Landing Page and sets up the major context and session variables.
 *
 * @returns Landing Page of the App
 */
export default function Landing() {
  const [fetchedData, setFetchedData] = React.useState<any>(null);
  const [fetchCounter, setFetchCounter] = React.useState(0);

  React.useEffect(() => {
    // call get data api max 2 times if there is no data fetched
    if (
      (fetchedData === null || fetchedData.length === 0) &&
      fetchCounter < 2
    ) {
      request
        .get("/GetProduct")
        .then((res: any) => {
          console.log("Response from API", res);
          if (res.status === 200) {
            setFetchedData([...res.data]);
            setFetchCounter((preVal) => preVal + 1);
          }
        })
        .catch((err) => {
          console.log("error found");
          console.log(err);
          setFetchCounter((preVal) => preVal + 1);
        });
      window.sessionStorage.setItem("selectedProduct", "");
      window.sessionStorage.setItem("selectedProductId", "");
      window.sessionStorage.setItem("isAdmin", "");
      console.log(
        window.localStorage.getItem("userName"),
        window.localStorage.getItem("isAdminGlobal")
      );
    }
  }, [fetchCounter]);

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("Modal Header");
  const [modalText, setModalText] = React.useState(<></>);

  const showModal = () => {
    setModalHeader("Add Disease Area");
    setModalText(
      <FormField3 handleSave={handleSave} closeModal={handleCancel} />
    );
    setVisible(true);
  };

  const showModalEdit = (id: number) => {
    const editData = fetchedData.find((product: any) => product.id === id);
    console.log("Editing", editData);
    setModalHeader("Edit Disease Area");
    setModalText(
      <FormField3
        handleEdit={handleEdit}
        editData={editData}
        closeModal={handleCancel}
      />
    );
    setVisible(true);
  };

  const handleOk = () => {
    setModalText(<p>Creating...</p>);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1500);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  async function handleSave(value: any) {
    value.id = 0;
    value.createdBy = window.localStorage.getItem("userName");
    value.lastUpdatedBy = window.localStorage.getItem("userName");
    if (value.createdBy === null || value.createdBy === undefined) {
      message.error("Please try again");
      return;
    }
    if (!value.userRoles) value.userRoles = [];
    value.userRoles.push({
      userId: String(window.localStorage.getItem("userName")),
      roleId: 2,
    });
    console.log("Adding new product", value);
    message.loading("Saving changes!", 1).then(() => {
      request
        .post("/EditProduct", value)
        .then((res: any) => {
          if (res.status === 200) {
            value.id = res.data;
            let temp = JSON.parse(JSON.stringify(fetchedData));
            temp.push(value);
            setFetchedData([...temp]);
            message.success("Saved!");
          } else message.error("Error!");
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
  }

  async function handleDelete(id: number) {
    const key = "updatable";
    console.log("deleting product id", id);
    // message.loading({ content: "Saving changes...", key });
    message.loading("Deleting!", 3).then(() => {
      request
        .delete("/DeleteProduct?id=" + id)
        .then((res: any) => {
          if (res.status === 200) {
            let temp = JSON.parse(JSON.stringify(fetchedData));
            for (let i = 0; i < temp.length; i++) {
              if (temp[i]["id"] === id) {
                temp.splice(i, 1);
                break;
              }
            }
            setFetchedData([...temp]);
            message.success("Deleted Successfully!");
          } else message.error("Error!");
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
  }

  async function handleEdit(value: any, id: number) {
    value.id = id;
    value.lastUpdatedBy = window.localStorage.getItem("userName");
    if (value.lastUpdatedBy === null || value.lastUpdatedBy === undefined) {
      message.error("Please try again");
      return;
    }
    if (!value.userRoles) value.userRoles = [];
    console.log("Editing product", value);
    message.loading("Saving changes!", 1).then(() => {
      request
        .post("/EditProduct", value)
        .then((res: any) => {
          if (res.status === 200) {
            let temp = JSON.parse(JSON.stringify(fetchedData));
            temp.map((item: any) => {
              if (item.id === id) {
                item.userRoles = value.userRoles;
                item.name = value.name;
                item.description = value.description;
              }
            });
            setFetchedData([...temp]);
            message.success("Saved!");
          } else message.error("Error!");
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
  }

  return (
    <>
      {fetchedData ? (
        <>
          <Modal
            destroyOnClose={true}
            title={modalHeader}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={null}
          >
            <p>{modalText}</p>
          </Modal>
          <LandingContainer>
            <div
              style={{
                marginTop: "1.5rem",
                position: "absolute",
                right: "20px",
              }}
              className="flex justify-end items-end"
            >
              <a
                style={{
                  padding: "0.8rem 0.6rem",
                }}
                href={process.env.REACT_APP_ADD_USER_URL}
                target="_blank"
              >
                <UserAdd
                  style={{
                    marginBottom: "0.3em",
                    marginRight: "0.3em",
                  }}
                  size={26}
                />
                <span style={{ fontSize: "16px" }}>Create User</span>
              </a>
              <a
                style={{
                  // background: "rgb(25,28,39)",
                  padding: "0.8rem 0.6rem",
                }}
                onClick={() => {
                  window.sessionStorage.setItem("appInfo", "");
                }}
                href={"/"}
                // target="_blank"
              >
                <Home7
                  style={{
                    marginBottom: "0.3em",
                    marginRight: "0.3em",
                  }}
                  size={26}
                />
                <span style={{ fontSize: "16px" }}>Home</span>
              </a>
              <a
                style={{
                  // background: "rgb(25,28,39)",
                  padding: "0.8rem 0.6rem",
                }}
                href={process.env.REACT_APP_SIGNOUT_URL}
              >
                <LogOut
                  style={{
                    marginBottom: "0.3em",
                    marginRight: "0.3em",
                  }}
                  size={26}
                />
                <span className="text-white" style={{ fontSize: "16px" }}>
                  {window.localStorage.getItem("userName")}
                </span>
              </a>
            </div>

            <StyledHeaderContainer>
              <StyledHeader>Systematic Literature Review</StyledHeader>
            </StyledHeaderContainer>

            <div className="flex items-center justify-center">
              <p
                className={`text-white text-center text-4xl mb-0 uppercase tracking-widest ${
                  window.innerWidth < 1250 ? "mt-8" : ""
                }`}
              >
                Disease Areas
              </p>
            </div>

            <StyledBrandsContainer>
              {fetchedData &&
                fetchedData.map((brand: any) => {
                  return (
                    <StyledBrands>
                      <div
                        onClick={() => {
                          console.log("setting product name");
                          window.sessionStorage.setItem(
                            "selectedProduct",
                            brand.name
                          );
                          window.sessionStorage.setItem(
                            "selectedProductId",
                            brand.id
                          );
                          const userName =
                            window.localStorage.getItem("userName");
                          let isAdmin = "1";
                          brand.userRoles.map((userRole: any) => {
                            if (userRole.userId === userName)
                              isAdmin = userRole.roleId;
                          });
                          window.sessionStorage.setItem("isAdmin", isAdmin);
                          console.log(isAdmin, userName);
                        }}
                      >
                        <LandingTile
                          id={brand.id}
                          split={window.innerWidth < 1250 ? true : false}
                          header={brand.name}
                          description={brand.description}
                          createdBy={brand.createdBy}
                          lastUpdatedBy={brand.lastUpdatedBy}
                          handleDelete={handleDelete}
                          role={
                            brand.userRoles.find(
                              (userRole: any) =>
                                userRole.userId ===
                                window.localStorage.getItem("userName")
                            )?.roleId
                          }
                          link={
                            "/SLR/overview/" +
                            // brand.id +
                            // "/" +
                            brand.name.split(" ").join("_")
                            //+ "/"
                          }
                          showModalEdit={showModalEdit}
                        />
                      </div>
                    </StyledBrands>
                  );
                })}
              <StyledBrands>
                <LandingTile
                  id={0}
                  header={""}
                  description={""}
                  createdBy={""}
                  lastUpdatedBy={""}
                  add
                  onClick={showModal}
                />
              </StyledBrands>
            </StyledBrandsContainer>
          </LandingContainer>
        </>
      ) : (
        <>
          <LandingContainer>
            <StyledHeaderContainer>
              <StyledHeader>Systematic Literature Review</StyledHeader>
            </StyledHeaderContainer>
            <StyledWelcomeContainer>
              <p>
                <Skeleton.Input style={{ width: 300 }} active size="large" />
              </p>
              {/* <h2>Ayushi Agarwal</h2> */}
              <h3>
                <Skeleton.Input style={{ width: 500 }} active size="large" />
              </h3>
            </StyledWelcomeContainer>
            <StyledBrandsContainer>
              <StyledBrands>
                <Skeleton.Avatar active size={200} shape="square" />
              </StyledBrands>
              <StyledBrands>
                <Skeleton.Avatar active size={200} shape="square" />
              </StyledBrands>
              <StyledBrands>
                <Skeleton.Avatar active size={200} shape="square" />
              </StyledBrands>
            </StyledBrandsContainer>
          </LandingContainer>
        </>
      )}
    </>
  );
}
