import BeatLoader from "react-spinners/BeatLoader";
import {
  EditFilled,
  SaveOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  StarFilled,
  DeleteOutlined,
  InfoCircleOutlined,
  FileOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Tooltip,
} from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CircleFill } from "@styled-icons/bootstrap/CircleFill";
import { TextBulletListSquare } from "@styled-icons/fluentui-system-regular/TextBulletListSquare";
import ReactFlow, {
  addEdge,
  FitViewOptions,
  applyNodeChanges,
  applyEdgeChanges,
  Node,
  Edge,
  NodeChange,
  EdgeChange,
  Connection,
  useReactFlow,
  Handle,
  Position,
  useUpdateNodeInternals,
  updateEdge,
} from "react-flow-renderer";
import "./style.css";
import { request } from "../../layout/SLR_Layout";
import SimpleMdeReact from "react-simplemde-editor";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router-dom";

const fitViewOptions: FitViewOptions = {
  padding: 0.02,
  minZoom: -2,
};

export default function Prisma() {
  const [inEditMode, setInEditMode] = useState(false);
  const [inReadOnlyMode, setInReadOnlyMode] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [keySelected, setKeySelected] = useState("prisma");
  const [data, setData] = useState<any>([]);
  const [initialData, setInitialData] = useState<any>([]);
  const [editColumnName, setEditColumnName] = useState<any>("");
  const [isModalVisibleAddStudy, setIsModalVisibleAddStudy] = useState(false);
  const [isModalVisibleConfirmDelete, setIsModalVisibleConfirmDelete] =
    useState(false);
  const [deleteStudykey, setDeleteStudyKey] = useState<any>();
  const [addStudyName, setAddStudyName] = useState<any>();
  const [addStudyReference, setAddStudyReference] = useState<any>();
  const [isModalVisibleEditStudy, setIsModalVisibleEditStudy] = useState(false);
  const [editStudykey, setEditStudyKey] = useState<any>();
  const [nodes, setNodes] = useState<Node[]>([]);
  const [nodesHoverData, setNodesHoverData] = useState<any>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);
  const [initialNodesHoverData, setInitialNodesHoverData] = useState<any>([]);
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);
  const [sections, setSections] = useState<any>();
  const [noData, setNoData] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [screenInfo, setScreenInfo] = useState<any>(
    "This section shows a Preferred Reporting Items for Systematic Reviews and Meta-Analyses (PRISMA) flow diagram with the reasons for exclusion of each study and details of the studies included in this systematic review."
  );
  const [footnote, setFootnote] = useState<any>("");
  const [footnoteInitial, setFootnoteInitial] = useState<any>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inEditModeFootnote, setInEditModeFootnote] = useState(false);
  const [isLoadingFootnote, setIsLoadingFootnote] = useState(true);

  const yPos = useRef(20);
  const nodeId = useRef(1);
  const nodeTypesEdit = useMemo(
    () => ({ textUpdater: TextUpdaterNode }),
    [nodesHoverData, nodeId]
  );
  const nodeTypesRead = useMemo(
    () => ({ textUpdater: TextUpdaterNodeRead }),
    [nodesHoverData, nodeId]
  );
  const edgeUpdateSuccessful = useRef(true);
  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "100%",
      maxHeight: "100%",
    };
  }, []);
  const navigate = useHistory();

  useEffect(() => {
    if (
      window.sessionStorage.getItem("selectedBackgroundName") === undefined ||
      window.sessionStorage.getItem("selectedBackgroundName") === null ||
      window.sessionStorage.getItem("selectedBackgroundName") === "" ||
      window.sessionStorage.getItem("productReviewId") === undefined ||
      window.sessionStorage.getItem("productReviewId") === null ||
      window.sessionStorage.getItem("productReviewId") === "" ||
      window.localStorage.getItem("productId") === undefined ||
      window.localStorage.getItem("productId") === null ||
      window.localStorage.getItem("productId") === ""
    ) {
      navigate.push("/SLR");
      return;
    }
    if (window.sessionStorage.getItem("redirectToStudy") === "true") {
      setKeySelected("studies");
      window.sessionStorage.setItem("redirectToStudy", "false");
    }
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    request
      .get("/GetFlowchart?productReviewId=" + productReviewId + "&sectionId=1")
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (res.data.node.length === 0 || !res.data.node) setNoData(true);
          let tempNodes = [...res.data.node];
          let tempSections = [...res.data.phaseData];
          let tempEdges = [...res.data.edge];
          let tempHoverData = [...res.data.nodeHoverData];
          console.log("tempSections", tempSections);
          tempNodes.map((node: any) => {
            node.className = "hover:border-black hover:shadow-xl";
            node.id = String(node.id);
            node.type = "textUpdater";
            delete node.phaseId;
          });
          console.log("Nodes :", tempNodes);
          tempEdges.map((edge: any, index: any) => {
            edge.type = "step";
            edge.id = String(index);
            edge.source = String(edge.source);
            edge.target = String(edge.target);
            delete edge.sectionId;
          });
          console.log("edges :", tempEdges);
          tempHoverData.map((item: any) => (item.id = String(item.id)));
          console.log("hoverData", tempHoverData);
          nodeId.current = Number(tempNodes?.length) || 1;
          setNodes([...tempNodes]);
          setEdges([...tempEdges]);
          setNodesHoverData([...tempHoverData]);
          setInitialNodes([...tempNodes]);
          setInitialEdges([...tempEdges]);
          setInitialNodesHoverData([...tempHoverData]);
          setSections([...tempSections]);
        } else {
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      })
      .finally(() => {
        //api to get study names
        setIsLoading(true);
        request
          .get(
            "/GetStudyNames?productReviewId=" + productReviewId + "&sectionId=1"
          )
          .then((res: any) => {
            console.log("response from API", res);
            if (res.status === 200) {
              setData([...res.data]);
            } else {
              message.error("Error!");
            }
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          })
          .finally(() => setIsLoading(false));
      });
  }, []);

  function TextUpdaterNode(data: any) {
    const [hoverData, setHoverData] = useState<any>("");
    const [open, setOpen] = useState(false);

    const style = useMemo(
      () => ({
        height: "100%",
        minWidth: "10rem",
        maxWidth: "50rem",
        background: data.data?.color,
        padding: 5,
        borderRadius: 5,
      }),
      []
    );

    useEffect(() => {
      setHoverData(nodesHoverData.find((ele: any) => ele.id === data.id)?.data);
    }, []);

    const onChange = useCallback((evt) => {
      setNodes((els: any) => {
        els.map((el: any) => {
          if (el.id === data.id) {
            el.data.label = evt.target.value;
          }
        });
        return [...els];
      });
    }, []);

    function onSaveHoverData() {
      let temp = [...nodesHoverData];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]["id"] === data.id) {
          temp[i]["data"] = hoverData;
        }
      }
      setNodesHoverData([...temp]);
      setOpen(false);
    }

    const handleOpenChange = (newOpen: boolean) => {
      setOpen(newOpen);
    };

    return (
      <div style={style}>
        <Handle type="target" position={Position.Top} id="a" />
        <div className="flex w-full items-center">
          <input
            style={{ height: "100%", width: "95%" }}
            id="text"
            name="text"
            onChange={onChange}
            placeholder="Enter Data Here"
            className="px-3 py-1 bg-transparent"
            defaultValue={data.data.label}
          />
          <div className="pr-4" style={{ width: "5%" }}>
            <Popover
              placement="right"
              visible={open}
              onVisibleChange={handleOpenChange}
              content={
                <div style={{ minHeight: "5rem" }}>
                  <textarea
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      resize: "none",
                      outline: "none",
                      border: "0.5px solid lightgray",
                    }}
                    className="text-xs"
                    rows={4}
                    onChange={(e: any) => setHoverData(e.target.value)}
                    defaultValue={hoverData}
                  />
                  <br />
                  <div className="flex justify-end items-end mt-1">
                    <Button
                      size="small"
                      type="default"
                      onClick={onSaveHoverData}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              }
              trigger="click"
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
        </div>
        <Handle type="source" position={Position.Right} id="b" />
        <Handle type="target" position={Position.Left} id="c" />
        <Handle type="source" position={Position.Bottom} id="d" />
      </div>
    );
  }

  function TextUpdaterNodeRead(data: any) {
    const [hoverData, setHoverData] = useState<any>("");
    const [open, setOpen] = useState(false);

    const style = useMemo(
      () => ({
        height: "100%",
        minWidth: "10rem",
        maxWidth: "50rem",
        background: data.data?.color,
        padding: "0.5rem",
        borderRadius: 5,
      }),
      []
    );

    useEffect(() => {
      setHoverData(nodesHoverData.find((ele: any) => ele.id === data.id)?.data);
    }, []);

    const handleOpenChange = (newOpen: boolean) => {
      setOpen(newOpen);
    };

    return (
      <div className="flex justify-center items-center" style={style}>
        <Handle
          style={{ backgroundColor: "transparent" }}
          type="target"
          position={Position.Top}
          id="a"
        />
        <div style={{ width: "100%" }} className="flex items-center">
          <div className="text-center" style={{ width: "95%" }}>
            {data.data.label}
          </div>
          <div className="pr-4 " style={{ width: "5%" }}>
            <Popover
              placement="right"
              visible={open}
              onVisibleChange={handleOpenChange}
              content={
                <div style={{ maxWidth: "10rem" }}>
                  <div className="text-xs">{hoverData}</div>
                  <br />
                  <div className="flex justify-end items-end mt-1">
                    <Button
                      size="small"
                      type="default"
                      onClick={() => setOpen(false)}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              }
              trigger="click"
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
        </div>
        <Handle
          style={{ backgroundColor: "transparent" }}
          type="source"
          position={Position.Right}
          id="b"
        />
        <Handle
          style={{ backgroundColor: "transparent" }}
          type="target"
          position={Position.Left}
          id="c"
        />
        <Handle
          style={{ backgroundColor: "transparent" }}
          type="source"
          position={Position.Bottom}
          id="d"
        />
      </div>
    );
  }

  function saveHandler() {
    if (keySelected === "studies") {
      return;
    }

    let tempNodeHoverData: any = [];
    nodesHoverData.map((item: any) => {
      let index = nodes.findIndex((x) => String(x.id) === String(item["id"]));
      if (index > -1) {
        tempNodeHoverData.push(item);
      }
    });

    let colorId: any = {};
    sections.map((section: any) => {
      colorId[section.color] = section.id;
    });

    let tempNodes: any = JSON.parse(JSON.stringify(nodes));
    tempNodes.map((node: any) => {
      node.id = Number(node.id);
      node.phaseId = colorId[node.data.color];
      node.position.x = Math.floor(node.position.x);
      node.position.y = Math.floor(node.position.y);
      delete node.className;
    });

    let tempEdges: any = JSON.parse(JSON.stringify(edges));
    tempEdges.map((edge: any) => {
      edge.sectionId = 1;
      edge.source = Number(edge.source);
      edge.target = Number(edge.target);
      delete edge.id;
      delete edge.type;
    });

    tempNodeHoverData.map((node: any) => {
      node.id = Number(node.id);
    });

    console.log(tempNodes, tempEdges, tempNodeHoverData, colorId);

    const productReviewId = window.sessionStorage.getItem("productReviewId");
    message.loading("Saving Changes!", 1.8);

    request
      .post(
        "/EditFlowchart?productReviewId=" +
          String(productReviewId) +
          "&sectionId=1",
        {
          nodeHoverData: tempNodeHoverData,
          node: tempNodes,
          edge: tempEdges,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setInitialNodes([...nodes]);
          setInitialEdges([...edges]);
          nodesHoverData.map((node: any) => {
            node.id = String(node.id);
          });
          setNodesHoverData([...nodesHoverData]);
          setInitialNodesHoverData([...nodesHoverData]);
          setNoData(false);
          message.success("Saved!");
        } else {
          setNodes([...initialNodes]);
          setEdges([...initialEdges]);
          setNodesHoverData([...initialNodesHoverData]);
          message.error("Error!");
        }
        console.log("res", res);
      })
      .catch((err) => {
        console.log(err);
        message.error("Error!");
      });
    setInEditMode(false);
    setInReadOnlyMode(true);
  }

  function handleMenuChange(value: any) {
    if (inEditMode) {
      message.warning("Please save your data!");
      return;
    }
    setKeySelected(value.key);
  }

  function addStudy() {
    if (addStudyName) {
      let tempData = [...data];

      let dataToSend: any = {
        id: 0,
        studyName: addStudyName,
        reference: addStudyReference,
      };

      console.log("dataTosend", dataToSend);
      const productReviewId = window.sessionStorage.getItem("productReviewId");
      message.loading("Saving Changes!", 1.8);

      request
        .post(
          "/EditStudyNames?productReviewId=" + String(productReviewId),
          dataToSend
        )
        .then((res) => {
          if (res.status === 200) {
            dataToSend.id = res.data;
            tempData.push(dataToSend);
            setData([...tempData]);
            setInitialData([...tempData]);
            setAddStudyName("");
            setAddStudyReference("");
            message.success("Saved!");
          } else {
            setData([...initialData]);
            setAddStudyName("");
            setAddStudyReference("");
            message.error("Error!");
          }
          console.log("res", res);
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
      setData([...tempData]);
      setAddStudyName("");
      setAddStudyReference("");
    } else message.error("Please try again!");
    setIsModalVisibleAddStudy(false);
  }

  function editStudy() {
    if (addStudyName) {
      let tempData = [...data];
      let dataToSend: any = {};
      tempData.map((data: any) => {
        if (data["id"] === editStudykey) {
          data["studyName"] = addStudyName;
          data["reference"] = addStudyReference;
          dataToSend = data;
        }
      });
      console.log("dataTosend", dataToSend);
      const productReviewId = window.sessionStorage.getItem("productReviewId");
      message.loading("Saving Changes!", 1.8);

      request
        .post(
          "/EditStudyNames?productReviewId=" + String(productReviewId),
          dataToSend
        )
        .then((res) => {
          if (res.status === 200) {
            setData([...tempData]);
            setInitialData([...tempData]);
            setAddStudyName("");
            setAddStudyReference("");
            message.success("Saved!");
          } else {
            setData([...initialData]);
            setAddStudyName("");
            setAddStudyReference("");
            message.error("Error!");
          }
          console.log("res", res);
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    } else message.error("Please try again!");
    setIsModalVisibleEditStudy(false);
  }

  function handleCancelAddStudy() {
    setIsModalVisibleAddStudy(false);
    setAddStudyName("");
    setAddStudyReference("");
  }

  function handleCancelEditStudy() {
    setIsModalVisibleEditStudy(false);
    setAddStudyName("");
    setAddStudyReference("");
  }

  const showModalConfirmDelete = (title: any) => {
    setEditColumnName(title);
    setIsModalVisibleConfirmDelete(true);
  };

  const showModalEditStudy = (key: any) => {
    setEditStudyKey(key);
    if (key !== null) {
      let temp = [...data];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]["id"] === key) {
          setAddStudyName(temp[i]["studyName"]);
          setAddStudyReference(temp[i]["reference"]);
          setIsModalVisibleEditStudy(true);
          break;
        }
      }
    } else message.error("Please try again!");
  };

  function confirmDelete() {
    console.log("removing study", deleteStudykey);
    if (deleteStudykey !== null && deleteStudykey !== undefined) {
      message.loading("Deleting!", 3).then(() => {
        request
          .delete("/DeleteStudy?id=" + String(deleteStudykey))
          .then((res) => {
            if (res.status === 200) {
              let temp = [...data];
              for (let i = 0; i < temp.length; i++) {
                if (temp[i]["id"] === deleteStudykey) {
                  temp.splice(i, 1);
                  break;
                }
              }
              setData([...temp]);
              setDeleteStudyKey(null);
              message.success("Saved!");
            } else {
              message.error("Error!");
            }
          })
          .catch((err) => {
            message.error("Error!");
            console.log(err);
          });
      });
    } else message.error("Please try again!");

    setIsModalVisibleConfirmDelete(false);
  }

  const handleCancelConfirmDelete = () => {
    setIsModalVisibleConfirmDelete(false);
  };

  function updateLayout() {
    let temp = [...edges];
    temp.map((t: any) => {
      t["type"] = "step";
    });
    setEdges([...temp]);
    setInReadOnlyMode(true);
  }

  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const onClickAddNode = useCallback(
    (phaseId: any, color: any) => {
      setNodes((els: any) => {
        yPos.current += 60;
        yPos.current = yPos.current > 500 ? 0 : yPos.current;
        return [
          ...els,
          {
            id: `${++nodeId.current}`,
            position: { x: 20, y: yPos.current },
            data: {
              label: `Node ${nodeId.current}`,
              color: String(color),
            },
            type: "textUpdater",
          },
        ];
      });

      let temp = [...nodesHoverData];
      temp.push({
        id: String(nodeId.current),
        data: "",
      });
      setNodesHoverData([...temp]);
    },
    [nodesHoverData, nodeId.current]
  );

  const handlePopOverOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  function saveHandlerFootnote() {
    console.log("data after save", footnote);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Prisma"];
    message.loading("Saving Changes!", 0.8).then(() => {
      request
        .post(
          "/EditFootNote?productReviewId=" +
            productReviewId +
            "&id=" +
            sectionId,
          {
            footNote: footnote,
          }
        )
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            message.success("Saved!");
            setFootnoteInitial(footnote);
          } else {
            message.error("Error!");
            setFootnote(footnoteInitial);
          }
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
    setInEditModeFootnote(false);
  }

  function showDrawerFootnote() {
    setIsLoadingFootnote(true);

    setDrawerOpen(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Prisma"];
    request
      .get(
        "/GetFootNote?productReviewId=" + productReviewId + "&id=" + sectionId
      )
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (!res.data.footNote) setFootnote("");
          else setFootnote(res.data.footNote);
          setFootnoteInitial(res.data.footNote);
          setIsLoadingFootnote(false);
        } else {
          setIsLoadingFootnote(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoadingFootnote(false);
        console.log(err);
      });
    // setIsLoadingFootnote(false);
  }

  return (
    <div style={{ overflow: "hidden", position: "relative", height: "100%" }}>
      <div className="flex items-center pr-4 bg-white">
        <div className="w-1/2 flex items-center">
          <Popover
            placement="bottomLeft"
            visible={popoverOpen}
            onVisibleChange={handlePopOverOpenChange}
            style={{ top: "4rem" }}
            overlayStyle={{
              minHeight: "2rem",
              maxWidth: "40rem",
            }}
            content={
              <div className="text-xs" style={{ maxWidth: "30rem" }}>
                <p>{screenInfo}</p>
              </div>
            }
            trigger="hover"
          >
            <InfoCircleOutlined
              style={{
                marginLeft: "1.2rem",
                fontSize: "1.1rem",
                // marginTop: "0.2rem",
              }}
            />
          </Popover>
          <Menu
            onClick={handleMenuChange}
            mode="horizontal"
            defaultSelectedKeys={["prisma"]}
            // activeKey={keySelected}
            selectedKeys={[keySelected]}
            className="w-full"
          >
            <Menu.Item key="prisma">PRISMA</Menu.Item>
            <Menu.Item key="studies">Studies Included</Menu.Item>
          </Menu>
        </div>
        {window.sessionStorage.getItem("isAdmin") === "2" &&
          keySelected !== "studies" && (
            <div className="flex w-1/2 justify-end items-center">
              {inEditMode ? (
                <div>
                  <Button type="default" onClick={saveHandler}>
                    <SaveOutlined />
                    Save
                  </Button>
                  <Button
                    className="ml-2"
                    type="default"
                    onClick={() => {
                      setNodes([...initialNodes]);
                      setEdges([...initialEdges]);
                      setNodesHoverData([...initialNodesHoverData]);
                      setInEditMode(false);
                    }}
                  >
                    <CloseOutlined />
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button
                  type="default"
                  onClick={() => {
                    setInEditMode(true);
                    setInReadOnlyMode(false);
                  }}
                >
                  <EditFilled />
                  Edit
                </Button>
              )}
              <TextBulletListSquare
                size={30}
                style={{
                  marginLeft: "0.6rem",
                  color: "#27a6a4",
                  cursor: "pointer",
                }}
                onClick={showDrawerFootnote}
                title="Open Footnotes"
              />
            </div>
          )}
      </div>

      {isLoading ? (
        <div
          style={{ height: "80vh" }}
          className="flex mt-4 items-center justify-center mx-4 bg-white px-2 py-2 shadow-md"
        >
          <BeatLoader color={"#27A6A4"} size={30} />
        </div>
      ) : (
        <div>
          {keySelected === "prisma" ? (
            noData && !inEditMode ? (
              <div
                className="mt-4 mx-4 bg-white rounded-sm shadow-md flex items-center justify-center"
                style={{ minHeight: "80vh", overflowY: "auto" }}
              >
                <div
                  className="m-4 bg-white border-2 rounded-sm flex items-center justify-center w-full"
                  style={{ minHeight: "75vh", overflowY: "auto" }}
                >
                  {window.sessionStorage.getItem("isAdmin") === "2" ? (
                    <div className="text-center">
                      <EditFilled
                        style={{ color: "#27a6a4", fontSize: "3rem" }}
                      />
                      <p className="text-xl mt-2 italic">
                        Click on Edit to add details!
                      </p>
                    </div>
                  ) : (
                    <div className="text-center">
                      <FileOutlined
                        style={{ color: "#27a6a4", fontSize: "3rem" }}
                      />
                      <p className="text-xl mt-2 italic">No Details Added!</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {inEditMode &&
                  (inReadOnlyMode ? (
                    <div>
                      <div className="bg-white m-4 flex">
                        <div
                          className="m-4 border rounded-md"
                          style={{ height: "77vh", width: "80%" }}
                        >
                          <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            fitView={true}
                            nodeTypes={nodeTypesRead}
                            panOnDrag={true}
                            nodesDraggable={true}
                            onNodesChange={undefined}
                            onEdgesChange={undefined}
                            onConnect={undefined}
                            fitViewOptions={fitViewOptions}
                            defaultZoom={-2}
                            onEdgeUpdate={undefined}
                            onEdgeUpdateStart={undefined}
                            onEdgeUpdateEnd={undefined}
                            deleteKeyCode={"Delete"}
                          />
                        </div>
                        <div className="m-4" style={{ width: "20%" }}>
                          <div className="mt-4">
                            {sections &&
                              sections.map((section: any) => {
                                return (
                                  <div className="flex items-center justify-start">
                                    <div
                                      style={{ backgroundColor: section.color }}
                                      className="h-4 w-4 rounded-sm"
                                    />
                                    <p className="ml-2"> {section.phaseName}</p>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="mt-2 pt-2 border-t py-2">
                            <div>
                              <Button
                                type="default"
                                size="small"
                                onClick={() => {
                                  setInReadOnlyMode(false);
                                }}
                                style={{ width: "100%" }}
                              >
                                Update Chart
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="bg-white m-4 flex ">
                        <div
                          className="m-4 border rounded-md"
                          style={{ height: "77vh", width: "80%" }}
                        >
                          <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            nodeTypes={nodeTypesEdit}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            fitView={true}
                            fitViewOptions={fitViewOptions}
                            onEdgeUpdate={onEdgeUpdate}
                            onEdgeUpdateStart={onEdgeUpdateStart}
                            onEdgeUpdateEnd={onEdgeUpdateEnd}
                            nodesDraggable={true}
                            deleteKeyCode={"Delete"}
                            // style={{
                            //   backgroundColor: "#FFE3C8",
                            // }}
                          />
                        </div>
                        <div
                          style={{ width: "20%", height: "100%" }}
                          className="m-4"
                        >
                          <div className="mt-2">
                            {sections &&
                              sections.map((section: any) => {
                                return (
                                  <div className="flex items-center justify-start">
                                    <div
                                      style={{ backgroundColor: section.color }}
                                      className="h-4 w-4 rounded-sm"
                                    />
                                    <p className="ml-2"> {section.phaseName}</p>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="mt-2 pt-2 border-t border-b py-2">
                            <div>
                              <Button
                                type="default"
                                size="small"
                                onClick={updateLayout}
                                style={{ width: "100%" }}
                              >
                                Update Layout
                              </Button>
                            </div>
                            {sections &&
                              sections.map((section: any) => {
                                return (
                                  <p
                                    style={{ color: "#27a6a4" }}
                                    className="font-semibold cursor-pointer mt-2"
                                    onClick={() =>
                                      onClickAddNode(section.id, section.color)
                                    }
                                  >
                                    <PlusCircleOutlined
                                      style={{ color: "#27a6a4" }}
                                    />{" "}
                                    {section.phaseName} Node
                                  </p>
                                );
                              })}
                          </div>
                          <div className="flex items-end">
                            <div className="mt-2 text-xs">
                              <p className="font-semibold text-md">
                                Instructions for creating flowchart
                              </p>
                              <p className="my-1">
                                1. Click on buttons above to add a node under
                                corresponding section.
                              </p>
                              <p className="my-1">
                                2. To delete a node, click on the node and press
                                Delete key.
                              </p>
                              <p className="my-1">
                                3. Connect two nodes by drawing an edge using
                                the handles between them.
                              </p>
                              <p className="my-1">
                                4. To remove an edge, simply drag the edge from
                                its endpoint and release it.
                              </p>
                              <p className="my-1">
                                5. You can edit the metadata by clicking on{" "}
                                <InfoCircleOutlined /> icon next to node's
                                label. Do not forget to save the data!
                              </p>
                              <p className="my-1">
                                6. You can always drag the nodes to your desired
                                position.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {!inEditMode && nodes && (
                  <div className="bg-white m-4 flex">
                    <div
                      className="m-4 border rounded-md"
                      style={{ height: "77vh", width: "80%" }}
                    >
                      <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        fitView={true}
                        nodeTypes={nodeTypesRead}
                        panOnDrag={true}
                        nodesDraggable={false}
                        onNodesChange={undefined}
                        onEdgesChange={undefined}
                        onConnect={undefined}
                        fitViewOptions={fitViewOptions}
                        onEdgeUpdate={undefined}
                        onEdgeUpdateStart={undefined}
                        onEdgeUpdateEnd={undefined}
                        deleteKeyCode={"Delete"}
                      />
                    </div>
                    <div className="m-4" style={{ width: "20%" }}>
                      <div className="mt-4">
                        {sections &&
                          sections.map((section: any) => {
                            return (
                              <div className="flex items-center justify-start">
                                <div
                                  style={{ backgroundColor: section.color }}
                                  className="h-4 w-4 rounded-sm"
                                />
                                <p className="ml-2"> {section.phaseName}</p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          ) : data.length === 0 &&
            window.sessionStorage.getItem("isAdmin") === "1" ? (
            <div
              className="mt-4 mx-4 bg-white rounded-sm shadow-md flex items-center justify-center"
              style={{ minHeight: "80vh", overflowY: "auto" }}
            >
              <div className="text-center">
                <FileOutlined style={{ color: "#27a6a4", fontSize: "3rem" }} />
                <p className="text-xl mt-2">No Details Added!</p>
              </div>
            </div>
          ) : (
            <div className="m-4 p-4">
              <div>
                <div className="mt-4">
                  <Row>
                    {data.map((study: any, index: any) => {
                      return (
                        <Col span={6}>
                          <div
                            style={{ minHeight: "6rem" }}
                            className="bg-white shadow-md rounded-md border mx-4 mt-2 mb-6 p-3"
                          >
                            <div className="flex items-center justify-start">
                              <p
                                style={{ width: "90%", color: "#27a6a4" }}
                                className="text-xl font-semibold"
                              >
                                {study.studyName}
                              </p>
                              {window.sessionStorage.getItem("isAdmin") ===
                                "2" && (
                                <DeleteOutlined
                                  style={{
                                    width: "5%",
                                    marginLeft: "0.2rem",
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    color: "#27a6a4",
                                  }}
                                  onClick={() => {
                                    setDeleteStudyKey(study.id);
                                    showModalConfirmDelete(study.studyName);
                                  }}
                                />
                              )}
                              {window.sessionStorage.getItem("isAdmin") ===
                                "2" && (
                                <EditFilled
                                  style={{
                                    width: "5%",
                                    marginLeft: "0.4rem",
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    color: "#27a6a4",
                                  }}
                                  onClick={() => showModalEditStudy(study.id)}
                                />
                              )}
                            </div>
                            <hr className="mt-2" />
                            <Tooltip
                              title={
                                <p className="text-xs">{study.reference}</p>
                              }
                            >
                              <a href={study.reference} target="_blank">
                                <p className="text-xs font-semibold text-blue-500 w-full mt-2 overflow-hidden">
                                  {study.reference}
                                </p>
                              </a>
                            </Tooltip>
                          </div>
                        </Col>
                      );
                    })}
                    {window.sessionStorage.getItem("isAdmin") === "2" && (
                      <Col span={6}>
                        <div
                          style={{ minHeight: "6rem" }}
                          className="bg-white shadow-md rounded-md border mx-4 mt-2 mb-6 p-3 flex items-center justify-center"
                        >
                          <div
                            className="align-middle text-center"
                            onClick={() => setIsModalVisibleAddStudy(true)}
                          >
                            <PlusCircleOutlined
                              style={{
                                color: "#27a6a4",
                                fontSize: "1rem",
                                cursor: "pointer",
                              }}
                            />
                            <p
                              style={{
                                color: "#27a6a4",
                              }}
                              className="cursor-pointer ml-1 font-semibold"
                            >
                              Add Study
                            </p>
                          </div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <Modal
        className="editTableModal"
        title={<p className="text-white">{editColumnName}</p>}
        visible={isModalVisibleConfirmDelete}
        onOk={confirmDelete}
        onCancel={handleCancelConfirmDelete}
        closable={false}
        destroyOnClose={true}
      >
        <p>Are you sure you want to delete this study?</p>
        <p className="mt-4 text-xs italic text-red-500">
          * This will delete all data related to this study!
        </p>
      </Modal>

      <Modal
        className="editTableModal"
        title={<p className="text-white">Add Study</p>}
        visible={isModalVisibleAddStudy}
        onOk={addStudy}
        okText="Save"
        onCancel={handleCancelAddStudy}
        closable={false}
        destroyOnClose={true}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
      >
        <div className="flex items-center justify-center">
          <p className="mr-2 w-1/3">Study Name:</p>
          <input
            style={{ border: "1px solid black" }}
            placeholder="Enter Study Name"
            defaultValue={""}
            onChange={(e: any) => setAddStudyName(e.target.value)}
            className="p-0.5 w-full"
          />
        </div>
        <div className="flex items-center justify-center mt-4">
          <p className="mr-2  w-1/3">Reference:</p>
          <input
            style={{ border: "1px solid black" }}
            defaultValue={""}
            placeholder="Enter Reference"
            onChange={(e: any) => setAddStudyReference(e.target.value)}
            className="p-0.5 w-full"
          />
        </div>
      </Modal>

      <Modal
        className="editTableModal"
        title={<p className="text-white">Edit {addStudyName}</p>}
        visible={isModalVisibleEditStudy}
        onOk={editStudy}
        okText="Save"
        onCancel={handleCancelEditStudy}
        closable={false}
        destroyOnClose={true}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
      >
        <div className="flex items-center justify-center">
          <p className="mr-2 w-1/3">Study Name:</p>
          {addStudyName && (
            <input
              style={{ border: "1px solid black" }}
              placeholder="Enter Study Name"
              defaultValue={addStudyName}
              onChange={(e: any) => setAddStudyName(e.target.value)}
              className="p-0.5 w-full"
            />
          )}
        </div>
        <div className="flex items-center justify-center mt-4">
          <p className="mr-2  w-1/3">Reference:</p>
          <input
            style={{ border: "1px solid black" }}
            defaultValue={addStudyReference}
            placeholder="Enter Reference"
            onChange={(e: any) => setAddStudyReference(e.target.value)}
            className="p-0.5 w-full"
          />
        </div>
      </Modal>
      <Drawer
        title={
          <div className="flex">
            <p className="text-black mb-0 w-1/2">Footnotes</p>
            <div className="flex w-1/2 justify-end items-center">
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <div className="flex w-full justify-end items-center">
                  {inEditModeFootnote ? (
                    <div className="flex">
                      <Tooltip title="Save Changes">
                        <SaveOutlined
                          onClick={saveHandlerFootnote}
                          style={{ color: "black", fontSize: "1.2rem" }}
                        />
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <UndoOutlined
                          onClick={() => {
                            setInEditModeFootnote(false);
                            setFootnote(footnoteInitial);
                          }}
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            marginLeft: "0.6rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Edit Footnotes">
                      <EditFilled
                        onClick={() => setInEditModeFootnote(true)}
                        style={{ color: "black", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Close">
                    <CloseOutlined
                      onClick={() => {
                        setDrawerOpen(false);
                        setInEditModeFootnote(false);
                      }}
                      style={{
                        color: "black",
                        fontSize: "1.2rem",
                        marginLeft: "0.6rem",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        }
        closable={false}
        placement="bottom"
        onClose={() => {
          setDrawerOpen(false);
          setInEditModeFootnote(false);
        }}
        getContainer={false}
        visible={drawerOpen}
        style={{ position: "absolute" }}
        zIndex={20}
        bodyStyle={{ padding: "0.5rem" }}
        headerStyle={{
          paddingTop: "0.6rem",
          paddingBottom: "0.6rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "whitesmoke",
          color: "black",
        }}
      >
        {isLoadingFootnote ? (
          <div
            style={{ minHeight: "23vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : footnote === "" && !inEditModeFootnote ? (
          <div
            className="border flex items-center justify-center"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Edit icon to add footnotes!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">No Footnotes Added!</p>
              </div>
            )}
          </div>
        ) : inEditModeFootnote ? (
          <div
            className="border-r"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            <div id="introEditor" className="">
              <SimpleMdeReact
                options={options}
                value={footnote}
                onChange={(e) => {
                  let temp = footnote;
                  temp = String(e);
                  setFootnote(temp);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="border-r"
            style={{
              minHeight: "23vh",
              maxHeight: "23vh",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div id="SLRMDEditor" className="py-4 px-4">
              <ReactMarkdown children={footnote}></ReactMarkdown>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
