import React from "react";
import AppContext from "../../../../store";
import sample from "../ImageHolder/sample.jpg";
import { GearFill } from "@styled-icons/bootstrap/GearFill";
import {
	ImageContainer,
	ImageHeaderContainer,
	ImageHeaderText,
	ImageOverlayContaner,
	ImageOverlayHeader,
	ImageOverlaySubHeader,
	ImageOverlayProductContainer,
	ImageOverlayProductName,
	ImageOverlayProductDesription,
} from "./style";
import { request } from "../../..";
import { Skeleton } from "antd";

/**
 * Renders the Image Holder of the Body Layout.
 *
 * @param src - Specifies src url of the Banner Image
 * @param noImgHeader - Specifies the type of Image Header ie. Overlay or Side
 * @param imgHeader -  Specifies the Image Header String
 * @param headerTextBg -  Specifies the Image Header String Background Color
 * @param headerTextColor - Specifies the Image Header String Color
 * @param overlayHeader - Specifies whether the layout is of Type Objction Handler
 * @param overlayTextSubHeader - Specifies the Image Sub Header String
 * @param overlayProductName - Specifies the Product Name on the Executive Summary Page
 * @param overlayProductDescription - Specifies the Product Description on the Executive Summary Page
 * @param overlayTextColor - Specifies the Overlay Text Color of the Header String / Header Name
 * @param overlayTextColor2 - Specifies the Overlay Text Color of the Product Description
 * @param subSectionId - Specifies the Section Id
 * @param showModal1 - Function for Displaying the Modal Form 1
 * @param showModal2 - Function for Displaying the Modal Form 2
 *
 * @returns Body Layout of the App
 */
const ImageHolder = (props: {
	src: string;
	noImgHeader?: boolean;
	imgHeader?: string;
	headerTextBg?: string;
	headerTextColor?: string;
	overlayHeader?: string;
	overlayTextSubHeader?: string;
	overlayProductName?: string;
	overlayProductDescription?: string;
	overlayTextColor?: string;
	overlayTextColor2?: string;
	showModal1?: () => void;
	showModal2?: () => void;
	subSectionId: number;
}) => {
	const context = React.useContext(AppContext);
	const [image, setImage] = React.useState<any>(null);
	async function fetchImg(src: string) {
		const data: string | unknown = await request
			.get("brand/GetImage?imagePath=" + src, {
				withCredentials: true,
				responseType: "arraybuffer",
			})
			.then((response: any) => {
				console.log("RESP", response);
				setImage(
					Buffer.from(response.data, "binary").toString("base64")
				);
				return Buffer.from(response.data, "binary").toString("base64");
			})
			.catch((err: any) => {
				console.log(err.message, "error here");
				return null;
			});
		return data;
	}
	React.useEffect(() => {
		if (props.src) {
			fetchImg(props.src);
		}
	}, [props.src]);
	return (
		<ImageContainer imgWidth={props.noImgHeader ? 100 : 80}>
			{props.src ? (
				<>
					{image ? (
						<img
							src={"data:image/jpeg;base64," + image}
							alt="Banner Image"
						/>
					) : (
						<Skeleton.Image
							style={{
								width: "100%",
								height: "100%",
								minHeight: "250px",
							}}
						/>
					)}
				</>
			) : (
				<img src={sample} alt="Banner Image" />
			)}
			{context.isAdmin && (
				<GearFill
					size={18}
					style={{
						position: "absolute",
						bottom: "1em",
						right: "1em",
						cursor: "pointer",
					}}
					title="Edit Banner"
					onClick={props.showModal1}
				/>
			)}

			{!props.noImgHeader && (
				<ImageHeaderContainer headerBg={props.headerTextBg}>
					<ImageHeaderText textColor={props.headerTextColor}>
						{props.imgHeader || "Header Text Here"}
					</ImageHeaderText>
				</ImageHeaderContainer>
			)}
			{props.noImgHeader && (
				<ImageOverlayContaner>
					<ImageOverlayHeader
						overlayTextColor={props.overlayTextColor}
					>
						{props.imgHeader}
					</ImageOverlayHeader>
					<ImageOverlaySubHeader
						overlayTextColor={props.overlayTextColor}
					>
						{props.overlayTextSubHeader}
					</ImageOverlaySubHeader>
				</ImageOverlayContaner>
			)}
			{props.subSectionId === 1 && (
				<ImageOverlayProductContainer>
					<ImageOverlayProductName
						overlayProductNameColor={props.overlayTextColor}
					>
						{props.overlayProductName || context.productName}
					</ImageOverlayProductName>
					<ImageOverlayProductDesription
						overlayProductDescriptionColor={props.overlayTextColor2}
					>
						{props.overlayProductDescription}
					</ImageOverlayProductDesription>
				</ImageOverlayProductContainer>
			)}
		</ImageContainer>
	);
};

export default ImageHolder;
