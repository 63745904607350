import { ExternalLinkOutline } from "@styled-icons/evaicons-outline/ExternalLinkOutline";
import { ResponsiveBar } from "@nivo/bar";
import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import ReactMarkdown from "react-markdown";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import html2canvas from "html2canvas";
import { Download } from "@styled-icons/boxicons-regular/Download";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";
import { CheckboxChecked } from "@styled-icons/boxicons-solid/CheckboxChecked";
import PieChart from "../PieChart";
import { CloseOutlined, FileOutlined, EditFilled } from "@ant-design/icons";
import { CircleFill } from "@styled-icons/bootstrap/CircleFill";
import { Link } from "react-router-dom";

const styles: any = {
  fontFamily: "sans-serif",
  textAlign: "center",
};

const override = css`
  display: block;
  /* padding-left: 3%; */
  padding-top: 6%;
  border-color: red;
`;

const colorsBar = [
  "#fdae6b",
  "#01a6dc",
  "#bbe9d0",
  "#a0afc6",
  "#f47660",
  "#86c8bc",
  "#B8CC7B",
  "#ED8B00",
  "#a2ddff",
  "#e39ed6",
];

const BAR_MAX_WIDTH = 22;

export default function Dashboard(props: {
  originalColumns: any;
  originalData: any;
  columns: any;
  data: any;
  setSelectedCountry?: any;
  selectedCountry?: any;
  studyDetails?: any;
}) {
  const [columns, setColumns] = useState([...props.columns]);
  const [tableData, setTableData] = useState([...props.data]);
  const [renderTableData, setRenderTableData] = useState([...props.data]);
  const [barData, setBarData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [colors, setColors] = useState<any>([]);
  const [barKeys, setBarKeys] = useState<any>([]);
  const [highlightRowId, setHighlightRowId] = useState<any>();
  const [plotBy, setPlotBy] = useState("ntotalrandomized");
  const [plotByName, setPlotByName] = useState("N Total Randomized");
  const [primaryPlotBy, setPrimaryPlotBy] = useState("ethnicity");
  const [primaryPlotByName, setPrimaryPlotByName] = useState("Study Design");
  const [primaryData, setPrimaryData] = useState<any>([]);
  const [averageValue, setAverageValue] = useState(0);
  const [interventionValues, setInterventionValues] = useState<any>([]);
  const [countryValues, setCountryValues] = useState<any>([]);
  const [countrySelected, setCountrySelected] = useState<any>("all");
  const [intervention, setIntervention] = useState("all");
  const [trialData, setTrialData] = useState<any>([]);
  const [nTrials, setNTrials] = useState(0);
  const [nComparators, setNComparators] = useState(0);
  const [comparatorsList, setComparatorsList] = useState<any>([]);
  const [nPublications, setNPublications] = useState(0);
  const [headerCheckbox, setHeaderCheckbox] = useState(
    <CheckboxChecked size={26} />
  );
  const [isModalOpenTrials, setIsModalOpenTrials] = useState(false);
  const [isModalOpenPublications, setIsModalOpenPublications] = useState(false);
  const [isModalOpenComparators, setIsModalOpenComparators] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState<any>();
  const [drawerData, setDrawerData] = useState<any>();

  const printRefStudies: React.MutableRefObject<null> = useRef(null);
  const printRefPrimary: React.MutableRefObject<null> = useRef(null);

  useEffect(() => {
    let tempTableData = [...renderTableData];
    if (tempTableData.length !== 0) {
      tempTableData.sort((a, b) => b[plotBy] - a[plotBy]); //sort on attribute used to plot the graph
      setTableData([...tempTableData]);
      setRenderTableData([...tempTableData]);

      //calculate avg value
      let average = 0;
      average =
        Math.round(
          (tempTableData
            .map((item: any) => Number(item[plotBy]))
            .reduce((prev: any, next: any) => prev + next) /
            tempTableData.length) *
            100
        ) / 100;
      setAverageValue(average);

      //create data for bar graph
      let tempObj: any = { key: "Study Name" };
      let tempKeys: any = [];
      let tempColors1: any = [];
      tempTableData.map((data: any) => {
        tempObj[data["studyname"]] = data[plotBy];
        tempKeys.push(data["studyname"]);
        tempColors1.push("#01a6dc");
      });
      setBarKeys([...tempKeys]);
      setColors([...tempColors1]);
      setBarData([tempObj]);
    }
  }, [plotBy]);

  useEffect(() => {
    if (props.data.length !== 0) {
      //check for all combinations of intervention and country selected
      if (intervention === "all") {
        if (countrySelected === "all") {
          let tempTableData = [...props.data];
          tempTableData.sort((a, b) => b[plotBy] - a[plotBy]);
          setTableData([...tempTableData]);
          setRenderTableData([...tempTableData]);

          //get list of Comparators
          let tempComparatorsList: any = [];
          tempTableData.map((d: any, ind: any) => {
            tempComparatorsList.push(d.comparator);
          });
          var uniqueComparatorsList = tempComparatorsList.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          setComparatorsList(uniqueComparatorsList);

          let average = 0;
          average =
            Math.round(
              (tempTableData
                .map((item) => Number(item[plotBy]))
                .reduce((prev, next) => prev + next) /
                tempTableData.length) *
                100
            ) / 100;
          setAverageValue(average);

          //get list of interventions
          let tempInterventionValues: any = [];
          let tempObj: any = { key: "Study Name" };
          let tempKeys: any = [];
          let tempColors1: any = [];
          let tempCountryValues: any = [];
          tempTableData.map((data: any) => {
            tempObj[data["studyname"]] = data[plotBy];
            tempKeys.push(data["studyname"]);
            tempColors1.push("#01a6dc");
            tempInterventionValues.push(
              data.intervention?.replaceAll("**", "")
            );
            let tempIteratorCountries = data.countries?.split(", ") || [];
            tempCountryValues = [
              ...tempCountryValues,
              ...tempIteratorCountries,
            ];
          });
          var unique = tempInterventionValues.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          var uniqueCountries = tempCountryValues.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          setInterventionValues([...unique]);
          setCountryValues([...uniqueCountries]);
          setBarKeys([...tempKeys]);
          setColors([...tempColors1]);
          setBarData([tempObj]);

          //for trial graph
          let tempTrialData: any = [];
          let nTrialsTemp = 0;
          let nComparatorsTemp = 0;
          let nPublicationsTemp = 0;
          let AllComparatorsArray: any = [];
          unique.map((intervention: any) => {
            let tempObj3: any = {
              Intervention: intervention,
              Trials: 0,
              ComparatorsArray: [],
              Publications: 0,
            };
            tempTableData?.map((data: any) => {
              if (data.intervention === intervention) {
                tempObj3["Trials"] += Number(data.numberoftrials);
                tempObj3["Publications"] += Number(data.numberofpublications);
                if (
                  tempObj3["ComparatorsArray"].indexOf(data.comparator) === -1
                ) {
                  tempObj3["ComparatorsArray"].push(data.comparator);
                }
                if (AllComparatorsArray.indexOf(data.comparator) === -1) {
                  AllComparatorsArray.push(data.comparator);
                }
              }
            });
            tempObj3["Comparators"] = tempObj3["ComparatorsArray"].length || 0;
            tempTrialData.push(tempObj3);
            nTrialsTemp += tempObj3["Trials"];
            nPublicationsTemp += tempObj3["Publications"];
            nComparatorsTemp = AllComparatorsArray.length;
          });
          setTrialData(tempTrialData);
          setNTrials(nTrialsTemp);
          setNPublications(nPublicationsTemp);
          setNComparators(nComparatorsTemp);

          //for primary graph
          let tempObj4: any = [];
          var res = tempTableData.reduce(function (obj, v) {
            obj[v[primaryPlotBy]] = (obj[v[primaryPlotBy]] || 0) + 1;
            return obj;
          }, {});
          let tempKeys4 = Object.keys(res);
          tempKeys4.map((key, i) => {
            tempObj4.push({
              id: key,
              label: key,
              value: res[key],
            });
          });
          setPrimaryData(tempObj4);
        } else {
          let tempTableData: any = [];
          props.data.map((t: any) => {
            if (t.countries.includes(countrySelected)) {
              tempTableData.push(t);
            }
          });
          tempTableData.sort((a: any, b: any) => b[plotBy] - a[plotBy]);
          // tempTableData.map((d: any, ind: any) => (d.key = ind));
          setTableData([...tempTableData]);
          setRenderTableData([...tempTableData]);

          let tempComparatorsList: any = [];
          tempTableData.map((d: any, ind: any) => {
            tempComparatorsList.push(d.comparator);
          });
          var uniqueComparatorsList = tempComparatorsList.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          setComparatorsList(uniqueComparatorsList);

          let average = 0;
          average =
            Math.round(
              (tempTableData
                .map((item: any) => Number(item[plotBy]))
                .reduce((prev: any, next: any) => prev + next) /
                tempTableData.length) *
                100
            ) / 100;
          setAverageValue(average);

          let tempInterventionValues: any = [];
          let tempObj: any = { key: "Study Name" };
          let tempKeys: any = [];
          let tempColors1: any = [];
          let tempCountryValues: any = [];
          tempTableData.map((data: any) => {
            tempObj[data["studyname"]] = data[plotBy];
            tempKeys.push(data["studyname"]);
            tempColors1.push("#01a6dc");
            tempInterventionValues.push(data.intervention.replaceAll("**", ""));
            tempCountryValues = [
              ...tempCountryValues,
              ...data.countries.split(", "),
            ];
          });
          var unique = tempInterventionValues.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          var uniqueCountries = tempCountryValues.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          setInterventionValues([...unique]);
          // setCountryValues([...uniqueCountries]);
          setBarKeys([...tempKeys]);
          setColors([...tempColors1]);
          setBarData([tempObj]);

          //for trial graph
          let tempTrialData: any = [];
          // let unique = [...interventionValues];
          let nTrialsTemp = 0;
          let nComparatorsTemp = 0;
          let nPublicationsTemp = 0;
          let AllComparatorsArray: any = [];
          unique.map((intervention: any) => {
            let tempObj3: any = {
              Intervention: intervention,
              Trials: 0,
              ComparatorsArray: [],
              Publications: 0,
            };
            tempTableData?.map((data: any) => {
              if (data.intervention === intervention) {
                tempObj3["Trials"] += Number(data.numberoftrials);
                tempObj3["Publications"] += Number(data.numberofpublications);
                if (
                  tempObj3["ComparatorsArray"].indexOf(data.comparator) === -1
                ) {
                  tempObj3["ComparatorsArray"].push(data.comparator);
                }
                if (AllComparatorsArray.indexOf(data.comparator) === -1) {
                  AllComparatorsArray.push(data.comparator);
                }
              }
            });
            tempObj3["Comparators"] = tempObj3["ComparatorsArray"].length || 0;
            tempTrialData.push(tempObj3);
            nTrialsTemp += tempObj3["Trials"];
            nPublicationsTemp += tempObj3["Publications"];
            nComparatorsTemp = AllComparatorsArray.length;
          });
          setTrialData(tempTrialData);
          setNTrials(nTrialsTemp);
          setNPublications(nPublicationsTemp);
          setNComparators(nComparatorsTemp);

          //for primary graph
          let tempObj4: any = [];
          var res = tempTableData.reduce(function (obj: any, v: any) {
            obj[v[primaryPlotBy]] = (obj[v[primaryPlotBy]] || 0) + 1;
            return obj;
          }, {});
          let tempKeys4 = Object.keys(res);
          tempKeys4.map((key, i) => {
            tempObj4.push({
              id: key,
              label: key,
              value: res[key],
            });
          });
          setPrimaryData(tempObj4);
        }
      } else {
        if (countrySelected === "all") {
          let tempTableData: any = [];
          props.data.map((t: any) => {
            if (t.intervention === intervention) {
              tempTableData.push(t);
            }
          });
          tempTableData.sort((a: any, b: any) => b[plotBy] - a[plotBy]);
          // tempTableData.map((d: any, ind: any) => (d.key = ind));
          setTableData([...tempTableData]);
          setRenderTableData([...tempTableData]);

          let tempComparatorsList: any = [];
          tempTableData.map((d: any, ind: any) => {
            tempComparatorsList.push(d.comparator);
          });
          var uniqueComparatorsList = tempComparatorsList.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          setComparatorsList(uniqueComparatorsList);

          let average = 0;
          average =
            Math.round(
              (tempTableData
                .map((item: any) => Number(item[plotBy]))
                .reduce((prev: any, next: any) => prev + next) /
                tempTableData.length) *
                100
            ) / 100;
          setAverageValue(average);

          let tempInterventionValues: any = [];
          let tempObj: any = { key: "Study Name" };
          let tempKeys: any = [];
          let tempColors1: any = [];
          let tempCountryValues: any = [];
          tempTableData.map((data: any) => {
            tempObj[data["studyname"]] = data[plotBy];
            tempKeys.push(data["studyname"]);
            tempColors1.push("#01a6dc");
            tempInterventionValues.push(data.intervention.replaceAll("**", ""));
            tempCountryValues = [
              ...tempCountryValues,
              ...data.countries.split(", "),
            ];
          });
          var unique = tempInterventionValues.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          var uniqueCountries = tempCountryValues.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          // setInterventionValues([...unique]);
          setCountryValues([...uniqueCountries]);
          setBarKeys([...tempKeys]);
          setColors([...tempColors1]);
          setBarData([tempObj]);

          //for trial graph
          let tempTrialData: any = [];
          // let unique = [...interventionValues];
          let nTrialsTemp = 0;
          let nComparatorsTemp = 0;
          let nPublicationsTemp = 0;
          let AllComparatorsArray: any = [];
          unique.map((intervention: any) => {
            let tempObj3: any = {
              Intervention: intervention,
              Trials: 0,
              ComparatorsArray: [],
              Publications: 0,
            };
            tempTableData?.map((data: any) => {
              if (data.intervention === intervention) {
                tempObj3["Trials"] += Number(data.numberoftrials);
                tempObj3["Publications"] += Number(data.numberofpublications);
                if (
                  tempObj3["ComparatorsArray"].indexOf(data.comparator) === -1
                ) {
                  tempObj3["ComparatorsArray"].push(data.comparator);
                }
                if (AllComparatorsArray.indexOf(data.comparator) === -1) {
                  AllComparatorsArray.push(data.comparator);
                }
              }
            });
            tempObj3["Comparators"] = tempObj3["ComparatorsArray"].length || 0;
            tempTrialData.push(tempObj3);
            nTrialsTemp += tempObj3["Trials"];
            nPublicationsTemp += tempObj3["Publications"];
            nComparatorsTemp = AllComparatorsArray.length;
          });
          setTrialData(tempTrialData);
          setNTrials(nTrialsTemp);
          setNPublications(nPublicationsTemp);
          setNComparators(nComparatorsTemp);

          //for primary graph
          let tempObj4: any = [];
          var res = tempTableData.reduce(function (obj: any, v: any) {
            obj[v[primaryPlotBy]] = (obj[v[primaryPlotBy]] || 0) + 1;
            return obj;
          }, {});
          let tempKeys4 = Object.keys(res);
          tempKeys4.map((key, i) => {
            tempObj4.push({
              id: key,
              label: key,
              value: res[key],
            });
          });
          setPrimaryData(tempObj4);
        } else {
          let tempTableData: any = [];
          props.data.map((t: any) => {
            if (
              t.intervention === intervention &&
              t.countries.includes(countrySelected)
            ) {
              tempTableData.push(t);
            }
          });
          tempTableData.sort((a: any, b: any) => b[plotBy] - a[plotBy]);
          // tempTableData.map((d: any, ind: any) => (d.key = ind));
          setTableData([...tempTableData]);
          setRenderTableData([...tempTableData]);

          let tempComparatorsList: any = [];
          tempTableData.map((d: any, ind: any) => {
            tempComparatorsList.push(d.comparator);
          });
          var uniqueComparatorsList = tempComparatorsList.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          setComparatorsList(uniqueComparatorsList);

          let average = 0;
          average =
            Math.round(
              (tempTableData
                .map((item: any) => Number(item[plotBy]))
                .reduce((prev: any, next: any) => prev + next) /
                tempTableData.length) *
                100
            ) / 100;
          setAverageValue(average);

          let tempInterventionValues: any = [];
          let tempObj: any = { key: "Study Name" };
          let tempKeys: any = [];
          let tempColors1: any = [];
          let tempCountryValues: any = [];
          tempTableData.map((data: any) => {
            tempObj[data["studyname"]] = data[plotBy];
            tempKeys.push(data["studyname"]);
            tempColors1.push("#01a6dc");
            tempInterventionValues.push(data.intervention.replaceAll("**", ""));
            tempCountryValues = [
              ...tempCountryValues,
              ...data.countries.split(", "),
            ];
          });
          props.data.map((data: any) => {
            if (data.countries.includes(countrySelected))
              tempInterventionValues.push(
                data.intervention.replaceAll("**", "")
              );
            if (data.intervention === intervention)
              tempCountryValues = [
                ...tempCountryValues,
                ...data.countries.split(", "),
              ];
          });
          var unique = tempInterventionValues.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          var uniqueCountries = tempCountryValues.filter(function (
            elem: any,
            index: any,
            self: any
          ) {
            return index === self.indexOf(elem);
          });
          setInterventionValues([...unique]);
          setCountryValues([...uniqueCountries]);
          setBarKeys([...tempKeys]);
          setColors([...tempColors1]);
          setBarData([tempObj]);

          //for trial graph
          let tempTrialData: any = [];
          // let unique = [...interventionValues];
          let nTrialsTemp = 0;
          let nComparatorsTemp = 0;
          let nPublicationsTemp = 0;
          let AllComparatorsArray: any = [];
          unique.map((intervention: any) => {
            let tempObj3: any = {
              Intervention: intervention,
              Trials: 0,
              ComparatorsArray: [],
              Publications: 0,
            };
            tempTableData?.map((data: any) => {
              if (data.intervention === intervention) {
                tempObj3["Trials"] += Number(data.numberoftrials);
                tempObj3["Publications"] += Number(data.numberofpublications);
                if (
                  tempObj3["ComparatorsArray"].indexOf(data.comparator) === -1
                ) {
                  tempObj3["ComparatorsArray"].push(data.comparator);
                }
                if (AllComparatorsArray.indexOf(data.comparator) === -1) {
                  AllComparatorsArray.push(data.comparator);
                }
              }
            });
            tempObj3["Comparators"] = tempObj3["ComparatorsArray"].length || 0;
            tempTrialData.push(tempObj3);
            nTrialsTemp += tempObj3["Trials"];
            nPublicationsTemp += tempObj3["Publications"];
            nComparatorsTemp = AllComparatorsArray.length;
          });
          setTrialData(tempTrialData);
          setNTrials(nTrialsTemp);
          setNPublications(nPublicationsTemp);
          setNComparators(nComparatorsTemp);

          //for primary graph
          let tempObj4: any = [];
          var res = tempTableData.reduce(function (obj: any, v: any) {
            obj[v[primaryPlotBy]] = (obj[v[primaryPlotBy]] || 0) + 1;
            return obj;
          }, {});
          let tempKeys4 = Object.keys(res);
          tempKeys4.map((key, i) => {
            tempObj4.push({
              id: key,
              label: key,
              value: res[key],
            });
          });
          setPrimaryData(tempObj4);
        }
      }
    }
  }, [intervention, countrySelected]);

  useEffect(() => {
    if (selectedStudy !== "" || (selectedStudy !== null && renderTableData)) {
      let tempData = renderTableData.find(
        (element) => element.studyname === selectedStudy
      );
      if (tempData) {
        delete tempData["key"];
        let tempKeys = Object.keys(tempData);
        setDrawerData([columns, tempData]);
      }
    }
  }, [selectedStudy]);

  useEffect(() => {
    //for primary graph (pie chart)
    let tempObj5: any = [];
    var res = renderTableData.reduce(function (obj, v) {
      obj[v[primaryPlotBy]] = (obj[v[primaryPlotBy]] || 0) + 1;
      return obj;
    }, {});
    let tempKeys5 = Object.keys(res);
    tempKeys5.map((key, i) => {
      tempObj5.push({
        id: key,
        label: key,
        value: res[key],
      });
    });
    setPrimaryData(tempObj5);
  }, [primaryPlotBy]);

  useEffect(() => {
    setTimeout(() => {
      setHighlightRowId("");
    }, 2500);
  }, [highlightRowId]);

  const handleDownloadImage = async (ref: React.MutableRefObject<null>) => {
    message.loading("Downloading!");
    const element = ref.current;
    if (element) {
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = data;
        link.download = "image.jpg";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (props.data.length !== 0) {
      let tempTableData = [...props.data];
      tempTableData.sort((a, b) => b[plotBy] - a[plotBy]);
      // tempTableData.map((d: any, ind: any) => (d.key = ind));
      setTableData([...tempTableData]);
      setRenderTableData([...tempTableData]);

      let tempComparatorsList: any = [];
      tempTableData.map((d: any, ind: any) => {
        tempComparatorsList.push(d.comparator);
      });
      var uniqueComparatorsList = tempComparatorsList.filter(function (
        elem: any,
        index: any,
        self: any
      ) {
        return index === self.indexOf(elem);
      });
      setComparatorsList(uniqueComparatorsList);

      let average = 0;
      average =
        Math.round(
          (tempTableData
            .map((item: any) => Number(item[plotBy]))
            .reduce((prev: any, next: any) => prev + next) /
            tempTableData.length) *
            100
        ) / 100;
      setAverageValue(average);

      let tempObj: any = { key: "Study Name" };
      let tempKeys: any = [];
      let tempColors1: any = [];
      let tempInterventionValues: any = [];
      let tempCountryValues: any = [];
      tempTableData.map((data: any) => {
        tempObj[data["studyname"]] = data[plotBy];
        tempKeys.push(data["studyname"]);
        tempColors1.push("#01a6dc");
        tempInterventionValues.push(data.intervention?.replaceAll("**", ""));
        let tempIteratorCountries = data.countries?.split(", ") || [];
        tempCountryValues = [...tempCountryValues, ...tempIteratorCountries];
      });
      var unique = tempInterventionValues.filter(function (
        elem: any,
        index: any,
        self: any
      ) {
        return index === self.indexOf(elem);
      });
      var uniqueCountries = tempCountryValues.filter(function (
        elem: any,
        index: any,
        self: any
      ) {
        return index === self.indexOf(elem);
      });
      setInterventionValues([...unique]);
      setCountryValues([...uniqueCountries]);
      setBarKeys([...tempKeys]);
      setColors([...tempColors1]);
      setBarData([tempObj]);

      //for trial graph
      let tempTrialData: any = [];
      let nTrialsTemp = 0;
      let nComparatorsTemp = 0;
      let nPublicationsTemp = 0;
      let AllComparatorsArray: any = [];
      unique.map((intervention: any) => {
        let tempObj3: any = {
          Intervention: intervention,
          Trials: 0,
          ComparatorsArray: [],
          Publications: 0,
        };
        tempTableData?.map((data: any) => {
          if (data.intervention === intervention) {
            tempObj3["Trials"] += Number(data.numberoftrials);
            tempObj3["Publications"] += Number(data.numberofpublications);
            if (tempObj3["ComparatorsArray"].indexOf(data.comparator) === -1) {
              tempObj3["ComparatorsArray"].push(data.comparator);
            }
            if (AllComparatorsArray.indexOf(data.comparator) === -1) {
              AllComparatorsArray.push(data.comparator);
            }
          }
        });
        tempObj3["Comparators"] = tempObj3["ComparatorsArray"].length || 0;
        tempTrialData.push(tempObj3);
        nTrialsTemp += tempObj3["Trials"];
        nPublicationsTemp += tempObj3["Publications"];
        nComparatorsTemp = AllComparatorsArray.length;
      });
      setTrialData(tempTrialData);
      setNTrials(nTrialsTemp);
      setNPublications(nPublicationsTemp);
      setNComparators(nComparatorsTemp);

      //for primary graph
      let tempObj4: any = [];
      var res = tempTableData.reduce(function (obj, v) {
        obj[v[primaryPlotBy]] = (obj[v[primaryPlotBy]] || 0) + 1;
        return obj;
      }, {});
      let tempKeys4 = Object.keys(res);
      tempKeys4.map((key, i) => {
        tempObj4.push({
          id: key,
          label: key,
          value: res[key],
        });
      });
      setPrimaryData(tempObj4);
    }
    setLoading(false);
  }, []);

  function onSelect(event: any, id: any) {
    setLoading(true);
    console.log(event, id);
    if (event.target.checked) {
      let temp = [...colors];
      temp[id] = "#01a6dc";
      setColors([...temp]);
      let i = temp.indexOf("#999999");
      if (i === -1) {
        setIndeterminate(false); //state to check if all are selected
      }
      let tempTableData = [...tableData];
      let renderStudyRecordIndex = renderTableData.findIndex(
        (x) => x.key === id
      );
      tempTableData.splice(
        renderStudyRecordIndex,
        0,
        renderTableData[renderStudyRecordIndex]
      );
      setTableData([...tempTableData]);
      let average = 0;
      average =
        Math.round(
          (tempTableData
            .map((item: any) => Number(item[plotBy]))
            .reduce((prev: any, next: any) => prev + next) /
            tempTableData.length) *
            100
        ) / 100;
      setAverageValue(average);

      //adding to update chart bars
      let tempObj: any = { key: "Study Name" };
      let tempKeys: any = [];
      let tempColors1: any = [];
      let tempInterventionValues: any = [];
      tempTableData.map((data: any) => {
        tempObj[data["studyname"]] = data[plotBy];
        tempKeys.push(data["studyname"]);
        tempColors1.push("#01a6dc");
        tempInterventionValues.push(data.intervention.replaceAll("**", ""));
      });
      let unique = tempInterventionValues.filter(function (
        elem: any,
        index: any,
        self: any
      ) {
        return index === self.indexOf(elem);
      });
      setInterventionValues([...unique]);
      setBarKeys([...tempKeys]);
      setColors([...tempColors1]);
      setBarData([tempObj]);

      //update trial data for cards and primary data for graphs
      let tempTrialData: any = [];
      // let unique = [...interventionValues];  //uncomment if you comment above code of setBarData
      let nTrialsTemp = 0;
      let nComparatorsTemp = 0;
      let nPublicationsTemp = 0;
      let AllComparatorsArray: any = [];
      unique.map((intervention: any) => {
        let tempObj3: any = {
          Intervention: intervention,
          Trials: 0,
          ComparatorsArray: [],
          Publications: 0,
        };
        tempTableData?.map((data: any) => {
          if (data.intervention === intervention) {
            tempObj3["Trials"] += Number(data.numberoftrials);
            tempObj3["Publications"] += Number(data.numberofpublications);
            if (tempObj3["ComparatorsArray"].indexOf(data.comparator) === -1) {
              tempObj3["ComparatorsArray"].push(data.comparator);
            }
            if (AllComparatorsArray.indexOf(data.comparator) === -1) {
              AllComparatorsArray.push(data.comparator);
            }
          }
        });
        tempObj3["Comparators"] = tempObj3["ComparatorsArray"].length || 0;
        tempTrialData.push(tempObj3);
        nTrialsTemp += tempObj3["Trials"];
        nPublicationsTemp += tempObj3["Publications"];
        nComparatorsTemp = AllComparatorsArray.length;
      });
      setTrialData(tempTrialData);
      setNTrials(nTrialsTemp);
      setNPublications(nPublicationsTemp);
      setNComparators(nComparatorsTemp);

      //for primary graph
      let tempObj4: any = [];
      var res = tempTableData.reduce(function (obj: any, v: any) {
        obj[v[primaryPlotBy]] = (obj[v[primaryPlotBy]] || 0) + 1;
        return obj;
      }, {});
      let tempKeys4 = Object.keys(res);
      tempKeys4.map((key, i) => {
        tempObj4.push({
          id: key,
          label: key,
          value: res[key],
        });
      });
      setPrimaryData(tempObj4);
      setLoading(false);
    } else {
      let temp = [...colors];
      temp[id] = "#999999";
      setColors([...temp]);
      setIndeterminate(true);
      let tempTableData = [...tableData];
      let renderStudyName = renderTableData.find((x) => x.key === id).studyname;
      let index = tempTableData.findIndex(
        (x) => x.studyname === renderStudyName
      );
      tempTableData.splice(index, 1);
      setTableData([...tempTableData]);
      let average = 0;
      average =
        Math.round(
          (tempTableData
            .map((item: any) => Number(item[plotBy]))
            .reduce((prev: any, next: any) => prev + next) /
            tempTableData.length) *
            100
        ) / 100;
      setAverageValue(average);

      //adding to update chart bars
      let tempObj: any = { key: "Study Name" };
      let tempKeys: any = [];
      let tempColors1: any = [];
      let tempInterventionValues: any = [];
      tempTableData.map((data: any) => {
        tempObj[data["studyname"]] = data[plotBy];
        tempKeys.push(data["studyname"]);
        tempColors1.push("#01a6dc");
        tempInterventionValues.push(data.intervention.replaceAll("**", ""));
      });
      let unique = tempInterventionValues.filter(function (
        elem: any,
        index: any,
        self: any
      ) {
        return index === self.indexOf(elem);
      });
      setInterventionValues([...unique]);
      setBarKeys([...tempKeys]);
      setColors([...tempColors1]);
      setBarData([tempObj]);

      //update trial data for cards and primary data for graphs
      let tempTrialData: any = [];
      // let unique = [...interventionValues]; //uncomment when commenting above code for setBarData
      let nTrialsTemp = 0;
      let nComparatorsTemp = 0;
      let nPublicationsTemp = 0;
      let AllComparatorsArray: any = [];
      unique.map((intervention: any) => {
        let tempObj3: any = {
          Intervention: intervention,
          Trials: 0,
          ComparatorsArray: [],
          Publications: 0,
        };
        tempTableData?.map((data: any) => {
          if (data.intervention === intervention) {
            tempObj3["Trials"] += Number(data.numberoftrials);
            tempObj3["Publications"] += Number(data.numberofpublications);
            if (tempObj3["ComparatorsArray"].indexOf(data.comparator) === -1) {
              tempObj3["ComparatorsArray"].push(data.comparator);
            }
            if (AllComparatorsArray.indexOf(data.comparator) === -1) {
              AllComparatorsArray.push(data.comparator);
            }
          }
        });
        tempObj3["Comparators"] = tempObj3["ComparatorsArray"].length || 0;
        tempTrialData.push(tempObj3);
        nTrialsTemp += tempObj3["Trials"];
        nPublicationsTemp += tempObj3["Publications"];
        nComparatorsTemp = AllComparatorsArray.length;
      });
      setTrialData(tempTrialData);
      setNTrials(nTrialsTemp);
      setNPublications(nPublicationsTemp);
      setNComparators(nComparatorsTemp);

      //for primary graph
      let tempObj4: any = [];
      var res = tempTableData.reduce(function (obj: any, v: any) {
        obj[v[primaryPlotBy]] = (obj[v[primaryPlotBy]] || 0) + 1;
        return obj;
      }, {});
      let tempKeys4 = Object.keys(res);
      tempKeys4.map((key, i) => {
        tempObj4.push({
          id: key,
          label: key,
          value: res[key],
        });
      });
      setPrimaryData(tempObj4);

      setLoading(false);
    }
  }

  const handlePlotChange = (value: string) => {
    setPlotBy(value);
    if (value === "ntotalrandomized") {
      setPlotByName("N Total Randomized");
    } else if (value === "studyduration(months)") {
      setPlotByName("Study Duration (Months)");
    } else if (value === "age(years)") {
      setPlotByName("Age (Years)");
    }
  };

  const handleCountryChange = (value: string) => {
    setCountrySelected(value);
  };

  const handlePrimaryChange = (value: string) => {
    setPrimaryPlotBy(value);
    if (value === "ethnicity") {
      setPrimaryPlotByName("Ethnicity");
    } else if (value === "smoking") {
      setPrimaryPlotByName("Smoking");
    } else if (value === "gender") {
      setPrimaryPlotByName("Gender");
    }
  };

  const handleInterventionChange = (value: string) => {
    setIntervention(value);
  };

  function onCheckBoxChange(value: any) {
    if (value.target.checked) {
      setLoading(true);
      let tempColors: any = [];
      renderTableData.map((t: any, i: any) => {
        tempColors.push("#01a6dc");
        let temp = document.getElementById(
          "checkbox" + i
        ) as HTMLInputElement | null;
        if (temp) temp.checked = true;
      });
      setColors([...tempColors]);
      setTableData([...renderTableData]);
      let tempTableData = [...renderTableData];
      let average = 0;
      average =
        Math.round(
          (tempTableData
            .map((item: any) => Number(item[plotBy]))
            .reduce((prev: any, next: any) => prev + next) /
            tempTableData.length) *
            100
        ) / 100;
      setAverageValue(average);

      let tempObj: any = { key: "Study Name" };
      let tempKeys: any = [];
      tempTableData.map((data: any) => {
        tempObj[data["studyname"]] = data[plotBy];
        tempKeys.push(data["studyname"]);
      });
      setBarKeys([...tempKeys]);
      setBarData([tempObj]);

      //update trial data for cards and primary data for graphs
      let tempTrialData: any = [];
      let unique = [...interventionValues];
      let nTrialsTemp = 0;
      let nComparatorsTemp = 0;
      let nPublicationsTemp = 0;
      let AllComparatorsArray: any = [];
      unique.map((intervention: any) => {
        let tempObj3: any = {
          Intervention: intervention,
          Trials: 0,
          ComparatorsArray: [],
          Publications: 0,
        };
        tempTableData?.map((data: any) => {
          if (data.intervention === intervention) {
            tempObj3["Trials"] += Number(data.numberoftrials);
            tempObj3["Publications"] += Number(data.numberofpublications);
            if (tempObj3["ComparatorsArray"].indexOf(data.comparator) === -1) {
              tempObj3["ComparatorsArray"].push(data.comparator);
            }
            if (AllComparatorsArray.indexOf(data.comparator) === -1) {
              AllComparatorsArray.push(data.comparator);
            }
          }
        });
        tempObj3["Comparators"] = tempObj3["ComparatorsArray"].length || 0;
        tempTrialData.push(tempObj3);
        nTrialsTemp += tempObj3["Trials"];
        nPublicationsTemp += tempObj3["Publications"];
        nComparatorsTemp = AllComparatorsArray.length;
      });
      setTrialData(tempTrialData);
      setNTrials(nTrialsTemp);
      setNPublications(nPublicationsTemp);
      setNComparators(nComparatorsTemp);

      //for primary graph
      let tempObj4: any = [];
      var res = tempTableData.reduce(function (obj: any, v: any) {
        obj[v[primaryPlotBy]] = (obj[v[primaryPlotBy]] || 0) + 1;
        return obj;
      }, {});
      let tempKeys4 = Object.keys(res);
      tempKeys4.map((key, i) => {
        tempObj4.push({
          id: key,
          label: key,
          value: res[key],
        });
      });
      setPrimaryData(tempObj4);
      setLoading(false);
    } else if (value.target.checked === false) {
      setLoading(true);
      let tempColors: any = [];
      renderTableData.map((t: any, i: any) => {
        tempColors.push("#999999");
        let temp = document.getElementById(
          "checkbox" + i
        ) as HTMLInputElement | null;
        if (temp) temp.checked = false;
      });

      setColors([...tempColors]);
      setTableData([]);
      setBarData([]);
      setAverageValue(0);
      setTrialData([]);
      setNTrials(0);
      setNPublications(0);
      setNComparators(0);
      setPrimaryData([]);
      setLoading(false);
    }
  }

  return (
    <div>
      <div style={styles}>
        <div>
          {loading ? (
            <BeatLoader
              css={override}
              color={"#27A6A4"}
              loading={true}
              size={30}
            />
          ) : renderTableData.length === 0 ? (
            <div
              className="bg-white m-2 border-2 rounded-sm flex items-center justify-center"
              style={{ minHeight: "78vh", overflowY: "auto" }}
            >
              <div className="text-center">
                <FileOutlined style={{ color: "#27a6a4", fontSize: "3rem" }} />
                <p className="text-xl mt-2 italic">No Details Added!</p>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="flex justify-start items-center">
                <div
                  style={{ height: "4.5rem", width: "15rem" }}
                  className="justify-start items-center mr-2 py-1 text-left "
                >
                  <p className="font-semibold mr-2 text-lg mb-1">
                    Select Intervention{" "}
                    <InfoCircle
                      title={"Filter data based on an intervention"}
                      size={12}
                      // style={{ marginLeft: "1.9rem" }}
                    />
                  </p>
                  <Select
                    defaultValue="all"
                    placeholder="Select Intervention"
                    style={{ width: "14rem", textAlign: "left" }}
                    onChange={handleInterventionChange}
                  >
                    <Select.Option value="all">All</Select.Option>
                    {interventionValues.map((intervention: any) => {
                      if (
                        intervention !== "" &&
                        intervention !== undefined &&
                        intervention !== null
                      )
                        return (
                          <Select.Option value={intervention}>
                            {intervention}
                          </Select.Option>
                        );
                    })}
                  </Select>
                </div>

                <div
                  style={{
                    height: "4.5rem",
                    width: "15rem",
                    backgroundColor: "rgb(0, 130, 225,0.2)",
                  }}
                  className="rounded-md shadow-md px-2 py-3 mx-2"
                >
                  <p className="text-2xl text-blue-600">{tableData.length}</p>
                  <p
                    className={`ml-1 mt-0.5 ${
                      window.innerWidth < 1200 ? "text-xs" : "text-sm"
                    }`}
                  >
                    Total Studies
                  </p>
                </div>
                <div
                  style={{
                    height: "4.5rem",
                    width: "15rem",
                    backgroundColor: "rgb(0, 130, 225,0.2)",
                  }}
                  className="rounded-md shadow-md px-2 py-3 mx-2"
                >
                  <p className="text-2xl text-blue-600">
                    {averageValue ? averageValue : 0}
                  </p>
                  {plotByName.length >= 20 ? (
                    <div className="flex items-center justify-start">
                      {plotByName && (
                        <p
                          className={`ml-1 mt-0.5 ${
                            window.innerWidth < 1200
                              ? "text-xs leading-3"
                              : "text-xs"
                          }`}
                        >
                          Avg. {plotByName}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      {plotByName && (
                        <p
                          className={`ml-1 mt-0.5 ${
                            window.innerWidth < 1200
                              ? "text-xs leading-3"
                              : "text-sm"
                          }`}
                        >
                          Avg. {plotByName}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <Tooltip
                  title={
                    intervention === "all" ? (
                      <p className="text-xs text-black italic">
                        Click to see intervention level details!
                      </p>
                    ) : (
                      ""
                    )
                  }
                  color="white"
                >
                  <div
                    style={{
                      height: "4.5rem",
                      width: "15rem",
                      backgroundColor: "rgb(0, 130, 225,0.2)",
                    }}
                    onClick={() => {
                      intervention === "all"
                        ? setIsModalOpenTrials(true)
                        : setIsModalOpenTrials(false);
                    }}
                    className="rounded-md shadow-md px-2 py-3 mx-2"
                  >
                    <p className="text-2xl text-blue-600">
                      {nTrials ? nTrials : 0}
                    </p>
                    <p
                      className={`ml-1 mt-0.5 ${
                        window.innerWidth < 1200 ? "text-xs" : "text-sm"
                      }`}
                    >
                      Total Trials
                    </p>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    intervention === "all" ? (
                      <p className="text-xs text-black italic">
                        Click to see intervention level details!
                      </p>
                    ) : (
                      ""
                    )
                  }
                  color="white"
                >
                  <div
                    style={{
                      height: "4.5rem",
                      width: "15rem",
                      backgroundColor: "rgb(0, 130, 225,0.2)",
                    }}
                    onClick={() => {
                      intervention === "all"
                        ? setIsModalOpenPublications(true)
                        : setIsModalOpenPublications(false);
                    }}
                    className="rounded-md shadow-md px-2 py-3 mx-2"
                  >
                    <p className="text-2xl text-blue-600">
                      {nPublications ? nPublications : 0}
                    </p>
                    <p
                      className={`ml-1 mt-0.5 ${
                        window.innerWidth < 1200 ? "text-xs" : "text-sm"
                      }`}
                    >
                      Total Publications
                    </p>
                  </div>
                </Tooltip>
                {intervention === "all" && (
                  <Tooltip
                    placement="left"
                    title={
                      <div className="bg-white text-black">
                        <p>Interventions:</p>
                        <hr className="mb-0.5" />
                        {interventionValues.map((c: any) => {
                          if (c === "" || c === undefined || c === null)
                            return "";
                          return (
                            <p>
                              <CircleFill
                                size={4}
                                style={{ marginRight: "0.2rem" }}
                              />
                              {c}
                            </p>
                          );
                        })}
                        {/* {intervention === "all" ? (
                        <p className="text-xs text-black italic">
                          Click to see intervention level details!
                        </p>
                      ) : (
                        ""
                      )} */}
                      </div>
                    }
                    color="white"
                  >
                    <div
                      style={{
                        height: "4.5rem",
                        width: "15rem",
                        backgroundColor: "rgb(0, 130, 225,0.2)",
                      }}
                      // onClick={() => {
                      //   setIsModalOpenInterventions(true);
                      // }}
                      className="rounded-md shadow-md px-2 py-3 mx-2"
                    >
                      {interventionValues[0] === "" ||
                      interventionValues[0] === undefined ||
                      interventionValues[0] === null ? (
                        <p className="text-2xl text-blue-600">0</p>
                      ) : (
                        <p className="text-2xl text-blue-600">
                          {interventionValues.length}
                        </p>
                      )}
                      <p
                        className={`ml-1 mt-0.5 ${
                          window.innerWidth < 1200 ? "text-xs" : "text-sm"
                        }`}
                      >
                        Total Interventions
                      </p>
                    </div>
                  </Tooltip>
                )}
                <Tooltip
                  color="white"
                  placement="left"
                  title={
                    <div className="bg-white text-black">
                      <p>Comparators:</p>
                      <hr />
                      {comparatorsList.map((c: any) => {
                        if (c === undefined) return "";
                        return (
                          <p>
                            <CircleFill
                              size={4}
                              style={{ marginRight: "0.2rem" }}
                            />
                            {c}
                          </p>
                        );
                      })}
                      {intervention === "all" ? (
                        <p className="text-xs text-black italic mt-2">
                          Click to see intervention level details!
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                >
                  <div
                    style={{
                      height: "4.5rem",
                      width: "15rem",
                      backgroundColor: "rgb(0, 130, 225,0.2)",
                    }}
                    onClick={() => {
                      intervention === "all"
                        ? setIsModalOpenComparators(true)
                        : setIsModalOpenComparators(false);
                    }}
                    className="rounded-md shadow-md px-2 py-3 ml-2"
                  >
                    {comparatorsList[0] === undefined ? (
                      <p className="text-2xl text-blue-600">0</p>
                    ) : (
                      <p className="text-2xl text-blue-600">{nComparators}</p>
                    )}

                    <p
                      className={`ml-1 mt-0.5 ${
                        window.innerWidth < 1200 ? "text-xs" : "text-sm"
                      }`}
                    >
                      Total Comparators
                    </p>
                  </div>
                </Tooltip>
              </div>
              {barData && colors && plotBy && (
                <div
                  className="flex mt-3"
                  style={{ height: "240px", width: "100%" }}
                >
                  <div
                    style={{ width: "70%", height: "100%" }}
                    className="bg-white px-2 py-2 shadow-md"
                  >
                    <div className="flex justify-start items-center">
                      <div
                        style={{ width: "75%" }}
                        className="flex justify-start items-center"
                      >
                        <p className="ml-2 font-semibold mr-2">Plot by:</p>
                        <Select
                          defaultValue="ntotalrandomized"
                          style={{ width: "14rem", textAlign: "left" }}
                          onChange={handlePlotChange}
                        >
                          <Select.Option value="ntotalrandomized">
                            N Total Randomized
                          </Select.Option>
                          <Select.Option value="studyduration(months)">
                            Study Duration (Months)
                          </Select.Option>
                          <Select.Option value="age(years)">
                            Age (Years)
                          </Select.Option>
                        </Select>
                        <p className="ml-10 font-semibold mr-2">
                          Select Country
                        </p>
                        <Select
                          defaultValue="all"
                          style={{ width: "14rem", textAlign: "left" }}
                          onChange={handleCountryChange}
                        >
                          <Select.Option value="all">All</Select.Option>
                          {countryValues?.map((country: any) => {
                            if (country !== "")
                              return (
                                <Select.Option value={country}>
                                  {country}
                                </Select.Option>
                              );
                          })}
                        </Select>
                      </div>
                      <div
                        style={{ width: "25%" }}
                        className="flex justify-end"
                      >
                        <Download
                          title="Download Chart"
                          size={26}
                          style={{ color: "#32A29B", cursor: "pointer" }}
                          onClick={() => handleDownloadImage(printRefStudies)}
                        />
                      </div>
                    </div>

                    <div ref={printRefStudies} style={{ height: "88%" }}>
                      <ResponsiveBar
                        data={barData}
                        groupMode="grouped"
                        innerPadding={
                          renderTableData.length === 1
                            ? 250
                            : renderTableData.length <= 2
                            ? 600
                            : renderTableData.length <= 3
                            ? 350
                            : renderTableData.length <= 5
                            ? 220
                            : renderTableData.length <= 10
                            ? 75
                            : renderTableData.length <= 15
                            ? 25
                            : 7
                        }
                        keys={barKeys}
                        indexBy="Study Name"
                        // barComponent={CustomBarComponent}
                        margin={{ top: 10, right: 10, bottom: 35, left: 60 }}
                        padding={
                          renderTableData.length === 1
                            ? 0.85
                            : renderTableData.length <= 3
                            ? 0.09
                            : renderTableData.length <= 5
                            ? 0.04
                            : 0.01
                        }
                        borderRadius={0}
                        enableLabel={false}
                        enableGridY={false}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        colors={colors}
                        theme={{
                          axis: {
                            domain: {
                              line: {
                                stroke: "gray",
                                strokeWidth: 2,
                              },
                            },
                          },
                        }}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          tickValues: 5,
                          legendPosition: "middle",
                          legendOffset: -45,
                          legend: plotByName,
                        }}
                        axisBottom={{
                          tickSize: 0,
                          tickPadding: 5,
                          tickRotation: 0,
                          tickValues: 5,
                          legendPosition: "middle",
                          legendOffset: 20,
                          legend: "Studies",
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                          from: "color",
                          modifiers: [["darker", 1.6]],
                        }}
                        role="application"
                        markers={[
                          {
                            axis: "y",
                            value: averageValue,
                            lineStyle: {
                              stroke: "rgba(0, 0, 0, .35)",
                              strokeWidth: 1,
                              strokeDasharray: 3,
                            },
                            legend: `Average: ${averageValue}`,
                            legendOrientation: "horizontal",
                            textStyle: { fontSize: "0.75rem" },
                          },
                        ]}
                        tooltip={({ id, value, color }) => (
                          <div className="flex items-center justify-center px-2 py-1 bg-white text-xs">
                            <p>{String(id).replaceAll("**", "")} :</p>
                            <p className="font-bold ml-1">{value}</p>
                          </div>
                        )}
                        onClick={(data, event) => {
                          setSelectedStudy(data.id);
                          setOpenDrawer(true);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{ width: "29.4%" }}
                    className="ml-2 bg-white px-2 py-2 shadow-md"
                  >
                    <div
                      className=""
                      style={{ height: "210px", width: "100%" }}
                    >
                      <div className="flex justify-start items-center">
                        <div className="w-2/3 flex justify-start">
                          <Select
                            defaultValue="studydesign"
                            style={{ width: "14rem", textAlign: "left" }}
                            onChange={handlePrimaryChange}
                            listHeight={128}
                          >
                            <Select.Option value="studydesign">
                              Study Design
                            </Select.Option>
                            <Select.Option value="ethnicity">
                              Ethnicity
                            </Select.Option>
                            <Select.Option value="gender">Gender</Select.Option>
                            <Select.Option value="smoking">
                              Smoking
                            </Select.Option>
                            <Select.Option value="histology">
                              Histology
                            </Select.Option>
                            <Select.Option value="mutationstatus">
                              Mutation Status
                            </Select.Option>
                            <Select.Option value="metastaticsites">
                              Metastatic Sites
                            </Select.Option>
                            <Select.Option value="metastaticstage">
                              Metastatic Stage
                            </Select.Option>
                            <Select.Option value="priorlineoftherapy">
                              Prior Line of Therapy
                            </Select.Option>
                          </Select>
                        </div>
                        <div className="w-1/3 flex justify-end">
                          <Download
                            title="Download Chart"
                            size={24}
                            style={{
                              color: "#32A29B",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDownloadImage(printRefPrimary)}
                          />
                        </div>
                      </div>
                      <div style={{ height: "85%" }} className="flex">
                        <div
                          ref={printRefPrimary}
                          style={{ height: "100%", width: "100%" }}
                        >
                          {primaryData && (
                            <PieChart
                              data={primaryData}
                              margin={
                                window.innerWidth < 1200
                                  ? {
                                      top: 25,
                                      right: 120,
                                      bottom: 20,
                                      left: -20,
                                    }
                                  : {
                                      top: 15,
                                      right: 50,
                                      bottom: 10,
                                      left: -120,
                                    }
                              }
                              legends={
                                window.innerWidth < 1200
                                  ? [
                                      {
                                        anchor: "right",
                                        direction: "column",
                                        justify: false,
                                        translateX: 75,
                                        translateY: 10,
                                        itemsSpacing: 2,
                                        itemWidth: 100,
                                        itemHeight: 14,
                                        itemTextColor: "#999",
                                        itemDirection: "left-to-right",
                                        itemOpacity: 1,
                                        symbolSize: 12,
                                        symbolShape: "circle",
                                        effects: [
                                          {
                                            on: "hover",
                                            style: {
                                              itemTextColor: "#000",
                                            },
                                          },
                                        ],
                                      },
                                    ]
                                  : [
                                      {
                                        anchor: "right",
                                        direction: "column",
                                        justify: false,
                                        translateX: 30,
                                        translateY: 10,
                                        itemsSpacing: 4,
                                        itemWidth: 150,
                                        itemHeight: 18,
                                        itemTextColor: "#999",
                                        itemDirection: "left-to-right",
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: "circle",
                                        effects: [
                                          {
                                            on: "hover",
                                            style: {
                                              itemTextColor: "#000",
                                            },
                                          },
                                        ],
                                      },
                                    ]
                              }
                            />
                          )}
                        </div>
                      </div>
                      <p className="text-xs">Number of Studies</p>
                    </div>
                  </div>
                </div>
              )}
              <div
                style={{
                  height: "290px",
                  overflowY: "auto",
                  width: "100%",
                  overflowX: "auto",
                }}
                className="mt-3 fixedHeader border-b dashboardCheckboxTable bg-white shadow-md"
              >
                {plotBy && renderTableData && renderTableData.length !== 0 && (
                  <table>
                    <thead>
                      <tr className="roundedRows">
                        {headerCheckbox && (
                          <th
                            style={{
                              backgroundColor: "#27a6a4",
                              color: "whitesmoke",
                              zIndex: 1,
                            }}
                            className="font-bold text-center"
                            onClick={() =>
                              setHeaderCheckbox(<CheckboxChecked size={26} />)
                            }
                          >
                            <Checkbox
                              id={"CheckBox"}
                              defaultChecked={true}
                              indeterminate={indeterminate}
                              onClick={() => setIndeterminate(false)}
                              onChange={onCheckBoxChange}
                            />
                          </th>
                        )}
                        {columns.map((col: any) => (
                          <th
                            style={{
                              backgroundColor: "#27a6a4",
                              color: "whitesmoke",
                              minWidth: "10rem",
                            }}
                            className="font-bold text-center "
                          >
                            {col.title}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <div className="h-1"></div>
                    <tbody>
                      {renderTableData.map((row: any, index: any) => {
                        const color = index % 2 === 0 ? "#eaf8f8" : "white";
                        const keys: any = [];
                        columns.map((c: any) => keys.push(c["dataIndex"]));
                        const temp = keys.indexOf("key");
                        if (temp > -1) {
                          keys.splice(temp, 1);
                        }
                        return (
                          <tr
                            id={row.studyname}
                            className="hover:bg-transparent border-b-8 roundedRows"
                            style={
                              row.studyname === highlightRowId
                                ? { backgroundColor: "#6eccee" }
                                : { backgroundColor: color }
                            }
                          >
                            <td className="text-center">
                              <input
                                type="checkbox"
                                id={"checkbox" + index}
                                style={{
                                  paddingLeft: "1rem",
                                  height: "0.9rem",
                                  width: "0.9rem",
                                }}
                                defaultChecked={true}
                                onChange={(e) => onSelect(e, row.key)}
                              />
                            </td>
                            {keys.map((key: any) => {
                              if (key === "studyname") {
                                return (
                                  <td className="align-top p-1 text-center">
                                    <div className="flex justify-end items-center">
                                      <Tooltip
                                        title={props.studyDetails[row["key"]]}
                                      >
                                        <a
                                          href={props.studyDetails[row["key"]]}
                                          target="_blank"
                                          style={{
                                            padding: 0,
                                            margin: 0,
                                            height: "0.7rem",
                                          }}
                                        >
                                          <ExternalLinkOutline
                                            size={12}
                                            style={{ right: 0 }}
                                          />
                                        </a>
                                      </Tooltip>
                                    </div>
                                    <ReactMarkdown
                                      children={row[key]}
                                    ></ReactMarkdown>
                                  </td>
                                );
                              } else
                                return (
                                  <td className="align-top p-2 text-center">
                                    <ReactMarkdown
                                      children={row[key]}
                                    ></ReactMarkdown>
                                  </td>
                                );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        title="Trials"
        visible={isModalOpenTrials}
        onOk={() => setIsModalOpenTrials(false)}
        onCancel={() => setIsModalOpenTrials(false)}
        footer={null}
      >
        <table>
          <thead>
            <tr className="roundedRows">
              <th
                style={{
                  backgroundColor: "#27a6a4",
                  color: "whitesmoke",
                }}
                className="font-bold text-center"
              >
                Intervention
              </th>
              <th
                style={{
                  backgroundColor: "#27a6a4",
                  color: "whitesmoke",
                }}
                className="font-bold text-center"
              >
                Number of Trials
              </th>
            </tr>
          </thead>
          <tbody>
            {trialData.map((row: any, index: any) => {
              return (
                <tr id={row.Intervention}>
                  <td className="align-top p-2 text-center border">
                    {row.Intervention}
                  </td>
                  <td className="align-top p-2 text-center border">
                    {row.Trials}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal>
      <Modal
        title="Publications"
        visible={isModalOpenPublications}
        onOk={() => setIsModalOpenPublications(false)}
        onCancel={() => setIsModalOpenPublications(false)}
        footer={null}
      >
        <table>
          <thead>
            <tr className="roundedRows">
              <th
                style={{
                  backgroundColor: "#27a6a4",
                  color: "whitesmoke",
                }}
                className="font-bold text-center"
              >
                Intervention
              </th>
              <th
                style={{
                  backgroundColor: "#27a6a4",
                  color: "whitesmoke",
                }}
                className="font-bold text-center"
              >
                Number of Publications
              </th>
            </tr>
          </thead>
          <tbody>
            {trialData.map((row: any, index: any) => {
              return (
                <tr id={row.Intervention}>
                  <td className="align-top p-2 text-center border">
                    {row.Intervention}
                  </td>
                  <td className="align-top p-2 text-center border">
                    {row.Publications}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal>
      <Modal
        title="Comparators"
        visible={isModalOpenComparators}
        onOk={() => setIsModalOpenComparators(false)}
        onCancel={() => setIsModalOpenComparators(false)}
        footer={null}
      >
        <table>
          <thead>
            <tr className="roundedRows">
              <th
                style={{
                  backgroundColor: "#27a6a4",
                  color: "whitesmoke",
                }}
                className="font-bold text-center"
              >
                Intervention
              </th>
              <th
                style={{
                  backgroundColor: "#27a6a4",
                  color: "whitesmoke",
                }}
                className="font-bold text-center"
              >
                Number of Comparators
              </th>
            </tr>
          </thead>
          <tbody>
            {trialData.map((row: any, index: any) => {
              return (
                <tr id={row.Intervention}>
                  <td className="align-top p-2 text-center border">
                    {row.Intervention}
                  </td>
                  <td className="align-top p-2 text-center border">
                    {row.Comparators}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal>
      {openDrawer && drawerData && (
        <Drawer
          title={<p className="text-white mb-0">{selectedStudy}</p>}
          className="dashboardDrawer"
          placement={"right"}
          onClose={() => {
            setOpenDrawer(false);
          }}
          visible={openDrawer}
          closeIcon={
            <CloseOutlined
              style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
            />
          }
          key={"right"}
          width="18rem"
          getContainer={false}
          style={{ position: "absolute" }}
          zIndex={10}
          bodyStyle={{ padding: "0.5rem" }}
          headerStyle={{
            paddingTop: "0.8rem",
            paddingBottom: "0.5rem",
            paddingLeft: "1rem",
            backgroundColor: "#27a6a4",
            color: "whitesmoke",
          }}
        >
          {drawerData[0].map((key: any) => {
            if (key.dataIndex === "studyname") return <></>;
            return (
              <Row
                style={{ backgroundColor: "#eaf8f8" }}
                className="mt-2 shadow-md rounded-md border px-4 py-1"
              >
                <Col span={24} className="">
                  <div className="flex justify-start items-center">
                    <p className="font-semibold">{key.title}</p>
                  </div>
                  <hr />
                  <div className="flex justify-end items-center">
                    <p>
                      {
                        drawerData[1][
                          key.title.toLowerCase().split(" ").join("")
                        ]
                      }
                    </p>
                  </div>
                </Col>
              </Row>
            );
          })}
        </Drawer>
      )}
    </div>
  );
}
