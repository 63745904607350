import { Save } from "@styled-icons/boxicons-regular/Save";

export default function SaveIcon(props: { handleClick?: () => void }) {
  return (
    <Save
      title="Save Current Settings"
      size={24}
      onClick={() => {
        if (props.handleClick) props.handleClick();
      }}
      style={{ color: "#32A29B" }}
    />
  );
}
