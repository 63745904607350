import React, { useState } from "react";
import AppContext from "../../store";
import { ContainerHeading, FormContainer } from "./style";
import {
	Button,
	Col,
	Form,
	Input,
	Row,
	Select,
	message,
	Popconfirm,
} from "antd";
import * as SettingsHelper from "./service-helper";
import { Tabs } from "antd";
import "./style.css";
import { headerIcons } from "../../globalTheme";
/**
 * Renders the Settings Page.
 *
 * @returns Settings Page of the App.
 */
export default function Settings() {
	const [form] = Form.useForm();
	const context = React.useContext(AppContext);
	const sectionArray = context.subSectionMapping.filter(
		(s) =>
			s.name !== "Abbreviations" &&
			s.name !== "Introduction to digital global value dossiers" &&
			s.name !== "Settings" &&
			s.name !== "References" &&
			s.name !== "Objection Handler"
	);
	// console.log("section array", sectionArray);
	React.useEffect(() => {
		context.setView(10);
	}, []);
	const [selected, setSelected] = useState();
	const [submitted, setSubmitted] = useState(false);
	const sectionSelect = context.subSectionMapping.find(
		(section) => section.id === selected
	);
	const { TabPane } = Tabs;

	async function finish(values: any) {
		const key = "updatable";
		message.loading({ content: "Saving changes...", key });
		const data: any = await SettingsHelper.addSubSection(
			values,
			context.productId
		);
		console.log(" DATA ... ", data);
		if (data) {
			context.setSubSection(data[0].subSectionDetails);
			localStorage.setItem(
				"subSectionMapping",
				`${JSON.stringify(data[0].subSectionDetails)}`
			);
			message.success({ content: "Saved successfully", key });
		} else message.error({ content: "Save failed", key });

		setSubmitted(true);
	}
	async function editSubtab(values: any) {
		const key = "updatable";
		message.loading({ content: "Saving changes...", key });
		const data: any = await SettingsHelper.editSubSection(
			{ name: values.name },
			context.productId,
			values.subsectionId
		);
		console.log(" DATA ... ", values);
		if (data) {
			context.setSubSection(data[0].subSectionDetails);
			localStorage.setItem(
				"subSectionMapping",
				`${JSON.stringify(data[0].subSectionDetails)}`
			);
			message.success({ content: "Saved successfully", key });
		} else message.error({ content: "Save failed", key });

		setSubmitted(true);
	}
	async function deleteSubtab(values: any) {
		const key = "updatable";
		message.loading({ content: "Saving changes...", key });
		const data: any = await SettingsHelper.deleteSubSection(
			context.productId,
			values.subsectionId
		);
		console.log(" DATA ... ", values);
		if (data) {
			context.setSubSection(data[0].subSectionDetails);
			localStorage.setItem(
				"subSectionMapping",
				`${JSON.stringify(data[0].subSectionDetails)}`
			);
			message.success({ content: "Saved successfully", key });
		} else message.error({ content: "Save failed", key });

		setSubmitted(true);
	}
	return (
		<div className="settings">
			{context.isAdmin ? (
				<div className="card-container">
					<Tabs animated>
						<TabPane
							tab="Add subsection"
							key="1"
							style={{ height: "30em" }}
						>
							<Form onFinish={finish}>
								<FormContainer>
									<ContainerHeading>
										Add new subsection here
									</ContainerHeading>
									<Row gutter={30}>
										<Col span={10}>
											<div>
												<Form.Item
													name="sectionId"
													label="Select Section"
													initialValue={null}
												>
													<Select
														onChange={(
															value: any
														) => {
															setSelected(value);
														}}
														placeholder="Select a section"
													>
														{sectionArray.map(
															(element) => {
																return (
																	<Select.Option
																		value={
																			element.id
																		}
																	>
																		{
																			element.name
																		}
																	</Select.Option>
																);
															}
														)}
													</Select>
												</Form.Item>

												{sectionSelect?.subSection &&
													sectionSelect?.subSection
														.length > 0 && (
														<>
															<h4>
																{" "}
																Subsections for
																the selected
																Section:{" "}
															</h4>
															<div
																style={{
																	height: "13em",
																	overflowY:
																		"auto",
																}}
															>
																<p>
																	{sectionSelect?.subSection.map(
																		(
																			element
																		) => {
																			return (
																				<>
																					<ul>
																						<li>
																							{
																								element.name
																							}{" "}
																						</li>
																					</ul>
																				</>
																			);
																		}
																	)}
																</p>
															</div>
														</>
													)}
											</div>
										</Col>
										<Col span={2}></Col>
										<Col span={12}>
											<Form.Item
												name="name"
												label="Add New Subsection"
												rules={[
													{
														required: true,
														message:
															"Please input name for the Subsection",
													},
												]}
											>
												<Input placeholder="Enter subsection name" />
											</Form.Item>

											<Form.Item
												style={{ float: "right" }}
											>
												<Button
													type="primary"
													htmlType="submit"
												>
													Add
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</FormContainer>
							</Form>
						</TabPane>
						<TabPane
							tab="Edit subsection"
							key="2"
							style={{ height: "30em" }}
						>
							<Form onFinish={editSubtab}>
								<FormContainer>
									<ContainerHeading>
										Edit existing subsection here
									</ContainerHeading>
									<Row gutter={30}>
										<Col span={11}>
											<div>
												<Form.Item
													name="subsectionId"
													label="Select subsection to edit"
													rules={[
														{
															required: true,
															message:
																"Please select subsection",
														},
													]}
												>
													<Select
														onChange={(
															value: any
														) => {
															setSelected(value);
														}}
														placeholder="Select a section"
													>
														{sectionArray.map(
															(element) => {
																return (
																	<Select.OptGroup
																		label={
																			element.name
																		}
																	>
																		{element.subSection.map(
																			(
																				el
																			) => {
																				return (
																					<Select.Option
																						value={
																							el.id
																						}
																						disabled={
																							el.isDefault
																						}
																					>
																						{
																							el.name
																						}
																					</Select.Option>
																				);
																			}
																		)}
																	</Select.OptGroup>
																);
															}
														)}
													</Select>
												</Form.Item>
											</div>
										</Col>
										<Col span={1}></Col>
										<Col span={12}>
											<Form.Item
												name="name"
												label="Add New Subsection"
												rules={[
													{
														required: true,
														message:
															"Please input name for the Subsection",
													},
												]}
											>
												<Input placeholder="Enter new subsection name" />
											</Form.Item>

											<Form.Item
												style={{ float: "right" }}
											>
												<Button
													type="primary"
													htmlType="submit"
												>
													Edit
												</Button>
											</Form.Item>
										</Col>
									</Row>
								</FormContainer>
							</Form>
						</TabPane>
						<TabPane tab="Delete sub-tab" key="3">
							<Form onFinish={deleteSubtab} form={form}>
								<FormContainer>
									<ContainerHeading>
										Delete existing subsection here
									</ContainerHeading>
									<Row gutter={30}>
										<Col span={14}>
											<Form.Item
												name="subsectionId"
												label="Select subsection to delete"
											>
												<Select
													onChange={(value: any) => {
														setSelected(value);
													}}
													placeholder="Select a section"
												>
													{sectionArray.map(
														(element) => {
															return (
																<Select.OptGroup
																	label={
																		element.name
																	}
																>
																	{element.subSection.map(
																		(
																			el
																		) => {
																			return (
																				<Select.Option
																					value={
																						el.id
																					}
																					disabled={
																						el.name ===
																							"Overview" ||
																						el.name ===
																							"default"
																					}
																				>
																					{
																						el.name
																					}
																				</Select.Option>
																			);
																		}
																	)}
																</Select.OptGroup>
															);
														}
													)}
												</Select>
											</Form.Item>
										</Col>
										<Col span={10}>
											<Form.Item>
												<Popconfirm
													title="Are you sure to delete this subsection?"
													onConfirm={() =>
														form.submit()
													}
													// onCancel={cancel}
													okText="Yes"
													cancelText="No"
												>
													<Button
														type="primary"
														htmlType="submit"
													>
														Delete
													</Button>
												</Popconfirm>
											</Form.Item>
										</Col>
									</Row>
									<p>
										<i style={{ color: "red" }}>
											Note : Deleting a subsection will
											delete all the related key and
											supporting messages. The data cannot
											be retrieved again.
										</i>
									</p>
								</FormContainer>
							</Form>
						</TabPane>
					</Tabs>
				</div>
			) : (
				<>
					<div className="not-authorised">
						<div className="content">
							<div>
								{
									headerIcons.find((l) => l.id === "Alert")
										?.icon
								}
							</div>
							<div className="text">
								You are not authorized to view this resource.
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
