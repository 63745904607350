import React, { useState } from "react";
import AppContext from "../../../../store";
import {
  SupportingMessageContainer,
  StyledMessageContainer,
  StyledSupportingMessage,
  SupportingMessageImagePlaceholder,
  SupportingMessageTextPlaceholder,
  SupportingMessageHeaderText,
  SupportingMessageBodyTexts,
  SupportingMessageBodyText,
  Actions,
  SupportingMessageIconPlaceholder,
  StyledTooltip,
  StyledSupportingMessageEdit,
  SupportingMessageFlexContainer,
} from "./style";
import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline";
import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import { Edit2Outline } from "@styled-icons/evaicons-outline/Edit2Outline";
import { SwitchHorizontal } from "@styled-icons/heroicons-outline/SwitchHorizontal";
import { ChangeCircle } from "@styled-icons/material-outlined/ChangeCircle";
import { Save } from "@styled-icons/boxicons-regular/Save";
import { Cancel } from "@styled-icons/material-outlined/Cancel";
import ReactMarkdown from "react-markdown";
import { Col, Modal, Popconfirm, Row } from "antd";
import { request } from "../../..";
import { DesktopDownload } from "@styled-icons/octicons/DesktopDownload";
import { ThreeDots } from "@styled-icons/bootstrap/ThreeDots";
import Star from "./star";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { colors, headerIcons } from "../../../../globalTheme";
import Highlight from "react-highlighter";
// import { exportComponentAsPNG } from "react-component-export-image";
/**
 * Renders Supporting Message View (Template 1) of the App.
 *
 * @param data - Data for the Supporting Messages
 * @param handleDelete - Callback function for deleting a Supporting Message
 * @param showModal - Callback function for opening the Model Form for Adding a New Supporting Message
 * @param showModalEdit - Callback function for opening the Model Form for Editing a Supporting Message
 * @param switchView - Callback function for Switching between Templates for the Supporting Message
 *
 * @returns Body Layput of the App
 */
export default function SupportingMessage1(props: {
  data: {
    id: number;
    keyMessage: string;
    supportingKeyMessage: {
      id: number;
      supportingMessageHeader: string;
      componentHeader?: string;
      chartType?: string;
      supportingMessages: {
        id: number;
        bulletPoint: string;
        link?: string;
      }[];
      imageURL: string;
      placeholderId: number;
      references: {
        id?: number;
        referenceHeader?: string;
        referenceLink?: string;
      }[];
    }[];
  };
  handleDelete?: (id: number) => void;
  showModal: () => void;
  showModalEdit: (id: number) => void;
  switchView: React.Dispatch<React.SetStateAction<number>>;
  ref_start?: number;
  refArray: any[];
  handleSaveOrder(items: any): Promise<void>;
}) {
  const context = React.useContext(AppContext);
  const ref1 = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [items, setItems] = React.useState<
    {
      id: number;
      supportingMessageHeader: string;
      componentHeader?: string | undefined;
      chartType?: string | undefined;
      supportingMessages: {
        id: number;
        bulletPoint: string;
        link?: string;
      }[];
      imageURL: string;
      placeholderId: number;
      references: {
        id?: number;
        referenceHeader?: string;
        referenceLink?: string;
      }[];
    }[]
  >(props.data.supportingKeyMessage);
  const [view, setView] = React.useState(false);
  const [bodyText, setBodyText] = useState(<> </>);
  const showModal = (url: string) => {
    setBodyText(<img src={url} alt="" style={{ width: "100%" }}></img>);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  function onChange(
    sourceId: any,
    sourceIndex: any,
    targetIndex: any,
    targetId: any
  ) {
    const nextState = swap(items, sourceIndex, targetIndex);

    setItems(nextState);
  }

  let n = props.ref_start;
  function ShowImg(props: { src: any; header: string; edit: boolean }) {
    const [image, setImage] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(true);
    async function fetchImg(src: string) {
      const data: string | unknown = await request
        .get("brand/GetImage?imagePath=" + src, {
          withCredentials: true,
          responseType: "arraybuffer",
        })
        .then((response: any) => {
          // console.log("RESP", response);
          setImage(Buffer.from(response.data, "binary").toString("base64"));
          setLoading(false);
          return Buffer.from(response.data, "binary").toString("base64");
        })
        .catch((err: any) => {
          console.log(err.message, "error here");
          return null;
        });
    }
    React.useEffect(() => {
      fetchImg(props.src);
    }, []);

    return (
      <>
        {!loading ? (
          <>
            {image ? (
              <img
                key={props.src}
                onClick={() => {
                  if (!props.edit)
                    showModal(
                      image
                        ? "data:image/jpeg;base64," + image
                        : "/images/defaultsupport.png"
                    );
                }}
                src={
                  image
                    ? "data:image/jpeg;base64," + image
                    : "/images/defaultsupport.png"
                }
                alt={props.header}
                style={{
                  maxHeight: "30vh",
                  padding: "1em",
                  margin: "0 auto",
                  objectFit: "cover", // border: "2px solid red",
                }}
              />
            ) : (
              <div
                style={{
                  height: "90px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "90px",
                    color: colors.zsGray30,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {headerIcons.find((l) => l.id === "No Image")?.Icon}
                  <div>No image available</div>
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  React.useEffect(() => {
    setItems(props.data.supportingKeyMessage);
  }, [props.data.supportingKeyMessage]);
  return (
    <>
      <Modal
        width={1000}
        visible={isModalVisible}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {bodyText}
      </Modal>
      <SupportingMessageContainer>
        <StyledMessageContainer>
          {/* <img
						src="/icons/SupportingMessageStar.png"
						alt=""
						style={{
							position: "absolute",
							top: 0,
							left: 20,
							height: "1.4rem",
							objectFit: "cover",
							// border: "2px solid red",
						}}
					/> */}

          {/* <Star /> */}
          <ReactMarkdown source={props.data.keyMessage} />
        </StyledMessageContainer>
        {context.isAdmin && (
          <Actions>
            {view && (
              <button
                onClick={() => {
                  setView(false);
                  setItems(props.data.supportingKeyMessage);
                }}
              >
                <Cancel
                  size={22}
                  style={{
                    marginRight: "2px",
                  }}
                />
                Cancel
              </button>
            )}
            {view && (
              <button
                onClick={() => {
                  setView(false);
                  props.handleSaveOrder(items);
                }}
              >
                <Save
                  size={20}
                  style={{
                    marginRight: "2px",
                  }}
                />
                Save Order
              </button>
            )}
            {!view && items.length > 0 && (
              <button
                onClick={() => {
                  setView(true);
                }}
              >
                <ChangeCircle
                  size={20}
                  style={{
                    marginRight: "2px",
                  }}
                />
                Change Order
              </button>
            )}
            <button onClick={() => props.switchView(2)}>
              <SwitchHorizontal
                title="Switch View"
                size={20}
                style={{ marginRight: "2px" }}
              />
              Switch View
            </button>
            <button onClick={props.showModal}>
              <AddCircleOutline
                title="Add New Message"
                size={20}
                style={{ marginRight: "2px" }}
              />
              Add New
            </button>
          </Actions>
        )}
        <div>
          {view ? (
            <GridContextProvider onChange={onChange}>
              <GridDropZone
                className="dropZone"
                id="messages"
                boxesPerRow={1}
                rowHeight={160}
                style={{ height: `${160 * items.length}px` }}
              >
                {items.map((message) => {
                  return (
                    <GridItem
                      className="grabcard"
                      // style={{ cursor: "grabbing" }}
                      key={message.id}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          // background: "red",
                        }}
                      >
                        <StyledSupportingMessageEdit edit={true}>
                          <SupportingMessageFlexContainer>
                            <div className="content">
                              <SupportingMessageTextPlaceholder>
                                <SupportingMessageHeaderText>
                                  {message.supportingMessageHeader}
                                  {message.references &&
                                    message.references.length > 0 &&
                                    message.references.map((refs) => {
                                      return (
                                        <StyledTooltip
                                          title={
                                            <>
                                              <p
                                                style={{
                                                  marginBottom: 0,
                                                }}
                                              >
                                                {refs.referenceHeader}
                                              </p>

                                              {refs.referenceLink && (
                                                <a
                                                  href={refs.referenceLink}
                                                  target="_blank"
                                                >
                                                  Link
                                                </a>
                                              )}
                                            </>
                                          }
                                          // title={"Refs"}
                                          // className="reference-tooltip"
                                        >
                                          <span>
                                            [
                                            {props.refArray.findIndex(
                                              (r: any) =>
                                                r.referenceHeader ===
                                                refs.referenceHeader
                                            ) + 1}
                                            ]
                                          </span>
                                        </StyledTooltip>
                                      );
                                    })}
                                </SupportingMessageHeaderText>
                                {message.placeholderId !== 4 && (
                                  <SupportingMessageBodyTexts>
                                    {message.supportingMessages.map(
                                      (bullet) => {
                                        return (
                                          <>
                                            <SupportingMessageBodyText>
                                              <ReactMarkdown
                                                source={bullet.bulletPoint}
                                              />

                                              {/* {bullet.bulletPoint} */}
                                            </SupportingMessageBodyText>
                                          </>
                                        );
                                      }
                                    )}
                                  </SupportingMessageBodyTexts>
                                )}

                                {message.placeholderId === 4 && (
                                  <SupportingMessageBodyTexts>
                                    <Row gutter={[16, 16]}>
                                      {message.supportingMessages.map(
                                        (bullet) => {
                                          return (
                                            <>
                                              <Col
                                                style={{
                                                  padding: "30px",
                                                }}
                                                span={12}
                                              >
                                                <li
                                                  style={{
                                                    margin: "0 1rem",
                                                  }}
                                                >
                                                  <SupportingMessageBodyText>
                                                    <ReactMarkdown
                                                      source={
                                                        bullet.bulletPoint
                                                      }
                                                    />

                                                    {/* {bullet.bulletPoint} */}
                                                  </SupportingMessageBodyText>
                                                </li>
                                              </Col>
                                            </>
                                          );
                                        }
                                      )}
                                    </Row>
                                  </SupportingMessageBodyTexts>
                                )}
                              </SupportingMessageTextPlaceholder>

                              {message.placeholderId !== 4 && (
                                <SupportingMessageImagePlaceholder
                                  style={{
                                    cursor: view ? "" : "zoom-in",
                                  }}
                                >
                                  <ShowImg
                                    key={message.imageURL}
                                    edit={view}
                                    src={message.imageURL}
                                    header={message.supportingMessageHeader}
                                  />
                                  {/* <img
										onClick={() =>
											showModal(
												message.imageURL
													? "https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
															message.imageURL
													: "/images/defaultsupport.png"
											)
										}
										src={
											message.imageURL
												? "https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
												  message.imageURL
												: "/images/defaultsupport.png"
										}
										alt={message.supportingMessageHeader}
										style={{
											maxHeight: "30vh",
											padding: "1em",
											margin: "0 auto",
											objectFit: "cover", // border: "2px solid red",
										}}
									/> */}
                                </SupportingMessageImagePlaceholder>
                              )}
                              {/* <Modal
															width={1000}
															visible={
																isModalVisible
															}
															footer={null}
															onOk={handleOk}
															onCancel={
																handleCancel
															}
														>
															{bodyText}
														</Modal> */}

                              {/* <ComponentToPrint
								ref={ref1}
								url={
									"https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
									message.imageURL
								}
							/> */}
                              <SupportingMessageIconPlaceholder>
                                {/* <DesktopDownload
									size={22}
									style={{ cursor: "pointer" }}
									onClick={() =>
										exportComponentAsPNG(ref1, {
											html2CanvasOptions: {
												onclone: (clonedElement) => {
													//@ts-ignore
													clonedElement.getElementById(
														"hiddenComponent"
													).style.visibility =
														"visible";
												},
											},
										})
									}
								/> */}
                                {context.isAdmin && (
                                  <>
                                    <Popconfirm
                                      title="Are you sure to delete this message?"
                                      onConfirm={() => {
                                        //@ts-ignore
                                        props.handleDelete(message.id);
                                      }} // onCancel={cancel}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Delete
                                        className="messageIcons"
                                        title="Delete Message"
                                        size={26}
                                        style={{
                                          marginTop: "12px",
                                          cursor: "pointer",
                                        }}
                                        //   onClick={() => {
                                        //     //@ts-ignore
                                        //     props.handleDelete(message.id);
                                        //   }}
                                      />
                                    </Popconfirm>
                                    <Edit2Outline
                                      className="messageIcons"
                                      title="Edit Message"
                                      size={22}
                                      style={{
                                        marginTop: "12px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        props.showModalEdit(message.id)
                                      }
                                    />
                                  </>
                                )}
                              </SupportingMessageIconPlaceholder>
                            </div>

                            <div
                              style={{
                                // background: "red",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {view && (
                                <>
                                  <ThreeDots
                                    size={24}
                                    // style={{
                                    // 	float: "right",
                                    // 	marginRight:
                                    // 		"20px",
                                    // }}
                                  />
                                </>
                              )}
                            </div>
                          </SupportingMessageFlexContainer>
                        </StyledSupportingMessageEdit>
                      </div>
                    </GridItem>
                  );
                })}
              </GridDropZone>
            </GridContextProvider>
          ) : (
            <>
              {items.map((message) => {
                return (
                  <StyledSupportingMessage>
                    <SupportingMessageFlexContainer>
                      <SupportingMessageTextPlaceholder>
                        <SupportingMessageHeaderText>
                          <Highlight
                            search={context.searchWord}
                            matchStyle={{
                              color: "red",
                              backgroundColor: "yellow",
                              padding: 0,
                            }}
                          >
                            {message.supportingMessageHeader}
                          </Highlight>
                          {message.references &&
                            message.references.length > 0 &&
                            message.references.map((refs) => {
                              return (
                                <StyledTooltip
                                  title={
                                    <>
                                      <p
                                        style={{
                                          marginBottom: 0,
                                        }}
                                      >
                                        {refs.referenceHeader}
                                      </p>

                                      {refs.referenceLink && (
                                        <a
                                          href={refs.referenceLink}
                                          target="_blank"
                                        >
                                          Link
                                        </a>
                                      )}
                                    </>
                                  }
                                  // title={"Refs"}
                                  // className="reference-tooltip"
                                >
                                  <span>
                                    [
                                    {props.refArray.findIndex(
                                      (r: any) =>
                                        r.referenceHeader ===
                                        refs.referenceHeader
                                    ) + 1}
                                    ]
                                  </span>
                                </StyledTooltip>
                              );
                            })}
                        </SupportingMessageHeaderText>
                        {message.placeholderId !== 4 && (
                          <SupportingMessageBodyTexts>
                            {message.supportingMessages.map((bullet) => {
                              if (
                                bullet.bulletPoint === null ||
                                bullet.bulletPoint === undefined
                              ) {
                                return (
                                  <>
                                    <SupportingMessageBodyText>
                                      <ReactMarkdown
                                        source={bullet.bulletPoint}
                                      />

                                      {/* {bullet.bulletPoint} */}
                                    </SupportingMessageBodyText>
                                  </>
                                );
                              }
                              return (
                                <>
                                  <SupportingMessageBodyText>
                                    {bullet.bulletPoint
                                      .toLowerCase()
                                      .includes(context.searchWord) &&
                                    context.searchWord !== "" ? (
                                      <Highlight
                                        search={context.searchWord}
                                        matchStyle={{
                                          color: "red",
                                          backgroundColor: "yellow",
                                          padding: 0,
                                        }}
                                      >
                                        {bullet.bulletPoint}
                                      </Highlight>
                                    ) : (
                                      <ReactMarkdown
                                        source={bullet.bulletPoint}
                                      />
                                    )}

                                    {/* <ReactMarkdown
                                      source={bullet.bulletPoint}
                                    /> */}

                                    {/* {bullet.bulletPoint} */}
                                  </SupportingMessageBodyText>
                                </>
                              );
                            })}
                          </SupportingMessageBodyTexts>
                        )}

                        {message.placeholderId === 4 && (
                          <SupportingMessageBodyTexts>
                            <Row gutter={[16, 16]}>
                              {message.supportingMessages.map((bullet) => {
                                if (
                                  bullet.bulletPoint === null ||
                                  bullet.bulletPoint === undefined
                                ) {
                                  return (
                                    <>
                                      <Col
                                        style={{
                                          padding: "30px",
                                        }}
                                        span={12}
                                      >
                                        <li
                                          style={{
                                            margin: "0 1rem",
                                          }}
                                        >
                                          <SupportingMessageBodyText>
                                            <ReactMarkdown
                                              source={bullet.bulletPoint}
                                            />
                                          </SupportingMessageBodyText>
                                        </li>
                                      </Col>
                                    </>
                                  );
                                }
                                return (
                                  <>
                                    <Col
                                      style={{
                                        padding: "30px",
                                      }}
                                      span={12}
                                    >
                                      <li
                                        style={{
                                          margin: "0 1rem",
                                        }}
                                      >
                                        <SupportingMessageBodyText>
                                          {bullet.bulletPoint
                                            .toLowerCase()
                                            .includes(context.searchWord) &&
                                          context.searchWord !== "" ? (
                                            <Highlight
                                              search={context.searchWord}
                                              matchStyle={{
                                                color: "red",
                                                backgroundColor: "yellow",
                                                padding: 0,
                                              }}
                                            >
                                              {bullet.bulletPoint}
                                            </Highlight>
                                          ) : (
                                            <ReactMarkdown
                                              source={bullet.bulletPoint}
                                            />
                                          )}

                                          {/* <ReactMarkdown
                                            source={bullet.bulletPoint}
                                          /> */}

                                          {/* {bullet.bulletPoint} */}
                                        </SupportingMessageBodyText>
                                      </li>
                                    </Col>
                                  </>
                                );
                              })}
                            </Row>
                          </SupportingMessageBodyTexts>
                        )}
                      </SupportingMessageTextPlaceholder>

                      {message.placeholderId !== 4 && (
                        <SupportingMessageImagePlaceholder
                          style={{
                            cursor: view ? "" : "zoom-in",
                          }}
                        >
                          <ShowImg
                            key={message.imageURL}
                            edit={view}
                            src={message.imageURL}
                            header={message.supportingMessageHeader}
                          />
                          {/* <img
								onClick={() =>
									showModal(
										message.imageURL
											? "https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
													message.imageURL
											: "/images/defaultsupport.png"
									)
								}
								src={
									message.imageURL
										? "https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
										  message.imageURL
										: "/images/defaultsupport.png"
								}
								alt={message.supportingMessageHeader}
								style={{
									maxHeight: "30vh",
									padding: "1em",
									margin: "0 auto",
									objectFit: "cover", // border: "2px solid red",
								}}
							/> */}
                        </SupportingMessageImagePlaceholder>
                      )}
                    </SupportingMessageFlexContainer>
                    {/* <Modal
											width={1000}
											visible={isModalVisible}
											footer={null}
											onOk={handleOk}
											onCancel={handleCancel}
										>
											{bodyText}
										</Modal> */}

                    {/* <ComponentToPrint
						ref={ref1}
						url={
							"https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
							message.imageURL
						}
					/> */}
                    <SupportingMessageIconPlaceholder>
                      {/* <DesktopDownload
							size={22}
							style={{ cursor: "pointer" }}
							onClick={() =>
								exportComponentAsPNG(ref1, {
									html2CanvasOptions: {
										onclone: (clonedElement) => {
											//@ts-ignore
											clonedElement.getElementById(
												"hiddenComponent"
											).style.visibility =
												"visible";
										},
									},
								})
							}
						/> */}
                      {context.isAdmin && (
                        <>
                          <Popconfirm
                            title="Are you sure to delete this message?"
                            onConfirm={() => {
                              //@ts-ignore
                              props.handleDelete(message.id);
                            }} // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Delete
                              className="messageIcons"
                              title="Delete Message"
                              size={26}
                              style={{
                                marginTop: "12px",
                                cursor: "pointer",
                              }}
                              //   onClick={() => {
                              //     //@ts-ignore
                              //     props.handleDelete(message.id);
                              //   }}
                            />
                          </Popconfirm>
                          <Edit2Outline
                            className="messageIcons"
                            title="Edit Message"
                            size={22}
                            style={{
                              marginTop: "12px",
                              cursor: "pointer",
                            }}
                            onClick={() => props.showModalEdit(message.id)}
                          />
                        </>
                      )}
                    </SupportingMessageIconPlaceholder>
                  </StyledSupportingMessage>
                );
              })}
            </>
          )}
        </div>
      </SupportingMessageContainer>
    </>
  );
}
// const ComponentToPrint = React.forwardRef(
// 	(props: { url: string }, ref: any) => (
// 		<div
// 			ref={ref}
// 			id="hiddenComponent"
// 			style={{
// 				position: "absolute",
// 				top: "50vh",
// 				zIndex: 1,
// 				visibility: "visible",
// 				background: "red",
// 				padding: "20px",
// 			}}
// 		>
// 			<h2>Hello</h2>
// 			<div style={{ background: "green", padding: "10px" }}>
// 				<img
// 					id="Banner Image"
// 					src={props.url}
// 					alt=""
// 					style={{ width: "100%" }}
// 				/>
// 			</div>
// 		</div>
// 	)
// );
