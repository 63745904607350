import styled from "styled-components";
import { colors } from "./../globalTheme";
export const LayoutContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 0;
	border-radius: 0px;
	width: 100%;
	height: 100vh;
	border: 1px solid black;
	box-sizing: border-box;
	background-color: ${colors.zsWhite};
	overflow-y: hidden;
`;

export const Body = styled.div`
	position: relative;
	margin: 0;
	padding-left: 5rem;
	border-radius: 0px;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
`;

export const BodyContent = styled.div`
	position: relative;
	width: 100%;
	height: calc(100vh - 13%);
	padding: 0 0rem 1rem 0rem;
	box-sizing: border-box;
	background-color: ${colors.zsBodyBg};
	overflow-y: hidden;
	/* ::-webkit-scrollbar {
		display: none;
	} */
`;

