import { request } from "./../layout";
export const getReferenceData = async (productId: number) => {
	const fetchUrl = "SubSection/GetReferences?BrandId=" + productId;
	// console.log("Fetching...", fetchUrl);
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			console.log("Error : ", err.message);
			return null;
		});

	return data;
};
