import { colors } from "../../globalTheme";
import { Form, Input, Button, Space, Upload, Switch, Row, Col } from "antd";
import { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import AppContext from "../../store";
import React from "react";
const FormField2 = (props: {
	BannerInfo: boolean;
	handleEdit?: (value: any, key: number) => void;
	editData?: any;
	subSectionId: number;
	sectionId: number;
	closeModal?: () => void;
}) => {
	const context = React.useContext(AppContext);

	const [noImgHeader, setImgHeader] = useState(props.editData.noImgHeader);

	function onChange(checked: any) {
		setImgHeader(checked);
	}
	const modifiedEditData = {
		...props.editData,
		overlayTextColor: props.editData.overlayTextColor
			? props.editData.overlayTextColor
			: colors.zsOrange,
		overlayTextColor2: props.editData.overlayTextColor2
			? props.editData.overlayTextColor2
			: colors.zsTeal,
		headerTextBg: props.editData.headerTextBg
			? props.editData.headerTextBg
			: colors.zsTeal,
		headerTextColor: props.editData.headerTextColor
			? props.editData.headerTextColor
			: colors.zsGray90,
	};
	let fileName: string = "";
	let filePath: string =
		process.env.REACT_APP_API_URL +
		"brand/UploadImage?imagePath=" +
		context.productId +
		"/" +
		props.sectionId +
		"/" +
		props.subSectionId;
	const defaultFileList: any = [];
	if (props.editData.imageUrl) {
		const tempName = props.editData.imageUrl.split("/");
		fileName = tempName[tempName.length - 1];
		defaultFileList.push({
			uid: "1",
			name: tempName[tempName.length - 1],

			// response: "Server Error 500"; // custom error message to show
			url: filePath,
		});
	}

	return (
		<Form
			name="dynamic_form_nest_item"
			onFinish={(value) => {
				console.log(value);

				if (fileName !== "") {
					value.imageUrl =
						context.productId +
						"/" +
						props.sectionId +
						"/" +
						props.subSectionId +
						"/" +
						fileName.split(" ").join("_");
				}
				value.noImgHeader = noImgHeader;
				if (props.closeModal) props.closeModal();
				if (props.handleEdit)
					props.handleEdit(value, props.editData.id);
			}}
			autoComplete="off"
			initialValues={modifiedEditData}
		>
			<Row>
				<Col span={5}>
					<p>Banner Image:</p>
				</Col>
				<Col span={19}>
					<Form.Item name="imageUrl">
						<Upload
							defaultFileList={defaultFileList}
							beforeUpload={(file) => (fileName = file.name)}
							action={filePath}
							withCredentials={true}
							maxCount={1}
							headers={{
								userName:
									localStorage.getItem("userName") || "",
								"x-api-key":
									process.env.REACT_APP_API_KEY || "",
							}}
						>
							<Button icon={<UploadOutlined />}>
								Click to Upload
							</Button>
						</Upload>
					</Form.Item>
					<p style={{ fontStyle: "italic", color: "grey" }}>
						{" "}
						Images should be sized to the dimensions: 1200 X 387
					</p>
					{/* <input type="file" onChange={upload} /> */}
				</Col>
			</Row>

			{props.subSectionId === 1 && (
				<>
					<Row align="top">
						<Col span={7}>
							<p>Product Name Color:</p>
						</Col>
						<Col span={17}>
							<Form.Item name="overlayTextColor">
								<Input
									style={{ width: "80px" }}
									type="color"
								></Input>
							</Form.Item>
						</Col>
						<Col span={7}>
							<p>Product Description Color:</p>
						</Col>
						<Col span={17}>
							<Form.Item name="overlayTextColor2">
								<Input
									style={{ width: "80px" }}
									type="color"
								></Input>
							</Form.Item>
						</Col>
					</Row>
				</>
			)}

			{props.subSectionId !== 1 && (
				<div>
					{!props.BannerInfo && (
						<div>
							<Row>
								<Col span={5}>
									{" "}
									<p>Text Header:</p>{" "}
								</Col>
								<Col span={19}>
									{" "}
									<Form.Item
										name="imgHeader"
										// label="Text Header"
										rules={[
											{
												required: true,
												message:
													"Please input Banner Heading",
											},
										]}
									>
										<Input
											placeholder="Enter header"
											maxLength={36}
										/>
									</Form.Item>
								</Col>

								<Form.Item
									name="noImgHeader"
									// valuePropName="checked"
								>
									<span style={{ marginRight: "10px" }}>
										{" "}
										Image Caption
									</span>
									<Switch
										onChange={onChange}
										checked={noImgHeader}
									/>
									<span style={{ marginLeft: "10px" }}>
										Overlay
									</span>
								</Form.Item>
							</Row>
							{noImgHeader ? (
								<div>
									<Row>
										<Col span={5}>
											{" "}
											<p>Overlay Color:</p>
										</Col>
										<Col span={19}>
											{" "}
											<Form.Item
												name="overlayTextColor"
												rules={[
													{
														required: true,
														message:
															"Overlay Color missing",
													},
												]}
											>
												<Input
													type="color"
													style={{ width: "80px" }}
												/>
											</Form.Item>
										</Col>

										{/* <Form.Item
                name="overlayTextHeader"
                label="Overlay Title"
                rules={[{ required: true, message: "" }]}
              >
                <Input />
              </Form.Item> */}
										<Col span={5}>
											{" "}
											<p>Overlay Description:</p>
										</Col>
										<Col span={19}>
											{" "}
											<Form.Item name="overlayTextSubHeader">
												<Input.TextArea
													placeholder="Enter description"
													maxLength={50}
												/>
											</Form.Item>
										</Col>
									</Row>
								</div>
							) : (
								<div>
									<Row>
										<Col span={5}>
											<p>Background Color:</p>
										</Col>
										<Col span={19}>
											<Form.Item name="headerTextBg">
												<Input
													type="color"
													style={{ width: "80px" }}
												/>
											</Form.Item>
										</Col>
										<Col span={5}>
											<p>Heading Color: </p>
										</Col>
										<Col span={19}>
											{" "}
											<Form.Item name="headerTextColor">
												<Input
													type="color"
													style={{ width: "80px" }}
												/>
											</Form.Item>
										</Col>
									</Row>
								</div>
							)}
						</div>
					)}
				</div>
			)}

			<Form.Item>
				<Button type="primary" htmlType="submit">
					Submit
				</Button>
			</Form.Item>
		</Form>
	);
};

export default FormField2;
