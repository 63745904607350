import {
	CardContainer,
	CardHeader,
	CardHeadingText,
	CardSubHeadingText,
	CardHeaderActions,
	CardHeaderAction,
	CardBody,
	CardHeaderText,
	CardFooter,
	CardFooterText,
	HeaderRibbon,
} from "./style";
import GearIcon from "./components/GearIcon";
import FullScreen from "./components/FullScreen";
import Save from "./components/Save";
import {Actions} from "../../layout/components/body-layout/style";
import { Modal } from "antd";
import React from "react";
import FormField5 from "../form-fields/formField5";

/**
 * Interface for Card Component
 */
export interface Interface {
	header?: string | React.ReactNode;
	subHeader?: string;
	footer?: string | React.ReactNode;
	bg?: string;
	minHeight?: string;
	height?: string;
	hideActions?: boolean[];
	hideRibbon?: boolean;
	bodyPadding?: string;
	children?: React.ReactNode;
	handleEdit?: any;
	handleSave?: any;
}
/**
 * Renders a Card Component with the chosen customizations
 *
 * @param props - The props for the Card Component
 *
 * @returns Card Component with Header, Body and Footer
 */
export default function Card(props: Interface) {
	return (
		<>
			<CardContainer bg={props.bg} height={props.height}>
				<CardHeader>
					<CardHeaderText>
						<CardHeadingText>{props.header}</CardHeadingText>
						{props.subHeader && (
							<CardSubHeadingText>
								{props.subHeader}
							</CardSubHeadingText>
						)}
					</CardHeaderText>

					<CardHeaderActions>
						{props.hideActions && !props.hideActions[0] && (
							<CardHeaderAction>
								<Save
									handleClick={() => {
										if (props.handleSave)
											props.handleSave();
									}}
								/>
							</CardHeaderAction>
						)}
						{props.hideActions && !props.hideActions[1] && (
							<CardHeaderAction>
								<GearIcon
									handleClick={() => {
										if (props.handleEdit)
											props.handleEdit();
									}}
								/>
							</CardHeaderAction>
						)}
					</CardHeaderActions>

					{!props.hideRibbon && <HeaderRibbon />}
				</CardHeader>

				<CardBody
					minHeight={props.minHeight}
					bodyPadding={props.bodyPadding}
				>
					{props.children}
				</CardBody>
				{props.footer && (
					<CardFooter>
						<CardFooterText>{props.footer}</CardFooterText>
					</CardFooter>
				)}
			</CardContainer>
		</>
	);
}
