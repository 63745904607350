import {
  Button,
  Col,
  Drawer,
  Menu,
  message,
  Modal,
  Popover,
  Row,
  Tooltip,
} from "antd";
import { TextBulletListSquare } from "@styled-icons/fluentui-system-regular/TextBulletListSquare";
import BeatLoader from "react-spinners/BeatLoader";
import { useEffect, useMemo, useState } from "react";
import { ExternalLinkOutline } from "@styled-icons/evaicons-outline/ExternalLinkOutline";
import {
  EditFilled,
  SaveOutlined,
  DeleteOutlined,
  ReloadOutlined,
  UndoOutlined,
  FileOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import EvidenceAtlas from "../../components/Results/EvidenceAtlas";
import Dashboard from "../../components/Results/Dashboard";
import Countries from "../../../countries.json";
import PatientCharacteristics from "./PatientCharacteristics";
import StudyCharacteristics from "./StudyCharacteristics";
import countryList from "../../../countryList.json";
import { request } from "../../layout/SLR_Layout";
import { Link, useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import SimpleMdeReact from "react-simplemde-editor";

export default function Characteristics() {
  const [columnsStudy, setColumnsStudy] = useState<any>([]);
  const [defaultColumnsStudy, setDefaultColumnsStudy] = useState<any>([]);
  const [additionalColumnsStudy, setAdditionalColumnsStudy] = useState<any>({
    checked: [],
    unChecked: [],
  });
  const [columnsPatient, setColumnsPatient] = useState<any>([]);
  const [defaultColumnsPatient, setDefaultColumnsPatient] = useState<any>([]);
  const [additionalColumnsPatient, setAdditionalColumnsPatient] = useState<any>(
    {
      checked: [],
      unChecked: [],
    }
  );
  const [dataStudy, setDataStudy] = useState<any>([]);
  const [dataPatient, setDataPatient] = useState<any>([]);
  const [keySelected, setKeySelected] = useState("atlas");
  const [inEditMode, setInEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [dashboardColumns, setDashboardColumns] = useState<any>([]);
  const [originalDashboardData, setOriginalDashboardData] = useState<any>([]);
  const [originalDashboardColumns, setOriginalDashboardColumns] = useState<any>(
    []
  );
  const [evidenceAtlasData, setEvidenceAtlasData] = useState<any>([]);
  const [maxDomainLength, setMaxDomainLength] = useState(10);
  const [selectedCountry, setSelectedCountry] = useState<any>("");
  const [studyDesignItems, setStudyDesignItems] = useState<any>([]);
  const [ethnicityItems, setEthnicityItems] = useState<any>([]);
  const [mutationStatusItems, setMutationStatusItems] = useState<any>([]);
  const [histologyItems, setHistologyItems] = useState<any>([]);
  const [genderItems, setGenderItems] = useState<any>([]);
  const [metastaticSitesItems, setMetastaticSitesItems] = useState<any>([]);
  const [metastaticStageItems, setMetastaticStageItems] = useState<any>([]);
  const [smokingItems, setSmokingItems] = useState<any>([]);
  const [saveCounter, setSaveCounter] = useState<any>(0);
  const [dataFetched, setDataFetched] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [screenInfo, setScreenInfo] = useState<any>(
    "This section shows characteristics of the studies included in this SLR; Click on the tabs to navigate through the subsections"
  );
  const [footnote, setFootnote] = useState<any>("");
  const [footnoteInitial, setFootnoteInitial] = useState<any>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inEditModeFootnote, setInEditModeFootnote] = useState(false);
  const [isLoadingFootnote, setIsLoadingFootnote] = useState(true);
  const [studyDetails, setStudyDetails] = useState<any>({});

  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "100%",
      maxHeight: "100%",
    };
  }, []);

  const navigate = useHistory();

  useEffect(() => {
    if (
      window.sessionStorage.getItem("selectedBackgroundName") === undefined ||
      window.sessionStorage.getItem("selectedBackgroundName") === null ||
      window.sessionStorage.getItem("selectedBackgroundName") === "" ||
      window.sessionStorage.getItem("productReviewId") === undefined ||
      window.sessionStorage.getItem("productReviewId") === null ||
      window.sessionStorage.getItem("productReviewId") === "" ||
      window.localStorage.getItem("productId") === undefined ||
      window.localStorage.getItem("productId") === null ||
      window.localStorage.getItem("productId") === ""
    ) {
      navigate.push("/SLR");
      return;
    }

    setLoading(true);

    if (!dataFetched) {
      const productReviewId = window.sessionStorage.getItem("productReviewId");
      request
        .get("/GetCharacteristics?productReviewId=" + productReviewId)
        .then((res: any) => {
          console.log("response from API", res);
          if (res.status === 200) {
            if (res.data) {
              //for evidence atlas data
              let evidenceAtlasDataTemp: any = {};
              for (
                let i = 0;
                i < res.data.studyCharacteristic.data.length;
                i++
              ) {
                //get all countries and create data in following format
                //{
                //  countryName : countOfStudiesInThatCountry
                //}
                if (res.data.studyCharacteristic.data[i]["countries"]) {
                  let countries =
                    res.data.studyCharacteristic.data[i]["countries"]
                      ?.replaceAll(", ", "***")
                      .split("***") || [];
                  countries?.map((country: any) => {
                    evidenceAtlasDataTemp[country] =
                      (evidenceAtlasDataTemp[country] || 0) + 1;
                  });
                }
              }
              let evidenceAtlasDataTemp2: any = [];
              let countries = Object.keys(evidenceAtlasDataTemp);
              let maxDomainLength = 10;
              countries?.map((country: any) => {
                let listStudies: any = [];
                for (
                  let i = 0;
                  i < res.data.studyCharacteristic.data.length;
                  i++
                ) {
                  if (
                    res.data.studyCharacteristic.data[i].countries.includes(
                      country
                    )
                  ) {
                    listStudies.push(
                      res.data.studyCharacteristic.data[i].studyname
                    );
                  }
                }
                maxDomainLength =
                  listStudies.length > maxDomainLength
                    ? listStudies.length
                    : maxDomainLength;
                evidenceAtlasDataTemp2.push({
                  country: country,
                  value: evidenceAtlasDataTemp[country],
                  studies: listStudies,
                });
              });
              evidenceAtlasDataTemp2?.map((data: any) => {
                let obj = countryList.countryList.find(
                  (o) => o.country === data.country
                );
                data["id"] = obj?.code;
              });
              setEvidenceAtlasData([...evidenceAtlasDataTemp2]);
              setMaxDomainLength(Math.ceil(maxDomainLength / 5) * 5);
              setLoading(false);

              //for study
              let tempDefaultStudy: any = [];
              let tempAdditionalStudy: any = {
                checked: [],
                unChecked: [],
              };
              let tempColumnsStudy: any = [];
              res.data.studyCharacteristic.columns.map((item: any) => {
                if (item.isFixed) {
                  tempDefaultStudy.push(item.title);
                  tempColumnsStudy.push(item);
                } else {
                  if (item.isSelected) {
                    tempAdditionalStudy.checked.push(item.title);
                    tempColumnsStudy.push(item);
                  } else tempAdditionalStudy.unChecked.push(item.title);
                }
              });

              tempColumnsStudy.sort((a: any, b: any) => a.order - b.order);
              setColumnsStudy([...tempColumnsStudy]);
              setDataStudy([...res.data.studyCharacteristic.data]);

              setAdditionalColumnsStudy(tempAdditionalStudy);
              setDefaultColumnsStudy([...tempDefaultStudy]);
              let tempValues: any = [];
              res.data.studyCharacteristic.studyDesign.map((item: any) => {
                tempValues.push(item.fieldName);
              });
              setStudyDesignItems([...tempValues]);

              //for patient
              let tempDefaultPatient: any = [];
              let tempAdditionalPatient: any = {
                checked: [],
                unChecked: [],
              };
              let tempColumnsPatient: any = [];
              res.data.patientCharacteristic.columns.map((item: any) => {
                if (item.isFixed) {
                  tempDefaultPatient.push(item.title);
                  tempColumnsPatient.push(item);
                } else {
                  if (item.isSelected) {
                    tempAdditionalPatient.checked.push(item.title);
                    tempColumnsPatient.push(item);
                  } else tempAdditionalPatient.unChecked.push(item.title);
                }
              });

              tempColumnsPatient.sort((a: any, b: any) => a.order - b.order);
              setColumnsPatient([...tempColumnsPatient]);
              setDataPatient([...res.data.patientCharacteristic.data]);

              setAdditionalColumnsPatient(tempAdditionalPatient);
              setDefaultColumnsPatient([...tempDefaultPatient]);
              let tempValues1: any = [];
              res.data.patientCharacteristic.ethnicity.map((item: any) => {
                tempValues1.push(item.fieldName);
              });
              setEthnicityItems([...tempValues1]);
              let tempValues2: any = [];
              res.data.patientCharacteristic.mutationStatus.map((item: any) => {
                tempValues2.push(item.fieldName);
              });
              setMutationStatusItems([...tempValues2]);
              let tempValues3: any = [];
              res.data.patientCharacteristic.histology.map((item: any) => {
                tempValues3.push(item.fieldName);
              });
              setHistologyItems([...tempValues3]);
              let tempValues4: any = [];
              res.data.patientCharacteristic.gender.map((item: any) => {
                tempValues4.push(item.fieldName);
              });
              setGenderItems([...tempValues4]);
              let tempValues5: any = [];
              res.data.patientCharacteristic.metastaticSites.map(
                (item: any) => {
                  tempValues5.push(item.fieldName);
                }
              );
              setMetastaticSitesItems([...tempValues5]);
              let tempValues6: any = [];
              res.data.patientCharacteristic.metastaticStage.map(
                (item: any) => {
                  tempValues6.push(item.fieldName);
                }
              );
              setMetastaticStageItems([...tempValues6]);
              let tempValues7: any = [];
              res.data.patientCharacteristic.smoking.map((item: any) => {
                tempValues7.push(item.fieldName);
              });
              setSmokingItems([...tempValues7]);

              //processing for dashboard and evidence atlas table data
              let columnsTemp: any = [];
              tempColumnsStudy.map((col: any) => {
                if (
                  col.dataIndex === "studyname" ||
                  col.dataIndex === "intervention" ||
                  col.dataIndex === "comparator" ||
                  col.dataIndex === "numberoftrials" ||
                  col.dataIndex === "numberofpublications" ||
                  col.dataIndex === "studydesign" ||
                  col.dataIndex === "countries" ||
                  col.dataIndex === "studyduration(months)" ||
                  col.dataIndex === "ntotalrandomized"
                )
                  columnsTemp.push(col);
              });
              tempColumnsPatient.map((col: any) => {
                if (
                  col.dataIndex === "age(years)" ||
                  col.dataIndex === "ethnicity" ||
                  col.dataIndex === "metastaticstage" ||
                  col.dataIndex === "priorlineoftherapy" ||
                  col.dataIndex === "gender" ||
                  col.dataIndex === "mutationstatus" ||
                  col.dataIndex === "metastaticsites" ||
                  col.dataIndex === "histology" ||
                  col.dataIndex === "smoking"
                )
                  columnsTemp.push(col);
              });
              setDashboardColumns([...columnsTemp]);
              setOriginalDashboardColumns([...columnsTemp]);

              // process data for dashboard using selectedcolumns from study and patient char
              let dashboardDataTemp: any = [];
              for (
                let i = 0;
                i < res.data.studyCharacteristic.data.length;
                i++
              ) {
                let temp: any = {};
                let index = res.data.patientCharacteristic.data.findIndex(
                  (e: any) =>
                    e.studyname ===
                    res.data.studyCharacteristic.data[i].studyname
                );
                if (index > -1) {
                  temp["key"] =
                    res.data.patientCharacteristic.data[index]["key"];
                  temp["age(years)"] =
                    res.data.patientCharacteristic.data[index]["age(years)"];
                  temp["ethnicity"] =
                    res.data.patientCharacteristic.data[index]["ethnicity"];
                  temp["smoking"] =
                    res.data.patientCharacteristic.data[index]["smoking"];
                  temp["gender"] =
                    res.data.patientCharacteristic.data[index]["gender"];
                  temp["priorlineoftherapy"] =
                    res.data.patientCharacteristic.data[index][
                      "priorlineoftherapy"
                    ];
                  temp["metastaticsites"] =
                    res.data.patientCharacteristic.data[index][
                      "metastaticsites"
                    ];
                  temp["mutationstatus"] =
                    res.data.patientCharacteristic.data[index][
                      "mutationstatus"
                    ];
                  temp["histology"] =
                    res.data.patientCharacteristic.data[index]["histology"];
                  temp["metastaticstage"] =
                    res.data.patientCharacteristic.data[index][
                      "metastaticstage"
                    ];
                  temp["studyname"] =
                    res.data.studyCharacteristic.data[i]["studyname"];
                  temp["intervention"] = res.data.studyCharacteristic.data[i][
                    "intervention"
                  ]
                    ? (temp["intervention"] = res.data.studyCharacteristic.data[
                        i
                      ]["intervention"].replaceAll("**", ""))
                    : "";
                  temp["comparator"] =
                    res.data.studyCharacteristic.data[i]["comparator"];
                  temp["studydesign"] =
                    res.data.studyCharacteristic.data[i]["studydesign"];
                  temp["studyduration(months)"] =
                    res.data.studyCharacteristic.data[i][
                      "studyduration(months)"
                    ];
                  temp["countries"] = res.data.studyCharacteristic.data[i][
                    "countries"
                  ]
                    ? res.data.studyCharacteristic.data[i]["countries"]
                    : "";
                  temp["ntotalrandomized"] =
                    res.data.studyCharacteristic.data[i]["ntotalrandomized"];
                  temp["numberoftrials"] =
                    res.data.studyCharacteristic.data[i]["numberoftrials"];
                  temp["numberofpublications"] =
                    res.data.studyCharacteristic.data[i][
                      "numberofpublications"
                    ];
                  dashboardDataTemp.push(temp);
                }
              }
              setDashboardData([...dashboardDataTemp]);
              setOriginalDashboardData([...dashboardDataTemp]);

              let tempStudyDetails: any = {};
              res.data.studyDetails.map((item: any) => {
                tempStudyDetails[item["key"]] = item["reference"];
              });
              setStudyDetails(tempStudyDetails);
              setDataFetched(true);
            }
          } else {
            message.error("Error!");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (saveCounter > 0) {
      //for comments, check above useEffect
      setLoading(true);
      const productReviewId = window.sessionStorage.getItem("productReviewId");
      request
        .get("/GetCharacteristics?productReviewId=" + productReviewId)
        .then((res: any) => {
          console.log("response from API", res);
          if (res.status === 200) {
            if (res.data) {
              let evidenceAtlasDataTemp: any = {};
              for (
                let i = 0;
                i < res.data.studyCharacteristic.data.length;
                i++
              ) {
                //for evidence atlas data
                if (res.data.studyCharacteristic.data[i]["countries"]) {
                  let countries =
                    res.data.studyCharacteristic.data[i]["countries"]
                      ?.replaceAll(", ", "***")
                      .split("***") || [];
                  countries?.map((country: any) => {
                    evidenceAtlasDataTemp[country] =
                      (evidenceAtlasDataTemp[country] || 0) + 1;
                  });
                }
              }
              let evidenceAtlasDataTemp2: any = [];
              let countries = Object.keys(evidenceAtlasDataTemp);
              let maxDomainLength = 10;
              countries?.map((country: any) => {
                let listStudies: any = [];
                for (
                  let i = 0;
                  i < res.data.studyCharacteristic.data.length;
                  i++
                ) {
                  if (
                    res.data.studyCharacteristic.data[i].countries.includes(
                      country
                    )
                  ) {
                    listStudies.push(
                      res.data.studyCharacteristic.data[i].studyname
                    );
                  }
                }
                maxDomainLength =
                  listStudies.length > maxDomainLength
                    ? listStudies.length
                    : maxDomainLength;
                evidenceAtlasDataTemp2.push({
                  country: country,
                  value: evidenceAtlasDataTemp[country],
                  studies: listStudies,
                });
              });
              evidenceAtlasDataTemp2?.map((data: any) => {
                let obj = countryList.countryList.find(
                  (o) => o.country === data.country
                );
                data["id"] = obj?.code;
              });
              setEvidenceAtlasData([...evidenceAtlasDataTemp2]);
              setMaxDomainLength(Math.ceil(maxDomainLength / 5) * 5);
              setLoading(false);

              //for study
              let tempDefaultStudy: any = [];
              let tempAdditionalStudy: any = {
                checked: [],
                unChecked: [],
              };
              let tempColumnsStudy: any = [];
              res.data.studyCharacteristic.columns.map((item: any) => {
                if (item.isFixed) {
                  tempDefaultStudy.push(item.title);
                  tempColumnsStudy.push(item);
                } else {
                  if (item.isSelected) {
                    tempAdditionalStudy.checked.push(item.title);
                    tempColumnsStudy.push(item);
                  } else tempAdditionalStudy.unChecked.push(item.title);
                }
              });

              tempColumnsStudy.sort((a: any, b: any) => a.order - b.order);
              setColumnsStudy([...tempColumnsStudy]);
              setDataStudy([...res.data.studyCharacteristic.data]);

              setAdditionalColumnsStudy(tempAdditionalStudy);
              setDefaultColumnsStudy([...tempDefaultStudy]);
              let tempValues: any = [];
              res.data.studyCharacteristic.studyDesign.map((item: any) => {
                tempValues.push(item.fieldName);
              });
              setStudyDesignItems([...tempValues]);

              //for patient
              let tempDefaultPatient: any = [];
              let tempAdditionalPatient: any = {
                checked: [],
                unChecked: [],
              };
              let tempColumnsPatient: any = [];
              res.data.patientCharacteristic.columns.map((item: any) => {
                if (item.isFixed) {
                  tempDefaultPatient.push(item.title);
                  tempColumnsPatient.push(item);
                } else {
                  if (item.isSelected) {
                    tempAdditionalPatient.checked.push(item.title);
                    tempColumnsPatient.push(item);
                  } else tempAdditionalPatient.unChecked.push(item.title);
                }
              });

              tempColumnsPatient.sort((a: any, b: any) => a.order - b.order);
              setColumnsPatient([...tempColumnsPatient]);
              setDataPatient([...res.data.patientCharacteristic.data]);

              setAdditionalColumnsPatient(tempAdditionalPatient);
              setDefaultColumnsPatient([...tempDefaultPatient]);
              let tempValues1: any = [];
              res.data.patientCharacteristic.ethnicity.map((item: any) => {
                tempValues1.push(item.fieldName);
              });
              setEthnicityItems([...tempValues1]);
              let tempValues2: any = [];
              res.data.patientCharacteristic.mutationStatus.map((item: any) => {
                tempValues2.push(item.fieldName);
              });
              setMutationStatusItems([...tempValues2]);
              let tempValues3: any = [];
              res.data.patientCharacteristic.histology.map((item: any) => {
                tempValues3.push(item.fieldName);
              });
              setHistologyItems([...tempValues3]);
              let tempValues4: any = [];
              res.data.patientCharacteristic.gender.map((item: any) => {
                tempValues4.push(item.fieldName);
              });
              setGenderItems([...tempValues4]);
              let tempValues5: any = [];
              res.data.patientCharacteristic.metastaticSites.map(
                (item: any) => {
                  tempValues5.push(item.fieldName);
                }
              );
              setMetastaticSitesItems([...tempValues5]);
              let tempValues6: any = [];
              res.data.patientCharacteristic.metastaticStage.map(
                (item: any) => {
                  tempValues6.push(item.fieldName);
                }
              );
              setMetastaticStageItems([...tempValues6]);
              let tempValues7: any = [];
              res.data.patientCharacteristic.smoking.map((item: any) => {
                tempValues7.push(item.fieldName);
              });
              setSmokingItems([...tempValues7]);
              //processing for dashboard and evidence atlas
              let columnsTemp: any = [];
              tempColumnsStudy.map((col: any) => {
                if (
                  col.dataIndex === "studyname" ||
                  col.dataIndex === "intervention" ||
                  col.dataIndex === "comparator" ||
                  col.dataIndex === "numberoftrials" ||
                  col.dataIndex === "numberofpublications" ||
                  col.dataIndex === "studydesign" ||
                  col.dataIndex === "countries" ||
                  col.dataIndex === "studyduration(months)" ||
                  col.dataIndex === "ntotalrandomized"
                )
                  columnsTemp.push(col);
              });
              tempColumnsPatient.map((col: any) => {
                if (
                  col.dataIndex === "age(years)" ||
                  col.dataIndex === "ethnicity" ||
                  col.dataIndex === "metastaticstage" ||
                  col.dataIndex === "priorlineoftherapy" ||
                  col.dataIndex === "gender" ||
                  col.dataIndex === "mutationstatus" ||
                  col.dataIndex === "metastaticsites" ||
                  col.dataIndex === "histology" ||
                  col.dataIndex === "smoking"
                )
                  columnsTemp.push(col);
              });
              setDashboardColumns([...columnsTemp]);
              setOriginalDashboardColumns([...columnsTemp]);
              let dashboardDataTemp: any = [];
              // let evidenceAtlasDataTemp: any = {};
              for (
                let i = 0;
                i < res.data.studyCharacteristic.data.length;
                i++
              ) {
                let temp: any = {};
                let index = res.data.patientCharacteristic.data.findIndex(
                  (e: any) =>
                    e.studyname ===
                    res.data.studyCharacteristic.data[i].studyname
                );
                if (index > -1) {
                  temp["key"] =
                    res.data.patientCharacteristic.data[index]["key"];
                  temp["age(years)"] =
                    res.data.patientCharacteristic.data[index]["age(years)"];
                  temp["ethnicity"] =
                    res.data.patientCharacteristic.data[index]["ethnicity"];
                  temp["smoking"] =
                    res.data.patientCharacteristic.data[index]["smoking"];
                  temp["gender"] =
                    res.data.patientCharacteristic.data[index]["gender"];
                  temp["priorlineoftherapy"] =
                    res.data.patientCharacteristic.data[index][
                      "priorlineoftherapy"
                    ];
                  temp["metastaticsites"] =
                    res.data.patientCharacteristic.data[index][
                      "metastaticsites"
                    ];
                  temp["mutationstatus"] =
                    res.data.patientCharacteristic.data[index][
                      "mutationstatus"
                    ];
                  temp["histology"] =
                    res.data.patientCharacteristic.data[index]["histology"];
                  temp["metastaticstage"] =
                    res.data.patientCharacteristic.data[index][
                      "metastaticstage"
                    ];
                  temp["studyname"] =
                    res.data.studyCharacteristic.data[i]["studyname"];
                  temp["intervention"] = res.data.studyCharacteristic.data[i][
                    "intervention"
                  ]
                    ? (temp["intervention"] = res.data.studyCharacteristic.data[
                        i
                      ]["intervention"].replaceAll("**", ""))
                    : "";
                  temp["comparator"] =
                    res.data.studyCharacteristic.data[i]["comparator"];
                  temp["studydesign"] =
                    res.data.studyCharacteristic.data[i]["studydesign"];
                  temp["studyduration(months)"] =
                    res.data.studyCharacteristic.data[i][
                      "studyduration(months)"
                    ];
                  temp["countries"] = res.data.studyCharacteristic.data[i][
                    "countries"
                  ]
                    ? res.data.studyCharacteristic.data[i]["countries"]
                    : "";
                  temp["ntotalrandomized"] =
                    res.data.studyCharacteristic.data[i]["ntotalrandomized"];
                  temp["numberoftrials"] =
                    res.data.studyCharacteristic.data[i]["numberoftrials"];
                  temp["numberofpublications"] =
                    res.data.studyCharacteristic.data[i][
                      "numberofpublications"
                    ];
                  dashboardDataTemp.push(temp);
                }
              }
              setDashboardData([...dashboardDataTemp]);
              setOriginalDashboardData([...dashboardDataTemp]);

              let tempStudyDetails: any = {};
              res.data.studyDetails.map((item: any) => {
                tempStudyDetails[item["key"]] = item["reference"];
              });
              setStudyDetails(tempStudyDetails);
            }
          } else {
            message.error("Error!");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [saveCounter]);

  useEffect(() => {
    if (selectedCountry !== "") {
      setLoading(true);
      let dashboardDataTemp: any = [];
      for (let i = 0; i < dataStudy.length; i++) {
        if (dataStudy[i].countries.includes(selectedCountry)) {
          let temp: any = {};
          let index = dataPatient.findIndex(
            (e: any) => e.studyname === dataStudy[i].studyname
          );

          if (index > -1) {
            temp["key"] = dataPatient[index]["key"];
            temp["age(years)"] = dataPatient[index]["age(years)"];
            temp["ethnicity"] = dataPatient[index]["ethnicity"];
            temp["smoking"] = dataPatient[index]["smoking"];
            temp["gender"] = dataPatient[index]["gender"];
            temp["priorlineoftherapy"] =
              dataPatient[index]["priorlineoftherapy"];
            temp["metastaticsites"] = dataPatient[index]["metastaticsites"];
            temp["mutationstatus"] = dataPatient[index]["mutationstatus"];
            temp["histology"] = dataPatient[index]["histology"];
            temp["metastaticstage"] = dataPatient[index]["metastaticstage"];
            temp["studyname"] = dataStudy[i]["studyname"];
            temp["intervention"] = dataStudy[i]["intervention"]?.replaceAll(
              "**",
              ""
            );
            temp["comparator"] = dataStudy[i]["comparator"];
            temp["studydesign"] = dataStudy[i]["studydesign"];
            temp["studyduration(months)"] =
              dataStudy[i]["studyduration(months)"];
            temp["countries"] = dataStudy[i]["countries"];
            temp["ntotalrandomized"] = dataStudy[i]["ntotalrandomized"];
            temp["numberoftrials"] = dataStudy[i]["numberoftrials"];
            temp["numberofpublications"] = dataStudy[i]["numberofpublications"];
            dashboardDataTemp.push(temp);
          }
        }
      }
      setDashboardData([...dashboardDataTemp]);
      setKeySelected("dashboard");
      setLoading(false);
    }
  }, [selectedCountry]);

  useEffect(() => {}, [columnsStudy]);

  const handleSaveTable = (
    id: number,
    columns: any,
    data: any,
    name: string,
    supportingMessageId: number
  ) => {
    const obj = {
      name: name,
      id: id || 0,
      columns: columns,
      tableData: data,
    };
    // props.editTable && props.editTable(obj, id || 0, supportingMessageId);
  };

  function handleMenuChange(value: any) {
    setKeySelected(value.key);
  }

  const handlePopOverOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  function saveHandlerFootnote() {
    console.log("data after save", footnote);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Characteristics"];
    message.loading("Saving Changes!", 0.8).then(() => {
      request
        .post(
          "/EditFootNote?productReviewId=" +
            productReviewId +
            "&id=" +
            sectionId,
          {
            footNote: footnote,
          }
        )
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            message.success("Saved!");
            setFootnoteInitial(footnote);
          } else {
            message.error("Error!");
            setFootnote(footnoteInitial);
          }
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
    setInEditModeFootnote(false);
  }

  function showDrawerFootnote() {
    setIsLoadingFootnote(true);

    setDrawerOpen(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Characteristics"];
    request
      .get(
        "/GetFootNote?productReviewId=" + productReviewId + "&id=" + sectionId
      )
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (!res.data.footNote) setFootnote("");
          else setFootnote(res.data.footNote);
          setFootnoteInitial(res.data.footNote);
          setIsLoadingFootnote(false);
        } else {
          setIsLoadingFootnote(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoadingFootnote(false);
        console.log(err);
      });
    // setIsLoadingFootnote(false);
  }

  return (
    <div style={{ overflow: "hidden", position: "relative", height: "100%" }}>
      <div className="flex pr-4 bg-white items-center">
        <Popover
          placement="bottomLeft"
          visible={popoverOpen}
          onVisibleChange={handlePopOverOpenChange}
          style={{ top: "4rem" }}
          overlayStyle={{
            minHeight: "2rem",
            maxWidth: "40rem",
          }}
          content={
            <div className="text-xs" style={{ maxWidth: "30rem" }}>
              <p>{screenInfo}</p>
            </div>
          }
          trigger="hover"
        >
          <InfoCircleOutlined
            style={{
              marginLeft: "1.2rem",
              fontSize: "1.1rem",
              // marginTop: "0.2rem",
            }}
          />
        </Popover>
        <Menu
          onClick={handleMenuChange}
          mode="horizontal"
          defaultSelectedKeys={["atlas"]}
          style={{ width: "100%" }}
          selectedKeys={[keySelected]}
        >
          <Menu.Item key="atlas">Evidence Atlas</Menu.Item>
          <Menu.Item key="dashboard">Study/Patient Characteristics</Menu.Item>
          {window.sessionStorage.getItem("isAdmin") === "2" && (
            <Menu.Item key="study">Study Characteristics</Menu.Item>
          )}
          {window.sessionStorage.getItem("isAdmin") === "2" && (
            <Menu.Item key="patient">Patient Characteristics</Menu.Item>
          )}
        </Menu>
        <TextBulletListSquare
          size={30}
          style={{
            marginLeft: "0.6rem",
            color: "#27a6a4",
            cursor: "pointer",
          }}
          onClick={showDrawerFootnote}
          title="Open Footnotes"
        />
      </div>
      <div className="mt-2">
        {loading ? (
          <div
            style={{ height: "80vh" }}
            className="flex items-center justify-center mx-4 bg-white px-2 py-2 shadow-md"
          >
            <BeatLoader color={"#27A6A4"} size={30} />
          </div>
        ) : keySelected === "study" && studyDesignItems ? (
          <div className="mx-4 bg-white px-2 py-2 shadow-md">
            <StudyCharacteristics
              additionalColumns={additionalColumnsStudy}
              columns={columnsStudy}
              data={dataStudy}
              defaultColumns={defaultColumnsStudy}
              handleSave={handleSaveTable}
              studyDesignValues={studyDesignItems}
              tableName={"Study"}
              saveCounter={saveCounter}
              setSaveCounter={setSaveCounter}
              studyDetails={studyDetails}
            />
          </div>
        ) : keySelected === "atlas" ? (
          <div className="mx-4 bg-white shadow-md">
            <EvidenceAtlas
              data={evidenceAtlasData}
              setSelectedCountry={setSelectedCountry}
              maxDomainLength={maxDomainLength}
              tableData={originalDashboardData}
              columns={dashboardColumns}
              studyDetails={studyDetails}
            />
          </div>
        ) : keySelected === "patient" && ethnicityItems ? (
          <div className="mx-4 bg-white px-2 py-2 shadow-md">
            <PatientCharacteristics
              additionalColumns={additionalColumnsPatient}
              columns={columnsPatient}
              data={dataPatient}
              defaultColumns={defaultColumnsPatient}
              handleSave={handleSaveTable}
              ethnicityItems={ethnicityItems}
              mutationStatusItems={mutationStatusItems}
              histologyItems={histologyItems}
              metastaticSitesItems={metastaticSitesItems}
              metastaticStageItems={metastaticStageItems}
              smokingItems={smokingItems}
              genderItems={genderItems}
              tableName={"Patient"}
              saveCounter={saveCounter}
              setSaveCounter={setSaveCounter}
              studyDetails={studyDetails}
            />
          </div>
        ) : keySelected === "dashboard" && dashboardData ? (
          <div className="mx-4">
            <Dashboard
              originalData={originalDashboardData}
              originalColumns={originalDashboardColumns}
              columns={dashboardColumns}
              data={dashboardData}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              studyDetails={studyDetails}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <Drawer
        title={
          <div className="flex">
            <p className="text-black mb-0 w-1/2">Footnotes</p>
            <div className="flex w-1/2 justify-end items-center">
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <div className="flex w-full justify-end items-center">
                  {inEditModeFootnote ? (
                    <div className="flex">
                      <Tooltip title="Save Changes">
                        <SaveOutlined
                          onClick={saveHandlerFootnote}
                          style={{ color: "black", fontSize: "1.2rem" }}
                        />
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <UndoOutlined
                          onClick={() => {
                            setInEditModeFootnote(false);
                            setFootnote(footnoteInitial);
                          }}
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            marginLeft: "0.6rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Edit Footnotes">
                      <EditFilled
                        onClick={() => setInEditModeFootnote(true)}
                        style={{ color: "black", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Close">
                    <CloseOutlined
                      onClick={() => {
                        setDrawerOpen(false);
                        setInEditModeFootnote(false);
                      }}
                      style={{
                        color: "black",
                        fontSize: "1.2rem",
                        marginLeft: "0.6rem",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        }
        closable={false}
        placement="bottom"
        onClose={() => {
          setDrawerOpen(false);
          setInEditModeFootnote(false);
        }}
        getContainer={false}
        visible={drawerOpen}
        style={{ position: "absolute" }}
        zIndex={20}
        bodyStyle={{ padding: "0.5rem" }}
        headerStyle={{
          paddingTop: "0.6rem",
          paddingBottom: "0.6rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "whitesmoke",
          color: "black",
        }}
      >
        {isLoadingFootnote ? (
          <div
            style={{ minHeight: "23vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : footnote === "" && !inEditModeFootnote ? (
          <div
            className="border flex items-center justify-center"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Edit icon to add footnotes!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">No Footnotes Added!</p>
              </div>
            )}
          </div>
        ) : inEditModeFootnote ? (
          <div
            className="border-r"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            <div id="introEditor" className="">
              <SimpleMdeReact
                options={options}
                value={footnote}
                onChange={(e) => {
                  let temp = footnote;
                  temp = String(e);
                  setFootnote(temp);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="border-r"
            style={{
              minHeight: "23vh",
              maxHeight: "23vh",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div id="SLRMDEditor" className="py-4 px-4">
              <ReactMarkdown children={footnote}></ReactMarkdown>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
