import * as SettingsService from "../../services/settings";

export const addSubSection: (
	value: number,
	productId: number
) => Promise<unknown> = async (value, productId) => {
	const data = await SettingsService.addSubSection(value, productId);
	if (!data) return null;
	return data;
};
export const editSubSection: (
	value: any,
	productId: number,
	subsectionId: number
) => Promise<unknown> = async (value, productId, subsectionId) => {
	const data = await SettingsService.editSubSection(
		value,
		productId,
		subsectionId
	);
	if (!data) return null;
	return data;
};
export const deleteSubSection: (
	productId: number,
	subsectionId: number
) => Promise<unknown> = async (productId, subsectionId) => {
	const data = await SettingsService.deleteSubSection(
		productId,
		subsectionId
	);
	if (!data) return null;
	return data;
};
