import React from "react";
const AppContext = React.createContext({
  userId: "",
  productId: 0,
  productName: "",
  viewSelected: 0,
  isAdmin: false,

  subSectionMapping: [
    {
      id: 1,
      name: "Executive Summary",
      // icon: "DocumentBulletList",
      subSection: [
        {
          id: 1,
          name: "default",
          sectionId: 1,
          // icon: "",
          isDefault: true,
        },
      ],
    },
  ],
  setSubSection: (
    data: {
      id: number;
      name: string;
      subSection: {
        id: number;
        name: string;
        sectionId: number;
        isDefault: boolean;
      }[];
    }[]
  ) => {},
  // subSectionMapping2: [
  // 	{
  // 		id: 0,
  // 		name: "",
  // 		subSection: [
  // 			{
  // 				id: 0,
  // 				name: "default",
  // 				sectionId: 0,
  // 			},
  // 		],
  // 	},
  // ],
  userRole: [
    {
      productId: 0,
      roleId: 0,
    },
  ],
  setRole: (
    data: {
      productId: number;
      roleId: number;
    }[]
  ) => {},
  setUser: (userId: string) => {},
  setProduct: (key: number, name: string) => {},
  setView: (key: number) => {},
  setAdmin: (isAdmin: boolean) => {},
  firstLoad: true,
  setFirstLoading: (data: boolean) => {},
  searchWord: "",
  setNewSearchWord: (searchWord: string) => {},
  newSearch: true,
  setNewSearchLink: (searchLink: string) => {},
  searchLink: "",
  setisNewSearch: (isNewSearch: boolean) => {},
  prevSearchResult: [
    {
      sectionName: "",
      lstsearch: [
        {
          sectionName: "",
          sectionId: 0,
          subsectionName: "",
          subsectionId: 0,
          subsectionCompHeader: "",
          subsectionCompSubHeader: "",
          cardCompID: 0,
          cardCompHeader: "",
          cardBodyCompID: 0,
          cardBodyCompHeader: "",
          cardBodyCompBodyContent: "",
          supportingkeyMessageHeader: "",
          supportingMsgContentBulletPoint: "",
          tableName: "",
          tableHeader: "",
          tableData: "",
          url: "",
          link: "",
        },
      ],
    },
  ],
  setPrevSearch: (
    data: {
      sectionName: string;
      lstsearch: {
        sectionName: string;
        sectionId: number;
        subsectionName: string;
        subsectionId: number;
        subsectionCompHeader: string;
        subsectionCompSubHeader: string;
        cardCompID: number;
        cardCompHeader: string;
        cardBodyCompID: number;
        cardBodyCompHeader: string;
        cardBodyCompBodyContent: string;
        supportingkeyMessageHeader: string;
        supportingMsgContentBulletPoint: string;
        tableName: string;
        tableHeader: string;
        tableData: string;
        url: string;
        link: string;
      }[];
    }[]
  ) => {},
  showSearchReturn: false,
  setShowSearch: (isSearch: boolean) => {},
});

export function ContextProvider(props: { children: React.ReactNode }) {
  const [userId, setUserId] = React.useState(
    localStorage.getItem("userName") || ""
  );
  const [productId, setProductId] = React.useState(
    Number(localStorage.getItem("productId"))
  );
  const [productName, setProductName] = React.useState(
    localStorage.getItem("productName") || ""
  );
  const [viewSelected, setViewSelected] = React.useState(1);
  const [isAdmin, setIsAdmin] = React.useState(
    localStorage.getItem("admin") === "true" ? true : false
  );

  const [subSectionMapping, setSubSectionMapping] = React.useState<
    {
      id: number;
      name: string;
      subSection: {
        id: number;
        name: string;
        sectionId: number;
        isDefault: boolean;
      }[];
    }[]
  >(
    JSON.parse(
      localStorage.getItem("subSectionMapping") ||
        `${JSON.stringify([
          {
            id: 1,
            name: "Executive Summary",
            // icon: "DocumentBulletList",
            subSection: [
              {
                id: 1,
                name: "default",
                sectionId: 1,
                // icon: "",
                isDefault: true,
              },
            ],
          },
        ])}`
    )
  );
  const [showSearchReturn, setShowSearchReturn] = React.useState(false);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [searchWord, setSearchWord] = React.useState("");
  const [searchLink, setSearchLink] = React.useState("&  &");
  const [newSearch, setNewSearch] = React.useState(true);
  // const [subSectionMapping, setSubSectionMapping] = React.useState<
  // 	{
  // 		id: number;
  // 		name: string;
  // 		subSection: {
  // 			id: number;
  // 			name: string;
  // 			sectionId: number;
  // 		}[];
  // 	}[]
  // >([
  // 	{
  // 		id: 1,
  // 		name: "Executive Summary",
  // 		// icon: "DocumentBulletList",
  // 		subSection: [
  // 			{
  // 				id: 1,
  // 				name: "default",
  // 				sectionId: 1,
  // 				// icon: "",
  // 			},
  // 		],
  // 	},
  // ]);

  const [prevSearchResult, setprevSearchResult] = React.useState<
    {
      sectionName: string;
      lstsearch: {
        sectionName: string;
        sectionId: number;
        subsectionName: string;
        subsectionId: number;
        subsectionCompHeader: string;
        subsectionCompSubHeader: string;
        cardCompID: number;
        cardCompHeader: string;
        cardBodyCompID: number;
        cardBodyCompHeader: string;
        cardBodyCompBodyContent: string;
        supportingkeyMessageHeader: string;
        supportingMsgContentBulletPoint: string;
        tableName: string;
        tableHeader: string;
        tableData: string;
        url: string;
        link: string;
      }[];
    }[]
  >([]);

  function setShowSearch(isSearch: boolean) {
    setShowSearchReturn(isSearch);
  }

  function setNewSearchWord(searchWord: string) {
    setSearchWord(searchWord);
  }
  function setNewSearchLink(searchLink: string) {
    setSearchLink(searchLink);
  }

  function setPrevSearch(
    data: {
      sectionName: string;
      lstsearch: {
        sectionName: string;
        sectionId: number;
        subsectionName: string;
        subsectionId: number;
        subsectionCompHeader: string;
        subsectionCompSubHeader: string;
        cardCompID: number;
        cardCompHeader: string;
        cardBodyCompID: number;
        cardBodyCompHeader: string;
        cardBodyCompBodyContent: string;
        supportingkeyMessageHeader: string;
        supportingMsgContentBulletPoint: string;
        tableName: string;
        tableHeader: string;
        tableData: string;
        url: string;
        link: string;
      }[];
    }[]
  ) {
    setprevSearchResult(data);
  }

  const [userRole, setUserRole] = React.useState<
    {
      productId: number;
      roleId: number;
    }[]
  >([]);
  function setisNewSearch(isNewSearch: boolean) {
    setNewSearch(isNewSearch);
  }

  function setFirstLoading(load: boolean) {
    setFirstLoad(load);
  }
  function setProduct(key: number, name: string) {
    console.log("Setting Product Id ", key);
    setProductId(key);
    setProductName(name);
  }
  function setView(key: number) {
    setViewSelected(key);
  }
  function setUser(userId: string) {
    setUserId(userId);
    window.sessionStorage.setItem("userId", userId);
  }
  function setAdmin(isAdmin: boolean) {
    setIsAdmin(isAdmin);
    window.sessionStorage.setItem("isAdmin", isAdmin ? "true" : "false");
  }
  function setSubSection(
    data: {
      id: number;
      name: string;
      subSection: {
        id: number;
        name: string;
        sectionId: number;
        isDefault: boolean;
      }[];
    }[]
  ) {
    setSubSectionMapping(data);
  }
  function setRole(
    data: {
      productId: number;
      roleId: number;
    }[]
  ) {
    setUserRole(data);
  }
  const contexts = {
    userId: userId,
    productId: productId,
    productName: productName,
    viewSelected: viewSelected,
    isAdmin: isAdmin,
    // subSectionMapping2: subSectionMapping2.current,
    userRole: userRole,
    setRole: setRole,
    subSectionMapping: subSectionMapping,
    setSubSection: setSubSection,
    setUser: setUser,
    setProduct: setProduct,
    setView: setView,
    setAdmin: setAdmin,
    firstLoad: firstLoad,
    setFirstLoading: setFirstLoading,
    searchWord: searchWord,
    setNewSearchWord: setNewSearchWord,
    searchLink: searchLink,
    setNewSearchLink: setNewSearchLink,
    newSearch: newSearch,
    setisNewSearch: setisNewSearch,
    prevSearchResult: prevSearchResult,
    setPrevSearch: setPrevSearch,
    showSearchReturn: showSearchReturn,
    setShowSearch: setShowSearch,
  };
  return (
    <AppContext.Provider value={contexts}>{props.children}</AppContext.Provider>
  );
}

export default AppContext;
