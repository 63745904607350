import { Body, BodyContent, LayoutContainer } from "./style";
import SideBar from "./components/sideBar";
import TopHeader from "./components/topHeader";
import MiddleHeader from "./components/middleHeader";
import { Route, Switch } from "react-router-dom";
import Landing from "../pages/Landing";
import axios from "axios";
import React from "react";
import AppContext from "../store";
import { Routes, TabNames } from "../routes";

/**
 * Renders the Main Layout of the App and displays different screens based on the Routes
 *
 * @returns Main Layout of the App along with different routes
 */
export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
export default function Layout() {
  request.interceptors.request.use((config) => reqConfig(config));
  request.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );
  const reqConfig = (config: any) => {
    config.headers["userName"] = localStorage.getItem("userName");
    config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
    return config;
  };
  const responseHandler = (response: any) => {
    return response;
  };
  const errorHandler = (error: any) => {
    if (error.response.status === 401)
      window.location.href = process.env.REACT_APP_REDIRECT_URL || "";
    return Promise.reject(error);
  };

  const routes = Routes();

  const tabNames = TabNames();
  return (
    <Switch>
      {console.log("API Key :", process.env.REACT_APP_API_KEY)}
      <Route exact path="/GVD">
        <Landing />
      </Route>
      <Route path="/:tab">
        <LayoutContainer>
          {console.log("Layout Rendered")}
          <Body>
            <SideBar tabNames={tabNames} />
            <TopHeader />
            <MiddleHeader tabNames={tabNames} />
            <BodyContent>
              <Switch>
                {routes.map((route, index) => (
                  <Route key={index} {...route} />
                ))}
              </Switch>
            </BodyContent>
          </Body>
        </LayoutContainer>
      </Route>
    </Switch>
  );
}
