// import { SettingsOutline } from "@styled-icons/evaicons-outline/SettingsOutline";
import { DesignServices } from "@styled-icons/material-sharp/DesignServices";
export default function DesignTable(props: {
  handleClick: () => void;
  text: string;
}) {
  return (
    <div className="flex" onClick={() => props.handleClick()}>
      <DesignServices
        title="Data Design"
        size={24}
        style={{ color: "#32A29B" }}
      />
      <p style={{ color: "#32A29B" }} className="mb-0 ml-1 font-semibold">
        {props.text}
      </p>
    </div>
  );
}
