import * as SupportigMessageService from "../../services/supportingMessage";

export const getSupportingMessage: (
  keyMessageId: number
) => Promise<unknown> = async (keyMessageId) => {
  const data = await SupportigMessageService.getSupportingMessageData(
    keyMessageId
  );
  // if (!data) return null;
  return data;
};
export const getSupportingMessageEvidenceAtlasData: (
  keyMessageId: number
) => Promise<unknown> = async (keyMessageId) => {
  const data =
    await SupportigMessageService.getSupportingMessageEvidenceAtlasData(
      keyMessageId
    );
  // if (!data) return null;
  return data;
};

export const deleteSupportingMessage: (
  deleteId: number,
  keyMessageId: number,
  productId: number
) => Promise<unknown> = async (deleteId, keyMessageId, productId) => {
  const data = await SupportigMessageService.deleteSupportingMessageData(
    deleteId,
    productId
  );
  const data2 = await SupportigMessageService.getSupportingMessageData(
    keyMessageId
  );
  if (data === null || data === undefined) return null;
  return data2;
};

export const addSupportingMessage: (
  supportingMessageData: any,
  keyMessageId: number,
  productId: number
) => Promise<unknown> = async (
  supportingMessageData,
  keyMessageId,
  productId
) => {
  const data = await SupportigMessageService.addSupportingMessageData(
    supportingMessageData,
    productId
  );
  const data2 = await SupportigMessageService.getSupportingMessageData(
    keyMessageId
  );
  if (data === null || data === undefined) return null;
  return data2;
};
export const editSupportingMessage: (
  supportingMessageData: any,
  supportingMessageId: number,
  keyMessageId: number,
  productId: number
) => Promise<unknown> = async (
  supportingMessageData,
  supportingMessageId,
  keyMessageId,
  productId
) => {
  const data = await SupportigMessageService.editSupportingMessageData(
    supportingMessageData,
    supportingMessageId,
    productId
  );
  const data2 = await SupportigMessageService.getSupportingMessageData(
    keyMessageId
  );
  if (data === null || data === undefined) return null;
  return data2;
};

export const editSupportingMessageEvidenceAtlas: (
  supportingMessageData: any,
  keyMessageId: number
) => Promise<unknown> = async (supportingMessageData, keyMessageId) => {
  console.log("keymessage service helper", keyMessageId);
  const data = await SupportigMessageService.editSupportingMessageEvidenceAtlas(
    supportingMessageData,
    keyMessageId
  );
  //   const data2 =
  //     await SupportigMessageService.getSupportingMessageEvidenceAtlasData(
  //       keyMessageId
  //     );
  if (data === null || data === undefined) return null;
  return data;
};

export const editTable: (
  tableData: any,
  supportingMessageId: number,
  tableId: number,
  keyMessageId: number,
  productId: number
) => Promise<unknown> = async (
  tableData,
  supportingMessageId,
  tableId,
  keyMessageId,
  productId
) => {
  const data = await SupportigMessageService.editTableData(
    tableData,
    supportingMessageId,
    tableId,
    productId
  );
  const data2 = await SupportigMessageService.getSupportingMessageData(
    keyMessageId
  );
  if (data === null || data === undefined) return null;
  return data2;
};

export const editChart: (
  chartData: any,
  chartId: number,
  supportingMessageId: number,
  keyMessageId: number,
  productId: number
) => Promise<unknown> = async (
  chartData,
  chartId,
  supportingMessageId,
  keyMessageId,
  productId
) => {
  const data = await SupportigMessageService.editChartData(
    chartData,
    chartId,
    supportingMessageId,
    productId
  );
  const data2 = await SupportigMessageService.getSupportingMessageData(
    keyMessageId
  );
  console.log(data);
  if (data === null || data === undefined) return null;
  return data2;
};

export const editOrder: (
  orderData: any,
  productId: number,
  keyMessageId: number
) => Promise<unknown> = async (orderData, productId, keyMessageId) => {
  const data = await SupportigMessageService.editOrderData(
    orderData,
    productId
  );
  const data2 = await SupportigMessageService.getSupportingMessageData(
    keyMessageId
  );
  if (data === null || data === undefined) return null;
  return data2;
};
