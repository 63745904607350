import BeatLoader from "react-spinners/BeatLoader";
import {
  Avatar,
  Button,
  Col,
  Drawer,
  Form,
  InputNumber,
  message,
  Modal,
  Popover,
  RadioChangeEvent,
  Row,
  Tooltip,
} from "antd";
import { TextBulletListSquare } from "@styled-icons/fluentui-system-regular/TextBulletListSquare";
import { Radio, Input, Table } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import {
  EditFilled,
  SaveOutlined,
  UndoOutlined,
  DeleteOutlined,
  CloseOutlined,
  DatabaseOutlined,
  InfoCircleOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { ResponsivePie } from "@nivo/pie";
import { Database } from "@styled-icons/fa-solid/Database";
import "./style.css";
import { request } from "../../layout/SLR_Layout";
import ReactMarkdown from "react-markdown";
import SimpleMdeReact from "react-simplemde-editor";
import { useHistory } from "react-router-dom";
const { Search } = Input;

interface DataType {
  key: string;
  sNo: number;
  searches: string;
  limits: number;
}

const colors: any = [
  "#160C60",
  "#3B9FE7",
  "#27A6A4",
  "#fdae6b",
  "#01a6dc",
  "#bbe9d0",
  "#a0afc6",
  "#f47660",
  "#86c8bc",
  "#B8CC7B",
  "#ED8B00",
  "#a2ddff",
  "#e39ed6",
];

const columns: ColumnsType<DataType> = [
  {
    title: "Serial No.",
    dataIndex: "sNo",
    key: "sNo",
    width: 120,
    align: "center",
  },
  {
    title: "Query",
    dataIndex: "searches",
    key: "searches",
    // width: 120,
    align: "center",
  },
  {
    title: "Results",
    dataIndex: "limits",
    key: "limits",
    width: 120,
    align: "center",
    sorter: (a, b) => b.limits - a.limits,
  },
];

export default function SearchDB() {
  const [value, setValue] = useState("ovid");
  const [inEditMode, setInEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [initialData, setInitialData] = useState<any>([]);
  const [selectedDB, setSelectedDB] = useState("all");
  const [tableData, setTableData] = useState<any>([]);
  const [copyTableData, setCopyTableData] = useState<any>([]);
  const [tableDataAddDB, setTableDataAddDB] = useState<any>([]);
  const [pieChartData, setPieChartData] = useState<any>();
  const [pieChartColors, setPieChartColors] = useState<any>([...colors]);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [isModalVisibleAddDB, setIsModalVisibleAddDB] = useState(false);
  const [isModalVisibleDeleteDB, setIsModalVisibleDeleteDB] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [screenInfo, setScreenInfo] = useState<any>(
    "This section includes tabs for viewing data sources that were searched within the scope of this review, including databases, trial registries, conferences etc. "
  );
  const [footnote, setFootnote] = useState<any>("");
  const [footnoteInitial, setFootnoteInitial] = useState<any>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inEditModeFootnote, setInEditModeFootnote] = useState(false);
  const [isLoadingFootnote, setIsLoadingFootnote] = useState(true);

  const [form] = Form.useForm();
  let maxSNo = useRef(0);
  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "100%",
      maxHeight: "100%",
    };
  }, []);
  const navigate = useHistory();

  useEffect(() => {
    if (
      window.sessionStorage.getItem("selectedBackgroundName") === undefined ||
      window.sessionStorage.getItem("selectedBackgroundName") === null ||
      window.sessionStorage.getItem("selectedBackgroundName") === "" ||
      window.sessionStorage.getItem("productReviewId") === undefined ||
      window.sessionStorage.getItem("productReviewId") === null ||
      window.sessionStorage.getItem("productReviewId") === "" ||
      window.localStorage.getItem("productId") === undefined ||
      window.localStorage.getItem("productId") === null ||
      window.localStorage.getItem("productId") === ""
    ) {
      navigate.push("/SLR");
      return;
    }
    setIsLoading(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    request
      .get("/GetDbSearch?productReviewId=" + productReviewId)
      .then((res: any) => {
        console.log("response from API", res, data);
        if (res.status === 200) {
          let tempData: any = [];
          let tempPieChartData: any = [];
          let temp = JSON.parse(JSON.stringify(res.data));

          //create data for pie chart
          if (temp.length !== 0 && temp !== undefined) {
            temp.map((d: any) => {
              d.data.map((x: any) => tempData.push(x));
              tempPieChartData.push({
                id: d.name,
                label: d.name,
                value: d.count,
              });
            });
            if (tempData.length !== 0) {
              tempData.map((d: any, index: any) => {
                d["sNo"] = index + 1;
                d["key"] = String(index + 1);
              });
              maxSNo.current = tempData[tempData.length - 1].sNo;
            }
          }
          setPieChartData([...tempPieChartData]);
          setTableData([...tempData]);
          setData([...temp]);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const handlePopOverOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  function selectDBHandler(value: any) {
    console.log("updating db", value);
    setSelectedDB(value);
    if (value === "all") {
      // create table data from all DBs data
      let tempData: any = [];
      let temp = JSON.parse(JSON.stringify(data));
      temp.map((d: any) => {
        d.data.map((x: any) => tempData.push(x));
      });
      tempData.map((d: any, index: any) => {
        d.sNo = index + 1;
        d.key = String(index + 1);
      });
      setTableData([...tempData]);
      setCopyTableData([...tempData]);
      setPieChartColors([...colors]);
      return;
    }
    let temp = JSON.parse(JSON.stringify(data));
    //create table data from selected DB data
    temp.map((d: any) => {
      if (d.name === value) {
        let tempTableData = JSON.parse(JSON.stringify(d.data));
        tempTableData.map((item: any, index: any) => {
          item["sNo"] = String(index + 1);
        });
        setTableData([...tempTableData]);
        setCopyTableData([...tempTableData]);
      }
    });
    let tempColors = [...colors];
    pieChartData.map((d: any, index: any) => {
      if (d.label !== value) {
        tempColors[index] = "#A9A9A9";
      }
    });
    setPieChartColors([...tempColors]);
  }

  useEffect(() => {
    console.log("updating db", value);
    // setSelectedDB(value);
    if (selectedDB === "all") {
      let tempData: any = [];
      let temp = JSON.parse(JSON.stringify(data));
      temp.map((d: any) => {
        d.data.map((x: any) => tempData.push(x));
      });
      tempData.map((d: any, index: any) => {
        d.sNo = index + 1;
        d.key = String(index + 1);
      });
      setTableData([...tempData]);
      setCopyTableData([...tempData]);
      setPieChartColors([...colors]);
      return;
    }
    let temp = JSON.parse(JSON.stringify(data));
    temp.map((d: any) => {
      if (d.name === selectedDB) {
        let tempTableData = JSON.parse(JSON.stringify(d.data));
        tempTableData.map((item: any, index: any) => {
          item["sNo"] = String(index + 1);
        });
        setTableData([...tempTableData]);
        setCopyTableData([...tempTableData]);
      }
    });
    let tempColors = [...colors];
    pieChartData.map((d: any, index: any) => {
      if (d.label !== selectedDB) {
        tempColors[index] = "#A9A9A9";
      }
    });
    setPieChartColors([...tempColors]);
  }, [selectedDB]);

  function editDB() {
    let tempFormValues: any = {};
    let tempData: any = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i]["name"] === selectedDB) {
        tempFormValues["DBName"] = data[i]["name"];
        tempFormValues["count"] = data[i]["count"];
        tempData = data[i]["data"];
        break;
      }
    }
    if (tempData) {
      tempData.map((row: any, index: any) => {
        let key1 = String(index + 1) + "searches";
        let key2 = String(index + 1) + "limits";
        tempFormValues[key1] = row.searches;
        tempFormValues[key2] = row.limits;
      });
    }
    console.log("formValues", tempFormValues);
    form.setFieldsValue(tempFormValues);
    setIsModalVisibleEdit(true);
  }
  function addDB() {
    setIsModalVisibleAddDB(true);
    setTableDataAddDB([]);
  }

  function handleOkEdit() {
    let formValues = form.getFieldsValue();
    let tempData = JSON.parse(JSON.stringify(data));
    let toSend: any = {};
    //extract form values and create a DB
    for (let i = 0; i < tempData.length; i++) {
      if (tempData[i]["name"] === selectedDB) {
        tempData[i]["name"] = formValues["DBName"];
        tempData[i]["count"] = formValues["count"];
        let tempTableData: any = [];
        let keys = Object.keys(formValues);
        keys.map((key: any, index: any) => {
          let tempSplits = key.split("limits");
          if (tempSplits.length === 2) {
            let n = tempSplits[0];
            let limitsVal = formValues[key];
            let searchesVal = formValues[n + "searches"];
            tempTableData.push({
              key: String(n),
              sNo: String(n),
              limits: limitsVal,
              searches: searchesVal,
            });
          }
        });
        tempData[i]["data"] = tempTableData;
        // setTableData([...tempTableData]);
        toSend = tempData[i];
        break;
      }
    }

    console.log("edited data to DB", toSend);

    const productReviewId = window.sessionStorage.getItem("productReviewId");
    message.loading("Saving Changes!", 2);

    request
      .post(
        "/EditDbSearch?productReviewId=" +
          productReviewId +
          "&id=" +
          toSend["id"],
        toSend
      )
      .then((res: any) => {
        console.log("response from API", res, data);
        if (res.status === 200) {
          let tempPieChartData: any = [];
          tempData.map((d: any) => {
            tempPieChartData.push({
              id: d.name,
              label: d.name,
              value: d.count,
            });
          });
          setPieChartData([...tempPieChartData]);
          setTableData([...toSend["data"]]);
          setData([...tempData]);
          setIsLoading(false);
          message.success("Saved!");
        } else {
          setIsLoading(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        message.error("Error!");
      });

    form.resetFields();
    form.setFieldsValue({
      DBName: "",
      count: 0,
    });
    setIsModalVisibleEdit(false);
  }

  function handleOkAdd() {
    let formValues = form.getFieldsValue();

    let tempData = JSON.parse(JSON.stringify(data));
    for (let i = 0; i < tempData.length; i++) {
      if (tempData[i]["name"] === formValues["DBName"]) {
        message.warning("Please provide unique name!");
        return;
      }
    }

    let temp: any = {
      name: formValues["DBName"],
      count: formValues["count"],
      categoryId: 1,
      id: 0,
    };

    let tempTableData: any = [];
    let keys = Object.keys(formValues);
    keys.map((key: any, index: any) => {
      let tempSplits = key.split("limits");
      if (tempSplits.length === 2) {
        let n = tempSplits[0];
        let limitsVal = formValues[key];
        let searchesVal = formValues[n + "searches"];
        tempTableData.push({
          key: String(n),
          sNo: String(n),
          limits: limitsVal,
          searches: searchesVal,
        });
      }
    });
    temp["data"] = tempTableData;

    console.log("toDb", temp);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    message.loading("Saving Changes!", 2);

    request
      .post("/EditDbSearch?productReviewId=" + productReviewId + "&id=0", temp)
      .then((res: any) => {
        console.log("response from API", res, data);
        if (res.status === 200) {
          temp.id = res.data;
          tempData.push(temp);

          let tempPieChartData: any = [];
          tempData.map((d: any) => {
            tempPieChartData.push({
              id: d.name,
              label: d.name,
              value: d.count,
            });
          });
          setPieChartData([...tempPieChartData]);

          if (selectedDB === "all") {
            setPieChartColors([...colors]);
          } else {
            let tempColors = [...colors];
            tempPieChartData.map((d: any, index: any) => {
              if (d.label !== selectedDB) {
                tempColors[index] = "#A9A9A9";
              }
            });
            setPieChartColors([...tempColors]);
          }

          setData([...tempData]);
          setIsLoading(false);
          message.success("Saved!");
        } else {
          setIsLoading(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        message.error("Error!");
      })
      .finally(() => setSelectedDB(formValues["DBName"]));
    setIsModalVisibleAddDB(false);
    form.resetFields();
    form.setFieldsValue({
      DBName: "",
      count: 0,
    });
  }

  function deleteDB() {
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    let deleteDBId = "";
    let temp = JSON.parse(JSON.stringify(data));
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["name"] === selectedDB) {
        deleteDBId = temp[i]["id"];
        break;
      }
    }
    setIsModalVisibleDeleteDB(false);
    message.loading("Deleting " + selectedDB, 3).then(() => {
      request
        .delete("/DeleteDbSearch?id=" + deleteDBId)
        .then((res: any) => {
          console.log("response from API", res, data);
          if (res.status === 200) {
            let tempData = JSON.parse(JSON.stringify(data));
            for (let i = 0; i < tempData.length; i++) {
              if (tempData[i]["name"] === selectedDB) {
                tempData.splice(i, 1);
                break;
              }
            }

            let tempPieChartData: any = [];
            tempData.map((d: any) => {
              tempPieChartData.push({
                id: d.name,
                label: d.name,
                value: d.count,
              });
            });

            let temp: any = [];
            tempData.map((d: any) => {
              d.data.map((x: any) => temp.push(x));
            });
            temp.map((d: any, index: any) => {
              d.sNo = String(index + 1);
              d.key = String(index + 1);
            });
            setTableData([...temp]);
            setCopyTableData([...temp]);

            setPieChartData([...tempPieChartData]);
            setPieChartColors([...colors]);
            setSelectedDB("all");
            setData([...tempData]);
            setIsModalVisibleEdit(false);
            form.resetFields();
            form.setFieldsValue({
              DBName: "",
              count: 0,
            });
            setIsLoading(false);
            message.success("Deleted Successfully!");
          } else {
            setIsLoading(false);
            message.error("Error!");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          message.error("Error!");
        });
    });
  }

  function handleCancel() {
    setIsModalVisibleAddDB(false);
    setIsModalVisibleEdit(false);
    setIsModalVisibleDeleteDB(false);
    form.resetFields();
    form.setFieldsValue({
      DBName: "",
      count: 0,
    });
  }

  function deleteRow(sNo: any) {
    let temp = [...copyTableData];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["sNo"] === sNo) {
        temp.splice(i, 1);
      }
    }
    setCopyTableData([...temp]);
  }

  function deleteRowAddDB(sNo: any) {
    let temp = [...tableDataAddDB];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["sNo"] === sNo) {
        temp.splice(i, 1);
      }
    }
    setTableDataAddDB([...temp]);
  }

  function addRow() {
    let sNo =
      Number(
        copyTableData[copyTableData.length - 1]
          ? copyTableData[copyTableData.length - 1].sNo
          : 0
      ) + 1;
    let tempData = [...copyTableData];
    tempData.push({
      key: String(sNo),
      sNo: String(sNo),
      searches: "",
      limits: 0,
    });
    setCopyTableData([...tempData]);
  }

  function addRowAddDB() {
    let sNo = Number(tableDataAddDB[tableDataAddDB.length - 1]?.sNo) + 1 || 1;
    let tempData = [...tableDataAddDB];
    tempData.push({
      key: String(sNo),
      sNo: String(sNo),
      searches: "",
      limits: 0,
    });
    setTableDataAddDB([...tempData]);
  }

  function saveHandlerFootnote() {
    console.log("data after save", footnote);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Search Database"];
    message.loading("Saving Changes!", 0.8).then(() => {
      request
        .post(
          "/EditFootNote?productReviewId=" +
            productReviewId +
            "&id=" +
            sectionId,
          {
            footNote: footnote,
          }
        )
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            message.success("Saved!");
            setFootnoteInitial(footnote);
          } else {
            message.error("Error!");
            setFootnote(footnoteInitial);
          }
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
    setInEditModeFootnote(false);
  }

  function showDrawerFootnote() {
    setIsLoadingFootnote(true);

    setDrawerOpen(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Search Database"];
    request
      .get(
        "/GetFootNote?productReviewId=" + productReviewId + "&id=" + sectionId
      )
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (!res.data.footNote) setFootnote("");
          else setFootnote(res.data.footNote);
          setFootnoteInitial(res.data.footNote);
          setIsLoadingFootnote(false);
        } else {
          setIsLoadingFootnote(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoadingFootnote(false);
        console.log(err);
      });
    // setIsLoadingFootnote(false);
  }

  return (
    <div style={{ overflow: "hidden", position: "relative", height: "100%" }}>
      <div className="flex px-4 py-2 items-center bg-white">
        <p className="w-1/2 text-lg font-semibold pl-1">
          <Popover
            placement="bottomLeft"
            visible={popoverOpen}
            onVisibleChange={handlePopOverOpenChange}
            style={{ top: "4rem" }}
            overlayStyle={{
              minHeight: "2rem",
              maxWidth: "40rem",
            }}
            content={
              <div className="text-xs" style={{ maxWidth: "30rem" }}>
                <p>{screenInfo}</p>
              </div>
            }
            trigger={"hover"}
          >
            <InfoCircleOutlined
              style={{
                marginRight: "0.6rem",
                fontSize: "1.1rem",
                marginTop: "0.2rem",
              }}
            />
          </Popover>
          Database Search
        </p>
        <div className="w-1/2 flex items-center justify-end">
          <TextBulletListSquare
            size={30}
            style={{
              marginLeft: "0.6rem",
              color: "#27a6a4",
              cursor: "pointer",
            }}
            onClick={showDrawerFootnote}
            title="Open Footnotes"
          />
        </div>
      </div>
      {isLoading ? (
        <div
          style={{ minHeight: "77vh", overflow: "hidden", width: "98%" }}
          className="flex items-center bg-white justify-center h-full m-4"
        >
          <BeatLoader color={"#27A6A4"} loading={true} size={24} />
        </div>
      ) : data.length === 0 ? (
        <div className="m-4 bg-white p-4 shadow-md">
          <div className="flex items-start">
            <div
              id="DBButtons"
              className="flex w-9/12 items-start justify-start"
            >
              <Radio.Group
                value={selectedDB}
                onChange={(e) => setSelectedDB(e.target.value)}
              >
                {window.sessionStorage.getItem("isAdmin") === "2" && (
                  <Button onClick={addDB} className="px-2" type="default">
                    + Add New
                  </Button>
                )}
              </Radio.Group>
            </div>
            {window.sessionStorage.getItem("isAdmin") === "2" &&
              selectedDB !== "all" && (
                <div className="w-3/12 flex items-start justify-end ml-4">
                  <Button onClick={editDB} className="px-2" type="default">
                    Edit {selectedDB}
                  </Button>
                  <Button
                    danger
                    onClick={() => setIsModalVisibleDeleteDB(true)}
                    className="px-2 ml-2"
                    // type="default"
                  >
                    <DeleteOutlined style={{ color: "red" }} />
                    Delete {selectedDB}
                  </Button>
                </div>
              )}
          </div>
          <div
            className=" bg-white  flex items-center justify-center"
            style={{ minHeight: "70vh", overflowY: "auto" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "#27a6a4", fontSize: "3rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Add New to add details!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "#27a6a4", fontSize: "3rem" }} />
                <p className="text-xl mt-2 italic">No Details Added!</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="m-4 bg-white p-4 shadow-md">
          <div className="flex items-start">
            <div
              id="DBButtons"
              className="flex w-9/12 items-start justify-start"
            >
              <Radio.Group
                value={selectedDB}
                onChange={(e) => selectDBHandler(e.target.value)}
              >
                <Radio.Button className="mr-2 px-2 mb-2" value="all">
                  <DatabaseOutlined
                    style={{
                      color: selectedDB === "all" ? "whitesmoke" : "darkgrey",
                    }}
                  />
                  &nbsp; All
                </Radio.Button>

                {data.map((d: any) => {
                  return (
                    <Radio.Button className="mr-2 px-2" value={d.name}>
                      <Database
                        size={14}
                        color={
                          selectedDB === d.name ? "whitesmoke" : "lightgrey"
                        }
                        style={{ marginBottom: "0.3rem" }}
                      />
                      &nbsp; {d.name}
                    </Radio.Button>
                  );
                })}
                {window.sessionStorage.getItem("isAdmin") === "2" && (
                  <Button onClick={addDB} className="px-2" type="default">
                    + Add New
                  </Button>
                )}
              </Radio.Group>
            </div>
            {window.sessionStorage.getItem("isAdmin") === "2" &&
              selectedDB !== "all" && (
                <div className="w-3/12 flex items-start justify-end ml-4">
                  <Button onClick={editDB} className="px-2" type="default">
                    Edit {selectedDB}
                  </Button>
                  <Button
                    danger
                    onClick={() => setIsModalVisibleDeleteDB(true)}
                    className="px-2 ml-2"
                    // type="default"
                  >
                    <DeleteOutlined style={{ color: "red" }} />
                    Delete {selectedDB}
                  </Button>
                </div>
              )}
          </div>
          <div className="mt-2 flex">
            <div style={{ width: "42%" }} className=" border-r">
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <p className="text-xs italic ">
                  * Click on a Data Source to edit it!
                </p>
              )}
              {pieChartData && (
                <div className="mt-4" style={{ height: "65vh", width: "80vh" }}>
                  <ResponsivePie
                    data={pieChartData}
                    colors={pieChartColors}
                    margin={
                      window.innerWidth < 1220
                        ? { top: 100, right: 350, bottom: 100, left: 20 }
                        : { top: 70, right: 150, bottom: 80, left: 0 }
                    }
                    innerRadius={0.05}
                    padAngle={8}
                    cornerRadius={7}
                    activeInnerRadiusOffset={12}
                    activeOuterRadiusOffset={22}
                    borderWidth={1}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    enableArcLinkLabels={false}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor="#ffffff"
                    legends={[
                      {
                        anchor: "right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 10,
                        itemsSpacing: 4,
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: "#999",
                        itemDirection: "left-to-right",
                        itemOpacity: 1,
                        symbolSize: 18,
                        symbolShape: "circle",
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemTextColor: "#000",
                            },
                          },
                        ],
                      },
                    ]}
                    onClick={(prop: any) => selectDBHandler(prop.label)}
                  />
                </div>
              )}
            </div>
            <div
              style={{ minHeight: "72vh", width: "58%" }}
              className=" px-4 flex items-start"
            >
              <div className="w-full ml-8">
                <div>
                  {selectedDB !== "all" &&
                    data.map((d: any) => {
                      if (d.name === selectedDB)
                        return (
                          <div className="flex items-center">
                            <p className="font-semibold mr-2">{d.count}</p>{" "}
                            search results for{" "}
                            <p className="font-semibold ml-2">{selectedDB}</p>
                          </div>
                        );
                    })}
                  {selectedDB === "all" && (
                    <div className="flex items-center">
                      Records from all databases.
                    </div>
                  )}
                </div>
                <div
                  style={{ maxHeight: "64vh", overflowY: "auto" }}
                  className="mt-2 border"
                >
                  {tableData && (
                    <Table
                      className="searchDBTable"
                      columns={columns}
                      dataSource={tableData}
                      pagination={false}
                      rowClassName={(record, index) =>
                        index % 2 === 1 ? "table-row-white" : "table-row-color"
                      }
                      scroll={{ y: 420 }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isModalVisibleEdit && (
        <Modal
          className="editTableModal"
          title={<p className="text-white">Edit Data</p>}
          visible={isModalVisibleEdit}
          onOk={handleOkEdit}
          onCancel={handleCancel}
          okText={"Save"}
          width={"60rem"}
          closeIcon={
            <CloseOutlined
              style={{
                color: "whitesmoke",
                marginBottom: "0.5rem",
              }}
            />
          }
        >
          <Form form={form} style={{ width: "100%" }}>
            <Form.Item
              className="mb-2"
              name={"DBName"}
              label="Enter Source Name"
            >
              <input
                style={{ border: "1px solid #27a6a4" }}
                className="rounded-sm w-full px-2 py-0.5"
                placeholder="Enter Here"
              />
            </Form.Item>
            <Form.Item className="mb-2" name={"count"} label="Enter Value">
              <InputNumber
                style={{
                  border: "1px solid #27a6a4",
                  // height: "28px",
                  marginLeft: "3.1rem",
                }}
                className="rounded-sm w-32 py-0"
                min={0}
              />
            </Form.Item>
            <div className="flex item-center justify-end ">
              <p
                onClick={addRow}
                className="text-green-600 font-semibold cursor-pointer mt-0 mb-5"
              >
                Add Row
              </p>
            </div>
            <Row>
              <Col span={4}>
                <p className="h-8 text-center border-r font-semibold">
                  Serial No.
                </p>
              </Col>
              <Col span={15}>
                <p className="h-8 text-center border-r font-semibold">Query</p>
              </Col>
              <Col span={4}>
                <p className="h-8 text-center border-r font-semibold">
                  Results
                </p>
              </Col>
            </Row>
            <div
              className="pt-2 border-t"
              id="EditFormSearch"
              style={{ maxHeight: "36vh", overflowY: "auto" }}
            >
              {copyTableData.map((row: any, index: any) => {
                return (
                  <Row>
                    <Col span={4}>
                      <p className="flex items-center justify-center h-full ">
                        {row.sNo}
                      </p>
                    </Col>
                    <Col span={15}>
                      <Form.Item
                        className="mt-0"
                        name={String(row.sNo) + "searches"}
                      >
                        <input
                          style={{ border: "1px solid #27a6a4" }}
                          className="rounded-sm w-full px-2 py-0.5 bg-gray-100"
                          placeholder="Enter Here"
                          defaultValue={""}
                          key={index + "s"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        className="mt-0"
                        name={String(row.sNo) + "limits"}
                      >
                        <InputNumber
                          style={{ border: "1px solid #27a6a4" }}
                          className="rounded-sm w-20 ml-12"
                          min={0}
                          defaultValue={0}
                          key={index + "l"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <p className="flex items-center justify-center h-full ">
                        <DeleteOutlined
                          onClick={() => {
                            deleteRow(row.sNo);
                          }}
                          style={{ color: "#27a6a4" }}
                        />
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Form>
        </Modal>
      )}
      {isModalVisibleAddDB && (
        <Modal
          className="editTableModal"
          title={<p className="text-white">Add Data</p>}
          visible={isModalVisibleAddDB}
          onOk={handleOkAdd}
          onCancel={handleCancel}
          okText={"Save"}
          width={"60rem"}
          closeIcon={
            <CloseOutlined
              style={{
                color: "whitesmoke",
                marginBottom: "0.5rem",
              }}
            />
          }
        >
          <Form form={form} style={{ width: "100%" }}>
            <Form.Item
              className="mb-2"
              name={"DBName"}
              label="Enter Source Name"
            >
              <input
                style={{ border: "1px solid #27a6a4" }}
                className="rounded-sm w-full px-2 py-0.5"
                placeholder="Enter Here"
              />
            </Form.Item>
            <Form.Item className="mb-2" name={"count"} label="Enter Value">
              <InputNumber
                style={{
                  border: "1px solid #27a6a4",
                  // height: "28px",
                  marginLeft: "3.1rem",
                }}
                className="rounded-sm w-32 py-0"
                min={0}
              />
            </Form.Item>
            <div className="flex item-center justify-end ">
              <p
                onClick={addRowAddDB}
                className="text-green-600 font-semibold cursor-pointer mt-0 mb-5"
              >
                Add Row
              </p>
            </div>
            <Row>
              <Col span={4}>
                <p className="h-8 text-center border-r font-semibold">
                  Serial No.
                </p>
              </Col>
              <Col span={15}>
                <p className="h-8 text-center border-r font-semibold">Query</p>
              </Col>
              <Col span={4}>
                <p className="h-8 text-center border-r font-semibold">
                  Results
                </p>
              </Col>
            </Row>
            <div
              className="pt-2 border-t"
              id="EditFormSearch"
              style={{ maxHeight: "36vh", overflowY: "auto" }}
            >
              {tableDataAddDB.map((row: any, index: any) => {
                return (
                  <Row>
                    <Col span={4}>
                      <p className="flex items-center justify-center h-full ">
                        {row.sNo}
                      </p>
                    </Col>
                    <Col span={15}>
                      <Form.Item
                        className="mt-0"
                        name={String(row.sNo) + "searches"}
                      >
                        <input
                          style={{ border: "1px solid #27a6a4" }}
                          className="rounded-sm w-full px-2 py-0.5 bg-gray-100"
                          placeholder="Enter Here"
                          defaultValue={""}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        className="mt-0"
                        name={String(row.sNo) + "limits"}
                      >
                        <InputNumber
                          style={{ border: "1px solid #27a6a4" }}
                          className="rounded-sm w-20 ml-12"
                          min={0}
                          defaultValue={0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <p className="flex items-center justify-center h-full ">
                        <DeleteOutlined
                          onClick={() => {
                            deleteRowAddDB(row.sNo);
                          }}
                          style={{ color: "#27a6a4" }}
                        />
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Form>
        </Modal>
      )}
      <Modal
        className="editTableModal"
        title={<p className="text-white">Delete {selectedDB}</p>}
        visible={isModalVisibleDeleteDB}
        onOk={deleteDB}
        onCancel={handleCancel}
        closable={false}
        destroyOnClose={true}
      >
        <p>Are you sure you want to delete {selectedDB}?</p>
        <p className="mt-4 text-xs italic text-red-500">
          * This will delete all data related to this {selectedDB}!
        </p>
      </Modal>
      <Drawer
        title={
          <div className="flex">
            <p className="text-black mb-0 w-1/2">Footnotes</p>
            <div className="flex w-1/2 justify-end items-center">
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <div className="flex w-full justify-end items-center">
                  {inEditModeFootnote ? (
                    <div className="flex">
                      <Tooltip title="Save Changes">
                        <SaveOutlined
                          onClick={saveHandlerFootnote}
                          style={{ color: "black", fontSize: "1.2rem" }}
                        />
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <UndoOutlined
                          onClick={() => {
                            setInEditModeFootnote(false);
                            setFootnote(footnoteInitial);
                          }}
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            marginLeft: "0.6rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Edit Footnotes">
                      <EditFilled
                        onClick={() => setInEditModeFootnote(true)}
                        style={{ color: "black", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Close">
                    <CloseOutlined
                      onClick={() => {
                        setDrawerOpen(false);
                        setInEditModeFootnote(false);
                      }}
                      style={{
                        color: "black",
                        fontSize: "1.2rem",
                        marginLeft: "0.6rem",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        }
        closable={false}
        placement="bottom"
        onClose={() => {
          setDrawerOpen(false);
          setInEditModeFootnote(false);
        }}
        getContainer={false}
        visible={drawerOpen}
        style={{ position: "absolute" }}
        zIndex={20}
        bodyStyle={{ padding: "0.5rem" }}
        headerStyle={{
          paddingTop: "0.6rem",
          paddingBottom: "0.6rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "whitesmoke",
          color: "black",
        }}
      >
        {isLoadingFootnote ? (
          <div
            style={{ minHeight: "23vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : footnote === "" && !inEditModeFootnote ? (
          <div
            className="border flex items-center justify-center"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Edit icon to add footnotes!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">No Footnotes Added!</p>
              </div>
            )}
          </div>
        ) : inEditModeFootnote ? (
          <div
            className="border-r"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            <div id="introEditor" className="">
              <SimpleMdeReact
                options={options}
                value={footnote}
                onChange={(e) => {
                  let temp = footnote;
                  temp = String(e);
                  setFootnote(temp);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="border-r"
            style={{
              minHeight: "23vh",
              maxHeight: "23vh",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div id="SLRMDEditor" className="py-4 px-4">
              <ReactMarkdown children={footnote}></ReactMarkdown>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
