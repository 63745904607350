import { Form, Input, Row, Col, Button, Space, Tabs } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline";
import FormField6 from "./formField6";
import { colors } from "./../../globalTheme";
import "./styled.css";
import React from "react";
const FormField7 = (props: {
	addColumn?: any;
	deleteColumn?: any;
	data: any;
	// onCancel: any;
	addRows: any;
	handleSave: (value: any) => void;
}) => {
	const onFinish = (values: any) => {
		props.handleSave(values);
	};
	const { TabPane } = Tabs;
	return (
		<>
			{/* {console.log("Form Data", props.data)} */}
			<Form
				preserve={true}
				// layout="inline"
				className="form5"
				name="dynamic_form_nest_item"
				onFinish={onFinish}
				autoComplete="off"
				initialValues={props.data}
			>
				<Row align="middle">
					<Col span={6}></Col>
					<Col span={6}>
						<p style={{ fontWeight: "bold" }}>Abbreviation</p>
					</Col>
					<Col span={3}></Col>
					<Col span={9}>
						<p style={{ fontWeight: "bold" }}>Details</p>
					</Col>
				</Row>
				{/* {console.log("Data", props.columns)} */}
				<Form.List name="abbreviationsList">
					{(fields, { add, remove }) => (
						<>
							{fields.map(
								({ key, name, fieldKey, ...restField }) => (
									<>
										<Row align="top" gutter={[8, 0]}>
											{/* <Col span={2}>Row {index + 1}</Col> */}

											<Col span={23}>
												<div
													style={{
														display: "flex",
														height: "auto",
														padding: "0px 0px",
														justifyContent:
															"space-evenly",
													}}
												>
													<Form.Item
														className="tableFormField"
														name={[
															name,
															"abbreviation",
														]}
														fieldKey={[
															fieldKey,
															"abbreviation",
														]}
														rules={[
															{
																required: true,
																message:
																	"Please enter abbreviation",
															},
														]}
														style={{
															marginBottom:
																"0px !important",
														}}
													>
														<Input />
													</Form.Item>
													<Form.Item
														className="tableFormField"
														name={[name, "details"]}
														fieldKey={[
															fieldKey,
															"details",
														]}
														rules={[
															{
																required: true,
																message:
																	"Please enter details",
															},
														]}
														style={{
															marginBottom:
																"0px !important",
														}}
													>
														<Input />
													</Form.Item>
												</div>
											</Col>

											<Col span={1}>
												<MinusCircleOutlined
													onClick={() => remove(name)}
												/>
											</Col>
										</Row>
									</>
								)
							)}
							<Form.Item>
								<button
									type="button"
									style={{
										border: "none",
										background: "transparent",
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
										float: "right",
									}}
									onClick={() => add()}
								>
									<PlusOutlined />
									Add Abbreviation
								</button>
							</Form.Item>
							<Form.Item>
								<Button type="primary" htmlType="submit">
									Save
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			</Form>
		</>
	);
};

export default FormField7;
