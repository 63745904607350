import styled from "styled-components";
import { colors } from "../../globalTheme";
export const ReferenceContainer = styled.div`
	width: 100%;
	background: transparent;
	padding-bottom: 8vh;
	/* height: calc(100vh - 22%); */

	height: ${(props: { noSubtab?: boolean }) =>
		props?.noSubtab ? "calc(100vh - 16%)" : "calc(100vh - 22%)"};
	overflow-y: scroll;
	padding: 1em;
	::-webkit-scrollbar {
		display: none;
	}
`;
export const SectionContainer = styled.div`
	width: 100%;
	background: transparent;
	display: grid;
	grid-template-columns: 50% 50%;
	@media (max-width: 1150px) {
		grid-template-columns: 100%;
	}
	margin-bottom: 4em;
	padding: 1em 4em;
`;
export const SectionHeader = styled.div`
	width: 100%;
	padding: 0.5em 0;
	/* background-color: green; */
	h3 {
		font-size: 1.3rem;
		font-weight: 600;
		color: ${colors.zsTeal};
	}
`;
export const SectionReferences = styled.div`
	width: 100%;
	padding: 0.4em 0.6em;
	/* background-color: red; */
`;

export const SubSectionHeader = styled.div`
	h3 {
		font-size: 1.2rem;
		font-weight: 500;
		color: ${colors.zsGray90};
	}
`;

export const SubsectionBody = styled.div`
	/* display: grid;
	grid-template-columns: 10% 90%;
	grid-gap: 1em; */
`;

export const SearchContainer = styled.div`
	width: 100%;
	padding: 1em 1em;
	display: flex;
	align-items: "center";
	p {
		font-size: 1rem;
		font-weight: 500;
		margin: 0;
		margin-right: 8px;
	}
	input {
		width: 20%;
	}
`;
