import * as SupportigMessageService from "../../services/abbreviations";

export const getAbbreviations: (productId: number) => Promise<unknown> = async (
	productId
) => {
	const data = await SupportigMessageService.getAbbreviationsData(productId);
	if (!data) return null;
	return data;
};

export const addAbbreviation: (
	productId: number,
	value: any
) => Promise<unknown> = async (productId, value) => {
	const data = await SupportigMessageService.addAbbreviationsData(
		productId,
		value
	);
	const data2 = await SupportigMessageService.getAbbreviationsData(productId);

	if (data === null || data === undefined) return null;
	return data2;
};
export const deleteAbbreviation: (
	id: number,
	productId: number
) => Promise<unknown> = async (id, productId) => {
	const data = await SupportigMessageService.deleteAbbreviationsData(
		id,
		productId
	);
	const data2 = await SupportigMessageService.getAbbreviationsData(productId);

	if (data === null || data === undefined) return null;
	return data2;
};
