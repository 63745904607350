import {
  KeyMessageContainer,
  KeyMessageHeaderContainer,
  KeyMessageBodyContainer,
  KeyMessageBullet,
  KeyMessageBodyHeader,
  KeyMessageBulletContainer,
  StyledTooltip,
} from "./style";
import { Link, useRouteMatch } from "react-router-dom";
import { ExternalLinkOutline } from "@styled-icons/evaicons-outline/ExternalLinkOutline";
import { Delete } from "@styled-icons/fluentui-system-regular/Delete";
import { Edit2Outline } from "@styled-icons/evaicons-outline/Edit2Outline";
import AppContext from "../../store";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { headerIcons } from "../../globalTheme";
import { ThreeDots } from "@styled-icons/bootstrap/ThreeDots";
import { Popconfirm, message } from "antd";
import Star from "./star";
import { Attachment } from "@styled-icons/typicons/Attachment";
import { request } from "../../layout";
import Highlight from "react-highlighter";

export interface Interface {
  sectionId: number;
  subSectionId: number;
  data: {
    header: string;
    id: number;
    filePath: string;
    text: {
      id: number;
      text: string;
      link: string;
      header: string;
      // referenceHeader?: string;
      // referenceLink?: string;
    }[];
    references: {
      id?: number;
      referenceHeader?: string;
      referenceLink?: string;
    }[];
    link?: string | undefined;
  };
  // header?: string | React.ReactNode;
  bg?: string;
  minHeight?: string;
  height?: string;
  bodyPadding?: string;
  // link?: string;
  // bodyText?: {
  // 	text: string;
  // 	link: string;
  // }[];
  noMatch?: boolean;
  children?: React.ReactNode;
  edit: boolean;
  ref_start: number;
  refArray: any[];
  handleDelete?: (key?: number) => void;
  showModal?: (editData: any) => void;
}
/**
 * Renders the Key Message Tile.
 *
 * @param sectionId - Section Id number
 * @param subSectionId - Sub Section Id number
 * @param data - Data for the Tile
 * @param bg - For setting Background color of the Tile
 * @param minHeight - For setting min height of the Body Container
 * @param height - For setting height of the Body Container
 * @param bodyPadding - For adding padding to Body Container
 * @param children - Children Elements
 * @param edit - Edit Handler function for editing form
 * @param ref_start - Reference Start Number
 * @param handleDelete - Interface elements for rendering the Key Message Tile
 * @param showModal - Function for opening the Modal with the Add / Edit Form
 *
 * @returns Key Message Tile
 */

export default function KeyMessage(props: Interface) {
  let match = useRouteMatch();
  const context = React.useContext(AppContext);
  const [highlight, setHightlight] = React.useState(false);
  function confirm(e: any) {
    //@ts-ignore
    props.handleDelete(props.data.id);
  }

  let n = props.ref_start;
  let FileName = "";
  if (
    props.data.filePath != null &&
    props.data.filePath !== "" &&
    props.data.filePath !== undefined
  ) {
    FileName = props.data.filePath;
    FileName = FileName.split("/")[5];
  }
  function DownloadDocument(filePath: string) {
    //specify path for file to download
    message.loading("Downloading");
    //API to download a document, given a proper filepath
    //returns an object, which needs to downloaded in desired format
    request
      .get(
        process.env.REACT_APP_API_URL +
          "subSection/DownloadFromS3?filePath=" +
          filePath,
        {
          method: "GET",
          responseType: "blob",
          withCredentials: true,
        }
      )
      .then((res) => {
        // console.log("From s3", res);
        //open the document
        const fileName = filePath.split("/")[5];
        const url = window.URL.createObjectURL(
          new Blob([res.data as BlobPart])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        message.error("Error!");
        // console.log({ err });
      });
  }
  const HighlightSearchWord = {
    color: "red",
    backgroundColor: "yellow",
    padding: 0,
  };

  React.useEffect(() => {
    // if (window.getSelection && context.searchWord) {
    //   document.designMode = "on";
    //   var sel = window.getSelection();
    //   sel?.collapse(document.body, 0);
    //   while (window.find(context.searchWord)) {
    //     document.execCommand("HiliteColor", false, "#27a6a4");
    //     sel?.collapseToEnd();
    //   }
    //   document.designMode = "off";
    let searched = context.searchWord;
    console.log(searched);
    // if (searched !== "") {
    //   //@ts-ignore
    //   props.data.text.map((item: any) => {
    //     if (
    //       (item.header && item.header.toLowerCase().includes(searched)) ||
    //       (item.text && item.text.toLowerCase().includes(searched))
    //     )
    //       setHightlight(true);
    //   });
    // } else {
    //   setHightlight(false);
    // }
  }, []);

  return (
    <>
      <KeyMessageContainer highlight={highlight}>
        {/* <img
					src="/icons/KeyMessageStar.png"
					alt=""
					style={{
						position: "absolute",
						top: 0,
						left: 20,
						height: "1.4rem",
						objectFit: "cover",
					}}
				/> */}
        {/* <Star /> */}
        <KeyMessageHeaderContainer>
          {/* <p>{props.data.header}</p> */}

          <div className="icon">
            {props.data.filePath && (
              <Attachment
                className="action-icon"
                title={FileName}
                size={24}
                style={{
                  marginRight: "4px",
                  // color: "gray",
                }}
                onClick={() => {
                  console.log(props);
                  console.log(props.data);
                  DownloadDocument(props.data.filePath);
                }}
              />
            )}
            {context.isAdmin && (
              <div className="action-icons">
                <Popconfirm
                  title="Are you sure to delete this message?"
                  onConfirm={() => {
                    //@ts-ignore
                    props.handleDelete(props.data.id);
                  }} // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Delete
                    className="action-icon"
                    title="Delete Tile"
                    size={28}
                    style={{
                      paddingBottom: "4px",
                      marginRight: "4px",
                      // color: "red",
                    }}
                    // onClick={() => {
                    //   //@ts-ignore

                    //   props.handleDelete(props.data.id);
                    // }}
                  />
                </Popconfirm>

                <Edit2Outline
                  className="action-icon"
                  title="Edit Tile"
                  size={24}
                  style={{
                    marginRight: "4px",
                    // color: "gray",
                  }}
                  onClick={() => {
                    //@ts-ignore
                    props.showModal(props.data);
                  }}
                />
              </div>
            )}
            {/* <Link
							to={`${match.url}/${props.data.link}/${props.data.id}`}
						>
							<ExternalLinkOutline size={24} />
						</Link> */}
          </div>
        </KeyMessageHeaderContainer>
        <KeyMessageBodyContainer edit={props.edit}>
          {props.data.text?.map((bullets, index) => {
            return (
              <>
                <KeyMessageBodyHeader isAdmin={context.isAdmin}>
                  <p>
                    <div
                      className="markDownContainer"
                      style={{ display: "inline-block" }}
                    >
                      {/* <ReactMarkdown source={bullets.header} /> */}
                      {bullets.header
                        .toLowerCase()
                        .includes(context.searchWord) &&
                        context.searchWord !== "" && (
                          <Highlight
                            search={context.searchWord}
                            matchStyle={HighlightSearchWord}
                          >
                            {bullets.header}
                          </Highlight>
                        )}
                      {(!bullets.header
                        .toLowerCase()
                        .includes(context.searchWord) ||
                        context.searchWord === "") && (
                        <ReactMarkdown source={bullets.header} />
                      )}
                      {props.data.references &&
                        props.data.references.length > 0 &&
                        props.data.references.map((refs) => {
                          return (
                            <StyledTooltip
                              title={
                                <>
                                  <span
                                    style={{
                                      marginBottom: 0,
                                    }}
                                  >
                                    {refs.referenceHeader}
                                    <br />

                                    {refs.referenceLink && (
                                      <a
                                        href={refs.referenceLink}
                                        target="_blank"
                                      >
                                        Link
                                      </a>
                                    )}
                                  </span>
                                </>
                              }
                              // title={"Refs"}
                              // className="reference-tooltip"
                            >
                              <span>
                                [
                                {props.refArray.findIndex(
                                  (r: any) =>
                                    r.referenceHeader === refs.referenceHeader
                                ) + 1}
                                ]
                              </span>
                              {/* <span>
																	[{n++}]
																</span> */}
                            </StyledTooltip>
                          );
                        })}
                    </div>
                  </p>
                  {!props.edit && (
                    <Link
                      to={
                        props.data.header === "Epidemiology data by country"
                          ? `${match.url}/${bullets.link}/EvidenceAtlas/${props.data.id}/${bullets.id}`
                          : `${match.url}/${bullets.link}/${props.data.id}/${bullets.id}`
                      }
                      onClick={() => context.setNewSearchLink("&  &")}
                    >
                      <ExternalLinkOutline size={24} />
                    </Link>
                  )}
                </KeyMessageBodyHeader>
                {!props.edit && bullets.text && (
                  <KeyMessageBulletContainer>
                    <KeyMessageBullet key={index}>
                      <div className="message-bullet">
                        {console.log()}
                        {bullets.text
                          .toLowerCase()
                          .includes(context.searchWord) &&
                          context.searchWord !== "" && (
                            <Highlight
                              search={context.searchWord}
                              matchStyle={HighlightSearchWord}
                            >
                              {bullets.text}
                            </Highlight>
                          )}
                        {(!bullets.text
                          .toLowerCase()
                          .includes(context.searchWord) ||
                          context.searchWord == "") && (
                          <ReactMarkdown source={bullets.text} />
                        )}

                        {/* <p>{bullets.text}</p> */}
                        {/* {bullets.link != "" && (
													<div className="icon">
														<Link
															to={
																props.noMatch
																	? bullets.link ||
																	  ""
																	: `${match.url}/${bullets.link}` ||
																	  `${match.url}`
															}
														>
															<ExternalLinkOutline
																size={24}
															/>
														</Link>
													</div>
												)} */}
                      </div>
                    </KeyMessageBullet>
                  </KeyMessageBulletContainer>
                )}
              </>
            );
          })}
        </KeyMessageBodyContainer>
        <div>
          {props.edit &&
            props.data.header !== "Epidemiology data by country" && (
              <>
                <ThreeDots
                  size={24}
                  style={{
                    float: "right",
                    marginRight: "20px",
                  }}
                />
              </>
            )}
        </div>
      </KeyMessageContainer>
    </>
  );
}
