import { LogOut } from "@styled-icons/boxicons-regular/LogOut";
import { Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import GVD_Logo from "../SLR/pictures/gvd.png";
import SLR_Logo from "../SLR/pictures/slr.png";

const request = axios.create({
  baseURL: "https://localhost:5001/api/Slr",
  // baseURL: "https://heorgvdslr.api.zsservices.com/api/Slr",
  withCredentials: true,
});

request.interceptors.request.use((config) => reqConfig(config));
request.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
const reqConfig = (config: any) => {
  config.headers["userName"] = localStorage.getItem("userName");
  config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
  return config;
};
const responseHandler = (response: any) => {
  return response;
};
const errorHandler = (error: any) => {
  if (error.response.status === 401)
    window.location.href = process.env.REACT_APP_REDIRECT_URL || "";
  return Promise.reject(error);
};

export default function Landing(props: { setApp: any }) {
  const [userName, setUserName] = useState("USERNAME");
  const [Loading, setLoading] = useState(true);
  const navigate = useHistory();

  useEffect(() => {
    // API to authenticate IDM login
    request
      .get("https://heorgvdslr.api.zsservices.com/api/Slr/FetchUserName", {
        withCredentials: true,
      })
      .then((res: any) => {
        console.log("Response from Authenticate API", res);
        if (res.status === 200) {
          let userName = res.data.username || "USERNAME";
          let isAdmin = res.data.isAdmin ? "true" : "false";
          window.localStorage.setItem("userName", String(userName));
          window.localStorage.setItem("isAdminGlobal", isAdmin);
          setUserName(userName);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401)
          window.location.href = process.env.REACT_APP_REDIRECT_URL || "";
      });
  }, []);

  return (
    <div>
      {!Loading ? (
        <div
          style={{ minHeight: "100vh" }}
          className="bg-gray-300 landingBackground"
        >
          <div className="flex items-center pt-4 justify-end pr-8 text-white ">
            <a
              style={{
                paddingLeft: "0.6rem",
              }}
              href={process.env.REACT_APP_SIGNOUT_URL}
            >
              <LogOut
                style={{
                  marginBottom: "0.3em",
                  color: "whitesmoke",
                  marginRight: "0.3em",
                  marginTop: "0.2rem",
                }}
                size={26}
              />
              <span style={{ fontSize: "15x" }} className="tracking-normal">
                Sign out
              </span>
            </a>
          </div>
          <div className="flex items-center pt-4 justify-center text-white text-7xl font-semibold ">
            GVD & SLR
          </div>
          <div className="flex items-center justify-center mt-32 text-white ">
            <div className="text-center">
              <p className="text-3xl">Welcome</p>
              <p className="text-5xl mt-2">{userName}</p>
            </div>
          </div>
          <div className="flex mt-32 items-center justify-center tracking-normal">
            <div
              style={{ minHeight: "13.19rem" }}
              className="bg-white w-80 py-2 rounded-md"
            >
              <div
                onClick={() => {
                  props.setApp("GVD");
                  navigate.replace("/GVD");
                }}
                className="mx-2 flex justify-center items-center border-b-2 cursor-pointer"
              >
                <img src={GVD_Logo} alt="GVD LOGO" />
                <div className="text-4xl ml-2 pb-2 text-blue-900 font-semibold tracking-widest">
                  GVD
                </div>
              </div>
              <div className="flex justify-center px-4 mt-2 text-center">
                Global Value Dossiers are industry standard tools used
                throughout product lifecycles that act as a foundation of
                current and future market initiatives through development,
                launch, and marketing.
              </div>
            </div>
            <div
              style={{ minHeight: "12rem" }}
              className="bg-white w-80 py-2 ml-8 rounded-md"
            >
              <div
                onClick={() => {
                  props.setApp("SLR");
                  navigate.replace("/SLR");
                }}
                className="mx-2 flex justify-center items-center border-b-2  cursor-pointer"
              >
                <img src={SLR_Logo} alt="GVD LOGO" />
                <div className="text-4xl ml-2 pb-2 text-green-900 font-semibold tracking-widest">
                  SLR
                </div>
              </div>
              <div className="flex justify-center px-4 mt-2 text-center">
                Systematic literature reviews help gather evidence that fits
                pre-specified eligibility criteria to answer a specific research
                question. They aim to minimize bias by using explicit,
                systematic methods documented in advance with a protocol.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ overflow: "hidden", height: "100vh" }}
          className="bg-gray-300 landingBackground"
        >
          <div className="flex items-center pt-16 justify-center">
            <Skeleton.Input
              active
              style={{ width: 700, height: 120 }}
              size="large"
            />
          </div>
          <div className="flex items-center justify-center mt-16">
            <div className="text-center">
              <Skeleton.Input
                style={{ width: 300, height: 40 }}
                active
                size="small"
              />
              <br />
              <br />
              <Skeleton.Input
                style={{ width: 400, height: 60 }}
                active
                size="default"
              />
            </div>
          </div>
          <div className="flex mt-24 items-center justify-center tracking-normal">
            <Skeleton.Avatar
              style={{ width: 350, height: 200 }}
              active
              shape="square"
            ></Skeleton.Avatar>
            <Skeleton.Avatar
              style={{ width: 350, height: 200, marginLeft: "3rem" }}
              active
              shape="square"
            ></Skeleton.Avatar>
          </div>
        </div>
      )}
    </div>
  );
}
