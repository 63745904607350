import React, { memo, useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { ResponsiveChoropleth } from "@nivo/geo";
import countries from "../../../countries.json";
import { CircleFill } from "@styled-icons/bootstrap/CircleFill";
import { ExternalLinkOutline } from "@styled-icons/evaicons-outline/ExternalLinkOutline";
import { Button, Drawer, Radio, Space, Tooltip } from "antd";
import ReactMarkdown from "react-markdown";

const EvidenceAtlas = (props: {
  data?: any;
  setSelectedCountry?: any;
  maxDomainLength: number;
  tableData: any;
  columns: any;
  studyDetails?: any;
}) => {
  const [content, setTooltipContent] = useState("");
  const [countryData, setCountryData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [renderTableData, setRenderTableData] = useState([...props.tableData]);
  const [legendData, setLegendData] = useState<any>([]);

  useEffect(() => {
    //create legends
    setLoading(true);
    let prev = 0;
    let interval = Math.ceil(props.maxDomainLength / 5);
    let end = interval;
    let temp = [
      {
        color: "#FFFFFF",
        label: "No. of studies",
      },
    ];
    let colors = ["#dbe9f6", "#b2d3e8", "#5ea5d0", "#1f6eb1", "#083a79"];
    colors.map((color: any) => {
      temp.push({
        color: color,
        label: prev + " - " + end,
      });
      prev += interval;
      end += interval;
    });
    setLegendData([...temp]);
    setLoading(false);
  }, []);

  useEffect(() => {
    let tempData: any = [];
    props.tableData?.map((data: any) => {
      if (data.countries?.includes(selectedCountry)) {
        tempData.push(data);
      }
    });
    setRenderTableData([...tempData]);
  }, [selectedCountry]);

  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div>
      <div>
        {loading ? (
          <div className="flex items-center justify-center mx-4 bg-white px-2 py-2 shadow-md">
            <BeatLoader color={"#27A6A4"} size={30} />
          </div>
        ) : (
          <div style={{ height: "585px" }}>
            <ResponsiveChoropleth
              data={props.data}
              margin={{ top: 10, right: 0, bottom: 0, left: 0 }}
              features={countries.features}
              colors={["#dbe9f6", "#b2d3e8", "#5ea5d0", "#1f6eb1", "#083a79"]}
              domain={[0, props.maxDomainLength]}
              unknownColor="#9f9f9f"
              label="properties.name"
              valueFormat=".2"
              projectionType="equirectangular"
              projectionScale={190}
              projectionTranslation={[0.5, 0.5]}
              projectionRotation={[0, 0, 0]}
              graticuleLineWidth={2}
              graticuleLineColor="#dddddd"
              borderColor="#152538"
              borderWidth={0.2}
              legends={[
                {
                  data: legendData,
                  anchor: "bottom-left",
                  direction: "column",
                  justify: false,
                  translateX: 20,
                  translateY: -100,
                  itemsSpacing: 0,
                  itemWidth: 60,
                  itemHeight: 18,
                  itemDirection: "left-to-right",
                  itemTextColor: "#444444",
                  itemOpacity: 0.85,
                  symbolSize: 18,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000000",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              tooltip={({ children, feature }) => {
                if (feature.data)
                  return (
                    <div className="px-2 py-1 bg-white">
                      <div className="flex items-center justify-center ">
                        <p className="font-semibold">{feature.label}:</p>
                        <p className="font-bold ml-1">
                          {feature.data?.value || 0}
                        </p>
                      </div>
                      <hr />
                      <div className="text-xs">
                        {feature.data?.studies.map((study: any) => {
                          return (
                            <p>
                              <CircleFill
                                size={4}
                                style={{ marginRight: "0.2rem" }}
                              />
                              {study}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  );
                else return <></>;
              }}
              onClick={({ color, data, formattedValue, label, value }) => {
                if (data) {
                  setSelectedCountry(data.country);
                  setOpenDrawer(true);
                  // props.setSelectedCountry(data.country);
                }
              }}
            />
          </div>
        )}
        <p className="text-green-600 italic mt-2 pl-2 pb-2">
          * Click on a country to view its details.
        </p>
      </div>
      {openDrawer && (
        <Drawer
          title={selectedCountry}
          placement={"bottom"}
          onClose={onClose}
          visible={openDrawer}
          key={"bottom"}
          height="20rem"
          getContainer={false}
          style={{ position: "absolute" }}
          zIndex={10}
          bodyStyle={{ padding: "0.5rem" }}
          headerStyle={{ paddingTop: "0.8rem", paddingBottom: "0.5rem" }}
        >
          <div
            style={{
              height: "260px",
              overflowY: "auto",
              width: "100%",
              overflowX: "auto",
            }}
            className="fixedHeader dashboardCheckboxTable bg-white"
          >
            {renderTableData && (
              <table>
                <thead>
                  <tr className="roundedRows">
                    {props.columns.map((col: any) => (
                      <th
                        style={{
                          backgroundColor: "#27a6a4",
                          color: "whitesmoke",
                          minWidth: "10rem",
                        }}
                        className="font-bold text-center "
                      >
                        {col.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {renderTableData.map((row: any, index: any) => {
                    const color = index % 2 === 0 ? "#eaf8f8" : "white";
                    const keys: any = [];
                    props.columns.map((c: any) => keys.push(c["dataIndex"]));
                    const temp = keys.indexOf("key");
                    if (temp > -1) {
                      keys.splice(temp, 1);
                    }
                    return (
                      <tr
                        id={row.studyname}
                        style={{ backgroundColor: color }}
                        className="hover:bg-transparent border-b-8 roundedRows"
                      >
                        {keys.map((key: any) => {
                          if (key === "studyname") {
                            return (
                              <td className="align-top p-1 text-center">
                                <div className="flex justify-end items-center">
                                  <Tooltip
                                    title={props.studyDetails[row["key"]]}
                                  >
                                    <a
                                      href={props.studyDetails[row["key"]]}
                                      target="_blank"
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        height: "0.7rem",
                                      }}
                                    >
                                      <ExternalLinkOutline
                                        size={12}
                                        style={{ right: 0 }}
                                      />
                                    </a>
                                  </Tooltip>
                                </div>
                                <ReactMarkdown
                                  children={row[key]}
                                ></ReactMarkdown>
                              </td>
                            );
                          } else
                            return (
                              <td className="align-top p-2 text-center">
                                <ReactMarkdown
                                  children={row[key]}
                                ></ReactMarkdown>
                              </td>
                            );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default EvidenceAtlas;
