import React from "react";
import Tile2 from "../../libraries/tile2";
import AppContext from "../../store/index";
import {
  ReferenceContainer,
  SearchContainer,
  SectionContainer,
  SectionHeader,
  SectionReferences,
  SubsectionBody,
  SubSectionHeader,
} from "./style";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { filterRefs } from "./processing";
import * as ReferencesServiceHelper from "./service-helper";

/**
 * Renders the References Page.
 *
 * @returns References Page of the App
 */
export default function References() {
  const context = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);
  const [fetchedData, setFetchedData] = React.useState<
    {
      section: string;
      subSection: {
        subSectionName: string;
        references: {
          referenceHeader: string;
          referenceLink: string;
          id: number;
        }[];
      }[];
    }[]
  >([]);
  // const [rawData, setRawData] = React.useState<any>([]);
  const rawDataCopy = React.useRef<any>([]);
  // const [searchWord, setSearchWord] = React.useState<string>("");
  const handleSearch = (e: any) => {
    if (!e.target.value) {
      setFetchedData(filterRefs(rawDataCopy.current));
    } else {
      var temp = [...rawDataCopy.current];
      var temp2: any = [];
      for (let i = 0; i < temp.length; i++) {
        const tempRef = temp[i].references.filter((ref: any) =>
          ref.referenceHeader
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        );
        if (tempRef.length > 0) {
          temp2.push({
            id: temp[i].id,
            section: temp[i].section,
            subSection: temp[i].subSection,
            references: tempRef,
          });
        } else {
          temp2.push({
            id: temp[i].id,
            section: temp[i].section,
            subSection: temp[i].subSection,
            references: [],
          });
        }
      }
      temp2.forEach((section: any) => {
        section.references = section.references.filter((ref: any) =>
          ref.referenceHeader
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        );
      });
      setFetchedData(filterRefs(temp2));
    }
  };
  async function fetchdata() {
    setLoading(true);
    const data: any = await ReferencesServiceHelper.getReferences(
      context.productId
    );
    if (data) {
      const temp = filterRefs(data);

      rawDataCopy.current = data;
      setFetchedData(temp);
    }
    setLoading(false);
  }
  React.useEffect(() => {
    context.setView(9);
    fetchdata();
  }, []);
  const dummyData = [
    {
      id: 1,
      section: "Executive Summary",
      subSection: "default",
      references: [],
    },
    {
      id: 4,
      section: "Disease Overview",
      subSection: "Overview",
      references: [
        {
          id: 148,
          referenceHeader:
            "MacDonald IM, Hume S, Chan S, et al. Choroideremia. 2003 Feb 21 [Updated 2015 Feb 26]. In: Adam MP, Ardinger HH, Pagon RA, et al., editors. GeneReviews® [Internet]. Seattle (WA): University of Washington, Seattle; 1993-20",
          referenceLink: "https://www.ncbi.nlm.nih.gov/books/NBK1337/",
        },
        {
          id: 149,
          referenceHeader: "Xxz",
          referenceLink: "https://www.ncbi.nlm.nih.gov/books/NBK1337/",
        },
      ],
    },
  ];

  const override = css`
    display: block;
    margin-left: 45%;
    margin-top: 15%;
    border-color: red;
  `;
  return (
    <>
      {!loading ? (
        <>
          <SearchContainer>
            <p className="mt-0.5">Search</p>
            <input
              placeholder="Enter a search term"
              className="p-1"
              onChange={(e) => handleSearch(e)}
            />
          </SearchContainer>
          {fetchedData.length > 0 ? (
            <ReferenceContainer>
              {fetchedData.map((ref) => {
                return (
                  <>
                    <>
                      <SectionHeader>
                        <h3>{ref.section}</h3>
                      </SectionHeader>
                      <SubsectionBody>
                        {ref.subSection.map((refs) => {
                          return (
                            <>
                              <SubSectionHeader>
                                {refs.subSectionName !== "default" && (
                                  <h3>{refs.subSectionName}</h3>
                                )}
                              </SubSectionHeader>
                              <SectionContainer>
                                {refs.references.map((refss, index) => {
                                  return (
                                    <>
                                      {refss.referenceHeader !== null && (
                                        <SectionReferences>
                                          <Tile2
                                            index={index}
                                            data={refss}
                                          ></Tile2>
                                        </SectionReferences>
                                      )}
                                    </>
                                  );
                                })}
                              </SectionContainer>
                            </>
                          );
                        })}
                      </SubsectionBody>
                    </>
                  </>
                );
              })}
            </ReferenceContainer>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "middle",
              }}
            >
              <h3>No References found</h3>
            </div>
          )}
        </>
      ) : (
        <>
          <BeatLoader
            css={override}
            color={"#27A6A4"}
            loading={true}
            size={30}
          />
        </>
      )}
    </>
  );
}

/* {fetchedData && fetchedData.length > 0 ? (
				<ReferenceContainer>
					{fetchedData.map((ref) => {
						return (
							<>
								<>
									<SectionHeader>
										<h3>{ref.subSection}</h3>
									</SectionHeader>
									<SectionContainer>
										{ref.references.map((refs) => {
											return (
												<>
													{refs.referenceHeader !==
														null && (
														<SectionReferences>
															<Tile2
																data={refs}
															></Tile2>
														</SectionReferences>
													)}
												</>
											);
										})}
									</SectionContainer>
								</>
							</>
						);
					})}
				</ReferenceContainer> */
