import { ResponsivePie } from "@nivo/pie";
import * as ReportTypesComponentProps from "../../interfaces/main";
import transformData from "./transform";

const theme = (background?: string, fontSize?: number) => {
	return {
		background: background || "white",
		fontSize: fontSize || 11,
	};
};
/**
 * Renders the Responsive Pie Chart Component. The component makes use of Nivo Charts Library.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @param props - Chart Config for the Pie Chart Component
 *
 * @returns Pie Chart Component of Nivo
 */
const PieChart: React.FC<ReportTypesComponentProps.Interface> = (
	props: ReportTypesComponentProps.Interface
): JSX.Element => (
	<>
		<ResponsivePie
			data={transformData(props.data, props.series, 0)}
			colors={{ datum: "data.color" }}
			// format        to be added
			theme={theme(props.chartArea?.background, props.style?.fontSize)}
			layers={["arcLinkLabels", "arcs", "arcLabels", "legends"]}
			margin={
				props.style?.margin || {
					top: 20,
					right: 80,
					bottom: 50,
					left: 50,
				}
			}
			startAngle={props.chartConfig.radial?.startAngle}
			endAngle={props.chartConfig.radial?.endAngle}
			innerRadius={props.chartConfig.radial?.innerRadius}
			padAngle={props.chartConfig.radial?.gaps?.padAngle}
			cornerRadius={props.chartConfig.radial?.cornerRadius}
			activeOuterRadiusOffset={8} //check
			borderWidth={props.style?.borderWidth}
			borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
			enableArcLabels={props.label ? true : false}
			arcLabel={props.label?.format}
			arcLabelsSkipAngle={props.label?.skipSmallValue ? 10 : 0}
			arcLabelsTextColor={
				props.style?.labelTextColor || {
					from: "color",
					modifiers: [["darker", 3]],
				}
			}
			enableArcLinkLabels={
				props.chartConfig.radial?.arcLinkLabel ? true : false
			}
			arcLinkLabelsSkipAngle={props.label?.skipSmallValue ? 10 : 0}
			arcLinkLabelsDiagonalLength={
				props.chartConfig.radial?.arcLinkLabel?.diagonalLength
			}
			arcLinkLabelsStraightLength={
				props.chartConfig.radial?.arcLinkLabel?.straightLength
			}
			arcLinkLabelsColor={props.style?.arcLinkColor || { from: "color" }}
			arcLinkLabelsTextColor={
				props.style?.arcLinkColor || {
					from: "color",
					modifiers: [["darker", 2]],
				}
			}
			arcLinkLabelsThickness={
				props.chartConfig.radial?.arcLinkLabel?.arcLinkLabelThickness ||
				2
			}
			isInteractive={props.tooltip?.isInteractive}
			animate={props.animate ? props.animate : true}
			legends={
				props.legend
					? [
							{
								anchor:
									props.legend?.position || "bottom-right",
								direction: props.legend?.direction || "column",
								itemHeight: 20,
								itemWidth: 110,
								toggleSerie: true,
								translateX: 120,
								symbolShape: props.legend.symbolShape,
							},
					  ]
					: []
			}
		/>
	</>
);
export default PieChart;
