import styled from "styled-components";
import { colors } from "../../globalTheme";
export const AboutUsContainer = styled.div`
	width: 100%;
	background: transparent;
	padding-bottom: 8vh;
	/* height: calc(100vh - 22%); */
	height: ${(props: { noSubtab?: boolean }) =>
		props?.noSubtab ? "calc(100vh - 16%)" : "calc(100vh - 22%)"};
	overflow-y: scroll;
	padding: 1em;
	::-webkit-scrollbar {
		display: none;
	}
`;
export const CardContainer = styled.div`
	width: 100%;
	background: transparent;
	display: grid;
	grid-template-columns: ${(props: { grid?: number }) =>
		props?.grid === 1
			? "100%"
			: props?.grid === 2
			? "50% 50%"
			: "33.33% 33.33% 33.33%" || "33.33% 33.33% 33.33%"};
`;
export const CardSlot = styled.div`
	width: 100%;
	padding: 1em 1.2em;
`;
