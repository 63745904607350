import { Button, Col, Modal, Row } from "antd";
import Table from "../../layout/components/body-layout/SupportingMessages3/Component/Table/index";
import BeatLoader from "react-spinners/BeatLoader";

import type { ColumnsType } from "antd/es/table";
import { StyledTableChartPlaceholder } from "../../../layout/components/body-layout/SupportingMessages3/style";
import FormField5 from "../../libraries/form-fields/formField5";
import SimpleTable from "../../libraries/report-types/charts/table/simpleTable/table";
import { useEffect, useState } from "react";
import { EditFilled, SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import EvidenceAtlas from "../../components/Results/EvidenceAtlas";
import Dashboard from "../../components/Results/Dashboard";
import Countries from "../../../countries.json";
import PatientCharacteristics from "./PatientCharacteristics";

export default function StudyCharacteristics(props: {
  columns: any;
  data: any;
  handleSave: any;
  defaultColumns: any;
  additionalColumns: any;
  studyDesignValues?: any;
  tableName?: any;
  saveCounter?: any;
  setSaveCounter?: any;
  studyDetails?:any;
}) {
  return (
    <div id={"studyCharacteristics"}>
      <SimpleTable
        tableData={{ data: props.data, column: props.columns }}
        saveFunction={props.handleSave}
        defaultColumns={props.defaultColumns}
        additionalColumns={props.additionalColumns}
        studyDesignItems={props.studyDesignValues}
        ethnicityItems={[]}
        histologyItems={[]}
        metastaticSitesItems={[]}
        metastaticStageItems={[]}
        genderItems={[]}
        mutationStatusItems={[]}
        smokingItems={[]}
        tableName={props.tableName}
        saveCounter={props.saveCounter}
        setSaveCounter={props.setSaveCounter}
        studyDetails={props.studyDetails}
      />
    </div>
  );
}
