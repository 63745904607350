import styled from "styled-components";
import { colors } from "../../../globalTheme";

export const ObjectionsContainer = styled.div`
	width: 100%;
	background: transparent;
	padding-bottom: 8vh;
	/* display: grid;
	grid-template-columns: 50% 50%; */

	height: calc(100vh - 16%);
	overflow-y: scroll;
	padding: 0 1em;
	::-webkit-scrollbar {
		display: none;
	}
	.slide {
		background: transparent !important;
	}
`;
export const DivTopCard = styled.div`
	width: 100%;
	padding: 1em 1.4em;
    margin 0.5em 0;
	background:transparent;
    display:grid;
    grid-template-columns: 25% 25% 25% 25%;
`;

export const ObjecionCard = styled.div`
	background: ${colors.zsWhite};
	padding: 1em 1.4em;
	margin: 0 1em;
`;
export const ObjectionCardDetail1 = styled.div`
	margin: 1em;
	padding: 1em 0.8em;
	background: #fdf2ea;
	min-height: 105px;
	font-size: 0.9rem;
	color: ${colors.zsGray90};
`;
export const ObjectionCardDetail2 = styled.div`
	margin: 1em;
	padding: 1em 0.8em;
	background: #e2f0d9;
	min-height: 210px;
	font-size: 0.9rem;
	color: ${colors.zsGray90};
`;

export const DivBottomCard = styled.div`
	width: 100%;
    /* background:red; */
	padding: 1em 1.4em;
    margin 0.5em 0;
	background:transparent;
   
`;

export const ObjecionCard2 = styled.div`
	background: ${colors.zsWhite};
	display: grid;
	grid-template-columns: 30em auto;
	padding: 1em 1.2em;
	margin: 0.5em 1.2em;
`;
export const ObjectionCard2Header = styled.div`
	margin: 0 1em;
	padding: 1em;
	height: 100%;
	min-height: 80px;
	display: flex;
	align-items: center;
	justify-content: left;
	background: ${(props: { color?: string }) =>
		props?.color ? props.color : "white"};
	color: white;
	font-size: 1.5em;
	clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%);
	p {
		font-size: 1.3rem;
		line-height: 100%;
		margin: auto 0;
		margin-left: 8px;
	}
`;
export const ObjectionCardDetail3 = styled.div`
	padding: 1em 0.8em;
	/* background: #e2f0d9; */
	font-size: 0.9rem;
	color: ${colors.zsGray90};
	margin: auto 0;
	p {
		font-size: 1.2rem;
		margin: 0;
	}
`;
