import { useState } from "react";
import Table from "../../layout/components/body-layout/SupportingMessages3/Component/Table/index";
import {
  transformColumn,
  transformTableData,
  reverseTransformTableData,
  reverseTransformColumn,
  transformLabels,
  transformSeries,
  transformChartData,
  reverseTransformCharteData,
  reverseTransformLabels,
  reverseTransformSeries,
} from "../../layout/components/body-layout/SupportingMessages3/processing";
import { SimpleTable } from "../../libraries/report-types/charts/table";

export default function PatientCharacteristics(props: {
  columns: any;
  data: any;
  handleSave: any;
  defaultColumns: any;
  additionalColumns: any;
  ethnicityItems?: any;
  mutationStatusItems?: any;
  histologyItems?: any;
  metastaticSitesItems?: any;
  metastaticStageItems?: any;
  smokingItems?: any;
  genderItems?: any;
  tableName?: any;
  saveCounter?: any;
  setSaveCounter?: any;
  studyDetails?: any;
}) {
  return (
    <div>
      <SimpleTable
        tableData={{ data: props.data, column: props.columns }}
        saveFunction={props.handleSave}
        defaultColumns={props.defaultColumns}
        additionalColumns={props.additionalColumns}
        ethnicityItems={props.ethnicityItems}
        mutationStatusItems={props.mutationStatusItems}
        histologyItems={props.histologyItems}
        metastaticSitesItems={props.metastaticSitesItems}
        metastaticStageItems={props.metastaticStageItems}
        smokingItems={props.smokingItems}
        genderItems={props.genderItems}
        studyDesignItems={[]}
        tableName={props.tableName}
        saveCounter={props.saveCounter}
        setSaveCounter={props.setSaveCounter}
        studyDetails={props.studyDetails}
      />
    </div>
  );
}
