import * as LandingService from "./../../services/landing";

export const getAllProducts: () => Promise<unknown> = async () => {
	const data = await LandingService.getAllBrandsData();
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const addNewBrand: (productData: {
	name: string;
	desription: string;
	createdBy: string;
	userRole: {
		userId: string;
		rolesId: number;
	}[];
}) => Promise<unknown> = async (productData) => {
	// console.log("Inside Add Brand");
	const data = await LandingService.addNewBrandData(productData);
	// console.log("D", data);
	const data2 = await LandingService.getAllBrandsData();
	if (data === null || data === undefined) return null;
	return data2;
};

export const deleteBrand: (id: number, productId: number) => Promise<unknown> =
	async (id, productId) => {
		// console.log("Inside Add Brand");
		const data = await LandingService.deleteBrandData(id, productId);
		const data2 = await LandingService.getAllBrandsData();
		if (data === null || data === undefined) return null;
		return data2;
	};

export const updateBrand: (updatedBrandData: {
	id: number;
	name: string;
	desription: string;
	lastUpdatedBy: string;
	userRole: {
		userId: string;
		rolesId: number;
	}[];
}) => Promise<unknown> = async (updateBrandData) => {
	// console.log("Inside Add Brand");
	const data = await LandingService.updateBrandData(updateBrandData);
	const data2 = await LandingService.getAllBrandsData();
	if (data === null || data === undefined) return null;
	return data2;
};
const d = {
	name: "Name 1",
	desription: "Description",
	createdBy: "ms30001",
	userRole: [
		{ userId: "aa20384", rolesId: 2 },
		{ userId: "hc31992", rolesId: 2 },
	],
};
