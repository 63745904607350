// import { SettingsOutline } from "@styled-icons/evaicons-outline/SettingsOutline";
import { Edit } from "@styled-icons/boxicons-solid/Edit";
export default function GearIcon(props: { handleClick: () => void }) {
  return (
    <div className="flex" onClick={() => props.handleClick()}>
      <Edit title="Edit Table" size={24} style={{ color: "#32A29B" }} />
      <p style={{ color: "#32A29B" }} className="mb-0 ml-1 font-semibold">
        Edit Table
      </p>
    </div>
  );
}
