import styled from "styled-components";
import { Interface } from "./../card";

export const CardContainer = styled.div`
  background-color: #ffffff; // Have option for transparent or other colors
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${(props: Interface) => props.bg || "#fff"};
  height: ${(props: Interface) => props.height || "auto"};
`;

export const CardHeadingText = styled.h3`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 100%;
  color: #000;
  margin: 5px 0px;
  text-transform: capitalize;
`;
export const CardSubHeadingText = styled.h4`
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  font-size: 12px;
  line-height: 100%;
  color: #32a29b;
  margin: 5px 0px;
  text-transform: capitalize;
  letter-spacing: 0.2rem;
`;

export const CardHeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CardHeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const CardHeaderAction = styled.div`
  margin: 0px 6px;
  opacity: 1;
  cursor: pointer;
`;

export const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${(props: Interface) => props.minHeight || "auto"};
  padding: ${(props: Interface) => props.bodyPadding || "0px 15px"};
  margin: 5px 0;
  border-radius: 5px;
  box-sizing: border-box;
`;

export const CardHeader = styled.div`
  display: flex;
  padding: 0px 15px 4px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  position: relative;
  margin-top: 8px;
  box-sizing: border-box;
`;
export const HeaderRibbon = styled.div`
  display: block;
  content: "";
  position: absolute;
  left: 1%;
  width: 98%;
  bottom: 0;
  border-bottom: 1px solid #ec7200;
`;
export const CardFooter = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 0px 15px;
  box-sizing: border-box;
`;
export const CardFooterText = styled.h4`
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  color: #6d6c6c;
  text-transform: capitalize;
  letter-spacing: 0.2rem;
`;
