import React from "react";
import { Modal } from "antd";
import ReportTypes from "../../../../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../../../../libraries/report-types/charts/interfaces/main";
import FormField6 from "../../../../../../libraries/form-fields/formField6";
import { colors } from "../../../../../../globalTheme";
import { groupMode } from "../../../../../../libraries/report-types/charts/interfaces/cartesianChart";
import {
	reverseTransformChartProcessedData,
	transformChartRawData,
} from "./processing";
import { Prompt } from "react-router-dom";
export default function Chart(props: {
	id: number | null;
	supportingMessageId: number;
	name: string;
	data: (string | number)[][];
	// data: { [id: string]: string | number }[];
	series: {
		id: number;
		dim: number;
		color: string;
		name: string;
	}[];
	chartType: number;
	xAxisLabel: string;
	yAxisLabel: string;
	labels: {
		id: number;
		headerName: string;
		identifier: string;
		chartComponentId: number;
	}[];
	handleSave: (
		chartId: number,
		data: any,
		series: any,
		name: string,
		chartType: number,
		xAxisLabel: string,
		yAxisLabel: string,
		supprtingMessageId: number
	) => void;
}) {
	const [chartName, setChartName] = React.useState(props.name);
	const [chartData, setChartData] = React.useState({
		series: props.series,
		data: transformChartRawData(props.data),
	});
	// const [series, setSeries] = React.useState(props.series);
	// const [data, setData] = React.useState(transformChartRawData(props.data));
	const [labels, setLabels] = React.useState(props.labels);
	const [chartType, setChartType] = React.useState(props.chartType);
	const [xAxisLabel, setXAxisLabel] = React.useState(props.xAxisLabel);
	const [yAxisLabel, setYAxisLabel] = React.useState(props.yAxisLabel);
	const [visible, setVisible] = React.useState(false);
	const [visible2, setVisible2] = React.useState(false);
	const [modalHeader, setModalHeader] = React.useState("Modal Header");
	const [modalText, setModalText] = React.useState(<></>);
	const [changed, setChanged] = React.useState(false);
	const chartMap: { [id: number]: ReportTypesComponentInterface.chartType } =
		{
			1: ReportTypesComponentInterface.chartType.bar,
			2: ReportTypesComponentInterface.chartType.line,
			3: ReportTypesComponentInterface.chartType.pie,
		};
	// React.useEffect(() => {
	// 	setLabels(props.labels);
	// 	setSeries(props.series);
	// 	setData(transformChartRawData(props.data));
	// 	setModalText(
	// 		<FormField6
	// 			data={{
	// 				chartType: chartType,
	// 				xAxisLabel: xAxisLabel,
	// 				yAxisLabel: yAxisLabel,
	// 				labelNames: labels,
	// 				name: chartName,
	// 				series: series,
	// 				chartData: data,
	// 			}}
	// 			addLabel={addLabel}
	// 			deleteLabel={deleteLabel}
	// 			handleSaveData={handleSaveData}
	// 		/>
	// 	);
	// }, []);

	const showModal = () => {
		setModalHeader("Edit Chart");

		setModalText(
			<FormField6
				// onCancel={handleCancel}

				data={{
					chartType: chartType,
					xAxisLabel: xAxisLabel,
					yAxisLabel: yAxisLabel,
					labelNames: labels,
					chartName: chartName,
					series: chartData.series,
					chartData: chartData.data,
				}}
				addLabel={addLabel}
				deleteLabel={deleteLabel}
				// handleSaveSeries={handleSaveSeries}
				handleSaveData={handleSaveData}
				// addData={addRows}
				// handleSave={handleSaveTable}
			/>
		);
		setVisible(true);
	};
	function makeid(length: number) {
		var result = "";
		var characters =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	}

	const handleSave = () => {
		props.handleSave(
			props.id || 0,
			chartData.series,
			chartData.data,
			chartName,
			chartType,
			xAxisLabel,
			yAxisLabel,
			props.supportingMessageId
		);
		setChanged(false);
	};

	// const handleSaveSeries = (value: any) => {
	// 	console.log(value);

	// 	setSeries(value.series);
	// };
	const handleSaveData = (value: any) => {
		setChanged(true);
		console.log("Val 1: ", value);
		value.labelNames.map((label: any) => {
			if (label.identifier !== label.headerName) {
				value.chartData.map((row: any) => {
					row[label.headerName] = Number(row[label.identifier]);
					delete row[label.identifier];
				});
				label.identifier = label.headerName;
			}
		});
		console.log("Val 2: ", value);
		setChartData({ series: value.series, data: value.chartData });
		// setSeries(value.series);
		setChartName(value.chartName);
		setChartType(value.chartType);
		setXAxisLabel(value.xAxisLabel);
		setYAxisLabel(value.yAxisLabel);
		setLabels(value.labelNames);
		// setData(value.chartData);
		setVisible(false);
	};
	const handleCancel = () => {
		setModalText(<></>);
		setVisible(false);
	};
	const addLabel = (uniqueLabel: string) => {
		// const uniqueId = "Label " + makeid(2);
		const temp = {
			id: 0,
			headerName: "",
			identifier: uniqueLabel,
			chartComponentId: 0,
		};
		// console.log("Received values of form:", value);
		const col = labels;
		col.push(temp);
		setLabels(col);
		const tempData = chartData.data;
		tempData.map((row) => (row[uniqueLabel] = 0));
		// setData(tempData);
		setChartData({ series: chartData.series, data: tempData });

		// console.log("T Lab", labels);
		setModalText(
			<FormField6
				// onCancel={handleCancel}

				data={{
					chartType: chartType,
					xAxisLabel: xAxisLabel,
					yAxisLabel: yAxisLabel,
					labelNames: labels,
					name: chartName,
					series: chartData.series,
					chartData: tempData,
				}}
				addLabel={addLabel}
				deleteLabel={deleteLabel}
				// handleSaveSeries={handleSaveSeries}
				handleSaveData={handleSaveData}
				// handleSave={handleSaveTable}
			/>
		);
	};
	const deleteLabel = (id: number) => {
		// console.log(id);
		const name = labels[id].headerName;
		const d = chartData.data;
		d.map((item: any) => delete item[name]);
		// setColumns((prevCols) => prevCols.filter((item, index) => index != id));
		labels.splice(id, 1);
		// setData(d);
		setChartData({ series: chartData.series, data: d });
		// console.log("Deleting", temp);
		setModalText(
			<FormField6
				data={{
					chartType: chartType,
					xAxisLabel: xAxisLabel,
					yAxisLabel: yAxisLabel,
					labelNames: labels,
					name: chartName,
					series: chartData.series,
					chartData: d,
				}}
				addLabel={addLabel}
				deleteLabel={deleteLabel}
				// handleSaveSeries={handleSaveSeries}
				handleSaveData={handleSaveData}
			/>
		);
	};
	function handleBlockNavigation() {
		setVisible2(true);

		return !changed;
	}

	return (
		<>
			<Modal
				destroyOnClose={true}
				title={modalHeader}
				visible={visible}
				onCancel={handleCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				footer={null}
				width={1000}
			>
				{modalText}
			</Modal>
			<Modal
				destroyOnClose={true}
				// title={modalHeader}
				visible={visible2}
				onCancel={() => setVisible2(false)}
				onOk={() => {
					setChanged(false);
					setVisible2(false);
				}}
				okText={"Yes"}
				cancelText={"No"}
				// footer={null}
				width={500}
			>
				Changes not saved. Are you sure you want to navigate away?
			</Modal>

			<Prompt when={changed} message={handleBlockNavigation} />
			{/* {console.log("SERIES ...", series)} */}
			<ReportTypes
				header={chartName}
				handleSave={handleSave}
				handleEdit={showModal}
				hideActions={changed ? [false, false] : [true, false]}
				// subHeader="Sub Header Text"
				// footer="Footer Text"
				minHeight="300px"
				height="320px"
				bodyPadding="5px 24px"
				title={{ title: chartName }}
				chartType={chartMap[chartType]}
				series={chartData.series}
				data={reverseTransformChartProcessedData(chartData.data)}
				axis={{
					primaryX: {
						dim: 0,
						legend: xAxisLabel,
						legendOffset: 40,
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
					},
					primaryY: {
						tickSize: 5,
						tickRotation: 0,
						legend: yAxisLabel,
						legendOffset: -40,
						tickValues: 5,
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
					},
				}}
				chartConfig={{
					cartesian: {
						groupMode: groupMode.grouped,
						grid: {
							enableGridX: false,
							enableGridY: false,
						},
						gaps: { barGap: 0.4, groupGap: 10 },
					},
				}}
				label={{
					position: {
						horzontal:
							ReportTypesComponentInterface
								.labelPositionHorizontal.middle,
						vertical:
							ReportTypesComponentInterface.labelPositionVertical
								.middle,
					},
				}}
				legend={{
					position:
						ReportTypesComponentInterface.legendPosition
							.bottomRight,
					direction:
						ReportTypesComponentInterface.legendDirection.col,
				}}
				style={{
					margin: {
						top: 30,
						right: 100,
						bottom: 50,
						left: 50,
					},
					labelTextColor: "white",
				}}
				// chartArea={{
				// 	layout: "horizontal",
				// }}
			/>
		</>
	);
}
