import { Form, Input, Row, Col, Button, Space, Tabs } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline";
import { Cross } from "@styled-icons/entypo/Cross";
import FormField6 from "./formField6";
import { colors } from "./../../globalTheme";
import "./styled.css";
import React from "react";
const FormField5 = (props: {
	addColumn: any;
	deleteColumn: any;
	data: any;
	// onCancel: any;
	addRows: any;
	handleSave: (value: any) => void;
}) => {
	const onFinish = (values: any) => {
		props.handleSave(values);
	};

	const { TabPane } = Tabs;

	// const [form] = Form.useForm();
	// React.useEffect(() => {
	// 	form.setFieldsValue(props.data);
	// }, [form, props.data]);
	return (
		<>
			{/* {console.log("Form Data", props.data)} */}
			<Form
				preserve={true}
				// layout="inline"
				className="form5"
				name="dynamic_form_nest_item"
				onFinish={onFinish}
				autoComplete="off"
				initialValues={props.data}
			>
				<Row align="middle">
					<Col offset={8} span={3}>
						<p>Table Name</p>
					</Col>
					<Col span={5}>
						<Form.Item
							name="name"
							style={{ marginBottom: "12px" }}
							rules={[
								{
									required: true,
									message: "Missing table name",
								},
							]}
						>
							<Input placeholder="Table Name" />
						</Form.Item>
					</Col>
				</Row>
				<Form.List name="columns">
					{(fields, { add, remove }) => (
						<Row>
							<Col span={23}>
								<div
									style={{
										width: "100%",
										display: "flex",
										alignContent: "center",
										justifyContent: "space-evenly",
									}}
								>
									{fields.map(
										({
											key,
											name,
											fieldKey,
											...restField
										}) => (
											<div
												style={{
													border: "1px solid black",
													background: colors.zsGray10,
													display: "flex",
													justifyContent: "center",
													// padding: "4px 8px",
												}}
											>
												{/* {console.log(fields)} */}
												<Form.Item
													className="tableFormField"
													{...restField}
													name={[name, "title"]}
													fieldKey={[
														fieldKey,
														"title",
													]}
													rules={[
														{
															required: true,
															message:
																"Missing column",
														},
													]}
												>
													<Input
														placeholder="Column Name"
														style={{
															border: "none",
															background:
																colors.zsGray10,
															fontWeight: 700,
														}}
													/>
												</Form.Item>

												<Cross
													title="Delete Column"
													style={{
														cursor: "pointer",
													}}
													size={20}
													//   color="red"
													onClick={() => {
														remove(name);
														props.deleteColumn(
															name
														);
													}}
												/>
												{/* </Space> */}
											</div>
										)
									)}
								</div>
							</Col>
							<Col span={1}>
								<Form.Item>
									<AddCircleOutline
										size={18}
										style={{ marginLeft: "4px" }}
										onClick={() => {
											props.addColumn();
										}}
									/>
									<p style={{ fontSize: "0.6rem" }}>
										Columns
									</p>
								</Form.Item>
							</Col>
						</Row>
					)}
				</Form.List>
				{/* <Form.Item>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item> */}

				{/* {console.log("Data", props.columns)} */}
				<Form.List name="tableData">
					{(fields, { add, remove }) => (
						<>
							{fields.map(
								({ key, name, fieldKey, ...restField }) => (
									<>
										<Row align="top" gutter={[8, 0]}>
											{/* <Col span={2}>Row {index + 1}</Col> */}

											<Col span={23}>
												<div
													style={{
														display: "flex",
														height: "auto",
														padding: "0px 0px",
														justifyContent:
															"space-evenly",
													}}
												>
													{props.data.columns.map(
														(col: any) => {
															return (
																<Form.Item
																	className="tableFormField"
																	{...restField}
																	name={[
																		name,
																		col.accessor,
																	]}
																	fieldKey={[
																		fieldKey,
																		"text",
																	]}
																	style={{
																		marginBottom:
																			"0px !important",
																	}}
																	rules={[
																		{
																			required:
																				true,
																			message:
																				"Missing field ",
																		},
																	]}
																>
																	<Input />
																</Form.Item>
															);
														}
													)}
												</div>
											</Col>

											<Col span={1}>
												<MinusCircleOutlined
													title="Delete Row"
													style={{
														cursor: "pointer",
													}}
													onClick={() => remove(name)}
												/>
											</Col>
										</Row>
									</>
								)
							)}
							<Form.Item>
								<Button
									style={{
										border: "none",
										background: "transparent !important",
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => add()}
									block
									icon={<PlusOutlined />}
								>
									Add Row
								</Button>
							</Form.Item>
							<Form.Item>
								<Button type="primary" htmlType="submit">
									Update Table
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>
			</Form>
		</>
	);
};

export default FormField5;
