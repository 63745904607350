import styled from "styled-components";
import { colors } from "./../../../globalTheme";

export const TileContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: ${colors.zsWhite};
	border-radius: 10px;
`;
export const TileHeader = styled.div`
	padding: 0em 0.8em;
	text-align: center;
	border-bottom: 1px solid ${colors.zsShadow};
	/* font-family: 'Gelasio', serif; */
	text-transform: capitalize;
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 4rem;
	/* letter-spacing: 2px; */
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	.icon {
		z-index: 1;
		&:hover {
			transform: scale(110%);
			color: ${colors.zsOrange};
			cursor: pointer;
		}
	}
`;
export const TileBody = styled.div`
	padding: 1.6em 1.4em;
	text-align: left;
	min-height: 7em;
`;
export const AddBrandContainer = styled.div`
	cursor: pointer;
	/* background-color: red; */
	min-height: 12.3em;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	p {
		text-align: center;
	}
	border-radius: 10px;
`;

export const TileFooter = styled.div`
	padding: 0em 1.8em;
	text-align: left;
	display: flex;
	justify-content: space-between;
	color: ${colors.zsGray30};

	h3 {
		font-weight: 600;
		font-size: 0.8rem;
		display: inline;
		color: ${colors.zsGray30};
	}
	p {
		font-weight: 400;
		display: inline;
		margin: 0;
		color: ${colors.zsTeal};
	}
`;
