import { ResponsiveHeatMap } from "@nivo/heatmap";
import * as ReportTypesComponentProps from "./../../interfaces/main";
import transformData from "./transform";

const theme = (textColor?: string, background?: string, fontSize?: number) => {
  return {
    background: background || "white",
    textColor: textColor || "black",
    fontSize: fontSize || 11,
  };
};
/**
 * Renders the Responsive Heat Map Component. The component makes use of Nivo Charts Library.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @param props - Chart Config for the Heat Map Component
 *
 * @returns Heat Map Component of Nivo
 */
const HeatMap: React.FC<ReportTypesComponentProps.Interface> = (
  props: ReportTypesComponentProps.Interface
): JSX.Element => (
  <>
    <ResponsiveHeatMap
      data={transformData(
        props.data,
        props.series,
        props.axis?.primaryX.dim || 0
      )}
      keys={props.series.map((s) => s.name)}
      colors={
        props.chartConfig.table?.heatColorArray || [
          "#dbb667",
          "#d4a43b",
          "#c28502",
          "#c25802",
          "#c23802",
        ]
      }
      minValue={props.chartConfig.table?.minHeatValue}
      maxValue={props.chartConfig.table?.maxHeatValue}
      forceSquare={props.chartConfig.table?.forceSquare}
      sizeVariation={props.chartConfig.table?.intraction?.cellSizeVariation}
      padding={props.chartConfig.table?.cellPadding}
      theme={theme(
        props.style?.textColor,
        props.chartArea?.background,
        props.style?.fontSize
      )}
      cellShape={props.chartConfig.table?.cellShape}
      cellBorderWidth={props.style?.borderWidth}
      enableLabels={props.label ? true : false}
      enableGridX={props.chartConfig.table?.grid?.enableGridX}
      enableGridY={props.chartConfig.table?.grid?.enableGridY}
      margin={
        props.style?.margin || {
          top: 20,
          right: 50,
          bottom: 50,
          left: 50,
        }
      }
      axisTop={props.axis?.primaryX || null}
      axisLeft={props.axis?.primaryY || null}
      cellBorderColor={
        props.style?.borderColor || {
          from: "color",
          modifiers: [["darker", 0.4]],
        }
      }
      labelTextColor={
        props.style?.labelTextColor || {
          from: "color",
          modifiers: [["darker", 1.8]],
        }
      }
      isInteractive={props.tooltip?.isInteractive}
      hoverTarget={
        props.chartConfig.table?.intraction?.hoverHighlight || "rowColumn"
      }
      animate={props.animate}
      motionStiffness={80}
      motionDamping={9}
      cellOpacity={props.chartConfig.table?.intraction?.cellHoverOpacity}
      cellHoverOthersOpacity={
        props.chartConfig.table?.intraction?.cellHoverOtherOpacity
      }
    />
  </>
);
export default HeatMap;
