import React from "react";
import { TileContainer, TileHeader, TileIcon, TileLink } from "./style";
import { headerIcons } from "../../globalTheme";
import AppContext from "../../store/index";
import Avatar from "antd/lib/avatar/avatar";
import { StyledTooltip } from "../keyMessage/style";

export interface Interface {
	data: {
		referenceHeader: string;
		referenceLink: string;
	};
	index: number;
}
export default function Tile2(props: Interface) {
	return (
		<>
			<TileContainer>
				{/* <Avatar
          size="small"
          style={{
            marginTop: "-35px",
            marginLeft: "-10px",
            color: "#f56a00",
            backgroundColor: "#fde3cf",
          }}
        > */}
				<p style={{ fontWeight: "bold", margin: 0, color: "#27A6A4" }}>
					{props.index + 1}
				</p>

				{/* </Avatar> */}
				{/* <TileIcon>
					{headerIcons.find((ref) => ref.id === "References")?.icon}
				</TileIcon> */}
				<TileHeader>{props.data.referenceHeader}</TileHeader>
				<TileLink>
					{props.data.referenceLink !== null &&
						props.data.referenceLink !== "" && (
							<StyledTooltip
								title={
									<>
										<span
											style={{
												marginBottom: 0,
											}}
										>
											{props.data.referenceLink}
										</span>
									</>
								}
								// title={"Refs"}
								// className="reference-tooltip"
							>
								<a
									href={props.data.referenceLink}
									target="_blank"
								>
									{
										headerIcons.find(
											(ref) => ref.id === "link"
										)?.icon
									}
								</a>
							</StyledTooltip>
						)}
				</TileLink>
			</TileContainer>
		</>
	);
}
