import { request } from "./../layout";

export const getAllBrandsData = async () => {
	// console.log("Inside Service");
	const fetchUrl = "brand/getallbrand";
	const data = await request
		.get(
			fetchUrl,
			{ withCredentials: true }
			// {
			// headers: {
			// 	"Access-Control-Allow-Origin": "http://localhost:3000",
			// },
			// }
		)
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	// const data = [
	// 	{
	// 		id: 17,
	// 		name: "biogen",
	// 		description: "biogen description",
	// 		createdBy: "Himanshu Chavan",
	// 		createdAt: "2021-11-09T16:36:36.533958",
	// 		lastUpdatedBy: null,
	// 		lastUpdatedAt: "2021-11-09T16:36:36.533961",
	// 		userRole: [
	// 			{
	// 				userId: "aa20384",
	// 				rolesId: 2,
	// 			},
	// 			{
	// 				userId: "ms30001",
	// 				rolesId: 1,
	// 			},
	// 			{
	// 				userId: "hc31992",
	// 				rolesId: 2,
	// 			},
	// 		],
	// 	},
	// ];
	return data;
};

export const addNewBrandData = async (brandData: {
	name: string;
	desription: string;
	createdBy: string;
	userRole: {
		userId: string;
		rolesId: number;
	}[];
}) => {
	let fetchUrl = "brand/AddBrand";
	// console.log(brandData);
	const data = await request
		.post(fetchUrl, brandData, { withCredentials: true })
		.then((response) => {
			console.log(response);
			return response.status;
		})
		.catch((err) => {
			console.log(err.message, "error here");
			return null;
		});

	return data;
};
export const deleteBrandData = async (id: number, productId: number) => {
	let fetchUrl = "brand/DeleteBrand/" + id;

	const data = await request
		.delete(fetchUrl, { withCredentials: true })
		.then((response) => {
			console.log(response);
			return response.status;
		})
		.catch((err) => {
			console.log(err.message, "error here");
			return null;
		});

	return data;
};

export const updateBrandData = async (updatedBrandData: {
	id: number;
	name: string;
	desription: string;
	lastUpdatedBy: string;
	userRole: {
		userId: string;
		rolesId: number;
	}[];
}) => {
	let fetchUrl = "brand/EditBrand";
	console.log(updatedBrandData);
	const data = await request
		.patch(
			fetchUrl,
			updatedBrandData,
			{ withCredentials: true }
			// {
			// headers: {
			// 	"x-api-key": process.env.REACT_APP_API_KEY,
			// },
			// }
		)
		.then((response) => {
			console.log(response);
			return response.status;
		})
		.catch((err) => {
			console.log(err.message, "error here");
			return null;
		});

	return data;
};
