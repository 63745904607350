import { request } from "./../layout";
export const addSubSection = async (value: any, productId: number) => {
	const fetchUrl = "SubSection/AddSubsection?BrandId=" + productId;
	const data = await request
		.post(fetchUrl, value, { withCredentials: true })
		.then((response) => {
			console.log("Response...", response);

			return response.data;
		})
		.catch((err) => {
			console.log("Error : ", err.message);
			return null;
		});

	// console.log("Fetching...", fetchUrl);
	//   const data = await axios
	//     .post(fetchUrl, { withCredentials: true })
	//     .then((response) => {
	//       return response.data;
	//     })
	//     .catch((err) => {
	//       console.log("Error : ", err.message);
	//       return null;
	//     });

	return data;
};

export const editSubSection = async (
	value: any,
	productId: number,
	subsectionId: number
) => {
	const fetchUrl =
		"SubSection/EditSubsection?BrandId=" +
		productId +
		"&subsectionId=" +
		subsectionId;
	const data = await request
		.patch(fetchUrl, value, { withCredentials: true })
		.then((response) => {
			console.log("Response...", response);

			return response.data;
		})
		.catch((err) => {
			console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const deleteSubSection = async (
	productId: number,
	subsectionId: number
) => {
	const fetchUrl =
		"SubSection/DeleteSubsection?BrandId=" +
		productId +
		"&subsectionId=" +
		subsectionId;
	const data = await request
		.delete(fetchUrl, { withCredentials: true })
		.then((response) => {
			console.log("Response...", response);

			return response.data;
		})
		.catch((err) => {
			console.log("Error : ", err.message);
			return null;
		});
	return data;
};
