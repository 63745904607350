import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { ChevronLeft } from "@styled-icons/boxicons-regular/ChevronLeft";
import { ChevronRight } from "@styled-icons/boxicons-regular/ChevronRight";
// import AppContext from "../../store";
import {
  Logo,
  SideBarItem,
  SideBarItems,
  SideBarText,
  StyledSideBar,
  ToggleIcon,
  ZSLogo,
  SideBarBottom,
  SideBarTop,
  SideBarsubNavCollapsedItem,
  SideBarsubNavItem,
  SideBarSubNavText,
  SideBarStaticItem,
} from "./style";
import { headerIcons } from "../../globalTheme";
import { Button, Tooltip } from "antd";
import { Menu } from "antd";
/**
 * Renders the Collapsible SideBar of the Layout.
 *
 * @param tabNames - Array Containing Names of the Menus and their Icons
 *
 * @returns SideBar of the Layout
 */
export default function SideBar(props: {
  tabNames: {
    name: string;
    path: string;
    icon: JSX.Element;
    subPath?: string;
    subTabs?: { name: string; path: string; icon: JSX.Element }[];
  }[];
  viewSelected: any;
}) {
  const [sideBarStatus, setSideBarStatus] = React.useState(true);
  const [menuItemSelected, setMenuItemSelected] = React.useState(
    window.sessionStorage.getItem("topNav") || "Introduction"
  );
  const history = useHistory();

  function handleMenuClick(e: any) {
    setMenuItemSelected(e.key);
    if (e.key === "Methodology") {
      window.sessionStorage.setItem("subNav", "DataCollection");
      history.push(
        "/" +
          window.sessionStorage.getItem("selectedBackground") +
          "/" +
          e.key +
          "/DataCollection"
      );
    } else if (e.key === "Results") {
      window.sessionStorage.setItem("subNav", "Prisma");
      history.push(
        "/" +
          window.sessionStorage.getItem("selectedBackground") +
          "/" +
          e.key +
          "/Prisma"
      );
    } else {
      history.push(
        "/" + window.sessionStorage.getItem("selectedBackground") + "/" + e.key
      );
      window.sessionStorage.setItem("subNav", "");
    }
  }

  function handleSubMenuClick(e: any) {
    history.push(
      "/" +
        window.sessionStorage.getItem("selectedBackground") +
        "/" +
        menuItemSelected +
        "/" +
        e.key
    );
    window.sessionStorage.setItem("subNav", e.key);
  }

  return (
    <>
      <StyledSideBar
        collapsed={sideBarStatus}
        // onMouseLeave={() => setSideBarStatus(true)}
        // onMouseEnter={() => setSideBarStatus(false)}
      >
        <Logo>
          <ZSLogo>
            {sideBarStatus && (
              <img
                className="zsLogo"
                src="/logo/ZSLogoSmall.png"
                alt="ZS Logo"
                style={{
                  marginLeft: "1.2rem",
                  height: "3rem",
                  objectFit: "cover",
                }}
              />
            )}

            {!sideBarStatus && (
              <img
                className="zsLogo"
                src="/logo/ZSLogoFull.png"
                alt="ZS Logo"
                style={{
                  marginLeft: "1.2rem",
                  height: "3rem",
                  objectFit: "cover",
                }}
              />
            )}
          </ZSLogo>
          <ToggleIcon>
            {!sideBarStatus && (
              <ChevronLeft size={24} onClick={() => setSideBarStatus(true)} />
            )}
            {sideBarStatus && (
              <ChevronRight size={24} onClick={() => setSideBarStatus(false)} />
            )}
          </ToggleIcon>
        </Logo>
        {sideBarStatus && (
          <>
            <SideBarTop>
              <SideBarItems>
                {console.log("tabnames", props)}
                {props.tabNames.map((tab, index) => {
                  if (tab.name === "Appendix") return <></>;
                  if (tab.name === "Conclusion")
                    return (
                      <div className="mb-0">
                        <SideBarStaticItem>
                          <NavLink
                            to={
                              "/SLR/" +
                              window.sessionStorage.getItem(
                                "selectedBackground"
                              ) +
                              "/Conclusion"
                            }
                            activeClassName="active"
                          >
                            <Tooltip placement="right" title={"Conclusion"}>
                              {
                                props.tabNames.find(
                                  (header) => header.name === "Conclusion"
                                )?.icon
                              }
                            </Tooltip>
                          </NavLink>
                        </SideBarStaticItem>
                      </div>
                    );
                  if (tab.name === "References") return <></>;
                  if (tab.name === "Introduction") {
                    return (
                      <div className="mb-0">
                        <SideBarStaticItem>
                          <NavLink
                            to={
                              "/SLR/" +
                              window.sessionStorage.getItem(
                                "selectedBackground"
                              ) +
                              "/Introduction"
                            }
                            activeClassName="active"
                            defaultValue={1}
                          >
                            <Tooltip placement="right" title={tab.name}>
                              {tab.icon}
                            </Tooltip>
                          </NavLink>
                        </SideBarStaticItem>
                      </div>
                    );
                  }
                  if (tab.name === "Executive Summary") {
                    return (
                      <div className="mb-0">
                        <SideBarStaticItem>
                          <NavLink
                            to={"/SLR/ExecutiveSummary"}
                            activeClassName="active"
                            defaultValue={1}
                          >
                            <Tooltip placement="right" title={tab.name}>
                              {tab.icon}
                            </Tooltip>
                          </NavLink>
                        </SideBarStaticItem>
                      </div>
                    );
                  }
                  if (tab.name === "Feasibility Assessment") {
                    return (
                      <div className="mb-0">
                        <SideBarStaticItem>
                          <NavLink
                            to={
                              "/SLR/" +
                              window.sessionStorage.getItem(
                                "selectedBackground"
                              ) +
                              "/Feasibility"
                            }
                            activeClassName="active"
                            defaultValue={1}
                          >
                            <Tooltip placement="right" title={tab.name}>
                              {tab.icon}
                            </Tooltip>
                          </NavLink>
                        </SideBarStaticItem>
                      </div>
                    );
                  }
                  return (
                    <div className="mb-1">
                      <SideBarStaticItem>
                        <NavLink
                          to={
                            "/SLR/" +
                            window.sessionStorage.getItem(
                              "selectedBackground"
                            ) +
                            "/" +
                            tab.path +
                            "/" +
                            tab.subPath
                          }
                          activeClassName=""
                          defaultValue={0}
                        >
                          <Tooltip placement="right" title={tab.name}>
                            {tab.icon}
                          </Tooltip>
                        </NavLink>
                      </SideBarStaticItem>

                      <div>
                        {tab.subTabs?.map((subTab, i) => {
                          return (
                            <SideBarsubNavCollapsedItem>
                              <NavLink
                                to={
                                  subTab.name === "Background"
                                    ? "/SLR/background"
                                    : "/SLR/" +
                                      window.sessionStorage.getItem(
                                        "selectedBackground"
                                      ) +
                                      "/" +
                                      tab.path +
                                      "/" +
                                      subTab.path
                                }
                                activeClassName="active"
                                defaultValue={1}
                              >
                                <Tooltip placement="right" title={subTab.name}>
                                  <div className="px-2">{subTab.icon}</div>
                                </Tooltip>
                              </NavLink>
                            </SideBarsubNavCollapsedItem>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </SideBarItems>
            </SideBarTop>
          </>
        )}
        {!sideBarStatus && (
          <>
            <SideBarTop>
              <SideBarItems>
                {props.tabNames.map((tab, index) => {
                  if (tab.name === "Appendix") return <></>;
                  if (tab.name === "Conclusion")
                    return (
                      <div className="">
                        <SideBarStaticItem>
                          <NavLink
                            to={
                              "/SLR/" +
                              window.sessionStorage.getItem(
                                "selectedBackground"
                              ) +
                              "/Conclusion"
                            }
                            activeClassName="active"
                          >
                            {tab.icon} <SideBarText>{tab.name}</SideBarText>
                          </NavLink>
                        </SideBarStaticItem>
                      </div>
                    );
                  if (tab.name === "References") return <></>;
                  if (tab.name === "Executive Summary") {
                    return (
                      <div className="mb-0">
                        <SideBarStaticItem>
                          <NavLink
                            to={"/SLR/ExecutiveSummary"}
                            activeClassName="active"
                            defaultValue={1}
                          >
                            {tab.icon} <SideBarText>{tab.name}</SideBarText>
                          </NavLink>
                        </SideBarStaticItem>
                      </div>
                    );
                  }
                  if (tab.name === "Introduction") {
                    return (
                      <div className="mb-0">
                        <SideBarStaticItem>
                          <NavLink
                            to={
                              "/SLR/" +
                              window.sessionStorage.getItem(
                                "selectedBackground"
                              ) +
                              "/Introduction"
                            }
                            activeClassName="active"
                            defaultValue={1}
                          >
                            {tab.icon} <SideBarText>{tab.name}</SideBarText>
                          </NavLink>
                        </SideBarStaticItem>
                      </div>
                    );
                  }
                  if (tab.name === "Feasibility Assessment") {
                    return (
                      <div className="mt-1">
                        <SideBarStaticItem>
                          <NavLink
                            to={
                              "/SLR/" +
                              window.sessionStorage.getItem(
                                "selectedBackground"
                              ) +
                              "/Feasibility"
                            }
                            activeClassName="active"
                            defaultValue={1}
                          >
                            {tab.icon} <SideBarText>{tab.name}</SideBarText>
                          </NavLink>
                        </SideBarStaticItem>
                      </div>
                    );
                  }
                  return (
                    <div className="mt-0">
                      <SideBarStaticItem>
                        <NavLink
                          to={
                            "/SLR/" +
                            window.sessionStorage.getItem(
                              "selectedBackground"
                            ) +
                            "/" +
                            tab.path +
                            "/" +
                            tab.subPath
                          }
                          activeClassName=""
                          defaultValue={0}
                        >
                          {tab.icon} <SideBarText>{tab.name}</SideBarText>
                        </NavLink>
                      </SideBarStaticItem>

                      <div>
                        {tab.subTabs?.map((subTab, i) => {
                          return (
                            <SideBarsubNavItem>
                              <NavLink
                                to={
                                  subTab.name === "Background"
                                    ? "/SLR/background"
                                    : "/SLR/" +
                                      window.sessionStorage.getItem(
                                        "selectedBackground"
                                      ) +
                                      "/" +
                                      tab.path +
                                      "/" +
                                      subTab.path
                                }
                                activeClassName="active"
                                defaultValue={1}
                              >
                                <div className="flex items-center pl-1">
                                  {subTab.icon}
                                  <SideBarSubNavText>
                                    {subTab.name}
                                  </SideBarSubNavText>
                                </div>
                              </NavLink>
                            </SideBarsubNavItem>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </SideBarItems>
            </SideBarTop>
          </>
        )}
      </StyledSideBar>
    </>
  );
}
