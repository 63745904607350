import { Virus } from "@styled-icons/remix-fill/Virus";
import { Pills } from "@styled-icons/fa-solid/Pills";
import { InfoWithCircle } from "@styled-icons/entypo/InfoWithCircle";
import { Body } from "@styled-icons/ionicons-solid/Body";
import { Gift2 } from "@styled-icons/remix-fill/Gift2";
import { HandCoin } from "@styled-icons/remix-fill/HandCoin";
import { Building } from "@styled-icons/remix-fill/Building";
import { TextBulletListSquareEdit } from "@styled-icons/fluentui-system-filled/TextBulletListSquareEdit";
import { HeadSideVirus } from "@styled-icons/fa-solid/HeadSideVirus";
import { DocumentBulletList } from "@styled-icons/fluentui-system-filled/DocumentBulletList";
import { Processingfoundation } from "@styled-icons/simple-icons/Processingfoundation";
import { EyeFill } from "@styled-icons/bootstrap/EyeFill";
import { NotesMedical } from "@styled-icons/fa-solid/NotesMedical";
import { History } from "@styled-icons/material/History";
import { FileMedical } from "@styled-icons/fa-solid/FileMedical";
import { Opslevel } from "@styled-icons/simple-icons/Opslevel";
import { FlowTree } from "@styled-icons/entypo/FlowTree";
import { HandHoldingMedical } from "@styled-icons/fa-solid/HandHoldingMedical";
import { BurstNew } from "@styled-icons/foundation/BurstNew";
import { BookReference } from "@styled-icons/zondicons/BookReference";
import { CircleRight } from "@styled-icons/icomoon/CircleRight";
import { CashCoin } from "@styled-icons/bootstrap/CashCoin";
import { Man } from "@styled-icons/icomoon/Man";
import { HospitalUser } from "@styled-icons/fa-solid/HospitalUser";
import { SquaredCross } from "@styled-icons/entypo/SquaredCross";
import { OpenBook } from "@styled-icons/entypo/OpenBook";
import { ClipboardDataBar } from "@styled-icons/fluentui-system-regular/ClipboardDataBar";
import { Science } from "@styled-icons/material-twotone/Science";
import { CoinStack } from "@styled-icons/boxicons-solid/CoinStack";
import { Savings } from "@styled-icons/fluentui-system-filled/Savings";
import { CashRegister } from "@styled-icons/fa-solid/CashRegister";
import { Doctor } from "@styled-icons/fluentui-system-filled/Doctor";
import { BuildingShop } from "@styled-icons/fluentui-system-regular/BuildingShop";
import { BookReader } from "@styled-icons/boxicons-regular/BookReader";
import { Law } from "@styled-icons/octicons/Law";
import { GearFill } from "@styled-icons/bootstrap/GearFill";
import { BookLetter } from "@styled-icons/fluentui-system-filled/BookLetter";
import { SiAcademia } from "react-icons/si";
import { Coins } from "@styled-icons/remix-fill/Coins";
import { QuestionCircleFill } from "@styled-icons/bootstrap/QuestionCircleFill";
import { Message } from "@styled-icons/material-rounded/Message";
import { ImageNotSupported } from "@styled-icons/material-outlined/ImageNotSupported";
import { SmsFailed } from "@styled-icons/material-twotone/SmsFailed";
import { Alert } from "@styled-icons/foundation/Alert";
import { ReactNode } from "react";

export const colors = {
  zsOrange: "#EC7200",
  zsTeal: "#27A6A4",
  zsWhite: "#FFF",
  zsGray100: "#1A1628",
  zsGray80: "#454250",
  zsGray90: "#2F2C3C",
  zsGray30: "#b8b8b8",
  zsGray20: "#DEDCDE",
  zsGray10: "#F4F3F3",
  zsShadow: "#bebebe",
  zsBodyBg: "#F4F3F3",
  zsDataLightBlue: "#3287C4",
  zsDataOrange: "#DB6C03",
  zsDataGreen: "#7FD07D",
  zsDataPurple: "#8F5AFF",
  zsDataYellow: "#EAC959",
};

export const headerIcons = [
  {
    id: "link",
    icon: <CircleRight size={18} />,
  },
  {
    id: "default",
    icon: <EyeFill size={24} />,
  },
  {
    id: "Executive Summary",
    icon: <DocumentBulletList size={24} />,
  },
  {
    id: "Disease Overview",
    icon: <Body size={24} />,
  },
  {
    id: "Current Treatment Landscape",
    icon: <Pills size={24} />,
  },
  {
    id: "Burden of Illness & Unmet Need",
    icon: <HospitalUser size={24} />,
  },
  {
    id: "Clinical Presentation",
    icon: <NotesMedical size={24} />,
  },
  {
    id: "Product Information",
    icon: <InfoWithCircle size={24} />,
  },
  {
    id: "Economic Value",
    icon: (
      <Coins size={32} style={{ marginLeft: "-3px", marginRight: "-5px" }} />
    ),
  },
  {
    id: "Market Access",
    icon: <HandHoldingMedical size={24} />,
  },
  {
    id: "Objection Handler",
    icon: <QuestionCircleFill size={22} />,
  },
  {
    id: "Abbreviations",
    icon: (
      <SiAcademia size={18} style={{ marginLeft: "4px", marginTop: "4px" }} />
    ),
  },
  {
    id: "References",
    icon: <BookReference size={22} />,
  },
  {
    id: "ReferencesSmall",
    icon: <BookReference size={12} />,
  },
  {
    id: "Settings",
    icon: <GearFill size={24} />,
  },
  {
    id: "Epidemiology",
    icon: <EyeFill size={24} />,
  },
  {
    id: "Pathophysiology",
    icon: <Processingfoundation size={24} />,
  },
  {
    id: "Clinical Presentation",
    icon: <NotesMedical size={24} />,
  },
  {
    id: "Natural History",
    icon: <History size={24} />,
  },
  {
    id: "Diagnosis",
    icon: <FileMedical size={24} />,
  },
  {
    id: "Current Standard",
    icon: <Opslevel size={24} />,
  },
  {
    id: "Current Treatment Options",
    icon: <FlowTree size={24} />,
  },
  {
    id: "Management",
    icon: <HandHoldingMedical size={24} />,
  },
  {
    id: "Emerging Therapies",
    icon: <BurstNew size={24} />,
  },
  {
    id: "Clinical Burden",
    icon: <HospitalUser size={24} />,
  },
  {
    id: "Humanistic Burden",
    icon: <Man size={24} />,
  },
  {
    id: "Economic Burden",
    icon: <CashCoin size={24} />,
  },
  {
    id: "Unmet Need",
    icon: <SquaredCross size={24} />,
  },

  {
    id: "Pharmacological Description",
    icon: <OpenBook size={24} />,
  },
  {
    id: "Pre-Clinical Data",
    icon: <ClipboardDataBar size={24} />,
  },
  {
    id: "Clinical Development Program",
    icon: <Science size={26} />,
  },

  {
    id: "Cost Effectiveness Model",
    icon: <CoinStack size={24} />,
  },
  {
    id: "Budget Impact Model",
    icon: <Savings size={24} />,
  },
  {
    id: "Economic Evaluations",
    icon: <CashRegister size={23} />,
  },
  {
    id: "QALY",
    icon: <Doctor size={24} />,
  },

  {
    id: "Market Access",
    icon: <HandHoldingMedical size={24} />,
  },
  {
    id: "Preparing For Objections",
    icon: <BookReader size={24} />,
  },
  {
    id: "Objection Handler Overview",
    icon: <Law size={24} />,
  },
  {
    id: "Key Message",
    icon: <Message size={16} />,
  },
  {
    id: "No Image",
    Icon: <ImageNotSupported size={20} />,
  },
  {
    id: "No Data",
    Icon: <SmsFailed size={50} color="#27a4a6" />,
  },
  {
    id: "Alert",
    icon: <Alert size={100} color="#27a4a6" />,
  },
];
