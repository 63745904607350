import Card from "../card";
import * as CardComponentProps from "../card";
import * as ReportTypesComponentProps from "./charts/interfaces/main";
import * as RadialChart from "./charts/radialChart";
import * as CartesianChart from "./charts/cartesianChart";
import * as Table from "./charts/table";
import * as GeoMap from "./charts/geoMap";

/**
 * Renders the chosen Chart Component inside a Card
 *
 * @param props - The props for the Card and Chart Components
 *
 * @returns Chosen Chart Component inside a Card
 */
export default function ReportTypes(
	props: CardComponentProps.Interface & ReportTypesComponentProps.Interface
) {
	return (
		<Card
			header={props.header}
			subHeader={props.subHeader}
			footer={props.footer}
			minHeight={props.minHeight}
			height={props.height}
			bodyPadding={props.bodyPadding}
			// hideActions
			// hideRibbon
			{...props}
		>
			{props.chartType === 0 && <CartesianChart.BarChart {...props} />}
			{props.chartType === 1 && <CartesianChart.LineChart {...props} />}
			{props.chartType === 2 && <RadialChart.PieChart {...props} />}
			{props.chartType === 3 && <Table.HeatMap {...props} />}
			{props.chartType === 5 && (
				<Table.SimpleTable
					tableData={props.chartConfig.table?.tableData}
					rows={props.chartConfig.table?.rows}
				/>
			)}
			{props.chartType === 6 && <GeoMap.Choropleth {...props} />}
		</Card>
	);
}
export {
	ReportTypesComponentProps,
	Card,
	CardComponentProps,
	CartesianChart,
	RadialChart,
	Table,
	GeoMap,
};
