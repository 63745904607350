import { ResponsiveChoropleth } from "@nivo/geo";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import { message, Modal } from "antd";
import * as ServiceHelper from "../service-helper";
import FormField1 from "../../../libraries/form-fields/formField1";
import FormField4 from "../../../libraries/form-fields/formField4";
import AppContext from "../../../store";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import "./style.css";
import countryList from "../../../countryList.json";
import EvidenceAtlas from "./EvidenceAtlas";
import DataTable from "./DataTable";

export default function SupportingMessageEvidence(props: {
  showReturn?: React.Dispatch<React.SetStateAction<string>>;
  layout?: string;
  grid?: number;
  type?: string;
  subSectionId?: number;
  sectionId: number;
  productId?: number;
  noKeyMessage?: boolean;
  // returnLink: string;
}) {
  //@ts-ignore
  let { tab, subtab, cardId, key } = useParams();
  if (props.showReturn) props.showReturn("/" + tab + "/" + subtab);
  // console.log("Tab : " + tab + " , Subtab : " + subtab + " , Key : " + key);

  const context = React.useContext(AppContext);
  const [fetchedData, setFetchedData] = React.useState<any>([]);
  const [template, setTemplate] = React.useState<number>(1);
  const [tableData, setTableData] = React.useState<any>([]);
  const [initialTableData, setInitialTableData] = React.useState<any>([]);
  const [evidenceAtlasData, setEvidenceAtlasData] = React.useState<any>([]);
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("Modal Header");
  const [modalText, setModalText] = React.useState(<></>);
  const [renderTableData, setRenderTableData] = React.useState<any>([]);
  const [subsectionId, setSubsectionId] = React.useState(0);
  const [saveCounter, setSaveCounter] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [selectedKey, setSelectedKey] = React.useState("1");
  const [maxDomainLength, setMaxDomainLength] = React.useState(0);
  const [refArray, setRefArray] = React.useState<
    {
      id: number;
      referenceHeader: string;
      referenceLink: string;
    }[]
  >([]);

  async function fetchData(keyMessageId: number) {
    setLoading(true);
    const d: any = await ServiceHelper.getSupportingMessageEvidenceAtlasData(
      keyMessageId
    );

    if (d.length > 0) {
      // d.map((item: any, index: any) => (item.key = index)); //comment out
      console.log(d);
      d.sort((a: any, b: any) => a.key - b.key);
      setTableData(d);
      setInitialTableData(d);

      //for Epidemiology Data by Country data
      let evidenceAtlasDataTemp: any = {};
      for (let i = 0; i < d.length; i++) {
        //get all countries and create data in following format
        if (d[i]["countries"]) {
          let countries =
            d[i]["countries"]?.replaceAll(", ", "***").split("***") || [];
          countries?.map((country: any) => {
            evidenceAtlasDataTemp[country] =
              (evidenceAtlasDataTemp[country] || 0) + 1;
          });
        }
      }
      let evidenceAtlasDataTemp2: any = [];
      let countries = Object.keys(evidenceAtlasDataTemp);
      let tempMaxDomainLength = 6;
      countries?.map((country: any) => {
        let listStudies: any = [];
        for (let i = 0; i < d.length; i++) {
          if (d[i].countries.includes(country)) {
            listStudies.push(d[i].publication);
          }
        }
        tempMaxDomainLength =
          listStudies.length > tempMaxDomainLength
            ? listStudies.length
            : tempMaxDomainLength;
        evidenceAtlasDataTemp2.push({
          country: country,
          value: evidenceAtlasDataTemp[country],
          studies: listStudies,
        });
      });
      evidenceAtlasDataTemp2?.map((data: any) => {
        let obj = countryList.countryList.find(
          (o: any) => o.country === data.country
        );
        data["id"] = obj?.code;
      });
      tempMaxDomainLength =
        tempMaxDomainLength + (6 - (tempMaxDomainLength % 6));
      setEvidenceAtlasData([...evidenceAtlasDataTemp2]);
      setMaxDomainLength(tempMaxDomainLength);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (saveCounter > 0) {
      setFetchedData([]);
      fetchData(key);
    }
  }, [key, saveCounter]);

  React.useEffect(() => {
    setFetchedData([]);
    fetchData(key);
  }, []);

  React.useEffect(() => {
    console.log("Subsection Name", subtab);
    const sec = context.subSectionMapping.find((section) =>
      section.subSection.find(
        (subSection) =>
          subSection.name.toLowerCase().split(" ").join("_") ===
          subtab.toLowerCase()
      )
    );
    console.log("Subsection Name 1", sec);
    setSubsectionId(
      sec?.subSection.find((subSection) => {
        return (
          subSection.name.toLowerCase().split(" ").join("_") ===
          subtab.toLowerCase()
        );
      })?.id || 0
    );
  }, []);

  const handleOk = () => {
    setModalText(<p>Saving</p>);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1500);
  };
  const handleCancel = () => {
    setModalText(<></>);
    setVisible(false);
  };
  const override = css`
    display: block;
    margin-left: ${props.layout === "ObjectionHandler" ? "25vw" : "43vw"};
    margin-top: 15vw;
    border-color: red;
  `;

  return (
    <>
      {!loading ? (
        <>
          <Modal
            destroyOnClose={true}
            title={modalHeader}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            // cancelButtonProps={{ style: { display: "none" } }}
            footer={null}
            width={800}
          >
            <p>{modalText}</p>
          </Modal>
          <Tabs
            className="evidenceTabs"
            defaultActiveKey={selectedKey}
            onChange={(e) => setSelectedKey(e)}
          >
            <Tabs.TabPane tab="Epidemiology Data by Country" key="1">
              <EvidenceAtlas
                mapData={evidenceAtlasData}
                tableData={tableData}
                maxDomainLength={maxDomainLength}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Study Characteristics" key="2">
              <DataTable
                initialTableData={initialTableData}
                tableData={tableData}
                saveCounter={saveCounter}
                setSaveCounter={setSaveCounter}
              />
            </Tabs.TabPane>
          </Tabs>
        </>
      ) : (
        <>
          <BeatLoader
            css={override}
            color={"#27A6A4"}
            loading={true}
            size={30}
          />
        </>
      )}
    </>
  );
}
