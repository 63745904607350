import PopulationLogo from "./../../../pictures/picos/population.png";
import InterventionLogo from "./../../../pictures/picos/fluent_document-pill-24-filled.png";
import ComparatorLogo from "./../../../pictures/picos/zs-icon-product-weight-fill.png";
import OutcomeLogo from "./../../../pictures/picos/zs-icon-target.png";
import StudyDesignLogo from "./../../../pictures/picos/zs-icon-map-graph.png";
import { Button, message } from "antd";
import { useEffect, useState } from "react";

const colors = {
  picos: ["#899FFF", "#A8C6FF", "#C3E0FF", "#DFE0FE", "#EEEEFF"],
  others: ["#C3E0FF", "#D6EBFF"],
};

const icons = {
  picos: [
    PopulationLogo,
    InterventionLogo,
    ComparatorLogo,
    OutcomeLogo,
    StudyDesignLogo,
  ],
  others: [PopulationLogo, InterventionLogo],
};

export default function Inclusion(props: {
  data: any;
  inEditMode: boolean;
  setData: any;
}) {
  const [picos, setPicos] = useState(props.data.picos);
  const [others, setOthers] = useState(props.data.others);

  useEffect(() => {
    let temp = {
      picos: picos,
      others: others,
    };
    props.setData(temp);
  }, [props.inEditMode]);

  return (
    <div>
      <div className="px-4 py-2 mx-4 my-4 bg-white rounded-sm">
        <div className="text-lg font-bold">PICOS</div>
        {picos &&
          picos.map((row: any, ind: any) => {
            return (
              <div className="flex mt-4">
                <div
                  style={{ backgroundColor: "#DEDCDE", width: "12%" }}
                  className="px-4 py-3 w-40 flex items-center"
                >
                  <div>
                    <p className="font-bold text-sm">{row["criteria"]}</p>
                    <div className="flex mt-2 items-center">
                      <p className="text-3xl mr-6">{ind + 1}</p>
                      <img
                        src={icons["picos"][ind % 5]}
                        style={{ marginTop: "0.5rem", width: "2.5rem" }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: colors["picos"][ind % 5],
                    width: "88%",
                  }}
                  className="px-6 py-3 w-full"
                >
                  {props.inEditMode ? (
                    <textarea
                      style={{
                        width: "100%",
                        padding: "0.4rem",
                        height: "100%",
                      }}
                      onChange={(e) => (row["description"] = e.target.value)}
                      defaultValue={row["description"]}
                      placeholder="Enter Description"
                    />
                  ) : (
                    row["description"]
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div className="px-4 py-2 mx-4 my-2 bg-white rounded-sm">
        <div className="text-lg font-bold mb-2">Others</div>
        {others &&
          others.map((row: any, ind: any) => {
            return (
              <div className="flex mt-4">
                <div
                  style={{ backgroundColor: "#DEDCDE", width: "12%" }}
                  className="px-4 py-3 w-40 flex items-center"
                >
                  <div>
                    <p className="font-bold text-sm">{row["criteria"]}</p>
                    <div className="flex mt-2 items-center">
                      <p className="text-3xl mr-6">{ind + 1}</p>
                      <img
                        src={icons["others"][ind % 5]}
                        style={{ marginTop: "0.5rem", width: "2.5rem" }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: colors["others"][ind % 5],
                    width: "88%",
                  }}
                  className="px-6 py-3 w-full"
                >
                  {props.inEditMode ? (
                    <textarea
                      style={{
                        width: "100%",
                        padding: "0.4rem",
                        height: "100%",
                      }}
                      onChange={(e) => (row["description"] = e.target.value)}
                      defaultValue={row["description"]}
                      placeholder="Enter Description"
                    />
                  ) : (
                    row["description"]
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
