import {
  Form,
  Input,
  Button,
  Space,
  Row,
  Col,
  Select,
  Tabs,
  Upload,
  message,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "easymde/dist/easymde.min.css";
import React, { useState, useEffect, useRef } from "react";
import { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import "./styled.css";
import AppContext from "../../store";
import { request } from "../../layout";

const FormField1 = (props: {
  handleEdit?: (value: any, key: number, deletedId: number[]) => void;
  handleSave?: (value: any) => void;
  editData?: any;
  closeModal?: () => void;
  subSectionId?: number;
  sectionId: number;
  viewType: string;
  tabId?: any;
}) => {
  const context = React.useContext(AppContext);
  const [changedRef, setChangedRef] = React.useState(false);
  const [fileRemoved, setFileRemoved] = React.useState(false);
  const value = React.useRef("");
  const onChange = (values: string) => {
    // setValue(value);
    value.current = values;
  };

  //   const [form] = Form.useForm();
  useEffect(() => {
    if (props.editData) {
      if (
        props.editData.filePath !== null &&
        props.editData.filePath !== "" &&
        props.editData.filePath !== undefined
      ) {
        defaultFileList.push({
          uid: "1",
          name: props.editData.filePath.split("/")[5],

          // response: "Server Error 500"; // custom error message to show
          url: props.editData.filePath,
        });
      }
    }
  }, []);

  function RemoveDocument() {
    //specify path for file to download
    message.loading("Deleting the File");
    //API to download a document, given a proper filepath
    //returns an object, which needs to downloaded in desired format
    let path = "";
    if (
      fileName !== null &&
      fileName !== "" &&
      fileLoc !== null &&
      fileLoc !== ""
    ) {
      path = fileLoc.split("=")[1] + fileName;
    } else {
      if (
        props.editData.filePath != null &&
        props.editData.filePath !== "" &&
        props.editData.filePath !== undefined
      ) {
        path = props.editData.filePath;
      } else {
        return;
      }
    }

    request
      .get(
        process.env.REACT_APP_API_URL + "SubSection/DeleteImages?path=" + path,
        {
          method: "GET",
          responseType: "blob",
          withCredentials: true,
        }
      )
      .then((res) => {
        // console.log("From s3", res);
        //open the document
        setFileRemoved(true);
      })
      .catch((err) => {
        message.error("Error!");
        // console.log({ err });
      });
  }
  const section = context.subSectionMapping.find(
    (section) => section.id === props.sectionId
  );
  const options: {
    label: string;
    value: string;
  }[] = [];
  (section?.subSection || []).map((subSection) => {
    if (subSection.name !== "Overview") {
      options.push({
        label: subSection.name,
        // value:
        // 	section?.name.split(" ")[0] +
        // 	"/" +
        // 	subSection.name.split(" ").join("_"),
        value: subSection.id + "",
      });
    }
  });
  const option1 = [
    {
      label: "Disease Overview",
      value: "disease",
    },
    {
      label: "Current Treatment Landscape",
      value: "current",
    },
    {
      label: "Burden of Illness and Unmet Need",
      value: "burden",
    },
    {
      label: "Product Information",
      value: "product",
    },
    {
      label: "Economic Value",
      value: "economic",
    },
    {
      label: "Market Access",
      value: "market",
    },
    {
      label: "Objection Handler",
      value: "objection",
    },
  ];

  const dataCopy = props.editData;
  const { TabPane } = Tabs;
  const [error, setError] = React.useState<null | string>(null);
  let fileName: string = "";
  let fileLoc: string = "";
  if (props.editData) {
    fileLoc =
      process.env.REACT_APP_API_URL +
      "subSection/UploadtoS3?filePath=files/" +
      context.productId +
      "/" +
      props.sectionId +
      "/" +
      props.subSectionId +
      "/" +
      props.editData.id;
  }
  //subsectioncomponentid + cardcomponentid + cardbodycomponentid
  //   if (dataCopy.Text)
  const defaultFileList: any = [];
  //   const formRef = useRef(null);
  //   if (props.editData.overviewBody[0].Text) {
  //     const tempName = props.editData.imageUrl.split("/");
  //     fileName = tempName[tempName.length - 1];
  //     defaultFileList.push({
  //       uid: "1",
  //       name: tempName[tempName.length - 1],

  //       // response: "Server Error 500"; // custom error message to show
  //       url: filePath,
  //     });
  //   }

  return (
    <Form
      //   form={form}
      //   ref={formRef}
      name="dynamic_form_nest_item"
      onFinish={(value) => {
        value.changedRef = changedRef;
        console.log("DATA VALUE...", value);
        // value.filePath = fileLoc.split("=")[1] + fileName;
        if (fileName !== null && fileName !== "") {
          value.filePath = fileLoc.split("=")[1] + "/" + fileName;
        }
        if (fileName === null || fileName === "" || fileName === undefined) {
          value.filePath = "";
        }
        if (fileRemoved) {
          value.filePath = "";
        }
        let flag = false;
        if (
          props.viewType === "overview" &&
          (value.header === undefined || value.header === "")
        ) {
          setError("Incomplete Form : Header is missing");
          flag = true;
          // console.log(flag);
        } else if (props.viewType === "overview" && value.link === undefined) {
          // console.log("INside Link!");
          setError("Incomplete Form : Link is missing");
          flag = true;
        }
        {
          !flag &&
            value.text.map((val: any) => {
              console.log("Inside Map...", val);

              if (
                props.viewType !== "overview" &&
                (val.header === "" || val.header === undefined)
              ) {
                setError("Incomplete Form : Header is missing");
                flag = true;
                // console.log(flag);
              }
              if (
                props.viewType === "overview" &&
                (val.text === "" || val.text === undefined)
              ) {
                setError("Incomplete Form : Message is missing");
                flag = true;
              }
            });
        }

        const refDeleted: number[] = [];
        if (props.editData)
          dataCopy.references.map((refs: any) => {
            if (!value.references.find((r: any) => r.id === refs.id))
              refDeleted.push(refs.id);
          });
        value.refDeleted = refDeleted;

        if (!value.references) value.references = [];
        if (props.subSectionId === 29) {
          console.log(value.text.length);
          if (!value.text.length) {
            value.text.push({
              header: "",
              link: "",
              referenceHeader: "",
              refereneLink: "",
            });
          }
        }

        if (!value.text) value.text = [];
        if (props.handleSave && !flag) {
          props.handleSave(value);
          if (props.closeModal) props.closeModal();
        } else if (props.handleEdit && !flag) {
          if (props.closeModal) props.closeModal();

          const deletedId: number[] = [];
          let message: any;
          for (let textData in value.text) {
            if (!value.text[textData].id) value.text[textData].id = 0;
          }
          for (message in dataCopy.text) {
            // console.log(dataCopy.text[message].id);
            const present = value.text.some(
              (el: any) => el.id === dataCopy.text[message].id
            );
            if (!present) deletedId.push(dataCopy.text[message].id);
          }

          props.handleEdit(value, props.editData.id, deletedId);
        }
      }}
      autoComplete="off"
      // layout="inline"
      initialValues={
        props.editData || {
          text: [{ header: "", link: "supporting", text: "" }],
        }
      }
    >
      {error && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <p style={{ color: "red", margin: 0 }}>
            <i>{error}</i>
          </p>
        </div>
      )}

      <Tabs defaultActiveKey="1">
        <TabPane tab="Message Properties" key="1">
          {props.viewType === "overview" && (
            <>
              <Row gutter={[10, 10]}>
                <Col span={2}>
                  <p>Header</p>
                </Col>
                <Col span={22}>
                  <Form.Item
                    name="header"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please enter header",
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder="Header"
                      // maxLength={50}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {props.subSectionId !== 29 ? (
                <Row>
                  <Col span={2}>
                    <p>Link</p>
                  </Col>
                  <Col span={22}>
                    <Form.Item
                      name="link"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please select a link",
                      //   },
                      // ]}
                    >
                      <Select placeholder="Select an option" allowClear>
                        {props.sectionId !== 1 &&
                          options.map((option, index) => (
                            <option
                              key={index}
                              // value={
                              // 	"/" +
                              // 	option.value
                              // }
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                        {props.sectionId === 1 &&
                          option1.map((x, y) => (
                            <option
                              key={y}
                              // value={
                              // 	"/" + x.value
                              // }
                              value={x.value}
                            >
                              {x.label}
                            </option>
                          ))}
                        {/* {props.subSectionId === 1 &&
											option1.map((x, y) => (
												<option
													key={y}
													value={"/" + x.value}
												>
													{x.label}
												</option>
											))}
										{props.subSectionId === 2 &&
											option2.map((x, y) => (
												<option
													key={y}
													value={"/" + x.value}
												>
													{x.label}
												</option>
											))}
										{props.subSectionId === 8 &&
											option3.map((x, y) => (
												<option
													key={y}
													value={"/" + x.value}
												>
													{x.label}
												</option>
											))}
										{props.subSectionId === 13 &&
											option4.map((x, y) => (
												<option
													key={y}
													value={"/" + x.value}
												>
													{x.label}
												</option>
											))}
										{props.subSectionId === 18 &&
											option5.map((x, y) => (
												<option
													key={y}
													value={"/" + x.value}
												>
													{x.label}
												</option>
											))}

										{props.subSectionId === 22 &&
											option6.map((x, y) => (
												<option
													key={y}
													value={"/" + x.value}
												>
													{x.label}
												</option>
											))}

										{props.subSectionId === 28 &&
											option8.map((x, y) => (
												<option
													key={y}
													value={"/" + x.value}
												>
													{x.label}
												</option>
											))} */}
                        {/* {props.subSectionId === 29 &&
                    option8.map((x, y) => (
                      <option key={y} value={"/" + x.value}>
                        {x.label}
                      </option>
                    ))} */}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <>
                  <Form.Item
                    name="link"
                    initialValue={`/objection/objection-handler-overview/${props.tabId}/supporting`}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select a link",
                    //   },
                    // ]}
                    style={{ margin: 0, display: "none" }}
                  >
                    {/* <Input disabled={true}></Input> */}
                  </Form.Item>
                </>
              )}
            </>
          )}
          <Row gutter={[10, 10]}>
            <Col span={2}>
              <p className="mt-1">File</p>
            </Col>
            <Col span={5}>
              <Form.Item name="filePath">
                <Upload
                  defaultFileList={defaultFileList}
                  beforeUpload={(file) => {
                    fileName = file.name;
                    if (String(file.name).includes(" ")) {
                      console.log("space found in filename");
                      message.error(
                        "File Name should not have (space) character!"
                      );
                      return Upload.LIST_IGNORE;
                    } else {
                      //check if file type is pdf/xls/txt/doc
                      console.log(file.type);
                      const isPDF = file.type === "application/pdf";
                      const isPPT =
                        file.type === "application/vnd.ms-powerpoint" ||
                        file.type ===
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                        file.type ===
                          "application/vnd.ms-powerpoint.presentation.macroEnabled.12";

                      if (!isPDF && !isPPT) {
                        message.error(
                          `${file.name} is not a valid file type. Accepted Types (.pdf | .ppt)`
                        );
                      }
                      return isPPT || isPDF || Upload.LIST_IGNORE;
                    }
                  }}
                  action={fileLoc}
                  withCredentials={true}
                  onRemove={(file) => RemoveDocument()}
                  maxCount={1}
                  headers={{
                    userName: localStorage.getItem("userName") || "",
                    "x-api-key": process.env.REACT_APP_API_KEY || "",
                  }}
                >
                  <Button icon={<UploadOutlined />}>Attach File</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={8}>
              <div
                style={{ fontSize: "0.8rem" }}
                className="flex font-semibold items-center justify-center mt-1"
              >
                <p className="italic ml-1">(Supported File Types: </p>
                <p className="italic ml-1 text-red-400"> ".pdf"</p>
                <p className="italic ml-1"> & </p>
                <p className="italic ml-1 text-red-400"> ".ppt"</p>
                <p className="italic">)</p>
              </div>
            </Col>
          </Row>
          {props.viewType === "overview" && <p>Message</p>}
          <div className="formItemOverview">
            <Form.List name="text">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <>
                      {/* <p>Key Message</p> */}
                      <Row gutter={[10, 10]}>
                        <Col span={props.viewType === "overview" ? 0 : 11}>
                          <Row>
                            <Col span={24}>
                              <p>Header</p>
                              <Form.Item
                                {...restField}
                                name={[name, "header"]}
                                fieldKey={[fieldKey, "link"]}
                                initialValue=""
                                // rules={[
                                //   {
                                //     required:
                                //       props.viewType === "overview"
                                //         ? false
                                //         : true,
                                //     message: "Please enter header",
                                //   },
                                // ]}
                              >
                                {props.viewType !== "overview" && (
                                  <SimpleMdeReact
                                    options={{
                                      hideIcons: [
                                        "fullscreen",
                                        "side-by-side",
                                        "image",
                                        "guide",
                                      ],
                                    }}
                                    value={value.current}
                                    onChange={onChange}
                                  />
                                )}
                              </Form.Item>
                            </Col>

                            <Col span={24}>
                              <Form.Item
                                {...restField}
                                name={[name, "link"]}
                                fieldKey={[fieldKey, "link"]}
                                initialValue={
                                  props.viewType !== "supporting"
                                    ? "supporting"
                                    : null
                                }
                              ></Form.Item>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          offset={props.viewType === "overview" ? 2 : 0}
                          span={props.viewType === "overview" ? 21 : 12}
                        >
                          <p>Body</p>
                          <Form.Item
                            {...restField}
                            name={[name, "text"]}
                            fieldKey={[fieldKey, "text"]}
                            // rules={[
                            //   {
                            //     required:
                            //       props.viewType === "overview" ? true : false,
                            //     message: "Missing Body",
                            //   },
                            // ]}
                          >
                            <SimpleMdeReact
                              options={{
                                hideIcons: [
                                  "fullscreen",
                                  "side-by-side",
                                  "image",
                                  "guide",
                                ],
                              }}
                              value={value.current}
                              onChange={onChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={1}>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Key Message
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </TabPane>
        <TabPane tab="References" key="2" forceRender>
          <Form.List name="references">
            {(fields, { add, remove }) => (
              <>
                <Row gutter={[8, 0]}>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <>
                      <Form.Item
                        {...restField}
                        name={[name, "id"]}
                        fieldKey={[fieldKey, "id"]}
                        initialValue={0}
                      />
                      <Col span={13}>
                        <Form.Item
                          {...restField}
                          name={[name, "referenceHeader"]}
                          fieldKey={[fieldKey, "referenceHeader"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Reference Header",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Reference Header"
                            onChange={() => setChangedRef(true)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          {...restField}
                          name={[name, "referenceLink"]}
                          fieldKey={[fieldKey, "referenceLink"]}
                          rules={
                            [
                              // {
                              // 	required: true,
                              // 	message: "Missing role",
                              // },
                            ]
                          }
                        >
                          <Input
                            placeholder="Reference Link"
                            onChange={() => setChangedRef(true)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1}>
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(name);
                            // setChangedRef(true);
                          }}
                        />
                      </Col>
                    </>
                  ))}
                </Row>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add References
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </TabPane>
      </Tabs>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormField1;
