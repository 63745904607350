import styled from "styled-components";
import { colors } from "../../../globalTheme";

export const BodyCardLayoutContainer = styled.div`
	width: 100%;
	background: transparent;
	padding-bottom: 8vh;
	/* height: calc(100vh - 22%); */
	height: ${(props: {
		noSubtab?: boolean;
		flexType?: string;
		layout?: string;
		grid?: number;
	}) =>
		props?.layout === "ObjectionHandler"
			? "calc(100vh - 32vh)"
			: props?.noSubtab
			? "calc(100vh - 16%)"
			: "calc(100vh - 22%)"};

	display: ${(props: { flexType?: string; noSubtab?: boolean }) =>
		props?.flexType ? "flex" : "block"};

	flex-direction: ${(props: { flexType?: string; noSubtab?: boolean }) =>
		props?.flexType ? props.flexType : "column"};

	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	
	.dropZone {
		.undefined {
			position: relative
			box-sizing: border-box;
			height: 130px;
			overflow: hidden;
			/* border-bottom: 3px dotted black; */
			padding-bottom:20px;
			
			/* &::after {
				content: "";
				background: 
					linear-gradient(-135deg, transparent 16px, #f4f3f3 0), 
					linear-gradient(135deg, transparent 16px, #f4f3f3  0);
        		background-repeat: repeat-x;
				background-position: left bottom;
				background-size: 22px 10px;
				position: absolute;
				
				bottom: 0px;
				left: 1.2em;
				height: 10px;
				width:${(props: { grid?: number }) => (props.grid === 2 ? "95.5%" : "93%")};
				
			} */
			/* &::after{
				content:""
			} */
		}
		.grabcard{
			cursor: grab !important;
			&:active{cursor: grabbing !important}

			}

	
	}

	.dropZone2 {
		.undefined {
			position: relative
			box-sizing: border-box;
			height: 140px;
			overflow: hidden;
			
			padding-bottom:20px;
			
		}
	}
`;
export const CardContainer = styled.div`
	width: 100%;
	background: transparent;
	display: grid;
	grid-template-columns: ${(props: { grid?: number }) =>
		props?.grid === 2
			? "50% 50%"
			: props?.grid === 1
			? "100%"
			: "33.33% 33.33% 33.33%" || "33.33% 33.33% 33.33%"};
	/* @media (max-width: 1050px) {
		grid-template-columns: 50% 50%;
	} */
	@media (max-width: 1000px) {
		grid-template-columns: 100%;
	}
`;
export const CardSlot = styled.div`
	width: 100%;
	padding: 1em 1.2em;
	/* filter: drop-shadow(0px -10px 15px #797979); */
`;
export const Actions = styled.div`
	margin: 0;
	/* padding: 0.8em 1.5em 0 1.5em; */
	padding: 0.8em 1em 0 1em;
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: center;
	color: ${colors.zsTeal};
	font-weight: 500;
	button {
		border: none;
		background: transparent;
		display: flex;
		align-items: center;
		cursor: pointer;
		&:hover {
			color: ${colors.zsOrange};
		}
	}
`;

export const BodyCardLayoutLeft = styled.div`
	width: 40%;
	height: calc(100vh - 28%);
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;

export const BodyCardLayoutRight = styled.div`
	width: 60%;
	margin-right: 1em;
	margin-top: 3.5em;
	height: calc(100vh - 32%);
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;

export const StarContainer = styled.div`
	background: #fafafa;
	color: ${colors.zsTeal};
	display: flex;
	justify: center;
	align-items: center;
	position: absolute;
	top: -1px;
	left: 1.8%;
	/* height: 1.4rem;
	width: 1.4rem; */
	padding: 0.4rem 0.3rem;
	clip-path: polygon(100% 0, 100% 100%, 50% 75%, 0 100%, 0 0);
`;
export const SearchContainer = styled.div`
	width: 50%;
	/* padding: 0 1em; */
	padding: 0.8em 1em 0 1em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	p {
		font-size: 1rem;
		font-weight: 500;
		margin: 0;
		margin-right: 8px;
	}
	input {
		width: 80%;
	}
`;
export const SearchActionContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: right;
`;
export const ActionContainer = styled.div`
	width: 50%;
`;
export const NoDataContainer = styled.div`
	margin: 1em;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: ${colors.zsWhite};
`;
