import { ResponsiveBar } from "@nivo/bar";
import * as ReportTypesComponentProps from "../../interfaces/main";
import transformData from "./transform";

const theme = (
	axisWidth?: number,
	axisColor?: string,
	textColor?: string,
	background?: string,
	fontSize?: number
) => {
	return {
		background: background || "white",
		textColor: textColor || "black",
		fontSize: fontSize || 11,
		axis: {
			domain: {
				line: {
					stroke: axisColor || "black",
					strokeWidth: axisWidth || 2,
				},
			},
			textColor: axisColor || "black",
			tickColor: axisColor || "black",
		},
	};
};
/**
 * Renders the Responsive Bar Chart Component. The component makes use of Nivo Charts Library.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @param props - Chart Config for the Bar Chart Component
 *
 * @returns Bar Chart Component of Nivo
 */
const BarChart: React.FC<ReportTypesComponentProps.Interface> = (
	props: ReportTypesComponentProps.Interface
): JSX.Element => (
	<>
		<ResponsiveBar
			data={transformData(
				props.data,
				props.series,
				props.axis?.primaryX.dim || 0
			)}
			keys={props.series.map((s) => s.name)}
			theme={theme(
				props.style?.axisWidth,
				props.style?.axisColor,
				props.style?.textColor,
				props.chartArea?.background,
				props.style?.fontSize
			)}
			layout={props.chartArea?.layout || "vertical"}
			layers={[
				"grid",
				"bars",
				"axes",
				"markers",
				"legends",
				"annotations",
			]}
			groupMode={props.chartConfig.cartesian?.groupMode || "stacked"}
			colors={({ id, data }) => String(data[`${id}Color`])}
			margin={
				props.style?.margin || {
					top: 20,
					right: 80,
					bottom: 50,
					left: 50,
				}
			}
			padding={props.chartConfig.cartesian?.gaps?.barGap || 0.1}
			innerPadding={props.chartConfig.cartesian?.gaps?.groupGap || 0}
			labelTextColor={props.style?.labelTextColor}
			labelSkipHeight={props.label?.skipSmallValue ? 20 : 0}
			enableLabel={props.label ? true : false}
			//label		to be added
			enableGridX={props.chartConfig.cartesian?.grid?.enableGridX}
			enableGridY={props.chartConfig.cartesian?.grid?.enableGridY}
			axisBottom={props.axis?.primaryX}
			axisLeft={props.axis?.primaryY}
			valueScale={{
				type: props.axis?.primaryY?.scale || "linear",
			}}
			reverse={props.axis?.primaryY?.reverse}
			minValue={props.axis?.primaryY?.minValue || "auto"}
			maxValue={props.axis?.primaryY?.maxValue || "auto"}
			isInteractive={props.tooltip ? true : false} // tooltip format to be added
			//valueFormat     to be added
			//defs     to be added for gradients
			animate={props.animate}
			legends={
				props.legend
					? [
							{
								anchor:
									props.legend?.position || "bottom-right",
								dataFrom: "keys",
								direction: props.legend?.direction || "column",
								itemHeight: 20,
								itemWidth: 110,
								toggleSerie: true,
								translateX: 120,
							},
					  ]
					: []
			}
		/>
	</>
);
export default BarChart;
