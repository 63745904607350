import React, { useEffect, useState } from "react";
import AppContext from "../../../store/index";
import KeyMessage from "../../../libraries/keyMessage";
import Tile from "../../../libraries/tile";
import ImageHolder from "./ImageHolder/ImageHolder";
import {
  BodyCardLayoutContainer,
  CardContainer,
  CardSlot,
  Actions,
  BodyCardLayoutLeft,
  BodyCardLayoutRight,
  SearchContainer,
  SearchActionContainer,
  ActionContainer,
  NoDataContainer,
} from "./style";
import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline";
import { ChangeCircle } from "@styled-icons/material-outlined/ChangeCircle";
import { Save } from "@styled-icons/boxicons-regular/Save";
import { Cancel } from "@styled-icons/material-outlined/Cancel";
import FormField2 from "../../../libraries/form-fields/formField2";
import FormField1 from "../../../libraries/form-fields/formField1";
import * as ServiceHelper from "../../../pages/executiveSummary/service-helper";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { message, Modal } from "antd";
import SupportingMessage3 from "./SupportingMessages3";
import { Container, Draggable } from "react-smooth-dnd";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import { headerIcons } from "../../../globalTheme";
import FormFieldEvidence from "../../../libraries/form-fields/formFieldEvidence";
export interface dataInterface {
  id?: number;
  componentTypeId?: number;
  imageUrl: string;
  imgHeader: string;
  headerTextBg?: string;
  headerTextColor?: string;
  noImgHeader?: boolean;

  overlayTextColor?: string;
  overlayTextSubHeader?: string;
  overlayProductName?: string;
  overlayProductDescription?: string;

  overviewBody?: {
    header: string;
    id: number;
    text: {
      text: string;
      link: string;
    }[];
    link?: string;
  }[];
  keyMessageBody?: {
    header: string;
    text: {
      text: string;
      link: string;
    }[];
    link: string;
  }[];
}

/**
 * Renders the Body Layput of the App along with the Cards.
 *
 * @param grid - Specifies the number of Cards in 1 row of the screen
 * @param type - Specifies the type of View ie. Overview or Key Message
 * @param sectionId -  Specifies the Section Id
 * @param subSectionId -  Specifies the SubSection Id
 * @param noImage - Specifies whether to Display Banner Image or not
 * @param layout - Specifies whether the layout is of Type Objction Handler
 *
 * @returns Body Layput of the App
 */
export default function BodyCardLayout(props: {
  grid?: number;
  type?: string;
  sectionId: number;
  subSectionId: number;
  productId?: number;
  productName?: string;
  noImage?: boolean;
  layout?: string;
  tabId?: number;
}) {
  const context = React.useContext(AppContext);

  const [fetchedData, setFetchedData] = React.useState<any>([]);
  const [hasEvidenceAtlas, setHasEvidenceAtlas] = React.useState<any>(false);
  const rawDataCopy = React.useRef<any>([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    console.log(
      "Here",
      props.subSectionId,
      context.productId,
      props.productId,
      props.type,
      props.subSectionId == 1 && context.productId != props.productId
    );
    // if (props.subSectionId === 1 && context.productId != props.productId) {
    // 	getSubSection(props.productId || context.productId);
    // 	localStorage.setItem(
    // 		"productId",
    // 		props.productId?.toString() || ""
    // 	);
    // }
    if (context.firstLoad) {
      getSubSection(
        props.productId ||
          context.productId ||
          Number(localStorage.getItem("productId"))
      );
      // localStorage.setItem(
      // 	"productId",
      // 	props.productId?.toString() || ""
      // );
      context.setFirstLoading(false);
    }

    fetchdata(props.productId || context.productId);
    setView(false);
  }, []);

  React.useEffect(() => {
    if (window.location.toString().includes(context.searchLink)) {
      context.setShowSearch(true);
    } else {
      context.setShowSearch(false);
      context.setNewSearchWord("");
      context.setNewSearchLink("&  &");
    }

    // if (window.getSelection && context.searchWord) {
    //   document.designMode = "on";
    //   var sel = window.getSelection();
    //   sel?.collapse(document.body, 0);
    //   while (window.find(context.searchWord)) {
    //     document.execCommand("HiliteColor", false, "#27a6a4");
    //     sel?.collapseToEnd();
    //   }
    //   document.designMode = "off";
    // let searched = context.searchWord;
    // if (searched !== "") {
    //   //@ts-ignore
    //   let text = document.getElementById("bodyLayout").innerHTML;
    //   let re = new RegExp(searched, "g"); // search for all instances
    //   let newText = text?.replace(re, `<mark>${searched}</mark>`);
    //   //@ts-ignore
    //   document.getElementById("bodyLayout").innerHTML = newText;
    // }
    // }
  });

  // useEffect(() => () => context.setShowSearch(false), []); // check instead for search link, then disable this showSearch

  const [refArray, setRefArray] = React.useState<
    {
      id: number;
      referenceHeader: string;
      referenceLink: string;
    }[]
  >([]);
  // const refArray = React.useRef<
  // 	{
  // 		id: number;
  // 		referenceHeader: string;
  // 		referenceLink: string;
  // 	}[]
  // >([]);
  async function getSubSection(productId: number) {
    console.log("Fetched First Load....", props);
    setLoading(true);
    // if (props.productName)
    // 	localStorage.setItem(
    // 		"productName",
    // 		props.productName.split("_").join(" ")
    // 	);
    // context.setProduct(
    // 	props.productId || 0,
    // 	props.productName?.split("_").join(" ") || ""
    // );
    const d: any = await ServiceHelper.getSubSection(productId);

    if (d && d.brandSubsectionDetails.length > 0) {
      console.log(
        "Set Subsection",
        productId,
        d.brandSubsectionDetails[0].subSectionDetails
      );
      localStorage.setItem(
        "subSectionMapping",
        `${JSON.stringify(d.brandSubsectionDetails[0].subSectionDetails)}`
      );
      context.setSubSection(d.brandSubsectionDetails[0].subSectionDetails);
    } else {
      console.log("Set Subsection", productId, d);
      localStorage.setItem(
        "subSectionMapping",
        `${JSON.stringify([
          {
            id: 1,
            name: "Executive Summary",
            // icon: "DocumentBulletList",
            subSection: [
              {
                id: 1,
                name: "default",
                sectionId: 1,
                // icon: "",
                isDefault: true,
              },
            ],
          },
          {
            id: 2,
            name: "Disease Overview",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 3,
            name: "Current Treatment Landscape",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 4,
            name: "Burden of Illness & Unmet Need",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 5,
            name: "Product Information",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 6,
            name: "Economic Value",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 7,
            name: "Market Access",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 8,
            name: "Objection Handler",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 9,
            name: "References",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 10,
            name: "Settings",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 11,
            name: "Introduction to digital global value dossiers",
            // icon: "DocumentBulletList",
            subSection: [],
          },
          {
            id: 12,
            name: "Abbreviations",
            // icon: "DocumentBulletList",
            subSection: [],
          },
        ])}`
      );
      context.setSubSection([
        {
          id: 1,
          name: "Executive Summary",
          // icon: "DocumentBulletList",
          subSection: [
            {
              id: 1,
              name: "default",
              sectionId: 1,
              // icon: "",
              isDefault: true,
            },
          ],
        },
        {
          id: 2,
          name: "Disease Overview",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 3,
          name: "Current Treatment Landscape",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 4,
          name: "Burden of Illness & Unmet Need",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 5,
          name: "Product Information",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 6,
          name: "Economic Value",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 7,
          name: "Market Access",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 8,
          name: "Objection Handler",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 9,
          name: "References",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 10,
          name: "Settings",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 11,
          name: "Introduction to digital global value dossiers",
          // icon: "DocumentBulletList",
          subSection: [],
        },
        {
          id: 12,
          name: "Abbreviations",
          // icon: "DocumentBulletList",
          subSection: [],
        },
      ]);
    }
    setLoading(false);
  }
  async function fetchdata(productId: number) {
    const d: any = await ServiceHelper.getAllData(
      productId,
      props.subSectionId,
      props.tabId
    );
    console.log("Fetched....", d);
    setItems(d[0].overviewBody);
    const tempArray: {
      id: number;
      referenceHeader: string;
      referenceLink: string;
    }[] = [];
    let evidenceIndex = -1;
    d[0].overviewBody.map((card: any, index: any) => {
      card.references.map((ref: any) => {
        if (!tempArray.find((r) => r.referenceHeader === ref.referenceHeader)) {
          tempArray.push(ref);
        }
      });
      card.text.map((t: any) => {
        if (
          t.header === "Epidemiology data by country" &&
          card.header === "Epidemiology data by country"
        ) {
          setHasEvidenceAtlas(true);
          evidenceIndex = index;
        }
      });
    });
    if (evidenceIndex > -1)
      d[0].overviewBody[0] = d[0].overviewBody.splice(
        evidenceIndex,
        1,
        d[0].overviewBody[0]
      )[0];
    setRefArray(tempArray);
    setFetchedData(d);
    rawDataCopy.current = d;
  }

  async function handleDelete(id?: number) {
    const key = "updatable";
    message.loading({ content: "Saving changes...", key });

    const data: any = await ServiceHelper.deleteTile(
      id || 0,
      context.productId,
      props.subSectionId,
      context.productId,
      props.tabId
    );
    if (data) message.success({ content: "Saved successfully", key });
    else message.error({ content: "Save failed", key });
    if (data) {
      rawDataCopy.current = data;
      setItems(data[0].overviewBody);
      const tempArray: {
        id: number;
        referenceHeader: string;
        referenceLink: string;
      }[] = [];
      data[0].overviewBody.map((card: any) => {
        card.references.map((ref: any) => {
          if (
            !tempArray.find((r) => r.referenceHeader === ref.referenceHeader)
          ) {
            tempArray.push(ref);
          }
        });
      });
      setRefArray(tempArray);
      setHasEvidenceAtlas(false);
      let evidenceIndex = -1;
      data[0].overviewBody.map((card: any, index: any) => {
        card.text.map((t: any) => {
          if (
            t.header === "Epidemiology data by country" &&
            card.header === "Epidemiology data by country"
          ) {
            setHasEvidenceAtlas(true);
            evidenceIndex = index;
          }
        });
      });

      if (evidenceIndex > -1)
        data[0].overviewBody[0] = data[0].overviewBody.splice(
          evidenceIndex,
          1,
          data[0].overviewBody[0]
        )[0];
      setFetchedData(data);
    }
  }
  async function handleSave(value: any) {
    const key = "updatable";

    message.loading({ content: "Saving changes...", key });
    if (props.tabId) {
      value.tabId = props.tabId;
    }

    const data: any = await ServiceHelper.addNewTile(
      value,
      context.productId,
      props.subSectionId,
      props.tabId
    );
    if (data) message.success({ content: "Saved successfully", key });
    else message.error({ content: "Save failed", key });
    if (data) {
      rawDataCopy.current = data;
      setItems(data[0].overviewBody);
      const tempArray: {
        id: number;
        referenceHeader: string;
        referenceLink: string;
      }[] = [];
      let evidenceIndex = -1;
      data[0].overviewBody.map((card: any, index: any) => {
        card.references.map((ref: any) => {
          if (
            !tempArray.find((r) => r.referenceHeader === ref.referenceHeader)
          ) {
            tempArray.push(ref);
          }
        });
        card.text.map((t: any) => {
          if (
            t.header === "Epidemiology data by country" &&
            card.header === "Epidemiology data by country"
          ) {
            setHasEvidenceAtlas(true);
            evidenceIndex = index;
          }
        });
      });

      if (evidenceIndex > -1)
        data[0].overviewBody[0] = data[0].overviewBody.splice(
          evidenceIndex,
          1,
          data[0].overviewBody[0]
        )[0];
      setFetchedData(data);
      setRefArray(tempArray);
      if (value.header === "Epidemiology data by country") {
        setHasEvidenceAtlas(true);
      }
    }
  }
  async function handleEditTile(
    value?: any,
    id?: number,
    deletedId?: number[]
  ) {
    value.deletedId = deletedId;
    // console.log(value);
    const key = "updatable";
    message.loading({ content: "Saving changes...", key });
    if (props.tabId) {
      value.tabId = props.tabId;
    }
    const data: any = await ServiceHelper.editTile(
      value,
      id || 0,
      context.productId,
      props.subSectionId,
      props.tabId
    );
    if (data) message.success({ content: "Saved successfully", key });
    else message.error({ content: "Save failed", key });
    if (data) {
      setFetchedData(data);
      rawDataCopy.current = data;
      setItems(data[0].overviewBody);
      const tempArray: {
        id: number;
        referenceHeader: string;
        referenceLink: string;
      }[] = [];
      let evidenceIndex = -1;
      data[0].overviewBody.map((card: any, index: any) => {
        card.references.map((ref: any) => {
          if (
            !tempArray.find((r) => r.referenceHeader === ref.referenceHeader)
          ) {
            tempArray.push(ref);
          }
        });
        card.text.map((t: any) => {
          if (
            t.header === "Epidemiology data by country" &&
            card.header === "Epidemiology data by country"
          ) {
            setHasEvidenceAtlas(true);
            evidenceIndex = index;
          }
        });
      });

      if (evidenceIndex > -1)
        data[0].overviewBody[0] = data[0].overviewBody.splice(
          evidenceIndex,
          1,
          data[0].overviewBody[0]
        )[0];
      setRefArray(tempArray);
    }
  }
  async function handleEditBanner(value?: any) {
    value.brandId = context.productId;
    value.componentTypeId = 1;
    value.subSectionId = props.subSectionId;
    console.log(value);
    const key = "updatable";
    message.loading({ content: "Saving changes...", key });
    const data = await ServiceHelper.editBanner(
      value,
      context.productId,
      props.subSectionId
    );
    if (data) message.success({ content: "Saved successfully", key });
    else message.error({ content: "Save failed", key });
    if (data) {
      setFetchedData(data);
      rawDataCopy.current = data;
    }
  }

  async function handleSaveOrder() {
    items.map((item: any, index: number) => {
      item.order = index + 1;
    });
    const obj = { overviewOrder: items };
    const key = "updatable";
    message.loading({ content: "Saving changes...", key });
    const data: any = await ServiceHelper.editOrder(
      obj,
      context.productId,
      props.subSectionId,
      context.productId,
      props.tabId
    );
    if (data) message.success({ content: "Saved successfully", key });
    else message.error({ content: "Save failed", key });
    if (data) {
      setFetchedData(data);
      rawDataCopy.current = data;
      setItems(data[0].overviewBody);
      const tempArray: {
        id: number;
        referenceHeader: string;
        referenceLink: string;
      }[] = [];
      data[0].overviewBody.map((card: any) => {
        card.references.map((ref: any) => {
          if (
            !tempArray.find((r) => r.referenceHeader === ref.referenceHeader)
          ) {
            tempArray.push(ref);
          }
        });
      });
      setRefArray(tempArray);
    }
    console.log("Saving Order ", items);
  }

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("Modal Header");
  const [modalText, setModalText] = React.useState(<></>);

  const showModal1 = () => {
    setModalHeader("Edit Banner");
    setModalText(
      <FormField2
        sectionId={props.sectionId}
        subSectionId={props.subSectionId}
        BannerInfo={false}
        editData={fetchedData[0]}
        handleEdit={handleEditBanner}
        closeModal={handleCancel}
      />
    );
    setVisible(true);
  };
  const showModal2 = () => {
    setModalHeader("Add New Tile");
    setModalText(
      <FormField1
        sectionId={props.sectionId}
        subSectionId={props.subSectionId}
        handleSave={handleSave}
        closeModal={handleCancel}
        viewType={props.type || ""}
        tabId={props.tabId ? props.tabId : null}
      />
    );
    setVisible(true);
  };

  const showModal3 = () => {
    setModalHeader("Add Epidemiology data by country");
    setModalText(
      <FormFieldEvidence
        sectionId={props.sectionId}
        subSectionId={props.subSectionId}
        handleSave={handleSave}
        closeModal={handleCancel}
        viewType={props.type || ""}
      />
    );
    setVisible(true);
  };

  const showModal2Edit = (editData: any) => {
    setModalHeader("Edit Tile");
    if (editData.header === "Epidemiology data by country") {
      setModalText(
        <FormFieldEvidence
          handleEdit={handleEditTile}
          editData={editData}
          closeModal={handleCancel}
          sectionId={props.sectionId}
          subSectionId={props.subSectionId}
          viewType={props.type || ""}
        />
      );
    } else {
      setModalText(
        <FormField1
          handleEdit={handleEditTile}
          editData={editData}
          closeModal={handleCancel}
          sectionId={props.sectionId}
          subSectionId={props.subSectionId}
          viewType={props.type || ""}
          tabId={props.tabId ? props.tabId : null}
        />
      );
    }
    setVisible(true);
  };
  const handleOk = () => {
    setModalText(<p>Saving</p>);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1500);
  };
  const handleCancel = () => {
    setModalText(<></>);
    setVisible(false);
  };

  const override = css`
    display: block;
    /* margin-left: ${props.layout === "ObjectionHandler" ? "100%" : "45%"}; */
    margin-left: 45%;
    margin-top: ${props.layout === "ObjectionHandler" ? "35%" : "15%"};
    border-color: red;
  `;

  const [items, setItems] = React.useState<any>([]);

  const [view, setView] = React.useState(false);
  let temp = 0;
  function onChange(
    sourceId: any,
    sourceIndex: any,
    targetIndex: any,
    targetId: any
  ) {
    if (
      items[0].header === "Epidemiology data by country" &&
      (targetIndex === 0 || (sourceIndex === 0 && targetIndex >= 0))
    ) {
      message.warning("Please select other position for this card!");
      return;
    }
    console.log(items);
    const nextState = swap(items, sourceIndex, targetIndex);
    setItems(nextState);
  }

  const handleSearch = (searchWord: string) => {
    console.log("Refsss", rawDataCopy.current);
    if (!searchWord) {
      setFetchedData(rawDataCopy.current);
    } else {
      var temp = [...rawDataCopy.current];

      const filteredData = temp[0].overviewBody.filter((card: any) => {
        if (card.header.toLowerCase().includes(searchWord.toLowerCase()))
          return true;
        else {
          if (
            card.text.filter((txt: any) =>
              txt.text.toLowerCase().includes(searchWord.toLowerCase())
            ).length > 0
          )
            return true;
          else return false;
        }
      });
      temp = [{ ...rawDataCopy.current[0], overviewBody: filteredData }];
      setFetchedData(temp);
    }
  };
  React.useEffect(() => {
    temp = 0;
  }, []);
  return (
    <>
      <Modal
        destroyOnClose={true}
        title={modalHeader}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        // cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
        width={800}
      >
        <p>{modalText}</p>
      </Modal>

      {!loading && fetchedData && fetchedData.length > 0 ? (
        <>
          <BodyCardLayoutContainer
            // noSubtab={fetchedData[0].noImgHeader}
            layout={props.layout}
            grid={props.grid}
          >
            {!props.noImage && (
              <ImageHolder
                src={fetchedData[0].imageUrl || ""}
                noImgHeader={fetchedData[0].noImgHeader}
                imgHeader={fetchedData[0].imgHeader}
                headerTextBg={fetchedData[0].headerTextBg}
                headerTextColor={fetchedData[0].headerTextColor}
                overlayTextSubHeader={fetchedData[0].overlayTextSubHeader}
                overlayProductName={fetchedData[0].overlayProductName}
                overlayProductDescription={
                  fetchedData[0].overlayProductDescription
                }
                overlayTextColor={fetchedData[0].overlayTextColor}
                overlayTextColor2={fetchedData[0].overlayTextColor2}
                showModal1={showModal1}
                subSectionId={props.subSectionId}
              />
            )}
            <SearchActionContainer>
              {props.layout === "ObjectionHandler" && (
                <SearchContainer>
                  <p>Search</p>
                  <input
                    // placeholder="Enter a search word to search"
                    placeholder="Enter a search term"
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                  />
                </SearchContainer>
              )}
              <ActionContainer>
                <>
                  {context.isAdmin && (
                    <>
                      <Actions>
                        {view && (
                          <button
                            onClick={() => {
                              setView(false);
                              setItems(fetchedData[0].overviewBody);
                            }}
                          >
                            <Cancel
                              size={22}
                              style={{
                                marginRight: "2px",
                              }}
                            />
                            Cancel
                          </button>
                        )}
                        {view && (
                          <button
                            onClick={() => {
                              setView(false);
                              handleSaveOrder();
                            }}
                          >
                            <Save
                              size={20}
                              style={{
                                marginRight: "2px",
                              }}
                            />
                            Save Order
                          </button>
                        )}
                        {!view && fetchedData[0].overviewBody.length > 0 && (
                          <button
                            onClick={() => {
                              setView(true);
                            }}
                          >
                            <ChangeCircle
                              size={20}
                              style={{
                                marginRight: "2px",
                              }}
                            />
                            Change Order
                          </button>
                        )}
                        <button onClick={showModal2}>
                          <AddCircleOutline
                            size={20}
                            style={{
                              marginRight: "2px",
                              marginLeft: "0.7rem",
                            }}
                          />
                          Add New
                        </button>
                        {!hasEvidenceAtlas && props.type !== "overview" && (
                          <button onClick={showModal3}>
                            <AddCircleOutline
                              size={20}
                              style={{
                                marginRight: "2px",
                                marginLeft: "0.7rem",
                              }}
                            />
                            Add Epidemiology data by country
                          </button>
                        )}
                      </Actions>
                    </>
                  )}
                </>
              </ActionContainer>
            </SearchActionContainer>

            {fetchedData[0].overviewBody.length > 0 ? (
              <>
                {view ? (
                  <>
                    {props.type === "overview" && (
                      <GridContextProvider onChange={onChange}>
                        <GridDropZone
                          className="dropZone"
                          id="items"
                          boxesPerRow={props.grid || 0}
                          rowHeight={130}
                          style={{ height: "400px" }}
                        >
                          {items.map((card: any, index: number) => {
                            if (index === 0) temp = 1;
                            else {
                              temp =
                                temp +
                                fetchedData[0].overviewBody[index - 1]
                                  .references.length;
                            }

                            return (
                              <GridItem
                                className="grabcard"
                                // style={{ cursor: "grabbing" }}
                                key={card.id}
                              >
                                <CardSlot>
                                  <Tile
                                    data={card}
                                    noMatch
                                    handleDelete={handleDelete}
                                    showModal={showModal2Edit}
                                    layout={props.layout}
                                    edit={view}
                                    ref_start={temp}
                                    refArray={refArray}
                                  />
                                </CardSlot>
                              </GridItem>
                            );
                          })}
                        </GridDropZone>
                      </GridContextProvider>
                    )}

                    {props.type === "keyMessage" && (
                      <GridContextProvider onChange={onChange}>
                        <GridDropZone
                          className="dropZone2"
                          id="items"
                          boxesPerRow={2}
                          rowHeight={140}
                          style={{ height: "400px" }}
                        >
                          {(items || []).map((card: any, index: number) => {
                            if (index === 0) temp = 1;
                            else {
                              temp =
                                temp +
                                fetchedData[0].overviewBody[index - 1]
                                  .references.length;
                            }
                            return (
                              <GridItem key={card.id}>
                                <CardSlot>
                                  <KeyMessage
                                    data={card}
                                    noMatch
                                    handleDelete={handleDelete}
                                    showModal={showModal2Edit}
                                    sectionId={props.sectionId}
                                    subSectionId={props.subSectionId}
                                    edit={view}
                                    ref_start={temp}
                                    refArray={refArray}
                                  />
                                </CardSlot>
                              </GridItem>
                            );
                          })}
                        </GridDropZone>
                      </GridContextProvider>
                    )}
                  </>
                ) : (
                  <>
                    <CardContainer grid={props.grid}>
                      {props.type === "overview" &&
                        fetchedData[0].overviewBody.map(
                          (card: any, index: number) => {
                            if (index === 0) temp = 1;
                            else {
                              temp =
                                temp +
                                fetchedData[0].overviewBody[index - 1]
                                  .references.length;
                            }
                            return (
                              <CardSlot>
                                <Tile
                                  data={card}
                                  noMatch
                                  handleDelete={handleDelete}
                                  showModal={showModal2Edit}
                                  layout={props.layout}
                                  edit={view}
                                  ref_start={temp}
                                  refArray={refArray}
                                />
                              </CardSlot>
                            );
                          }
                        )}
                      {props.type === "keyMessage" &&
                        (fetchedData[0].overviewBody || []).map(
                          (card: any, index: number) => {
                            if (index === 0) temp = 1;
                            else {
                              temp =
                                temp +
                                fetchedData[0].overviewBody[index - 1]
                                  .references.length;
                            }
                            return (
                              <CardSlot>
                                <KeyMessage // header={card.header} // link={card.link} // bodyText={card.text}
                                  data={card}
                                  noMatch
                                  handleDelete={handleDelete}
                                  showModal={showModal2Edit}
                                  sectionId={props.sectionId}
                                  subSectionId={props.subSectionId}
                                  edit={view}
                                  ref_start={temp}
                                  refArray={refArray}
                                />
                              </CardSlot>
                            );
                          }
                        )}
                    </CardContainer>
                  </>
                )}
              </>
            ) : (
              <>
                {props.layout === "ObjectionHandler" && (
                  <NoDataContainer>
                    {headerIcons.find((l) => l.id === "No Data")?.Icon}
                    No data available
                  </NoDataContainer>
                )}
              </>
            )}
          </BodyCardLayoutContainer>
        </>
      ) : (
        <>
          <BeatLoader
            css={override}
            color={"#27A6A4"}
            loading={true}
            size={30}
          />
        </>
      )}
    </>
  );
}
