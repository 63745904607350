import { ResponsivePie } from "@nivo/pie";

const colors = [
  "#fdae6b",
  "#01a6dc",
  "#bbe9d0",
  "#a0afc6",
  "#f47660",
  "#86c8bc",
  "#B8CC7B",
  "#ED8B00",
  "#a2ddff",
  "#e39ed6",
];
export default function (props: { data: any; margin?: any; legends?: any }) {
  return (
    <ResponsivePie
      data={props.data}
      margin={props.margin}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={colors}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      //   tooltip={({ datum }) => (
      //     <div className="flex items-center justify-center px-2 py-1 bg-white text-xs">
      //       <p>{String(id).replaceAll("**", "")} :</p>
      //       <p className="font-bold ml-1">{value}</p>
      //     </div>
      //   )}
      // // legends={[
      //   {
      //     anchor: "right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 30,
      //     translateY: 10,
      //     itemsSpacing: 4,
      //     itemWidth: 150,
      //     itemHeight: 18,
      //     itemTextColor: "#999",
      //     itemDirection: "left-to-right",
      //     itemOpacity: 1,
      //     symbolSize: 18,
      //     symbolShape: "circle",
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemTextColor: "#000",
      //         },
      //       },
      //     ],
      //   },
      // ]}
      // legends={[
      //   {
      //     anchor: "right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 60,
      //     translateY: 10,
      //     itemsSpacing: 2,
      //     itemWidth: 100,
      //     itemHeight: 14,
      //     itemTextColor: "#999",
      //     itemDirection: "left-to-right",
      //     itemOpacity: 1,
      //     symbolSize: 12,
      //     symbolShape: "circle",
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemTextColor: "#000",
      //         },
      //       },
      //     ],
      //   },
      // ]}
      legends={props.legends}
    />
  );
}
