import { Col, Popconfirm, Row } from "antd";
import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./index.css";
import AppContext from "../../../../store";
import {
  StyledMessageContainer,
  SupportingMessageBodyText,
  SupportingMessageBodyTexts,
  SupportingMessageContainer,
  SupportingMessageIconPlaceholder,
  SupportingMessageTextPlaceholder,
  StyledTooltip,
} from "../SupportingMessages1/style";
import { Actions } from "../style";
import { AddCircleOutline } from "@styled-icons/material";
import { SwitchHorizontal } from "@styled-icons/heroicons-outline";
import { Delete } from "@styled-icons/fluentui-system-regular";
import { Edit2Outline } from "@styled-icons/evaicons-outline";
import ReactMarkdown from "react-markdown";
import { request } from "../../..";
import Star from "../SupportingMessages1/star";
import { StyledSupportingMessage } from "./style";
import Highlight from "react-highlighter";

/**
 * Renders Supporting Message View (Template 2) of the App.
 *
 * @param data - Data for the Supporting Messages
 * @param handleDelete - Callback function for deleting a Supporting Message
 * @param showModal - Callback function for opening the Model Form for Adding a New Supporting Message
 * @param showModalEdit - Callback function for opening the Model Form for Editing a Supporting Message
 * @param switchView - Callback function for Switching between Templates for the Supporting Message
 *
 * @returns Body Layput of the App
 */
const SupportingMessage2 = (props: {
  data: {
    id: number;
    keyMessage: string;
    supportingKeyMessage: {
      id: number;
      supportingMessageHeader: string;
      componentHeader?: string;
      chartType?: string;
      supportingMessages: {
        id: number;
        bulletPoint: string;
        link?: string;
      }[];
      references: {
        id?: number;
        referenceHeader?: string;
        referenceLink?: string;
      }[];
      imageURL: string;
    }[];
  };
  switchView: React.Dispatch<React.SetStateAction<number>>;
  showModal: () => void;
  showModalEdit: (id: number) => void;
  handleDelete?: (id: number) => void;
  ref_start?: number;
  refArray: any[];
}) => {
  const context = React.useContext(AppContext);

  const zoomOutProperties = {
    scale: 0.4,
  };
  let n = props.ref_start;
  function ShowImg(props: { src: any; header: string }) {
    const [image, setImage] = React.useState<any>(null);
    async function fetchImg(src: string) {
      const data: string | unknown = await request
        .get("brand/GetImage?imagePath=" + src, {
          withCredentials: true,
          responseType: "arraybuffer",
        })
        .then((response: any) => {
          // console.log("RESP", response);
          setImage(Buffer.from(response.data, "binary").toString("base64"));
          return Buffer.from(response.data, "binary").toString("base64");
        })
        .catch((err: any) => {
          console.log(err.message, "error here");
          return null;
        });
    }
    fetchImg(props.src);
    return (
      <img
        src={
          image
            ? "data:image/jpeg;base64," + image
            : "/images/defaultsupport.png"
        }
        alt={props.header}
        style={{
          maxHeight: "30vh",
          padding: "1em",
          margin: "0 auto",
          objectFit: "cover", // border: "2px solid red",
        }}
      />
    );
  }
  console.log("support");
  return (
    <SupportingMessageContainer>
      <StyledMessageContainer>
        {/* <img
					src="/icons/SupportingMessageStar.png"
					alt=""
					style={{
						position: "absolute",
						top: 0,
						left: 40,
						objectFit: "cover",
					}}
				/> */}
        {/* <Star /> */}
        <ReactMarkdown
          className="carousel-message"
          source={props.data.keyMessage}
        />
      </StyledMessageContainer>
      {context.isAdmin && (
        <Actions>
          <button onClick={() => props.switchView(1)}>
            <SwitchHorizontal
              title="Switch View"
              size={20}
              style={{ marginRight: "2px" }}
            />
            Switch View
          </button>
          <button onClick={props.showModal}>
            <AddCircleOutline
              title="Add New Message"
              size={20}
              style={{ marginRight: "2px" }}
            />
            Add New
          </button>
        </Actions>
      )}
      <div className="outerdiv">
        <Slide
          // infinite={false}
          autoplay={false}
          className="slidediv"
          easing="ease"
          {...zoomOutProperties}
        >
          {props.data.supportingKeyMessage.map((message) => {
            return (
              <div className="each-slide">
                <Row>
                  <Col span={18}>
                    <ShowImg
                      src={message.imageURL}
                      header={message.supportingMessageHeader}
                    />
                    {/* <img
											style={{
												minHeight: "300px",
												height: "auto",
												padding: "40px",
											}}
											src={
												message.imageURL
													? "https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
													  message.imageURL
													: "/images/defaultsupport.png"
											}
										/> */}
                  </Col>
                  <Col
                    style={{
                      position: "relative",
                      background: "rgb(216,236,251)",
                      padding: "50px",
                    }}
                    span={6}
                  >
                    {context.isAdmin && (
                      <div
                        style={{
                          position: "absolute",
                          right: "1em",
                          top: "1em",
                        }}
                      >
                        <Popconfirm
                          title="Are you sure to delete this message?"
                          onConfirm={() => {
                            //@ts-ignore
                            props.handleDelete(message.id);
                          }} // onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Delete
                            className="messageIcons"
                            title="Delete Message"
                            size={26}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </Popconfirm>

                        <Edit2Outline
                          className="messageIcons"
                          title="Edit Message"
                          size={22}
                          style={{
                            marginTop: "5px",
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                          onClick={() => props.showModalEdit(message.id)}
                        />
                      </div>
                    )}
                    <h2>
                      <Highlight
                        search={context.searchWord}
                        matchStyle={{
                          color: "red",
                          backgroundColor: "yellow",
                          padding: 0,
                        }}
                      >
                        {message.supportingMessageHeader}
                      </Highlight>
                    </h2>
                    {message.references &&
                      message.references.length > 0 &&
                      message.references.map((refs) => {
                        return (
                          <StyledTooltip
                            title={
                              <>
                                <p
                                  style={{
                                    marginBottom: 0,
                                  }}
                                >
                                  {refs.referenceHeader}
                                </p>

                                {refs.referenceLink && (
                                  <a href={refs.referenceLink} target="_blank">
                                    Link
                                  </a>
                                )}
                              </>
                            }
                            // title={"Refs"}
                            // className="reference-tooltip"
                          >
                            <span>
                              [
                              {props.refArray.findIndex(
                                (r: any) =>
                                  r.referenceHeader === refs.referenceHeader
                              ) + 1}
                              ]
                            </span>
                          </StyledTooltip>
                        );
                      })}
                  </Col>
                  <div
                    style={{
                      height: "30px",
                      width: "100%",
                      backgroundColor: "rgb(244,243,243)",
                    }}
                  ></div>
                  <Col
                    span={24}
                    style={{
                      padding: "1em",
                      background: "#F4F3F3",
                    }}
                  >
                    <StyledSupportingMessage>
                      <SupportingMessageTextPlaceholder>
                        <SupportingMessageBodyTexts>
                          <Row gutter={[16, 16]}>
                            {message.supportingMessages.map((bullet) => {
                              if (
                                bullet.bulletPoint === null ||
                                bullet.bulletPoint === undefined
                              ) {
                                return (
                                  <Col
                                    style={{
                                      padding: "30px",
                                    }}
                                    span={12}
                                  >
                                    <li
                                      style={{
                                        margin: "0 1rem",
                                      }}
                                    >
                                      <SupportingMessageBodyText>
                                        <ReactMarkdown
                                          source={bullet.bulletPoint}
                                        />

                                        {/* {bullet.bulletPoint} */}
                                      </SupportingMessageBodyText>
                                    </li>
                                  </Col>
                                );
                              }
                              return (
                                <Col
                                  style={{
                                    padding: "30px",
                                  }}
                                  span={12}
                                >
                                  <li
                                    style={{
                                      margin: "0 1rem",
                                    }}
                                  >
                                    <SupportingMessageBodyText>
                                      {bullet.bulletPoint
                                        .toLowerCase()
                                        .includes(context.searchWord) &&
                                      context.searchWord !== "" ? (
                                        <Highlight
                                          search={context.searchWord}
                                          matchStyle={{
                                            color: "red",
                                            backgroundColor: "yellow",
                                            padding: 0,
                                          }}
                                        >
                                          {bullet.bulletPoint}
                                        </Highlight>
                                      ) : (
                                        <ReactMarkdown
                                          source={bullet.bulletPoint}
                                        />
                                      )}
                                      {/* <ReactMarkdown
                                        source={bullet.bulletPoint}
                                      /> */}

                                      {/* {bullet.bulletPoint} */}
                                    </SupportingMessageBodyText>
                                  </li>
                                </Col>
                              );
                            })}
                          </Row>
                        </SupportingMessageBodyTexts>
                      </SupportingMessageTextPlaceholder>
                    </StyledSupportingMessage>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Slide>
      </div>
    </SupportingMessageContainer>
  );
};

export default SupportingMessage2;
