import styled from "styled-components";
import { colors } from "./../../globalTheme";
import { Tooltip } from "antd";
export const KeyMessageContainer = styled.div`
  position: relative;
  padding: 0.5em 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* box-shadow: 4px 4px 10px ${colors.zsShadow}; */
  border-radius: 8px;
  border-left: 6px solid ${colors.zsTeal};
  background: ${colors.zsWhite};
  min-height: 100px;
  /* border: ${(props: { highlight?: boolean }) =>
    props.highlight ? "4px solid black" : "none"}; */
  box-shadow: ${(props: { highlight?: boolean }) =>
    props.highlight ? "0px 0px 10px orange" : "none"};
  border: ${(props: { highlight?: boolean }) =>
    props.highlight ? "2px solid yellow" : "none"};
`;
export const KeyMessageHeaderContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  color: ${colors.zsTeal};
  p {
    margin: 0.6em 2.4em;
    padding: 0;
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.zsTeal};
  }
  .icon {
    display: flex;
    .action-icon {
      cursor: pointer;

      &:hover {
        color: ${colors.zsOrange};
        transform: scale(110%);
      }
    }
  }
  .icon a {
    color: ${colors.zsTeal};
  }
`;

export const KeyMessageBodyContainer = styled.div`
  color: ${colors.zsGray80};
  margin: 0;
  overflow: hidden;
  max-height: ${(props: {
    minHeight?: string;
    bodyPadding?: string;
    edit?: boolean;
  }) => (props.edit ? "50px" : "auto")};

  /* padding: 0 0em 0em 0em; */
`;
export const KeyMessageBodyHeader = styled.div`
  margin-top: ${(props: { isAdmin: boolean }) => (props.isAdmin ? "1em" : "0")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${colors.zsTeal};

  p {
    margin: 0.6em 2.4em;
    padding: 0;
    font-weight: 500;
    font-size: 1rem;
    color: ${colors.zsTeal};
    display: inline;
    span {
      padding: 2px;
    }
  }
  a {
    color: ${colors.zsTeal};
  }
  .markDownContainer {
    p {
      margin: 0;
    }
  }
`;
export const KeyMessageBulletContainer = styled.ul`
  color: ${colors.zsGray80};
  margin: 0;
  padding: 0 0em 0em 5em;
`;
export const KeyMessageBullet = styled.li`
  .message-bullet {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: flex-start;
    padding-bottom: 0.5em;
    padding-right: 1em;
  }
  p {
    margin: 0 0 0.4em 0;
    color: ${colors.zsGray80};
    font-weight: 400;
  }
  .icon a {
    color: ${colors.zsTeal};
  }
`;
export const StyledTooltip = styled(Tooltip)`
  vertical-align: super;
  font-size: 0.6rem;
  padding: 5px;
`;
export const StarContainer = styled.div`
  background: #b2b0b6;
  color: white;
  display: flex;
  justify: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 3%;
  /* height: 1.4rem;
	width: 1.4rem; */
  padding: 0.4rem 0.3rem;
  clip-path: polygon(100% 0, 100% 100%, 50% 75%, 0 100%, 0 0);
`;
