import { TextBulletListSquare } from "@styled-icons/fluentui-system-regular/TextBulletListSquare";
import { HeatMap } from "@nivo/heatmap";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Popover,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
  Drawer,
} from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  EditFilled,
  SaveOutlined,
  FileOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  UndoOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { ExternalLinkOutline } from "@styled-icons/evaicons-outline/ExternalLinkOutline";
import "./style.css";
import ReactMarkdown from "react-markdown";
import { request } from "../../layout/SLR_Layout";
import { Link, useHistory } from "react-router-dom";
import SimpleMdeReact from "react-simplemde-editor";

interface DataType {
  key: string;
  studyname: string;
}
function Outcomes() {
  const [inEditMode, setInEditMode] = useState(false);
  const [inEditModeOutcome, setInEditModeOutcome] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [efficacyData, setEfficacyData] = useState<any>([]);
  const [safetyData, setSafetyData] = useState<any>([]);
  const [tolerabilityData, setTolerabilityData] = useState<any>([]);
  const [effWidth, setEffWidth] = useState<any>(463);
  const [safWidth, setSafWidth] = useState<any>(463);
  const [tolWidth, setTolWidth] = useState<any>(263);
  const [efficacyKeys, setEfficacyKeys] = useState<any>([]);
  const [safetyKeys, setSafetyKeys] = useState<any>([]);
  const [tolerabilityKeys, setTolerabilityKeys] = useState<any>([]);
  const [selectedEfficacyKeys, setSelectedEfficacyKeys] = useState<any>([]);
  const [selectedSafetyKeys, setSelectedSafetyKeys] = useState<any>([]);
  const [selectedTolerabilityKeys, setSelectedTolerabilityKeys] = useState<any>(
    []
  );
  const [duplicateEfficacyKeys, setDuplicateEfficacyKeys] = useState<any>([
    ...efficacyKeys,
  ]);
  const [duplicateSafetyKeys, setDuplicateSafetyKeys] = useState<any>([
    ...safetyKeys,
  ]);
  const [duplicateTolerabilityKeys, setDuplicateTolerabilityKeys] =
    useState<any>([...tolerabilityKeys]);

  const [isModalEfficacyOpen, setIsModalEfficacyOpen] = useState(false);
  const [isModalSafetyOpen, setIsModalSafetyOpen] = useState(false);
  const [isModalTolerabilityOpen, setIsModalTolerabilityOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOutcome, setSelectedOutcome] = useState<any>("");
  const [selectedOutcomeId, setSelectedOutcomeId] = useState<any>("");
  const [selectedOutcomeColumns, setSelectedOutcomeColumns] = useState<any>([]);
  const [selectedOutcomeData, setSelectedOutcomeData] = useState<any>([]);
  const [selectedOutcomeDescription, setSelectedOutcomeDescription] =
    useState<any>([]);
  const [selectedOutcomeFootnote, setSelectedOutcomeFootnote] = useState<any>(
    []
  );
  const [isModalVisibleSelectedOutcome, setIsModalVisibleSelectedOutcome] =
    useState(false);
  const [
    isModalVisibleSelectOutcomesEfficacy,
    setIsModalVisibleSelectOutcomesEfficacy,
  ] = useState(false);
  const [
    isModalVisibleSelectOutcomesSafety,
    setIsModalVisibleSelectOutcomesSafety,
  ] = useState(false);
  const [
    isModalVisibleSelectOutcomesTolerability,
    setIsModalVisibleSelectOutcomesTolerability,
  ] = useState(false);
  const [loadingOutcome, setLoadingOutcome] = useState(false);
  const [saveCounter, setSaveCounter] = useState<any>(0);
  const [dataFetched, setDataFetched] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [screenInfo, setScreenInfo] = useState<any>(
    "This section shows the results of this SLR; Showing efficacy, safety and tolerability outcomes which can be viewed by clicking on each outcome."
  );
  const [footnoteInfo, setFootnoteInfo] = useState<any>("");
  const [copyFootnoteInfo, setCopyFootnoteInfo] = useState<any>("");
  const [footnote, setFootnote] = useState<any>("");
  const [footnoteInitial, setFootnoteInitial] = useState<any>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inEditModeFootnote, setInEditModeFootnote] = useState(false);
  const [isLoadingFootnote, setIsLoadingFootnote] = useState(true);
  const [studyDetails, setStudyDetails] = useState<any>({});

  const [formEfficacy] = Form.useForm();
  const [formSafety] = Form.useForm();
  const [formTolerability] = Form.useForm();
  const [formOutcome] = Form.useForm();
  const outcomeNumber = useRef(1);
  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "100%",
      maxHeight: "100%",
    };
  }, []);

  const theme = {
    fontFamily: "Fira Sans",
    fontSize: 14,
    legends: {
      text: {
        fill: "#333333",
      },
    },
    labels: {
      text: {
        fill: "#333333",
      },
    },
    axis: {
      legend: {
        text: {
          fontSize: 20,
        },
      },
    },
  };

  const navigate = useHistory();

  useEffect(() => {
    setLoading(true);
    if (
      window.sessionStorage.getItem("selectedBackgroundName") === undefined ||
      window.sessionStorage.getItem("selectedBackgroundName") === null ||
      window.sessionStorage.getItem("selectedBackgroundName") === "" ||
      window.sessionStorage.getItem("productReviewId") === undefined ||
      window.sessionStorage.getItem("productReviewId") === null ||
      window.sessionStorage.getItem("productReviewId") === "" ||
      window.localStorage.getItem("productId") === undefined ||
      window.localStorage.getItem("productId") === null ||
      window.localStorage.getItem("productId") === ""
    ) {
      navigate.push("/SLR");
      return;
    }
    if (!dataFetched) {
      if (window.innerWidth < 1220) {
        setTolWidth(
          (document.getElementById("tolerabilityDiv")?.offsetWidth || 193) *
            0.95
        );
        setEffWidth(
          (document.getElementById("efficacyDiv")?.offsetWidth || 343) * 0.95
        );
        setSafWidth(
          (document.getElementById("safetyDiv")?.offsetWidth || 343) * 0.95
        );
      } else {
        setTolWidth(
          (document.getElementById("tolerabilityDiv")?.offsetWidth || 263) *
            0.95
        );
        setEffWidth(
          (document.getElementById("efficacyDiv")?.offsetWidth || 463) * 0.95
        );
        setSafWidth(
          (document.getElementById("safetyDiv")?.offsetWidth || 463) * 0.95
        );
      }

      const productReviewId = window.sessionStorage.getItem("productReviewId");

      request
        .get("/GetAllOutcomes?productReviewId=" + productReviewId)
        .then((res: any) => {
          console.log("response from API", res);
          if (res.status === 200 && res.data) {
            setEfficacyKeys([...res.data.efficacy.outcomeNames]);
            setSafetyKeys([...res.data.safety.outcomeNames]);
            setTolerabilityKeys([...res.data.tolerability.outcomeNames]);
            setSelectedEfficacyKeys(
              res.data.efficacy.outcomeNames.map((item: any) => item.name)
            );
            setSelectedSafetyKeys(
              res.data.safety.outcomeNames.map((item: any) => item.name)
            );
            setSelectedTolerabilityKeys(
              res.data.tolerability.outcomeNames.map((item: any) => item.name)
            );
            let tempTableData: any = [];
            res.data.efficacy.data.map((item: any) => {
              tempTableData.push({
                key: item.key,
                studyname: item.studyname,
              });
            });
            setTableData([...tempTableData]);
            setEfficacyData([...res.data.efficacy.data]);
            setSafetyData([...res.data.safety.data]);
            setTolerabilityData([...res.data.tolerability.data]);

            //create form values for all 3 outcomes
            let tempFormValuesEfficacy: any = {};
            res.data.efficacy.data.map((data: any) => {
              res.data.efficacy.outcomeNames.map((key: any) => {
                let tempKey = data.studyname + "_" + key.name;
                tempFormValuesEfficacy[key.name] = key.name;
                if (
                  data[key.name] === "3" ||
                  data[key.name] === undefined ||
                  data[key.name] === null
                ) {
                  tempFormValuesEfficacy[tempKey] = undefined;
                } else if (data[key.name] === "1") {
                  tempFormValuesEfficacy[tempKey] = "nonsignificant";
                } else if (data[key.name] === "2") {
                  tempFormValuesEfficacy[tempKey] = "significant";
                }
              });
            });
            formEfficacy.setFieldsValue(tempFormValuesEfficacy);
            let tempFormValuesSafety: any = {};
            res.data.safety.data.map((data: any) => {
              res.data.safety.outcomeNames.map((key: any) => {
                let tempKey = data.studyname + "_" + key.name;
                tempFormValuesSafety[key.name] = key.name;
                if (data[key.name] === "1") {
                  tempFormValuesSafety[tempKey] = "nonsignificant";
                } else if (
                  data[key.name] === "3" ||
                  data[key.name] === undefined ||
                  data[key.name] === null
                ) {
                  tempFormValuesSafety[tempKey] = undefined;
                } else if (data[key.name] === "2") {
                  tempFormValuesSafety[tempKey] = "significant";
                }
              });
            });
            formSafety.setFieldsValue(tempFormValuesSafety);
            let tempFormValuesTolerability: any = {};
            res.data.tolerability.data.map((data: any) => {
              res.data.tolerability.outcomeNames.map((key: any) => {
                let tempKey = data.studyname + "_" + key.name;
                tempFormValuesTolerability[key.name] = key.name;
                if (data[key.name] === "1") {
                  tempFormValuesTolerability[tempKey] = "nonsignificant";
                } else if (
                  data[key.name] === "3" ||
                  data[key.name] === undefined ||
                  data[key.name] === null
                ) {
                  tempFormValuesTolerability[tempKey] = undefined;
                } else if (data[key.name] === "2") {
                  tempFormValuesTolerability[tempKey] = "significant";
                }
              });
            });
            formTolerability.setFieldsValue(tempFormValuesTolerability);
            let tempStudyDetails: any = {};
            res.data.studyDetails.map((item: any) => {
              tempStudyDetails[item["key"]] = item["reference"];
            });
            setStudyDetails(tempStudyDetails);
            setDataFetched(true);
            console.log(
              tempFormValuesEfficacy,
              tempFormValuesSafety,
              tempFormValuesTolerability
            );
          } else {
            message.error("Error!");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [saveCounter]);

  function saveHandler() {
    console.log("saving data", {
      efficacy: {
        outcomeNames: efficacyKeys,
        data: efficacyData,
      },
      safety: {
        outcomeNames: safetyKeys,
        data: safetyData,
      },
      tolerability: {
        outcomeNames: tolerabilityKeys,
        data: tolerabilityData,
      },
    });

    setFootnoteInfo(copyFootnoteInfo);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    message.loading("Saving Changes!", 1.8);

    request
      .post("/EditAllOutcome?productReviewId=" + productReviewId, {
        efficacy: {
          outcomeNames: efficacyKeys,
          data: efficacyData,
        },
        safety: {
          outcomeNames: safetyKeys,
          data: safetyData,
        },
        tolerability: {
          outcomeNames: tolerabilityKeys,
          data: tolerabilityData,
        },
      })
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          setLoading(false);
          message.success("Saved!");
          let temp = saveCounter;
          setDataFetched(false);
          setSaveCounter(++temp);
        } else {
          setLoading(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Error!");
      });
    setInEditMode(false);
  }

  function saveHandlerOutcome() {
    setLoadingOutcome(true);
    let values = formOutcome.getFieldsValue();
    let tempColumns = [...selectedOutcomeColumns];
    let tempData = [...selectedOutcomeData];

    tempColumns.map((oldColumn: any, i: any) => {
      let newColName = values[oldColumn["title"]];
      //check for updated column name
      if (newColName) {
        let newColDataIndex = newColName.replaceAll(" ", "").toLowerCase();
        tempData.map((item: any, j: any) => {
          item[newColDataIndex] = item[oldColumn["dataIndex"]];
          values[newColName] = newColName;
          if (newColName !== tempColumns[i].title) {
            delete item[oldColumn["dataIndex"]];
          }
        });
        tempColumns[i].title = newColName;
        tempColumns[i].dataIndex = newColDataIndex;
      }
      tempColumns[i].order = Number(i + 1);
    });

    let valueKeys = Object.keys(values);
    valueKeys.map((key: any) => {
      //update values for new columns and old columns
      let studyname = key.split("_")[0];
      let oldColumn = key.split("_")[1];
      if (key.split("_")[2]) {
        let valMinMax = key.split("_")[2];
        let column = values[oldColumn];
        if (oldColumn !== undefined && column !== undefined) {
          values[studyname + "_" + column + "_" + valMinMax] = values[key];
        }
        let colDataIndex = column.replaceAll(" ", "").toLowerCase();
        let index = tempData.findIndex(
          (data: any) => data.studyname === studyname
        );
        if (index > -1 && colDataIndex !== undefined) {
          let ratioTempValue =
            String(values[studyname + "_" + column + "_value"]) +
            "," +
            String(values[studyname + "_" + column + "_min"]) +
            "," +
            String(values[studyname + "_" + column + "_max"]);
          tempData[index][colDataIndex] =
            ratioTempValue.includes("undefined") ||
            ratioTempValue.includes("null")
              ? ""
              : ratioTempValue;
        }
      } else if (oldColumn) {
        let column = values[oldColumn];
        if (oldColumn !== undefined && column !== undefined) {
          values[studyname + "_" + column] = values[key];
        }

        let index = tempData.findIndex(
          (data: any) => data.studyname === studyname
        );
        let colDataIndex = column.replaceAll(" ", "").toLowerCase();
        if (index > -1 && column !== undefined) {
          tempData[index][colDataIndex] = values[key] ? values[key] : "";
        }
      }
    });

    console.log("saving to db", {
      outcomeForestData: {
        columns: tempColumns,
        data: tempData,
        description: values["outcomesDescription"],
        name: selectedOutcome,
        footNote: values["footnote"],
      },
    });

    const productReviewId = window.sessionStorage.getItem("productReviewId");
    message.loading("Saving Changes!", 2);

    request
      .post("/EditForestPlot?outcomeId=" + selectedOutcomeId, {
        outcomeForestData: {
          columns: tempColumns,
          data: tempData,
          description: values["outcomesDescription"],
          name: selectedOutcome,
          footNote: values["footnote"],
        },
      })
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          setLoadingOutcome(false);
          message.success("Saved!");
          setSelectedOutcomeColumns([...tempColumns]);
          setSelectedOutcomeData([...tempData]);
          setSelectedOutcomeDescription(values["outcomesDescription"]);
          setSelectedOutcomeFootnote(values["footnote"]);
          formOutcome.setFieldsValue(values);
          setInEditModeOutcome(false);
        } else {
          setLoadingOutcome(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setLoadingOutcome(false);
        console.log(err);
        message.error("Error!");
      });
  }

  function deleteOutcomeEfficacy(value: any, index: any) {
    setLoading(true);
    let tempKeys = [...efficacyKeys];
    let tempDup = [...duplicateEfficacyKeys];
    tempDup.splice(index, 1);
    tempKeys.splice(index, 1);
    setEfficacyKeys([...tempKeys]);
    setDuplicateEfficacyKeys([...tempKeys]);
    let tempData = [...efficacyData];
    tempData.map((data: any) => {
      delete data[value];
    });
    setEfficacyData([...tempData]);
    setLoading(false);
  }

  function deleteOutcomeSafety(value: any, index: any) {
    setLoading(true);
    let tempKeys = [...safetyKeys];
    let tempDup = [...duplicateSafetyKeys];
    tempDup.splice(index, 1);
    tempKeys.splice(index, 1);
    setSafetyKeys([...tempKeys]);
    setDuplicateSafetyKeys([...tempKeys]);
    let tempData = [...safetyData];
    tempData.map((data: any) => {
      delete data[value];
    });
    setSafetyData([...tempData]);
    setLoading(false);
  }

  function deleteOutcomeTolerability(value: any, index: any) {
    setLoading(true);
    let tempKeys = [...tolerabilityKeys];
    let tempDup = [...duplicateTolerabilityKeys];
    tempDup.splice(index, 1);
    tempKeys.splice(index, 1);
    setTolerabilityKeys([...tempKeys]);
    setDuplicateTolerabilityKeys([...tempKeys]);
    let tempData = [...tolerabilityData];
    tempData.map((data: any) => {
      delete data[value];
    });
    setTolerabilityData([...tempData]);
    setLoading(false);
  }

  function deleteOutcomeColumn(value: any, index: any) {
    setLoadingOutcome(true);
    let tempColumns = [...selectedOutcomeColumns];
    tempColumns.splice(index, 1);
    setSelectedOutcomeColumns([...tempColumns]);
    let tempData = [...selectedOutcomeData];
    tempData.map((data: any) => {
      delete data[value];
    });
    setSelectedOutcomeData([...tempData]);
    setLoadingOutcome(false);
  }

  function addOutcomeEfficacy() {
    setLoading(true);
    let tempKeys = [...efficacyKeys];
    let tempFormData = formEfficacy.getFieldsValue();
    let newOutcomeName = "New Outcome #" + String(outcomeNumber.current++);
    tempFormData[newOutcomeName] = newOutcomeName;
    tempKeys.push({
      name: newOutcomeName,
      id: "0",
    });
    setEfficacyKeys([...tempKeys]);
    let tempData = [...efficacyData];
    tempData.map((data: any) => {
      data[newOutcomeName] = "3";
    });
    setEfficacyData([...tempData]);
    formEfficacy.setFieldsValue(tempFormData);
    setLoading(false);
  }

  function addOutcomeSafety() {
    setLoading(true);
    let tempKeys = [...safetyKeys];
    let tempFormData = formSafety.getFieldsValue();
    let newOutcomeName = "New Outcome #" + String(outcomeNumber.current++);
    tempFormData[newOutcomeName] = newOutcomeName;
    tempKeys.push({
      name: newOutcomeName,
      id: "0",
    });
    setSafetyKeys([...tempKeys]);
    let tempData = [...safetyData];
    tempData.map((data: any) => {
      data[newOutcomeName] = "3";
    });
    setSafetyData([...tempData]);
    formSafety.setFieldsValue(tempFormData);
    setLoading(false);
  }

  function addOutcomeTolerability() {
    setLoading(true);
    let tempKeys = [...tolerabilityKeys];
    let tempFormData = formTolerability.getFieldsValue();
    let newOutcomeName = "New Outcome #" + String(outcomeNumber.current++);
    tempFormData[newOutcomeName] = newOutcomeName;
    tempKeys.push({
      name: newOutcomeName,
      id: "0",
    });
    setTolerabilityKeys([...tempKeys]);
    let tempData = [...tolerabilityData];
    tempData.map((data: any) => {
      data[newOutcomeName] = "3";
    });
    setTolerabilityData([...tempData]);
    formTolerability.setFieldsValue(tempFormData);
    setLoading(false);
  }

  function onEfficacyKeyChange(values: any) {
    setLoading(true);
    setSelectedEfficacyKeys(values);
    setLoading(false);
  }

  function onSafetyKeyChange(values: any) {
    setLoading(true);
    setSelectedSafetyKeys(values);
    setLoading(false);
  }

  function onTolerabilityKeyChange(values: any) {
    setLoading(true);
    setSelectedTolerabilityKeys(values);
    setLoading(false);
  }

  const handleCancel = () => {
    setLoading(true);
    setIsModalEfficacyOpen(false);
    setIsModalSafetyOpen(false);
    setIsModalTolerabilityOpen(false);
    setIsModalVisibleSelectedOutcome(false);
    setIsModalVisibleSelectOutcomesEfficacy(false);
    setIsModalVisibleSelectOutcomesSafety(false);
    setIsModalVisibleSelectOutcomesTolerability(false);
    setInEditModeOutcome(false);
    setLoading(false);
    setSelectedOutcomeId("");
  };

  const handleOkEfficacy = () => {
    setLoading(true);
    let values = formEfficacy.getFieldsValue();
    console.log("values", values);
    let tempKeys = [...efficacyKeys];
    let tempEfficacyData = [...efficacyData];
    tempKeys.map((oldKey: any, i: any) => {
      let originalOldKey = JSON.parse(JSON.stringify(oldKey));
      tempKeys[i].name = values[oldKey.name];
      tempEfficacyData.map((item: any, j: any) => {
        item[values[oldKey.name]] = item[oldKey.name];
        values[oldKey.name] = oldKey.name;
        delete item[originalOldKey.name];
        delete item["undefined"];
      });
    });

    let formKeys = Object.keys(values);
    formKeys.map((key: any) => {
      let studyname = key.split("_")[0];
      let oldOutcome = key.split("_")[1];
      let outcome = values[oldOutcome];
      if (oldOutcome !== undefined && outcome !== undefined) {
        values[studyname + "_" + outcome] = values[key];
      }

      let index = tempEfficacyData.findIndex(
        (data: any) => data.studyname === studyname
      );
      if (index > -1 && outcome !== undefined) {
        if (values[key] === "nonsignificant") {
          tempEfficacyData[index][outcome] = "1";
        } else if (values[key] === undefined) {
          tempEfficacyData[index][outcome] = "3";
        } else if (values[key] === "significant") {
          tempEfficacyData[index][outcome] = "2";
        }
      }
    });

    setEfficacyData([...tempEfficacyData]);
    setEfficacyKeys([...tempKeys]);
    setSelectedEfficacyKeys(tempKeys.map((item: any) => item.name));
    console.log("values after update", values, tempEfficacyData);
    formEfficacy.setFieldsValue(values);
    setIsModalEfficacyOpen(false);
    setLoading(false);
  };

  const handleOkSafety = () => {
    setLoading(true);
    let values = formSafety.getFieldsValue();
    console.log("values", values);
    let tempKeys = [...safetyKeys];
    let tempEfficacyData = [...safetyData];
    tempKeys.map((oldKey: any, i: any) => {
      let originalOldKey = JSON.parse(JSON.stringify(oldKey));
      tempKeys[i].name = values[oldKey.name];
      tempEfficacyData.map((item: any, j: any) => {
        item[values[oldKey.name]] = item[oldKey.name];
        values[oldKey.name] = oldKey.name;
        delete item[originalOldKey.name];
        delete item["undefined"];
      });
    });

    let formKeys = Object.keys(values);
    formKeys.map((key: any) => {
      let studyname = key.split("_")[0];
      let oldOutcome = key.split("_")[1];
      let outcome = values[oldOutcome];
      if (oldOutcome !== undefined && outcome !== undefined) {
        values[studyname + "_" + outcome] = values[key];
      }

      let index = tempEfficacyData.findIndex(
        (data: any) => data.studyname === studyname
      );
      if (index > -1 && outcome !== undefined) {
        if (values[key] === "nonsignificant") {
          tempEfficacyData[index][outcome] = "1";
        } else if (values[key] === undefined) {
          tempEfficacyData[index][outcome] = "3";
        } else if (values[key] === "significant") {
          tempEfficacyData[index][outcome] = "2";
        }
      }
    });
    setSafetyData([...tempEfficacyData]);
    setSafetyKeys([...tempKeys]);
    setSelectedSafetyKeys(tempKeys.map((item: any) => item.name));
    console.log("values after update", values, tempEfficacyData);
    formSafety.setFieldsValue(values);
    setIsModalSafetyOpen(false);
    setLoading(false);
  };

  const handleOkTolerability = () => {
    setLoading(true);
    let values = formTolerability.getFieldsValue();
    console.log("values", values);
    let tempKeys = [...tolerabilityKeys];
    let tempEfficacyData = [...tolerabilityData];
    tempKeys.map((oldKey: any, i: any) => {
      let originalOldKey = JSON.parse(JSON.stringify(oldKey));
      tempKeys[i].name = values[oldKey.name];
      tempEfficacyData.map((item: any, j: any) => {
        item[values[oldKey.name]] = item[oldKey.name];
        values[oldKey.name] = oldKey.name;
        delete item[originalOldKey.name];
        delete item["undefined"];
      });
    });

    let formKeys = Object.keys(values);
    formKeys.map((key: any) => {
      let studyname = key.split("_")[0];
      let oldOutcome = key.split("_")[1];
      let outcome = values[oldOutcome];
      if (oldOutcome !== undefined && outcome !== undefined) {
        values[studyname + "_" + outcome] = values[key];
      }

      let index = tempEfficacyData.findIndex(
        (data: any) => data.studyname === studyname
      );
      if (index > -1 && outcome !== undefined) {
        if (values[key] === "nonsignificant") {
          tempEfficacyData[index][outcome] = "1";
        } else if (values[key] === undefined) {
          tempEfficacyData[index][outcome] = "3";
        } else if (values[key] === "significant") {
          tempEfficacyData[index][outcome] = "2";
        }
      }
    });
    console.log(tempEfficacyData, tempKeys);
    setTolerabilityData([...tempEfficacyData]);
    setTolerabilityKeys([...tempKeys]);
    setSelectedTolerabilityKeys(tempKeys.map((item: any) => item.name));
    formTolerability.setFieldsValue(values);
    setIsModalTolerabilityOpen(false);
    setLoading(false);
  };

  function onSelectOutcome(key: any, outcomeGroup: any) {
    setSelectedOutcome(key);
    let id = "";
    if (outcomeGroup === "efficacy") {
      efficacyKeys.map((item: any) => {
        if (item.name === key) id = item.id;
      });
    } else if (outcomeGroup === "safety") {
      safetyKeys.map((item: any) => {
        if (item.name === key) id = item.id;
      });
    } else if (outcomeGroup === "tolerability") {
      tolerabilityKeys.map((item: any) => {
        if (item.name === key) id = item.id;
      });
    }
    setSelectedOutcomeId(id);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    setLoadingOutcome(true);
    request
      .get(
        "/GetForestPlot?productReviewId=" + productReviewId + "&outcomeId=" + id
      )
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200 && res.data) {
          let tempColumns = [...res.data.outcomeForestData.columns];
          tempColumns.sort((a: any, b: any) => a.order - b.order);
          let tempData = [...res.data.outcomeForestData.data];
          setSelectedOutcomeColumns([...tempColumns]);
          setSelectedOutcomeData([...tempData]);
          setSelectedOutcomeDescription(res.data.outcomeForestData.description);
          setSelectedOutcomeFootnote(res.data.outcomeForestData.footNote);
          let tempFormValues: any = {};
          tempData.map((data: any) => {
            tempColumns.map((col: any, j: any) => {
              if (
                j === tempColumns.length - 1 &&
                col.dataIndex !== "studyname"
              ) {
                let studyname = data.studyname;
                let colname = col.title;
                let keyvalue = studyname + "_" + colname + "_value";
                let keymin = studyname + "_" + colname + "_min";
                let keymax = studyname + "_" + colname + "_max";
                let colDataIndex = col.dataIndex;
                tempFormValues[keyvalue] = data[colDataIndex]?.split(",")[0];
                tempFormValues[keymin] = data[colDataIndex]?.split(",")[1];
                tempFormValues[keymax] = data[colDataIndex]?.split(",")[2];
                tempFormValues[colname] = colname;
              } else if (col.dataIndex !== "studyname") {
                let studyname = data.studyname;
                let colname = col.title;
                let key = studyname + "_" + colname;
                let colDataIndex = col.dataIndex;
                tempFormValues[key] = data[colDataIndex];
                tempFormValues[colname] = colname;
              }
            });
          });
          console.log(tempFormValues);
          tempFormValues["outcomesDescription"] =
            res.data.outcomeForestData.description;
          tempFormValues["footnote"] = res.data.outcomeForestData.footNote;
          formOutcome.setFieldsValue(tempFormValues);
        } else {
          // setLoading(false);
          // message.error("Error!");
          setSelectedOutcomeColumns([]);
          setSelectedOutcomeData([]);
          setSelectedOutcomeDescription("");
          setSelectedOutcomeFootnote("");
        }
      })
      .catch((err) => {
        setLoadingOutcome(false);
        console.log(err);
      })
      .finally(() => setLoadingOutcome(false));
    setIsModalVisibleSelectedOutcome(true);
  }

  function addOutcomeColumn() {
    setLoading(true);
    let tempColumns = [...selectedOutcomeColumns];
    let tempFormData = formOutcome.getFieldsValue();
    let newOutcomeName = "New Columns #" + String(tempColumns.length + 1);
    tempFormData[newOutcomeName] = newOutcomeName;
    tempColumns.splice(tempColumns.length - 1, 0, {
      title: newOutcomeName,
      dataIndex: newOutcomeName.replaceAll(" ", "").toLowerCase(),
    });
    setSelectedOutcomeColumns([...tempColumns]);
    console.log(tempColumns);
    let tempData = [...selectedOutcomeData];
    tempData.map((data: any) => {
      data[newOutcomeName.replaceAll(" ", "").toLowerCase()] = "";
    });
    setSelectedOutcomeData([...tempData]);
    formOutcome.setFieldsValue(tempFormData);
    setLoading(false);
  }

  const handlePopOverOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  function saveHandlerFootnote() {
    console.log("data after save", footnote);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Outcomes"];
    message.loading("Saving Changes!", 0.8).then(() => {
      request
        .post(
          "/EditFootNote?productReviewId=" +
            productReviewId +
            "&id=" +
            sectionId,
          {
            footNote: footnote,
          }
        )
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            message.success("Saved!");
            setFootnoteInitial(footnote);
          } else {
            message.error("Error!");
            setFootnote(footnoteInitial);
          }
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
    setInEditModeFootnote(false);
  }

  function showDrawerFootnote() {
    setIsLoadingFootnote(true);

    setDrawerOpen(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Outcomes"];
    request
      .get(
        "/GetFootNote?productReviewId=" + productReviewId + "&id=" + sectionId
      )
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (!res.data.footNote) setFootnote("");
          else setFootnote(res.data.footNote);
          setFootnoteInitial(res.data.footNote);
          setIsLoadingFootnote(false);
        } else {
          setIsLoadingFootnote(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoadingFootnote(false);
        console.log(err);
      });
    // setIsLoadingFootnote(false);
  }

  return (
    <div style={{ overflow: "hidden", position: "relative", height: "100%" }}>
      <div className="flex px-4 py-2 items-center bg-white">
        <p className="w-1/2 text-lg font-semibold pl-1">
          <Popover
            placement="bottomLeft"
            visible={popoverOpen}
            onVisibleChange={handlePopOverOpenChange}
            style={{ top: "4rem" }}
            overlayStyle={{
              minHeight: "2rem",
              maxWidth: "40rem",
            }}
            content={
              <div className="text-xs" style={{ maxWidth: "30rem" }}>
                <p>{screenInfo}</p>
              </div>
            }
            trigger={"hover"}
          >
            <InfoCircleOutlined
              style={{
                marginRight: "0.6rem",
                fontSize: "1.1rem",
                // marginTop: "0.2rem",
              }}
            />
          </Popover>
          Overview of Outcomes
        </p>
        {window.sessionStorage.getItem("isAdmin") === "2" && (
          <div className="flex w-1/2 justify-end items-center">
            {inEditMode ? (
              <div>
                <Button type="default" onClick={saveHandler}>
                  <SaveOutlined />
                  Save
                </Button>
                <Button
                  className="ml-2"
                  type="default"
                  onClick={() => {
                    setCopyFootnoteInfo(footnoteInfo);
                    setInEditMode(false);
                  }}
                >
                  <CloseOutlined />
                  Cancel
                </Button>
              </div>
            ) : (
              <Button type="default" onClick={() => setInEditMode(true)}>
                <EditFilled />
                Edit
              </Button>
            )}
            <TextBulletListSquare
              size={30}
              style={{
                marginLeft: "0.6rem",
                color: "#27a6a4",
                cursor: "pointer",
              }}
              onClick={showDrawerFootnote}
              title="Open Footnotes"
            />
          </div>
        )}
      </div>

      {loading ? (
        <div
          style={{ height: "80vh" }}
          className="flex mt-4 items-center justify-center mx-4 bg-white px-2 py-2 shadow-md"
        >
          <BeatLoader color={"#27A6A4"} size={30} />
        </div>
      ) : tableData.length === 0 ? (
        <div
          className="bg-white m-2 border-2 rounded-sm flex items-center justify-center"
          style={{ minHeight: "80vh", overflowY: "auto" }}
        >
          {window.sessionStorage.getItem("isAdmin") === "2" ? (
            <div className="text-center ">
              <p className="text-lg text-gray-500 mt-2 mr-4 italic font-semibold mb-1 flex items-center pl-12 ">
                Navigate to PRISMA to add Study Details &nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  to={
                    "/SLR/" +
                    window.sessionStorage.getItem("selectedBackground") +
                    "/Results/Prisma"
                  }
                >
                  <Button
                    type="default"
                    onClick={() =>
                      window.sessionStorage.setItem("redirectToStudy", "true")
                    }
                    className="p-1"
                  >
                    <ExternalLinkOutline
                      size={24}
                      style={{ right: 0, paddingBottom: "0.2rem" }}
                    />
                  </Button>
                </Link>
              </p>
            </div>
          ) : (
            <div className="text-center">
              <FileOutlined style={{ color: "#27a6a4", fontSize: "3rem" }} />
              <p className="text-xl mt-2 italic">No Details Added!</p>
            </div>
          )}
        </div>
      ) : (
        <div className="m-4 bg-white p-4 shadow-md">
          <div
            className="flex items-center"
            // style={{ maxHeight: "30px", overflow: "auto" }}
          >
            <div style={{ width: "100%" }}>
              {inEditMode && (
                <div>
                  <div className="flex items-center">
                    <p className="text-red-500 pl-2">*</p>
                    <p className="italic pl-1">
                      Click on "
                      <EditFilled
                        style={{
                          color: "#27a6a4",
                          fontSize: "1.1rem",
                        }}
                      />
                      " icon to edit the outcomes!
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-red-500 pl-2">*</p>
                    <p className="italic pl-1">
                      Save your changes after adding outcomes to enter data for
                      forest plot!
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-red-500 pl-2">*</p>
                    <p className="italic pl-1">
                      Click on an outcome to view the forest plot!
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-2">
            <div className="flex">
              <div
                style={{ width: "15%" }}
                className="flex justify-center items-start pr-2 font-semibold border-r"
              >
                <p
                  style={{ backgroundColor: "#27a6a4", width: "100%" }}
                  className="text-center p-2 text-white rounded-sm"
                >
                  Study Name
                </p>
              </div>
              <div
                style={{ width: "33%" }}
                id="effiacyHeaderDiv"
                className="flex justify-center font-semibold px-2 border-r"
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{ backgroundColor: "#27a6a4", width: "100%" }}
                    className="p-2 rounded-sm flex items-center"
                  >
                    <div
                      className={`flex items-center text-center text-white ${
                        inEditMode ? "w-11/12" : "w-full"
                      }`}
                    >
                      <p
                        className="text-center text-white 
                         w-11/12"
                      >
                        Efficacy Outcomes
                      </p>
                      <Tooltip title="Select outcomes to display!">
                        <p
                          className="text-center text-white 
                      w-1/12"
                        >
                          <InfoCircleOutlined
                            style={{
                              color: "whitesmoke",
                              fontSize: "0.9rem",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setIsModalVisibleSelectOutcomesEfficacy(true)
                            }
                          />
                        </p>
                      </Tooltip>
                    </div>

                    {inEditMode && (
                      <div className="flex items-center justify-end">
                        <EditFilled
                          style={{
                            color: "whitesmoke",
                            fontSize: "1.1rem",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsModalEfficacyOpen(true)}
                        />
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      maxHeight: "2.5rem",
                      minHeight: "2.5rem",
                      overflow: "hidden",
                      top: 0,
                    }}
                    className="flex items-start mt-2"
                  >
                    {selectedEfficacyKeys &&
                      selectedEfficacyKeys.map((key: any) => {
                        let width =
                          String(
                            Math.floor(
                              effWidth / 0.95 / selectedEfficacyKeys.length
                            ) * 0.95
                          ) + "px";
                        let keyString = key;
                        // let keyString1 = key.split(" ")[0];
                        // if (keyString1.length > 8)
                        let keyString1 = key.slice(0, 8);
                        let keyString2 = keyString.slice(8);
                        if (keyString2.length > 1) {
                          keyString1 = keyString1 + "- ";
                          if (keyString2.length > 9)
                            keyString2 = keyString2.slice(0, 6) + "...";
                        }

                        return (
                          <Tooltip
                            title={key}
                            color="#FFFFFF"
                            overlayStyle={{
                              fontSize: "0.75rem",
                            }}
                            overlayInnerStyle={{
                              color: "black",
                            }}
                          >
                            <p
                              style={{
                                // transform: "rotate(-50deg)",
                                minWidth: width,
                                maxWidth: width,
                                overflow: "hidden",
                                fontSize: "0.7rem",
                              }}
                              className="text-center px-1 mb-0 cursor-pointer"
                              onClick={() => onSelectOutcome(key, "efficacy")}
                            >
                              {keyString1}
                            </p>
                            <p
                              style={{
                                // transform: "rotate(-50deg)",
                                minWidth: width,
                                maxWidth: width,
                                overflow: "hidden",
                                fontSize: "0.7rem",
                              }}
                              className="text-center px-1 mb-0 cursor-pointer"
                              onClick={() => onSelectOutcome(key, "efficacy")}
                            >
                              {keyString2}
                            </p>
                          </Tooltip>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div
                style={{ width: "33%" }}
                id="safetyHeaderDiv"
                className="flex justify-center font-semibold px-2 border-r"
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{ backgroundColor: "#27a6a4", width: "100%" }}
                    className="p-2 rounded-sm flex items-center"
                  >
                    <div
                      className={`flex items-center text-center text-white ${
                        inEditMode ? "w-11/12" : "w-full"
                      }`}
                    >
                      <p
                        className="text-center text-white 
                         w-11/12"
                      >
                        Safety Outcomes
                      </p>
                      <Tooltip title="Select outcomes to display!">
                        <p
                          className="text-center text-white 
                      w-1/12"
                        >
                          <InfoCircleOutlined
                            style={{
                              color: "whitesmoke",
                              fontSize: "0.9rem",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setIsModalVisibleSelectOutcomesSafety(true)
                            }
                          />
                        </p>
                      </Tooltip>
                    </div>

                    {inEditMode && (
                      <div className="flex items-center justify-end">
                        <EditFilled
                          style={{
                            color: "whitesmoke",
                            fontSize: "1.1rem",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsModalSafetyOpen(true)}
                        />
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      maxHeight: "2.5rem",
                      minHeight: "2.5rem",
                      overflow: "hidden",
                      top: 0,
                    }}
                    className="flex items-start mt-2"
                  >
                    {selectedSafetyKeys &&
                      selectedSafetyKeys.map((key: any) => {
                        let width =
                          String(
                            Math.floor(
                              safWidth / 0.95 / selectedSafetyKeys.length
                            ) * 0.95
                          ) + "px";
                        let keyString = key;
                        // let keyString1 = key.split(" ")[0];
                        // if (keyString1.length > 8)
                        let keyString1 = key.slice(0, 8);
                        let keyString2 = keyString.slice(8);
                        if (keyString2.length > 1) {
                          keyString1 = keyString1 + "- ";
                          if (keyString2.length > 9)
                            keyString2 = keyString2.slice(0, 6) + "...";
                        }

                        return (
                          <Tooltip
                            title={key}
                            color="#FFFFFF"
                            overlayStyle={{
                              fontSize: "0.75rem",
                            }}
                            overlayInnerStyle={{
                              color: "black",
                            }}
                          >
                            <p
                              style={{
                                // transform: "rotate(-50deg)",
                                minWidth: width,
                                maxWidth: width,
                                overflow: "hidden",
                                fontSize: "0.7rem",
                              }}
                              className="text-center px-1 mb-0  cursor-pointer"
                              onClick={() => onSelectOutcome(key, "safety")}
                            >
                              {keyString1}
                            </p>
                            <p
                              style={{
                                // transform: "rotate(-50deg)",
                                minWidth: width,
                                maxWidth: width,
                                overflow: "hidden",
                                fontSize: "0.7rem",
                              }}
                              className="text-center px-1 mb-0 cursor-pointer"
                              onClick={() => onSelectOutcome(key, "safety")}
                            >
                              {keyString2}
                            </p>
                          </Tooltip>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div
                style={{ width: "19%" }}
                id="tolHeaderDiv"
                className="flex justify-center font-semibold px-2"
              >
                <div style={{ width: "100%" }}>
                  <div
                    style={{ backgroundColor: "#27a6a4", width: "100%" }}
                    className="p-2 rounded-sm flex items-center"
                  >
                    <div
                      className={`flex items-center text-center text-white ${
                        inEditMode ? "w-11/12" : "w-full"
                      }`}
                    >
                      <p
                        className="text-center text-white 
                         w-10/12 md:text-xs lg:text-xs xl:text-sm"
                      >
                        Tolerability Outcomes
                      </p>
                      <Tooltip title="Select outcomes to display!">
                        <p
                          className="text-center text-white 
                      w-1/12"
                        >
                          <InfoCircleOutlined
                            style={{
                              color: "whitesmoke",
                              fontSize: "0.9rem",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setIsModalVisibleSelectOutcomesTolerability(true)
                            }
                          />
                        </p>
                      </Tooltip>
                    </div>

                    {inEditMode && (
                      <div className="w-1/12 flex items-center justify-end">
                        <EditFilled
                          style={{
                            color: "whitesmoke",
                            fontSize: "1.1rem",
                            cursor: "pointer",
                          }}
                          onClick={() => setIsModalTolerabilityOpen(true)}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      maxHeight: "2.5rem",
                      minHeight: "2.5rem",
                      overflow: "hidden",
                      top: 0,
                    }}
                    className="flex items-start mt-2"
                  >
                    {selectedTolerabilityKeys &&
                      selectedTolerabilityKeys.map((key: any) => {
                        let width =
                          String(
                            Math.floor(
                              tolWidth / 0.95 / selectedTolerabilityKeys.length
                            ) * 0.95
                          ) + "px";
                        let keyString = key;
                        // let keyString1 = key.split(" ")[0];
                        // if (keyString1.length > 8)
                        let keyString1 = key.slice(0, 8);
                        let keyString2 = keyString.slice(8);
                        if (keyString2.length > 1) {
                          keyString1 = keyString1 + "- ";
                          if (keyString2.length > 9)
                            keyString2 = keyString2.slice(0, 6) + "...";
                        }
                        return (
                          <Tooltip
                            title={key}
                            color="#FFFFFF"
                            overlayStyle={{
                              fontSize: "0.75rem",
                            }}
                            overlayInnerStyle={{
                              color: "black",
                            }}
                          >
                            <p
                              style={{
                                // transform: "rotate(-50deg)",
                                minWidth: width,
                                maxWidth: width,
                                overflow: "hidden",
                                fontSize: "0.7rem",
                              }}
                              className="text-center px-1 mb-0 cursor-pointer"
                              onClick={() =>
                                onSelectOutcome(key, "tolerability")
                              }
                            >
                              {keyString1}
                            </p>
                            <p
                              style={{
                                // transform: "rotate(-50deg)",
                                minWidth: width,
                                maxWidth: width,
                                overflow: "hidden",
                                fontSize: "0.7rem",
                              }}
                              className="text-center px-1 mb-0 cursor-pointer"
                              onClick={() =>
                                onSelectOutcome(key, "tolerability")
                              }
                            >
                              {keyString2}
                            </p>
                          </Tooltip>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={
                inEditMode
                  ? { maxHeight: "57vh", overflowY: "auto" }
                  : { maxHeight: "65vh", overflowY: "auto" }
              }
              className="flex items-center border-t"
            >
              <div
                style={{ width: "15%", marginTop: "0.5rem" }}
                className="pr-2 border-r"
              >
                <table className="outcomesTable">
                  <tbody>
                    {tableData.map((row: any) => {
                      return (
                        <tr>
                          <td
                            style={{
                              backgroundColor: "#eaf8f8",
                            }}
                            className="align-middle p-2 text-center italic"
                          >
                            <div className="flex justify-end items-center">
                              <Tooltip title={studyDetails[row["key"]]}>
                                <a
                                  href={studyDetails[row["key"]]}
                                  target="_blank"
                                  style={{
                                    padding: 0,
                                    margin: 0,
                                    height: "0.7rem",
                                  }}
                                >
                                  <ExternalLinkOutline
                                    size={12}
                                    style={{ right: 0 }}
                                  />
                                </a>
                              </Tooltip>
                            </div>
                            {row.studyname}{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div
                id="efficacyDiv"
                style={{ width: "33%" }}
                className="px-2 border-r flex justify-start"
              >
                {selectedEfficacyKeys.length !== 0 ? (
                  <HeatMap
                    width={effWidth}
                    height={50 * tableData.length}
                    margin={{ left: 0, top: 8 }}
                    data={efficacyData}
                    maxValue={3}
                    minValue={1}
                    cellBorderColor="#FFFFFF"
                    cellBorderWidth={4}
                    indexBy="studyname"
                    keys={selectedEfficacyKeys}
                    axisTop={null}
                    theme={theme}
                    axisLeft={null}
                    enableLabels={false}
                    colors={["#B2B0B6", "#3CB043", "#FFFFFF"]}
                    hoverTarget="cell"
                    tooltip={(data) => {
                      return (
                        <div className="flex text-xs items-center justify-center">
                          <div
                            style={{ backgroundColor: data.color }}
                            className="h-3 w-3 mr-1"
                          />
                          <p>
                            {data.yKey} - {data.xKey}:
                          </p>
                          <p className="font-semibold ml-2">
                            {data.label === "1"
                              ? "Non Significant"
                              : data.label === "2"
                              ? "Significant"
                              : "None"}
                          </p>
                        </div>
                      );
                    }}
                  />
                ) : (
                  <div
                    style={{ width: "100%" }}
                    className="text-center flex items-center justify-center"
                  >
                    <EditFilled
                      style={{ color: "#27a6a4", fontSize: "1.2rem" }}
                    />
                    <p className="text-lg ml-2 italic">
                      Click on Edit to add Outcomes!
                    </p>
                  </div>
                )}
              </div>
              <div
                id="safetyDiv"
                style={{ width: "33%" }}
                className="px-2 border-r flex justify-start"
              >
                {selectedSafetyKeys.length !== 0 ? (
                  <HeatMap
                    width={safWidth}
                    height={50 * tableData.length}
                    maxValue={3}
                    minValue={1}
                    margin={{ left: 0, top: 8 }}
                    cellBorderColor="#FFFFFF"
                    cellBorderWidth={4}
                    data={safetyData}
                    indexBy="studyname"
                    keys={selectedSafetyKeys}
                    axisTop={null}
                    theme={theme}
                    axisLeft={null}
                    enableLabels={false}
                    colors={["#B2B0B6", "#3CB043", "#FFFFFF"]}
                    onClick={(data) => {
                      console.log(data);
                    }}
                    hoverTarget="cell"
                    tooltip={(data) => {
                      return (
                        <div className="flex text-xs items-center justify-center">
                          <div
                            style={{ backgroundColor: data.color }}
                            className="h-3 w-3 mr-1"
                          />
                          <p>
                            {data.yKey} - {data.xKey}:
                          </p>
                          <p className="font-semibold ml-2">
                            {data.label === "1"
                              ? "Non Significant"
                              : data.label === "2"
                              ? "Significant"
                              : "None"}
                          </p>
                        </div>
                      );
                    }}
                  />
                ) : (
                  <div
                    style={{ width: "100%" }}
                    className="text-center flex items-center justify-center"
                  >
                    <EditFilled
                      style={{ color: "#27a6a4", fontSize: "1.2rem" }}
                    />
                    <p className="text-lg ml-2 italic">
                      Click on Edit to add Outcomes!
                    </p>
                  </div>
                )}
              </div>
              <div
                id="tolerabilityDiv"
                style={{ width: "19%" }}
                className="px-2 border-r flex justify-start"
              >
                {selectedTolerabilityKeys.length !== 0 ? (
                  <HeatMap
                    width={tolWidth}
                    height={50 * tableData.length}
                    maxValue={3}
                    minValue={1}
                    margin={{ left: 0, top: 8 }}
                    cellBorderColor="#FFFFFF"
                    cellBorderWidth={4}
                    data={tolerabilityData}
                    indexBy="studyname"
                    keys={selectedTolerabilityKeys}
                    axisTop={null}
                    theme={theme}
                    axisLeft={null}
                    enableLabels={false}
                    colors={["#B2B0B6", "#3CB043", "#FFFFFF"]}
                    onClick={(data) => {
                      console.log(data);
                    }}
                    hoverTarget="cell"
                    tooltip={(data) => {
                      return (
                        <div className="flex text-xs items-center justify-center">
                          <div
                            style={{ backgroundColor: data.color }}
                            className="h-3 w-3 mr-1"
                          />
                          <p>
                            {data.yKey} - {data.xKey}:
                          </p>
                          <p className="font-semibold ml-2">
                            {data.label === "1"
                              ? "Non Significant"
                              : data.label === "2"
                              ? "Significant"
                              : "None"}
                          </p>
                        </div>
                      );
                    }}
                  />
                ) : (
                  <div
                    style={{ width: "100%" }}
                    className="text-center flex items-center justify-center"
                  >
                    <EditFilled
                      style={{ color: "#27a6a4", fontSize: "1.2rem" }}
                    />
                    <p className=" ml-2 italic">
                      Click on Edit to add Outcomes!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            {/* {inEditMode ? (
              <textarea
                rows={3}
                defaultValue={copyFootnoteInfo}
                onChange={(e) => setCopyFootnoteInfo(e.target.value)}
              />
            ) : (
              <textarea rows={3} value={footnoteInfo} />
            )} */}
          </div>
        </div>
      )}

      <Modal
        title={<p className="text-white">Efficacy Outcomes</p>}
        className="editTableModal"
        visible={isModalEfficacyOpen}
        onCancel={handleCancel}
        width={"85rem"}
        footer={null}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
      >
        <Form form={formEfficacy} name="userResponses">
          {inEditMode && (
            <div className="flex items-center justify-end">
              <div className="flex items-center" onClick={addOutcomeEfficacy}>
                <PlusCircleOutlined
                  style={{
                    color: "#27a6a4",
                    fontSize: "1rem",
                    cursor: "pointer",
                    marginLeft: "0.2rem",
                  }}
                />
                <p
                  style={{
                    color: "#27a6a4",
                  }}
                  className="cursor-pointer ml-1 font-semibold"
                >
                  Add Outcome
                </p>
              </div>
            </div>
          )}
          <Row>
            <Col span={3}>
              <table className="outcomesTable mt-2">
                <thead>
                  <tr className="hover:bg-transparent">
                    <th
                      style={{
                        backgroundColor: "#27a6a4",
                        color: "whitesmoke",
                      }}
                      className="align-top p-2 text-center border-b"
                    >
                      Study Name
                    </th>
                  </tr>
                </thead>
                <div className="h-0.5 bg-gray-300" />
                <tbody>
                  {tableData.map((row: any) => {
                    return (
                      <tr className="hover:bg-transparent">
                        <td
                          style={{
                            backgroundColor: "#eaf8f8",
                          }}
                          className="align-middle p-1 text-center italic"
                        >
                          <div className="flex justify-end items-center">
                            <Tooltip title={studyDetails[row["key"]]}>
                              <a
                                href={studyDetails[row["key"]]}
                                target="_blank"
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  height: "0.7rem",
                                }}
                              >
                                <ExternalLinkOutline
                                  size={12}
                                  style={{ right: 0 }}
                                />
                              </a>
                            </Tooltip>
                          </div>
                          {row.studyname}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
            <Col span={21} style={{ overflowX: "auto" }}>
              <div className="outcomesForm">
                <div className="flex items-center pt-2">
                  {efficacyKeys.map((key: any, index: any) => {
                    return (
                      <div
                        id={index}
                        style={{
                          minWidth: "140px",
                          maxWidth: "140px",
                          backgroundColor: "#F4F3F3",
                        }}
                        className="px-2 pt-1 flex justify-start mx-1 items-center border"
                      >
                        <Form.Item name={key.name}>
                          <input
                            style={{
                              width: "95%",
                              // border: "1px solid gray",
                              backgroundColor: "transparent",
                            }}
                            // defaultValue={key}
                            // onChange={(e) =>
                            //   onOutcomeNameChangeEfficacy(e.target.value, index)
                            // }
                            className="px-2"
                          />
                        </Form.Item>
                        <DeleteOutlined
                          style={{
                            color: "#27a6a4",
                            fontSize: "1rem",
                            cursor: "pointer",
                            marginLeft: "0.2rem",
                          }}
                          onClick={() => deleteOutcomeEfficacy(key.name, index)}
                        />
                      </div>
                    );
                  })}
                </div>

                {tableData.map((t: any, i: any) => {
                  return (
                    <div
                      className="flex items-center"
                      style={{ height: "47.05px" }}
                    >
                      {efficacyKeys.map((key: any, j: any) => {
                        return (
                          <div
                            style={{ minWidth: "140px" }}
                            className="flex justify-center items-center mt-1 mx-1"
                          >
                            <Form.Item name={t.studyname + "_" + key.name}>
                              <Select
                                placeholder="Select Value"
                                style={{ minWidth: "140px" }}
                                id={t.studyname + "_" + key.name}
                              >
                                <Select.Option value="significant">
                                  Significant
                                </Select.Option>
                                <Select.Option value="nonsignificant">
                                  Non Significant
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <div className="flex mt-4 w-full justify-end outcomesForm">
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={handleOkEfficacy}
                type="default"
              >
                OK
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        title={<p className="text-white">Safety Outcomes</p>}
        className="editTableModal"
        visible={isModalSafetyOpen}
        width={"85rem"}
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
      >
        <Form form={formSafety} name="userResponses">
          {inEditMode && (
            <div className="flex items-center justify-end">
              <div className="flex items-center" onClick={addOutcomeSafety}>
                <PlusCircleOutlined
                  style={{
                    color: "#27a6a4",
                    fontSize: "1rem",
                    cursor: "pointer",
                    marginLeft: "0.2rem",
                  }}
                />
                <p
                  style={{
                    color: "#27a6a4",
                  }}
                  className="cursor-pointer ml-1 font-semibold"
                >
                  Add Outcome
                </p>
              </div>
            </div>
          )}
          <Row>
            <Col span={3}>
              <table className="outcomesTable mt-2">
                <thead>
                  <tr className="hover:bg-transparent">
                    <th
                      style={{
                        backgroundColor: "#27a6a4",
                        color: "whitesmoke",
                      }}
                      className="align-top p-2 text-center border-b"
                    >
                      Study Name
                    </th>
                  </tr>
                </thead>
                <div className="h-0.5 bg-gray-300" />
                <tbody>
                  {tableData.map((row: any) => {
                    return (
                      <tr className="hover:bg-transparent">
                        <td
                          style={{
                            backgroundColor: "#eaf8f8",
                          }}
                          className="align-middle p-1 text-center italic"
                        >
                          <div className="flex justify-end items-center">
                            <Tooltip title={studyDetails[row["key"]]}>
                              <a
                                href={studyDetails[row["key"]]}
                                target="_blank"
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  height: "0.7rem",
                                }}
                              >
                                <ExternalLinkOutline
                                  size={12}
                                  style={{ right: 0 }}
                                />
                              </a>
                            </Tooltip>
                          </div>
                          {row.studyname}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
            <Col span={21} style={{ overflowX: "auto" }}>
              <div className="outcomesForm">
                <div className="flex items-center pt-2">
                  {safetyKeys.map((key: any, index: any) => {
                    return (
                      <div
                        id={index}
                        style={{
                          minWidth: "140px",
                          maxWidth: "140px",
                          backgroundColor: "#F4F3F3",
                        }}
                        className="px-2 pt-1 flex justify-start mx-1 items-center border"
                      >
                        <Form.Item name={key.name}>
                          <input
                            style={{
                              width: "95%",
                              // border: "1px solid gray",
                              backgroundColor: "transparent",
                            }}
                            // defaultValue={key}
                            // onChange={(e) =>
                            //   onOutcomeNameChangeSafety(e.target.value, index)
                            // }
                            className="px-2"
                          />
                        </Form.Item>
                        <DeleteOutlined
                          style={{
                            color: "#27a6a4",
                            fontSize: "1rem",
                            cursor: "pointer",
                            marginLeft: "0.2rem",
                          }}
                          onClick={() => deleteOutcomeSafety(key.name, index)}
                        />
                      </div>
                    );
                  })}
                </div>

                {tableData.map((t: any, i: any) => {
                  return (
                    <div
                      className="flex items-center"
                      style={{ height: "47.05px" }}
                    >
                      {safetyKeys.map((key: any, j: any) => {
                        return (
                          <div
                            style={{ minWidth: "140px" }}
                            className="flex justify-center items-center mt-1 mx-1"
                          >
                            <Form.Item name={t.studyname + "_" + key.name}>
                              <Select
                                placeholder="Select Value"
                                style={{ minWidth: "140px" }}
                                id={t.studyname + "_" + key.name}
                              >
                                <Select.Option value="significant">
                                  Significant
                                </Select.Option>
                                <Select.Option value="nonsignificant">
                                  Non Significant
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <div className="flex mt-4 w-full justify-end outcomesForm">
            <Form.Item>
              <Button htmlType="submit" onClick={handleOkSafety} type="default">
                OK
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        destroyOnClose={true}
        title={<p className="text-white">Tolerability Outcomes</p>}
        className="editTableModal"
        visible={isModalTolerabilityOpen}
        width={"43rem"}
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
      >
        <Form form={formTolerability} name="userResponses">
          {inEditMode && (
            <div className="flex items-center justify-end">
              <div
                className="flex items-center"
                onClick={addOutcomeTolerability}
              >
                <PlusCircleOutlined
                  style={{
                    color: "#27a6a4",
                    fontSize: "1rem",
                    cursor: "pointer",
                    marginLeft: "0.2rem",
                  }}
                />
                <p
                  style={{
                    color: "#27a6a4",
                  }}
                  className="cursor-pointer ml-1 font-semibold"
                >
                  Add Outcome
                </p>
              </div>
            </div>
          )}
          <Row>
            <Col span={7}>
              <table className="outcomesTable mt-2">
                <thead>
                  <tr className="hover:bg-transparent">
                    <th
                      style={{
                        backgroundColor: "#27a6a4",
                        color: "whitesmoke",
                      }}
                      className="align-top p-2 text-center border-b"
                    >
                      Study Name
                    </th>
                  </tr>
                </thead>
                <div className="h-0.5 bg-gray-300" />
                <tbody>
                  {tableData.map((row: any) => {
                    return (
                      <tr className="hover:bg-transparent">
                        <td
                          style={{
                            backgroundColor: "#eaf8f8",
                          }}
                          className="align-middle p-1 text-center italic"
                        >
                          <div className="flex justify-end items-center">
                            <Tooltip title={studyDetails[row["key"]]}>
                              <a
                                href={studyDetails[row["key"]]}
                                target="_blank"
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  height: "0.7rem",
                                }}
                              >
                                <ExternalLinkOutline
                                  size={12}
                                  style={{ right: 0 }}
                                />
                              </a>
                            </Tooltip>
                          </div>
                          {row.studyname}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
            <Col span={17} style={{ overflowX: "auto" }}>
              <div className="outcomesForm">
                <div className="flex items-center pt-2">
                  {tolerabilityKeys.map((key: any, index: any) => {
                    return (
                      <div
                        id={index}
                        style={{
                          minWidth: "140px",
                          maxWidth: "140px",
                          backgroundColor: "#F4F3F3",
                        }}
                        className="px-2 pt-1 flex justify-start mx-1 items-center border"
                      >
                        <Form.Item name={key.name}>
                          <input
                            style={{
                              width: "95%",
                              // border: "1px solid gray",
                              backgroundColor: "transparent",
                            }}
                            // defaultValue={key}
                            // onChange={(e) =>
                            //   onOutcomeNameChangeTolerability(e.target.value, index)
                            // }
                            className="px-2"
                          />
                        </Form.Item>
                        <DeleteOutlined
                          style={{
                            color: "#27a6a4",
                            fontSize: "1rem",
                            cursor: "pointer",
                            marginLeft: "0.2rem",
                          }}
                          onClick={() => deleteOutcomeTolerability(key, index)}
                        />
                      </div>
                    );
                  })}
                </div>

                {tableData.map((t: any, i: any) => {
                  return (
                    <div
                      className="flex items-center"
                      style={{ height: "47.05px" }}
                    >
                      {tolerabilityKeys.map((key: any, j: any) => {
                        return (
                          <div
                            style={{ minWidth: "140px" }}
                            className="flex justify-center items-center mt-1 mx-1"
                          >
                            <Form.Item name={t.studyname + "_" + key.name}>
                              <Select
                                placeholder="Select Value"
                                style={{ minWidth: "140px" }}
                                id={t.studyname + "_" + key.name}
                              >
                                <Select.Option value="significant">
                                  Significant
                                </Select.Option>
                                <Select.Option value="nonsignificant">
                                  Non Significant
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
          <div className="flex mt-4 w-full justify-end outcomesForm">
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={handleOkTolerability}
                type="default"
              >
                OK
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      <Modal
        title={
          <p className={`${inEditModeOutcome ? "text-white" : "text-black"}`}>
            {selectedOutcome}
          </p>
        }
        className={`${
          inEditModeOutcome ? "editTableModal outcomeModal" : "outcomeModal"
        }`}
        visible={isModalVisibleSelectedOutcome}
        onCancel={handleCancel}
        width={"80rem"}
        footer={null}
        closeIcon={
          <CloseOutlined
            style={{
              color: inEditModeOutcome ? "whitesmoke" : "black",
              marginBottom: "0.5rem",
            }}
          />
        }
      >
        <Form form={formOutcome} name="userResponses" style={{ width: "100%" }}>
          <div className="flex">
            <div style={{ width: "80%" }}>
              {loadingOutcome ? (
                ""
              ) : inEditModeOutcome ? (
                <Form.Item
                  style={{ marginBottom: "10px" }}
                  name={"outcomesDescription"}
                >
                  <textarea
                    style={{ width: "100%", border: "2px solid teal" }}
                    placeholder={`Enter description for ${selectedOutcome}`}
                    className="rounded-md px-2"
                  />
                </Form.Item>
              ) : (
                <textarea
                  style={{ width: "100%", maxHeight: "5rem", resize: "none" }}
                  className="rounded-md px-2"
                  rows={3}
                  value={selectedOutcomeDescription}
                />
              )}
            </div>
            <div style={{ width: "20%" }}>
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <div className="flex justify-end items-center">
                  {inEditModeOutcome ? (
                    <div className="flex items-center justify-end">
                      <div
                        className="flex items-center mr-4"
                        onClick={addOutcomeColumn}
                      >
                        <PlusCircleOutlined
                          style={{
                            color: "#27a6a4",
                            fontSize: "1rem",
                            cursor: "pointer",
                            marginLeft: "0.2rem",
                          }}
                        />
                        <p
                          style={{
                            color: "#27a6a4",
                          }}
                          className="cursor-pointer ml-1 font-semibold"
                        >
                          Add Column
                        </p>
                      </div>

                      <Button type="default" onClick={saveHandlerOutcome}>
                        <SaveOutlined />
                        Save
                      </Button>
                    </div>
                  ) : (
                    <Button
                      type="default"
                      onClick={() => {
                        setInEditModeOutcome(true);
                      }}
                    >
                      <EditFilled />
                      Edit
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="">
            {loadingOutcome ? (
              <div
                style={{ height: "22rem" }}
                className="flex mt-4 items-center justify-center mx-4 bg-white px-2 py-2"
              >
                <BeatLoader color={"#27A6A4"} size={30} />
              </div>
            ) : inEditModeOutcome ? (
              <div style={{ overflowX: "auto", width: "100%" }}>
                <div
                  className="flex border-b"
                  style={{ overflowX: "auto", width: "100%" }}
                >
                  <Row
                    style={{
                      height: "20rem",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    <Col span={5}>
                      <table className="fixedHeader">
                        <thead>
                          <tr className="roundedRows">
                            <th
                              style={{
                                backgroundColor: "#27a6a4",
                                color: "whitesmoke",
                                // minWidth: "10rem",
                                // maxWidth: "10rem",
                              }}
                              className="font-bold text-center px-2"
                            >
                              Study Name
                            </th>
                          </tr>
                        </thead>
                        <div className="h-1"></div>
                        <tbody>
                          {selectedOutcomeData.map((row: any, index: any) => {
                            const color = index % 2 === 0 ? "#eaf8f8" : "white";
                            return (
                              <tr
                                className="hover:bg-transparent roundedRows"
                                style={{ backgroundColor: color }}
                              >
                                <td
                                  style={{
                                    padding: "0.2rem",
                                    minHeight: "40px",
                                  }}
                                  className="align-center text-center italic"
                                >
                                  <div className="flex justify-end items-center">
                                    <Tooltip title={studyDetails[row["key"]]}>
                                      <a
                                        href={studyDetails[row["key"]]}
                                        target="_blank"
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          height: "0.7rem",
                                        }}
                                      >
                                        <ExternalLinkOutline
                                          size={12}
                                          style={{ right: 0 }}
                                        />
                                      </a>
                                    </Tooltip>
                                  </div>
                                  {row["studyname"]}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Col>
                    <Col span={19} style={{ overflowX: "auto" }}>
                      <div className="outcomesForm">
                        <div className="flex items-center">
                          {selectedOutcomeColumns.map(
                            (key: any, index: any) => {
                              if (key.dataIndex === "studyname") return "";
                              else
                                return (
                                  <div
                                    id={index}
                                    style={{
                                      minWidth:
                                        index ===
                                        selectedOutcomeColumns.length - 1
                                          ? "220px"
                                          : "250px",
                                      maxWidth:
                                        index ===
                                        selectedOutcomeColumns.length - 1
                                          ? "220px"
                                          : "250px",
                                      backgroundColor: "#27a6a4",
                                    }}
                                    className="px-2 py-1.5 flex justify-start mx-1 items-center border"
                                  >
                                    <Form.Item
                                      name={key["title"]}
                                      style={{ height: "100%" }}
                                    >
                                      {key.dataIndex === "comparison" ? (
                                        <input
                                          style={{
                                            width: "100%",
                                            // border: "1px solid whitesmoke",
                                            backgroundColor: "transparent",
                                          }}
                                          disabled
                                          className="px-2 text-white text-center font-bold"
                                          placeholder="Enter Column Name"
                                        />
                                      ) : (
                                        <input
                                          style={{
                                            width: "95%",
                                            border: "1px solid whitesmoke",
                                            backgroundColor: "transparent",
                                          }}
                                          className="px-2 text-white font-bold"
                                          placeholder="Enter Column Name"
                                        />
                                      )}
                                    </Form.Item>
                                    {key.dataIndex === "studyname" ||
                                    key.dataIndex === "comparison" ||
                                    index ===
                                      selectedOutcomeColumns.length - 1 ? (
                                      ""
                                    ) : (
                                      <DeleteOutlined
                                        style={{
                                          color: "whitesmoke",
                                          fontSize: "1rem",
                                          cursor: "pointer",
                                          marginLeft: "0.2rem",
                                          // paddingBottom: "0.2rem",
                                        }}
                                        onClick={() =>
                                          deleteOutcomeColumn(
                                            key["title"],
                                            index
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                );
                            }
                          )}
                        </div>

                        <div className="mt-1">
                          {selectedOutcomeData.map((t: any, i: any) => {
                            return (
                              <div
                                className="flex items-center"
                                style={{
                                  minHeight: "42px",
                                }}
                              >
                                {selectedOutcomeColumns.map(
                                  (key: any, j: any) => {
                                    if (key["dataIndex"] === "studyname")
                                      return "";
                                    else if (
                                      j ===
                                      selectedOutcomeColumns.length - 1
                                    )
                                      return (
                                        <div
                                          style={{
                                            minWidth: "220px",
                                            maxWidth: "220px",
                                          }}
                                          className="flex justify-around items-center mx-1"
                                        >
                                          <Form.Item
                                            name={
                                              t.studyname +
                                              "_" +
                                              key["title"] +
                                              "_value"
                                            }
                                          >
                                            <InputNumber
                                              placeholder="Value"
                                              // min={0}
                                              precision={2}
                                              step={0.01}
                                              // max={2}
                                              size="small"
                                              style={{
                                                backgroundColor: "#eaf3f8",
                                              }}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            name={
                                              t.studyname +
                                              "_" +
                                              key["title"] +
                                              "_min"
                                            }
                                          >
                                            <InputNumber
                                              placeholder="Min"
                                              // min={0}
                                              precision={2}
                                              step={0.01}
                                              // max={2}
                                              size="small"
                                              style={{
                                                backgroundColor: "#eaf3f8",
                                              }}
                                            />
                                          </Form.Item>
                                          <Form.Item
                                            name={
                                              t.studyname +
                                              "_" +
                                              key["title"] +
                                              "_max"
                                            }
                                          >
                                            <InputNumber
                                              placeholder="Max"
                                              // min={0}
                                              precision={2}
                                              step={0.01}
                                              // max={2}
                                              size="small"
                                              style={{
                                                backgroundColor: "#eaf3f8",
                                              }}
                                            />
                                          </Form.Item>
                                        </div>
                                      );
                                    else
                                      return (
                                        <div
                                          style={{
                                            minWidth: "250px",
                                            maxWidth: "250px",
                                          }}
                                          className="flex justify-center items-center mx-1"
                                        >
                                          <Form.Item
                                            name={
                                              t.studyname + "_" + key["title"]
                                            }
                                            style={{
                                              width: "95%",
                                            }}
                                          >
                                            <input
                                              style={{
                                                width: "100%",
                                                backgroundColor: "#eaf3f8",
                                                border: "1px solid #a1a1a17e",
                                              }}
                                              className="px-2 py-0.5"
                                              placeholder="Enter Data"
                                            />
                                          </Form.Item>
                                        </div>
                                      );
                                  }
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  style={{ width: "100%" }}
                  className="flex justify-start items-center mt-4"
                >
                  <div
                    style={{
                      width: "80%",
                      overflowY: "auto",
                    }}
                  >
                    <Form.Item
                      style={{ marginBottom: "10px" }}
                      name={"footnote"}
                    >
                      <textarea
                        style={{ width: "100%", border: "2px solid teal" }}
                        placeholder="Enter footnotes"
                        rows={4}
                        className="rounded-md px-2"
                      />
                    </Form.Item>
                  </div>
                  <div
                    className="flex justify-end items-center"
                    style={{ width: "20%" }}
                  >
                    <Button
                      type="default"
                      onClick={() => setInEditModeOutcome(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  overflowX: "auto",
                  height: "20rem",
                  overflowY: "auto",
                }}
                className="fixedHeader mt-5"
              >
                <table
                  className="forestPlotTable"
                  style={{
                    overflowX: "auto",
                  }}
                >
                  <thead>
                    <tr className="roundedRows">
                      {selectedOutcomeColumns.map((col: any, index: any) => {
                        if (index === selectedOutcomeColumns.length - 1) {
                          return (
                            <th
                              style={{
                                backgroundColor: "#27a6a4",
                                color: "whitesmoke",
                                minWidth: "10rem",
                                // maxWidth: "10rem",
                                zIndex: 2,
                              }}
                              className="font-bold text-center px-2"
                            >
                              {col.title}
                            </th>
                          );
                        } else
                          return (
                            <th
                              style={{
                                backgroundColor: "#27a6a4",
                                color: "whitesmoke",
                              }}
                              className="font-bold text-center px-2"
                            >
                              {col.title}
                            </th>
                          );
                      })}
                    </tr>
                  </thead>
                  <div className="h-1"></div>
                  <tbody>
                    {selectedOutcomeData.map((row: any, index: any) => {
                      const color = index % 2 === 0 ? "#eaf8f8" : "white";
                      const keys: any = [];
                      selectedOutcomeColumns.map((c: any) =>
                        keys.push(c["dataIndex"])
                      );
                      const temp = keys.indexOf("key");
                      if (temp > -1) {
                        keys.splice(temp, 1);
                      }
                      return (
                        <tr
                          className="hover:bg-transparent roundedRows"
                          style={{ backgroundColor: color }}
                        >
                          {keys.map((key: any, i: any) => {
                            if (i === keys.length - 1 && row[key]) {
                              let dotValue =
                                Number(row[key].split(",")[0]) * 100;
                              let color =
                                dotValue < 100 ? "#00AA67" : "#B21111";
                              let minLineValue =
                                Number(row[key].split(",")[1]) * 100;
                              let maxLineValue =
                                Number(row[key].split(",")[2]) * 100;
                              let width1 = maxLineValue - minLineValue;
                              return (
                                <td
                                  style={{ width: "20rem" }}
                                  className="align-top p-2 text-center"
                                >
                                  <div className="px-4 flex items-center">
                                    <div
                                      style={{
                                        minWidth: "200px",
                                        // maxWidth: "200px",
                                        position: "relative",
                                        height: "40px",
                                      }}
                                      className="flex items-center"
                                    >
                                      <div
                                        style={{
                                          position: "absolute",
                                          backgroundColor: color,
                                          left: minLineValue,
                                          width: width1,
                                          height: "1.5px",
                                        }}
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          backgroundColor: color,
                                          left: dotValue,
                                          width: "6px",
                                          height: "6px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                      <div
                                        style={{
                                          width: "1px",
                                          height: "100%",
                                          backgroundColor: "black",
                                          left: "100px",
                                          position: "absolute",
                                        }}
                                      />
                                    </div>
                                    <div className="w-full ml-2 flex items-center justify-end">
                                      <p style={{ color: color }}>
                                        {row[key].split(",")[0]}&nbsp;
                                      </p>
                                      <p style={{ color: color }}>
                                        ({row[key].split(",")[1]},&nbsp;
                                      </p>
                                      <p style={{ color: color }}>
                                        {row[key].split(",")[2]})
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              );
                            } else if (key === "studyname") {
                              return (
                                <td
                                  style={{
                                    padding: "0.2rem",
                                    minHeight: "40px",
                                  }}
                                  className="align-center text-center italic"
                                >
                                  <div className="flex justify-end items-center">
                                    <Tooltip title={studyDetails[row["key"]]}>
                                      <a
                                        href={studyDetails[row["key"]]}
                                        target="_blank"
                                        style={{
                                          padding: 0,
                                          margin: 0,
                                          height: "0.7rem",
                                        }}
                                      >
                                        <ExternalLinkOutline
                                          size={12}
                                          style={{ right: 0 }}
                                        />
                                      </a>
                                    </Tooltip>
                                  </div>
                                  {row["studyname"]}
                                </td>
                              );
                            } else
                              return (
                                <td
                                  // style={{ width: "30rem" }}
                                  className="align-center p-2 text-center"
                                >
                                  <ReactMarkdown
                                    children={row[key]}
                                  ></ReactMarkdown>
                                </td>
                              );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Form>
        {!inEditModeOutcome &&
          selectedOutcomeColumns.length !== 0 &&
          !loadingOutcome && (
            <div className="flex items-center justify-end mr-28">
              <p className="text-xs">Favors Intervention</p>
              <p className="text-xs ml-12">Favors Comparator</p>
            </div>
          )}
        {!inEditModeOutcome &&
          selectedOutcomeColumns.length !== 0 &&
          !loadingOutcome && (
            <div style={{ overflow: "hidden" }}>
              <textarea
                style={{
                  width: "100%",
                  maxHeight: "10rem",
                  overflowY: "scroll",
                  marginTop: "0.6rem",
                  resize: "none",
                  paddingRight: "17px",
                  boxSizing: "content-box",
                }}
                rows={3}
                className="px-2 italic rounded-md"
                value={selectedOutcomeFootnote}
              />
            </div>
          )}
      </Modal>

      <Modal
        title={<p className="text-white">Select Efficacy Outcomes</p>}
        className="editTableModal"
        visible={isModalVisibleSelectOutcomesEfficacy}
        onOk={handleCancel}
        onCancel={handleCancel}
        width={"30rem"}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
        footer={[
          <Button type="primary" onClick={handleCancel}>
            OK
          </Button>,
        ]}
      >
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "95%" }}
          placeholder="Select Outcomes"
          defaultValue={efficacyKeys.map((item: any) => item.name)}
          onChange={onEfficacyKeyChange}
          // maxTagTextLength={10}
          // maxTagCount={3}
        >
          {efficacyKeys.map((key: any) => {
            return <Select.Option value={key.name}>{key.name}</Select.Option>;
          })}
        </Select>
      </Modal>
      <Modal
        title={<p className="text-white">Select Safety Outcomes</p>}
        className="editTableModal"
        visible={isModalVisibleSelectOutcomesSafety}
        onOk={handleCancel}
        onCancel={handleCancel}
        width={"30rem"}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
        footer={[
          <Button type="primary" onClick={handleCancel}>
            OK
          </Button>,
        ]}
      >
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "95%" }}
          placeholder="Select Outcomes"
          defaultValue={safetyKeys.map((item: any) => item.name)}
          onChange={onSafetyKeyChange}
          // maxTagTextLength={10}
          // maxTagCount={3}
        >
          {safetyKeys.map((key: any) => {
            return <Select.Option value={key.name}>{key.name}</Select.Option>;
          })}
        </Select>
      </Modal>
      <Modal
        title={<p className="text-white">Select Tolerability Outcomes</p>}
        className="editTableModal"
        visible={isModalVisibleSelectOutcomesTolerability}
        onOk={handleCancel}
        onCancel={handleCancel}
        width={"30rem"}
        closeIcon={
          <CloseOutlined
            style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
          />
        }
        footer={[
          <Button type="primary" onClick={handleCancel}>
            OK
          </Button>,
        ]}
      >
        <Select
          mode="multiple"
          allowClear={true}
          style={{ width: "95%" }}
          placeholder="Select Outcomes"
          defaultValue={tolerabilityKeys.map((item: any) => item.name)}
          onChange={onTolerabilityKeyChange}
          // maxTagTextLength={10}
          // maxTagCount={3}
        >
          {tolerabilityKeys.map((key: any) => {
            return <Select.Option value={key.name}>{key.name}</Select.Option>;
          })}
        </Select>
      </Modal>
      <Drawer
        title={
          <div className="flex">
            <p className="text-black mb-0 w-1/2">Footnotes</p>
            <div className="flex w-1/2 justify-end items-center">
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <div className="flex w-full justify-end items-center">
                  {inEditModeFootnote ? (
                    <div className="flex">
                      <Tooltip title="Save Changes">
                        <SaveOutlined
                          onClick={saveHandlerFootnote}
                          style={{ color: "black", fontSize: "1.2rem" }}
                        />
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <UndoOutlined
                          onClick={() => {
                            setInEditModeFootnote(false);
                            setFootnote(footnoteInitial);
                          }}
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            marginLeft: "0.6rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Edit Footnotes">
                      <EditFilled
                        onClick={() => setInEditModeFootnote(true)}
                        style={{ color: "black", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Close">
                    <CloseOutlined
                      onClick={() => {
                        setDrawerOpen(false);
                        setInEditModeFootnote(false);
                      }}
                      style={{
                        color: "black",
                        fontSize: "1.2rem",
                        marginLeft: "0.6rem",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        }
        closable={false}
        placement="bottom"
        onClose={() => {
          setDrawerOpen(false);
          setInEditModeFootnote(false);
        }}
        getContainer={false}
        visible={drawerOpen}
        style={{ position: "absolute" }}
        zIndex={20}
        bodyStyle={{ padding: "0.5rem" }}
        headerStyle={{
          paddingTop: "0.6rem",
          paddingBottom: "0.6rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "whitesmoke",
          color: "black",
        }}
      >
        {isLoadingFootnote ? (
          <div
            style={{ minHeight: "23vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : footnote === "" && !inEditModeFootnote ? (
          <div
            className="border flex items-center justify-center"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Edit icon to add footnotes!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">No Footnotes Added!</p>
              </div>
            )}
          </div>
        ) : inEditModeFootnote ? (
          <div
            className="border-r"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            <div id="introEditor" className="">
              <SimpleMdeReact
                options={options}
                value={footnote}
                onChange={(e) => {
                  let temp = footnote;
                  temp = String(e);
                  setFootnote(temp);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="border-r"
            style={{
              minHeight: "23vh",
              maxHeight: "23vh",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div id="SLRMDEditor" className="py-4 px-4">
              <ReactMarkdown children={footnote}></ReactMarkdown>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
export default Outcomes;
