import React from "react";
import { Modal } from "antd";
import ReportTypes from "../../../../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../../../../libraries/report-types/charts/interfaces/main";
import FormField5 from "../../../../../../libraries/form-fields/formField5";
import FormField6 from "../../../../../../libraries/form-fields/formField6";
import { ColumnFilter } from "../../../../../../libraries/report-types/charts/table/simpleTable/columnFilter";
import Item from "antd/lib/list/Item";
import { Prompt } from "react-router-dom";
export default function Table(props: {
  id: number | null;
  name: string;
  supportingMessageId: number;
  column: (
    | {
        id: number;
        title: string;
        accessor: string;
        Footer?: string;
        Filter?: ({ column }: { column: any }) => JSX.Element;
        sort?: boolean;
        tableId?: number;
      }
    | {
        id: number;
        title: string;
        accessor: string;
        Footer?: string;
        Filter?: ({ column }: { column: any }) => JSX.Element;
        sort?: undefined;
        tableId?: number;
      }
  )[];
  data: any;
  handleSave: (
    id: number,
    data: any,
    columns: any,
    name: string,
    supprtingMessageId: number
  ) => void;
}) {
  const [tableName, setTableName] = React.useState(props.name);
  const [columns, setColumns] = React.useState(props.column);
  const [data, setData] = React.useState(props.data);
  const [visible, setVisible] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("Modal Header");
  const [modalText, setModalText] = React.useState(<></>);
  const [changed, setChanged] = React.useState(false);
  const [visible2, setVisible2] = React.useState(false);
  const showModal = () => {
    setModalHeader("Edit Table");
    setModalText(
      <FormField5
        data={{ name: tableName, columns: columns, tableData: data }}
        addColumn={addColumn}
        deleteColumn={deleteColumn}
        addRows={addRows}
        handleSave={handleSaveTable}
      />
    );
    setVisible(true);
  };
  React.useEffect(
    () =>
      setModalText(
        <FormField5
          data={{
            name: tableName,
            columns: columns,
            tableData: data,
          }}
          addColumn={addColumn}
          deleteColumn={deleteColumn}
          addRows={addRows}
          handleSave={handleSaveTable}
        />
      ),
    [columns, data]
  );
  function makeid(length: number) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const handleSaveTable = (value: any) => {
    // console.log("Submit", value);
    value.columns.map((col: any) => {
      if (col.accessor === "accessor")
        col.accessor = col.title.split(" ").join("");
    });
    setColumns((columns) => value.columns);
    setData((data: any) => value.tableData);
    setTableName((tableName) => value.name);
    setVisible(false);
    setChanged(true);
  };
  const handleSave = () => {
    props.handleSave(
      props.id || 0,
      columns,
      data,
      tableName,
      props.supportingMessageId
    );
    setChanged(false);
  };
  const handleCancel = () => {
    setModalText(<></>);
    setVisible(false);
  };
  const addColumn = () => {
    const temp = {
      id: 0,
      title: "Header",
      accessor: makeid(10),
      Filter: ColumnFilter,
      sort: false,
      tableId: 0,
    };
    // console.log("Received values of form:", value);
    const col = columns;
    col.push(temp);
    setColumns(col);
    setModalText(
      <FormField5
        data={{ name: tableName, columns: col, tableData: data }}
        addColumn={addColumn}
        deleteColumn={deleteColumn}
        addRows={addRows}
        handleSave={handleSaveTable}
      />
    );
  };
  const deleteColumn = (id: number) => {
    const name = columns[id].accessor;
    const d = data;
    d.map((item: any) => delete item[name]);
    // setColumns((prevCols) => prevCols.filter((item, index) => index != id));
    columns.splice(id, 1);
    setData(d);
    // console.log("Deleting", temp);
    setModalText(
      <FormField5
        data={{ columns: columns, tableData: data }}
        addColumn={addColumn}
        deleteColumn={deleteColumn}
        addRows={addRows}
        handleSave={handleSaveTable}
      />
    );
  };
  const addRows = (value: any) => {
    let tempData = value.tableData;
    setVisible(false);
    setData(tempData);
  };
  function handleBlockNavigation() {
    setVisible2(true);

    return !changed;
  }
  return (
    <>
      <Modal
        destroyOnClose={true}
        title={modalHeader}
        visible={visible}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
        width={1000}
      >
        {/* {console.log(visible)} */}
        {modalText}
      </Modal>
      <Modal
        destroyOnClose={true}
        // title={modalHeader}
        visible={visible2}
        onCancel={() => setVisible2(false)}
        onOk={() => {
          setChanged(false);
          setVisible2(false);
        }}
        okText={"Yes"}
        cancelText={"No"}
        // footer={null}
        width={500}
      >
        Changes not saved. Are you sure you want to navigate away from this
        page?
      </Modal>
      <Prompt when={changed} message={handleBlockNavigation} />
      <ReportTypes
        handleEdit={showModal}
        handleSave={handleSave}
        // header={tableName}
        hideActions={changed ? [false, false] : [true, false]}
        // subHeader="Sub Header Text"
        // footer="Footer Text"
        minHeight="300px"
        // bodyPadding="5px 24px"
        // title={{ title: "Chart Title" }}
        chartType={ReportTypesComponentInterface.chartType.simpleTable}
        series={[]}
        // 		tableData={
        // 			column:props.column,
        // data:props.data
        // 		}

        chartConfig={{
          table: {
            tableData: { data: data, column: columns },
            grid: {
              enableGridX: false,
              enableGridY: false,
            },
            intraction: {
              hoverHighlight:
                ReportTypesComponentInterface.tableInterface.hoverTarget
                  .rowColumn,
            },
            cellPadding: 2,
          },
        }}
        label={{}}
      />
    </>
  );
}
