import { Link, useHistory } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import {
  CaretRightOutlined,
  CaretDownOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { CollapsibleItem, LandingContainerBackground } from "./style";
import {
  ArrowLeftOutlined,
  RightCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Col, message, Modal, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import SimpleMdeReact from "react-simplemde-editor";
import ReactMarkdown from "react-markdown";
import { request } from "../layout/SLR_Layout";
import { SiWindows } from "react-icons/si";

export default function ExecutiveSummary() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tabNames, setTabNames] = useState<any>([]);
  const [editSectionName, setEditSectionName] = useState<any>("");
  const [editSubSectionName, setEditSubSectionName] = useState<any>("");
  const [editSectionDescription, setEditSectionDescription] = useState<any>("");
  const [collapsedMethodology, setCollapsedMethodology] = useState(true);
  const [collapsedResults, setCollapsedResults] = useState(true);
  const history = useHistory();

  useEffect(() => {
    // check for redirection
    if (
      window.sessionStorage.getItem("selectedBackgroundName") === undefined ||
      window.sessionStorage.getItem("selectedBackgroundName") === null ||
      window.sessionStorage.getItem("selectedBackgroundName") === "" ||
      window.sessionStorage.getItem("productReviewId") === undefined ||
      window.sessionStorage.getItem("productReviewId") === null ||
      window.sessionStorage.getItem("productReviewId") === "" ||
      window.localStorage.getItem("productId") === undefined ||
      window.localStorage.getItem("productId") === null ||
      window.localStorage.getItem("productId") === ""
    ) {
      history.push("/SLR");
      return;
    }
    setIsLoading(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    request
      .get("/GetExecutiveSummary?productReviewId=" + String(productReviewId))
      .then((res) => {
        console.log("response from API", res);
        if (res.status === 200) {
          let output: any = res.data;
          let temp: any = [];
          let eachSectionId: any = {};
          //extract sections
          output?.map((item: any, index: any) => {
            eachSectionId[item["sectionName"]] = item["id"];
            if (item["parentId"] === 0) {
              temp.push(item);
            }
          });
          console.log(eachSectionId);
          window.sessionStorage.setItem(
            "sectionId",
            JSON.stringify(eachSectionId)
          );
          output?.map((item: any, index: any) => {
            if (item["parentId"] !== 0) {
              let tempIndex = temp.find((x: any, i: any) => {
                // extract subsections and then push them as children of major section
                if (x.id === item["parentId"]) {
                  if (!temp[i]["subSections"]) temp[i]["subSections"] = [];
                  temp[i]["subSections"].push({
                    sectionName: item["sectionName"],
                    parentId: item["parentId"],
                    id: item["id"],
                    topNav: temp[i]["sectionName"],
                    subNav: item["sectionName"].split(" ")[0],
                    description: item["description"],
                    path:
                      temp[i]["sectionName"] +
                      "/" +
                      item["sectionName"].split(" ")[0],
                  });
                }
              });
            }
          });
          //update navigation paths
          temp.map((item: any) => {
            if (item["subSections"]) {
              item["subSections"].sort((a: any, b: any) => a.id - b.id);
              item["path"] = item["subSections"][0]["path"];
              item["topNav"] = item["subSections"][0]["topNav"];
              item["subNav"] = item["subSections"][0]["subNav"];
            } else {
              item["path"] = item["sectionName"];
              item["topNav"] = item["sectionName"];
              item["subNav"] = "";
            }
          });
          console.log(temp);
          temp.sort((a: any, b: any) => a.id - b.id);
          setTabNames([...temp]);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  const showModal = (
    sectionName: any,
    description: any,
    subSectionName?: any
  ) => {
    setEditSectionName(sectionName);
    setEditSectionDescription(description);
    setEditSubSectionName(subSectionName);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let temp = JSON.parse(JSON.stringify(tabNames));
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    console.log(editSectionName, editSubSectionName, editSectionDescription);

    //straighten out the array from subsection to just sections
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["sectionName"] === editSectionName) {
        if (
          editSubSectionName !== "" &&
          editSubSectionName !== undefined &&
          editSubSectionName !== null
        ) {
          temp[i]["subSections"].map((subSection: any) => {
            if (subSection.sectionName === editSubSectionName) {
              console.log("found for ", subSection.sectionName);
              subSection["description"] = editSectionDescription;
            }
          });
        } else {
          temp[i]["description"] = editSectionDescription;
          break;
        }
      }
    }
    let tempData: any = [];
    temp.map((item: any) => {
      if (item.subSections) {
        item.subSections.map((sub: any) => {
          tempData.push(sub);
        });
      }
      tempData.push(item);
    });
    console.log("after change", temp, tempData);
    message.loading("Saving Changes!", 1.6);

    request
      .post(
        "/EditExecutiveSummary?productReviewId=" + String(productReviewId),
        tempData
      )
      .then((res) => {
        if (res.status === 200) {
          setTabNames([...temp]);
          message.success("Saved!");
        } else message.error("Error!");
        console.log("res", res);
      })
      .catch((err) => {
        message.error("Error!");
        console.log(err);
      });
    setIsModalVisible(false);
    setEditSectionName("");
    setEditSectionDescription("");
    setEditSubSectionName("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditSectionName("");
    setEditSectionDescription("");
  };

  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "700px",
    };
  }, []);

  return (
    <div style={{ backgroundColor: "whitesmoke", height: "100%" }}>
      <div className="productBackgroundBanner items-end h-28">
        <LandingContainerBackground>
          <div
            style={{
              padding: "1.35rem 1.2rem",
            }}
            className="flex items-center backDiv"
            onClick={() => history.goBack()}
          >
            <ArrowLeftOutlined
              style={{
                color: "whitesmoke",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}
            />
            <span
              className="ml-2 cursor-pointer"
              style={{ fontSize: "16px", color: "whitesmoke" }}
            >
              Back
            </span>
          </div>
        </LandingContainerBackground>

        <p
          style={{ height: "30%" }}
          className="text-white flex items-end text-5xl mb-4 ml-4"
        >
          Executive Summary
        </p>
      </div>

      <div style={{ backgroundColor: "whitesmoke" }}>
        <div
          style={{ overflowY: "auto", height: "570px", paddingBottom: "2rem" }}
        >
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <BeatLoader color={"#27A6A4"} loading={true} size={24} />
            </div>
          ) : (
            <div>
              {tabNames &&
                tabNames.map((tab: any, index: any) => {
                  if (
                    tab.sectionName === "Introduction" ||
                    tab.sectionName === "Conclusion" ||
                    tab.sectionName === "Feasibility Assessment"
                  )
                    return (
                      <Row>
                        <Col span={24}>
                          <div
                            style={{ minHeight: "8rem" }}
                            className="bg-white mx-8 mt-4 rounded-md shadow-md"
                          >
                            <div className="px-2 pt-2 flex justify-start items-center rounded-md ">
                              <div
                                onClick={() => {
                                  window.sessionStorage.setItem(
                                    "topNav",
                                    tab.topNav
                                  );
                                  window.sessionStorage.setItem(
                                    "subNav",
                                    tab.subNav
                                  );
                                }}
                                className="text-xl ml-2 w-4/5 text-black font-bold"
                              >
                                <Link
                                  to={
                                    "/SLR/" +
                                    window.sessionStorage.getItem(
                                      "selectedBackground"
                                    ) +
                                    "/" +
                                    tab.path
                                  }
                                >
                                  {tab.sectionName}
                                </Link>
                              </div>

                              <div className="flex justify-end items-center w-1/5 pr-4">
                                <Link
                                  to={
                                    "/SLR/" +
                                    window.sessionStorage.getItem(
                                      "selectedBackground"
                                    ) +
                                    "/" +
                                    tab.path
                                  }
                                >
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      window.sessionStorage.setItem(
                                        "topNav",
                                        tab.topNav
                                      );
                                      window.sessionStorage.setItem(
                                        "subNav",
                                        tab.subNav
                                      );
                                    }}
                                  >
                                    <RightCircleOutlined
                                      style={{
                                        fontSize: "1.3rem",
                                        color: "teal",
                                      }}
                                    />
                                  </div>
                                </Link>
                                {window.sessionStorage.getItem("isAdmin") ===
                                  "2" && (
                                  <EditOutlined
                                    style={{
                                      fontSize: "1.3rem",
                                      color: "teal",
                                      marginLeft: "0.4rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      showModal(
                                        tab.sectionName,
                                        tab.description
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>

                            <div
                              style={{ fontSize: "0.82rem" }}
                              className="font-normal border-b-2 px-4 pt-0.5 italic"
                            ></div>

                            <div className="flex py-2 justify-start px-4 mt-2 text-left">
                              <ReactMarkdown
                                children={tab.description}
                              ></ReactMarkdown>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                  else
                    return (
                      <Row>
                        <Col span={24}>
                          <div
                            style={{ minHeight: "8rem" }}
                            className="bg-white mx-8 mt-4 rounded-md shadow-md"
                          >
                            <div className="px-2 pt-2 flex justify-start items-center rounded-md ">
                              <div
                                onClick={() => {
                                  window.sessionStorage.setItem(
                                    "topNav",
                                    tab.topNav
                                  );
                                  window.sessionStorage.setItem(
                                    "subNav",
                                    tab.subNav
                                  );
                                }}
                                className="text-xl ml-2 w-4/5 text-black font-bold"
                              >
                                <Link
                                  to={
                                    "/SLR/" +
                                    window.sessionStorage.getItem(
                                      "selectedBackground"
                                    ) +
                                    "/" +
                                    tab.path
                                  }
                                >
                                  {tab.sectionName}
                                </Link>
                              </div>

                              <div className="flex justify-end items-center w-1/5 pr-4">
                                <Link
                                  to={
                                    "/SLR/" +
                                    window.sessionStorage.getItem(
                                      "selectedBackground"
                                    ) +
                                    "/" +
                                    tab.path
                                  }
                                >
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                      window.sessionStorage.setItem(
                                        "topNav",
                                        tab.topNav
                                      );
                                      window.sessionStorage.setItem(
                                        "subNav",
                                        tab.subNav
                                      );
                                    }}
                                  >
                                    <RightCircleOutlined
                                      style={{
                                        fontSize: "1.3rem",
                                        color: "teal",
                                      }}
                                    />
                                  </div>
                                </Link>
                                {window.sessionStorage.getItem("isAdmin") ===
                                  "2" && (
                                  <EditOutlined
                                    style={{
                                      fontSize: "1.3rem",
                                      color: "teal",
                                      marginLeft: "0.4rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      showModal(
                                        tab.sectionName,
                                        tab.description
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </div>

                            <div
                              style={{ fontSize: "0.82rem" }}
                              className="font-normal border-b-2 px-4 pt-0.5 italic"
                            ></div>

                            <div className="flex py-2 justify-start px-4 mt-2 text-left">
                              <ReactMarkdown
                                children={tab.description}
                              ></ReactMarkdown>
                            </div>
                            <div className="flex justify-end items-end pr-4 pb-4">
                              {tab.sectionName === "Methodology" ? (
                                collapsedMethodology ? (
                                  <div
                                    onClick={() =>
                                      setCollapsedMethodology(false)
                                    }
                                    className="flex items-center cursor-pointer"
                                  >
                                    <p className="mr-1 text-green-600 italic text-xs">
                                      View Sub-sections
                                    </p>
                                    <CaretRightOutlined
                                      style={{
                                        color: "teal",
                                        fontSize: "0.8rem",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    onClick={() =>
                                      setCollapsedMethodology(true)
                                    }
                                    className="flex items-center cursor-pointer"
                                  >
                                    <p className="mr-1 text-green-600 italic text-xs">
                                      Hide Sub-sections
                                    </p>
                                    <CaretDownOutlined
                                      style={{
                                        color: "teal",
                                        fontSize: "0.8rem",
                                      }}
                                    />
                                  </div>
                                )
                              ) : collapsedResults ? (
                                <div
                                  onClick={() => setCollapsedResults(false)}
                                  className="flex items-center cursor-pointer"
                                >
                                  <p className="mr-1 text-green-600 italic text-xs">
                                    View Sub-sections
                                  </p>
                                  <CaretRightOutlined
                                    style={{
                                      color: "teal",
                                      fontSize: "0.8rem",
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  onClick={() => setCollapsedResults(true)}
                                  className="flex items-center cursor-pointer"
                                >
                                  <p className="mr-1 text-green-600 italic text-xs">
                                    Hide Sub-sections
                                  </p>
                                  <CaretDownOutlined
                                    style={{
                                      color: "teal",
                                      fontSize: "0.8rem",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            {!collapsedMethodology &&
                              tab.sectionName === "Methodology" && (
                                <CollapsibleItem
                                  collapsed={collapsedMethodology}
                                >
                                  {tab.subSections.map((subsection: any) => {
                                    return (
                                      <div
                                        style={{
                                          minHeight: "4rem",
                                          height: "100%",
                                        }}
                                        className="mx-8 mb-4 rounded-md shadow-lg border border-green-600"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#d6ebff5a",
                                          }}
                                          className="px-2 pt-2 pb-1 flex justify-start items-center border-b-2 rounded-t-md"
                                        >
                                          <div
                                            onClick={() => {
                                              window.sessionStorage.setItem(
                                                "topNav",
                                                subsection.topNav
                                              );
                                              window.sessionStorage.setItem(
                                                "subNav",
                                                subsection.subNav
                                              );
                                            }}
                                            className="text-xl ml-2 w-4/5 text-black font-bold"
                                          >
                                            <Link
                                              to={
                                                "/SLR/" +
                                                window.sessionStorage.getItem(
                                                  "selectedBackground"
                                                ) +
                                                "/" +
                                                subsection.path
                                              }
                                            >
                                              {subsection.sectionName}
                                            </Link>
                                          </div>

                                          <div className="flex justify-end items-center w-1/5 pr-4">
                                            <Link
                                              to={
                                                "/SLR/" +
                                                window.sessionStorage.getItem(
                                                  "selectedBackground"
                                                ) +
                                                "/" +
                                                subsection.path
                                              }
                                            >
                                              <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  window.sessionStorage.setItem(
                                                    "topNav",
                                                    subsection.topNav
                                                  );
                                                  window.sessionStorage.setItem(
                                                    "subNav",
                                                    subsection.subNav
                                                  );
                                                }}
                                              >
                                                <RightCircleOutlined
                                                  style={{
                                                    fontSize: "1.3rem",
                                                    color: "teal",
                                                  }}
                                                />
                                              </div>
                                            </Link>
                                            {window.sessionStorage.getItem(
                                              "isAdmin"
                                            ) === "2" && (
                                              <EditOutlined
                                                style={{
                                                  fontSize: "1.3rem",
                                                  color: "teal",
                                                  marginLeft: "0.4rem",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  showModal(
                                                    tab.sectionName,
                                                    subsection.description,
                                                    subsection.sectionName
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>

                                        <div className="flex py-2 justify-start px-4 text-left">
                                          <ReactMarkdown
                                            children={subsection.description}
                                          ></ReactMarkdown>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </CollapsibleItem>
                              )}

                            {!collapsedResults &&
                              tab.sectionName === "Results" && (
                                <CollapsibleItem collapsed={collapsedResults}>
                                  {tab.subSections.map((subsection: any) => {
                                    return (
                                      <div
                                        style={{
                                          minHeight: "4rem",
                                          height: "100%",
                                        }}
                                        className="mx-8 mb-4 rounded-md shadow-lg border border-green-600"
                                      >
                                        <div
                                          style={{
                                            backgroundColor: "#d6ebff5a",
                                          }}
                                          className="px-2 pt-2 pb-1 flex justify-start items-center border-b-2 rounded-t-md"
                                        >
                                          <div
                                            onClick={() => {
                                              window.sessionStorage.setItem(
                                                "topNav",
                                                subsection.topNav
                                              );
                                              window.sessionStorage.setItem(
                                                "subNav",
                                                subsection.subNav
                                              );
                                            }}
                                            className="text-xl ml-2 w-4/5 text-black font-bold"
                                          >
                                            <Link
                                              to={
                                                "/SLR/" +
                                                window.sessionStorage.getItem(
                                                  "selectedBackground"
                                                ) +
                                                "/" +
                                                subsection.path
                                              }
                                            >
                                              {subsection.sectionName}
                                            </Link>
                                          </div>

                                          <div className="flex justify-end items-center w-1/5 pr-4">
                                            <Link
                                              to={
                                                "/SLR/" +
                                                window.sessionStorage.getItem(
                                                  "selectedBackground"
                                                ) +
                                                "/" +
                                                subsection.path
                                              }
                                            >
                                              <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  window.sessionStorage.setItem(
                                                    "topNav",
                                                    subsection.topNav
                                                  );
                                                  window.sessionStorage.setItem(
                                                    "subNav",
                                                    subsection.subNav
                                                  );
                                                }}
                                              >
                                                <RightCircleOutlined
                                                  style={{
                                                    fontSize: "1.3rem",
                                                    color: "teal",
                                                  }}
                                                />
                                              </div>
                                            </Link>
                                            {window.sessionStorage.getItem(
                                              "isAdmin"
                                            ) === "2" && (
                                              <EditOutlined
                                                style={{
                                                  fontSize: "1.3rem",
                                                  color: "teal",
                                                  marginLeft: "0.4rem",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  showModal(
                                                    tab.sectionName,
                                                    subsection.description,
                                                    subsection.sectionName
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>

                                        <div className="flex py-2 justify-start px-4 text-left">
                                          <ReactMarkdown
                                            children={subsection.description}
                                          ></ReactMarkdown>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </CollapsibleItem>
                              )}
                          </div>
                        </Col>
                      </Row>
                    );
                })}
            </div>
          )}
        </div>
      </div>

      {editSectionName && (
        <Modal
          className="editTableModal"
          title={
            <p className="text-white">
              Edit {editSectionName}:&nbsp;
              {editSubSectionName !== "" ? editSubSectionName : ""}
            </p>
          }
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={"Save"}
          width={"40rem"}
          closeIcon={
            <CloseOutlined
              style={{
                color: "whitesmoke",
                marginBottom: "0.5rem",
              }}
            />
          }
        >
          <div id="SLRMDEditor" className="flex justify-center items-center">
            <div>
              <SimpleMdeReact
                options={options}
                value={editSectionDescription}
                onChange={(e) => {
                  let temp: any = editSectionDescription;
                  temp = String(e);
                  setEditSectionDescription(temp);
                }}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
