import { ResponsiveLine } from "@nivo/line";
import * as ReportTypesComponentProps from "../../interfaces/main";
import transformData from "./transform";

const theme = (
	axisWidth?: number,
	axisColor?: string,
	textColor?: string,
	background?: string,
	fontSize?: number
) => {
	return {
		background: background || "white",
		textColor: textColor || "black",
		fontSize: fontSize || 11,
		axis: {
			domain: {
				line: {
					stroke: axisColor || "black",
					strokeWidth: axisWidth || 2,
				},
			},
			textColor: axisColor || "black",
			tickColor: axisColor || "black",
		},
	};
};
/**
 * Renders the Line Chart Component. The component makes use of Nivo Charts Library.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @param props - Chart Config for the Line Chart Component
 *
 * @returns Line Chart Component of Nivo
 */
const LineChart: React.FC<ReportTypesComponentProps.Interface> = (
	props: ReportTypesComponentProps.Interface
): JSX.Element => (
	<>
		<ResponsiveLine
			data={transformData(
				props.data,
				props.series,
				props.axis?.primaryX.dim || 0
			)}
			colors={(d) => d.color}
			curve={props.chartConfig.cartesian?.line?.curve}
			enablePoints={props.chartConfig.cartesian?.line?.enablePoints}
			pointSize={props.chartConfig.cartesian?.line?.pointSize}
			pointColor={props.chartConfig.cartesian?.line?.pointColor}
			pointBorderWidth={
				props.chartConfig.cartesian?.line?.pointBorderWidth
			}
			pointBorderColor={props.chartConfig.cartesian?.line?.pointColor}
			useMesh={props.chartConfig.cartesian?.line?.useMesh}
			enablePointLabel={props.label ? true : false}
			// format        to be added
			theme={theme(
				props.style?.axisWidth,
				props.style?.axisColor,
				props.style?.textColor,
				props.chartArea?.background,
				props.style?.fontSize
			)}
			layers={[
				"grid",
				"markers",
				"areas",
				"axes",
				"crosshair",
				"lines",
				"points",
				"slices",
				"mesh",
				"legends",
			]}
			enableArea={props.chartConfig.cartesian?.line?.enableArea}
			margin={
				props.style?.margin || {
					top: 20,
					right: 80,
					bottom: 50,
					left: 50,
				}
			}
			enableGridX={props.chartConfig.cartesian?.grid?.enableGridX}
			enableGridY={props.chartConfig.cartesian?.grid?.enableGridY}
			axisBottom={props.axis?.primaryX}
			axisLeft={props.axis?.primaryY}
			animate={props.animate ? props.animate : true}
			legends={
				props.legend
					? [
							{
								anchor:
									props.legend?.position || "bottom-right",
								direction: props.legend?.direction || "column",
								itemHeight: 20,
								itemWidth: 110,
								toggleSerie: true,
								translateX: 120,
								symbolShape: props.legend.symbolShape,
							},
					  ]
					: []
			}
		/>
	</>
);
export default LineChart;
