import { Form, Input, Button, Space, Select, Row, Col } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "./styled.css";

const FormField3 = (props: {
  handleEdit?: (value: any, key: number) => void;
  handleSave?: (value: any) => void;
  editData?: any;
  closeModal: () => void;
}) => {
  const roles = [
    { label: "Read only", value: 1 },
    { label: "Read and Edit", value: 2 },
  ];
  // const dataCopy = props.editData;
  // if (props.editData) {
  // 	for (let i = 0; i < dataCopy.userRole.length; i++) {
  // 		if (dataCopy.userRole[i].rolesId == 2)
  // 			dataCopy.userRole[i].rolesId = "Read and Edit";
  // 		else if (dataCopy.userRole[i].rolesId == 1)
  // 			dataCopy.userRole[i].rolesId = "Read only";
  // 	}
  // }

  // console.log(props.editData);
  return (
    <div>
      <Form
        name="dynamic_form_nest_item"
        autoComplete="off"
        onFinish={(value) => {
          console.log("values", value);
          if (props.closeModal) props.closeModal();
          if (props.handleSave) props.handleSave(value);
          else if (props.handleEdit) props.handleEdit(value, props.editData.id);
        }}
        initialValues={props.editData}
      >
        <div>
          <Row>
            <Col span={5}>Product Name</Col>
            <Col span={19}>
              <Form.Item
                name="name"
                // label="Product Name"
                rules={[
                  {
                    required: true,
                    message: "Please input Product Name",
                  },
                ]}
              >
                <Input placeholder="Enter product name" maxLength={15} />
              </Form.Item>
            </Col>
            <Col span={5}>Description</Col>
            <Col span={19}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input Product Description",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter product description"
                  showCount
                  maxLength={100}
                />
              </Form.Item>
            </Col>
            <Col span={5}>User Roles</Col>
          </Row>
        </div>

        <Form.List name="userRole">
          {(fields, { add, remove }) => (
            <>
              <Row className="formItem" gutter={[8, 8]}>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <>
                    <Col offset={5} span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, "userId"]}
                        fieldKey={[fieldKey, "userId"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing User ID",
                          },
                        ]}
                        className="formInput"
                      >
                        <Input placeholder="User ID" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, "rolesId"]}
                        fieldKey={[fieldKey, "rolesId"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing role",
                          },
                        ]}
                        className="formSelect"
                      >
                        <Select
                          placeholder="Role"
                          style={{ width: 130 }}
                          options={roles}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                    <Col span={3}></Col>
                  </>
                ))}
              </Row>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add User Role
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormField3;
