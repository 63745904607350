import React from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { dataa } from "./data";
import { grouped_columns } from "./columns";
import "./style.css";
import { GlobalFilter } from "./globalFilter.jsx";
import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline";
import { Sort } from "@styled-icons/boxicons-regular/Sort";
import { editTableData } from "../../../../../services/supportingMessage";
import Highlight from "react-highlighter";
import AppContext from "../../../../../store";
/**
 * Renders the Responsive Table Component. The component makes use of react-table hooks.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @returns Table Component
 */
const SimpleTable = (props: {
  tableData?: any;
  rows?: number;
  addButton?: any;
}) => {
  // const columns = React.useMemo(
  // 	() => props.tableData.column,
  // 	[props.tableData.column]
  // );
  // const data = React.useMemo(
  // 	() => props.tableData.data,
  // 	[props.tableData.data]
  // );
  console.log("props.tableData", props.tableData);
  const context = React.useContext(AppContext);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // footerGroups,
    //@ts-ignore
    page,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    previousPage,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    canPreviousPage,
    //@ts-ignore
    pageOptions,
    //@ts-ignore
    gotoPage,
    //@ts-ignore
    pageCount,
    //@ts-ignore
    setPageSize,
    state,
    //@ts-ignore
    setGlobalFilter,
    //@ts-ignore
    prepareRow, //@ts-ignore
  } = useTable(
    {
      columns: props.tableData.column,
      data: props.tableData.data,
      initialState: {
        //@ts-ignore
        pageIndex: 0,
        pageSize: props.rows ? props.rows : 4,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  //@ts-ignore
  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <>
      {/* {console.log("TableData", props.tableData)} */}
      {props.tableData.column === null ||
      props.tableData.column.length === 0 ? (
        <div className="flex justify-center items-center ">
          No Records Found
        </div>
      ) : (
        <div className="tableSearch" style={{ margin: "4px auto" }}>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {props.addButton && (
            <>
              <button onClick={props.addButton}>
                <AddCircleOutline
                  size={20}
                  style={{
                    marginRight: "2px",
                    marginBottom: "2px",
                  }}
                />
                Add Abbreviations
              </button>
            </>
          )}
        </div>
      )}
      <table {...getTableProps}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                //@ts-ignore
                let tempVal = String(column.render("title"));
                if (tempVal.toLowerCase().includes(context.searchWord)) {
                  return (
                    <th
                      {...column.getHeaderProps(
                        //@ts-ignore
                        column.sort
                          ? //@ts-ignore
                            column.getSortByToggleProps()
                          : ""
                      )}
                    >
                      {/* <div>
										{
											//@ts-ignore
											column.canFilter
												? column.render("Filter")
												: null
										}
									</div> */}

                      <Highlight
                        search={context.searchWord}
                        matchStyle={{
                          color: "red",
                          backgroundColor: "yellow",
                          padding: 0,
                        }}
                      >
                        {column.render("title")}
                      </Highlight>
                      <span>
                        {
                          //@ts-ignore
                          column.isSorted ? (
                            //@ts-ignore
                            column.isSortedDesc ? (
                              " ↓"
                            ) : (
                              " ↑"
                            )
                          ) : //@ts-ignore
                          column.sort ? (
                            <Sort
                              size={16}
                              {
                                //@ts-ignore
                                ...column.getSortByToggleProps()
                              }
                            />
                          ) : (
                            ""
                          )
                        }
                      </span>
                    </th>
                  );
                } else
                  return (
                    <th
                      {...column.getHeaderProps(
                        //@ts-ignore
                        column.sort
                          ? //@ts-ignore
                            column.getSortByToggleProps()
                          : ""
                      )}
                    >
                      {column.render("title")}
                      <span>
                        {
                          //@ts-ignore
                          column.isSorted ? (
                            //@ts-ignore
                            column.isSortedDesc ? (
                              " ↓"
                            ) : (
                              " ↑"
                            )
                          ) : //@ts-ignore
                          column.sort ? (
                            <Sort
                              size={16}
                              {
                                //@ts-ignore
                                ...column.getSortByToggleProps()
                              }
                            />
                          ) : (
                            ""
                          )
                        }
                      </span>
                      {/* <div>
										{
											//@ts-ignore
											column.canFilter
												? column.render("Filter")
												: null
										}
									</div> */}
                    </th>
                  );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps}>
          {
            //@ts-ignore
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {
                    //@ts-ignore
                    row.cells.map((cell) => {
                      //@ts-ignore
                      let tempVal = String(cell.render("Cell").props.value);
                      if (
                        context.searchWord &&
                        tempVal.toLowerCase().includes(context.searchWord)
                      ) {
                        return (
                          <td className="align-top" {...cell.getCellProps()}>
                            <Highlight
                              search={context.searchWord}
                              matchStyle={{
                                color: "red",
                                backgroundColor: "yellow",
                                padding: 0,
                              }}
                            >
                              {tempVal}
                            </Highlight>
                          </td>
                        );
                      } else
                        return (
                          <td className="align-top" {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
        {/* <tfoot>
					{footerGroups.map((footerGroup) => (
						<tr {...footerGroup.getFooterGroupProps()}>
							{footerGroup.headers.map((column) => (
								<td {...column.getFooterProps}>
									{column.render("Footer")}
								</td>
							))}
						</tr>
					))}
				</tfoot> */}
      </table>
      {props.tableData.column === null ||
      props.tableData.column.length === 0 ? (
        ""
      ) : (
        <div className="pagination" style={{ margin: "0 auto" }}>
          <div className="paginationLeft">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span className="ml-2">
              | &nbsp;&nbsp;Go to page:{" "}
              <input
                className="border rounded-sm h-7"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: "40px" }}
              />{" "}
            </span>
            <select
              className="border rounded-sm ml-6 h-7"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[3, 5, 7, 10].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>{" "}
          </div>
          <div className="paginationRight ml-6">
            <button
              className="font-semibold text-lg"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="ml-2 font-semibold"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prev
            </button>
            <button
              className="ml-4 font-semibold"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="ml-2 font-semibold text-lg"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default SimpleTable;
