import { ColumnFilter } from "../../../../libraries/report-types/charts/table/simpleTable/columnFilter";
export function transformTableData(
	columns: {
		id: number;
		title: string;
		accessor: string;
		tableId: number;
	}[],
	tableData: { key: number; rowData: string[] }[]
) {
	const processedTableData: { [id: string]: string | number }[] = [];
	tableData.map((row: { key: number; rowData: string[] }) => {
		const tempRow: { [id: string]: string | number } = {};
		row.rowData.map((rowItem: string, index: number) => {
			tempRow[columns[index].accessor] = rowItem;
		});
		tempRow["key"] = row.key;
		processedTableData.push(tempRow);
	});
	return processedTableData;
}
export function transformColumn(
	columns: {
		id: number;
		title: string;
		accessor: string;
		tableId: number;
	}[]
) {
	const transformedColumn: {
		id: number;
		title: string;
		accessor: string;
		Filter: ({ column }: { column: any }) => JSX.Element;
		// tableId: number;
	}[] = [];
	columns.map((col: any) => {
		transformedColumn.push({
			id: col.id,
			title: col.title,
			accessor: col.accessor,
			Filter: ColumnFilter,
			// tableId: col.tableId,
		});
	});
	return transformedColumn;
}
export function reverseTransformColumn(
	id: number,
	processedColumns: {
		id: number;
		title: string;
		accessor: string;
		Footer?: string;
		Filter: ({ column }: { column: any }) => JSX.Element;
		sort?: undefined;
		tableId?: number;
	}[]
) {
	const columns: {
		// id: number;
		title: string;
		accessor: string;
		// tableId: number;
		order: number;
	}[] = [];
	processedColumns.map((col: any, index: number) => {
		columns.push({
			// id: col.id,
			title: col.title,
			accessor: col.accessor,
			// tableId: id,
			order: index + 1,
		});
	});
	return columns;
}
export function reverseTransformTableData(
	processedTableData: {
		[id: string]: string | number;
	}[]
) {
	const tableData: {
		key: string;
		rowData: { [id: string]: string | number }[];
	}[] = [];
	processedTableData.map((row: { [id: string]: string | number }, index) => {
		const temp: any = {};
		// console.log(processedTableData);
		temp.key = (index + 1).toString();
		temp.rowData = [];
		for (let k in row) {
			if (k !== "key") temp.rowData.push(row[k]);
		}
		tableData.push(temp);
	});
	return tableData;
}

export function transformLabels(
	chartHeader: {
		id: number;
		headerName: string;
		chartComponentId: number;
	}[]
) {
	const transformedLabel: {
		id: number;
		headerName: string;
		identifier: string;
		chartComponentId: number;
	}[] = [];
	chartHeader.map((col) => {
		transformedLabel.push({
			id: col.id,
			headerName: col.headerName,
			identifier: col.headerName,
			chartComponentId: col.chartComponentId,
		});
	});
	return transformedLabel;
}

export function transformSeries(
	chartSeries: {
		id: number;
		name: string;
		rowNumber: number;
		color: string;
		chartComponentId: number;
	}[]
) {
	const transformedSeries: {
		id: number;
		name: string;
		dim: number;
		color: string;
		chartComponentId: number;
	}[] = [];

	chartSeries.map((series) => {
		transformedSeries.push({
			id: series.id,
			name: series.name,
			dim: series.rowNumber,
			color: series.color,
			chartComponentId: series.chartComponentId,
		});
	});
	return transformedSeries;
}

export function transformChartData(
	chartHeader: {
		id: number;
		headerName: string;
		chartComponentId: number;
	}[],
	chartData: { key: number; chartData: string[] }[]
) {
	const processedChartData: (string | number)[][] = [];
	processedChartData.push([]);
	chartHeader.map((header) => processedChartData[0].push(header.headerName));
	chartData.map((row: { key: number; chartData: string[] }, index) => {
		processedChartData.push([]);
		row.chartData.map((rowItem) =>
			processedChartData[index + 1].push(Number(rowItem))
		);
	});
	return processedChartData;
}
export function reverseTransformCharteData(
	processedChartData: {
		[id: string]: number;
	}[]
) {
	const rawChartData: {
		key: number;
		chartData: number[];
	}[] = [];
	processedChartData.map((row: { [id: string]: number }, index) => {
		const temp: any = {};
		// console.log(processedTableData);
		temp.key = index + 1;
		temp.chartData = [];
		for (let k in row) {
			if (k !== "key") temp.chartData.push(row[k]);
		}
		rawChartData.push(temp);
	});
	return rawChartData;
}
export function reverseTransformLabels(
	processedChartData: {
		[id: string]: number;
	}[]
) {
	const rawChartLabels: {
		headerName: string;
		orderBy: number;
	}[] = [];
	let order = 1;
	for (let key in processedChartData[0]) {
		rawChartLabels.push({ headerName: key, orderBy: order++ });
	}
	return rawChartLabels;
}

export function reverseTransformSeries(
	processedSeries: {
		id: number;
		name: string;
		dim: number;
		color: string;
		chartComponentId: number;
	}[]
) {
	const rawSeries: {
		name: string;
		rowNumber: number;
		color: string;
	}[] = [];

	processedSeries.map((series) => {
		rawSeries.push({
			name: series.name,
			rowNumber: series.dim,
			color: series.color,
		});
	});
	return rawSeries;
}
