import { ResponsiveChoropleth } from "@nivo/geo";
import { mapData } from "./geoFeatures";
// import { data } from "./data";
import * as ReportTypesComponentProps from "./../interfaces/main";
import transformData from "./transform";
const theme = (textColor?: string, background?: string, fontSize?: number) => {
	return {
		background: background || "white",
		textColor: textColor || "black",
		fontSize: fontSize || 11,
	};
};
/**
 * Renders the Responsive GeoMap Component. The component makes use of Choropleth Component of Nivo Charts Library.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @param props - Chart Config for the GeoMap Chart Component
 *
 * @returns GeoMap Chart Component of Nivo
 */
const Choropleth: React.FC<ReportTypesComponentProps.Interface> = (
	props: ReportTypesComponentProps.Interface
): JSX.Element => (
	<>
		{console.log(transformData(props.data, props.series))}
		<ResponsiveChoropleth
			data={transformData(props.data, props.series)}
			features={mapData.features}
			colors={props.chartConfig.geoMap?.colorArray || "nivo"}
			unknownColor={props.chartConfig.geoMap?.unknownColor || "#666666"}
			domain={props.chartConfig.geoMap?.domain || [0, 1000000]}
			projectionType={
				props.chartConfig.geoMap?.projection?.type || "mercator"
			}
			projectionTranslation={
				props.chartConfig.geoMap?.projection?.translation || [0.5, 0.5]
			}
			projectionRotation={
				props.chartConfig.geoMap?.projection?.rotation || [0, 0, 0]
			}
			projectionScale={props.chartConfig.geoMap?.projection?.scale || 100}
			theme={theme(
				props.style?.textColor,
				props.chartArea?.background,
				props.style?.fontSize
			)}
			borderWidth={props.style?.borderWidth || 0.5}
			borderColor={props.style?.borderColor || "#152538"}
			// label="properties.name"
			valueFormat=".2s"
			enableGraticule={props.chartConfig.geoMap?.graticule ? true : false}
			graticuleLineWidth={props.chartConfig.geoMap?.graticule?.lineWidth}
			graticuleLineColor={
				props.chartConfig.geoMap?.graticule?.lineColor || "#dddddd"
			}
			isInteractive={props.tooltip ? true : false} // tooltip format to be added
			margin={{
				top: 0,
				right: 80,
				bottom: 20,
				left: 0,
			}}
			legends={[
				{
					anchor: props.legend?.position || "bottom-left",
					direction: props.legend?.direction || "column",
					justify: true,
					translateX: props.legend?.translateX || 20,
					translateY: props.legend?.translateY || -100,
					itemsSpacing: 0,
					itemWidth: 94,
					itemHeight: 18,
					itemDirection: "left-to-right",
					itemTextColor: props.style?.textColor || "#444444",
					itemOpacity: 0.85,
					symbolSize: props.legend?.symbolSize || 18,
					effects: [
						{
							on: "hover",
							style: {
								itemTextColor:
									props.style?.textColor || "#000000",
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	</>
);
export default Choropleth;
