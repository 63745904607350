import styled from "styled-components";
import { colors } from "./../../globalTheme";
import { Tooltip } from "antd";
export const TileContainer = styled.div`
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${(props: {
    bg?: string;
    height?: string;
    highlight?: boolean;
  }) =>
    props.bg || colors.zsWhite}; // Have option for transparent or other colors

  height: ${(props: { bg?: string; height?: string; highlight?: boolean }) =>
    props.height || "100%"};
  /* box-shadow: 4px 4px 10px #bebebe; */
  border-radius: 10px;
  box-shadow: ${(props: { g?: string; height?: string; highlight?: boolean }) =>
    props.highlight ? "0px 0px 20px #e75a22" : "none"};
  color: ${colors.zsGray80};
`;

export const TileHeader = styled.div`
  padding: 0.6em 1em 1em 2em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /*justify-content: space-between; */
  z-index: 0;
  position: relative;
  margin-top: 8px;
  box-sizing: border-box;
`;
export const TitleIcon = styled.div`
  color: ${colors.zsGray90};
  flex: 3em;
`;
export const TileHeaderText = styled.p`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  color: ${colors.zsGray90};
  margin: 0px 0px;
  flex: calc(100% - 2em);
  span {
    padding: 2px;
  }
`;
export const TileHeaderActions = styled.div`
  margin: 0 4px;
  display: flex;
  flex: row;
  align-items: center;
  justify-content: left;
`;
export const TileHeaderAction = styled.div`
  margin: 0px 4px;
  opacity: 1;
  line-height: 100%;
  cursor: pointer;
  flex: 5%;
  color: ${colors.zsTeal};
  a {
    color: ${colors.zsTeal};
  }

  &:hover {
    color: ${colors.zsOrange};
    transform: scale(110%);
  }
`;
export const HeaderRibbon = styled.div`
  display: block;
  content: "";
  position: absolute;
  left: 1%;
  width: 98%;
  bottom: 0;
  border-bottom: 1px solid ${colors.zsGray20};
`;

export const TileBody = styled.div`
  flex: 1;
  /* display: flex;
	flex-direction: column; */
  justify-content: center;
  min-height: ${(props: {
    minHeight?: string;
    bodyPadding?: string;
    edit?: boolean;
  }) => props.minHeight || "auto"};
  padding: ${(props: {
    minHeight?: string;
    bodyPadding?: string;
    edit?: boolean;
  }) => props.bodyPadding || "1em 2em"};
  max-height: ${(props: {
    minHeight?: string;
    bodyPadding?: string;
    edit?: boolean;
  }) => (props.edit ? "35px" : "auto")};
  overflow: hidden;
  margin: 0px 0;
  border-radius: 5px;
  box-sizing: border-box;
  p {
    margin-bottom: 0;
  }
`;
export const StyledTooltip = styled(Tooltip)`
  vertical-align: super;
  font-size: 0.6rem;
  padding: 5px;
`;
