import { request } from "./../layout";
export const getSupportingMessageData = async (keyMessageId: number) => {
  const fetchUrl = "SubSection/GetSupportingMessage?CardBodyId=" + keyMessageId;
  console.log(fetchUrl);
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log("Support : " + JSON.stringify(response.data));
      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  return data;
};
export const getSupportingMessageEvidenceAtlasData = async (
  keyMessageId: number
) => {
  const fetchUrl = "SubSection/GetEvidenceAtlas?CardBodyId=" + keyMessageId;
  console.log(fetchUrl);
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      console.log("Response from API", response);
      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  return data;
};
export const deleteSupportingMessageData = async (
  id: number,
  productId: number
) => {
  let fetchUrl =
    "SubSection/DeleteSupportingMessage/" + id + "?BrandId=" + productId;

  const data = await request
    .delete(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response);
      return response.status;
    })
    .catch((err) => {
      console.log(err.message, "error here");
      return null;
    });
  return data;
};
export const addSupportingMessageData = async (
  supportingMessageData: any,
  productId: number
) => {
  let fetchUrl = "SubSection/AddSupportingKeyMessage?BrandId=" + productId;
  console.log("Adding : " + JSON.stringify(supportingMessageData));
  const data = await request
    .post(fetchUrl, supportingMessageData, { withCredentials: true })
    .then((response) => {
      console.log("Adding", response);
      return response.status;
    })
    .catch((err) => {
      console.log(err.message, "error here");
      return null;
    });
  return data;
};

export const editSupportingMessageEvidenceAtlas = async (
  supportingMessageData: any,
  keyMessageId: number
) => {
  let fetchUrl = "SubSection/SaveEvidenceAtlas?cardbodyId=" + keyMessageId;
  console.log("Editing", supportingMessageData, keyMessageId);
  const data = await request
    .post(fetchUrl, supportingMessageData, {
      headers: {
        contentType: "application/json",
      },
      withCredentials: true,
    })
    .then((response) => {
      // console.log(response);
      return response.status;
    })
    .catch((err) => {
      console.log(err.message, "error here");
      return null;
    });
  return data;
};

export const editSupportingMessageData = async (
  supportingMessageData: any,
  keyMessageId: number,
  productId: number
) => {
  let fetchUrl =
    "SubSection/EditSupportingKeyMessage?id=" +
    keyMessageId +
    "&BrandId=" +
    productId;
  console.log("Editing", supportingMessageData);
  const data = await request
    .patch(fetchUrl, supportingMessageData, {
      headers: {
        contentType: "application/json",
      },
      withCredentials: true,
    })
    .then((response) => {
      // console.log(response);
      return response.status;
    })
    .catch((err) => {
      console.log(err.message, "error here");
      return null;
    });
  return data;
};

export const editTableData = async (
  tableData: any,
  supportingMessageId: number,
  tableId: number,
  productId: number
) => {
  let fetchUrl =
    "SubSection/AddTableComponent?supportingKeyMsgId=" +
    supportingMessageId +
    "&supportingTableId=" +
    tableId +
    "&BrandId=" +
    productId;
  console.log("Editing Table", tableData);
  const data = await request
    .post(fetchUrl, tableData, {
      headers: {
        contentType: "application/json",
      },
      withCredentials: true,
    })
    .then((response) => {
      // console.log(response);
      return response.status;
    })
    .catch((err) => {
      console.log(err.message, "error here");
      return null;
    });
  return data;
};

export const editChartData = async (
  chartData: any,
  chartId: number,
  supportingMessageId: number,
  productId: number
) => {
  let fetchUrl =
    "SubSection/AddChartComponent?supportingKeyMsgId=" +
    supportingMessageId +
    "&SupportingChartId=" +
    chartId +
    "&BrandId=" +
    productId;
  console.log("Editing Chart", chartData);
  const data = await request
    .post(fetchUrl, chartData, {
      headers: {
        contentType: "application/json",
      },
      withCredentials: true,
    })
    .then((response) => {
      console.log("Response", response.status);
      return response.status;
    })
    .catch((err) => {
      console.log(err.message, "error here");
      return null;
    });
  return data;
};

export const editOrderData = async (orderData: any, productId: number) => {
  let fetchUrl = "SubSection/EditSupportingMessageOrder?BrandId=" + productId;
  // console.log("Save Order API :", orderData);
  const data = await request
    .patch(fetchUrl, orderData, {
      headers: { contenType: "application/json" },
      withCredentials: true,
    })
    .then((response) => {
      console.log(response);

      return response.status;
    })
    .catch((err) => {
      console.log(err.message, "error here");
      return null;
    });
  return data;
};
