import React from "react";
import AppContext from "./../../store/index";
import "../../layout/components/body-layout/Subtabs/Subtabs.css";
import BodyCardLayout from "../../layout/components/body-layout";
import { Route, Switch, useParams } from "react-router-dom";
import Tile3 from "../../libraries/tile3";
import { message } from "antd";
import { request } from "../../layout";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import { SearchOutlined } from "@ant-design/icons";
import {
  SectionContainer,
  SectionReferences,
  ReferenceContainer,
  SectionHeader,
  SubsectionBody,
} from "./style";
/**
 * Renders the Executive Summary Page.
 *
 * @returns Executive Summary Page
 */

message.config({
  maxCount: 1,
});

export default function Search() {
  const [loading, setLoading] = React.useState(true);
  const context = React.useContext(AppContext);
  const [Data, setData] = React.useState<any>([]);

  //@ts-ignore
  let { value } = useParams();
  React.useEffect(() => {
    context.setView(13);
    context.setShowSearch(false);
    let searchValue = value.replaceAll("&", " ");
    console.log(searchValue.toLowerCase());
    context.setNewSearchWord(searchValue.toLowerCase());
    if (Data === null || Data.length === 0 || Data === undefined) {
      if (context.newSearch === true) {
        message.loading("Searching", 1);
        GlobalSearch(searchValue);
      } else {
        setData(context.prevSearchResult);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  function GlobalSearch(keyword: string) {
    //api to get search results
    setLoading(true);
    request
      .get(
        process.env.REACT_APP_API_URL +
          "Brand/GlobalSearch?brandId=" +
          context.productId +
          "&keyword=" +
          keyword,
        {
          method: "GET",
          withCredentials: true,
        }
      )
      .then((res: any) => {
        setData(res.data);
        context.setisNewSearch(false);
        context.setPrevSearch(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // message.error("Error!");
        // console.log({ err });
        setLoading(false);
        setData([]);
      });
  }

  return (
    <ReferenceContainer>
      {loading ? (
        <div
          className="flex items-center justify-center mx-4 px-2 py-2"
          style={{ height: "75vh" }}
        >
          <BeatLoader color={"#27A6A4"} size={30} />
        </div>
      ) : Data.length === 0 || Data === undefined || Data === null ? (
        <div
          className="flex items-center justify-center mx-4 px-2 py-2 bg-white shadow-md"
          style={{ height: "77vh" }}
        >
          <div className="text-center">
            <SearchOutlined style={{ fontSize: "3rem", color: "#27a6a4" }} />
            <p className="text-xl italic mt-2">No Search Results Found!</p>
          </div>
        </div>
      ) : (
        <>
          {Data.map((ref: any) => {
            return (
              <>
                <SectionHeader>
                  <h3>{ref.sectionName}</h3>
                </SectionHeader>
                <SubsectionBody>
                  {ref.lstsearch.map((refs: any) => {
                    return (
                      <>
                        <SectionContainer>
                          <SectionReferences>
                            <Tile3 lstsearch={refs} setData={setData}></Tile3>
                          </SectionReferences>
                        </SectionContainer>
                      </>
                    );
                  })}
                </SubsectionBody>
              </>
            );
          })}
        </>
      )}
    </ReferenceContainer>
  );
}
