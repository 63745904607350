import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Layout from "./layout";
import AppContext, { ContextProvider } from "./store/index";
import SLR_Layout from "./SLR/layout/SLR_Layout";
import GVD_Logo from "./SLR/pictures/gvd.png";
import SLR_Logo from "./SLR/pictures/slr.png";

import TestComponents from "./testComponents";
import Landing from "./Landing";
// import { getAllBrandsData } from "./services/test";

function App() {
  //comment to commit
  const [app, setApp] = useState(window.sessionStorage.getItem("appInfo"));

  useEffect(() => {
    let url = window.location;
    console.log(url);
    if (url.href.includes("/SLR")) {
      setApp("SLR");
      window.sessionStorage.setItem("appInfo", "SLR");
    }
    if (url.pathname === "/") {
      setApp("");
      console.log("SETTING BLAnk");
      window.sessionStorage.setItem("appInfo", "");
    }
    const appInfo = window.sessionStorage.getItem("appInfo");
    console.log("appInfo", appInfo);
    if (appInfo !== "") {
      setApp(appInfo);
    } else setApp("");
  }, []);

  function setSelectedApp(selectedApp: string) {
    console.log("selectedApp", selectedApp);
    window.sessionStorage.setItem("appInfo", selectedApp);
    setApp(selectedApp);
  }

  // let [responseData, setResponseData] = React.useState("");
  // const fetchData = (e: any) => {
  // 	e.preventDefault();
  // 	getAllBrandsData()
  // 		.then((response: any) => {
  // 			setResponseData(response.data);
  // 			console.log(response);
  // 		})
  // 		.catch((error) => {
  // 			console.log(error);
  // 		});
  // };
  // return (
  // 	<>
  // 		<h3>Hello World!!!</h3>
  // 		<button
  // 			onClick={(e) => {
  // 				fetchData(e);
  // 				setResponseData("Fetching...");
  // 			}}
  // 			type="button"
  // 		>
  // 			Click for Fetch
  // 		</button>
  // 		<p>{responseData}</p>
  // 	</>
  // );
  // return <TestComponents />;

  return (
    // <ContextProvider>
    // 	<BrowserRouter>
    // 		<Layout />
    // 	</BrowserRouter>
    // </ContextProvider>
    <div>
      {app === "SLR" ? (
        <ContextProvider>
          <BrowserRouter>
            <SLR_Layout />
          </BrowserRouter>
        </ContextProvider>
      ) : app === "GVD" ? (
        <>
          <ContextProvider>
            <BrowserRouter>
              <Layout />
            </BrowserRouter>
          </ContextProvider>
        </>
      ) : (
        <ContextProvider>
          <BrowserRouter>
            <Route exact path="/">
              <Landing setApp={setSelectedApp} />
            </Route>
            {/* <Route exact path="/SLR"> */}
            {/* //       <SLR_Layout />
      //     </Route>
      //     <Route exact path="/GVD">
      //       <Layout />
      //     </Route> */}
          </BrowserRouter>
        </ContextProvider>
      )}
    </div>
  );
}

export default App;
