import { Save } from "@styled-icons/boxicons-regular/Save";

export default function SaveIcon(props: { handleClick?: () => void }) {
  return (
    <div
      className="flex"
      onClick={() => {
        if (props.handleClick) props.handleClick();
      }}
    >
      <Save
        title="Save Current Settings"
        size={24}
        style={{ color: "#32A29B" }}
      />
      <p style={{ color: "#32A29B" }} className="mb-0 ml-1 font-semibold">
        Save
      </p>
    </div>
  );
}
