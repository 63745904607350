import {
	HeaderRibbon,
	TileHeaderText,
	TileBody,
	TileContainer,
	TileHeader,
} from "../../../libraries/tile/style";

export default function ContactCard() {
	return (
		<TileContainer>
			<TileHeader>
				<TileHeaderText>Contact us</TileHeaderText>
				<HeaderRibbon />
			</TileHeader>
			<TileBody>
				For technical support or questions about the dGVD, please
				contact&nbsp;
				<a href="mailto:digitalgvd@zs.com">digitalgvd@zs.com</a>.
			</TileBody>
		</TileContainer>
	);
}
