import React from "react";
import { message, Modal, Skeleton } from "antd";
import ReportTypes from "../../libraries/report-types";
import SimpleTable from "../../libraries/report-types/charts/table/simpleTable/table";
import { ColumnFilter } from "../../libraries/report-types/charts/table/simpleTable/columnFilter";
import { AbbreviationsContainer } from "./style";
import AppContext from "../../store";
import * as AbbreviationsServiceHelper from "./service-helper";
import FormField7 from "../../libraries/form-fields/formField7";
import { Delete } from "@styled-icons/feather/Delete";

export default function Abbreviations() {
  const context = React.useContext(AppContext);
  React.useEffect(() => {
    context.setView(12);
    fetchdata(context.productId);
  }, []);

  /**
   * Renders the Abbreviations Page.
   *
   * @returns Abbreviations Page of the App
   */
  const [columns, setColumns] = React.useState(
    context.isAdmin
      ? [
          {
            title: "Abbreviation",
            accessor: "abbreviation",
            Filter: ColumnFilter,
            sort: true,
            width: 50,
          },
          {
            title: "Details",
            accessor: "details",
            Filter: ColumnFilter,
          },
          {
            title: "Action",
            accessor: "action",
            Filter: ColumnFilter,
            width: 10,
          },
        ]
      : [
          {
            title: "Abbreviation",
            accessor: "abbreviation",
            Filter: ColumnFilter,
            sort: true,
            width: 50,
          },
          {
            title: "Details",
            accessor: "details",
            Filter: ColumnFilter,
          },
        ]
  );
  const [data, setData] = React.useState<
    {
      id: number;
      abbreviation: string | JSX.Element;
      details: string | JSX.Element;
      action?: string | JSX.Element;
    }[]
  >([
    {
      id: 0,
      abbreviation: (
        <Skeleton.Input style={{ width: 400 }} active size="default" />
      ),
      details: <Skeleton.Input style={{ width: 400 }} active size="default" />,
      action: <Skeleton.Input style={{ width: 400 }} active size="default" />,
    },
    {
      id: 0,
      abbreviation: (
        <Skeleton.Input style={{ width: 400 }} active size="default" />
      ),
      details: <Skeleton.Input style={{ width: 400 }} active size="default" />,
      action: <Skeleton.Input style={{ width: 400 }} active size="default" />,
    },
  ]);
  const [visible, setVisible] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("Modal Header");
  const [modalText, setModalText] = React.useState(<></>);

  async function fetchdata(productId: number) {
    const d: any = await AbbreviationsServiceHelper.getAbbreviations(productId);

    if (context.isAdmin) {
      d[0].abbreviationsList.map((row: any) => {
        row.action = (
          <>
            <Delete
              size={24}
              style={{
                marginLeft: "15px",
                cursor: "pointer",
                color: "red",
              }}
              onClick={() => handleDelete(row.id)}
            />
          </>
        );
      });
    }
    setData(d[0].abbreviationsList);
  }

  const showModal = () => {
    setModalHeader("Add Abbreviations");
    setModalText(
      <FormField7
        data={{ columns: columns, tableData: data }}
        addRows={addRows}
        handleSave={handleSaveTable}
      />
    );
    setVisible(true);
  };
  const addRows = (value: any) => {
    let tempData = value.tableData;
    setVisible(false);
    setData(tempData);
  };
  async function handleSaveTable(value: any) {
    // setTableName((tableName) => value.name);
    setVisible(false);
    const key = "updatable";
    message.loading({ content: "Adding...", key });
    const data: any = await AbbreviationsServiceHelper.addAbbreviation(
      context.productId,
      value
    );
    if (data) {
      if (context.isAdmin) {
        data[0].abbreviationsList.map((row: any) => {
          row.action = (
            <>
              <Delete
                size={24}
                style={{
                  marginLeft: "15px",
                  cursor: "pointer",
                  color: "red",
                }}
                onClick={() => handleDelete(row.id)}
              />
            </>
          );
        });
      }
      setData(data[0].abbreviationsList);
      message.success({ content: "Saved successfully", key });
    } else message.error({ content: "Save failed", key });
  }

  async function handleDelete(id: number) {
    const key = "updatable";
    message.loading({ content: "Deleting...", key });
    const data: any = await AbbreviationsServiceHelper.deleteAbbreviation(
      id,
      context.productId
    );
    if (data) {
      if (context.isAdmin) {
        data[0].abbreviationsList.map((row: any) => {
          row.action = (
            <>
              <Delete
                size={24}
                style={{
                  marginLeft: "15px",
                  cursor: "pointer",
                  color: "red",
                }}
                onClick={() => handleDelete(row.id)}
              />
            </>
          );
        });
      }
      setData(data[0].abbreviationsList);
      message.success({ content: "Saved successfully", key });
    } else message.error({ content: "Save failed", key });
  }
  const handleCancel = () => {
    setModalText(<></>);
    setVisible(false);
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        title={modalHeader}
        visible={visible}
        // onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
        width={1000}
      >
        {modalText}
      </Modal>

      <AbbreviationsContainer>
        <SimpleTable
          tableData={{ data: data, column: columns }}
          rows={10}
          addButton={showModal}
        />
      </AbbreviationsContainer>
    </>
  );
}
