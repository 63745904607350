import BeatLoader from "react-spinners/BeatLoader";
import { Button, Drawer, message, Popover, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  FitViewOptions,
  applyNodeChanges,
  applyEdgeChanges,
  Node,
  Edge,
  NodeChange,
  EdgeChange,
  Connection,
  useReactFlow,
  Handle,
  Position,
  useUpdateNodeInternals,
  updateEdge,
} from "react-flow-renderer";
import {
  EditFilled,
  SaveOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  UndoOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { request } from "../../layout/SLR_Layout";
import { TextBulletListSquare } from "@styled-icons/fluentui-system-regular/TextBulletListSquare";
import SimpleMdeReact from "react-simplemde-editor";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router-dom";

const fitViewOptions: FitViewOptions = {
  padding: 0.02,
  minZoom: -2,
};

export default function DataCollection() {
  const [inEditMode, setInEditMode] = useState(false);
  const [inReadOnlyMode, setInReadOnlyMode] = useState(true);
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [nodesHoverData, setNodesHoverData] = useState<any>([]);
  const [initialNodes, setInitialNodes] = useState<Node[]>([]);
  const [initialNodesHoverData, setInitialNodesHoverData] = useState<any>([]);
  const [initialEdges, setInitialEdges] = useState<Edge[]>([]);
  const [sections, setSections] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [screenInfo, setScreenInfo] = useState<any>(
    "This section shows the data collection and extraction process, where two independent reviewers performed the data collection and extraction, and any discrepancies were resolved by a third reviewer."
  );
  const [footnote, setFootnote] = useState<any>("");
  const [footnoteInitial, setFootnoteInitial] = useState<any>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [inEditModeFootnote, setInEditModeFootnote] = useState(false);
  const [isLoadingFootnote, setIsLoadingFootnote] = useState(true);

  const yPos = useRef(20);
  const nodeId = useRef(1);
  const nodeTypesEdit = useMemo(
    () => ({ textUpdater: TextUpdaterNode }),
    [nodesHoverData, nodeId]
  );
  const nodeTypesRead = useMemo(
    () => ({ textUpdater: TextUpdaterNodeRead }),
    [nodesHoverData, nodeId]
  );
  const edgeUpdateSuccessful = useRef(true);
  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "100%",
      maxHeight: "100%",
    };
  }, []);
  const navigate = useHistory();

  useEffect(() => {
    // check redirection
    if (
      window.sessionStorage.getItem("selectedBackgroundName") === undefined ||
      window.sessionStorage.getItem("selectedBackgroundName") === null ||
      window.sessionStorage.getItem("selectedBackgroundName") === "" ||
      window.sessionStorage.getItem("productReviewId") === undefined ||
      window.sessionStorage.getItem("productReviewId") === null ||
      window.sessionStorage.getItem("productReviewId") === "" ||
      window.localStorage.getItem("productId") === undefined ||
      window.localStorage.getItem("productId") === null ||
      window.localStorage.getItem("productId") === ""
    ) {
      navigate.push("/SLR");
      return;
    }
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    request
      .get("/GetFlowchart?productReviewId=" + productReviewId + "&sectionId=3")
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (res.data.node.length === 0 || !res.data.node) setNoData(true);
          let tempNodes = [...res.data.node];
          let tempSections = [...res.data.phaseData];
          let tempEdges = [...res.data.edge];
          let tempHoverData = [...res.data.nodeHoverData];
          console.log("tempSections", tempSections);
          tempNodes.map((node: any) => {
            node.className = "hover:border-black hover:shadow-xl";
            node.id = String(node.id);
            node.type = "textUpdater";
            delete node.phaseId;
          });
          console.log("Nodes :", tempNodes);
          tempEdges.map((edge: any, index: any) => {
            edge.type = "step";
            edge.id = String(index);
            edge.source = String(edge.source);
            edge.target = String(edge.target);
            delete edge.sectionId;
          });
          console.log("edges :", tempEdges);
          tempHoverData.map((item: any) => (item.id = String(item.id)));
          console.log("hoverData", tempHoverData);
          nodeId.current = Number(tempNodes?.length) || 1;
          setNodes([...tempNodes]);
          setEdges([...tempEdges]);
          setNodesHoverData([...tempHoverData]);
          setInitialNodes([...tempNodes]);
          setInitialEdges([...tempEdges]);
          setInitialNodesHoverData([...tempHoverData]);
          setSections([...tempSections]);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  function saveHandler() {
    let tempNodeHoverData: any = [];
    nodesHoverData.map((item: any) => {
      let index = nodes.findIndex((x) => String(x.id) === String(item["id"]));
      if (index > -1) {
        tempNodeHoverData.push(item);
      }
    });

    let colorId: any = {};
    sections.map((section: any) => {
      colorId[section.color] = section.id;
    });

    let tempNodes: any = JSON.parse(JSON.stringify(nodes));
    tempNodes.map((node: any) => {
      node.id = Number(node.id);
      node.phaseId = colorId[node.data.color];
      node.position.x = Math.floor(node.position.x);
      node.position.y = Math.floor(node.position.y);
      delete node.className;
    });

    let tempEdges: any = JSON.parse(JSON.stringify(edges));
    tempEdges.map((edge: any) => {
      edge.sectionId = 3;
      edge.source = Number(edge.source);
      edge.target = Number(edge.target);
      delete edge.id;
      delete edge.type;
    });

    tempNodeHoverData.map((node: any) => {
      node.id = Number(node.id);
    });

    console.log(tempNodes, tempEdges, tempNodeHoverData, colorId);

    const productReviewId = window.sessionStorage.getItem("productReviewId");
    message.loading("Saving Changes!", 1.8);

    request
      .post(
        "/EditFlowchart?productReviewId=" +
          String(productReviewId) +
          "&sectionId=3",
        {
          nodeHoverData: tempNodeHoverData,
          node: tempNodes,
          edge: tempEdges,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setInitialNodes([...nodes]);
          setInitialEdges([...edges]);
          nodesHoverData.map((node: any) => {
            node.id = String(node.id);
          });
          setNodesHoverData([...nodesHoverData]);
          setInitialNodesHoverData([...nodesHoverData]);
          setNoData(false);
          message.success("Saved!");
        } else {
          setNodes([...initialNodes]);
          setEdges([...initialEdges]);
          setNodesHoverData([...initialNodesHoverData]);
          message.error("Error!");
        }
        console.log("res", res);
      })
      .catch((err) => {
        message.error("Error!");
        console.log(err);
      });
    setInEditMode(false);
    setInReadOnlyMode(true);
  }

  function updateLayout() {
    let temp = [...edges];
    temp.map((t: any) => {
      t["type"] = "step";
    });
    setEdges([...temp]);
    setInReadOnlyMode(true);
  }

  function TextUpdaterNode(data: any) {
    const [hoverData, setHoverData] = useState<any>("");
    const [open, setOpen] = useState(false);

    const style = useMemo(
      () => ({
        height: "100%",
        minWidth: "10rem",
        background: data.data?.color,
        padding: 5,
        borderRadius: 5,
      }),
      []
    );

    useEffect(() => {
      setHoverData(nodesHoverData.find((ele: any) => ele.id === data.id)?.data);
    }, []);

    const onChange = useCallback((evt) => {
      setNodes((els: any) => {
        els.map((el: any) => {
          if (el.id === data.id) {
            el.data.label = evt.target.value;
          }
        });
        return [...els];
      });
    }, []);

    function onSaveHoverData() {
      let temp = [...nodesHoverData];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]["id"] === data.id) {
          temp[i]["data"] = hoverData;
        }
      }
      setNodesHoverData([...temp]);
      setOpen(false);
    }

    const handleOpenChange = (newOpen: boolean) => {
      setOpen(newOpen);
    };

    return (
      <div style={style}>
        <Handle type="target" position={Position.Top} id="a" />
        <div className="flex items-center">
          <input
            style={{ height: "100%", width: "95%" }}
            id="text"
            name="text"
            onChange={onChange}
            placeholder="Enter Data Here"
            className="px-3 py-1 bg-transparent"
            defaultValue={data.data.label}
          />
          <div className="pr-4" style={{ width: "5%" }}>
            <Popover
              placement="right"
              visible={open}
              onVisibleChange={handleOpenChange}
              content={
                <div style={{ minHeight: "5rem" }}>
                  <textarea
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      resize: "none",
                      outline: "none",
                      border: "0.5px solid lightgray",
                    }}
                    className="text-xs"
                    rows={4}
                    onChange={(e: any) => setHoverData(e.target.value)}
                    defaultValue={hoverData}
                  />
                  <br />
                  <div className="flex justify-end items-end mt-1">
                    <Button
                      size="small"
                      type="default"
                      onClick={onSaveHoverData}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              }
              trigger="click"
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
        </div>
        <Handle type="source" position={Position.Right} id="b" />
        <Handle type="target" position={Position.Left} id="c" />
        <Handle type="source" position={Position.Bottom} id="d" />
      </div>
    );
  }

  function TextUpdaterNodeRead(data: any) {
    const [hoverData, setHoverData] = useState<any>("");
    const [open, setOpen] = useState(false);

    const style = useMemo(
      () => ({
        height: "100%",
        minWidth: "10rem",
        maxWidth: "50rem",
        background: data.data?.color,
        padding: "0.5rem",
        borderRadius: 5,
      }),
      []
    );

    useEffect(() => {
      setHoverData(nodesHoverData.find((ele: any) => ele.id === data.id)?.data);
    }, []);

    const handleOpenChange = (newOpen: boolean) => {
      setOpen(newOpen);
    };

    return (
      <div className="flex justify-center items-center" style={style}>
        <Handle
          style={{ backgroundColor: "transparent" }}
          type="target"
          position={Position.Top}
          id="a"
        />
        <div style={{ width: "100%" }} className="flex items-center">
          <div className="text-center" style={{ width: "95%" }}>
            {data.data.label}
          </div>
          <div className="pr-4 " style={{ width: "5%" }}>
            <Popover
              placement="right"
              visible={open}
              onVisibleChange={handleOpenChange}
              content={
                <div style={{ maxWidth: "10rem" }}>
                  <div className="text-xs">{hoverData}</div>
                  <br />
                  <div className="flex justify-end items-end mt-1">
                    <Button
                      size="small"
                      type="default"
                      onClick={() => setOpen(false)}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              }
              trigger="click"
            >
              <InfoCircleOutlined />
            </Popover>
          </div>
        </div>
        <Handle
          style={{ backgroundColor: "transparent" }}
          type="source"
          position={Position.Right}
          id="b"
        />
        <Handle
          style={{ backgroundColor: "transparent" }}
          type="target"
          position={Position.Left}
          id="c"
        />
        <Handle
          style={{ backgroundColor: "transparent" }}
          type="source"
          position={Position.Bottom}
          id="d"
        />
      </div>
    );
  }

  const onNodesChange = useCallback(
    (changes: NodeChange[]) =>
      setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) =>
      setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const onConnect = useCallback(
    (connection: Connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const onClickAddNode = useCallback(
    (phaseId: any, color: any) => {
      setNodes((els: any) => {
        yPos.current += 60;
        yPos.current = yPos.current > 500 ? 0 : yPos.current;
        return [
          ...els,
          {
            id: `${++nodeId.current}`,
            position: { x: 20, y: yPos.current },
            data: {
              label: `Node ${nodeId.current}`,
              color: String(color),
            },
            type: "textUpdater",
          },
        ];
      });

      let temp = [...nodesHoverData];
      temp.push({
        id: String(nodeId.current),
        data: "",
      });
      setNodesHoverData([...temp]);
    },
    [nodesHoverData, nodeId.current]
  );

  const handlePopOverOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  function saveHandlerFootnote() {
    console.log("data after save", footnote);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Data Collection & Extraction Process"];
    message.loading("Saving Changes!", 0.8).then(() => {
      request
        .post(
          "/EditFootNote?productReviewId=" +
            productReviewId +
            "&id=" +
            sectionId,
          {
            footNote: footnote,
          }
        )
        .then((res) => {
          console.log("res", res);
          if (res.status === 200) {
            message.success("Saved!");
            setFootnoteInitial(footnote);
          } else {
            message.error("Error!");
            setFootnote(footnoteInitial);
          }
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    });
    setInEditModeFootnote(false);
  }

  function showDrawerFootnote() {
    setIsLoadingFootnote(true);

    setDrawerOpen(true);
    const productReviewId = window.sessionStorage.getItem("productReviewId");
    const sectionId = JSON.parse(
      window.sessionStorage.getItem("sectionId") || ""
    )["Data Collection & Extraction Process"];
    request
      .get(
        "/GetFootNote?productReviewId=" + productReviewId + "&id=" + sectionId
      )
      .then((res: any) => {
        console.log("response from API", res);
        if (res.status === 200) {
          if (!res.data.footNote) setFootnote("");
          else setFootnote(res.data.footNote);
          setFootnoteInitial(res.data.footNote);
          setIsLoadingFootnote(false);
        } else {
          setIsLoadingFootnote(false);
          message.error("Error!");
        }
      })
      .catch((err) => {
        setIsLoadingFootnote(false);
        console.log(err);
      });
    // setIsLoadingFootnote(false);
  }

  return (
    <div style={{ overflow: "hidden", position: "relative", height: "100%" }}>
      <div className="flex px-4 py-2 items-center bg-white">
        <p className="w-1/2 text-lg font-semibold pl-1">
          <Popover
            placement="bottomLeft"
            visible={popoverOpen}
            onVisibleChange={handlePopOverOpenChange}
            style={{ top: "4rem" }}
            overlayStyle={{
              minHeight: "2rem",
              maxWidth: "40rem",
            }}
            content={
              <div className="text-xs" style={{ maxWidth: "30rem" }}>
                <p>{screenInfo}</p>
              </div>
            }
            trigger={"hover"}
          >
            <InfoCircleOutlined
              style={{
                marginRight: "0.6rem",
                fontSize: "1.1rem",
                marginTop: "0.2rem",
              }}
            />
          </Popover>
          Data Collection & Extraction Process
        </p>
        {window.sessionStorage.getItem("isAdmin") === "2" && (
          <div className="flex w-1/2 justify-end items-center">
            {inEditMode ? (
              <div>
                <Button type="default" onClick={saveHandler}>
                  <SaveOutlined />
                  Save
                </Button>
                <Button
                  className="ml-2"
                  type="default"
                  onClick={() => {
                    setNodes([...initialNodes]);
                    setEdges([...initialEdges]);
                    setNodesHoverData([...initialNodesHoverData]);
                    setInEditMode(false);
                  }}
                >
                  <CloseOutlined />
                  Cancel
                </Button>
              </div>
            ) : (
              <Button
                type="default"
                onClick={() => {
                  setInEditMode(true);
                  setInReadOnlyMode(false);
                }}
              >
                <EditFilled />
                Edit
              </Button>
            )}
            <TextBulletListSquare
              size={30}
              style={{
                marginLeft: "0.6rem",
                color: "#27a6a4",
                cursor: "pointer",
              }}
              onClick={showDrawerFootnote}
              title="Open Footnotes"
            />
          </div>
        )}
      </div>
      {isLoading ? (
        <div
          style={{ minHeight: "80vh", overflow: "hidden", width: "100%" }}
          className="flex items-center justify-center w-full h-full"
        >
          <BeatLoader color={"#27A6A4"} loading={true} size={24} />
        </div>
      ) : noData && !inEditMode ? (
        <div
          className="mt-4 mx-4 bg-white rounded-sm shadow-md flex items-center justify-center"
          style={{ minHeight: "80vh", overflowY: "auto" }}
        >
          <div
            className="m-4 bg-white border-2 rounded-sm flex items-center justify-center w-full"
            style={{ minHeight: "75vh", overflowY: "auto" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "#27a6a4", fontSize: "3rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Edit to add details!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "#27a6a4", fontSize: "3rem" }} />
                <p className="text-xl mt-2 italic">No Details Added!</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          {inEditMode &&
            (inReadOnlyMode ? (
              <div className="bg-white m-4 flex">
                <div
                  className="m-4 border rounded-md"
                  style={{ height: "77vh", width: "80%" }}
                >
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    fitView
                    nodeTypes={nodeTypesRead}
                    panOnDrag={true}
                    nodesDraggable={true}
                    onNodesChange={undefined}
                    onEdgesChange={undefined}
                    onConnect={undefined}
                    fitViewOptions={fitViewOptions}
                    onEdgeUpdate={undefined}
                    onEdgeUpdateStart={undefined}
                    onEdgeUpdateEnd={undefined}
                  />
                </div>
                <div className="m-4" style={{ width: "20%" }}>
                  <div className="mt-2 pt-2 border-t py-2">
                    <div>
                      <Button
                        type="default"
                        size="small"
                        onClick={() => {
                          setInReadOnlyMode(false);
                        }}
                        style={{ width: "100%" }}
                      >
                        Update Chart
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-white m-4 flex ">
                <div
                  className="m-4 border rounded-md"
                  style={{ height: "77vh", width: "80%" }}
                >
                  <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypesEdit}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    fitView
                    fitViewOptions={fitViewOptions}
                    onEdgeUpdate={onEdgeUpdate}
                    onEdgeUpdateStart={onEdgeUpdateStart}
                    onEdgeUpdateEnd={onEdgeUpdateEnd}
                    nodesDraggable={true}
                    deleteKeyCode={"Delete"}
                    // style={{
                    //   backgroundColor: "#FFE3C8",
                    // }}
                  />
                </div>
                <div style={{ width: "20%", height: "100%" }} className="m-4">
                  <div>
                    <Button
                      type="default"
                      size="small"
                      onClick={updateLayout}
                      style={{ width: "100%" }}
                    >
                      Update Layout
                    </Button>
                  </div>
                  <div className="mt-2 border-t border-b py-2">
                    {sections &&
                      sections.map((section: any) => {
                        return (
                          <p
                            style={{ color: "#27a6a4" }}
                            className="font-semibold cursor-pointer mt-2"
                            onClick={() =>
                              onClickAddNode(section.id, section.color)
                            }
                          >
                            <PlusCircleOutlined style={{ color: "#27a6a4" }} />{" "}
                            {section.phaseName} Node
                          </p>
                        );
                      })}
                  </div>

                  <div className="flex items-end">
                    <div className="mt-2 text-xs">
                      <p className="font-semibold text-md">
                        Instructions for creating flowchart
                      </p>
                      <p className="my-1">
                        1. Click on buttons above to add a node under
                        corresponding section.
                      </p>
                      <p className="my-1">
                        2. To delete a node, click on the node and press Delete
                        key.
                      </p>
                      <p className="my-1">
                        3. Connect two nodes by drawing an edge using the
                        handles between them.
                      </p>
                      <p className="my-1">
                        4. To remove an edge, simply drag the edge from its
                        endpoint and release it.
                      </p>
                      <p className="my-1">
                        5. You can edit the metadata by clicking on{" "}
                        <InfoCircleOutlined /> icon next to node's label. Do not
                        forget to save the data!
                      </p>
                      <p className="my-1">
                        6. You can always drag the nodes to your desired
                        position.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {!inEditMode && (
            <div className="bg-white m-4 flex">
              <div
                className="m-4 border rounded-md"
                style={{ height: "77vh", width: "80%" }}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  fitView
                  nodeTypes={nodeTypesRead}
                  panOnDrag={true}
                  nodesDraggable={false}
                  onNodesChange={undefined}
                  onEdgesChange={undefined}
                  onConnect={undefined}
                  fitViewOptions={fitViewOptions}
                  onEdgeUpdate={undefined}
                  onEdgeUpdateStart={undefined}
                  onEdgeUpdateEnd={undefined}
                />
              </div>
              <div className="m-4" style={{ width: "20%" }}></div>
            </div>
          )}
        </div>
      )}
      <Drawer
        title={
          <div className="flex">
            <p className="text-black mb-0 w-1/2">Footnotes</p>
            <div className="flex w-1/2 justify-end items-center">
              {window.sessionStorage.getItem("isAdmin") === "2" && (
                <div className="flex w-full justify-end items-center">
                  {inEditModeFootnote ? (
                    <div className="flex">
                      <Tooltip title="Save Changes">
                        <SaveOutlined
                          onClick={saveHandlerFootnote}
                          style={{ color: "black", fontSize: "1.2rem" }}
                        />
                      </Tooltip>
                      <Tooltip title="Cancel">
                        <UndoOutlined
                          onClick={() => {
                            setInEditModeFootnote(false);
                            setFootnote(footnoteInitial);
                          }}
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            marginLeft: "0.6rem",
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    <Tooltip title="Edit Footnotes">
                      <EditFilled
                        onClick={() => setInEditModeFootnote(true)}
                        style={{ color: "black", fontSize: "1.2rem" }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Close">
                    <CloseOutlined
                      onClick={() => {
                        setDrawerOpen(false);
                        setInEditModeFootnote(false);
                      }}
                      style={{
                        color: "black",
                        fontSize: "1.2rem",
                        marginLeft: "0.6rem",
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        }
        closable={false}
        placement="bottom"
        onClose={() => {
          setDrawerOpen(false);
          setInEditModeFootnote(false);
        }}
        getContainer={false}
        visible={drawerOpen}
        style={{ position: "absolute" }}
        zIndex={20}
        bodyStyle={{ padding: "0.5rem" }}
        headerStyle={{
          paddingTop: "0.6rem",
          paddingBottom: "0.6rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          backgroundColor: "whitesmoke",
          color: "black",
        }}
      >
        {isLoadingFootnote ? (
          <div
            style={{ minHeight: "23vh", overflow: "hidden", width: "100%" }}
            className="flex items-center justify-center w-full h-full"
          >
            <BeatLoader color={"#27A6A4"} loading={true} size={24} />
          </div>
        ) : footnote === "" && !inEditModeFootnote ? (
          <div
            className="border flex items-center justify-center"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            {window.sessionStorage.getItem("isAdmin") === "2" ? (
              <div className="text-center">
                <EditFilled style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">
                  Click on Edit icon to add footnotes!
                </p>
              </div>
            ) : (
              <div className="text-center">
                <FileOutlined style={{ color: "black", fontSize: "2rem" }} />
                <p className="text-xl mt-2 italic">No Footnotes Added!</p>
              </div>
            )}
          </div>
        ) : inEditModeFootnote ? (
          <div
            className="border-r"
            style={{ minHeight: "23vh", overflowY: "auto", width: "100%" }}
          >
            <div id="introEditor" className="">
              <SimpleMdeReact
                options={options}
                value={footnote}
                onChange={(e) => {
                  let temp = footnote;
                  temp = String(e);
                  setFootnote(temp);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="border-r"
            style={{
              minHeight: "23vh",
              maxHeight: "23vh",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <div id="SLRMDEditor" className="py-4 px-4">
              <ReactMarkdown children={footnote}></ReactMarkdown>
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
}
