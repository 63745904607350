import React from "react";
import AppContext from "./../../store/index";
import { BookmarkStarFill } from "@styled-icons/bootstrap/BookmarkStarFill";
import {
  MiddleHeaderDropDown,
  MiddleHeaderText,
  StyledMiddleHeader,
} from "./style";
import { Input, message, Space } from "antd";
import { useHistory } from "react-router-dom";
import { AngleLeft } from "@styled-icons/fa-solid/AngleLeft";

/**
 * Renders the Middle Header of the Layout.
 *
 * @param tabNames - Name of the Tab which is being rendered
 *
 * @returns Middle Header of the Layout
 */

export default function MiddleHeader(props: {
  tabNames: {
    name: string;
    icon: JSX.Element;
  }[];
}) {
  const history = useHistory();
  const context = React.useContext(AppContext);
  const { Search } = Input;
  const view = context.viewSelected;
  const [inputValue, setInputValue] = React.useState("");
  const [showReturn, setShowReturn] = React.useState(false);
  const onSearch = (value: string) => {
    if (value === "") {
      setShowReturn(false);
      context.setShowSearch(false);
      context.setNewSearchLink("&  &");
      context.setNewSearchWord("");
      return;
    }
    if (value.length < 3) {
      message.error("Enter a search word greater than 3 letters");
      context.setNewSearchWord("");
      return;
    }
    var format = /[+-[\]{};':"|,.<>/!@#%^&*[^0-9]]+/;
    if (format.test(value.toLowerCase())) {
      message.error("Special Characters are not allowed!");
      return;
    }
    value = value.replaceAll(" ", "&");
    console.log(value);
    context.setisNewSearch(true);
    // setInputValue("");
    history.replace("/searchresults/" + value);
  };

  React.useEffect(() => {
    if (
      window.location.toString().includes(context.searchLink) &&
      context.showSearchReturn
    ) {
      setShowReturn(true);
    } else {
      setShowReturn(false);
      context.setShowSearch(false);
      context.setNewSearchLink("&  &");
      context.setNewSearchWord("");
    }
  });

  return (
    <StyledMiddleHeader>
      <MiddleHeaderText>
        {props.tabNames[view]?.name ? <>{props.tabNames[view].name}</> : <></>}
        {props.tabNames[view].name === "Search Results" && (
          <>
            {" for "} {context.searchWord}
          </>
        )}
      </MiddleHeaderText>
      <Space>
        {showReturn && (
          <div
            style={{ color: "#27a6a4", cursor: "pointer" }}
            className="font-semibold"
            onClick={() => history.goBack()}
          >
            <AngleLeft size={18} style={{ marginBottom: "2px" }} />
            {"Return to Search Results"}
          </div>
        )}
        <Search
          placeholder="Enter a search term"
          onSearch={onSearch}
          enterButton
          allowClear
          style={{ marginRight: "20px" }}
          // value={inputValue}
          // onChange={(e) => setInputValue(e.target.value)}
        />

        {view !== 0 && (
          <MiddleHeaderDropDown>
            <BookmarkStarFill
              size={24}
              style={{ marginBottom: "4px", marginRight: "8px" }}
            />
            {/* {console.log(props.tabNames)} */}
            {context.productName}
          </MiddleHeaderDropDown>
        )}
      </Space>
    </StyledMiddleHeader>
  );
}
