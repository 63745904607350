import { NavLink, useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
	SubtabContainer,
	SubtabLeft,
	SubtabRight,
	SubtabElement,
	StyledBorder,
} from "./style";
import { AngleLeft } from "@styled-icons/fa-solid/AngleLeft";
/**
 * Renders the Image Holder of the Body Layout.
 *
 * @param subtabOptions - Array specifying the SubTab Menus and their Links
 * @param showRight - Specifies the Return link from inside a Supporting Message Page
 *
 * @returns Body Layput of the App
 */
const Subtabs = (props: {
	subtabOptions: {
		name: string;
		link: string;
		isDefault: boolean;
	}[];
	showRight?: string;
}) => {
	let match = useRouteMatch();
	//@ts-ignore
	let { tab, subtab, cardId, key } = useParams();

	return (
		<SubtabContainer>
			<SubtabLeft showRight={props.showRight}>
				{props.subtabOptions.map((option) => {
					return (
						<SubtabElement>
							<NavLink
								className={
									option.isDefault ? "default" : "non-default"
								}
								to={`${match.url}/${option.link}`}
								activeClassName="active"
							>
								{option.name}
							</NavLink>
							<StyledBorder />
						</SubtabElement>
					);
				})}
			</SubtabLeft>

			{props.showRight != "" && (
				<SubtabRight>
					<SubtabElement>
						<NavLink to={props.showRight || ""}>
							<AngleLeft
								size={18}
								style={{ marginBottom: "2px" }}
							/>
							{console.log(props.showRight)}
							Return to{" "}
							{props.showRight
								?.split("/")
								.pop()
								?.split("_")
								.join(" ")}
						</NavLink>
					</SubtabElement>
				</SubtabRight>
			)}
		</SubtabContainer>
	);
};

export default Subtabs;
