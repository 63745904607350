import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SupportingMessageTemplate1 from "../../../layout/components/body-layout/SupportingMessages1";
import SupportingMessageTemplate2 from "../../../layout/components/body-layout/SupportingMessages2/index";
import SupportingMessageTemplate3 from "../../../layout/components/body-layout/SupportingMessages3/index";
import { message, Modal } from "antd";
import * as ServiceHelper from "../service-helper";
import FormField1 from "../../../libraries/form-fields/formField1";
import FormField4 from "../../../libraries/form-fields/formField4";
import AppContext from "../../../store";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
const dummyData = [
  {
    id: 1,
    keyMessage: "This is the Key Message that these messages are linked to.",
    supportingKeyMessage: [
      {
        id: 2,
        supportingMessageHeader:
          "CHM is caused by a loss of function mutation, where the protein (REP1) produced by the defective CHM gene has little or no function. ",
        supportingMessages: [
          {
            id: 1,
            bulletPoint:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam repellat, praesentium doloribus quibusdam harum itaque a velit iure, aperiam eum sapiente tempora maxime aliquid saepe deleniti et eius doloremque eligendi!",
            link: "some-link",
          },
          {
            id: 2,
            bulletPoint:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam repellat, praesentium doloribus quibusdam harum itaque a velit iure, aperiam eum sapiente tempora maxime aliquid saepe deleniti et eius doloremque eligendi!",
            link: "some-link",
          },
        ],
        imageURL: "/images/supporting1.png",
      },
      {
        id: 3,
        supportingMessageHeader:
          "CHM is caused by a loss of function mutation, where the protein (REP1) produced by the defective CHM gene has little or no function. ",
        supportingMessages: [
          {
            id: 1,
            bulletPoint:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam repellat, praesentium doloribus quibusdam harum itaque a velit iure, aperiam eum sapiente tempora maxime aliquid saepe deleniti et eius doloremque eligendi!",
            link: "some-link",
          },
          {
            id: 2,
            bulletPoint:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam repellat, praesentium doloribus quibusdam harum itaque a velit iure, aperiam eum sapiente tempora maxime aliquid saepe deleniti et eius doloremque eligendi!",
            link: "some-link",
          },
        ],
        imageURL: "/images/supporting1.png",
      },
    ],
  },
];
export default function SupportingMessage1(props: {
  showReturn?: React.Dispatch<React.SetStateAction<string>>;
  layout?: string;
  grid?: number;
  type?: string;
  subSectionId?: number;
  sectionId: number;
  productId?: number;
  noKeyMessage?: boolean;
  // returnLink: string;
}) {
  //@ts-ignore
  let { tab, subtab, cardId, key } = useParams();
  if (props.showReturn) props.showReturn("/" + tab + "/" + subtab);
  // console.log("Tab : " + tab + " , Subtab : " + subtab + " , Key : " + key);

  const context = React.useContext(AppContext);
  const [fetchedData, setFetchedData] = React.useState<any>([]);
  const [template, setTemplate] = React.useState<number>(1);

  // let section: {
  // 	id: number;
  // 	name: string;
  // 	subSection: { id: number; name: string; sectionId: number }[];
  // }[];
  // section = context.subSectionMapping.find(
  // 	(section) => section.id === props.sectionId
  // );
  // const [section, setSection] = React.useState("");
  const [subsectionId, setSubsectionId] = React.useState(0);

  const [refArray, setRefArray] = React.useState<
    {
      id: number;
      referenceHeader: string;
      referenceLink: string;
    }[]
  >([]);
  async function fetchData(keyMessageId: number) {
    const d: any = await ServiceHelper.getSupportingMessage(keyMessageId);

    d[0].supportingKeyMessage.map((messages: any) => {
      if (messages.placeholderId === 2 || messages.placeholderId === 3)
        setTemplate(3);
    });
    temp = 1;
    const tempArray: {
      id: number;
      referenceHeader: string;
      referenceLink: string;
    }[] = [];
    d[0].supportingKeyMessage.map((card: any) => {
      card.references.map((ref: any) => {
        if (!tempArray.find((r) => r.referenceHeader === ref.referenceHeader)) {
          tempArray.push(ref);
        }
      });
    });
    setRefArray(tempArray);
    // console.log(d);
    setFetchedData(d);
  }
  let temp = 1;
  React.useEffect(() => {
    setFetchedData([]);
    fetchData(key);
  }, [key]);
  // React.useEffect(() => {
  //   console.log("in SM1 useeffect", context.searchLink);
  //   if (window.location.toString().includes(context.searchLink)) {
  //     context.setShowSearch(true);
  //   } else {
  //     context.setShowSearch(false);
  //     context.setNewSearchWord("");
  //     context.setNewSearchLink("&  &");
  //   }
  // });
  React.useEffect(() => {
    console.log("Subsection Name", subtab);
    const sec = context.subSectionMapping.find((section) =>
      section.subSection.find(
        (subSection) =>
          subSection.name.toLowerCase().split(" ").join("_") ===
          subtab.toLowerCase()
      )
    );
    console.log("Subsection Name 1", sec);
    setSubsectionId(
      sec?.subSection.find((subSection) => {
        return (
          subSection.name.toLowerCase().split(" ").join("_") ===
          subtab.toLowerCase()
        );
      })?.id || 0
    );
  }, []);

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalHeader, setModalHeader] = React.useState("Modal Header");
  const [modalText, setModalText] = React.useState(<></>);

  const showModal = () => {
    setModalHeader("Add New Tile");
    setModalText(
      <FormField4
        // subSectionId={props.subSectionId}
        handleSave={handleSave}
        closeModal={handleCancel}
        viewType="supporting"
        keyMessageId={key}
        cardId={cardId}
        sectionId={props.sectionId}
        subSectionId={subsectionId}
      />
    );
    setVisible(true);
  };

  const showModalEdit = (id: number) => {
    const editData = fetchedData[0].supportingKeyMessage.find(
      (message: any) => message.id === id
    );
    setModalHeader("Edit Tile");
    setModalText(
      <FormField4
        // subSectionId={props.subSectionId}
        handleEdit={handleEdit}
        closeModal={handleCancel}
        viewType="supporting"
        editData={editData}
        messageId={id}
        keyMessageId={key}
        cardId={cardId}
        sectionId={props.sectionId}
        subSectionId={subsectionId || 0}
      />
    );
    setVisible(true);
  };
  const handleOk = () => {
    setModalText(<p>Saving</p>);
    setConfirmLoading(true);
    setTimeout(() => {
      setVisible(false);
      setConfirmLoading(false);
    }, 1500);
  };
  const handleCancel = () => {
    setModalText(<></>);
    setVisible(false);
  };
  async function handleSave(value: any) {
    // console.log("form values ", value);
    value.cardbodyComponentId = key;
    const updatable = "updatable";
    message.loading({ content: "Saving changes...", updatable });
    const data: any = await ServiceHelper.addSupportingMessage(
      value,
      key,
      context.productId
    );
    if (data) message.success({ content: "Saved successfully", updatable });
    else message.error({ content: "Save failed", key });
    if (data) {
      data[0].supportingKeyMessage.map((messages: any) => {
        if (messages.placeholderId === 2 || messages.placeholderId === 3)
          setTemplate(3);
      });
      setFetchedData(data);
      const tempArray: {
        id: number;
        referenceHeader: string;
        referenceLink: string;
      }[] = [];
      data[0].supportingKeyMessage.map((card: any) => {
        card.references.map((ref: any) => {
          if (
            !tempArray.find((r) => r.referenceHeader === ref.referenceHeader)
          ) {
            tempArray.push(ref);
          }
        });
      });
      setRefArray(tempArray);
    }
  }
  async function handleDelete(id: number) {
    const updatable = "updatable";
    message.loading({ content: "Saving changes...", updatable });
    const data: any = await ServiceHelper.deleteSupportingMessage(
      id,
      key,
      context.productId
    );
    if (data) message.success({ content: "Saved successfully", updatable });
    else message.error({ content: "Save failed", key });
    if (data) {
      data[0].supportingKeyMessage.map((messages: any) => {
        if (messages.placeholderId === 2 || messages.placeholderId === 3)
          setTemplate(3);
      });
      setFetchedData(data);
      const tempArray: {
        id: number;
        referenceHeader: string;
        referenceLink: string;
      }[] = [];
      data[0].supportingKeyMessage.map((card: any) => {
        card.references.map((ref: any) => {
          if (
            !tempArray.find((r) => r.referenceHeader === ref.referenceHeader)
          ) {
            tempArray.push(ref);
          }
        });
      });
      setRefArray(tempArray);
    }
  }
  async function handleEdit(value: any, messageId: number) {
    console.log("Val ------ ", value);
    const updatable = "updatable";
    message.loading({ content: "Saving changes...", updatable });
    value.cardbodyComponentId = key;
    const data: any = await ServiceHelper.editSupportingMessage(
      value,
      messageId,
      key,
      context.productId
    );
    if (data) message.success({ content: "Saved successfully", updatable });
    else message.error({ content: "Save failed", key });
    if (data) {
      data[0].supportingKeyMessage.map((messages: any) => {
        if (messages.placeholderId === 2 || messages.placeholderId === 3)
          setTemplate(3);
      });
      setFetchedData(data);
      const tempArray: {
        id: number;
        referenceHeader: string;
        referenceLink: string;
      }[] = [];
      data[0].supportingKeyMessage.map((card: any) => {
        card.references.map((ref: any) => {
          if (
            !tempArray.find((r) => r.referenceHeader === ref.referenceHeader)
          ) {
            tempArray.push(ref);
          }
        });
      });
      setRefArray(tempArray);
    }
  }
  async function handleTableEdit(
    value: {
      name: string;
      id?: number;
      columns: {
        id?: number;
        title: string;
        accessor: string;
        tableId?: number;
        order: number;
      }[];
      tableData: {
        key: number | string;
        rowData: {
          [id: string]: string | number;
        }[];
      }[];
    },
    tableId: number,
    supportingMessageId: number
  ) {
    const updatable = "updatable";
    message.loading({ content: "Saving changes...", updatable });
    const data = await ServiceHelper.editTable(
      value,
      supportingMessageId,
      tableId,
      key,
      context.productId
    );
    if (data) message.success({ content: "Saved successfully", updatable });
    else message.error({ content: "Save failed", updatable });
    if (data) {
      setFetchedData(data);
    }
  }
  async function handleChartEdit(
    value: {
      name: string;
      // id?: number;
      chartHeader: {
        headerName: string;
        orderBy: number;
      }[];
      chartData: {
        key: number;
        chartData: number[];
      }[];
      chartSeries: {
        name: string;
        rowNumber: number;
        color: string;
      }[];
    },
    supportingChartId: number,
    supportingMessageId: number
  ) {
    const updatable = "updatable";
    message.loading({ content: "Saving changes...", updatable });
    const data = await ServiceHelper.editChart(
      value,
      supportingChartId,
      supportingMessageId,
      key,
      context.productId
    );

    if (data) message.success({ content: "Saved successfully", key });
    else message.error({ content: "Save failed", key });
    if (data) {
      setFetchedData(data);
    }
  }
  async function handleSaveOrder(items: any) {
    items.map((item: any, index: number) => {
      item.order = index + 1;
    });
    const obj = { supportingMessageOrder: items };
    const keys = "updatable";
    message.loading({ content: "Saving changes...", keys });
    const d: any = await ServiceHelper.editOrder(obj, context.productId, key);
    if (d) message.success({ content: "Saved successfully", keys });
    else message.error({ content: "Save failed", keys });
    if (d) {
      d[0].supportingKeyMessage.map((messages: any) => {
        if (messages.placeholderId === 2 || messages.placeholderId === 3)
          setTemplate(3);
      });
      temp = 1;
      const tempArray: {
        id: number;
        referenceHeader: string;
        referenceLink: string;
      }[] = [];
      d[0].supportingKeyMessage.map((card: any) => {
        card.references.map((ref: any) => {
          if (
            !tempArray.find((r) => r.referenceHeader === ref.referenceHeader)
          ) {
            tempArray.push(ref);
          }
        });
      });
      setRefArray(tempArray);
      // console.log(d);
      setFetchedData(d);
    }
    console.log("Saving Order ", items);
  }
  const override = css`
    display: block;
    margin-left: ${props.layout === "ObjectionHandler" ? "25vw" : "43vw"};
    margin-top: 15vw;
    border-color: red;
  `;

  return (
    <>
      {fetchedData && fetchedData.length > 0 ? (
        <>
          <Modal
            destroyOnClose={true}
            title={modalHeader}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            // cancelButtonProps={{ style: { display: "none" } }}
            footer={null}
            width={800}
          >
            <p>{modalText}</p>
          </Modal>
          {!props.noKeyMessage &&
            fetchedData &&
            fetchedData.length > 0 &&
            template === 1 && (
              <SupportingMessageTemplate1
                data={fetchedData[0]}
                ref_start={temp}
                refArray={refArray}
                showModal={showModal}
                showModalEdit={showModalEdit}
                switchView={setTemplate}
                handleDelete={handleDelete}
                handleSaveOrder={handleSaveOrder}
              />
            )}
          {!props.noKeyMessage &&
            fetchedData &&
            fetchedData.length > 0 &&
            template === 2 && (
              <SupportingMessageTemplate2
                data={fetchedData[0]}
                ref_start={temp}
                refArray={refArray}
                handleDelete={handleDelete}
                showModal={showModal}
                showModalEdit={showModalEdit}
                switchView={setTemplate}
              />
            )}
          {fetchedData &&
            fetchedData.length > 0 &&
            (template === 3 || props.noKeyMessage) && (
              <SupportingMessageTemplate3
                noKeyMessage={props.noKeyMessage}
                layout={props.layout}
                ref_start={temp}
                refArray={refArray}
                data={fetchedData[0]}
                handleDelete={handleDelete}
                showModal={showModal}
                showModalEdit={showModalEdit}
                switchView={setTemplate}
                editTable={handleTableEdit}
                editChart={handleChartEdit}
                handleSaveOrder={handleSaveOrder}
              />
            )}{" "}
        </>
      ) : (
        <>
          <BeatLoader
            css={override}
            color={"#27A6A4"}
            loading={true}
            size={30}
          />
        </>
      )}
    </>
  );
}
