import { Body, BodyContent, LayoutContainer } from "./style";
import "./index.css";
import SideBar from "./components/sideBar";
import TopHeader from "./components/topHeader";
// import MiddleHeader from "./components/middleHeader";
import { Route, Switch } from "react-router-dom";
import Landing from "../pages/Landing";
import axios from "axios";
import React, { useEffect, useState } from "react";
// import AppContext from "../store";
import { TabNames } from "../routes";
import { Routes } from "../routes";
import Background from "../pages/Background";
import ExecutiveSummary from "../pages/ExecutiveSummary";
import LandingMain from "../../Landing";

/**
 * Renders the Main Layout of the SLR App and displays different screens based on the Routes
 *
 * @returns Main Layout of the SLR App along with different routes
 */

export const request = axios.create({
  // baseURL: "https://localhost:5001/api/Slr",
  baseURL: "https://heorgvdslr.api.zsservices.com/api/Slr",
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "https://heorgvdslr.zsservices.com",
  },
});
export default function SLR_Layout() {
  //define request headers
  request.interceptors.request.use((config) => reqConfig(config));
  request.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  );
  const reqConfig = (config: any) => {
    config.headers["userName"] = localStorage.getItem("userName");
    config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
    return config;
  };
  const responseHandler = (response: any) => {
    return response;
  };
  const errorHandler = (error: any) => {
    if (error.response.status === 401)
      window.location.href = process.env.REACT_APP_REDIRECT_URL || "";
    return Promise.reject(error);
  };

  const routes = Routes();

  const tabNames = TabNames();

  const [selectedBackground, setSelectedBackground] = useState(
    window.sessionStorage.getItem("selectedBackground")
  );

  useEffect(() => {
    console.log("calling SLR_Layout");
    if (
      window.sessionStorage.getItem("selectedBackground") === null ||
      window.sessionStorage.getItem("selectedBackground") === undefined
    ) {
      window.sessionStorage.setItem("selectedBackground", "ClinicalSLR");
      window.sessionStorage.setItem("selectedBackgroundName", "Clinical SLR");
    }
    setSelectedBackground("Clinical SLR");
  }, []);

  return (
    <Switch>
      {console.log("API Key :", process.env.REACT_APP_API_KEY)}
      <Route exact path="/SLR">
        <Landing />
      </Route>
      <Route exact path="/SLR/overview/:productName">
        <LayoutContainer>
          <div className="backgroundImage" style={{ width: "100%" }}>
            <Background
              setSelectedBackground={setSelectedBackground}
              tabNames={tabNames}
            />
          </div>
        </LayoutContainer>
      </Route>
      <Route exact path="/SLR/ExecutiveSummary">
        <LayoutContainer>
          <SideBar viewSelected={selectedBackground} tabNames={tabNames} />
          <div style={{ marginLeft: "5rem", width: "100%", height: "100%" }}>
            <div style={{ height: "7%" }}>
              <TopHeader isLogo={false} />
            </div>
            <ExecutiveSummary />
          </div>
        </LayoutContainer>
      </Route>
      <Route path="/SLR/:tab">
        <LayoutContainer>
          {console.log("Layout Rendered", routes)}
          <Body>
            <div style={{ marginLeft: "5rem", height: "7%" }}>
              <TopHeader isLogo={false} />
            </div>
            <SideBar viewSelected={selectedBackground} tabNames={tabNames} />
            {/* <MiddleHeader tabNames={tabNames} /> */}
            <BodyContent>
              <Switch>
                {routes.map((route, index) => (
                  <Route exact key={index} {...route} />
                ))}
              </Switch>
            </BodyContent>
          </Body>
        </LayoutContainer>
      </Route>
    </Switch>
  );
}
