import React from "react";
import Tile from "../../../libraries/tile";
// import AppContext from "../../store/index";
import ContactCard from "./Component/ContactCard";
import PicTile from "./Component/PicTile";
import { AboutUsContainer, CardContainer, CardSlot } from "./style";

/**
 * Renders the About Us Page.
 *
 * @returns About Us Page of the App
 */
export default function AboutUs() {
  // const context = React.useContext(AppContext);

  // React.useEffect(() => {
  // 	context.setView(11);
  // });
  const data1 = {
    header: "Introduction to digital systematic literature review (dSLR)",
    id: 0,
    filePath: "",
    text: [
      {
        id: 0,
        text: "Systematic Literature Reviews (SLRs) are at the top of evidence pyramid and have the highest quality of evidence. SLRs helps gather evidence that fits pre-specified eligibility criteria to answer a specific research question. They aim to minimize bias by using explicit, systematic methods documented in advance with a protocol.",
        bullets: [],
        link: "some-link",
      },
      {
        id: 1,
        text: "The dSLR tool has been developed to deliver information in a modular format, divided into organized and clear sections, which makes it seamless to navigate through dense data and enables real-time insights through interactive dashboards to view components of SLR. These dashboards enable to view geographical distribution of evidence through Evidence Atlas tab and a searchable dashboard to view study/patient characteristics of included studies.",
        bullets: [],
        link: "some-link",
      },
    ],
    link: "",
  };
  const data2 = {
    header: "Disclaimers",
    id: 0,
    filePath: "",
    text: [
      {
        id: 0,
        text: "The dSLR is an internal resource including company-confidential content and should not be directly shared with external parties, payers, providers, or vendors. It is intended to be used as a basic reference for building multidisciplinary resources.",
        bullets: [],
        link: "some-link",
      },
    ],
    link: "",
  };
  const data3 = {
    header: "How to use",
    id: 0,
    filePath: "",
    text: [
      {
        id: 0,
        text: "Consistent with a traditional SLRs, the dSLR contributes to evidence-based practice and identifies, evaluates, and synthesizes research results to create a summary of currently available evidence. This helps to reduce substantial amounts of information into comprehensible units and to aggregate information for efficient decision-making. Specifically customized to optimize usability, information in the dSLR is concisely presented to provide a comprehensive, high-quality synthesis of published evidence, and is a fundamental component of regulatory submissions for many HTA (Health Technology Assessment) agencies ",
        bullets: [],
        link: "some-link",
      },
      {
        id: 1,
        text: "In the dSLR, a full spectrum of information from high-level value messages to granular supporting details associated with the following topics can be found. The content is fully referenced and is reported in accordance with PRISMA guidelines:",
        bullets: [
          {
            title: "Introduction",
            text: "Includes a brief introduction to the study",
          },
          {
            title: "Methodology",
            text: "Includes approach, eligibility criteria, database search, data collection & extraction process",
          },
          {
            title: "Feasibility Assessment",
            text: "This feature is still in development phase",
          },
          {
            title: "Results",
            text: "Includes PRISMA, quality assessment results, study/patient characteristics, outcomes ",
          },
          {
            title: "Conclusion",
            text: "Includes a brief conclusion of the results of SLR",
          },
        ],
        link: "some-link",
      },
    ],
    link: "",
  };

  return (
    <>
      <AboutUsContainer>
        <CardContainer grid={1}>
          <CardSlot>
            <Tile
              data={data1}
              hideHeaderIcon
              hideActions={true}
              edit={false}
              ref_start={0}
              refArray={[]}
            />
          </CardSlot>
          <CardSlot>
            <Tile
              data={data2}
              hideHeaderIcon
              hideActions={true}
              edit={false}
              ref_start={0}
              refArray={[]}
            />
          </CardSlot>
          <CardSlot>
            <Tile
              data={data3}
              hideHeaderIcon
              hideActions={true}
              edit={false}
              ref_start={0}
              refArray={[]}
            />
          </CardSlot>
          <CardSlot>
            <PicTile />
          </CardSlot>
          <CardSlot>
            <ContactCard />
          </CardSlot>
        </CardContainer>
      </AboutUsContainer>
    </>
  );
}
