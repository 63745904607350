import React from "react";
import { NavLink } from "react-router-dom";
import { ChevronLeft } from "@styled-icons/boxicons-regular/ChevronLeft";
import { ChevronRight } from "@styled-icons/boxicons-regular/ChevronRight";
import AppContext from "../../store";
import {
	Logo,
	SideBarItem,
	SideBarItems,
	SideBarText,
	StyledSideBar,
	ToggleIcon,
	ZSLogo,
	SideBarBottom,
	SideBarTop,
} from "./style";
import { headerIcons } from "../../globalTheme";
import { Button, Tooltip } from "antd";
/**
 * Renders the Collapsible SideBar of the Layout.
 *
 * @param tabNames - Array Containing Names of the Menus and their Icons
 *
 * @returns SideBar of the Layout
 */
export default function SideBar(props: {
	tabNames: {
		name: string;
		icon: JSX.Element;
	}[];
}) {
	const [sideBarStatus, setSideBarStatus] = React.useState(true);
	const context = React.useContext(AppContext);
	console.log("Sidebar Elements : ", context.subSectionMapping);
	return (
		<>
			<StyledSideBar
				collapsed={sideBarStatus}
				// onMouseLeave={() => setSideBarStatus(true)}
				// onMouseEnter={() => setSideBarStatus(false)}
			>
				<Logo>
					<ZSLogo>
						{sideBarStatus && (
							<img
								className="zsLogo"
								src="/logo/ZSLogoSmall.png"
								alt="ZS Logo"
								style={{
									marginLeft: "1.2rem",
									height: "3.5rem",
									objectFit: "cover",
								}}
							/>
						)}

						{!sideBarStatus && (
							<img
								className="zsLogo"
								src="/logo/ZSLogoFull.png"
								alt="ZS Logo"
								style={{
									marginLeft: "1.2rem",
									height: "3.5rem",
									objectFit: "cover",
								}}
							/>
						)}
					</ZSLogo>
					<ToggleIcon>
						{!sideBarStatus && (
							<ChevronLeft
								size={24}
								onClick={() => setSideBarStatus(true)}
							/>
						)}
						{sideBarStatus && (
							<ChevronRight
								size={24}
								onClick={() => setSideBarStatus(false)}
							/>
						)}
					</ToggleIcon>
				</Logo>
				{sideBarStatus && (
					<>
						<SideBarTop>
							<SideBarItems>
								{props.tabNames.map((tab, index) => {
									if (index === 0) return <></>;
									if (
										tab.name ===
										"Introduction to digital global value dossiers"
									)
										return <></>;
									if (tab.name === "Abbreviations")
										return <></>;
									if (tab.name === "References") return <></>;
									if (tab.name === "Settings") return <></>;
									return (
										<SideBarItem>
											<NavLink
												to={
													tab.name ===
													"Executive Summary"
														? "/summary/" +
														  context.productId +
														  "/" +
														  context.productName
																.split(" ")
																.join("_")
														: "/" +
														  tab.name.split(" ")[0]
												}
												activeClassName="active"
											>
												<Tooltip
													placement="right"
													title={tab.name}
												>
													{tab.icon}
												</Tooltip>
											</NavLink>
										</SideBarItem>
									);
								})}
							</SideBarItems>
						</SideBarTop>
						<SideBarBottom>
							<SideBarItems>
								<SideBarItem>
									<NavLink
										to={"/abbreviations"}
										activeClassName="active"
									>
										<Tooltip
											placement="right"
											title={"Abbreviations"}
										>
											{
												headerIcons.find(
													(header) =>
														header.id ===
														"Abbreviations"
												)?.icon
											}
										</Tooltip>
									</NavLink>
								</SideBarItem>
								<SideBarItem>
									<NavLink
										to={"/references"}
										activeClassName="active"
									>
										<Tooltip
											placement="right"
											title={"References"}
										>
											{
												headerIcons.find(
													(header) =>
														header.id ===
														"References"
												)?.icon
											}
										</Tooltip>
									</NavLink>
								</SideBarItem>
								{context.isAdmin && (
									<SideBarItem>
										<NavLink
											to={"/settings"}
											activeClassName="active"
										>
											<Tooltip
												placement="right"
												title={"Settings"}
											>
												{
													headerIcons.find(
														(header) =>
															header.id ===
															"Settings"
													)?.icon
												}
											</Tooltip>
										</NavLink>
									</SideBarItem>
								)}
							</SideBarItems>
						</SideBarBottom>
					</>
				)}
				{!sideBarStatus && (
					<>
						<SideBarTop>
							<SideBarItems>
								{props.tabNames.map((tab, index) => {
									if (index === 0) return <></>;
									if (
										tab.name ===
										"Introduction to digital global value dossiers"
									)
										return <></>;
									if (tab.name === "Abbreviations")
										return <></>;
									if (tab.name === "References") return <></>;
									if (tab.name === "Settings") return <></>;
									return (
										<SideBarItem>
											<NavLink
												to={
													tab.name ===
													"Executive Summary"
														? "/summary/" +
														  context.productId +
														  "/" +
														  context.productName
																.split(" ")
																.join("_")
														: "/" +
														  tab.name.split(" ")[0]
												}
												activeClassName="active"
											>
												{tab.icon}
												<SideBarText>
													{tab.name}
												</SideBarText>
											</NavLink>
										</SideBarItem>
									);
								})}
							</SideBarItems>
						</SideBarTop>
						<SideBarBottom>
							<SideBarItems>
								<SideBarItem>
									<NavLink
										to={"/abbreviations"}
										activeClassName="active"
									>
										{
											headerIcons.find(
												(header) =>
													header.id ===
													"Abbreviations"
											)?.icon
										}
										<SideBarText>Abbreviations</SideBarText>
									</NavLink>
								</SideBarItem>

								<SideBarItem>
									<NavLink
										to={"/references"}
										activeClassName="active"
									>
										{
											headerIcons.find(
												(header) =>
													header.id === "References"
											)?.icon
										}
										<SideBarText>References</SideBarText>
									</NavLink>
								</SideBarItem>

								{context.isAdmin && (
									<SideBarItem>
										<NavLink
											to={"/settings"}
											activeClassName="active"
										>
											{
												headerIcons.find(
													(header) =>
														header.id === "Settings"
												)?.icon
											}
											<SideBarText>Settings</SideBarText>
										</NavLink>
									</SideBarItem>
								)}
							</SideBarItems>
						</SideBarBottom>
					</>
				)}
			</StyledSideBar>
		</>
	);
}
