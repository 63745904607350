import styled from "styled-components";
import { colors } from "./../../globalTheme";

export const TileContainer = styled.div`
	border-radius: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	box-sizing: border-box;
	background-color: ${colors.zsWhite};
	padding: 1em 1.2em;
	/* box-shadow: 2px 2px #bebebe; */

	border-radius: 10px;
`;

export const TileHeader = styled.div`
	width: 80%;
	align-items: flex-start;
	line-height: 1rem;
	font-size: 1rem;
	font-weight: 600;
	overflow-wrap: break-word;
`;
export const TileIcon = styled.div`
	width: 10%;
	padding: 1em 1em;
`;
export const TileLink = styled.div`
	width: 10%;
	padding: 1em 1.2em;

	a {
		color: ${colors.zsTeal};
	}
`;
