import {
  SupportingMessageContainer,
  StyledMessageContainer,
  StyledSupportingMessage,
  SupportingMessageImagePlaceholder,
  SupportingMessageTextPlaceholder,
  SupportingMessageHeaderText,
  SupportingMessageBodyTexts,
  SupportingMessageBodyText,
  Actions,
  StyledTooltip,
  SupportingMessageIconPlaceholder,
  StyledSupportingMessageEdit,
  SupportingMessageFlexContainer,
  StyledTableChartPlaceholder,
} from "./style";
// import { Table } from "antd";
import { Row, Col, Popconfirm, Modal } from "antd";
import "../SupportingMessages2/index.css";
import Table from "./Component/Table";

import {
  transformColumn,
  transformTableData,
  reverseTransformTableData,
  reverseTransformColumn,
  transformLabels,
  transformSeries,
  transformChartData,
  reverseTransformCharteData,
  reverseTransformLabels,
  reverseTransformSeries,
} from "./processing";

import { AddCircleOutline } from "@styled-icons/material";
import React, { useState } from "react";
import AppContext from "../../../../store";
import { Edit2Outline } from "@styled-icons/evaicons-outline";
import { Delete } from "@styled-icons/fluentui-system-regular";
import { SwitchHorizontal } from "@styled-icons/heroicons-outline/SwitchHorizontal";
import { ChangeCircle } from "@styled-icons/material-outlined/ChangeCircle";
import { ThreeDots } from "@styled-icons/bootstrap/ThreeDots";
import { Save } from "@styled-icons/boxicons-regular/Save";
import { Cancel } from "@styled-icons/material-outlined/Cancel";
import Chart from "./Component/Chart";
import { colors, headerIcons } from "../../../../globalTheme";
import ReactMarkdown from "react-markdown";
import { request } from "../../..";
import Star from "../SupportingMessages1/star";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import Highlight from "react-highlighter";
export enum containerType {
  image = "image",
  table = "table",
  chart = "chart",
}
/**
 * Renders Supporting Message View (Template 3) of the App.
 *
 * @param layout - Specifies whether the Layout is type Objection Handler
 * @param noKeyMessage - Specifies whether to Display the Key Message to which the Supporting Message are related
 * @param data - Data for the Supporting Messages
 * @param handleDelete - Callback function for deleting a Supporting Message
 * @param showModal - Callback function for opening the Model Form for Adding a New Supporting Message
 * @param switchView - Callback function for Switching between Templates for the Supporting Message
 * @param editTable - Callback function editing Table Setting by calling the Api
 * @param editChart - Callback function editing Chart Setting by calling the Api
 *
 * @returns Body Layput of the App
 */
const SupportingMessage3 = (props: {
  layout?: string;
  noKeyMessage?: boolean;
  data: {
    id: number;
    keyMessage: string;
    supportingKeyMessage: {
      id: number;
      supportingMessageHeader: string;
      componentHeader?: string;
      chartType?: string;
      placeholderId: number;
      supportingMessages: {
        id: number;
        bulletPoint: string;
        link?: string;
      }[];
      references: {
        id?: number;
        referenceHeader?: string;
        referenceLink?: string;
      }[];
      imageURL: string;
      tableId: null | number;
      tableComponent: {
        id: number;
        name: string;
        columns: {
          id: number;
          title: string;
          accessor: string;
          tableId: number;
        }[];
        tableData: { key: number; rowData: string[] }[];
      }[];
      chartComponent: {
        id: number;
        name: string;
        chartHeader: {
          id: number;
          headerName: string;
          chartComponentId: number;
        }[];
        chartData: { key: number; chartData: string[] }[];
        chartSeries: {
          id: number;
          name: string;
          rowNumber: number;
          color: string;
          chartComponentId: number;
        }[];
        chartType: {
          id: number;
        };
        xAxisLabel: string;
        yAxisLabel: string;
      }[];
    }[];
  };
  switchView?: React.Dispatch<React.SetStateAction<number>>;
  handleDelete?: (id: number) => void;
  showModal?: () => void;
  showModalEdit?: (id: number) => void;
  ref_start: number;
  refArray: any[];
  handleSaveOrder(items: any): Promise<void>;
  editTable?: (
    value: {
      name: string;
      id?: number;
      columns: {
        id?: number;
        title: string;
        accessor: string;
        tableId?: number;
        order: number;
      }[];
      tableData: {
        key: number | string;
        rowData: {
          [id: string]: string | number;
        }[];
      }[];
    },
    tableId: number,
    supportingMessageeId: number
  ) => Promise<void>;
  editChart?: (
    value: {
      name: string;

      chartHeader: {
        headerName: string;
        orderBy: number;
      }[];
      chartData: {
        key: number;
        chartData: number[];
      }[];
      chartSeries: {
        name: string;
        rowNumber: number;
        color: string;
      }[];
    },
    supportingChartId: number,
    supportingMessageId: number
  ) => Promise<void>;
}) => {
  const context = React.useContext(AppContext);
  const [items, setItems] = React.useState<
    {
      id: number;
      supportingMessageHeader: string;
      componentHeader?: string;
      chartType?: string;
      placeholderId: number;
      supportingMessages: {
        id: number;
        bulletPoint: string;
        link?: string;
      }[];
      references: {
        id?: number;
        referenceHeader?: string;
        referenceLink?: string;
      }[];
      imageURL: string;
      tableId: null | number;
      tableComponent: {
        id: number;
        name: string;
        columns: {
          id: number;
          title: string;
          accessor: string;
          tableId: number;
        }[];
        tableData: { key: number; rowData: string[] }[];
      }[];
      chartComponent: {
        id: number;
        name: string;
        chartHeader: {
          id: number;
          headerName: string;
          chartComponentId: number;
        }[];
        chartData: { key: number; chartData: string[] }[];
        chartSeries: {
          id: number;
          name: string;
          rowNumber: number;
          color: string;
          chartComponentId: number;
        }[];
        chartType: {
          id: number;
        };
        xAxisLabel: string;
        yAxisLabel: string;
      }[];
    }[]
  >(props.data.supportingKeyMessage);
  const [view, setView] = React.useState(false);
  const handleSaveTable = (
    id: number,
    columns: any,
    data: any,
    name: string,
    supportingMessageId: number
  ) => {
    const obj = {
      name: name,
      id: id || 0,
      columns: reverseTransformColumn(id, columns),
      tableData: reverseTransformTableData(data),
    };
    props.editTable && props.editTable(obj, id || 0, supportingMessageId);
  };
  const handleSaveChart = (
    chartId: number,
    series: any,
    data: any,
    name: string,
    chartType: number,
    xAxisLabel: string,
    yAxisLabel: string,
    supportingMessageId: number
  ) => {
    const obj = {
      name: name,

      chartSeries: reverseTransformSeries(series),
      chartData: reverseTransformCharteData(data),
      chartHeader: reverseTransformLabels(data),
      chartType: { id: chartType },
      xAxisLabel: xAxisLabel,
      yAxisLabel: yAxisLabel,
    };
    props.editChart && props.editChart(obj, chartId || 0, supportingMessageId);
  };
  function confirm(e: any) {
    //@ts-ignore
    props.handleDelete(message.id);
  }

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bodyText, setBodyText] = useState(<> </>);

  const showModal = (url: string) => {
    setBodyText(<img src={url} alt="" style={{ width: "100%" }}></img>);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  function onChange(
    sourceId: any,
    sourceIndex: any,
    targetIndex: any,
    targetId: any
  ) {
    const nextState = swap(items, sourceIndex, targetIndex);

    setItems(nextState);
  }
  let n = props.ref_start;

  function ShowImg(props: { src: any; header: string; edit: boolean }) {
    const [image, setImage] = React.useState<any>(null);
    const [loading, setLoading] = React.useState(true);
    async function fetchImg(src: string) {
      const data: string | unknown = await request
        .get("brand/GetImage?imagePath=" + src, {
          withCredentials: true,
          responseType: "arraybuffer",
        })
        .then((response: any) => {
          // console.log("RESP", response);
          setImage(Buffer.from(response.data, "binary").toString("base64"));
          setLoading(false);
          return Buffer.from(response.data, "binary").toString("base64");
        })
        .catch((err: any) => {
          console.log(err.message, "error here");
          return null;
        });
    }
    fetchImg(props.src);
    return (
      <>
        {!loading ? (
          <>
            {image ? (
              <img
                onClick={() => {
                  if (!props.edit)
                    showModal(
                      image
                        ? "data:image/jpeg;base64," + image
                        : "/images/defaultsupport.png"
                    );
                }}
                src={
                  image
                    ? "data:image/jpeg;base64," + image
                    : "/images/defaultsupport.png"
                }
                alt={props.header}
                style={{
                  maxHeight: "30vh",
                  padding: "1em",
                  margin: "0 auto",
                  objectFit: "cover", // border: "2px solid red",
                }}
              />
            ) : (
              <div
                style={{
                  height: "90px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "90px",
                    color: colors.zsGray30,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {headerIcons.find((l) => l.id === "No Image")?.Icon}
                  <div>No image available</div>
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  React.useEffect(() => {
    setItems(props.data.supportingKeyMessage);
  }, [props.data.supportingKeyMessage]);
  console.log("ausdfaisd ugf", view);
  return (
    <SupportingMessageContainer
      padding={
        props.layout === "ObjectionHandler" ? "0 1.5em 0 0.5em" : undefined
      }
      height={
        props.layout === "ObjectionHandler" ? "calc(100vh - 10.5%)" : undefined
      }
      layout={props.layout || ""}
    >
      {!props.noKeyMessage && (
        <StyledMessageContainer>
          {/* <img
            src="/icons/SupportingMessageStar.png"
            alt="image"
            style={{
              position: "absolute",
              top: 0,
              left: 20,
              height: "1.4rem",
              objectFit: "cover",
            }}
          /> */}
          {/* <Star /> */}
          <ReactMarkdown source={props.data.keyMessage} />
        </StyledMessageContainer>
      )}

      {context.isAdmin && (
        <Actions>
          {view && (
            <button
              onClick={() => {
                setView(false);
                setItems(props.data.supportingKeyMessage);
              }}
            >
              <Cancel
                size={22}
                style={{
                  marginRight: "2px",
                }}
              />
              Cancel
            </button>
          )}
          {view && (
            <button
              onClick={() => {
                setView(false);
                props.handleSaveOrder(items);
              }}
            >
              <Save
                size={20}
                style={{
                  marginRight: "2px",
                }}
              />
              Save Order
            </button>
          )}
          {!view && items.length > 0 && (
            <button
              onClick={() => {
                setView(true);
              }}
            >
              <ChangeCircle
                size={20}
                style={{
                  marginRight: "2px",
                }}
              />
              Change Order
            </button>
          )}

          <button onClick={props.showModal}>
            <AddCircleOutline
              title="Add New Message"
              size={20}
              style={{ marginRight: "2px" }}
            />
            Add New
          </button>
        </Actions>
      )}

      <div>
        {view ? (
          <>
            <GridContextProvider onChange={onChange}>
              <GridDropZone
                className="dropZone"
                id="messages"
                boxesPerRow={1}
                rowHeight={160}
                style={{ height: `${160 * items.length}px` }}
              >
                {items.map((message, y) => {
                  return (
                    <GridItem
                      className="grabcard"
                      // style={{ cursor: "grabbing" }}
                      key={message.id}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <>
                        {message.placeholderId !== 1 &&
                          message.placeholderId !== 4 && (
                            <StyledSupportingMessageEdit edit={true}>
                              <div className="content">
                                <SupportingMessageTextPlaceholder>
                                  <SupportingMessageHeaderText>
                                    {message.supportingMessageHeader}
                                    {message.references &&
                                      message.references.length > 0 &&
                                      message.references.map((refs) => {
                                        return (
                                          <StyledTooltip
                                            title={
                                              <>
                                                <p
                                                  style={{
                                                    marginBottom: 0,
                                                  }}
                                                >
                                                  {refs.referenceHeader}
                                                </p>
                                                {refs.referenceLink && (
                                                  <a
                                                    href={refs.referenceLink}
                                                    target="_blank"
                                                  >
                                                    Link
                                                  </a>
                                                )}
                                              </>
                                            }
                                            // title={"Refs"}
                                            // className="reference-tooltip"
                                          >
                                            <span>
                                              [
                                              {props.refArray.findIndex(
                                                (r: any) =>
                                                  r.referenceHeader ===
                                                  refs.referenceHeader
                                              ) + 1}
                                              ]
                                            </span>
                                            {/* <span>[{refs.id}]</span> */}
                                            {/* <span>
																	[{n++}]
																</span> */}
                                          </StyledTooltip>
                                        );
                                      })}
                                  </SupportingMessageHeaderText>
                                  <StyledTableChartPlaceholder>
                                    {message.placeholderId === 3 && (
                                      <Table
                                        id={message.tableId}
                                        name={
                                          message.tableComponent
                                            ? message.tableComponent[0].name
                                            : "Table Name"
                                        }
                                        supportingMessageId={message.id}
                                        column={
                                          message.tableComponent
                                            ? transformColumn(
                                                message.tableComponent[0]
                                                  .columns
                                              )
                                            : []
                                        }
                                        data={
                                          message.tableComponent
                                            ? transformTableData(
                                                message.tableComponent[0]
                                                  .columns,
                                                message.tableComponent[0]
                                                  .tableData
                                              )
                                            : []
                                        }
                                        handleSave={handleSaveTable}
                                      />
                                    )}

                                    {message.placeholderId === 2 && (
                                      <>
                                        {console.log(message.chartComponent)}
                                        <Chart
                                          id={
                                            message.chartComponent
                                              ? message.chartComponent[0].id
                                              : 0
                                          }
                                          supportingMessageId={message.id}
                                          labels={
                                            message.chartComponent
                                              ? transformLabels(
                                                  message.chartComponent[0]
                                                    .chartHeader
                                                )
                                              : []
                                          }
                                          name={
                                            message.chartComponent
                                              ? message.chartComponent[0].name
                                              : "Chart Name"
                                          }
                                          data={
                                            message.chartComponent
                                              ? transformChartData(
                                                  message.chartComponent[0]
                                                    .chartHeader,
                                                  message.chartComponent[0]
                                                    .chartData
                                                )
                                              : []
                                          }
                                          series={
                                            message.chartComponent
                                              ? transformSeries(
                                                  message.chartComponent[0]
                                                    .chartSeries
                                                )
                                              : []
                                          }
                                          chartType={
                                            message.chartComponent
                                              ? message.chartComponent[0]
                                                  .chartType.id
                                              : 1
                                          }
                                          xAxisLabel={
                                            message.chartComponent
                                              ? message.chartComponent[0]
                                                  .xAxisLabel
                                              : "X Axis"
                                          }
                                          yAxisLabel={
                                            message.chartComponent
                                              ? message.chartComponent[0]
                                                  .yAxisLabel
                                              : "Y Axis"
                                          }
                                          handleSave={handleSaveChart}
                                        />
                                      </>
                                    )}
                                  </StyledTableChartPlaceholder>

                                  <SupportingMessageBodyTexts>
                                    <div
                                      style={{
                                        background: "white",
                                        paddingTop: "20px",
                                      }}
                                    >
                                      <Row gutter={[16, 16]}>
                                        {message.supportingMessages.map(
                                          (bullet) => (
                                            <Col span={12}>
                                              <li
                                                style={{
                                                  margin: "0.6em 0",
                                                }}
                                              >
                                                {" "}
                                                {
                                                  <ReactMarkdown
                                                    source={bullet.bulletPoint}
                                                  />
                                                }{" "}
                                              </li>
                                            </Col>
                                          )
                                        )}
                                      </Row>
                                    </div>
                                  </SupportingMessageBodyTexts>
                                </SupportingMessageTextPlaceholder>
                                {context.isAdmin && (
                                  <SupportingMessageIconPlaceholder>
                                    <Popconfirm
                                      title="Are you sure to delete this message?"
                                      onConfirm={() => {
                                        if (props.handleDelete)
                                          props.handleDelete(message.id);
                                      }}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <Delete
                                        className="messageIcons"
                                        title="Delete Message"
                                        size={26}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Popconfirm>

                                    <Edit2Outline
                                      className="messageIcons"
                                      title="Edit Message"
                                      size={22}
                                      style={{
                                        marginTop: "12px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        props.showModalEdit &&
                                        props.showModalEdit(message.id)
                                      }
                                    />
                                  </SupportingMessageIconPlaceholder>
                                )}
                              </div>
                              <div
                                style={{
                                  // background: "red",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {view && (
                                  <>
                                    <ThreeDots
                                      size={24}
                                      // style={{
                                      // 	float: "right",
                                      // 	marginRight:
                                      // 		"20px",
                                      // }}
                                    />
                                  </>
                                )}
                              </div>
                            </StyledSupportingMessageEdit>
                          )}

                        <Modal
                          width={1000}
                          visible={isModalVisible}
                          footer={null}
                          onOk={handleOk}
                          onCancel={handleCancel}
                        >
                          {bodyText}
                        </Modal>
                        {(message.placeholderId === 1 ||
                          message.placeholderId === 4) && (
                          <StyledSupportingMessageEdit edit={true}>
                            <div className="content">
                              <SupportingMessageTextPlaceholder>
                                <SupportingMessageHeaderText>
                                  {message.supportingMessageHeader}
                                  {message.references &&
                                    message.references.length > 0 &&
                                    message.references.map((refs) => {
                                      return (
                                        <StyledTooltip
                                          title={
                                            <>
                                              <p
                                                style={{
                                                  marginBottom: 0,
                                                }}
                                              >
                                                {refs.referenceHeader}
                                              </p>
                                              {refs.referenceLink && (
                                                <a
                                                  href={refs.referenceLink}
                                                  target="_blank"
                                                >
                                                  Link
                                                </a>
                                              )}
                                            </>
                                          }
                                          // title={"Refs"}
                                          // className="reference-tooltip"
                                        >
                                          <span>
                                            [
                                            {props.refArray.findIndex(
                                              (r: any) =>
                                                r.referenceHeader ===
                                                refs.referenceHeader
                                            ) + 1}
                                            ]
                                          </span>
                                        </StyledTooltip>
                                      );
                                    })}
                                </SupportingMessageHeaderText>
                                <SupportingMessageBodyTexts>
                                  {message.supportingMessages.map((bullet) => {
                                    return (
                                      <Row>
                                        <Col span={24}>
                                          <SupportingMessageBodyText>
                                            <ReactMarkdown
                                              source={bullet.bulletPoint}
                                            />

                                            {/* {bullet.bulletPoint} */}
                                          </SupportingMessageBodyText>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </SupportingMessageBodyTexts>
                              </SupportingMessageTextPlaceholder>
                              {message.placeholderId !== 4 && (
                                <SupportingMessageImagePlaceholder
                                  style={{
                                    cursor: "zoom-in",
                                  }}
                                >
                                  <ShowImg
                                    edit={view}
                                    src={message.imageURL}
                                    header={message.supportingMessageHeader}
                                  />
                                  {/* <img
											src={
												message.imageURL
													? "https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
													  message.imageURL
													: "/images/defaultsupport.png"
											}
											onClick={() =>
												showModal(
													message.imageURL
														? "https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
																message.imageURL
														: "/images/defaultsupport.png"
												)
											}
											style={{
												maxHeight: "30vh",
												padding: "1em",
												margin: "0 auto",
												objectFit: "cover",
											}}
										/> */}
                                </SupportingMessageImagePlaceholder>
                              )}
                              {context.isAdmin && (
                                <SupportingMessageIconPlaceholder>
                                  <Popconfirm
                                    title="Are you sure to delete this message?"
                                    onConfirm={() => {
                                      if (props.handleDelete)
                                        props.handleDelete(message.id);
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <Delete
                                      className="messageIcons"
                                      title="Delete Message"
                                      size={26}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Popconfirm>

                                  <Edit2Outline
                                    className="messageIcons"
                                    title="Edit Message"
                                    size={22}
                                    style={{
                                      marginTop: "12px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      props.showModalEdit &&
                                      props.showModalEdit(message.id)
                                    }
                                  />
                                </SupportingMessageIconPlaceholder>
                              )}
                            </div>
                            <div
                              style={{
                                // background: "red",
                                height: "20px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {view && (
                                <>
                                  <ThreeDots
                                    size={24}
                                    // style={{
                                    // 	float: "right",
                                    // 	marginRight:
                                    // 		"20px",
                                    // }}
                                  />
                                </>
                              )}
                            </div>
                          </StyledSupportingMessageEdit>
                        )}
                      </>
                    </GridItem>
                  );
                })}
              </GridDropZone>
            </GridContextProvider>
          </>
        ) : (
          <>
            {items.map((message, y) => {
              return (
                <>
                  {message.placeholderId !== 1 &&
                    message.placeholderId !== 4 && (
                      <StyledSupportingMessage>
                        <SupportingMessageTextPlaceholder>
                          <SupportingMessageHeaderText>
                            <Highlight
                              search={context.searchWord}
                              matchStyle={{
                                color: "red",
                                backgroundColor: "yellow",
                                padding: 0,
                              }}
                            >
                              {message.supportingMessageHeader}
                            </Highlight>
                            {message.references &&
                              message.references.length > 0 &&
                              message.references.map((refs) => {
                                return (
                                  <StyledTooltip
                                    title={
                                      <>
                                        <p
                                          style={{
                                            marginBottom: 0,
                                          }}
                                        >
                                          {refs.referenceHeader}
                                        </p>
                                        {refs.referenceLink && (
                                          <a
                                            href={refs.referenceLink}
                                            target="_blank"
                                          >
                                            Link
                                          </a>
                                        )}
                                      </>
                                    }
                                    // title={"Refs"}
                                    // className="reference-tooltip"
                                  >
                                    <span>
                                      [
                                      {props.refArray.findIndex(
                                        (r: any) =>
                                          r.referenceHeader ===
                                          refs.referenceHeader
                                      ) + 1}
                                      ]
                                    </span>
                                    {/* <span>[{refs.id}]</span> */}
                                    {/* <span>
																		[{n++}]
																	</span> */}
                                  </StyledTooltip>
                                );
                              })}
                          </SupportingMessageHeaderText>
                          <StyledTableChartPlaceholder>
                            {message.placeholderId === 3 && (
                              <Table
                                id={message.tableId}
                                name={
                                  message.tableComponent
                                    ? message.tableComponent[0].name
                                    : "Table Name"
                                }
                                supportingMessageId={message.id}
                                column={
                                  message.tableComponent
                                    ? transformColumn(
                                        message.tableComponent[0].columns
                                      )
                                    : []
                                }
                                data={
                                  message.tableComponent
                                    ? transformTableData(
                                        message.tableComponent[0].columns,
                                        message.tableComponent[0].tableData
                                      )
                                    : []
                                }
                                handleSave={handleSaveTable}
                              />
                            )}

                            {message.placeholderId === 2 && (
                              <>
                                {console.log(message.chartComponent)}
                                <Chart
                                  id={
                                    message.chartComponent
                                      ? message.chartComponent[0].id
                                      : 0
                                  }
                                  supportingMessageId={message.id}
                                  labels={
                                    message.chartComponent
                                      ? transformLabels(
                                          message.chartComponent[0].chartHeader
                                        )
                                      : []
                                  }
                                  name={
                                    message.chartComponent
                                      ? message.chartComponent[0].name
                                      : "Chart Name"
                                  }
                                  data={
                                    message.chartComponent
                                      ? transformChartData(
                                          message.chartComponent[0].chartHeader,
                                          message.chartComponent[0].chartData
                                        )
                                      : []
                                  }
                                  series={
                                    message.chartComponent
                                      ? transformSeries(
                                          message.chartComponent[0].chartSeries
                                        )
                                      : []
                                  }
                                  chartType={
                                    message.chartComponent
                                      ? message.chartComponent[0].chartType.id
                                      : 1
                                  }
                                  xAxisLabel={
                                    message.chartComponent
                                      ? message.chartComponent[0].xAxisLabel
                                      : "X Axis"
                                  }
                                  yAxisLabel={
                                    message.chartComponent
                                      ? message.chartComponent[0].yAxisLabel
                                      : "Y Axis"
                                  }
                                  handleSave={handleSaveChart}
                                />
                              </>
                            )}
                          </StyledTableChartPlaceholder>

                          <SupportingMessageBodyTexts>
                            <div
                              style={{
                                background: "white",
                                paddingTop: "20px",
                              }}
                            >
                              <Row gutter={[16, 16]}>
                                {message.supportingMessages.map((bullet) => {
                                  if (
                                    bullet.bulletPoint === null ||
                                    bullet.bulletPoint === undefined
                                  ) {
                                    return (
                                      <Col span={12}>
                                        <li
                                          style={{
                                            margin: "0.6em 0",
                                          }}
                                        >
                                          <ReactMarkdown
                                            source={bullet.bulletPoint}
                                          />
                                        </li>
                                      </Col>
                                    );
                                  }
                                  return (
                                    <Col span={12}>
                                      <li
                                        style={{
                                          margin: "0.6em 0",
                                        }}
                                      >
                                        {bullet.bulletPoint
                                          .toLowerCase()
                                          .includes(context.searchWord) &&
                                        context.searchWord !== "" ? (
                                          <Highlight
                                            search={context.searchWord}
                                            matchStyle={{
                                              color: "red",
                                              backgroundColor: "yellow",
                                              padding: 0,
                                            }}
                                          >
                                            {bullet.bulletPoint}
                                          </Highlight>
                                        ) : (
                                          <ReactMarkdown
                                            source={bullet.bulletPoint}
                                          />
                                        )}

                                        {/* <ReactMarkdown
                                        source={bullet.bulletPoint}
                                      /> */}
                                      </li>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </div>
                          </SupportingMessageBodyTexts>
                        </SupportingMessageTextPlaceholder>
                        {context.isAdmin && (
                          <SupportingMessageIconPlaceholder>
                            <Popconfirm
                              title="Are you sure to delete this message?"
                              onConfirm={() => {
                                if (props.handleDelete)
                                  props.handleDelete(message.id);
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Delete
                                className="messageIcons"
                                title="Delete Message"
                                size={26}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </Popconfirm>

                            <Edit2Outline
                              className="messageIcons"
                              title="Edit Message"
                              size={22}
                              style={{
                                marginTop: "12px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                props.showModalEdit &&
                                props.showModalEdit(message.id)
                              }
                            />
                          </SupportingMessageIconPlaceholder>
                        )}
                      </StyledSupportingMessage>
                    )}

                  <Modal
                    width={1000}
                    visible={isModalVisible}
                    footer={null}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    {bodyText}
                  </Modal>
                  {(message.placeholderId === 1 ||
                    message.placeholderId === 4) && (
                    <StyledSupportingMessage>
                      <SupportingMessageFlexContainer>
                        <SupportingMessageTextPlaceholder>
                          <SupportingMessageHeaderText>
                            <Highlight
                              search={context.searchWord}
                              matchStyle={{
                                color: "red",
                                backgroundColor: "yellow",
                                padding: 0,
                              }}
                            >
                              {message.supportingMessageHeader}
                            </Highlight>
                            {message.references &&
                              message.references.length > 0 &&
                              message.references.map((refs) => {
                                return (
                                  <StyledTooltip
                                    title={
                                      <>
                                        <p
                                          style={{
                                            marginBottom: 0,
                                          }}
                                        >
                                          {refs.referenceHeader}
                                        </p>
                                        {refs.referenceLink && (
                                          <a
                                            href={refs.referenceLink}
                                            target="_blank"
                                          >
                                            Link
                                          </a>
                                        )}
                                      </>
                                    }
                                    // title={"Refs"}
                                    // className="reference-tooltip"
                                  >
                                    <span>
                                      [
                                      {props.refArray.findIndex(
                                        (r: any) =>
                                          r.referenceHeader ===
                                          refs.referenceHeader
                                      ) + 1}
                                      ]
                                    </span>
                                  </StyledTooltip>
                                );
                              })}
                          </SupportingMessageHeaderText>
                          <SupportingMessageBodyTexts>
                            {message.supportingMessages.map((bullet) => {
                              if (
                                bullet.bulletPoint === null ||
                                bullet.bulletPoint === undefined
                              ) {
                                return (
                                  <Row>
                                    <Col span={24}>
                                      <SupportingMessageBodyText>
                                        <ReactMarkdown
                                          source={bullet.bulletPoint}
                                        />

                                        {/* {bullet.bulletPoint} */}
                                      </SupportingMessageBodyText>
                                    </Col>
                                  </Row>
                                );
                              } else
                                return (
                                  <Row>
                                    <Col span={24}>
                                      <SupportingMessageBodyText>
                                        {bullet.bulletPoint
                                          .toLowerCase()
                                          .includes(context.searchWord) &&
                                        context.searchWord !== "" ? (
                                          <Highlight
                                            search={context.searchWord}
                                            matchStyle={{
                                              color: "red",
                                              backgroundColor: "yellow",
                                              padding: 0,
                                            }}
                                          >
                                            {bullet.bulletPoint}
                                          </Highlight>
                                        ) : (
                                          <ReactMarkdown
                                            source={bullet.bulletPoint}
                                          />
                                        )}

                                        {/* <ReactMarkdown
                                          source={bullet.bulletPoint}
                                        /> */}

                                        {/* {bullet.bulletPoint} */}
                                      </SupportingMessageBodyText>
                                    </Col>
                                  </Row>
                                );
                            })}
                          </SupportingMessageBodyTexts>
                        </SupportingMessageTextPlaceholder>
                        {message.placeholderId !== 4 && (
                          <SupportingMessageImagePlaceholder
                            style={{
                              cursor: "zoom-in",
                            }}
                          >
                            <ShowImg
                              edit={view}
                              src={message.imageURL}
                              header={message.supportingMessageHeader}
                            />
                            {/* <img
												src={
													message.imageURL
														? "https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
														  message.imageURL
														: "/images/defaultsupport.png"
												}
												onClick={() =>
													showModal(
														message.imageURL
															? "https://heorgvdslr.api.zsservices.com/api/brand/GetImage?imagePath=" +
																	message.imageURL
															: "/images/defaultsupport.png"
													)
												}
												style={{
													maxHeight: "30vh",
													padding: "1em",
													margin: "0 auto",
													objectFit: "cover",
												}}
											/> */}
                          </SupportingMessageImagePlaceholder>
                        )}
                      </SupportingMessageFlexContainer>
                      {context.isAdmin && (
                        <SupportingMessageIconPlaceholder>
                          <Popconfirm
                            title="Are you sure to delete this message?"
                            onConfirm={() => {
                              if (props.handleDelete)
                                props.handleDelete(message.id);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Delete
                              className="messageIcons"
                              title="Delete Message"
                              size={26}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </Popconfirm>

                          <Edit2Outline
                            className="messageIcons"
                            title="Edit Message"
                            size={22}
                            style={{
                              marginTop: "12px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              props.showModalEdit &&
                              props.showModalEdit(message.id)
                            }
                          />
                        </SupportingMessageIconPlaceholder>
                      )}
                    </StyledSupportingMessage>
                  )}
                </>
              );
            })}
          </>
        )}
      </div>
    </SupportingMessageContainer>
  );
};

export default SupportingMessage3;
