import styled from "styled-components";
import { colors } from "./../../../../globalTheme";
export const ImageContainer = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: ${(props: { imgWidth: number }) => {
		return props.imgWidth + "% " + (100 - props.imgWidth) + "%" || "100%";
	}};
	border-radius: 0px;

	img {
		width: 100%;
		object-fit: cover;
		object-position: middle;
		max-height: 40vh;
	}
	@media (max-width: 1150px) {
		grid-template-columns: 100%;
	}
`;
export const ImageHeaderContainer = styled.div`
	background-color: ${(props: { headerBg?: string }) =>
		props.headerBg || colors.zsTeal};
	height: 100%;
	/* width: 100%; */
	display: flex;
	align-items: center;
	padding: 1em 2em;
`;
export const ImageHeaderText = styled.h2`
	margin: 0;
	color: ${(props: { textColor?: string }) =>
		props.textColor || colors.zsGray100};
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 120%;
`;
export const ImageOverlayContaner = styled.div`
	position: absolute;
	bottom: 2em;
	background: transparent;
	/* background-color: red; */
	padding: 1em 2em;
`;
export const ImageOverlayHeader = styled.h2`
	margin: 0;
	color: ${(props: { overlayTextColor?: string }) =>
		props.overlayTextColor || colors.zsWhite};
	font-size: 2em;
	font-weight: 600;
	letter-spacing: 2.5px;
	line-height: 3.5rem;
	text-transform: uppercase;
`;
export const ImageOverlaySubHeader = styled.h3`
	margin: 0;
	color: ${(props: { overlayTextColor?: string }) =>
		props.overlayTextColor || colors.zsWhite};
	font-size: 1.4em;
	font-weight: 600;
	letter-spacing: 2.5px;
	text-transform: uppercase;
`;
export const ImageOverlayProductContainer = styled.div`
	position: absolute;
	width: 30%;
	/* top: 40%; */
	bottom: 2em;
	background: transparent;
	/* background-color: red; */
	padding: 1em 2em;
	overflow: hidden;
	max-height: 100%;
	@media (max-width: 900px) {
		width: 60%;
		font-size: 0.5rem;
	}
`;
export const ImageOverlayProductName = styled.h2`
	margin: 0;
	color: ${(props: { overlayProductNameColor?: string }) =>
		props.overlayProductNameColor || colors.zsOrange};
	font-size: 2.2rem;
	font-weight: 700;
	letter-spacing: 2.5px;
	line-height: 3.5rem;
	text-transform: capitalize;
`;
export const ImageOverlayProductDesription = styled.p`
	margin: 0;
	color: ${(props: { overlayProductDescriptionColor?: string }) =>
		props.overlayProductDescriptionColor || colors.zsTeal};
	font-size: 1rem;
	font-weight: 400;
`;
