import styled from "styled-components";
import { colors } from "./../../globalTheme";

export const StyledSideBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  border-right: 4px solid ${colors.zsShadow};
  /* width: 15rem; */
  width: ${(props: { collapsed: boolean }) =>
    props.collapsed ? "5rem" : "18rem"};
  transition: width 0.5s ease-in-out;
  height: 100%;
  /* border: 1px solid black; */
  box-sizing: border-box;
  background: ${colors.zsWhite};
  z-index: 1;
`;
export const Logo = styled.div`
  position: relative;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 8%;
  /* border: 1px solid black; */
  box-sizing: border-box;
  background: transparent;
  z-index: 1;
`;
export const SideBarItems = styled.div`
  margin: 2vh 1.2rem;
  display: flex;
  flex-direction: column;
  width: calc(100% - 2.4rem);
  justify-content: space-around;
  align-items: center;
  animation: moveIn ease 1.4s;
  @keyframes moveIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
export const SideBarItem = styled.div`
  width: 100%;
  border-radius: 6px;
  color: ${colors.zsGray80};
  margin-bottom: 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  a {
    width: 100%;
    border-radius: 6px;
    padding: 6px 6px;
    text-decoration: none;
    color: ${colors.zsGray80};
    h3 {
      &:hover {
        color: ${colors.zsTeal};
      }
    }
    &:hover {
      color: ${colors.zsTeal};
    }
  }
  .active {
    background-color: ${colors.zsTeal};
    color: ${colors.zsWhite};
    h3 {
      color: ${colors.zsWhite} !important;
    }
    &:hover {
      color: ${colors.zsWhite};
    }
  }
`;
export const SideBarText = styled.h3`
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 8px;
  display: inline-block;
  animation: moveIn ease 1.4s;
  @keyframes moveIn {
    from {
      opacity: 0;
      transform: scaleX(0) translateX(-400px);
    }
    to {
      opacity: 1;
      transform: scaleX(100%) translateX(0);
    }
  }
`;
export const ZSLogo = styled.div`
  flex: 1;
  width: 5rem;
  .zsLogo {
    animation: fadeInn 0.8s;
  }

  @keyframes fadeInn {
    0% {
      opacity: 0;
      transform: scaleX(0) translateX(-400px);
    }
    100% {
      opacity: 1;
      transform: scaleX(100%) translateX(0);
    }
  }
`;
export const ToggleIcon = styled.div`
  flex: 0 0 1rem;
  color: ${colors.zsGray20};
  cursor: pointer;
`;

export const StyledTopHeader = styled.div`
  height: 7%;
  background-color: ${colors.zsGray90};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  box-sizing: border-box;
`;
export const AppHeaderText = styled.h1`
  color: ${colors.zsWhite};
  font-size: 1.15rem !important;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 700;
  flex: 1;
`;
export const HeaderIcons = styled.div`
  flex: 0 0 20%;
  display: flex;
  align-items: center;
  justify-content: right;
`;
export const HeaderIcon = styled.div`
  margin-left: 2em;
  color: ${colors.zsWhite};
  a {
    color: ${colors.zsWhite};
    &:hover {
      color: ${colors.zsTeal};
    }
  }
`;
export const HeaderIconUser = styled.div`
  display: flex;
  align-items: center;
  p {
    margin: 0;
    font-size: 0.8rem;
    line-height: 0.7rem;
  }

  color: ${colors.zsWhite};
  a {
    margin: 0;
    font-size: 0.8rem;
    line-height: 0.7rem;
    color: ${colors.zsOrange};
    &:hover {
      color: ${colors.zsGray20};
    }
  }
`;
export const StyledMiddleHeader = styled.div`
  height: 6%;
  background-color: ${colors.zsWhite};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  box-sizing: border-box;
  border-bottom: 1px solid gray;
`;
export const MiddleHeaderText = styled.h2`
  color: ${colors.zsGray100};
  font-size: 1.4rem;
  /* text-transform: capitalize; */
  font-weight: 700;
`;
export const MiddleHeaderDropDown = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: ${colors.zsTeal};
`;
export const SideBarTop = styled.div`
  height: 66%;
`;
export const SideBarBottom = styled.div`
  height: 26%;
`;
