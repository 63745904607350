import styled from "styled-components";
import { colors } from "./../../globalTheme";
export const LandingContainer = styled.div`
  position: relative;
  margin: 0;
  border-radius: 0px;
  width: 100%;
  height: 100vh;
  /* background: url(https://cdn.dam.gettyimages.com/Y9DGRJ39/at/svxhpxh8tqp594h37v6xwvs/960164282.jpg); */
  background-image: linear-gradient(to top, ${colors.zsGray80}, #10182073),
    url(https://cdn.dam.gettyimages.com/Y9DGRJ39/at/qc2syw-a31q14-16b0ic/1014151744.jpg);
  background-size: cover;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  /* display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around; */
  a {
    color: ${colors.zsWhite};
    &:hover {
      color: ${colors.zsTeal};
    }
  }
`;
export const StyledHeaderContainer = styled.div`
  height: 30vh;
  width: 100%;

  text-align: center;
  padding-top: 10vh;
`;
export const StyledHeader = styled.h1`
  font-size: 3rem !important;
  color: ${colors.zsBodyBg};
  text-transform: uppercase;
  letter-spacing: 1rem;
  line-height: 4.5rem;
  font-weight: 600;
`;
export const StyledWelcomeContainer = styled.div`
  width: 100%;
  text-align: center;

  p {
    color: ${colors.zsWhite};
    margin: 0;
    font-size: 2rem;
    letter-spacing: -0.2px;
    font-weight: 400;
    line-height: 3rem;
  }
  h2 {
    color: ${colors.zsWhite};
    margin: 0;
    font-size: 4rem;
    letter-spacing: -1px;
    font-weight: 300;
    line-height: 5rem;
  }
  h3 {
    color: ${colors.zsWhite};
    margin: 0;
    font-size: 3rem;
    letter-spacing: -0.5px;
    font-weight: 300;
    line-height: 5rem;
  }
`;
export const StyledBrandsContainer = styled.div`
  margin-top: 8vh;
  width: 100%;
  /* background-color: red; */
  display: grid;
  padding: 1em 10%;
  grid-template-columns: 33.33% 33.33% 33.33%;

  @media (max-width: 1068px) {
    grid-template-columns: 50% 50%;
    padding: 1em 5%;
  }
  @media (max-width: 700px) {
    grid-template-columns: 100%;
    padding: 1em 10%;
  }
`;
export const StyledBrands = styled.div`
  margin: 0.5em 1em;
  /* background-color: wheat; */
  button {
    width: 100%;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: ${colors.zsGray80};
    &:hover {
      color: ${colors.zsTeal};
    }
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: ${colors.zsGray80};
    &:hover {
      color: ${colors.zsTeal};
    }
  }
`;
