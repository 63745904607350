import { Form, Input, Row, Col, Button, Switch, Upload, Tabs } from "antd";
import React, { useState } from "react";
import { SimpleMdeReact } from "react-simplemde-editor";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Select } from "antd";
import AppContext from "../../store";
import { UploadOutlined } from "@ant-design/icons";
const { Option } = Select;

const FormField4 = (props: {
	handleEdit?: (value: any, messageId: number) => void;
	handleSave?: (value: any) => void;
	editData?: any;
	closeModal?: () => void;
	cardId: number;
	messageId?: number;
	keyMessageId: number;
	sectionId: number;
	subSectionId: number;
	viewType: string;
}) => {
	const context = React.useContext(AppContext);
	const value = React.useRef("");
	const onChange = (values: string) => {
		// setValue(value);
		value.current = values;
	};

	const [changedRef, setChangedRef] = React.useState(false);
	const [selected, setSelected] = useState(
		props.editData ? props.editData.placeholderId : 0
	);

	function handleChange(value: number) {
		console.log(`selected ${value}`);
		console.log(typeof { value });

		setSelected(value);
	}

	const [fileName, setFileName] = React.useState("");
	let filePath: string =
		process.env.REACT_APP_API_URL +
		"brand/UploadImage?imagePath=" +
		context.productId +
		"/" +
		props.sectionId +
		"/" +
		props.subSectionId +
		"/" +
		props.cardId +
		"/" +
		props.keyMessageId +
		"/" +
		props.messageId;
	const defaultFileList: any = [];

	const { TabPane } = Tabs;
	const dataCopy = props.editData;
	const [error, setError] = React.useState<null | string>(null);
	const [imgUploaded, setImgUploaded] = React.useState(false);
	React.useEffect(() => {
		if (props.editData && props.editData.imageURL) {
			const tempName = props.editData.imageURL?.split("/") || "";
			setFileName(tempName[tempName.length - 1]);
			defaultFileList.push({
				uid: "1",
				name: tempName[tempName.length - 1],

				// response: "Server Error 500"; // custom error message to show
				url: props.editData.imageURL,
			});
		}
	}, []);
	return (
		<>
			{console.log("EDIT FORM : ", props.editData)}
			<Form
				name="dynamic_form_nest_item"
				onFinish={(value) => {
					console.log("DATA VALUE ", value);

					let flag = false;
					if (
						value.supportingMessageHeader === undefined ||
						value.supportingMessageHeader === ""
					) {
						setError("Incomplete Form : Header is missing");
						flag = true;
						// console.log(flag);
					} else if (value.placeholderId === undefined) {
						// console.log("INside Link!");
						setError("Incomplete Form : Container Type is missing");
						flag = true;
					}
					value.changedRef = changedRef;
					const refDeleted: number[] = [];
					if (props.editData)
						dataCopy.references.map((refs: any) => {
							if (
								!value.references.find(
									(r: any) => r.id === refs.id
								)
							)
								refDeleted.push(refs.id);
						});

					value.refDeleted = refDeleted;
					if (!value.supportingMessages) {
						value.supportingMessages = [];
					}
					if (!value.references) value.references = [];
					console.log(
						"Props Section Subsetion Id",
						props.sectionId,
						props.subSectionId
					);
					// if (props.messageId && fileName !== "") {
					// 	value.imageURL =
					// 		context.productId +
					// 		"/" +
					// 		props.sectionId +
					// 		"/" +
					// 		props.subSectionId +
					// 		"/" +
					// 		props.cardId +
					// 		"/" +
					// 		props.keyMessageId +
					// 		"/" +
					// 		props.messageId +
					// 		"/" +
					// 		fileName.split(" ").join("_");
					// }
					if (imgUploaded) {
						console.log(
							"Uploading...",
							props.sectionId,
							props.subSectionId,
							fileName.split(" ").join("_")
						);
						value.imageURL =
							context.productId +
							"/" +
							props.sectionId +
							"/" +
							props.subSectionId +
							"/" +
							props.cardId +
							"/" +
							props.keyMessageId +
							"/" +
							props.messageId +
							"/" +
							fileName.split(" ").join("_");
					}

					// console.log("Image Url", value.imageURL);

					if (props.handleSave && !flag) {
						if (props.closeModal) props.closeModal();
						props.handleSave(value);
					} else if (props.handleEdit && !flag) {
						if (props.closeModal) props.closeModal();
						props.handleEdit(value, props.messageId || 0);
					}
				}}
				autoComplete="off"
				initialValues={props.editData}
			>
				{error && (
					<div
						style={{
							display: "flex",
							justifyContent: "space-around",
						}}
					>
						<p style={{ color: "red", margin: 0 }}>
							<i>{error}</i>
						</p>
					</div>
				)}
				<Tabs defaultActiveKey="1">
					<TabPane tab="Message Properties" key="1">
						<Row align="top" gutter={[10, 10]}>
							<Col span={3}>
								<p>Header</p>
							</Col>
							<Col span={21}>
								<Form.Item
									name="supportingMessageHeader"
									// rules={[
									// 	{
									// 		required: true,
									// 		message: "Please enter a Header",
									// 	},
									// ]}
								>
									<Input placeholder="Header" />
								</Form.Item>
							</Col>

							<Col span={3}>
								<span>Container </span>
							</Col>
							<Col span={21}>
								<Form.Item
									name="placeholderId"
									// rules={[
									// 	{
									// 		required: true,
									// 		message:
									// 			"Please select a container type",
									// 	},
									// ]}
								>
									<Select
										// defaultValue="1"
										placeholder="Select container"
										style={{ width: 200 }}
										onChange={handleChange}
									>
										<Option value={1}>Image</Option>
										<Option value={2}>Chart</Option>
										<Option value={3}>Table</Option>
										<Option value={4}>Text</Option>
									</Select>
								</Form.Item>
							</Col>

							{selected === 1 &&
								(props.messageId ? (
									<>
										<Col span={3}>
											<p>Image URL</p>
										</Col>
										<Col span={21}>
											<Form.Item name="imageURL">
												<Upload
													defaultFileList={
														defaultFileList
													}
													beforeUpload={(file) => {
														console.log(
															"File here",
															file
														);
														setFileName(file.name);
														setImgUploaded(true);
														return true;
													}}
													action={filePath}
													withCredentials={true}
													maxCount={1}
													headers={{
														userName:
															localStorage.getItem(
																"userName"
															) || "",
														"x-api-key":
															process.env
																.REACT_APP_API_KEY ||
															"",
													}}
												>
													{" "}
													<Button
														icon={
															<UploadOutlined />
														}
													>
														Click to Upload
													</Button>
												</Upload>
											</Form.Item>
										</Col>
									</>
								) : (
									<Col span={24}>
										<p>
											<i>
												Create the Supporting Message
												and Click on Edit to Upload
												Image.
											</i>
										</p>
									</Col>
								))}
							{selected === 3 && (
								<>
									<Col span={24}>
										<p>
											<i>
												Data for table can be entered
												from the Table Settings option.
											</i>
										</p>
									</Col>
								</>
							)}
							{selected === 2 && (
								<>
									<Col span={24}>
										<p>
											<i>
												Data for chart can be entered
												from the Chart Settings option.
											</i>
										</p>
									</Col>
								</>
							)}

							<Form.List name="supportingMessages">
								{(fields, { add, remove }) => (
									<>
										{fields.map(
											({
												key,
												name,
												fieldKey,
												...restField
											}) => (
												<>
													<Col span={3}>Bullet</Col>
													<Col span={20}>
														<Form.Item
															{...restField}
															name={[
																name,
																"bulletPoint",
															]}
															fieldKey={[
																fieldKey,
																"text",
															]}
														>
															<SimpleMdeReact
																options={{
																	hideIcons: [
																		"fullscreen",
																		"side-by-side",
																		"image",
																		"guide",
																	],
																}}
																value={
																	value.current
																}
																onChange={
																	onChange
																}
															/>
														</Form.Item>
													</Col>

													<Col span={1}>
														<MinusCircleOutlined
															onClick={() =>
																remove(name)
															}
														/>
													</Col>
												</>
											)
										)}
										<Form.Item>
											<Button
												type="dashed"
												onClick={() => add()}
												block
												icon={<PlusOutlined />}
											>
												Add Bullet
											</Button>
										</Form.Item>
									</>
								)}
							</Form.List>
						</Row>
					</TabPane>
					<TabPane tab="References" key="2" forceRender>
						<Form.List name="references">
							{(fields, { add, remove }) => (
								<>
									<Row gutter={[8, 0]}>
										{fields.map(
											({
												key,
												name,
												fieldKey,
												...restField
											}) => (
												<>
													<Form.Item
														{...restField}
														name={[name, "id"]}
														fieldKey={[
															fieldKey,
															"id",
														]}
														initialValue={0}
													/>
													<Col span={13}>
														<Form.Item
															{...restField}
															name={[
																name,
																"referenceHeader",
															]}
															fieldKey={[
																fieldKey,
																"referenceHeader",
															]}
															rules={[
																{
																	required:
																		true,
																	message:
																		"Missing Reference Header",
																},
															]}
														>
															<Input
																placeholder="Reference Header"
																onChange={() =>
																	setChangedRef(
																		true
																	)
																}
															/>
														</Form.Item>
													</Col>
													<Col span={10}>
														<Form.Item
															{...restField}
															name={[
																name,
																"referenceLink",
															]}
															fieldKey={[
																fieldKey,
																"referenceLink",
															]}
															rules={
																[
																	// {
																	// 	required: true,
																	// 	message: "Missing role",
																	// },
																]
															}
														>
															<Input
																placeholder="Reference Link"
																onChange={() =>
																	setChangedRef(
																		true
																	)
																}
															/>
														</Form.Item>
													</Col>
													<Col span={1}>
														<MinusCircleOutlined
															onClick={() =>
																remove(name)
															}
														/>
													</Col>
												</>
											)
										)}
									</Row>
									<Form.Item>
										<Button
											type="dashed"
											onClick={() => add()}
											block
											icon={<PlusOutlined />}
										>
											Add References
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					</TabPane>
				</Tabs>

				<Form.Item>
					<Button type="primary" htmlType="submit">
						Save
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default FormField4;
