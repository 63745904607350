import React, { useEffect, useState, useRef, useMemo } from "react";
import { ExternalLinkOutline } from "@styled-icons/evaicons-outline/ExternalLinkOutline";
import {
  ArrowsAltOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
  EditFilled,
  FileOutlined,
} from "@ant-design/icons";

import { Cross } from "@styled-icons/entypo/Cross";
import EditIcon from "../../../../card/components/EditIcon";
import SaveIcon from "../../../../card/components/Save";
import Cancel from "../../../../card/components/Cancel";
import AddIcon from "../../../../card/components/Add";
import DesignTable from "../../../../card/components/DesignTable";
import { CardHeaderAction } from "../../../../card/style";
import {
  Button,
  message,
  Modal,
  Select,
  Table,
  Divider,
  Space,
  Input,
  InputNumber,
  Popover,
  Tooltip,
} from "antd";
// import type { InputRef } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "antd";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import SimpleMdeReact from "react-simplemde-editor";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import Countries from "../../../../../../countryList.json";
import { request } from "../../../../../layout/SLR_Layout";
import { Link } from "react-router-dom";
// import { editTableData } from "../../../../../services/supportingMessage";

/**
 * Renders the Responsive Table Component. The component makes use of react-table hooks.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @returns Table Component
 */

const { Option } = Select;

interface LooseObject {
  [key: string]: any;
}

const contentStyle: React.CSSProperties = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const RightArrow = () => {
  return (
    <Button
      icon={<RightCircleOutlined style={{ fontSize: "2rem" }} />}
      size="middle"
    />
  );
};

const LeftArrow = () => {
  return (
    <Button
      icon={<LeftCircleOutlined style={{ fontSize: "2rem" }} />}
      size="middle"
    />
  );
};

const SimpleTable = (props: {
  tableData?: any;
  rows?: number;
  addButton?: any;
  editFunction?: any;
  saveFunction?: any;
  hideActions?: boolean[];
  deleteColumn?: any;
  defaultColumns?: any;
  additionalColumns?: any;
  studyDesignItems?: any;
  ethnicityItems?: any;
  mutationStatusItems?: any;
  histologyItems?: any;
  metastaticSitesItems?: any;
  metastaticStageItems?: any;
  smokingItems?: any;
  genderItems?: any;
  tableName?: any;
  saveCounter?: any;
  setSaveCounter?: any;
  studyDetails?: any;
}) => {
  // const columns = React.useMemo(
  // 	() => props.tableData.column,
  // 	[props.tableData.column]
  // );
  // const data = React.useMemo(
  // 	() => props.tableData.data,
  // 	[props.tableData.data]
  // );
  console.log(props);
  const [tableData, setTableData] = useState(props.tableData.data);
  const [filteredTableData, setFilteredTableData] = useState(
    props.tableData.data
  );
  const [columns, setColumns] = useState(props.tableData.column);
  const [searchValue, setSearchValue] = useState("");
  const [inEditMode, setInEditMode] = useState(false);
  const [isModalVisibleColumn, setIsModalVisibleColumn] = useState(false);
  const [isModalVisibleCell, setIsModalVisibleCell] = useState(false);
  const [isModalVisibleAddRow, setIsModalVisibleAddRow] = useState(false);
  const [editColumnKey, setEditColumnKey] = useState<string>("");
  const [disabledDeleteColumn, setDisabledDeleteColumn] = useState(false);
  const [editColumnName, setEditColumnName] = useState<string>("");
  const [editCellKey, setEditCellKey] = useState<any>();
  const [editCellDataIndex, setEditCellDataIndex] = useState<string>("");
  const [editCellValue, setEditCellValue] = useState<any>("");
  const [newRowData, setNewRowData] = useState<LooseObject>();
  // const [studyDesignItems, setStudyDesignItems] = useState([
  //   "Randomized Controlled",
  //   "Non Randomized",
  //   "Single Arm",
  //   "Observational Studies",
  // ]);
  // const [ethnicityItems, setEthnicityItems] = useState([
  //   "White",
  //   "Black or African American",
  //   "Asian",
  //   "American Indian or Alaska Native",
  //   "Other Pacific Islander",
  //   "Mixed",
  // ]);
  // const [mutationStatusItems, setMutationStatusItems] = useState([
  //   "Option 1",
  //   "Option 2",
  //   "Option 3",
  //   "Option 4",
  // ]);
  // const [histologyItems, setHistologyItems] = useState([
  //   "Option 1",
  //   "Option 2",
  //   "Option 3",
  //   "Option 4",
  // ]);
  // const [metastaticSitesItems, setMetastaticSitesItems] = useState([
  //   "Option 1",
  //   "Option 2",
  //   "Option 3",
  //   "Option 4",
  // ]);
  // const [metastaticStageItems, setMetastaticStageItems] = useState([
  //   "Stage 1",
  //   "Stage 2",
  //   "Stage 3",
  //   "Stage 4",
  // ]);
  // const [smokingItems, setSmokingItems] = useState([
  //   "Non Smoker",
  //   "Current",
  //   "Previous",
  // ]);
  // const [genderItems, setGenderItems] = useState([
  //   "Non Smoker",
  //   "Current",
  //   "Previous",
  // ]);
  const [studyDesignItems, setStudyDesignItems] = useState([
    ...props.studyDesignItems,
  ]);
  const [ethnicityItems, setEthnicityItems] = useState([
    ...props.ethnicityItems,
  ]);
  const [mutationStatusItems, setMutationStatusItems] = useState([
    ...props.mutationStatusItems,
  ]);
  const [histologyItems, setHistologyItems] = useState([
    ...props.histologyItems,
  ]);
  const [metastaticSitesItems, setMetastaticSitesItems] = useState([
    ...props.metastaticSitesItems,
  ]);
  const [metastaticStageItems, setMetastaticStageItems] = useState([
    ...props.metastaticStageItems,
  ]);
  const [smokingItems, setSmokingItems] = useState([...props.smokingItems]);
  const [genderItems, setGenderItems] = useState([...props.genderItems]);
  const [name, setName] = useState("");
  const [isModalVisibleDesignTable, setIsModalVisibleDesignTable] =
    useState(false);
  const [columnOptions, setColumnOptions] = useState<any>([]);
  const [defaultColumnKeys, setDefaultColumnKeys] = useState<any>([]);
  const [newColumnName, setNewColumnName] = useState<string>("");
  const [selectedColumns, setSelectedColumns] = useState<any>();
  const [
    selectedColumnsAdditionalChecked,
    setSelectedColumnsAdditionalChecked,
  ] = useState<any>([...props.additionalColumns?.checked] || []);
  const [
    selectedColumnsAdditionalUnchecked,
    setSelectedColumnsAdditionalUnchecked,
  ] = useState<any>([...props.additionalColumns?.unChecked] || []);
  const [selectedColumnsAddNew, setSelectedColumnsAddNew] = useState<any>([]);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (defaultColumnKeys.length === 0) {
      let temp = [...defaultColumnKeys];
      props.defaultColumns?.map((column: any) => {
        temp.push(column.split(" ").join("").toLowerCase());
      });
      setDefaultColumnKeys([...temp]);
    }
  }, []);

  function saveHandler() {
    if (props.tableName === "Study") {
      let tempColumns = [...columns];
      let tempData = JSON.parse(JSON.stringify(filteredTableData));
      let tempColumnKeys: any = [];

      tempColumns.map((item: any, index: any) => {
        item.order = index;
        tempColumnKeys.push(item.dataIndex);
      });

      tempData.map((item: any) => {
        let temp = Object.keys(item);
        for (let i = 0; i < temp.length; i++) {
          if (!tempColumnKeys.includes(temp[i]) && temp[i] !== "key") {
            delete item[temp[i]];
          }
        }
      });

      console.log("saving data for study", tempColumnKeys, {
        studyCharacteristic: {
          columns: tempColumns,
          data: tempData,
        },
      });

      const productReviewId = window.sessionStorage.getItem("productReviewId");
      message.loading("Saving Changes!", 2);
      request
        .post(
          "/EditStudyCharacteristics?productReviewId=" +
            String(productReviewId),
          {
            studyCharacteristic: {
              columns: tempColumns,
              data: tempData,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            message.success("Saved!");
            props.setSaveCounter(props.saveCounter + 1);
          } else {
            message.error("Error!");
          }
          console.log("res", res);
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    } else if (props.tableName === "Patient") {
      let tempColumns = [...columns];
      let tempData = JSON.parse(JSON.stringify(filteredTableData));
      let tempColumnKeys: any = [];

      tempColumns.map((item: any, index: any) => {
        item.order = index;
        tempColumnKeys.push(item.dataIndex);
      });

      tempData.map((item: any) => {
        let temp = Object.keys(item);
        for (let i = 0; i < temp.length; i++) {
          if (!tempColumnKeys.includes(temp[i]) && temp[i] !== "key") {
            delete item[temp[i]];
          }
        }
      });

      console.log("saving data for patient", {
        patientCharacteristic: {
          columns: tempColumns,
          data: tempData,
        },
      });

      const productReviewId = window.sessionStorage.getItem("productReviewId");
      message.loading("Saving Changes!", 2);
      request
        .post(
          "/EditPatientCharacteristics?productReviewId=" +
            String(productReviewId),
          {
            patientCharacteristic: {
              columns: tempColumns,
              data: tempData,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            message.success("Saved!");
            props.setSaveCounter(props.saveCounter + 1);
          } else {
            message.error("Error!");
          }
          console.log("res", res);
        })
        .catch((err) => {
          message.error("Error!");
          console.log(err);
        });
    }
    setInEditMode(false);
  }

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onChangeAdditionalChecked = (checkedValues: CheckboxValueType[]) => {
    console.log(checkedValues);
    setSelectedColumnsAdditionalChecked(checkedValues);
  };

  const onChangeAdditionalUnchecked = (checkedValues: CheckboxValueType[]) => {
    console.log(checkedValues);
    let temp = [...selectedColumnsAdditionalChecked];
    props.additionalColumns.unChecked.map((value: any) => {
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        checkedValues.findIndex((e) => e === value) >= 0
      ) {
        if (temp.findIndex((e) => e === value) === -1) temp.push(value);
      } else if (checkedValues.findIndex((e) => e === value) === -1) {
        let index = temp.findIndex((e) => e === value);
        temp.splice(index, 1);
      }
    });
    setSelectedColumnsAdditionalChecked([...temp]);
    setSelectedColumnsAdditionalUnchecked(checkedValues);
  };

  const onChangeAddNew = (checkedValues: CheckboxValueType[]) => {
    console.log(checkedValues);
    setSelectedColumnsAddNew(checkedValues);
  };

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (name === "" || name === undefined) {
      message.error("Please enter a value!");
      return;
    }
    setStudyDesignItems([...studyDesignItems, name]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const addNewColumn = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (newColumnName === "" || newColumnName === undefined) {
      message.error("Please enter a value!");
      return;
    }
    for (let i = 0; i < props.additionalColumns.checked.length; i++) {
      if (props.additionalColumns.checked[i] === newColumnName) {
        message.error("Please provide unique column name!");
        return;
      }
    }
    for (let i = 0; i < props.additionalColumns.unChecked.length; i++) {
      if (props.additionalColumns.unChecked[i] === newColumnName) {
        message.error("Please provide unique column name!");
        return;
      }
    }
    for (let i = 0; i < props.defaultColumns.length; i++) {
      if (props.defaultColumns[i] === newColumnName) {
        message.error("Please provide unique column name!");
        return;
      }
    }
    for (let i = 0; i < columnOptions.length; i++) {
      if (columnOptions[i] === newColumnName) {
        message.error("Please provide unique column name!");
        return;
      }
    }
    setColumnOptions([...columnOptions, newColumnName]);
    setNewColumnName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const showModalColumn = (key: any, name: any) => {
    setEditColumnKey(key);
    setEditColumnName(name);
    setIsModalVisibleColumn(true);
    for (let i = 0; i < props.defaultColumns.length; i++) {
      if (props.defaultColumns[i].split(" ").join("").toLowerCase() === key) {
        setDisabledDeleteColumn(true);
        break;
      } else {
        setDisabledDeleteColumn(false);
      }
    }
  };

  const handleOkColumn = () => {
    let temp = columns;
    let previousDataIndex = "";
    let newDataIndex = editColumnName.split(" ").join("");
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["dataIndex"] === editColumnKey) {
        previousDataIndex = temp[i]["dataIndex"];
        temp[i]["title"] = editColumnName;
        temp[i]["dataIndex"] = newDataIndex;
        // temp[i]["key"] = newDataIndex;
      }
    }
    setColumns(temp);
    setIsModalVisibleColumn(false);
    // props.saveFunction({ tableData: tableData, columns: columns });
    let tempData = filteredTableData;
    for (let i = 0; i < tempData.length; i++) {
      tempData[i][newDataIndex] = tempData[i][previousDataIndex];
      if (newDataIndex !== previousDataIndex)
        delete tempData[i][previousDataIndex];
    }

    setEditColumnName("");
    setEditColumnKey("");
  };

  const handleCancelColumn = () => {
    setIsModalVisibleColumn(false);
    setEditColumnName("");
    setEditColumnKey("");
  };

  const showModalCell = (key: any, dataIndex: any, value: any) => {
    setEditCellKey(key);
    setEditCellDataIndex(dataIndex);
    if (dataIndex === "countries") {
      setEditCellValue(value.replaceAll(", ", "***").split("***"));
    } else setEditCellValue(value);
    setIsModalVisibleCell(true);
  };

  const handleOkCell = () => {
    let temp = filteredTableData;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["key"] === editCellKey) {
        if (editCellDataIndex === "countries") {
          temp[i][editCellDataIndex] = editCellValue.join(", ");
        } else temp[i][editCellDataIndex] = editCellValue;
      }
    }
    setIsModalVisibleCell(false);
    // props.saveFunction({ tableData: tableData, columns: columns });
    setEditCellValue("");
  };

  const handleCancelCell = () => {
    setIsModalVisibleCell(false);
    setEditCellValue("");
  };

  const showModalAddRow = () => {
    let tempObj: LooseObject = { key: filteredTableData.length };
    columns.map((c: any) => {
      tempObj[c["dataIndex"]] = "";
    });
    setNewRowData(tempObj);
    setIsModalVisibleAddRow(true);
  };

  const handleOkAddRow = () => {
    setIsModalVisibleAddRow(false);
    addRows(newRowData);
  };
  const handleOkDesignTable = () => {
    setIsModalVisibleDesignTable(false);
    let temp = [
      ...props.defaultColumns,
      ...selectedColumnsAdditionalChecked,
      // ...selectedColumnsAdditionalUnchecked,
      ...selectedColumnsAddNew,
    ];
    // add the new columns to column state
    let tempColumns: any = [];
    for (let i = 0; i < temp.length; i++) {
      let tempColumn = {
        title: temp[i],
        dataIndex: temp[i].split(" ").join("").toLowerCase(),
        order: i,
        isSelected: temp[i].isSelected ? temp[i].isSelected : false,
        isFixed: temp[i].isFixed ? temp[i].isFixed : false,
      };
      tempColumns.push(tempColumn);
    }
    console.log("tempColumns,", tempColumns);
    setColumns([...tempColumns]);
  };

  const handleCancelAddRow = () => {
    setIsModalVisibleAddRow(false);
  };
  const handleCancelDesignTable = () => {
    setIsModalVisibleDesignTable(false);
  };

  const handleCellValueChange = (value: string) => {
    setEditCellValue(value);
  };

  // function handleStudyDesignValue(value: string) {
  //   setEditCellValue(value);
  // }

  // function handleCountryChange(value: any) {
  //   console.log("value", value);
  //   setEditCellValue(value);
  // }

  // function handleGenderChange(value: any) {
  //   console.log("value", value);
  //   setEditCellValue(value);
  // }

  // function handleMutationStatusValue(value:any){
  //   setEditCellValue(value);
  // }

  const options = useMemo(() => {
    return {
      hideIcons: ["preview", "side-by-side", "fullscreen", "image"],
      spellChecker: false,
      minHeight: "700px",
    };
  }, []);

  const addColumn = () => {
    let check = false;
    for (const col of columns) {
      if (col["dataIndex"] === "accessor") check = true;
    }
    if (check) {
      message.warning("Please update previously added column header first!");
      return;
    }
    const temp = {
      key: "mynewcolumn",
      title: "Enter Column Name",
      dataIndex: "accessor",
      sort: false,
      tableId: 1,
    };
    // console.log("Received values of form:", value);
    const col = columns;
    col.push(temp);

    const col2: any = [];
    columns.map((c: any) => col2.push(c));
    setColumns(col2);
  };

  const deleteColumn = (id: string) => {
    if (id === "") return;

    let name = "";
    let temp = columns;
    let columnTitle: any = "";
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["dataIndex"] === id) {
        name = temp[i].dataIndex;
        columnTitle = temp[i].title;
        temp.splice(i, 1);
      }
    }
    let temp2: any = [];
    columns.map((c: any) => temp2.push(c));
    for (let i = 0; i < temp2.length; i++) {
      if (temp2[i]["dataIndex"] === id) {
        name = temp2[i].dataIndex;
        temp2.splice(i, 1);
      }
    }
    setColumns(temp2);
    const d = filteredTableData;
    d.map((item: any) => delete item[name]);
    setFilteredTableData(d);

    // let tempChecked = [...selectedColumnsAdditionalChecked];
    // for (let i = 0; i < tempChecked.length; i++) {
    //   if (tempChecked[i] === columnTitle) {
    //     tempChecked.splice(i, 1);
    //   }
    // }
    // console.log("tempChecked,tempChecked", tempChecked);
    // setSelectedColumnsAdditionalChecked([...tempChecked]);

    // let tempUnchecked = [...selectedColumnsAdditionalUnchecked];
    // temp.push(columnTitle);
    // setSelectedColumnsAdditionalUnchecked([...temp])

    const index = props.additionalColumns.checked.indexOf(columnTitle);
    if (index > -1) {
      // only splice array when item is found
      props.additionalColumns.checked.splice(index, 1);
      props.additionalColumns.unChecked.push(columnTitle);
    }

    setIsModalVisibleColumn(false);
  };

  const addRows = (newRow: any) => {
    // let temp: LooseObject = { key: filteredTableData.length };

    // for (const column of columns) {
    //   temp[column["dataIndex"]] = "";
    // }
    // console.log("temp add rows", temp);

    let tempData = filteredTableData;
    tempData.push(newRow);

    let temp2: any = [];
    filteredTableData.map((d: any) => temp2.push(d));
    setFilteredTableData(temp2);
    // setVisible(false);
    let obj: LooseObject = {};
    setNewRowData(obj);
  };

  const removeRow = (key: number) => {
    let temp = filteredTableData;
    for (let i = 0; i < temp.length; i++) {
      if (temp[i]["key"] === key) {
        temp.splice(i, 1);
        break;
      }
    }
    let temp2: any = [];
    filteredTableData.map((d: any) => temp2.push(d));
    // setVisible(false);
    setFilteredTableData(temp2);
  };

  function searchData(value: any) {
    //search in question bank
    const searchText = value.toLowerCase();
    if (searchText === "") {
      setFilteredTableData(props.tableData.data);
      return;
    }
    let tempFilteredData: any = [];
    for (let i = 0; i < props.tableData.data.length; i++) {
      let tempObj = props.tableData.data[i];
      let keys = Object.keys(tempObj);
      // console.log("keys", keys, tempObj);
      for (const key of keys) {
        if (key === "key") continue;
        if (tempObj[key].toLowerCase().search(searchText) >= 0) {
          tempFilteredData.push(tempObj);
          break;
        }
      }
    }
    setFilteredTableData([...tempFilteredData]);
  }

  return (
    <>
      <div className="flex">
        <div className="w-1/2 flex justify-start items-center">
          <span className="mb-2 font-semibold">
            Search:{" "}
            <input
              className="border border-gray-300 rounded-md h-7 w-72 ml-2"
              value={searchValue || ""}
              onChange={(e) => {
                setSearchValue(e.target.value);
                searchData(e.target.value);
              }}
            />
          </span>
        </div>
        {window.sessionStorage.getItem("isAdmin") === "2" ? (
          <div className="w-1/2 flex justify-end items-center">
            {inEditMode ? (
              <div className="flex justify-end">
                {/* <CardHeaderAction>
                <AddIcon
                  // handleClick={() => {
                  //   if (props.editFunction) props.editFunction();
                  // }}
                  handleClick={showModalAddRow}
                  text={"Add Row"}
                />
              </CardHeaderAction> */}
                <CardHeaderAction>
                  <DesignTable
                    handleClick={() => setIsModalVisibleDesignTable(true)}
                    text={"Design Table"}
                  />
                </CardHeaderAction>
                <CardHeaderAction>
                  <SaveIcon handleClick={saveHandler} />
                </CardHeaderAction>
                {/* <CardHeaderAction>
                  <Cancel
                    handleClick={() => {
                      setInEditMode(false);
                    }}
                  />
                </CardHeaderAction> */}
              </div>
            ) : (
              window.sessionStorage.getItem("isAdmin") === "2" && (
                <CardHeaderAction>
                  <EditIcon handleClick={() => setInEditMode(true)} />
                </CardHeaderAction>
              )
            )}

            {/* )} */}
          </div>
        ) : (
          ""
        )}
      </div>
      {inEditMode ? (
        <div className="flex items-center justify-start mb-2 ">
          <p className="text-xs italic  text-center text-red-500">
            * Click on the cell to edit!
          </p>
        </div>
      ) : (
        ""
      )}

      {filteredTableData.length === 0 ? (
        <div
          className="flex justify-center items-center rounded-md border-2"
          style={{ overflowY: "auto", height: 530 }}
        >
          {window.sessionStorage.getItem("isAdmin") === "2" ? (
            <div className="text-center ">
              <p className="text-lg text-gray-500 mt-2 mr-4 italic font-semibold mb-1 flex items-center pl-12 ">
                Navigate to PRISMA to add Study Details &nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  to={
                    "/SLR/" +
                    window.sessionStorage.getItem("selectedBackground") +
                    "/Results/Prisma"
                  }
                >
                  <Button
                    type="default"
                    onClick={() =>
                      window.sessionStorage.setItem("redirectToStudy", "true")
                    }
                    className="p-1"
                  >
                    <ExternalLinkOutline
                      size={24}
                      style={{ right: 0, paddingBottom: "0.2rem" }}
                    />
                  </Button>
                </Link>
              </p>
            </div>
          ) : (
            <div className="text-center">
              <FileOutlined style={{ color: "#27a6a4", fontSize: "3rem" }} />
              <p className="text-xl mt-2 italic">No Details Added!</p>
            </div>
          )}
          {/* <div className="text-center">
            <EditFilled style={{ color: "#27a6a4", fontSize: "3rem" }} />
            <p className="text-xl mt-2 italic">
              Click on Add New to add details!
            </p>
          </div> */}
        </div>
      ) : (
        <div
          className="fixedHeader"
          style={
            inEditMode
              ? {
                  overflowY: "auto",
                  height: 535,
                }
              : {
                  overflowY: "auto",
                  height: 555,
                }
          }
        >
          <table>
            <thead>
              <tr className="roundedRows ">
                {/* {inEditMode && (
                  <th
                    style={{
                      backgroundColor: "#27a6a4",
                      color: "whitesmoke",
                    }}
                    className="font-bold text-center px-2 w-6"
                  ></th>
                )} */}
                {columns.map((col: any) => (
                  <th
                    onClick={() => {
                      if (inEditMode) showModalColumn(col.dataIndex, col.title);
                    }}
                    style={{
                      backgroundColor: "#27a6a4",
                      color: "whitesmoke",
                    }}
                    className="font-bold text-center px-2 "
                  >
                    {col.title}
                  </th>
                ))}
              </tr>
            </thead>
            <div className="h-1"></div>
            <tbody>
              {filteredTableData.map((row: any, index: any) => {
                const color = index % 2 === 0 ? "#eaf8f8" : "white";
                const keys: any = [];
                columns.map((c: any) => keys.push(c["dataIndex"]));
                const temp = keys.indexOf("key");
                if (temp > -1) {
                  keys.splice(temp, 1);
                }
                return (
                  <tr
                    className="hover:bg-transparent border-b-8 roundedRows"
                    style={{ backgroundColor: color }}
                  >
                    {/* {inEditMode && (
                      <td>
                        <DeleteOutlined
                          onClick={() => removeRow(row.key)}
                          style={{
                            color: "teal",
                            fontSize: "1.2rem",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                        />
                      </td>
                    )} */}
                    {
                      //@ts-ignore
                      keys.map((key) => {
                        if (searchValue !== "") {
                          if (row[key].toLowerCase().search(searchValue) >= 0) {
                            if (key === "studyname") {
                              return (
                                <td
                                  onClick={() => {
                                    if (inEditMode)
                                      showModalCell(row.key, key, row[key]);
                                  }}
                                  // style={{ width: "30rem" }}
                                  className="align-top p-2 text-center"
                                >
                                  <div className="flex justify-end items-center">
                                    <Tooltip
                                      title={props.studyDetails[row["key"]]}
                                    >
                                      <a
                                        href={props.studyDetails[row["key"]]}
                                        target="_blank"
                                      >
                                        <ExternalLinkOutline
                                          size={12}
                                          style={{ right: 0 }}
                                        />
                                      </a>
                                    </Tooltip>
                                  </div>
                                  <mark className="bg-yellow-100">
                                    {row[key]}
                                  </mark>
                                </td>
                              );
                            } else
                              return (
                                <td
                                  onClick={() => {
                                    if (inEditMode)
                                      showModalCell(row.key, key, row[key]);
                                  }}
                                  // style={{ width: "30rem" }}
                                  className="align-top p-2 text-center"
                                >
                                  <mark className="bg-yellow-100">
                                    {row[key]}
                                  </mark>
                                </td>
                              );
                          } else {
                            if (key === "studyname") {
                              return (
                                <td
                                  onClick={() => {
                                    if (inEditMode)
                                      showModalCell(row.key, key, row[key]);
                                  }}
                                  // style={{ width: "30rem" }}
                                  className="align-top p-2 text-center"
                                >
                                  <div className="flex justify-end items-center">
                                    <Tooltip
                                      title={props.studyDetails[row["key"]]}
                                    >
                                      <a
                                        href={props.studyDetails[row["key"]]}
                                        target="_blank"
                                      >
                                        <ExternalLinkOutline
                                          size={12}
                                          style={{ right: 0 }}
                                        />
                                      </a>
                                    </Tooltip>
                                  </div>
                                  <ReactMarkdown
                                    children={row[key]}
                                  ></ReactMarkdown>
                                </td>
                              );
                            } else
                              return (
                                <td
                                  onClick={() => {
                                    if (inEditMode)
                                      showModalCell(row.key, key, row[key]);
                                  }}
                                  // style={{ width: "30rem" }}
                                  className="align-top p-2 text-center"
                                >
                                  <ReactMarkdown
                                    children={row[key]}
                                  ></ReactMarkdown>
                                </td>
                              );
                          }
                        } else if (key === "studyname") {
                          return (
                            <td
                              onClick={() => {
                                if (inEditMode)
                                  showModalCell(row.key, key, row[key]);
                              }}
                              // style={{ width: "30rem" }}
                              className="align-top p-2 text-center"
                            >
                              <div className="flex justify-end items-center">
                                <Tooltip title={props.studyDetails[row["key"]]}>
                                  <a
                                    href={props.studyDetails[row["key"]]}
                                    target="_blank"
                                  >
                                    <ExternalLinkOutline
                                      size={12}
                                      style={{ right: 0 }}
                                    />
                                  </a>
                                </Tooltip>
                              </div>
                              <ReactMarkdown
                                children={row[key]}
                              ></ReactMarkdown>
                            </td>
                          );
                        } else
                          return (
                            <td
                              onClick={() => {
                                if (inEditMode)
                                  showModalCell(row.key, key, row[key]);
                              }}
                              // style={{ width: "30rem" }}
                              className="align-top p-2 text-center"
                            >
                              <ReactMarkdown
                                children={row[key]}
                              ></ReactMarkdown>
                            </td>
                          );
                      })
                    }
                  </tr>
                );
              })}
            </tbody>
            {/* <tfoot>
					{footerGroups.map((footerGroup) => (
						<tr {...footerGroup.getFooterGroupProps()}>
							{footerGroup.headers.map((column) => (
								<td {...column.getFooterProps}>
									{column.render("Footer")}
								</td>
							))}
						</tr>
					))}
				</tfoot> */}
          </table>

          {editColumnName && (
            <Modal
              title={<p className="text-white">Edit Column</p>}
              className="editTableModal"
              visible={isModalVisibleColumn}
              onOk={handleOkColumn}
              onCancel={handleCancelColumn}
              closable={disabledDeleteColumn ? true : false}
              footer={
                disabledDeleteColumn
                  ? null
                  : [
                      <Button
                        disabled={disabledDeleteColumn}
                        key="1"
                        onClick={() => deleteColumn(editColumnKey)}
                      >
                        Delete Column
                      </Button>,
                      <Button key="2" type="primary" onClick={handleOkColumn}>
                        OK
                      </Button>,
                    ]
              }
              closeIcon={
                <CloseOutlined
                  style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
                />
              }
            >
              {disabledDeleteColumn ? (
                <div className="flex justify-center items-center">
                  <div>
                    <textarea
                      disabled={true}
                      className="border pl-2"
                      rows={2}
                      cols={40}
                      style={{ minHeight: "2rem" }}
                      defaultValue={editColumnName}
                    ></textarea>
                    <p className="text-sm italic  text-center text-red-500">
                      * This is a non-editable field
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center italic">
                  <p className="mr-2">Enter Column Header:</p>
                  <textarea
                    disabled={disabledDeleteColumn}
                    className="border pl-2"
                    rows={5}
                    cols={40}
                    style={{ minHeight: "2rem" }}
                    onChange={(e) => setEditColumnName(e.target.value)}
                    defaultValue={editColumnName}
                  ></textarea>
                </div>
              )}
            </Modal>
          )}
          {(editCellValue === undefined || editCellValue) && (
            <Modal
              className="editTableModal"
              title={<p className="text-white">Edit Data</p>}
              visible={isModalVisibleCell}
              onOk={handleOkCell}
              onCancel={handleCancelCell}
              closable={false}
              closeIcon={
                <CloseOutlined
                  style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
                />
              }
            >
              {editCellDataIndex === "studydesign" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Select Option</p>
                  <Select
                    style={{ width: 300 }}
                    defaultValue={editCellValue}
                    placeholder="Select Option"
                    onChange={handleCellValueChange}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        {/* <Divider style={{ margin: "8px 0" }} />
                        <Space style={{ padding: "0 8px 4px" }}>
                          <Input
                            placeholder="Please enter item"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                          />
                          <Button
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={addItem}
                          >
                            Add item
                          </Button>
                        </Space> */}
                      </>
                    )}
                  >
                    {studyDesignItems.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              ) : editCellDataIndex === "countries" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Select Countries</p>
                  <Select
                    style={{ width: 300 }}
                    defaultValue={editCellValue}
                    placeholder="Select Option"
                    onChange={handleCellValueChange}
                    mode="multiple"
                    allowClear
                  >
                    {Countries.countryList.map((item) => (
                      <Option value={item.country}>{item.country}</Option>
                    ))}
                  </Select>
                </div>
              ) : editCellDataIndex === "gender" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Select Gender</p>
                  <Select
                    style={{ width: 300 }}
                    defaultValue={editCellValue}
                    placeholder="Select Gender"
                    onChange={handleCellValueChange}
                  >
                    {genderItems.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              ) : editCellDataIndex === "mutationstatus" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Select Mutation Status</p>
                  <Select
                    style={{ width: 300 }}
                    defaultValue={editCellValue}
                    placeholder="Select Option"
                    onChange={handleCellValueChange}
                  >
                    {mutationStatusItems.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              ) : editCellDataIndex === "metastaticsites" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Select Metastatic Sites</p>
                  <Select
                    style={{ width: 300 }}
                    defaultValue={editCellValue}
                    placeholder="Select Option"
                    onChange={handleCellValueChange}
                  >
                    {metastaticSitesItems.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              ) : editCellDataIndex === "metastaticstage" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Select Metastatic Stage</p>
                  <Select
                    style={{ width: 300 }}
                    defaultValue={editCellValue}
                    placeholder="Select Option"
                    onChange={handleCellValueChange}
                  >
                    {metastaticStageItems.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              ) : editCellDataIndex === "smoking" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Select Smoking</p>
                  <Select
                    style={{ width: 300 }}
                    defaultValue={editCellValue}
                    placeholder="Select Option"
                    onChange={handleCellValueChange}
                  >
                    {smokingItems.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              ) : editCellDataIndex === "ethnicity" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Select Ethnicity</p>
                  <Select
                    style={{ width: 300 }}
                    defaultValue={editCellValue}
                    placeholder="Select Option"
                    onChange={handleCellValueChange}
                  >
                    {ethnicityItems.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              ) : editCellDataIndex === "histology" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Select Histology</p>
                  <Select
                    style={{ width: 300 }}
                    defaultValue={editCellValue}
                    placeholder="Select Option"
                    onChange={handleCellValueChange}
                  >
                    {histologyItems.map((item) => (
                      <Option value={item}>{item}</Option>
                    ))}
                  </Select>
                </div>
              ) : editCellDataIndex === "ntotalrandomized" ||
                editCellDataIndex === "interventionnrandomized" ||
                editCellDataIndex === "comparisonnrandomized" ||
                editCellDataIndex === "priorlineoftherapy" ||
                editCellDataIndex === "age(years)" ||
                editCellDataIndex === "studyduration(months)" ||
                editCellDataIndex === "followup(months)" ||
                editCellDataIndex === "phase" ||
                editCellDataIndex === "numberoftrials" ? (
                <div className="flex justify-center items-center">
                  <p className="mr-2 italic">Enter Data:</p>
                  <InputNumber
                    min={0}
                    defaultValue={Number(editCellValue)}
                    onChange={(e) => setEditCellValue(String(e))}
                  />
                </div>
              ) : editCellDataIndex === "studyname" ? (
                <div className="flex justify-center items-center">
                  <div>
                    <textarea
                      disabled
                      className="border pl-2"
                      rows={5}
                      cols={40}
                      style={{ minHeight: "2rem" }}
                      defaultValue={editCellValue}
                    ></textarea>
                    <p className="text-sm italic text-center text-red-500">
                      * This is a non-editable field
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  id="SLRMDEditor"
                  className="flex justify-center items-center"
                >
                  <p className="mr-2 italic">Enter Data:</p>
                  {/* <textarea
                    className="border pl-2"
                    rows={5}
                    cols={40}
                    style={{ minHeight: "2rem" }}
                    onChange={(e) => setEditCellValue(e.target.value)}
                    defaultValue={editCellValue}
                  ></textarea> */}
                  <SimpleMdeReact
                    options={options}
                    value={editCellValue}
                    onChange={handleCellValueChange}
                  />
                </div>
              )}
            </Modal>
          )}
          <Modal
            title="Add Row"
            visible={isModalVisibleAddRow}
            onOk={handleOkAddRow}
            onCancel={handleCancelAddRow}
            width={"40rem"}
          >
            <Carousel
              arrows
              prevArrow={<LeftCircleOutlined />}
              nextArrow={<RightCircleOutlined />}
            >
              {columns.map((c: any) => {
                return (
                  <div>
                    <div className="flex justify-center">
                      <div id="SLRMDEditor" className="p-4">
                        <p className="text-center text-sm ">Enter value for</p>
                        <p
                          style={{
                            backgroundColor: "#27a6a4",
                            color: "whitesmoke",
                          }}
                          className="text-center text-lg font-seimbold mt-2 rounded-md p-2 mb-4"
                        >
                          {c.title}
                        </p>
                        {c.dataIndex === "studyDesign" ? (
                          <Select
                            style={{ width: 300 }}
                            // defaultValue={editCellValue}
                            placeholder="Select Option"
                            // onChange={handleStudyDesignValue}
                            onChange={(e) => {
                              let temp: any = newRowData;
                              temp[c["dataIndex"]] = String(e);
                              setNewRowData(temp);
                            }}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: "8px 0" }} />
                                <Space style={{ padding: "0 8px 4px" }}>
                                  <Input
                                    placeholder="Please enter item"
                                    ref={inputRef}
                                    value={name}
                                    onChange={onNameChange}
                                  />
                                  <Button
                                    type="text"
                                    icon={<PlusOutlined />}
                                    onClick={addItem}
                                  >
                                    Add item
                                  </Button>
                                </Space>
                              </>
                            )}
                          >
                            {studyDesignItems.map((item) => (
                              <Option value={item}>{item}</Option>
                            ))}
                          </Select>
                        ) : c.dataIndex === "phase" ||
                          c.dataIndex === "totalRandomized" ? (
                          <div className="flex justify-center items-center">
                            <p className="mr-2">Enter Data:</p>
                            <InputNumber
                              min={0}
                              // defaultValue={}
                              onChange={(e) => {
                                let temp: any = newRowData;
                                temp[c["dataIndex"]] = String(e);
                                setNewRowData(temp);
                              }}
                            />
                          </div>
                        ) : c.dataIndex === "followUp" ||
                          c.dataIndex === "studyDuration(months)" ? (
                          <div className="flex justify-center items-center">
                            <p className="mr-2">Enter Number of Months:</p>
                            <InputNumber
                              min={0}
                              // defaultValue={}
                              onChange={(e) => {
                                let temp: any = newRowData;
                                temp[c["dataIndex"]] = String(e);
                                setNewRowData(temp);
                              }}
                            />
                          </div>
                        ) : (
                          // <textarea
                          //   // style={contentStyle}
                          //   className="border pl-2 w-full"
                          //   rows={5}
                          //   cols={40}
                          //   // style={{ minHeight: "2rem" }}
                          //   onChange={(e) => {
                          //     let temp: any = newRowData;
                          //     temp[c["dataIndex"]] = e.target.value;
                          //     setNewRowData(temp);
                          //   }}
                          //   defaultValue={""}
                          //   placeholder="Enter data"
                          // />
                          <SimpleMdeReact
                            options={options}
                            value={""}
                            onChange={(e) => {
                              let temp: any = newRowData;
                              temp[c["dataIndex"]] = String(e);
                              setNewRowData(temp);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Modal>

          <Modal
            className="editTableModal"
            title={<p className="text-white">Design Table</p>}
            visible={isModalVisibleDesignTable}
            onOk={handleOkDesignTable}
            onCancel={handleCancelDesignTable}
            closeIcon={
              <CloseOutlined
                style={{ color: "whitesmoke", marginBottom: "0.5rem" }}
              />
            }
          >
            <p className="mb-2 text-center italic">
              Select columns for the table
            </p>
            <Checkbox.Group
              className="defaultColumnsCheckBox"
              options={props.defaultColumns}
              // disabled
              value={props.defaultColumns}
              // onChange={onChange}
            />
            <br />
            <Checkbox.Group
              options={props.additionalColumns.checked}
              defaultValue={selectedColumnsAdditionalChecked}
              onChange={onChangeAdditionalChecked}
            />
            <br />
            <Checkbox.Group
              options={props.additionalColumns.unChecked}
              onChange={onChangeAdditionalUnchecked}
            />
            <br />
            <Checkbox.Group
              options={columnOptions}
              // defaultValue={}
              onChange={onChangeAddNew}
            />
            <br />
            <br />
            <Space style={{ padding: "0 0px 4px" }}>
              <Input
                placeholder="Enter New Column Name"
                // ref={inputRef}
                value={newColumnName}
                onChange={(e) => setNewColumnName(e.target.value)}
              />
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={addNewColumn}
              >
                Add Column
              </Button>
            </Space>
          </Modal>
        </div>
      )}
      {/* <Table
        rowClassName={(record, index) =>
          index % 2 === 1
            ? "roundedRows table-row-dark"
            : " roundedRows table-row-light"
        }
        dataSource={filteredTableData}
        columns={props.tableData.column}
        scroll={{ y: 330 }}
      /> */}

      {/* {props.tableData.column === null ||
      props.tableData.column.length === 0 ? (
        ""
      ) : (
        <div className="pagination" style={{ margin: "0 auto" }}>
          <div className="paginationLeft">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span className="ml-2">
              | &nbsp;&nbsp;Go to page:{" "}
              <input
                className="border rounded-sm h-7"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: "40px" }}
              />{" "}
            </span>
            <select
              className="border rounded-sm ml-6 h-7"
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[3, 5, 7, 10].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>{" "}
          </div>
          <div className="paginationRight ml-6">
            <button
              className="font-semibold text-lg"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="ml-2 font-semibold"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prev
            </button>
            <button
              className="ml-4 font-semibold"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Next
            </button>
            <button
              className="ml-2 font-semibold text-lg"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
        </div>
      )} */}
    </>
  );
};
export default SimpleTable;
