import React from "react";
import ExecutiveSummary from "../pages/executiveSummary";
import DiseaseOverview from "../pages/diseaseOverview";
import CurrentTreatmentOverview from "../pages/CurrentTreatmentLandscape";
import BurdenOfIllness from "../pages/BurdenOfIllness";
import ProductInformation from "../pages/ProductInformation";
import EconomicValue from "../pages/EconomicValue";
import MarketAccess from "../pages/MarketAccess";
import AboutUs from "../pages/AboutUs";
import ObjectionHandler from "../pages/ObjectionHandler";
import References from "../pages/References";
import Settings from "../pages/Settings";
import Search from "../pages/Search";
import AppContext from "../store";
import { headerIcons } from "../globalTheme";
import Abbreviations from "../pages/Abbreviations";

/**
 * Defines the different routes to the pages
 *
 * @returns Routes
 */
export function Routes() {
  const routePaths = [
    {
      path: "/summary/:productId/:productName",
      component: () => <ExecutiveSummary />,
    },
    {
      path: "/about",
      component: () => <AboutUs />,
    },
    {
      path: "/disease",
      component: () => <DiseaseOverview />,
    },
    {
      path: "/current",
      component: () => <CurrentTreatmentOverview />,
    },
    {
      path: "/burden",
      component: () => <BurdenOfIllness />,
    },
    {
      path: "/product",
      component: () => <ProductInformation />,
    },
    {
      path: "/economic",
      component: () => <EconomicValue />,
    },
    {
      path: "/market",
      component: () => <MarketAccess />,
    },
    {
      path: "/objection",
      component: () => <ObjectionHandler />,
    },
    {
      path: "/abbreviations",
      component: () => <Abbreviations />,
    },
    {
      path: "/references",
      component: () => <References />,
    },
    {
      path: "/settings",
      component: () => <Settings />,
    },
    {
      path: "/searchresults/:value",
      component: () => <Search />,
    },
  ];
  return routePaths;
}
/**
 * Configures the SideBar Menu Items
 *
 * @returns SideBar Menu Items
 */
export function TabNames() {
  const context = React.useContext(AppContext);
  const tabNames: { name: string; icon: JSX.Element }[] = [
    { name: "", icon: <></> },
  ];

  if (context.subSectionMapping.length > 0)
    context.subSectionMapping.map((tab) => {
      let icon: any = <></>;
      if (headerIcons.find((t) => t.id === tab.name))
        icon = headerIcons.find((t) => t.id === tab.name);
      tabNames.push({ name: tab.name, icon: icon.icon });
    });

  console.log("SSSSS", tabNames);
  console.log("SSSSS", context.subSectionMapping.length);
  return tabNames;
}
