import React, { useEffect, useState } from "react";
import BodyCardLayout from "../../../layout/components/body-layout";
import AppContext from "../../../store";
import SupportingMessage1 from "../../SupportingMessages/SupportingMessage1";
import { Tabs } from "antd";
import {
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch,
  useParams,
} from "react-router-dom";
import "./index.css";
const ObjectionHandlerOverview = (props: {
  showReturn: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const context = React.useContext(AppContext);
  const dummyData: any = [
    {
      id: 130,
      keyMessage: "Causes approximately 4% of blindness globally",
      supportingKeyMessage: [
        {
          id: 34,
          supportingMessageHeader:
            "CHM is caused by a loss of function mutation, where the protein (REP1) produced by the defective CHM gene has little or no function.",
          componentHeader: null,
          imageURL: "/images/supporting1.png",
          chartType: null,
          cardbodyComponentId: 130,
          tableId: null,
          placeholderId: 1,
          supportingMessages: [
            {
              id: 232,
              bulletPoint:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam repellat, praesentium doloribus quibusdam harum itaque a velit iure, aperiam eum sapiente tempora maxime aliquid saepe deleniti et eius doloremque eligendi!",
              link: null,
            },
            {
              id: 233,
              bulletPoint:
                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam repellat, praesentium doloribus quibusdam harum itaque a velit iure, aperiam eum sapiente tempora maxime aliquid saepe deleniti et eius doloremque eligendi! esf",
              link: "some-link",
            },
          ],
          tableComponent: null,
        },
        {
          id: 71,
          supportingMessageHeader:
            "CHM is caused by a loss of function mutation, where the protein (REP1) produced by the defective CHM gene has little or no function.",
          componentHeader: null,
          imageURL: null,
          chartType: null,
          cardbodyComponentId: 130,
          tableId: null,
          placeholderId: 3,
          supportingMessages: [
            {
              id: 202,
              bulletPoint: "Supporting Message",
              link: null,
            },
          ],
          tableComponent: null,
        },
        {
          id: 176,
          supportingMessageHeader: "Header 1",
          componentHeader: null,
          imageURL: null,
          chartType: null,
          cardbodyComponentId: 130,
          tableId: 50,
          placeholderId: 3,
          supportingMessages: [
            { id: 193, bulletPoint: "Body text here.", link: null },
          ],
          tableComponent: [
            {
              id: 50,
              name: "Table name",
              columns: [
                {
                  id: 105,
                  title: "Header1",
                  accessor: "0EoFJtf889",
                  tableId: 50,
                },
                {
                  id: 106,
                  title: "Header2",
                  accessor: "Oc9mwfpIXC",
                  tableId: 50,
                },
                {
                  id: 107,
                  title: "Header3",
                  accessor: "EgtSF9O2yo",
                  tableId: 50,
                },
                {
                  id: 108,
                  title: "Header4",
                  accessor: "Jkru67Py67",
                  tableId: 50,
                },
                {
                  id: 109,
                  title: "Header5",
                  accessor: "V81dTzRN6U",
                  tableId: 50,
                },
                {
                  id: 110,
                  title: "Header6",
                  accessor: "lkNOjRwM04",
                  tableId: 50,
                },
              ],
              tableData: [
                {
                  key: 1,
                  rowData: [
                    "Col 11",
                    "Col 12",
                    "Col 13",
                    "Col 14",
                    "Col 15",
                    "Col16",
                  ],
                },
                {
                  key: 2,
                  rowData: [
                    "Col 21",
                    "Col 22",
                    "Col 23",
                    "Col 24",
                    "Col 25",
                    "Col26",
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const [tabId, setTabId] = useState<any>(1);

  useEffect(() => {
    //@ts-ignore
    if (window.location.pathname.includes("/tabs/")) {
      let tabArr = window.location.pathname.split("/");
      let tabID = tabArr[4];
      console.log("props", tabID);
      setTabId(tabID);
    }
    if (window.location.pathname.includes("/supporting/")) {
      let tabArr = window.location.pathname.split("/");
      let tabID = tabArr[3];
      console.log("props", tabID);
      setTabId(tabID);
    }
  });

  const subSectionId: number =
    context.subSectionMapping
      .find((section) => section.name === "Objection Handler")
      ?.subSection.find((subSection) => subSection.name === "Objection Handler")
      ?.id || 0;
  const sectionId: number =
    context.subSectionMapping
      .find((section) => section.name === "Objection Handler")
      ?.subSection.find((subSection) => subSection.name === "Objection Handler")
      ?.sectionId || 0;
  const TabPane = Tabs.TabPane;
  return (
    <>
      <Router>
        <div className="App">
          <Switch>
            <Route
              path="/:tab/:subtab/"
              render={({ match, history }) => {
                return (
                  <div className="card-container">
                    <Switch>
                      <Tabs
                        animated={true}
                        size="small"
                        type="line"
                        defaultActiveKey={tabId}
                        activeKey={tabId}
                        onChange={(key) => {
                          history.push(
                            `/objection/objection-handler/tabs/${key}`
                          );
                          setTabId(key);
                        }}
                      >
                        <TabPane tab="Unmet Needs" key="1">
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                            }}
                          >
                            <BodyCardLayout
                              grid={1}
                              type="overview"
                              layout="ObjectionHandler"
                              noImage
                              subSectionId={subSectionId}
                              sectionId={sectionId}
                              tabId={1}
                            />

                            <Switch>
                              <Route exact path="/:tab/:subtab/">
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <p>
                                    <i>
                                      Click on card link to open respective
                                      objections.
                                    </i>
                                  </p>
                                </div>
                              </Route>
                              <Route path="/:tab/:subtab/1/supporting/:cardId/:key">
                                <SupportingMessage1
                                  noKeyMessage
                                  layout="ObjectionHandler"
                                  sectionId={sectionId || 0}
                                />
                              </Route>
                            </Switch>
                          </div>
                        </TabPane>
                        {/* <TabPane tab="Patient" key="2">
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                            }}
                          >
                            <BodyCardLayout
                              grid={1}
                              type="overview"
                              layout="ObjectionHandler"
                              noImage
                              subSectionId={subSectionId}
                              sectionId={sectionId}
                              tabId={2}
                            />

                            <Switch>
                              <Route exact path="/:tab/:subtab/">
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <p>
                                    <i>
                                      Click on card link to open respective
                                      objections.
                                    </i>
                                  </p>
                                </div>
                              </Route>
                              <Route path="/:tab/:subtab/supporting/:cardId/:key">
                                <SupportingMessage1
                                  noKeyMessage
                                  layout="ObjectionHandler"
                                  sectionId={sectionId || 0}
                                />
                              </Route>
                            </Switch>
                          </div>
                        </TabPane> */}
                        <TabPane tab="Clinical" key="3">
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                            }}
                          >
                            <BodyCardLayout
                              grid={1}
                              type="overview"
                              layout="ObjectionHandler"
                              noImage
                              subSectionId={subSectionId}
                              sectionId={sectionId}
                              tabId={3}
                            />

                            <Switch>
                              <Route exact path="/:tab/:subtab/">
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <p>
                                    <i>
                                      Click on card link to open respective
                                      objections.
                                    </i>
                                  </p>
                                </div>
                              </Route>
                              <Route path="/:tab/:subtab/3/supporting/:cardId/:key">
                                <SupportingMessage1
                                  noKeyMessage
                                  layout="ObjectionHandler"
                                  sectionId={sectionId || 0}
                                />
                              </Route>
                            </Switch>
                          </div>
                        </TabPane>
                        <TabPane tab="Economic" key="4">
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "40% 60%",
                            }}
                          >
                            <BodyCardLayout
                              grid={1}
                              type="overview"
                              layout="ObjectionHandler"
                              noImage
                              subSectionId={subSectionId}
                              sectionId={sectionId}
                              tabId={4}
                            />

                            <Switch>
                              <Route exact path="/:tab/:subtab/">
                                <div
                                  style={{
                                    margin: "auto",
                                  }}
                                >
                                  <p>
                                    <i>
                                      Click on card link to open respective
                                      objections.
                                    </i>
                                  </p>
                                </div>
                              </Route>
                              <Route path="/:tab/:subtab/4/supporting/:cardId/:key">
                                <SupportingMessage1
                                  noKeyMessage
                                  layout="ObjectionHandler"
                                  sectionId={sectionId || 0}
                                />
                              </Route>
                            </Switch>
                          </div>
                        </TabPane>
                      </Tabs>
                    </Switch>
                  </div>
                );
              }}
            />
          </Switch>
        </div>
      </Router>
    </>
  );
};

export default ObjectionHandlerOverview;
