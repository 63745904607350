import { Col, Row } from "antd";
import { colors } from "../../../globalTheme";
import {
	HeaderRibbon,
	TileBody,
	TileContainer,
	TileHeader,
	TileHeaderText,
} from "../../../libraries/tile/style";
import pic1 from "../Pictures/1.png";
import pic2 from "../Pictures/2.png";
import pic3 from "../Pictures/3.png";
import pic4 from "../Pictures/4.png";
import pic5 from "../Pictures/5.png";
import pic6 from "../Pictures/6.png";
const data = [
	{
		header: "Executive Summary (homepage)",
		details: [
			{
				text: "The executive summary page includes key take-home messages pulled from each subsequent section of the dGVD. Click on the green arrows inside the tiles to proceed to corresponding sections for more details.",
				imageIndex: 0,
			},
			{
				text: "The navigation bar on the left side of the screen contains the key sections comprising the dGVD. Click on the gray arrow near the logo on the top left side of the window to expand or close the navigation bar.",
				imageIndex: 1,
			},
		],
	},
	{
		header: "Disease Overview, Current Treatment Landscape, Burden of Illness & Unmet Need, Product Information, Economic Value, and Market Access",
		details: [
			{
				text: "Each section includes comprehensively referenced key value messages with layered supporting information. Further subsections can be navigated to using the tabs across the top of the window, or by clicking the green arrows inside the tiles. ",
				imageIndex: 2,
			},
			{
				text: "Within each subsection, each key message is highlighted in green within its own tile. Click on the box with the arrow to navigate to further supporting information associated with the message. ",
				imageIndex: 3,
			},
			{
				text: "On any page, hover over the superscript enclosed in brackets to reveal the reference. If available, you will also see a hyperlink that takes you directly to the URL. ",
				imageIndex: 4,
			},
		],
	},
];
const data2 = [
	{
		header: "Objection Handler",
		details: [
			{
				text: "The objection handler includes a library of prospective payer concerns about the product, with strategic responses paired with each question. For each objection located on the left side of the window, click the green arrow to reveal the response on the right.",
				imageIndex: 5,
			},
		],
	},
	{
		header: "Abbreviations",
		details: [
			{
				text: "A comprehensive list of abbreviations found throughout the dGVD can be found in this section. Search by keyword using the box at the top of the window. ",
				imageIndex: -1,
			},
		],
	},
	{
		header: "References",
		details: [
			{
				text: "Key messages are hyperlinked to supporting evidence as appropriate throughout the dGVD, and these can be viewed on any page by hovering over the superscript in brackets. A full list of references that appear in the dGVD can be found here, organized by dGVD section. Search by keyword using the box at the top of the window.",
				imageIndex: -1,
			},
		],
	},
];
const imgArray = [pic1, pic2, pic3, pic4, pic5, pic6];
export default function PicTile() {
	return (
		<>
			<TileContainer>
				<TileHeader>
					<TileHeaderText>
						Navigation and functionality of the dGVD
					</TileHeaderText>
					<HeaderRibbon />
				</TileHeader>
				<TileBody>
					{data.map((group) => {
						return (
							<div style={{ width: "100%", margin: "2em 1em" }}>
								<div
									style={{
										borderBottom: `1px solid ${colors.zsGray20}`,
										padding: "1em 0",
									}}
								>
									<h3
										style={{
											fontSize: "1.1rem",
											fontWeight: 500,
											color: colors.zsTeal,
										}}
									>
										{group.header}
									</h3>
								</div>

								<div style={{ padding: "2em 1em" }}>
									<Row gutter={[10, 40]} align="top">
										{group.details.map((points) => {
											return (
												<>
													<Col
														span={7}
														style={{
															fontSize: "1rem",
														}}
													>
														{points.text}
													</Col>
													<Col offset={1} span={16}>
														<div
															style={{
																// width: "60%",
																// margin: "1em auto",
																// marginBottom:
																// 	"2em",
																border: "2px solid black",
															}}
														>
															<img
																src={
																	imgArray[
																		points
																			.imageIndex
																	]
																}
															/>
														</div>
													</Col>
												</>
											);
										})}
									</Row>
								</div>
							</div>
						);
					})}
					{data2.map((group) => {
						return (
							<div style={{ width: "100%", margin: "2em 1em" }}>
								<div
									style={{
										borderBottom: `1px solid ${colors.zsGray20}`,
										padding: "1em 0",
									}}
								>
									<h3
										style={{
											fontSize: "1.1rem",
											fontWeight: 500,
											color: colors.zsTeal,
										}}
									>
										{group.header}
									</h3>
								</div>

								<div style={{ padding: "2em 1em" }}>
									<Row gutter={[10, 40]} align="top">
										{group.details.map((points) => {
											return (
												<>
													<Col
														span={
															points.imageIndex ===
															-1
																? 24
																: 7
														}
														style={{
															fontSize: "1rem",
														}}
													>
														{points.text}
													</Col>
													<Col
														offset={1}
														span={
															points.imageIndex ===
															-1
																? 0
																: 16
														}
													>
														<div
															style={{
																// width: "60%",
																// margin: "1em auto",
																// marginBottom:
																// 	"2em",
																border: "2px solid black",
															}}
														>
															<img
																src={
																	imgArray[
																		points
																			.imageIndex
																	]
																}
															/>
														</div>
													</Col>
												</>
											);
										})}
									</Row>
								</div>
							</div>
						);
					})}
				</TileBody>
			</TileContainer>
		</>
	);
}
