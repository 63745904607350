import React from "react";
import AppContext from "../../store/index";
import { Route, Switch, Redirect } from "react-router-dom";
import "../../layout/components/body-layout/Subtabs/Subtabs.css";
import Subtabs from "../../layout/components/body-layout/Subtabs/Subtabs";
import SupportingMessage1 from "../SupportingMessages/SupportingMessage1";
import BodyCardLayout from "../../layout/components/body-layout";
import SupportingMessageEvidence from "../SupportingMessages/SupportingMessageEvidence";

/**
 * Renders the Market Access.
 *
 * @returns Market Access Page of the App
 */
export default function MarketAccess() {
  const context = React.useContext(AppContext);
  const [showReturn, setShowReturn] = React.useState("");
  React.useEffect(() => {
    context.setView(7);
  });
  // let matchLink = useRouteMatch();
  const subSections = context.subSectionMapping.find(
    (section) => section.name === "Market Access"
  );
  const sectionId = subSections?.id;
  const subTabs: {
    name: string;
    link: string;
    path: string;
    component: (subSectionId: number) => JSX.Element;
    isDefault: boolean;
  }[] = [];
  const subTabViews = (
    subSectionName: string,
    subSectionId: number,
    sectionId: number,
    showReturn: React.Dispatch<React.SetStateAction<string>>
  ) => {
    showReturn("");
    return (
      <BodyCardLayout
        // data={dummyData}
        grid={subSectionName === "Overview" ? 3 : 2}
        type={subSectionName === "Overview" ? "overview" : "keyMessage"}
        subSectionId={subSectionId}
        sectionId={sectionId}
      />
    );
  };
  subSections?.subSection.map((tab, index) =>
    subTabs.push({
      name: tab.name,
      link: tab.name.split(" ").join("_").toLowerCase(),
      path: "/market/" + tab.name.split(" ").join("_").toLowerCase(),
      component: () =>
        subTabViews(tab.name, tab.id, tab.sectionId, setShowReturn),
      isDefault: tab.isDefault,
    })
  );

  return (
    <>
      <Subtabs subtabOptions={subTabs} showRight={showReturn} />

      <Switch>
        <Redirect exact from="/market" to="/market/overview" />

        {subTabs.map((subTab) => {
          return (
            <Route exact path={subTab.path} component={subTab.component} />
          );
        })}
        <Route exact path="/:tab/:subtab/supporting/:cardId/:key">
          <SupportingMessage1
            showReturn={setShowReturn}
            sectionId={sectionId || 0}
          />
        </Route>
        <Route exact path="/:tab/:subtab/supporting/EvidenceAtlas/:cardId/:key">
          <SupportingMessageEvidence
            showReturn={setShowReturn}
            sectionId={sectionId || 0}
          />
        </Route>
      </Switch>
    </>
  );
}
