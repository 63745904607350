import React from "react";
import {
  TileBody,
  TileContainer,
  TileHeader,
  TileHeaderAction,
  TileHeaderActions,
  TileHeaderText,
  TileIcon,
  TileLink,
} from "./style";
import { headerIcons } from "../../globalTheme";
import AppContext from "../../store/index";
import Avatar from "antd/lib/avatar/avatar";
import { StyledTooltip } from "../keyMessage/style";
import Highlight from "react-highlighter";
import { Link, useHistory } from "react-router-dom";

export interface Interface {
  lstsearch: {
    sectionName: string;
    sectionId: number;
    subsectionName: string;
    subsectionId: number;
    subsectionCompHeader: string;
    subsectionCompSubHeader: string;
    cardCompID: number;
    cardCompHeader: string;
    cardBodyCompID: number;
    cardBodyCompHeader: string;
    cardBodyCompBodyContent: string;
    supportingkeyMessageHeader: string;
    supportingMsgContentBulletPoint: string;
    tableName: string;
    tableHeader: string;
    tableData: string;
    url: string;
    link: string;
  };
  setData: any;
}

export default function Tile3(props: Interface) {
  let Header = "";
  const context = React.useContext(AppContext);
  const history = useHistory();
  let SearchWord = context.searchWord;
  let tableComp = false;
  let supportingMsgComp = false;
  let cardBodyComp = false;

  if (
    props.lstsearch.subsectionName !== null &&
    props.lstsearch.subsectionName !== "" &&
    props.lstsearch.subsectionName !== "default"
  ) {
    Header = props.lstsearch.subsectionName;
  }
  if (
    props.lstsearch.subsectionCompHeader !== null &&
    props.lstsearch.subsectionCompHeader !== ""
  ) {
    Header = Header + "(" + props.lstsearch.subsectionCompHeader + ")";
  }
  if (
    props.lstsearch.cardCompHeader !== null &&
    props.lstsearch.cardCompHeader !== ""
  ) {
    if (Header === "") Header = Header + props.lstsearch.cardCompHeader;
    else Header = Header + " > " + props.lstsearch.cardCompHeader;
  }
  if (
    props.lstsearch.cardBodyCompHeader !== null &&
    props.lstsearch.cardBodyCompHeader !== ""
  ) {
    Header = Header + " > " + props.lstsearch.cardBodyCompHeader;
  }
  if (
    props.lstsearch.supportingkeyMessageHeader !== null &&
    props.lstsearch.supportingkeyMessageHeader !== ""
  ) {
    Header = Header + " > " + props.lstsearch.supportingkeyMessageHeader;
  }
  if (
    (props.lstsearch.tableName !== "" &&
      props.lstsearch.tableName !== null &&
      props.lstsearch.tableName !== undefined) ||
    (props.lstsearch.tableHeader !== "" &&
      props.lstsearch.tableHeader !== null &&
      props.lstsearch.tableHeader !== undefined) ||
    (props.lstsearch.tableData !== "" &&
      props.lstsearch.tableData !== null &&
      props.lstsearch.tableData !== undefined)
  ) {
    tableComp = true;
  } else if (
    (props.lstsearch.supportingkeyMessageHeader !== "" &&
      props.lstsearch.supportingkeyMessageHeader !== null &&
      props.lstsearch.supportingkeyMessageHeader !== undefined) ||
    (props.lstsearch.supportingMsgContentBulletPoint !== "" &&
      props.lstsearch.supportingMsgContentBulletPoint !== null &&
      props.lstsearch.supportingMsgContentBulletPoint !== undefined)
  ) {
    supportingMsgComp = true;
  } else if (
    (props.lstsearch.cardBodyCompHeader !== "" &&
      props.lstsearch.cardBodyCompHeader !== null &&
      props.lstsearch.cardBodyCompHeader !== undefined) ||
    (props.lstsearch.cardBodyCompBodyContent !== "" &&
      props.lstsearch.cardBodyCompBodyContent !== null &&
      props.lstsearch.cardBodyCompBodyContent !== undefined)
  ) {
    cardBodyComp = true;
  }

  const HighlightSearchWord = {
    color: "red",
    backgroundColor: "yellow",
    padding: 0,
  };
  return (
    <>
      <TileContainer>
        <TileHeader>
          <TileHeaderText>
            <Highlight search={SearchWord} matchStyle={HighlightSearchWord}>
              {Header}
            </Highlight>
          </TileHeaderText>
          <TileHeaderActions>
            <TileHeaderAction>
              <TileLink>
                <StyledTooltip>
                  <Link
                    to={"/" + props.lstsearch.link}
                    onClick={() => {
                      context.setShowSearch(true);
                      props.setData([]);
                      context.setNewSearchLink(props.lstsearch.link);
                    }}
                  >
                    {headerIcons.find((ref) => ref.id === "link")?.icon}
                  </Link>
                  {/* <div onClick={() => history.replace(props.lstsearch.link)}>
                    {headerIcons.find((ref) => ref.id === "link")?.icon}
                  </div> */}
                  {/* <Link
                    // to={{
                    //   pathname: props.lstsearch.link,
                    //   search: "",
                    //   hash: "#the-hash",
                    //   state: { fromDashboard: true },
                    // }}

                    to={
                      /*process.env.REACT_APP_API_URL "http://localhost:3000/" +
                      props.lstsearch.link
                    }

                    // onClick={() => context.setShowSearch(true)}
                  >
                    {headerIcons.find((ref) => ref.id === "link")?.icon}
                  </Link> */}
                  {/* <a
                    href={
                      /*process.env.REACT_APP_API_URL "http://localhost:3000/" +
                      props.lstsearch.link
                    }
                  >
                    
                  </a> */}
                </StyledTooltip>
              </TileLink>
            </TileHeaderAction>
          </TileHeaderActions>
        </TileHeader>
        <TileBody>
          {tableComp && (
            <table>
              <thead>
                <tr style={{ border: "0.2px solid black" }}>
                  <th
                    style={{
                      // borderBottom: "3px solid black",
                      border: "none",
                    }}
                  >
                    <Highlight
                      search={SearchWord}
                      matchStyle={HighlightSearchWord}
                    >
                      {props.lstsearch.tableHeader}
                    </Highlight>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ border: "0.2px solid black" }}>
                  <td
                    style={{
                      // borderBottom: "3px solid black",
                      border: "none",
                    }}
                  >
                    <Highlight
                      search={SearchWord}
                      matchStyle={HighlightSearchWord}
                    >
                      {props.lstsearch.tableData}
                    </Highlight>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {supportingMsgComp && (
            <>
              <ul style={{ listStyle: "disc" }}>
                {props.lstsearch.supportingMsgContentBulletPoint && (
                  <li>
                    <Highlight
                      search={SearchWord}
                      matchStyle={HighlightSearchWord}
                    >
                      {props.lstsearch.supportingMsgContentBulletPoint}
                    </Highlight>
                  </li>
                )}
              </ul>
            </>
          )}
          {cardBodyComp && (
            <>
              <ul style={{ listStyle: "disc" }}>
                {props.lstsearch.cardBodyCompBodyContent && (
                  <li>
                    <Highlight
                      search={SearchWord}
                      matchStyle={HighlightSearchWord}
                    >
                      {props.lstsearch.cardBodyCompBodyContent}
                    </Highlight>
                  </li>
                )}
              </ul>
            </>
          )}
        </TileBody>
      </TileContainer>
    </>
  );
}
