import { request } from "./../layout";
export const getAbbreviationsData = async (productId: number) => {
	const fetchUrl = "SubSection/GetAbbreviation?BrandId=" + productId;
	// console.log("Fetching...", fetchUrl);
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			return response.data;
		})
		.catch((err) => {
			console.log("Error : ", err.message);
			return null;
		});

	return data;
};

export const addAbbreviationsData = async (productId: number, value: any) => {
	const fetchUrl = "SubSection/AddAbbreviation?BrandId=" + productId;
	const data = await request
		.post(fetchUrl, value, { withCredentials: true })
		.then((response) => {
			console.log("Response...", response);

			return response.data;
		})
		.catch((err) => {
			console.log("Error : ", err.message);
			return null;
		});

	return data;
};

export const deleteAbbreviationsData = async (
	id: number,
	productId: number
) => {
	const fetchUrl =
		"SubSection/DeleteAbbreviation/" + id + "?BrandId=" + productId;
	const data = await request
		.delete(fetchUrl, { withCredentials: true })
		.then((response) => {
			console.log("Response...", response);

			return response.data;
		})
		.catch((err) => {
			console.log("Error : ", err.message);
			return null;
		});

	return data;
};
